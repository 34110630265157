import React from 'react';
import PropTypes from 'prop-types';
import uploadPhoto from '../../../assets/images/upload-photo2.png';
import db from '../../../lib/structure';
import {isValidCo2Value} from "../../../lib/util";

export default class FloorRoom extends React.Component {
    constructor(props){
        super(props);
    }

    componentDidMount(){}

    render(){
        const {t, room} = this.props;

        let hasCo2Sensor =
            this.props.room.get(db.Room.NUMBER_SENSP) +
            this.props.room.get(db.Room.NUMBER_SENCO) > 0;

        let roomType = room.get(db.Room.ROOM_TYPE);

        let co2 = isValidCo2Value(this.props.co2) && this.props.co2 < 400 ? 400 : this.props.co2;

        return <a href="#"
            onClick={() => this.props.history.push(`/room/${this.props.id}`)}
            className="card-building-outer">
            <div className="card card-outer card-building">
                <div className="card-building-inner">
                    <div className="card-img">
                        <img
                            src={this.props.mainPhotoUrl || uploadPhoto}/>
                    </div>
                    <div className="card-title">
                        <h5 className="card-title">{this.props.roomName}</h5>
                        {
                            roomType && <span>{t(`class.label.${db.classes.RoomType}.${db.RoomType.NAME}$${roomType}`)}</span>
                        }
                        {/*
                        <br/>
                        <span>{room.get(db.Room.HOME)?.id}</span>
                        */}
                    </div>
                </div>
                <div className="card-info align-center">
                    {
                        isValidCo2Value(co2) && <div className="card-info-item">
                            <div className="card-info-img co2"/>
                            <div className="card-info-text">
                                <h4>{co2} ppm</h4>
                                <small>{t('CO2')}</small>
                            </div>
                        </div>
                    }
                    {
                        this.props.temperature != null && <div className="card-info-item">
                            <div className="card-info-img temperature"/>
                            <div className="card-info-text">
                                <h4>{this.props.temperature}°C</h4>
                                <small>{t('Temperature')}</small>
                            </div>
                        </div>
                    }
                    {
                        this.props.temperature == null && <div className="card-info-item">
                            <div className="card-info-img temperature"/>
                            <div className="card-info-text">
                                <h4>{t('N/A')}</h4>
                                <small>{t('Temperature')}</small>
                            </div>
                        </div>
                    }

                    {
                        this.props.humidity != null && <div className="card-info-item">
                            <div className="card-info-img humidity"/>
                            <div className="card-info-text">
                                <h4>{this.props.humidity}%</h4>
                                <small>{t('Humidity')}</small>
                            </div>
                        </div>
                    }
                    {
                        this.props.humidity == null &&  <div className="card-info-item">
                            <div className="card-info-img humidity"/>
                            <div className="card-info-text">
                                <h4>{t('N/A')}</h4>
                                <small>{t('Humidity')}</small>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </a>
    }
}

FloorRoom.propTypes = {
    id: PropTypes.string,
    temperature: PropTypes.string,
    humidity: PropTypes.string,
    savedEnergy: PropTypes.string,
    roomName: PropTypes.string.isRequired,
    numberRadiators: PropTypes.number,
    numberSensp: PropTypes.number,
    co2: PropTypes.number,
    lastUpdate: PropTypes.string,
    t: PropTypes.func,
    mainPhotoUrl: PropTypes.string,
    room: PropTypes.object,
    history: PropTypes.any,
    roomType: PropTypes.string
};