import React, {Component, Fragment} from 'react';
import {merge} from 'lodash';
import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import Footer from '../footer';
import translate from '../translate';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import db from '../../lib/structure.js';
import Parse from 'parse';
import moment from 'moment';
import CreateEventModal from '../page-room/create-calendarEvent-modal';
import EditEventModal from './edit-calendarEvent-modal';
import Loader from '../loader';
import {toPointerFromId} from '../../lib/util';
import deleteIcon from '../../assets/images/ic_delete_grey.svg';
import editIcon from '../../assets/images/ic_edit.svg';

class HolidayPage extends Component {
    constructor(props){
        super(props);

        this.state = {
            events: [],
            event: {},
            eventName: '',
            dateFrom: moment(),
            dateTo: moment(),
            loading: true
        };

        this.deleteConfirmed = this.deleteConfirmed.bind(this);
        this.deleteIconClicked = this.deleteIconClicked.bind(this);
        this.editIconClicked = this.editIconClicked.bind(this);
        this.fetchEvents = this.fetchEvents.bind(this);
        this.getEventName = this.getEventName.bind(this);
    }

    async componentDidMount() {
        try {
            let homeId = localStorage.getItem('buildingSelection');
            this.setState({homeId});
            await this.fetchEvents();
            this.setState({loading: false})

        } catch (err) {
            console.error(err);
        }

    }
    
    async fetchEvents() {
        let query = new Parse.Query(db.classes.CalendarEvent);
        try {
            let currentHome = localStorage.getItem('buildingSelection');
            query.equalTo(db.CalendarEvent.HOME, toPointerFromId(currentHome, db.classes.Home));
            query.doesNotExist(db.CalendarEvent.ROOM);
            query.notEqualTo(db.CalendarEvent.DELETED, true);
            query.ascending(db.CalendarEvent.START_DATE);
            query.greaterThan(db.CalendarEvent.END_DATE, moment().toDate());
            let calendarEvents = await query.find();
            this.setState({events: calendarEvents});
        } catch (err) {
            console.error(err);
        }
    }

    deleteIconClicked(event) {
        this.setState({
            eventName: event.get('eventName'),
            dateFrom: moment(event.get('startDate')),
            dateTo: moment(event.get('endDate')),
            event
        });
    }

    editIconClicked(event) {
        this.setState({
            eventName: event.get('eventName'),
            dateFrom: moment(event.get('startDate')),
            dateTo: moment(event.get('endDate')),
            event
        });
    }

    async deleteConfirmed() {
        const {event} = this.state;

        try {
            let query = new Parse.Query(db.classes.CalendarEvent);
            let calendarEvent = await query.get(event.id);
            calendarEvent.set(db.CalendarEvent.DELETED, true);
            await calendarEvent.save();
            await this.fetchEvents();

        } catch (err) {
            console.error(err);
        }
    }

    getEventName(event) {
        const {t} = this.props;
        let eventName = '';
        if (!event.get(db.CalendarEvent.EVENT_NAME)) {
            if (event.get(db.CalendarEvent.EVENT_TYPE) === 'heat') eventName = t('Heating');
            if (event.get(db.CalendarEvent.EVENT_TYPE) === 'no-heat') eventName = t('No heating');
            return eventName;
        }
        return event.get(db.CalendarEvent.EVENT_NAME);
    }

    render() {
        const {t} = this.props;
        const dateFormat = 'DD MMM YYYY, HH:mm';
        const homeId = this.state.homeId;

        const hasWritePermission = localStorage.getItem('user:hasWritePermission') === 'true';

        return (
            <Fragment>
                <CreateEventModal {...this.props} homeId={homeId} fetchEvents={this.fetchEvents}/>
                <EditEventModal {...this.props} event={this.state.event} fetchEvents={this.fetchEvents}/>
                <div className="page-holiday">
                    <div className="wrapper d-flex align-items-stretch">
                        <Sidebar {...this.props}/>
                        <div className="content">
                            <Navbar {...this.props}/>
                            <div className="inner page-holiday-container">
                                <Breadcrumbs {...this.props} elements={[
                                    {
                                        link: '/dashboard',
                                        name: t('Home')
                                    },
                                    {
                                        link: null,
                                        name: t('Holidays')
                                    }
                                ]}/>
                                <div className="d-flex">

                                    <h1>
                                        {t('Holidays')}
                                        {
                                            hasWritePermission && <svg
                                                className="icon icon-add"
                                                data-toggle="modal"
                                                data-target="#createEvent"
                                                id="createButton">
                                                <use href={'/assets/images/sprite.svg#add'}></use>
                                            </svg>
                                        }
                                    </h1>
                                </div>
                                <div className="grid-cols flex-xs-column-reverse flex-sm-column-reverse flex-md-column-reverse
                            flex-lg-row">

                                    <div className="card card-outer full-w">
                                        { this.state.loading &&  <Loader size={'medium'}/>}
                                        { (this.state.events.length === 0 && !this.state.loading) &&  t('No holiday data available')}
                                        { this.state.events.length > 0 &&
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            {t('Holiday')}
                                                        </th>
                                                        <th className="hide-lg">
                                                            {t('Date From')}
                                                        </th>
                                                        <th className="hide-lg">
                                                            {t('Date To')}
                                                        </th>
                                                        <th className="hide-lg">
                                                            {t('Heating')}
                                                        </th>
                                                        <th className="hide-lg">
                                                            {t('Action')}
                                                        </th>
                                                        <th className="hide-header"/>
                                                        <th className="hide-header"/>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.events && this.state.events.map(event => {
                                                            return <tr key={event.id}>
                                                                <td>
                                                                    {this.getEventName(event)}
                                                                    <small className='m-download-date'>
                                                                        {/* eslint-disable-next-line max-len */}
                                                                        {moment(event.get(db.CalendarEvent.START_DATE)).format(dateFormat)} - {moment(event.get(db.CalendarEvent.END_DATE)).format(dateFormat)}
                                                                    </small>
                                                                </td>
                                                                <td className='hide-sm'>{moment(event.get(db.CalendarEvent.START_DATE)).format(dateFormat)}</td>
                                                                <td className='hide-sm'>{moment(event.get(db.CalendarEvent.END_DATE)).format(dateFormat)}</td>
                                                                <td className='hide-sm'>{t(`class.label.CalendarEvent.${event.get(db.CalendarEvent.EVENT_TYPE)}`)}</td>
                                                                <td className='delete-btn-small'>
                                                                    {
                                                                        hasWritePermission && <button className="edit-modal-btn"
                                                                            data-toggle="modal"
                                                                            data-target="#editEvent"
                                                                            id="editButton"
                                                                            onClick={() => this.editIconClicked(event)}
                                                                        >
                                                                            <img src={editIcon}/>
                                                                        </button>
                                                                    }

                                                                    {
                                                                        hasWritePermission && <button className="edit-modal-btn"
                                                                            data-toggle="modal"
                                                                            data-target="#deleteEvent"
                                                                            id="deleteButton"
                                                                            onClick={
                                                                                () => this.deleteIconClicked(event)
                                                                            }
                                                                        >
                                                                            <img alt="delete" src={deleteIcon}/>
                                                                        </button>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                </div>
                            </div>
                            <Footer/>
                        </div>
                    </div>
                </div>

                {/*Delete Modal*/}
                <div className="modal fade" id="deleteEvent" tabIndex="-1" role="dialog"
                    aria-labelledby="deleteEventLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content edit-info">
                            <div className="modal-header">
                                <h5 className="modal-title"
                                    id="deleteEventLabel">{t('Are you sure you want to delete this event?')}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                        viewBox="0 0 18 18">
                                        <path fill="#222"
                                            d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="text">
                                    <table className="delete-event-card mb-2">
                                        <tbody>
                                            <tr>
                                                <td>{t('Event Name')}:</td>
                                                <td>{this.state.eventName}</td>
                                            </tr>
                                            <tr>
                                                <td>{t('Start Date')}:</td>
                                                <td>{this.state.dateFrom.format(dateFormat)}</td>
                                            </tr>
                                            <tr>
                                                <td>{t('End Date')}:</td>
                                                <td>{this.state.dateTo.format(dateFormat)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="actions">
                                    <button type="submit" className="submit-form-btn" data-dismiss="modal"
                                        onClick={(e) => this.deleteConfirmed(e)}>{t('Yes')}</button>
                                    <button className="cancel-form-btn" data-dismiss="modal">{t('Cancel')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>

        );
    }
}

HolidayPage.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired
};

const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(HolidayPage));