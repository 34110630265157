'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {manageError, redirectIfNotLogged} from '../../lib/util';
import swal from 'sweetalert';

import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import translate from '../translate';
import * as db from '../../lib/structure';
import DiagnosticsStarter from '../page-system-diagnostics/diagnostics-starter';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Label} from 'reactstrap';
import DiagnosticResultItemBattery from './diagnostic-result-item-battery';
import DiagnosticResultItemTemperature from './diagnostic-result-item-temperature';
import DiagnosticResultItemAir from './diagnostic-result-item-air';
import DiagnosticResultItemConnection from './diagnostic-result-item-connection';
import DiagnosticResultItemProgramAndEvents from './diagnostic-result-item-program-and-events';
import PropType from 'prop-types';

class PageSupportRequestDiagnostic extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            loading: false,
            diagnosticRequest: null,
            supportRequest: props.location?.state?.supportRequest,
            from: props.location?.state?.from,
            room: props.location?.state?.room
        };

        this.updateSupportRequest = this.updateSupportRequest.bind(this);
        this.runDiagnostics = this.runDiagnostics.bind(this);
        this.saveAsDraft = this.saveAsDraft.bind(this);
        this.saveAndSendToSupport = this.saveAndSendToSupport.bind(this);
        this.refresh = this.refresh.bind(this);

        //https://webapp.stage.simplyhome.tech/support-request/nv7WWdfD63/diagnostic
        //https://webapp.stage.simplyhome.tech/support-request/BmMFYbjNIi/diagnostic
    }

    async componentDidMount(){
        await redirectIfNotLogged(this.props.history);

        await this.refresh();
    }

    async getDiagnosticRequest(supportRequest){
        let diagnosticRequest;

        if(supportRequest){
            diagnosticRequest = await new Parse.Query(db.classes.DiagnosticRequest)
                .equalTo(db.DiagnosticRequest.SUPPORT_REQUEST, supportRequest)
                .notEqualTo(db.DiagnosticRequest.DELETED, true)
                .include([
                    db.DiagnosticRequest.MEASUREMENTS,
                    db.DiagnosticRequest.SUPPORT_REQUEST,
                    `${db.DiagnosticRequest.SUPPORT_REQUEST}.${db.SupportRequest.ROOM}`,
                    `${db.DiagnosticRequest.SUPPORT_REQUEST}.${db.SupportRequest.RELATED_DEVICES}.${db.Device.SERIAL_NUMBER}`,
                    `${db.DiagnosticRequest.SUPPORT_REQUEST}.${db.SupportRequest.RELATED_DEVICES}.${db.Device.DEVICE_TYP}`,
                ])
                .first();
        }

        if(!diagnosticRequest){
            diagnosticRequest = new Parse.Object(db.classes.DiagnosticRequest);
        }

        return diagnosticRequest;
    }

    updateSupportRequest(fieldName, value){
        this.setState(prev => {
            let supportRequest = prev.supportRequest;

            supportRequest.set(fieldName, value);

            prev.supportRequest = supportRequest;

            return prev;
        });
    }

    async runDiagnostics(){
        try {
            this.setState({
                loading: true,
                diagnosticsResult: null,
                diagnosticRequest: null
            });

            let result = await Parse.Cloud.run('execute-room-diagnostic', {
                supportRequestId: this.state.supportRequest.id
            });

            if(result?.length !== 10) {
                throw new Error('Wasn\'t possible to execute the diagnostic for this room in this timeframe. Please contact the support: support@cleveron.ch if you would like more informations.');
            }

            let diagnosticRequest = await new Parse.Query(db.classes.DiagnosticRequest)
                .include([db.DiagnosticRequest.MEASUREMENTS, db.DiagnosticRequest.SUPPORT_REQUEST])
                .get(result);

            this.setState({
                diagnosticRequest
            });
        } catch (err) {
            console.error(err);
            manageError(err, this.props);
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    async saveAsDraft(){
        try {
            await this.state.supportRequest.save();
            await this.state.diagnosticRequest.save();

            await swal('Success', '', 'success');

            this.props.history.push({
                pathname: '/room/:id'.replace(':id', this.state.supportRequest.get(db.SupportRequest.ROOM).id),

            });
        } catch (e){
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async saveAndSendToSupport(){
        const {t} = this.props;

        try {
            this.state.supportRequest.set(db.SupportRequest.STATUS, db.SupportRequest.STATUS$OPEN);

            let confirmed = await swal({
                title: t('Are you sure to proceed?'),
                icon: 'warning',
                text: t('This will send a notification to the cleveron support.'),
                buttons: [t('Cancel'), t('Confirm')],
                dangerMode: true
            });

            if(!confirmed) return;

            await this.state.supportRequest.save();
            await this.state.diagnosticRequest.save();

            await swal({title: t('Success'), text: ' ', icon: 'success', button: [''], timer: 1000});
            this.props.history.push('/support-request/:id'.replace(':id', this.state.supportRequest.id));
        } catch(e){
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async refresh(){
        this.setState({loading: true});

        let supportRequest;

        try {
            supportRequest = await new Parse.Query(db.classes.SupportRequest).get(this.props.match.params.id);
        }catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
            return;
        }

        let diagnosticRequest = await this.getDiagnosticRequest(supportRequest);

        let room = supportRequest.get(db.SupportRequest.ROOM);

        this.setState({supportRequest, diagnosticRequest, room, loading: false});
    }

    render(){
        const {t} = this.props;
        const hasWritePermission = localStorage.getItem('user:hasWritePermission') === 'true';

        return <div className={'system-diagnostics'}>
            <div className="wrapper d-flex align-items-stretch">
                <Sidebar {...this.props}/>
                <div className="content">
                    <Navbar {...this.props}/>
                    <div className="inner">
                        <Breadcrumbs {...this.props} elements={[
                            {
                                link: `/room/${this.state.room?.id}`,
                                name: 'Room'
                            },
                            {
                                link: null,
                                name: t('Support request')
                            }
                        ]}/>
                        <div className='system-diagnostics-header'>
                            <div className='system-diagnostics-header-title full-w'>
                                {
                                    this.state.diagnosticRequest?.id && <button
                                        className='room-reporting-export-button'
                                        disabled={!hasWritePermission}
                                        style={{float: 'right'}}
                                        onClick={this.runDiagnostics}
                                    >
                                        {t('Re-run the disgnostic')}
                                    </button>
                                }

                                {
                                    (this.state.from === 'support-request-open-new' ||
                                    this.state.from == null) &&
                                    <button className='room-reporting-export-button' style={{float: 'left'}}
                                        onClick={() => this.props.history.push({
                                            pathname: '/room/:id'.replace(':id', this.props.location.state?.room?.id)
                                        })}
                                    >
                                        {t('Return to room')}
                                    </button>
                                }
                                {
                                    this.state.from === 'support-request-detail' && <button className='room-reporting-export-button' style={{float: 'left'}}
                                        onClick={() => this.props.history.push({
                                            pathname: '/support-request/:id'.replace(':id', this.state.supportRequest?.id)
                                        })}
                                    >
                                        {t('Return support request detail')}
                                    </button>
                                }

                                <h1 style={{textAlign: 'center'}}>
                                    {t('Diagnostic of room')} {this.props.location?.state?.room?.get?.(db.Room.ROOM_NAME)}
                                </h1>
                            </div>
                        </div>

                        {!this.state.diagnosticRequest?.id &&
                            <DiagnosticsStarter
                                loading={this.state.loading}
                                t={t}
                                onStart={this.runDiagnostics}
                                labels={{
                                    description: t('Run the room diagnostic'),
                                    title: t('We\'re are running the diagnostic for your room')
                                }}
                            />
                        }

                        {
                            this.state.diagnosticRequest?.id &&
                            <div className='card card-outer' style={{padding: '20px'}}>
                                <div className='system-diagnostics-result-header'>
                                    <h4>{t(this.state.diagnosticRequest?.title)}</h4>
                                    <div className='system-diagnostics-result-date'>{this.state.diagnosticRequest?.generatedAt}</div>
                                </div>
                                <div className='system-diagnostics-result-text'>
                                    {t(this.state.diagnosticRequest?.resultText)}
                                </div>
                                <div className='system-diagnostics-result-items'>
                                    <DiagnosticResultItemBattery
                                        {...this.props}
                                        diagnosticRequest={this.state.diagnosticRequest}
                                    />
                                    <DiagnosticResultItemTemperature
                                        {...this.props}
                                        refresh={this.refresh}
                                        diagnosticRequest={this.state.diagnosticRequest}
                                    />
                                    <DiagnosticResultItemConnection
                                        {...this.props}
                                        diagnosticRequest={this.state.diagnosticRequest}
                                    />
                                    <DiagnosticResultItemProgramAndEvents
                                        {...this.props}
                                        refresh={this.refresh}
                                        diagnosticRequest={this.state.diagnosticRequest}
                                    />
                                    <DiagnosticResultItemAir
                                        {...this.props}
                                        refresh={this.refresh}
                                        diagnosticRequest={this.state.diagnosticRequest}
                                    />
                                </div>
                                <br/>
                                <Label>{t('Comment')}:</Label><br/>

                                <ReactQuill theme="snow" value={this.state.supportRequest?.get(db.SupportRequest.COMMENT)} onChange={comment => this.updateSupportRequest(db.SupportRequest.COMMENT, comment)}/>

                                <div className='system-diagnostics-header-button' style={{marginTop: 20}}>
                                    <button className='btn btn-green'
                                        style={{float: 'left'}}
                                        disabled={!hasWritePermission}
                                        onClick={this.saveAsDraft}>
                                        {t('Save as Draft')}
                                    </button>
                                    <button className='btn btn-green'
                                        style={{float: 'right'}}
                                        disabled={!hasWritePermission}
                                        onClick={this.saveAndSendToSupport}>
                                        {t('Save and submit to the customer service')}
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }
}

PageSupportRequestDiagnostic.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    history: PropTypes.any.isRequired,
    location: PropTypes.object,
    match: PropType.any
};

export default translate(PageSupportRequestDiagnostic);
