import React, {Component, Fragment} from 'react';
import translate from '../../../translate';
import roomFeedbackSteps from '../../room_feedback_steps';
import PropTypes from 'prop-types';
import Notification from '../../../notification';

let gravityFeedbackConfig = roomFeedbackSteps.gravity_feedback;
let temperatureFeedbackConfig = roomFeedbackSteps.temperature_feedback;
let radiatorsFeedbackConfig = roomFeedbackSteps.radiators_feedback;

class GravityFeedback extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gravityFeedback: false,
            gravityFeedbackValue: null,
            radiatorsFeedback: false,
            radiatorsFeedbackValue: null,
            temperatureFeedbackValue: props.temperatureFeedbackValue,
            usageFeedbackValue: props.usageFeedbackValue,

            sent: {
                gravityFeedback: false,
                radiatorsFeedback: false
            }
        };

        this.gravityFeedbackBlock = React.createRef();
        this.radiatorFeedbackBlock = React.createRef();
        this.textResultBlock = React.createRef();
        this.buttonBlock = React.createRef();
    }

    async componentDidMount() {
    }

    render() {
        const {t} = this.props;

        let numberOfRadiators = localStorage.getItem('roomFeedback:numberRadiators') ?
            parseInt(localStorage.getItem('roomFeedback:numberRadiators')): null;

        let gravityFeedback = () => {
            let selectedOptions = gravityFeedbackConfig.options[this.state.temperatureFeedbackValue];
            let selectedText = gravityFeedbackConfig.text[this.state.temperatureFeedbackValue];
            let selectedTitle = gravityFeedbackConfig.title[this.state.temperatureFeedbackValue];

            let onChangeGravityFeedback = (value) => {
                this.setState({
                    gravityFeedbackValue: value,
                    gravityFeedback: true
                },() => {
                    if(numberOfRadiators === 0)
                        this.buttonBlock?.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
                    else
                        this.radiatorFeedbackBlock?.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
                });
            }

            return <Fragment>
                <div ref={this.gravityFeedbackBlock}><h5>{t(selectedTitle)}</h5></div>

                <div className="room-feedback__header">
                    <fieldset className="room-feedback__fields">
                        <div className="radio-label">
                            <input className="radio-label__input" type="radio"
                                name="name-gravity-feedback-opt-1" id="gravity-feedback-opt-1"
                                value={'gravity-feedback-opt-1'}
                                checked={selectedOptions.VERY === this.state.gravityFeedbackValue}
                                onChange={() => onChangeGravityFeedback(selectedOptions.VERY)}
                            />
                            <label className="radio-label__main" htmlFor="gravity-feedback-opt-1"
                                onChange={() => onChangeGravityFeedback(selectedOptions.VERY)}>
                                {t(selectedText[selectedOptions.VERY])}</label>
                        </div>
                        <div className="radio-label">
                            <input className="radio-label__input" type="radio"
                                name="name-gravity-feedback-opt-2" id="gravity-feedback-opt-2"
                                value={'gravity-feedback-opt-2'}
                                checked={selectedOptions.BUT_I_CAN_CONTINUE === this.state.gravityFeedbackValue}
                                onChange={() => onChangeGravityFeedback(selectedOptions.BUT_I_CAN_CONTINUE)}
                            />
                            <label className="radio-label__main" htmlFor="gravity-feedback-opt-2"
                                onChange={() => onChangeGravityFeedback(selectedOptions.BUT_I_CAN_CONTINUE)}
                            >{t(selectedText[selectedOptions.BUT_I_CAN_CONTINUE])}</label>
                        </div>
                        <div className="radio-label">
                            <input className="radio-label__input" type="radio"
                                name="name-gravity-feedback-opt-3" id="gravity-feedback-opt-3"
                                value={'gravity-feedback-opt-3'}
                                checked={selectedOptions.BUT_OK_TO_CHANGE_MAX_TEMP === this.state.gravityFeedbackValue}
                                onChange={() => onChangeGravityFeedback(selectedOptions.BUT_OK_TO_CHANGE_MAX_TEMP)}
                            />
                            <label className="radio-label__main" htmlFor="gravity-feedback-opt-3"
                                onChange={() => onChangeGravityFeedback(selectedOptions.BUT_OK_TO_CHANGE_MAX_TEMP)}
                            >{t(selectedText[selectedOptions.BUT_OK_TO_CHANGE_MAX_TEMP])}</label>
                        </div>
                    </fieldset>
                    <div className="room-feedback__aside">

                    </div>
                </div>
            </Fragment>
        }
        let radiatorsFeedback = () => {
            let options = radiatorsFeedbackConfig.options;
            let text = radiatorsFeedbackConfig.text;

            let onChangeRadiatorFeedback = (value) => {
                this.setState({
                    radiatorsFeedbackValue: value,
                    radiatorsFeedback: true
                },() => {
                    this.buttonBlock.current.scrollIntoView({behavior: 'smooth', block: 'center'})
                });
            }
            return <div className={'animate__animated animate__fadeInDown animate__fast'} ref={this.radiatorFeedbackBlock}>
                <div>
                    <h5>{t(radiatorsFeedbackConfig.title)}</h5>
                </div>
                <div className="room-feedback__header">
                    <fieldset className="room-feedback__fields">
                        <div className="radio-label">
                            <input className="radio-label__input" type="radio"
                                name="name-radiators-feedback-opt-1" id="radiators-feedback-opt-1"
                                value={'radiators-feedback-opt-1'}
                                checked={options.ALL === this.state.radiatorsFeedbackValue}
                                onChange={() => onChangeRadiatorFeedback(options.ALL)}
                            />
                            <label className="radio-label__main" htmlFor="radiators-feedback-opt-1"
                                onChange={() => onChangeRadiatorFeedback(options.ALL)}>
                                {t(text[options.ALL])}
                            </label>
                        </div>
                        <div className="radio-label">
                            <input className="radio-label__input" type="radio"
                                name="name-radiators-feedback-opt-2" id="radiators-feedback-opt-2"
                                value={'radiators-feedback-opt-2'}
                                checked={options.SOME === this.state.radiatorsFeedbackValue}
                                onChange={() => onChangeRadiatorFeedback(options.SOME)}
                            />
                            <label className="radio-label__main" htmlFor="radiators-feedback-opt-2"
                                onChange={() => onChangeRadiatorFeedback(options.SOME)}
                            >{t(text[options.SOME])}</label>
                        </div>
                        <div className="radio-label">
                            <input className="radio-label__input" type="radio"
                                name="name-radiators-feedback-opt-3" id="radiators-feedback-opt-3"
                                value={'radiators-feedback-opt-3'}
                                checked={options.NONE === this.state.radiatorsFeedbackValue}
                                onChange={() => onChangeRadiatorFeedback(options.NONE)}
                            />
                            <label className="radio-label__main" htmlFor="radiators-feedback-opt-3"
                                onChange={() => onChangeRadiatorFeedback(options.NONE)}
                            >{t(text[options.NONE])}</label>
                        </div>
                    </fieldset>
                    <div className="room-feedback__aside">
                    </div>
                </div>
            </div>
        }

        let isNotComfortable = this.state.temperatureFeedbackValue !== temperatureFeedbackConfig.options.COMFORTABLE;

        return <form id='next-step-form' className="informer__feedback room-feedback animate__animated animate__fadeInRight animate__fast"
            onSubmit={e => {
                e.preventDefault();
                this.props.continueNextStep('gravity-radiators-feedback', this.state);
            }}
            name="room-feedback">
            
            <Notification message={roomFeedbackSteps.gravity_feedback.pre_text.thanks}/>

            {
                isNotComfortable && <Fragment>
                    <p style={{fontSize: '1rem', color: 'grey', marginTop: '1rem'}}>
                        {t(roomFeedbackSteps.gravity_feedback.pre_text.wantToContinue)}
                    </p>

                    {gravityFeedback()}

                    {this.state.gravityFeedback && numberOfRadiators > 0 && radiatorsFeedback()}

                    {
                        this.state.gravityFeedback &&
                        (this.state.radiatorsFeedback || numberOfRadiators === 0) &&  <input
                            className="btn btn--primary" type="submit" name="send_data"
                            ref={this.buttonBlock}
                            value={'Send'}
                        />
                    }
                </Fragment>
            }
        </form>;
    }
}

GravityFeedback.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    continueNextStep: PropTypes.func.isRequired,
    temperatureFeedbackValue: PropTypes.string.isRequired,
    usageFeedbackValue: PropTypes.string.isRequired
};

export default translate(GravityFeedback);