import React, {Component} from 'react';
import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import Footer from '../footer';
import translate from '../translate';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {redirectIfNotLogged} from '../../lib/util';
import {merge} from 'lodash';

export class FAQ extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){
        redirectIfNotLogged(this.props.history);

        let headings = document.querySelectorAll('.accordeon__heading');

        headings.forEach(function (item) {

            item.addEventListener('click', function () {
                this.classList.toggle('is-active');
            });

        });

    }

    render() {
        const {t} = this.props;
        return (
            <div className="wrapper d-flex align-items-stretch faq-page">
                <Sidebar {...this.props}/>
                <div className="content">
                    <Navbar {...this.props}/>
                    <div className="inner user-management-container">
                        <Breadcrumbs {...this.props} elements={[
                            {
                                link: '/dashboard',
                                name: t('Home')
                            },
                            {
                                link: null,
                                name: t('FAQ')
                            }
                        ]}/>
                        <h1>{t('Frequently Asked Questions')}</h1>
                        <div className="grid-cols flex-xs-column-reverse flex-sm-column-reverse flex-md-column-reverse
                            flex-lg-row">

                            <div className="card card-outer full-w">
                                <div className="accordeon">
                                    <div className="accordeon__heading"><p className="faq-header">{t('Welche Einsparmöglichkeiten bestehen durch das CLEVERON System?')}</p></div>
                                    <div className="accordeon__pane">
                                        <div className="accordeon__pane-main">
                                            <p className="faq-content">{t('Cleveron ermöglicht es Ihnen, den Verbrauch um bis zu 30% zu senken.<br/>Die Einsparungen hängen von der Art des Gebäudes und der Art der Nutzung ab. Alle unsere Kunden reduzieren jedoch den Verbrauch um ca. 20-30%')}</p>
                                        </div>
                                    </div>

                                    <div className="accordeon__heading"><p className="faq-header">{t('Welche Gebäude sind für das CLEVERON System geeignet?')}</p></div>
                                    <div className="accordeon__pane">
                                        <div className="accordeon__pane-main">
                                            <p className="faq-content">{t('Unsere Technologie funktioniert gut in grossen Gebäuden wie Büros, Schulen, Kindergärten, Werke, Mehrzweckhallen usw. Als geeignete Gebäude gelten grundsätzlich solche, in denen Räume unterschiedlich genutzt werden.')}</p>
                                        </div>
                                    </div>

                                    <div className="accordeon__heading"><p className="faq-header">{t('Nach welchen Algorithmus funktioniert das CLEVERON System?')}</p></div>
                                    <div className="accordeon__pane">
                                        <div className="accordeon__pane-main">
                                            <p className="faq-content">{t('Unsere Algorithmen basieren auf den drei Faktoren, die den Energieverbrauch beeinflussen:<br/>Gebäudeeigenschaften, Nutzerverhalten und Wetter.<br/>Wir kombinieren alle Informationen und berechnen die richtige Temperatur zur richtigen Zeit. Unser Algorithmus spart Energie bei gleichzeitig hohem Komfort.')}</p>
                                        </div>
                                    </div>

                                    <div className="accordeon__heading"><p className="faq-header">{t('Wie zuverlässig ist das CLEVERON System?')}</p></div>
                                    <div className="accordeon__pane">
                                        <div className="accordeon__pane-main">
                                            <p className="faq-content">{t('Nach einer kurzen "Lernphase" von ca. 4 Wochen, in denen das System die Nutzungsgewohnheiten und Gegebenheiten erlernt, läuft das System sehr stabil.')}</p>
                                        </div>
                                    </div>

                                    <div className="accordeon__heading"><p className="faq-header">{t('Welche Referenzen gibt es bisher?')}</p></div>
                                    <div className="accordeon__pane">
                                        <div className="accordeon__pane-main">
                                            <p className="faq-content">{t('Eine genaue Aufführung unserer Referenzen finden Sie auf <a target="_blank" href="https://cleveron.ch/references">https://cleveron.ch/references</a>')}</p>
                                        </div>
                                    </div>

                                    <div className="accordeon__heading"><p className="faq-header">{t('Wie funktioniert die Web Applikation?')}</p></div>
                                    <div className="accordeon__pane">
                                        <div className="accordeon__pane-main">
                                            <p className="faq-content">{t('Gerne können Sie unsere Web-App über die Demo-Version testen.<br/><a target="_blank" href="https://app.cleveron.ch/login">https://app.cleveron.ch/login</a><br/>Login: Cleveron Demo<br/>PW: demo2020')}</p>
                                        </div>
                                    </div>

                                    <div className="accordeon__heading"><p className="faq-header">{t('Welche mögliche Folgekosten können entstehen?')}</p></div>
                                    <div className="accordeon__pane">
                                        <div className="accordeon__pane-main">
                                            <p className="faq-content">{t('An die Miete des CLEVERON Systems (alle Geräte) ist ein 5 Jahres-Abonnement  mit folgenden Leistungen gekoppelt:<br/><ul><li>Intelligente Technologie</li><li>Ferngesteuerte Heizung + benutzerdefiniertes Programm</li><li>Weltweite 24-Stunden-Temperaturüberwachung</li><li>Kundendienst Montag - Freitag 8:00 - 18:00 Uhr MEZ/MESZ</li><li>Wettervorhersage inkl. Live-Integration mit Wetterstation in Ihrer Nähe</li></ul>Zusatzkosten können sein:<ul><li>Benutzer Management Webapp (1 Admin inkl.) 1. - 10. Zusatznutzer</li><li>Outlook Schnittstelle</li><li>Vorzeitige Kündigung nach 12 Monaten möglich. Anfallende Kosten können individuell thematisiert werden.</li></ul>')}</p>
                                        </div>
                                    </div>

                                    <div className="accordeon__heading"><p className="faq-header">{t('Wie ist die Fernsteuerung des CLEVERON Systems geregelt?')}</p></div>
                                    <div className="accordeon__pane">
                                        <div className="accordeon__pane-main">
                                            <p className="faq-content">{t('Die Thermostate und Sensoren sind über WI-Fi mit unseren Servern verbunden. Unsere Cloud kommuniziert mit ihnen und regelt die richtige Temperatur zur richtigen Zeit. Der Benutzer kann das Gebäude über eine Webapp weltweit verwalten.')}</p>
                                        </div>
                                    </div>

                                    <div className="accordeon__heading"><p className="faq-header">{t('Wo befindet sich der Server und wie sind die Daten geschützt?')}</p></div>
                                    <div className="accordeon__pane">
                                        <div className="accordeon__pane-main">
                                            <p className="faq-content">{t('<ul><li>Der Server befindet sich in Frankfurt (Amazon Services).</li><li>Rohdaten werden in der Serverdatenbank gespeichert und sind nur für Serveradministratoren zugänglich.</li><li>Daten werden nur zu statistischen Zwecken und zur Systemüberwachung in aggregierter und nicht personenbezogener Form verwendet.</li><li>Die Daten sind nur für den Benutzer und Eigentümer des Gebäudes sichtbar. Dazu gehören Informationen zu Raum, Geräten und Messungen.</li></ul>')}</p>
                                        </div>
                                    </div>

                                    <div className="accordeon__heading"><p className="faq-header">{t('Welche Anforderungen hat das System an das Wifi-Netzwerk?')}</p></div>
                                    <div className="accordeon__pane">
                                        <div className="accordeon__pane-main">
                                            <p className="faq-content">{t('<ul><li>Es werden keine Daten oder Befehle direkt von einem externen Server an die Geräte gesendet. Es ist immer das Gerät, das eine Anfrage stellt und eine Antwort erhält.</li><li>Die Thermostate kommunizieren nicht untereinander, sondern nur mit unserer Cloud. Es kann also auch ein Gastnetzwerk für unser System eingerichtet werden.</li><li>Die Kundendaten, die der Sensor und der Thermostat an die Webapplikation übermittelt, werden auf unserem Server gespeichert.</li><li>Die Datenmenge beträgt ca. 40 kbit je Thermostat. 1 Verbindung alle 5-20 Min.</li></ul>')}</p>
                                        </div>
                                    </div>

                                    <div className="accordeon__heading"><p className="faq-header">{t('Arbeitet CLEVERON mit Partnern für die Installation zusammen? Wenn ja, welche?')}</p></div>
                                    <div className="accordeon__pane">
                                        <div className="accordeon__pane-main">
                                            <p className="faq-content">{t('Ab April 2020 arbeiten wir mit der Post Immobilien Management und Services AG zusammen. Die Post IMS wird die Installationen, Wartungen und Fehlerbehebungen schweizweit übernehmen.')}</p>
                                        </div>
                                    </div>

                                    <div className="accordeon__heading"><p className="faq-header">{t('Was passiert, wenn ein Gerät defekt ist?')}</p></div>
                                    <div className="accordeon__pane">
                                        <div className="accordeon__pane-main">
                                            <p className="faq-content">{t('Wie bei allen technischen Systemen kann es passieren, dass einmal ein Gerät defekt ist. Sofern nicht eine Beschädigung  durch den Kunden erfolgt ist, tauschen wir das Gerät so schnell wie möglich durch unseren Kundendienst aus.')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}

FAQ.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired
};

const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(FAQ));