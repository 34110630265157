'use strict';
/* eslint-disable max-len */

import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {isEmpty, merge, uniqBy} from 'lodash';
import {connect} from 'react-redux';
import Parse from 'parse';
import translate from '../translate';
import db from '../../lib/structure';
import moment from 'moment';
import * as paths from '../../lib/paths';
import i18next from '../i18n';
import swal from 'sweetalert';
import {
    redirectIfNotLogged,
    resetLocalStorage, sendDataToSalesIq, sendDateToSalesIq,
    setLocalStorageUserParameters,
    toPointerFromId,
    userIsInRole
} from '../../lib/util';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import localStorageStructure from '../../lib/localStorageStructure';

import logoImg from '../../assets/images/logo.png';
import buildingIcon1 from '../../assets/images/buildings/building-1.jpg';
import buildingIcon2 from '../../assets/images/buildings/building-2.jpg';
import buildingIcon3 from '../../assets/images/buildings/building-3.jpg';
import buildingIcon4 from '../../assets/images/buildings/building-4.jpg';
import buildingIcon5 from '../../assets/images/buildings/building-5.jpg';
import buildingIcon6 from '../../assets/images/buildings/building-6.jpg';
import LoaderV2 from '../loader-v2';

function getBuildingImage(num){

    return [
        buildingIcon1,
        buildingIcon2,
        buildingIcon3,
        buildingIcon4,
        buildingIcon5,
        buildingIcon6
    ][num % 6];
}

class BuildingsPage extends Component {
    constructor(props){
        super(props);

        this.state = {
            homes: [],
            buildingsToOnBoard: [],
            loading: true
        };
        this.user = Parse.User.current();

        this.changeLanguage = this.changeLanguage.bind(this);
        this.logout = this.logout.bind(this);
        this.getHomes = this.getHomes.bind(this);
        this.getHomesByName = this.getHomesByName.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickOnBaordBuilding = this.handleClickOnBaordBuilding.bind(this);
    }

    async componentDidMount(){
        await redirectIfNotLogged(this.props.history);
        await this.getHomes();
        this.setState({loading: false});
    }

    changeLanguage(language){
        i18next.changeLanguage(language);
        moment.locale(language);
        document.cookie = 'i18next=' + language;
    }

    async logout(){
        try {
            await Parse.User.logOut()
            this.props.history.push('/login');

        } catch(err) {
            swal('Error', err.message, 'error');

        }
    }

    async getHomes() {
        this.setState({loading: true})

        let homes = await new Parse.Query(db.classes.Home)
            .notEqualTo(db.Home.DELETED, true)
            .notEqualTo(db.Home.HIDDEN, true)
            .notEqualTo(db.Home.DATA_COLLECTION_STATUS, db.Home.DATA_COLLECTION_STATUS$OPEN)
            .select([
                db.Home.HOME_NAME,
                db.Home.OWNER,
                db.Home.ADDRESS,
                db.Home.CITY,
                db.Home.COUNTRY,
                db.Home.BUILDING_IMAGE,
                db.Home.TYPE_OF_CUSTOMER
            ])
            .limit(50)
            .find();

        let demoBuilding;

        try {
            demoBuilding = await new Parse.Query(db.classes.Home).get('X6EitoEDpX');
            homes.unshift(demoBuilding);
        } catch (e) {
            console.error(e);
        }

        homes = uniqBy(homes, 'id');

        let buildingsToOnBoard = await new Parse.Query(db.classes.Home)
            .notEqualTo(db.Home.DELETED, true)
            .notEqualTo(db.Home.HIDDEN, true)
            .equalTo(db.Home.DATA_COLLECTION_STATUS, db.Home.DATA_COLLECTION_STATUS$OPEN)
            .select([
                db.Home.HOME_NAME,
                db.Home.OWNER,
                db.Home.ADDRESS,
                db.Home.CITY,
                db.Home.BUILDING_IMAGE,
                db.Home.TYPE_OF_CUSTOMER
            ])
            .limit(50)
            .find();

        this.setState({homes, loading: false, buildingsToOnBoard});
    }

    async getHomesByName(homeName) {
        if(!homeName) return [];
        if(homeName.length < 2) return [];

        let query = new Parse.Query('Home');

        query.select([
            db.Home.HOME_NAME,
            db.Home.OWNER,
            db.Home.ADDRESS,
            db.Home.CITY,
            db.Home.BUILDING_IMAGE,
            db.Home.TYPE_OF_CUSTOMER
        ])
        query.notEqualTo(db.Home.DELETED, true);
        query.notEqualTo(db.Home.HIDDEN, true);
        query.limit(10);

        query.fullText(db.Home.HOME_NAME, homeName);
        //query.ascending('$score');

        let homes = await query.find();

        if(homes.length === 0){
            query = new Parse.Query('Home');
            query.select([
                db.Home.HOME_NAME,
                db.Home.OWNER,
                db.Home.ADDRESS,
                db.Home.CITY,
                db.Home.BUILDING_IMAGE,
                db.Home.TYPE_OF_CUSTOMER
            ])
            query.notEqualTo(db.Home.DELETED, true);
            query.notEqualTo(db.Home.HIDDEN, true);
            query.limit(10);

            query.contains(db.Home.HOME_NAME, homeName);

            homes = await query.find();
        }

        this.setState({homes: homes});
    }

    async handleClick(home) {
        resetLocalStorage();

        await setLocalStorageUserParameters(home);
        sendDataToSalesIq();

        this.props.history.push(paths.building.replace(':floorNumber', '0'));
    }

    async handleClickOnBaordBuilding(home) {
        resetLocalStorage();

        await setLocalStorageUserParameters(home);

        this.props.history.push(paths.onBoardBuilding.replace(':buildingId', home.id));
    }

    render(){
        const {t} = this.props;
        let {homes, loading, buildingsToOnBoard} = this.state;
        const home = isEmpty(homes) ? homes[0] : '';
        let isSupportRole = userIsInRole(db.roles.SUPPORT);
        let isAdminRole = userIsInRole(db.roles.ADMIN);

        return <Fragment>
            <div className="page-buildings">
                <div className="forms-page">
                    <nav className="navbar navbar-expand-lg navbar-expand-md navbar-expand-sm navbar-expand-xs navbar-light">
                        <div className="container-fluid">
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <div className="logo header__logo">
                                    <a className="logo__wrap" href="/">
                                        <img style={{margin: '20px'}} className="logo__img img-responsive" src={logoImg} alt="logo"
                                            width="135" height="65" title=""/>
                                    </a>

                                </div>
                                <ul className="nav navbar-nav ml-auto">
                                    <li className="nav-item dropdown dropdown-custom language-dropdown
                             d-flex align-items-center">
                                        <a className="nav-link dropdown-toggle" href="#" id="languageDropdown"
                                            role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {this.props.i18n.resolvedLanguage}
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="languageDropdown">
                                            <a className={`dropdown-item ${this.props.i18n.resolvedLanguage === 'en'? 'selected' : ''}`}
                                                href="#"
                                                onClick={() => this.changeLanguage('en')}>{t('English')}</a>
                                            <a className={`dropdown-item ${this.props.i18n.resolvedLanguage === 'it'? 'selected' : ''}`}
                                                href="#"
                                                onClick={() => this.changeLanguage('it')}>{t('Italian')}</a>
                                            <a className={`dropdown-item ${this.props.i18n.resolvedLanguage === 'fr'? 'selected' : ''}`}
                                                href="#"
                                                onClick={() => this.changeLanguage('fr')}>{t('French')}</a>
                                            <a className={`dropdown-item ${this.props.i18n.resolvedLanguage === 'de'? 'selected' : ''}`}
                                                href="#"
                                                onClick={() => this.changeLanguage('de')}>{t('German')}</a>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown dropdown-custom profile-dropdown">
                                        {home && <a className="nav-link dropdown-toggle d-flex align-items-center" href="#"
                                            id="navbarDropdown"
                                            role="button" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            <span>{t('Hello')}, {this.user.get(db._User.USERNAME)}</span>
                                            <div className="user-logo"
                                                style={home.get(db.Home.MAIN_PHOTO) ? {
                                                    backgroundImage: `url(${home.get(db.Home.MAIN_PHOTO).url()}`
                                                } : {}}>

                                            </div>
                                        </a>}
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <ul className="list-unstyled">
                                                <li>
                                                    <a className="dropdown-item" href={paths.profile}>{t('Profile')}</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href={paths.buildingSettings}>{t('Building Settings')}</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item logout-ico"
                                                        href="#" onClick={() => this.logout()}>{t('Logout')}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>

                    <main className="main-layout">
                        <div className="container-fluid mw-1400">
                            {loading && <LoaderV2/>}
                            {
                                loading != null &&
                                loading === false &&
                                <h1 className="main-heading">{t('Equipped Buildings')}</h1>
                            }

                            {
                                loading != null &&
                                loading === false &&
                                homes.length === 0 && <h2 className="main-heading" style={{fontSize: '1.5rem'}}>{t('No Buildings available')}</h2>
                            }
                            <div className="works">
                                <div className={'row'}>
                                    {
                                        (isSupportRole || isAdminRole) && <div className={'col-sm-6 col-lg-4'}>
                                            <label style={{color: 'white'}}>{t('Enter building name or city')}:</label>
                                            <AsyncSelect
                                                value={null}
                                                loadOptions={this.getHomesByName}
                                                isMulti={false}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    {
                                        homes.length > 0 && homes.map((home, i) => {
                                            let image = home.get(db.Home.BUILDING_IMAGE) || getBuildingImage(i);
                                            return <div key={home.id}  className="col-sm-6 col-lg-4">
                                                <a className="work" href="#" id={home.id} onClick={() => this.handleClick(home)}>
                                                    <figure className="work__main">
                                                        <div className="work__img-wrap" style={{background: `url(${image})`}}>
                                                        </div>
                                                        <figcaption className="work__figcaption">
                                                            <div className="work__heading">
                                                                {home.get(db.Home.HOME_NAME)}

                                                                {
                                                                    (isSupportRole || isAdminRole) && <span>({home.get(db.Home.CITY)})</span>
                                                                }

                                                            </div>
                                                            <div className="work__address">{home.get(db.Home.ADDRESS)}</div>
                                                        </figcaption>
                                                    </figure>
                                                </a>
                                            </div>
                                        })
                                    }

                                </div>
                            </div>

                            {
                                loading != null &&
                                loading === false &&
                                <h1 className="main-heading">{t('Buildings to equip')}</h1>
                            }
                            {
                                loading != null &&
                                loading === false &&
                                buildingsToOnBoard.length === 0 && <h2 className="main-heading" style={{fontSize: '1.5rem'}}>{t('No Buildings available')}</h2>
                            }
                            <div className="works">
                                <div className="row">
                                    {
                                        buildingsToOnBoard.length > 0 && buildingsToOnBoard.map((home, i) => {
                                            let image = home.get(db.Home.BUILDING_IMAGE) || getBuildingImage(i);
                                            return <div key={home.id}  className="col-sm-6 col-lg-4">
                                                <a className="work" href="#" id={home.id} onClick={() => this.handleClickOnBaordBuilding(home)}>
                                                    <figure className="work__main">
                                                        <div className="work__img-wrap" style={{background: `url(${image})`}}>
                                                        </div>
                                                        <figcaption className="work__figcaption">
                                                            <div className='support-request-item-status'>
                                                                <div className="work__heading">{home.get(db.Home.HOME_NAME)} <div className={'support-request-item-status-open'} style={{float: 'right'}}>{t('Pending')}</div></div>
                                                            </div>
                                                        </figcaption>
                                                    </figure>
                                                </a>
                                            </div>
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </Fragment>
    }

}

BuildingsPage.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired,
    match: PropTypes.object
};

/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(BuildingsPage));