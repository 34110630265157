const dbStructure = {
    classes: {
        _User: '_User',
        Device: 'Device',
        DeviceReplacementRequest: 'DeviceReplacementRequest',
        Room: 'Room',
        Measurement: 'Measurement',
        CalendarEvent: 'CalendarEvent',
        Home: 'Home',
        HomeSystem: 'HomeSystem',
        ProgramEvent: 'ProgramEvent',
        PresencePreset: 'PresencePreset',
        RoomPresencePreset: 'RoomPresencePreset',
        Newsletter: 'Newsletter',
        TestClass: 'TestClass',
        CommandQueue: 'CommandQueue',
        RoomReport: 'RoomReport',
        HomeReport: 'HomeReport',
        PrintJobQueue: 'PrintJobQueue',
        TestBatchDevice: 'TestBatchDevice',
        TestBatch: 'TestBatch',
        Installation: 'Installation',
        DeviceHistory: 'DeviceHistory',
        DocumentDownload: 'DocumentDownload',
        FuelMeasurement: 'FuelMeasurement',
        HomeReportPhrase: 'HomeReportPhrase',
        RoomDailyReport: 'RoomDailyReport',
        RoomFMFeedback: 'RoomFMFeedback',
        RoomAction: 'RoomAction',
        RoomMonthlyReport: 'RoomMonthlyReport',
        RoomRecommendation: 'RoomRecommendation',
        RoomWeeklyReport: 'RoomWeeklyReport',
        RoomType: 'RoomType',
        SupportRequest: 'SupportRequest',
        DiagnosticRequest: 'DiagnosticRequest',
        SupportRequestComment: 'SupportRequestComment',
        ActionHistory: 'ActionHistory',
        RoomTicket: 'RoomTicket',
        IssueCategory: 'IssueCategory',
        OperationTask: 'OperationTask'
    },
    functions: {
        WEBAPP_UPDATE_LAST_LOGIN: 'webapp-update-last-login'
    },
    roles: {
        ADMIN: 'Admin',
        LEAN_MANAGEMENT: 'Lean management',
        BUILDING_ADMIN: 'Building Admin',
        INSTALLER: 'Installer',
        REDACTOR: 'Redactor',
        SUPPLIER: 'Supplier',
        SUPPORT: 'Support',
        FIRST_LEVEL_SUPPORT: 'First Level Support',
        SECOND_LEVEL_SUPPORT: 'Second Level Support'
    },
    diagnosticActions: {
        TEMPERATURE_CALIBRATION_DOWN: 'temperatureCalibrationDown',
        TEMPERATURE_CALIBRATION_UP: 'temperatureCalibrationUp',
        DECREASE_MAX_TEMP: 'decreaseMaxTemp',
        INCREASE_MAX_TEMP: 'increaseMaxTemp',
        DECREASE_MIN_TEMP: 'decreaseMinTemp',
        INCREASE_MIN_TEMP: 'increaseMinTemp',
        HEAT_X_HOURS_BEFORE: 'heatXHoursBefore',
        ACTIVATE_AUTOMATIC_PROGRAM: 'activateAutomaticProgram',
        ACTIVATE_SUN: 'activateSun',
        HALF_MOTOR_ON_VALUE: 'halfMotorOnValue',
        DOUBLE_REPETITION: 'doubleRepetition',
        ACTIVATE_PID: 'activatePid',
        REDUCE_SLEEP_TIME: 'reduceSleepTime',
        CALIBRATE_MOTOR: 'calibrateMotor',
        TEMPERATURE_CALIBRATION_TO_MEASURED: 'temperatureCalibrationToMeasured',
        INCREASE_AUTONOMOUS_TEMP: 'increaseAutonomousTemp',
        DECREASE_AUTONOMOUS_TEMP: 'decreaseAutonomousTemp'
    },
    _User: {
        MONGO: {
            LINKED_HOME: '_p_linkedHome',
            LINKED_DEVICE: '_p_linkedDevice'
        },
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        USERNAME: 'username',
        PASSWORD: 'password',
        EMAIL: 'email',
        EMAIL_VERIFIED: 'emailVerified',
        AUTH_DATA: 'authData',
        PHONE: 'phone',
        UTC_TIME: 'utcTime',
        NAME: 'name',
        LAST_NAME: 'lastName',
        LOCATION: 'location',
        CITY: 'city',
        MQTT_SERVER: 'mqttServer',
        MQTT_USERNAME: 'mqttUsername',
        MQTT_PASSWORD: 'mqttPassword',
        IS_DEVICE: 'isDevice',
        IS_COUPLED: 'isCoupled',
        LINKED_DEVICE: 'linkedDevice',
        LINKED_HOME: 'linkedHome',
        REGISTRATION_METHOD: 'registrationMethod',
        REGISTRATION_METHOD$MANUALLY_REGISTERED: 'manually-registered',
        ACTIVE: 'active',
        TYPE_OF_CUSTOMER: 'typeOfCustomer',
        TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER: 'clever-sense-customer',
        TYPE_OF_CUSTOMER$FULL_SYSTEM_CUSTOMER: 'full-system-customer',
        TYPE_OF_CUSTOMER$READ_ONLY_DEMO_USER: 'read-only-demo-user',
        LANGUAGE: 'language',
        DELETED: 'deleted',
        MOBILE_PHONE: 'mobilePhone',
        MAILING_STREET: 'mailingStreet',
        MAILING_CITY: 'mailingCity',
        MAILING_ZIP_CODE: 'mailingZipCode'
    },
    _Role: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        NAME: 'name',
        USERS: 'users',
        ROLES: 'roles',
        WHERE: 'where',
        LIMIT: 'limit',

        ROLE$ADMIN: 'Admin',
        ROLE$LEAN_MANAGEMENT: 'Lean management',
        ROLE$BUILDING_ADMIN: 'Building Admin',
        ROLE$INSTALLER: 'Installer',
        ROLE$REDACTOR: 'Redactor',
        ROLE$SUPPLIER: 'Supplier',
        ROLE$SUPPORT: 'Support'
    },
    _Session: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        RESTRICTED: 'restricted',
        USER: 'user',
        INSTALLATION_ID: 'installationId',
        SESSION_TOKEN: 'sessionToken',
        EXPIRES_AT: 'expiresAt',
        CREATED_WITH: 'createdWith'
    },
    Device: {
        MONGO: {
            DEVICE_TYP: 'deviceTyp',
            CREATED_AT: '_created_at',
            UPDATED_AT: '_updated_at',
        },
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        DEVICE_NAME: 'deviceName',
        OWNER: 'owner',
        ROOM_ID: 'roomId',
        TEMP_MIN: 'tempMin',
        TEMP_DEFAULT: 'tempDefault',
        MAC_ADDRESS: 'macAddress',
        DEVICE_CONFIG: 'deviceConfig',
        STATIC_IP: 'staticIp',
        DEVICE_STATE_FLAG: 'deviceStateFlag',
        MOTOR_MOUVEMENTS: 'motorMouvements',
        TOTAL_MEASUREMENTS: 'totalMeasurements',
        MOTOR_MOUVEMENT_SAVE_RATIO: 'motorMouvementSaveRatio',
        DEVICE_TYP: 'deviceTyp',
        DEVICE_TYP$THERM: 'therm',
        DEVICE_TYP$SENSE: 'sense',
        DEVICE_TYP$SENCO: 'senco',
        DEVICE_TYP$SENSP: 'sensp',
        SERIAL_NUMBER: 'serialNumber',
        INSTALLATION_INFO: 'installationInfo',
        DEVICE_STATE_FLAG$ONLINE: 'online',
        DEVICE_STATE_FLAG$WORK_IN_PROGRESS: 'work-in-progress',
        DEVICE_STATE_FLAG$_CLIENT_ACTION_REQUIRED: 'client-action-required',
        DEVICE_STATE_FLAG$IN_MOUNT: 'in-mount',
        DEVICE_STATE_FLAG$INACTIVE: 'inactive',
        LAST_MEASUREMENT: 'lastMeasurement',
        LAST_MEASUREMENT_DATE: 'lastMeasurementDate',
        CORRECTION_TEMP: 'correctionTemp',
        WIFI_STRENGTH: 'wifiStrength',
        BATTERY_VOLTAGE: 'batteryVoltage',
        MOTOR_ENERGY_SAVING_ACTIVE: 'motorEnergySavingActive',
        DISCHARGE_SLOPE: 'dischargeSlope',
        MODE: 'mode',
        HOME: 'home',
        LAST_MODE_CHANGE: 'lastModeChange',
        LAST_DEVICE_STATE_FLAG_CHANGE: 'lastDeviceStateFlagChange',
        CO2: 'co2',
        TEMPERATURE: 'temperature',
        MEASURED_TEMP: 'measuredTemp',
        HUMIDITY: 'humidity',
        PRESENCE: 'presence',
        MOTOR_POSITION: 'motorPosition',
        LOCAL_IP: 'localIp',
        VERSION_FIRMWARE: 'versionFirmware',
        CONFIG_WIFI_SSID: 'configWifiSsid',
        CONFIG_WIFI_PASSWORD: 'configWifiPassword',
        CONNECTION_WIFI_SSID: 'connectionWifiSsid',
        CONNECTION_WIFI_PASSWORD: 'connectionWifiPassword',
        LAST_RESPONSE_MOTOR_COMMAND_VALUE: 'lastResponseMotorCommandValue',
        LAST_RESPONSE_MOTOR_COMMAND_VALUE_DATE: 'lastResponseMotorCommandValueDate',
        LAST_RESPONSE_SLEEP_COMMAND_VALUE: 'lastResponseSleepCommandValue',
        COMMERCIAL_LABEL: 'commercialLabel',
        COMMERCIAL_LABEL$AVAILABLE_ON_BRACK_CH: 'available-on-brack-ch',
        HEADER_X_REQUEST_PORT: 'headerXRequestPort',
        HEADER_REQUEST_HOST: 'headerRequestHost',
        MOTOR_CURRENT_LIMITS: 'motorCurrentLimits',
        MOTOR_SPEED: 'motorSpeed',
        FIRMWARE_TESTING_ACTIVE: 'firmwareTestingActive',
        FIRMWARE_TESTING_STATUS: 'firmwareTestingStatus',
        FORCE_LED_COLOR: 'forceLedColor',
        FORCE_LED_COLOR$TURNED_OFF: 'turnedOff',
        FORCE_LED_COLOR$GREEN: 'green',
        FORCE_LED_COLOR$YELLOW: 'yellow',
        FORCE_LED_COLOR$RED: 'red',
        LED_COLOR_CONFIG: 'ledColorConfig',
        MOUNTED_ENGINE: 'mountedEngine',
        ROOM_TEMP_CORRECTION: 'roomTempCorrection',
        DELETED: 'deleted',
        FORCE_LED_COLOR$WHITE: 'white',
        NB_IOT_SIGNAL_QUALITY: 'nbIotSignalQuality',

        TEMP_CORR_SHT30: 'tempCorrSht30',
        TEMP_CORR_SCD30: 'tempCorrScd30',
        TEMP_SHT30: 'sht30',

        TEMPERATURE_CORRECTION_LABEL: 'temperatureCorrectionLabel',
        TEMPERATURE_CORRECTION_LABEL$CONCRETE_WALL: 'concrete-wall',
        TEMPERATURE_CORRECTION_LABEL$ISOLATED_MATERIAL: 'isolated-material',
        LAST_MOTOR_MOUVEMENT_TIME: 'lastMotorMouvementTime',
        PID_PREV_INTEGRATION: 'pidPrevIntegration',
        NEIGHBORS_LIST: 'neighborsList',
        NEIGHBORS_WIFI_STRENGTH: 'neighborsWifiList',
        PEER_LIST: 'peerList',
        LAST_CONNECTION_PROTOCOL: 'lastConnectionProtocol',
        LAST_CONNECTION_PROTOCOL$ESP_NOW: 'esp-now',
        LAST_CONNECTION_PROTOCOL$NB_IOT: 'nb-iot',
        LAST_CONNECTION_PROTOCOL$WIFI: 'wifi',
        REFURBISHED_DATE: 'refurbishedDate',
        BATTERY_STATE_BATTERY_DIAGNOSTIC_DATE: 'batteryStateBatteryDiagnosticDate',
        BATTERY_STATE_DEVICE_MONTHLY_BATTERY_USAGE_VERSION: 'batteryStateDeviceMonthlyBatteryUsageVersion',
        BATTERY_STATE_CALCULATION_METHOD: 'batteryStateCalculationMethod',
        BATTERY_STATE_DEPLETION_DATE: 'batteryStateDepletionDate',
        BATTERY_STATE_AVAILABLE_MEASUREMENTS: 'batteryStateAvailableMeasurements',
        BATTERY_STATE_REMAINING_MEASUREMENTS: 'batteryStateRemainingMeasurements',
        BATTERY_STATE_DEVICE_MONTHLY_BATTERYUSAGE_VERSION: 'batteryStateDeviceMonthlyBatteryusageVersion',
        BATTERY_STATE_MEASUREMENT_RATE: 'batteryStateMeasurementRate',
        BATTERY_STATE_MEASUREMENTS_PER_DAY: 'batteryStateMeasurementsPerDay',
        NUMBER_UPDATE_COMMANDS_ON_CURRENT_SERIE: 'numberUpdateCommandsOnCurrentSerie',
        DATE_LAST_UPDATE_COMMANDS_SERIE: 'dateLastUpdateCommandsSerie',
        LAST_NB_IOT_CONNECTION: 'lastNbIotConnection',
        NB_IOT_FIRMWARE_VERSION: 'nbIotFirmwareVersion',
        FIRMWARE_UPDATE_STATUS: 'firmwareUpdateStatus',
        TEST_AUTONOMOUS_MODE: 'testAutonomousMode',
        FIX_RESPONSE: 'fixResponse',
    },
    DeviceReplacementRequest: {
        OBJECT_ID: 'objectId',
        REASON: 'reason',
        DEVICE: 'device',
        COMMENT: 'comment',
        PHOTO: 'photo',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
    },
    Room: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        ROOM_NAME: 'roomName',
        TEMP_MIN: 'tempMin',
        TEMP_MAX: 'tempMax',
        AREA: 'area',
        HEIGHT: 'height',
        WINDOWS: 'windows',
        HOME: 'home',
        DATA: 'data',
        PRESENCE_FORECAST_MATRIX: 'presenceForecastMatrix',
        PRESENCE_FORECAST_ENABLED: 'presenceForecastEnabled',
        STATE_FLAG: 'stateFlag',
        CORRECTION_TEMP: 'correctionTemp',
        FLOOR: 'floor',
        MAIN_PHOTO: 'mainPhoto',
        NUMBER_RADIATORS: 'numberRadiators',
        NUMBER_SENSE: 'numberSense',
        DELETED: 'deleted',
        HIDDEN: 'hidden',
        ENERGY_SAVED: 'energySaved',
        ENERGY_SAVING_PERCENTAGE: 'energySavingPercentage',
        PRESENCE_DATA_CONFIRMATION_DATE: 'presenceDataConfirmationDate',
        PRESENCE_DATA_CONFIRMATION_USER: 'presenceDataConfirmationUser',
        MAIN_PHOTO_URL: 'mainPhotoUrl',
        AVG_TEMP_LAST_HOUR: 'avgTempLastHour',
        AVG_HUM_LAST_HOUR: 'avgHumLastHour',
        AVG_CO2_LAST_HOUR: 'avgCo2LastHour',
        AVG_ROOM_EST_TEMP_LAST_HOUR: 'avgRoomEstTempLastHour',
        LAST_UPDATE_AVG_TEMP: 'lastUpdateAvgTemp',
        NUMBER_SENSP: 'numberSensp',
        NUMBER_SENCO: 'numberSenco',
        MOTOR_ON_VALUE: 'motorOnValue',
        PID_ACTIVE: 'pidActive',
        MIN_VALUE_WHEN_MAX_TEMP_PRESET: 'minValueWhenMaxTempPreset',
        OLD_TEMP_MAX: 'oldTempMax',
        OLD_TEMP_MIN: 'oldTempMin',
        DEVICE_CONFIG: 'deviceConfig',
        HIDE: 'hide',
        EMERGENCY_TEMPERATURE: 'emergencyTemperature',
        MONITORING_ACTIVATED: 'monitoringActivated',
        SUN_RADIATED: 'sunRadiated',
        TEMPERATURE_PREDICTION_FORMULA: 'temperaturePredictionFormula',
        HEATING_SLOPE_ACTIVE: 'heatingSlopeActive',
        ROOM_PROGRAM: 'roomProgram',
        UNIQUE_ID: 'uniqueId',
        ROOM_SIZE: 'roomSize',
        ROOM_TYPE: 'roomType',
        ROOM_TYPE$RESERVE: 'reserve',
        ROOM_TYPE$CORRIDOR: 'corridor',
        ROOM_TYPE$ARCHIVE: 'archive',
        THERMO_SLEEP_TIME_MINUTES: 'thermSleepTimeMinutes',
        OPEN_WINDOW: 'openWindow',
        LAST_CALIBRATION_TEMP: 'lastCalibrationTemp',
        CO2: 'co2',
        WEEKLY_PRESENCE_FORECAST: 'weeklyPresenceForecast',
        QR_CODE_PORTAL_EMAIL: 'qrCodePortalEmail',
        SENSOR: 'sensor',
        SENSOR_POSITION: 'sensorPosition',
        SENSOR_PHOTO: 'sensorPhoto',
        RADIATORS_SIZE: 'radiatorsSize',
        ROOM_CODE: 'roomCode',
        SHOW_ONLY_SENSOR_ON_WEB_APP_CHART: 'showOnlySensorOnWebAppChart',
        SHOW_ESTIMATED_ROOM_TEMP_APP_CHART: 'showEstimatedRoomTempWebAppChart'
    },
    Measurement: {
        MONGO: {
            TEMP: 'temp',
            TARGET_TEMP: 'target_temp',
            DEVICE_ID: '_p_deviceId',
            ROOM_ID: '_p_roomId',
            DEVICE_TYP: 'deviceTyp',
            CREATED_AT: '_created_at',
            UPDATED_AT: '_updated_at',
            REQUEST: 'request',
            WEATHER_TEMP_C: 'weather_temp_c',
            WEATHER_RELATIVE_HUMIDITY: 'weather_relative_humidity',
            MEASURED_TEMP: 'measuredTemp',
            HUM: 'hum',
            MAC_ADDRESS: 'macAddress',
            PRESENCE: 'presence',
            DEVICET_TYP: 'deviceTyp',
            WPERM: '_wperm',
            RPERM: '_rperm',
            ACL: '_acl',
            EXPECTED_TEMP: 'expected_temp',
            MOTOR_TARGET_STATE: 'motorTargetState',
            RESPONSE: 'response'
        },
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        TEMP: 'temp',
        ESTIMATED_ROOM_TEMP: 'estimatedRoomTemp',
        HUM: 'hum',
        PRESENCE: 'presence',
        MAC_ADDRESS: 'macAddress',
        DEVICE_ID: 'deviceId',
        ROOM_ID: 'roomId',
        MESSAGE: 'message',
        WEATHER_TEMP_C: 'weather_temp_c',
        WEATHER_RELATIVE_HUMIDITY: 'weather_relative_humidity',
        VOLTAGE: 'voltage',
        DEVICE_TYP: 'deviceTyp',
        MOTOR_STATE: 'motorState',
        TARGET_TEMP: 'targetTemp',
        MOTOR_TARGET_STATE: 'motorTargetState',
        EXPECTED_TEMP: 'expected_temp',
        REQUEST: 'request',
        RESPONSE: 'response',
        CO2: 'co2',
        MEASURED_TEMP: 'measuredTemp',
        BATTERY_VOLTAGE: 'batteryVoltage'
    },
    CalendarEvent: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        ROOM: 'room',
        HOME: 'home',
        START_DATE: 'startDate',
        END_DATE: 'endDate',
        EVENT_NAME: 'eventName',
        OUTLOOK_ID: 'outlookId',
        EVENT_TYPE: 'eventType',
        STATE: 'state',
        TEMP: 'temp',
        ROOM_ID: 'roomId',
        DEVICE_ID: 'deviceId',
        OBJECT_ID: 'objectId',
        SKEDDA_EVENT_ID: 'skeddaEventId',
        EVENT_TYPE$HEAT: 'heat',
        EVENT_TYPE$NO_HEAT: 'no-heat',
        ADDED_FROM: 'addedFrom',
        ADDED_FROM$WEB_APP: 'webapp',
        ADDED_FROM$QR_CODE_PORTAL: 'qr-code-portal',
        ACTIVE: 'active',
        DELETED: 'deleted',
        EMAIL: 'email'
    },
    Home: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        YEAR: 'year',
        AREA: 'area',
        VOLUME: 'volume',
        WINDOWS: 'windows',
        PEOPLE: 'people',
        CITY: 'city',
        COUNTRY: 'country',
        COUNTRY$SWITZERLAND: 'Switzerland',
        COUNTRY$GERMANY: 'Germany',
        COUNTRY$ITALY: 'Italy',
        OWNER: 'Owner',
        SYSTEM: 'system',
        TEMP_C: 'temp_c',
        RELATIVE_HUMIDITY: 'relative_humidity',
        U_VALUE: 'uValue',
        AREA_WALL: 'areaWall',
        TYPE: 'type',
        TIME_ZONE: 'time_zone',
        ALLOW_ADMIN_ACTIONS: 'allowAdminActions',
        DEVICES_CONFIG: 'devicesConfig',
        ALLOW_UPDATE: 'allowUpdate',
        MAIN_PHOTO: 'mainPhoto',
        HOME_NAME: 'homeName',
        MOTOR_ENERGY_SAVING_ACTIVE: 'motorEnergySavingActive',
        MOTOR_ON_VALUE: 'motorOnValue',
        INSTALLATION_STATUS: 'installationStatus',
        INSTALLATION_STATUS$ONLINE: 'online',
        INSTALLATION_STATUS$TO_INSTALL: 'to-install',
        INSTALLATION_STATUS$DEMOUNTED: 'demounted',
        BUILDING_IMAGE: 'buildingImage',
        TYPE_OF_CUSTOMER: 'typeOfCustomer',
        TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER: 'clever-sense-customer',
        TYPE_OF_CUSTOMER$FULL_SYSTEM_CUSTOMER: 'full-system-customer',
        LANGUAGE: 'language',
        ENERGY_REPORT_COMPLIANT: 'energyReportCompliant',
        HIDDEN: 'hidden',
        DELETED: 'deleted',
        PRESENCE_FORECAST_WEEK: 'presenceForecastWeek',
        SUMMER_MODE: 'summerMode',
        SUMMER_MODE_MOTOR_VALUE: 'summerModeMotorValue',
        ADDRESS: 'address',
        DEVICES_OFFLINE_NOTIFICATION: 'devicesOfflineNotification',
        ENERGY_REPORT_NOTIFICATION: 'energyReportNotification',
        ALLOW_HOLIDAY_ON_QR_CODE_PORTAL: 'allowHolidayOnQrCodePortal',
        ALLOW_TEMPERATURE_MODIFICATION_ON_QR_CODE_PORTAL: 'allowTemperatureModificationOnQrCodePortal',
        ALLOW_ROOM_FEEDBACK_QR_CODE_PORTAL: 'allowQrCodeRoomFeedback',
        SHOW_TEMPERATURE_QR_CODE_PORTAL: 'showTemperatureQrCodePortal',
        SHOW_CO2_QR_CODE_PORTAL: 'showCo2QrCodePortal',
        ALLOW_DAY_EVENT_CREATION_QR_CODE_PORTAL: 'allowDayEventCreationQrCodePortal',
        DEVICES_CONFIG$WIFI_NAME: 'wifiName',
        DEVICES_CONFIG$WIFI_PASSWORD: 'wifiPassword',
        EMISSIONS_REDUCTION_POTENTIAL_KG: 'emissionsReductionPotentialKg',
        PID_ACTIVE: 'pidActive',
        SAVING_PERCENTAGE: 'savingPercentage',
        MONITORING_ACTIVATED: 'monitoringActivated',
        WEATHER_FORECAST_TODAY: 'weatherForecastToday',
        CANTON: 'canton',
        OFFLINE_DEVICES_NOTIFICATION: 'offlineDevicesNotification',
        TYPE_OF_CUSTOMER$CUSTOMER: 'customer',
        NUMBER_RADIATORS: 'numberRadiators',
        NUMBER_SENSE: 'numberSense',
        NUMBER_SENSP: 'numberSensp',
        NUMBER_SENCO: 'numberSenco',
        CO2_PREDICTION_ACTIVATED: 'co2PredictionActivated',
        SENSP_COLOR_OFF: 'senspColorOff',
        CRM_BUILDING_ID: 'crmBuildingId',
        ALLOW_ADMIN_ACTIONS_EDIT_USER: 'allowAdminActionsEditUser',
        ALLOW_ADMIN_ACTIONS_EDIT_DATE: 'allowAdminActionsEditDate',
        THRESHOLDS: 'thresholds',
        QR_CODE_PORTAL_ALLOWED_DOMAIN: 'qrCodePortalAllowedDomain',
        WEB_UI_HIDE_OFFLINE_DEVICES: 'webUIHideOfflineDevices',
        ZIP_CODE: 'zipCode',
        DATA_COLLECTION_STATUS: 'dataCollectionStatus',
        DATA_COLLECTION_STATUS$OPEN: 'Open',
        DATA_COLLECTION_STATUS$VALID: 'Valid',
        SHOW_ONLY_SENSOR_ON_WEB_APP_CHART: 'showOnlySensorOnWebAppChart',
        SHOW_ESTIMATED_ROOM_TEMP_APP_CHART: 'showEstimatedRoomTempWebAppChart'
    },
    HomeSystem: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        SYSTEM_NAME: 'systemName',
        PRICE_KWH: 'priceKwh',
        CO_EMISSION: 'coEmission',
        HEAT_COMBUSTION: 'heatCombustion'
    },
    ProgramEvent: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        TITLE: 'title',
        START: 'start',
        END: 'end',
        ALL_DAY: 'allDay'
    },
    PresencePreset: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        NAME: 'name',
        PRESENCE_PRESET: 'presencePreset'
    },
    RoomPresencePreset: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        ROOM: 'room',
        PRESENCE_PRESET: 'presencePreset',
        APPLIES_AT: 'appliesAt',
        IS_FIXED: 'isFixed',
        APPLIES_AT_DAY: 'appliesAtDay',
        APPLIES_AT_UTC: 'appliesAtUtc'
    },
    Newsletter: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        NAME: 'name',
        EMAIL: 'email'
    },
    TestClass: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        TEST: 'test'
    },
    CommandQueue: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        COMMAND_NAME: 'commandName',
        DATA: 'data',
        DEVICE: 'device'
    },
    RoomReport: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        DATA: 'data',
        FROM_DATE: 'fromDate',
        TO_DATE: 'toDate',
        ROOM: 'room',
        HOME: 'home',
        GENERATED_AT: 'generatedAt',
        STATUS: 'status'
    },
    HomeReport: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        DATA: 'data',
        FROM_DATE: 'fromDate',
        TO_DATE: 'toDate',
        HOME: 'home',
        GENERATED_AT: 'generatedAt',
        STATUS: 'status',
        PUBLISHED_AT: 'publishedAt',
        MONTH: 'month',
        YEAR: 'year',
        ISO_WEEK: 'isoWeek',
        ROOM_COMMENTS: 'roomComments'
    },
    PrintJobQueue: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        PRINTER_ID: 'printerId',
        SERIES_N: 'seriesN',
        MAC_ADDRESS: 'macAddress',
        DEVICE_TYPE: 'deviceType',
        STATUS: 'status',
        STATUS$IN_QUEUE: 'in-queue'
    },
    TestBatchDevice: {
        DESCRIPTION: 'description',
        DEVICE: 'device',
        BATCH: 'batch',
        CONNECTED: 'connected',
        BATTERY_TEST: 'batteryTest',
        VISUAL_TEST: 'visualTest',
        MOTOR_UP_TEST: 'motorUpTest',
        MOTOR_DOWN_TEST: 'motorDownTest',
        TESTED: 'tested',
        DATE_TESTED: 'dateTested',
        CURRENT_BATTERY_LEVEL: 'currentBatteryLevel'
    },
    TestBatch: {
        NAME: 'name',
        DESCRIPTION: 'description',
        TEST_MODE_ACTIVE: 'testModeActive',
        MOTOR_UP_TEST_ACTIVE: 'motorUpTestActive',
        MOTOR_DOWN_TEST_ACTIVE: 'motorDownTestActive',
        COMPLETED: 'completed',
        DATE_COMPLETED: 'dateCompleted',
        DELETED: 'deleted'
    },
    Installation: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        HOME: 'home',
        STATUS: 'status',
        COMPLETED: 'completed',
        DATE_COMPLETED: 'dateCompleted',
        CHECK_BATTERY: 'checkBattery',
        CHECK_SIGNAL_DB: 'checkSignalDb',
        CHECK_CONNECTION: 'checkConnection',
        LAST_CHECK_DATE: 'lastCheckDate',
        CHECK_DATA: 'checkData',
        INSTALLATION_DATA: 'installationData',
        DELETED: 'deleted'
    },
    DeviceHistory: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        USER: 'user',
        DEVICE: 'device',
        NEW_OWNER: 'newOwner',
        OLD_OWNER: 'oldOwner',
        NEW_ROOM: 'newRoom',
        OLD_ROOM: 'oldRoom'
    },
    RoomFeedback: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        EMAIL: 'email',
        ROOM: 'room',
        TEXT: 'text',
        TEMPERATURE: 'temperature',
        HUMIDITY: 'humidity',
        FEEDBACK_VALUE: 'feedbackValue',
        SELECT_TEXT: 'selectText',

        TEMPERATURE_FEEDBACK_VALUE: 'temperatureFeedbackValue',
        TEMPERATURE_FEEDBACK_VALUE$TOO_COLD: 'tooCold',
        TEMPERATURE_FEEDBACK_VALUE$TOO_HOT: 'tooHot',
        TEMPERATURE_FEEDBACK_VALUE$COMFORTABLE: 'comfortable',
    },
    RoomFMFeedback: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        EMAIL: 'email',
        COMMENT: 'comment',
        ROOM: 'room',
        HOME: 'home',
        FILE: 'file',
        DELETED: 'deleted',
        RESOLVED: 'resolved'
    },
    RoomHistory: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        USER: 'user',
        ROOM: 'room',
        OLD_ROOM: 'oldRoom',
        NEW_ROOM: 'newRoom',
        DESCRIPTION: 'description'
    },
    RoomPresencePresetHistory: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        USER: 'user',
        ROOM_PRESENCE_PRESET: 'RoomPresencePreset',
        OLD_ROOM_PRESENCE_PRESET: 'oldRoomPresencePreset',
        NEW_ROOM_PRESENCE_PRESET: 'newRoomPresencePreset',
        DESCRIPTION: 'description'
    },
    DeviceTicket: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        DEVICE: 'device',
        COMMENT: 'comment',
        TEST_BATCH: 'testBatch'
    },
    ParseSession: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        USER: 'user'
    },
    RoomReportComments: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        HOME_REPORT: 'homeReport',
        ROOM: 'room',
        COMMENTS: 'comments'
    },
    HomeReportPhrase: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        CODE: 'code',
        TEXT_DE: 'text_de',
        TEXT_FR: 'text_fr',
        TEXT_EN: 'text_en',
        TEXT_IT: 'text_it'
    },
    DocumentDownload: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        FILE: 'file',
        URL: 'url',
        LANGUAGE: 'language',
        TYPE: 'type',
        HOME: 'home',
        KEY: 'key',
        DOCUMENT_NAME: 'documentName',
        STATUS: 'status',
        DATE: 'date'
    },
    FuelMeasurement: {
        LITER: 'liters',
        KWH: 'kWh',
        PRICE: 'price',
        HEATING_TYPE: 'heatingType',
        START_DATE: 'startDate',
        END_DATE: 'endDate',
        BUILDING: 'building',
        DELETED: 'deleted',
        CREATED_AT: 'createdAt',
        WITH_CLEVERON: 'withCleveron',
        CONSUMPTION_UNIT: 'consumptionUnit'
    },
    RoomDailyReport: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        DAY: 'day',
        MONTH: 'month',
        YEAR: 'year',
        ISO_WEEK: 'isoWeek',
        HOURLY_TEMPERATURE_AVERAGES: 'hourlyTemperatureAverages',
        ROOM: 'room',
        GENERATED_AT: 'generatedAt',
        HOURLY_TEMPERATURE_AVERAGE: 'hourlyTemperatureAverage',
        HOURLY_CO2_AVERAGE: 'hourlyCo2Average',
        HOURLY_PRESENCE_AVERAGE: 'hourlyPresenceAverage',
        HOURLY_MAX_PRESENCE: 'hourlyMaxPresence',
        HOURLY_EXTERNAL_TEMPERATURE_AVERAGE: 'hourlyExternalTemperatureAverage',
        HOURLY_MAXIMAL_EXPECTED_TEMPERATURE: 'hourlyMaximalExpectedTemperatureAverage',
        HOURLY_ROOM_MAX_TEMP: 'hourlyRoomMaxTemp',
        HOURLY_ROOM_MIN_TEMP: 'hourlyRoomMinTemp',
        HOURLY_MEASUREMENT_COUNT: 'hourlyMeasurementCount',
        HOURLY_CO_AVERAGE: 'hourlyCo2Average',
        RELATIVE_OCCUPATION: 'relativeOccupation',
        HUM_RATIOS: 'humRatios',
        AVERAGE_CO_WHEN_OCCUPIED: 'averageCo2WhenOccupied',
        CO_RATIOS: 'co2Ratios',
        EMPTY_TIME: 'emptyTime',
        OCCUPIED_TIME: 'occupiedTime',
        AVERAGE_TEMP_WHEN_EMPTY: 'averageTempWhenEmpty',
        AVERAGE_TEMP_WHEN_OCCUPIED: 'averageTempWhenOccupied',
        HOURLY_WINDOWS_OPENING: 'hourlyWindowsOpening',
        NUMBER_WINDOW_OPENINGS: 'numberWindowOpenings'
    },
    Documentations: {
        CREATED_BY: 'Created_By',	//Single Line
        RECORD_IMAGE: 'Record_Image',	//ocumentation Image
        OWNER: 'Owner',	//Lookup
        NAME: 'Name',	//Auto Number
        ICON: 'Icon',	//Pick List
        LINK:'Link_',	//URL
        MODIEFIED_BY: 'Modified_By',	//Single Line
        STATUS: 'Status',	//Pick List
        STATUS$DRAFT: 'Draft',	//Pick List
        STATUS$ONLINE: 'Online',	//Pick List
        STATUS$OFFLINE: 'Offline',	//Pick List
        SUBTITLE: 'Subtitle_',	//Single Line
        TITLE: 'Title_',	//Single Line
        VISIBLE_FOR:'Visible_for',	//Multiselect
        VISIBLE_ON: 'Visible_on',	//Multiselect
        VISIBLE_ON$QR_CODE: 'QR-Code',
    },
    RoomMonthlyReport: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        AVERAGE_TEMP_WHEN_EMPTY: 'averageTempWhenEmpty',
        YEAR: 'year',
        AVERAGE_TEMP_WEEKEND: 'averageTempWeekend',
        COARSE_CO_RATIOS: 'coarseCo2Ratios',
        AVERAGE_TEMP_DAY: 'averageTempDay',
        HUM_LOW_RATIO: 'humLowRatio',
        AVERAGE_TEMP_WHEN_OCCUPIED: 'averageTempWhenOccupied',
        AVERAGE_CO_WHEN_OCCUPIED: 'averageCo2WhenOccupied',
        AVERAGE_TEMP_NIGHT: 'averageTempNight',
        DAY: 'day',
        MONTH: 'month',
        RELATIVE_OCCUPATION_FIGURE: 'relativeOccupationFigure',
        HUM_HIGH_RATIO: 'humHighRatio',
        ROOM: 'room',
        COARSE_NUMBER_WINDOW_OPENINGS: 'coarseNumberWindowOpenings',
        WINDOW_OPENING_DISTRIBUTION_FIGURE: 'windowOpeningDistributionFigure',
        AVERAGE_CO2: 'averageCo2',
        AVERAGE_HUMIDITY: 'averageHumidity',
        AVERAGE_TEMPERATURE: 'averageTemperature',
        HOME: 'home',

        HOURLY_TEMPERATURE_AVERAGE: 'hourlyTemperatureAverage',
        HOURLY_MEASUREMENT_COUNT: 'hourlyMeasurementCount',
        HOURLY_EXPECTED_TEMPERATURE_MAXIMUM: 'hourlyExpectedTemperatureMaximum',
        HOURLY_EXTERNAL_TEMPERATURE_AVERAGE: 'hourlyExternalTemperatureAverage',
        HOURLY_PRESENCE_AVERAGE: 'hourlyPresenceAverage',
        HOURLY_ROOM_MAX_TEMP: 'hourlyRoomMaxTemp',
        HOURLY_ROOM_MIN_TEMP: 'hourlyRoomMinTemp',
        HOURLY_CO2_AVERAGE: 'hourlyCo2Average'

    },
    RoomRecommendation: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        STANDARD_MESSAGE_ID: 'standardMessageId',
        EXPORT_COMMENT: 'exportComment',
        ROOM: 'room',
        MONTH: 'month',
        YEAR: 'year'
    },
    RoomWeeklyReport: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        AVERAGE_TEMP_DAY: 'averageTempDay',
        HUM_HIGH_RATIO: 'humHighRatio',
        AVERAGE_TEMP_WHEN_EMPTY: 'averageTempWhenEmpty',
        AVERAGE_TEMP_WHEN_OCCUPIED: 'averageTempWhenOccupied',
        ISO_WEEK_NUMBER: 'isoWeekNumber',
        AVERAGE_TEMP_WEEKEND: 'averageTempWeekend',
        COARSE_CO_RATIOS: 'coarseCo2Ratios',
        COARSE_NUMBER_WINDOW_OPENINGS: 'coarseNumberWindowOpenings',
        AVERAGE_CO_WHEN_OCCUPIED: 'averageCo2WhenOccupied',
        DAY: 'day',
        AVERAGE_TEMP_NIGHT: 'averageTempNight',
        HOME: 'home',
        WINDOW_OPENING_DISTRIBUTION_FIGURE: 'windowOpeningDistributionFigure',
        MONTH: 'month',
        ROOM: 'room',
        RELATIVE_OCCUPATION_FIGURE: 'relativeOccupationFigure',
        HUM_LOW_RATIO: 'humLowRatio',
        YEAR: 'year',
        RUN_AT: 'runAt'
    },
    RoomAction: {
        ACTION_NAME: 'actionName',
        ACTION_NAME$TEMPERATURE_CALIBRATION: 'temperatureCalibration',
        CURRENT_TEMPERATURE: 'currentTemperature',
        LAST_TEMP_CORRECTION: 'lastTempCorrection',
        CALIBRATION_TEMPERATURE: 'calibrationTemperature',
        ERROR: 'error',
        USER: 'user',
        ROOM: 'room',
        HOME: 'home'
    },
    RoomType: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        NAME: 'name'
    },
    SupportRequest: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        INITIAL_SITUATION: 'initialSituation',
        INITIAL_SITUATION$TOO_COLD: 'tooCold',
        INITIAL_SITUATION$TOO_HOT: 'tooHot',
        INITIAL_SITUATION$CONNECTION_ISSUE: 'connectionIssue',
        INITIAL_SITUATION$BROKEN_VANDALISM: 'brokenVandalism',
        INITIAL_SITUATION$BATTERY_DISCHARGED: 'batteryDischarged',
        INITIAL_SITUATION$DISPLAYED_TEMPERATURE_IS_WRONG: 'displayedTemperatureIsWrong',
        INITIAL_SITUATION$TEMPERATURE_REGULATION_WRONG: 'temperatureRegulationWrong',
        INITIAL_SITUATION$SENSOR_COLOR_WRONG: 'sensorColorsWrong',
        DATE_OF_PROBLEM: 'dateOfProblem',
        PRIORITY: 'priority',
        PRIORITY$HIGH: 'high',
        PRIORITY$MEDIUM: 'medium',
        PRIORITY$NORMAL: 'normal',
        PRIORITY$LOW: 'low',

        RELATED_DEVICES: 'relatedDevices',
        HOME: 'home',
        USER: 'user',
        OWNER: 'owner',
        STATUS: 'status',
        STATUS$DRAFT: 'draft',
        STATUS$RESOLVED: 'resolved',
        STATUS$OPEN: 'open',
        COMMENT: 'comment',
        ROOM: 'room',
        REFERENCE_NUMBER: 'referenceNumber',
        DELETED: 'deleted'
    },
    DiagnosticRequest: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        START_DATE: 'startDate',
        END_DATE: 'endDate',
        CONTEXT: 'context',
        BATTERY_STATUS: 'batteryStatus',
        CONNECTION_STATUS: 'connectionStatus',
        MEASUREMENTS: 'measurements',
        PROGRAM_STATUS: 'programStatus',
        EVENTS_STATUS: 'eventsStatus',
        SUPPORT_REQUEST: 'supportRequest',
        AIR_QUALITY_STATUS: 'airQualityStatus',
        TEMPERATURE_STATUS: 'temperatureStatus',
        DEVICES_DIAGNOSTIC_RESULTS: 'devicesDiagnosticResults',
        DIAGNOSTIC_PROPERTIES: 'diagnosticProperties',
        DIAGNOSTIC_CONCLUSIONS: 'diagnosticConclusions',
        RESULT_DATA: 'resultData',
        TEMPERATURE_ACTIONS_EXECUTED: 'temperatureActionsExecuted',
        AIR_QUALITY_ACTIONS_EXECUTED: 'airQualityActionsExecuted',
        DELETED: 'deleted'
    },
    SupportRequestComment: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        SUPPORT_REQUEST: 'supportRequest',
        COMMENT_HTML: 'commentHtml',
        COMMENT_TEXT: 'commentText',
        SHOW_TO_CUSTOMER: 'showToCustomer',
        SEND_NOTIFICATION: 'sendNotification',
        ADDED_BY: 'addedBy',
        ACTIONS: 'actions',
        DELETED: 'deleted'
    },
    ActionHistory: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        USER: 'user',
        DATA: 'data',
        NAME: 'name',
        NAMES: 'names'
    },
    ZohoBuilding: {
        ACCOUNT: { NAME: 'Account.name', ID: 'Account.id' },
        ADAPTER_A: 'Adapter_A',
        ADAPTER_B: 'Adapter_B',
        ADAPTER_C: 'Adapter_C',
        ADMINISTRATOR: { NAME: 'Administrator.name', ID: 'Administrator.id' },
        ADMIN_PASSWORD: 'Admin_Password',
        ADMIN_USERNAME: 'Admin_Username',
        APPROVAL: {
            DELEGATE: 'approval.delegate',
            APPROVE: 'approval.approve',
            REJECT: 'approval.reject',
            RESUBMIT: 'approval.resubmit'
        },
        APPROVAL_STATE: 'approval_state',
        AUTO_COMMUNICATION: 'Auto_Communication',
        BUILDING_ADDRESS: 'Building_Address',
        BUILDING_AREA: 'Building_area',
        BUILDING_LAYOUTS: 'Building_Layouts',
        BUILDING_RESPONSIBLE: 'Building_Responsible',
        CANTON_REGION: 'Canton_Region',
        CITY: 'City',
        CLOUD_BUILDING_ID: 'Cloud_Building_Id',
        CONTRACT_END: 'Contract_End',
        CONTRACT_LENGTH: 'Contract_Length',
        COUNTRY: 'Country',
        CREATED_BY: {NAME: 'Created_By.name', ID: 'Created_By.id', EMAIL: 'Created_By.email'},
        CREATED_TIME: 'Created_Time',
        CURRENCY: 'Currency',
        CURRENCY_SYMBOL: 'currency_symbol',
        DATE_OF_INSTALLATION: 'Date_of_Installation1',
        EDITABLE: 'editable',
        EMAIL: 'Email',
        EMAIL_OPT_OUT: 'Email_Opt_Out',
        ESTIMATED_DATE_OF_INSTALLATION: 'Estimated_date_of_installation',
        EXIT_POSSIBILITY: 'Exit_possibility',
        E_G_I_D_NUMBER: 'EGID_Number',
        FUTURE_REVENUE: 'Future_revenue',
        HEATING_SYSTEM: 'Heating_System',
        ID: 'id',
        INSTALLATION_ID: 'Installation_Id',
        INSTALLATION_PARTNER: 'Installation_Partner',
        INSTALLATION_REQUIREMENTS_SENT: 'Installation_requirements_sent',
        IN_MERGE: 'in_merge',
        I_T_RESPONSIBLE: 'IT_Responsible',
        LANGUAGE: 'Language',
        LAST_ACTIVITY_TIME: 'Last_Activity_Time',
        LAST_STATUS_UPDATE: 'Last_Status_Update',
        MODIFIED_BY: {NAME: 'Modified_By.name', ID: 'Modified_By.id', EMAIL: 'Modified_By.email'},
        MODIFIED_TIME: 'Modified_Time',
        NAME: 'Name',
        ONE_TIME_REVENUE: 'One_Time_revenue',
        ORCHESTRATION: 'orchestration',
        OWNER: { NAME: 'Owner.name', ID: 'Owner.id', EMAIL: 'Owner.email' },
        PHONE: 'Phone',
        PROCESS_FLOW: 'process_flow',
        PROJECT_STATUS: 'Project_Status',
        QUANTITY_OF_C_O_SENSORS: 'Quantity_of_CO2_Sensors',
        QUANTITY_OF_DISCHARGED_THERMOSTATS: 'Quantity_of_discharged_Thermostats',
        PERCENTAGE_OF_DISCHARGED_THERMOSTATS: 'Percentage_of_Discharged_Thermostats',
        QUANTITY_OF_SENSORS: 'Quantity_of_Sensors',
        QUANTITY_OF_THERMOSTATS: 'Quantity_of_Thermostats',
        RECORD_IMAGE: 'Record_Image',
        REVIEW: 'review',
        REVIEW_PROCESS: {
            APPROVE: 'review_process.approve',
            REJECT: 'review_process.reject',
            RESUBMIT: 'review_process.resubmit'
        },
        SALES_ORDER: { NAME: 'Sales_Order.name', ID: 'Sales_Order.id' },
        STATE: 'state',
        S_L_A: 'SLA',
        TAG: 'Tag',
        TECHNICAL_RESPONSIBLE: 'Technical_Responsible',
        TECHNICAL_STATUS: 'Technical_Status',
        UNSUBSCRIBED_MODE: 'Unsubscribed_Mode',
        UNSUBSCRIBED_TIME: 'Unsubscribed_Time',
        W_I_F_I_PASSWORD: 'WI_FI_Password2',
        W_I_F_I_SECURITY: 'WI_FI_Security',
        W_I_F_I_S_S_I_D: 'WI_FI_SSID',
        YEARLY_RECURRING_REVENUE: 'Yearly_Recurring_Revenue',
        YEAR_OF_CONSTRUCTION: 'Year_of_construction',
        ZIP_CODE: 'Zip_Code',
        QUANTITY_OF_THERMOSTATS_OFFLINE: 'Quantity_of_Thermostats_offline',
        QUANTITY_OF_CO2_SENSOR_OFFLINE: 'Quantity_of_CO2_Sensor_offline',
        RENTED_DEVICES: 'Rented_Devices',
        RENTED_DEVICES$SENSORS: 'Sensors',
        RENTED_DEVICES$THERMOSTATS: 'Thermostats',
        RENTED_DEVICES$NO_RENTED_DEVICES: 'No rented devices',
        QUANTITY_OF_EXPECTED_DEFECT_THERMOSTATS: 'Quantity_of_expected_defect_Thermostats',
        LAST_UPDATE_DISCHARGED_DEVICES: 'Last_Update_Discharged_Devices',
        LAST_UPDATE_OFFLINE_DEVICES: 'Last_Update_Offline_Devices',
        STATUS_MESSAGE: 'Status_Message',
        BUILDING_INFORMATION_STATUS: 'Building_Information_Status',
        BUILDING_RESPONSIBLE_INFO_STATUS: 'Resp_Info_Status',
        IT_INFO_STATUS: 'IT_info_Status',
        USER_LIST_STATUS: 'Users_List_Status',
        ROOM_LIST_STATUS: 'Room_List_Status',
        INFO_STATUS$NEEDED: 'Needed',
        INFO_STATUS$TO_CHECK: 'To check',
        INFO_STATUS$VALID: 'Valid',
        INFO_STATUS$NOT_NEEDED: 'Not needed'
    },
    RoomTicket: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        ROOM: 'room',
        ISSUE_CATEGORY: 'issueCategory',
        AFFECTED_DEVICES: 'affectedDevices',
        BILLABLE: 'billable',
        STATUS: 'status',
        STATUS$DRAFT: 'draft',
        STATUS$CONFIRMED: 'confirmed',
        STATUS$INVOICED: 'invoiced',
        STATUS$CLOSED: 'closed',
        NOTE: 'note',
        PHOTO: 'photo',
        CRM_OPERATION_REFERENCE_NUMBER: 'crmOperationReferenceNumber',
        CRM_OPERATION_ID: 'crmOperationId',
        USER: 'user',
        CUSTOMER_NOTE: 'customerNote',
        DELETED: 'deleted'
    },
    IssueCategory: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        CATEGORY_KEY: 'categoryKey',
        CATEGORY_KEY$WIFI: 'wifi',
        CATEGORY_KEY$RADIATORS: 'radiators',
        CATEGORY_KEY$CLEVERON_DEVICES: 'cleveron-devices',
        CATEGORY_LABEL_EN: 'categoryLabelEN',
        SUB_CATEGORY_KEY: 'subCategoryKey',
        SUB_CATEGORY_LABEL_EN: 'subCategoryLabelEN'
    },
    OperationTask: {
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        ORIGINAL_CREATION_DATE: 'originalCreationDate',

        HOME: 'home',

        //Status information
        STATUS: 'status',

        COMPLETED: 'completed',
        DATE_COMPLETED: 'dateCompleted',
        INSTALLATION_DATA: 'installationData',
        INSTALLATION_SCHEDULED_DATE: 'installationScheduledDate', //Rename SCHEDULED_DATE
        REQUIRED_THERM: 'requiredTherm',
        REQUIRED_SENSE: 'requiredSense',
        OPERATION_ID: 'operationId',
        ROOM_QR_CODE: 'roomQrCode',
        TYPE: 'type',
        TYPE$INSTALLATION: 'installation',
        TYPE$MAINTENANCE: 'maintenance',
        TYPE$REPLACE_DEVICE: 'replaceDevice',
        TYPE$RECHARGE: 'buildingRecharge',
        REFERENCE_NUMBER: 'referenceNumber',
        NOTES: 'notes',
        SUBJECT: 'subject',

        //Shipping information
        SHIPPING_CITY: 'shippingCity',
        SHIPPING_COMPANY_NAME: 'shippingCompanyName',
        SHIPPING_COUNTRY: 'shippingCountry',
        SHIPPING_DATE: 'shippingDate',
        SHIPPING_DEPARTMENT: 'shippingDepartment',
        SHIPPING_FIRST_NAME: 'shippingFirstName',
        SHIPPING_LAST_NAME: 'shippingLastName',
        SHIPPING_STREET: 'shippingStreet',
        SHIPPING_STREET_NUMBER: 'shippingStreetNumber',
        SHIPPING_ZIP: 'shippingZip',

        INSTALLER: 'installer',
        INSTALLATION_PERIOD: 'installationPeriod',

        DELETED: 'deleted'
    },
    ZohoOperation: {
        ACCOUNT: 'Account',
        OWNER: { NAME: 'Owner.name', ID: 'Owner.id', EMAIL: 'Owner.email' },
        EMAIL: 'Email',
        SUBFORM: 'Subform_2',
        NAME: 'Name',
        LAST_ACTIVITY_TIME: 'Last_Activity_Time',
        TOTAL_COSTS: 'Total_Costs',
        UNSUBSCRIBED_MODE: 'Unsubscribed_Mode',
        EXCHANGE_RATE: 'Exchange_Rate',
        QUANTITY_OF_THERMOSTATS: 'Quantity_of_Thermostats',
        CURRENCY: 'Currency',
        ID: 'id',
        STATUS: 'Status',
        BUILDING_ADDRESS: 'Building_Address',
        QUANTITY_OF_SENSORS: 'Quantity_of_Sensors',
        CREATED_TIME: 'Created_Time',
        DEADLINE: 'Deadline',
        TRACKING_NUMBER: 'Tracking_Number',
        CREATED_BY: {
            NAME: 'Created_By.name',
            ID: 'Created_By.id',
            EMAIL: 'Created_By.email'
        },
        FILE: 'File',
        BUILDING: { NAME: 'Building.name', ID: 'Building.id' },
        COSTS: 'Costs',
        CATEGORY: 'Category',
        CATEGORY$NETWORK_ENHANCEMENT: 'Network enhancement',
        CATEGORY$DEVICE_REPLACEMENT: 'Device replacement',
        CATEGORY$ACTIONS: 'Actions',
        DATE_AND_TIME: 'Date_and_Time',
        REFERENCE_NUMBER: 'Reference_Number',
        SUPPORT_TICKET_URL: 'Support_Ticket_Url',
        TOTAL_BILLABLE_TIME: 'Total_Billable_Time',
        MODIFIED_BY: {
            NAME: 'Modified_By.name',
            ID: 'Modified_By.id',
            EMAIL: 'Modified_By.email'
        },
        LANGUAGE: 'Language',
        PHONE: 'Phone',
        SUPPORT_TICKET_ID: 'Support_Ticket_Id',
        SHIPPING_CONTACT: {
            ID: 'Shipping_Contact.id',
            NAME: 'Shipping_Contact.name',
        },
        SHIPPING_CITY: 'Shipping_City',
        SHIPPING_COUNTRY: 'Shipping_Country',
        SHIPPING_CODE: 'Shipping_Code',
        SHIPPING_COMPANY: 'Shipping_Company',
        SHIPPING_STREET: 'Shipping_Street',
        SHIPPING_STATE: 'Shipping_State',
        SHIPPING_DEPARTMENT: 'Shipping_Department',
        HOURLY_RATE: 'Hourly_Rate',
        MODIFIED_TIME: 'Modified_Time',
        BUILDING_RESPONSIBLE: {
            NAME: 'Building_Responsible.name',
            ID: 'Building_Responsible.id'
        },
        UNSUBSCRIBED_TIME: 'Unsubscribed_Time',
        REPORTING: 'Reporting',
        TECHNICAL_RESPONSIBLE: {
            NAME: 'Technical_Responsible.name',
            ID: 'Technical_Responsible.id'
        },
        PLANNED_TIME: 'Planned_Time',
        TAG: 'Tag',
        TOTAL_BILLABLE_COSTS: 'Total_Billable_Costs',
        CURRENCY_SYMBOL: 'currency_symbol',
        STATE: 'state',
        PROCESS_FLOW: 'process_flow',
        APPROVED: 'approved',
        APPROVAL: {
            DELEGATE: 'approval.delegate',
            APPROVE: 'approval.approve',
            REJECT: 'approval.reject',
            RESUBMIT: 'approval.resubmit'
        },
        EDITABLE: 'editable',
        REVIEW_PROCESS: {
            APPROVE: 'review_process.approve',
            REJECT: 'review_process.reject',
            RESUBMIT: 'review_process.resubmit'
        },
        REVIEW: 'review',
        ORCHESTRATION: 'orchestration',
        IN_MERGE: 'in_merge',
        APPROVAL_STATE: 'approval_state',
        NUMBER_SENSORS_TO_REPLACE: 'Number_sensors_to_replace',
        NUMBER_THERMOSTATS_TO_REPLACE: 'Number_thermostats_to_replace',
        CUSTOMER_NOTE: 'Customer_Note'
    }
};

module.exports = dbStructure;
