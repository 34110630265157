'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import db from '../../lib/structure';
import infoIcon from '../../assets/images/ic_info.svg';
import successIcon from '../../assets/images/ic_diagnostics_success.svg';
import warningIcon from '../../assets/images/ic_diagnostics_warning.svg';
import errorIcon from '../../assets/images/ic_diagnostics_error.svg';
import moment from 'moment-timezone';
import SelectSingle from '../component-select-single/select-single';
import ReactDOMServer from 'react-dom/server';
import swal from 'sweetalert';
import {convertColorsToIconTag, isValidCo2Value} from "../../lib/util";

let airQuestions = {
    firstLevelQuestions: [
        {
            key: 'theCo2MeasurementAreNotCorrect',
            label: 'airQuestions.firstLevelQuestion.theCo2MeasurementAreNotCorrect' // The co2 measurements are not correct
        }
    ],
    secondLevelQuestions: {
        theCo2MeasurementAreNotCorrect: [
            {
                key: 'co2DoNotGoBelow500',
                label: 'airQuestions.secondLevelQuestions.co2DoNotGoBelow500', // Il co2 non scende sotto o 500 ppm durante la notte
                actions: ['calibrateCo2']
            },
            {
                key: 'co2AlwaysOver1000',
                label: 'airQuestions.secondLevelQuestions.co2AlwaysOver1000', //Il co2 è sempre sopra I 1000 ppm
                actions: ['calibrateCo2']
            },
            {
                key: 'theSensorDoesNotGoGreen',
                label: 'airQuestions.secondLevelQuestions.theSensorDoesNotGoGreen',//Il sensore non diventa quasi mai verde ma resta giallo/rosso (never green)
                actions: ['calibrateCo2']
            },
            {
                key: 'theCo2ValuesAreNotPossible',
                label: 'airQuestions.secondLevelQuestions.theCo2ValuesAreNotPossible',//I valori del co2 sono sopra I 3000ppm o inferiori a 400 ppm
                actions: ['calibrateCo2']
            },
            {
                key: 'sensorNeverChangeColor',
                label: 'airQuestions.secondLevelQuestions.sensorNeverChangeColor',//The sensor never change color
                actions: ['calibrateCo2']
            }
        ]
    },
    actions: {
        calibrateCo2: {
            label: 'diagnostic.action.calibrateCo2'//Calibration of the temperature of the room
        }
    }
};

class DiagnosticResultItemAir extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            supportRequests: [],
            viewDetailedOpened: false
        };

        this.initChart = this.initChart.bind(this);
        this.getActions = this.getActions.bind(this);
        this.getActionsText = this.getActionsText.bind(this);
        this.executeCo2Actions = this.executeCo2Actions.bind(this);
    }

    async componentDidMount() {
        let supportRequest = this.props.diagnosticRequest.get(db.DiagnosticRequest.SUPPORT_REQUEST);

        this.setState({supportRequest});
    }

    initChart(){
        let viewDetailedOpened = this.state.viewDetailedOpened;

        if(viewDetailedOpened !== true) return;

        function getEuropeZurichTimestamp(date){
            let offset = Math.abs(moment.tz.zone('Europe/Zurich').utcOffset(date.clone().valueOf())) * 60 * 1000;
            return date.clone().tz('Europe/Zurich').valueOf() + offset;
        }

        const {t, diagnosticRequest} = this.props;

        let measurements = diagnosticRequest.get(db.DiagnosticRequest.MEASUREMENTS);
        let supportRequest = diagnosticRequest.get(db.DiagnosticRequest.SUPPORT_REQUEST);
        let relatedDevices = supportRequest.get(db.SupportRequest.RELATED_DEVICES);

        let airQualityChartSerie = [];
        relatedDevices //Maybe then filter sensor OR Thermo firmware v6.x
            .forEach(device => {
                airQualityChartSerie.push({
                    name: device.get(db.Device.SERIAL_NUMBER),
                    data: measurements
                        .filter(measurement => {
                            return measurement[db.Measurement.DEVICE_ID] === device.id;
                        })
                        .map(measurement => {
                            let createdAt = measurement[db.Measurement.CREATED_AT];
                            let co2 = measurement[db.Measurement.CO2];

                            if(isValidCo2Value(co2) && co2 < 400) co2 = 400;

                            return [getEuropeZurichTimestamp(moment(createdAt)), co2];
                        })
                });
            });

        Highcharts.chart('air-quality-chart', {
            chart: {
                type: 'spline',
                zoomType: 'x',
                height: 400
            },
            yAxis: {
                title: {
                    text: 'Co2 concentration (ppm)'
                },
            },

            xAxis: {
                type: 'datetime',
            },

            title: {
                text: 'Co2 concentration'
            },

            subtitle: {
                text: ''
            },

            caption: {
                text: ''
            },

            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
            },

            series: airQualityChartSerie
        });
    }

    getActions(){
        let {firstLevelAnswer, secondLevelAnswer, thirdLevelAnswer} = this.state;
        let actions = [];

        if(thirdLevelAnswer){
            actions = thirdLevelAnswer.actions || [];
        } else if(secondLevelAnswer){
            actions = secondLevelAnswer.actions || [];
        } else if(firstLevelAnswer){
            actions = firstLevelAnswer.actions || [];
        } else {
            actions = [];
        }

        return actions;
    }

    getActionsText(){
        const {t} = this.props;
        const actions = this.getActions();

        return <div className='system-diagnostics-result-item-details-info'>
            {t('Thanks for your inputs! Based on your answers the system will perform the following actions to improve the situation')}:
            <br/>
            <ul>
                {
                    actions.map(actionKey => {
                        return <li key={actionKey}>
                            {t(airQuestions.actions?.[actionKey]?.label)}
                        </li>
                    })
                }
            </ul>
            <br/>
            {t('Please click on "Execute actions" to perform these modification in the system. The modifications will have affect in the next hours. If the situation is not getting better in the next days, please re-do a diagnostic or send this ticket ot the support by clicking "Save and submit to the customer service" button.')}
            <br/>
            <br/>
            {t('Good continuation with the energy optimization!')}
        </div>;
    }

    async executeCo2Actions(){
        let {diagnosticRequest, t} = this.props;
        let {supportRequest} = this.state;

        diagnosticRequest.set(db.DiagnosticRequest.AIR_QUALITY_ACTIONS_EXECUTED, new Date());

        await diagnosticRequest.save();

        let actions = this.getActions();

        await new Parse.Object(db.classes.SupportRequestComment)
            .set(db.SupportRequestComment.COMMENT_HTML, ReactDOMServer.renderToStaticMarkup(this.getActionsText()))
            .set(db.SupportRequestComment.SUPPORT_REQUEST, supportRequest)
            .set(db.SupportRequestComment.SHOW_TO_CUSTOMER, true)
            .set(db.SupportRequestComment.ADDED_BY, Parse.User.current())
            .set(db.SupportRequestComment.ACTIONS, actions)
            .save();

        await new Parse.Object(db.classes.ActionHistory)
            .set(db.ActionHistory.NAMES, this.getActions())
            .set(db.ActionHistory.NAME, 'diagnostic-co2-actions')
            .set(db.ActionHistory.DATA, {supportRequest})
            .set(db.ActionHistory.USER, Parse.User.current())
            .save();

        await Parse.Cloud.run('execute-room-diagnostic-actions', {
            supportRequestId: supportRequest.id,
            actions
        });

        await swal({title: t('Success'), text: ' ', icon: 'success', button: [''], timer: 1000});

        this.props.refresh();
    }

    render() {
        const {t, diagnosticRequest} = this.props;
        let {firstLevelAnswer, secondLevelAnswer} = this.state;

        /** @typedef {object} DeviceProperty
         * @property {string} device
         * @property {number} serial_number
         * @property {string} device_type
         * @property {boolean} is_disconnected
         * @property {string} disconnection_time
         * @property {object} battery_state
         * @property {object} battery_state.comment
         * @property {string} battery_state.comment.it
         * @property {string} battery_state.comment.de
         * @property {string} battery_state.comment.en
         * @property {string} battery_state.comment.fr
         * @property {string} battery_state.statusLabel
         * @property {number} remaining_battery
         * @property {number} wifi_state
         * @property {number} nb_data_points
         * @property {number} meas_density
         * @property {null} device_temp_correction
         * @property {boolean} is_outlier
         * @property {string} turn_on_properties
         * @property {number} min_co2
         * @property {boolean} faulty
         * @property {boolean} faulty_motor
         * @property {object} connection_state
         * @property {object} connection_state.comment
         * @property {string} connection_state.comment.it
         * @property {string} connection_state.comment.de
         * @property {string} connection_state.comment.en
         * @property {string} connection_state.comment.fr
         * @property {string} connection_state.statusLabel
         */

        /** @typedef {object} FeedbackProperties
         * @property {number} min_battery_voltage
         * @property {number} min_estimated_battery_voltage
         * @property {string} most_distant_time_of_last_connection
         * @property {number} disconnected_prob
         * @property {number} worst_meas_density
         * @property {number} nb_devices
         * @property {number} nb_good_devices
         * @property {number} nb_outliers
         * @property {} other_feedbacks
         * @property {number} nb_days_with_feedbacks_in_last_month
         * @property {number} nb_weeks_with_feedbacks_in_last_month
         * @property {number} nb_days_with_feedbacks_last_week
         * @property {number} current_room_calibration_offset
         * @property {string[]} faulty_devices
         * @property {string[]} faulty_motors
         * @property {number} calibration_offset
         * @property {number} std_calibration_offset
         */

        /** @typedef {object} FeedbackConclusions
         * @property {string} room_id
         * @property {boolean} is_disconnection
         * @property {boolean} battery_depleted
         * @property {boolean} is_outlier
         * @property {boolean} is_wifi_unstable
         * @property {boolean} is_disconnection_global
         * @property {object} connection_state
         * @property {object} connection_state.comment
         * @property {string} connection_state.comment.it
         * @property {string} connection_state.comment.de
         * @property {string} connection_state.comment.en
         * @property {string} connection_state.comment.fr
         * @property {string} connection_state.statusLabel
         * @property {string} feedback_urgency
         * @property {string} faulty_devices
         * @property {string} faulty_motors
         * @property {string} calibration
         * @property {object} temperature_state
         * @property {object} temperature_state.comment
         * @property {string} temperature_state.comment.it
         * @property {string} temperature_state.comment.de
         * @property {string} temperature_state.comment.en
         * @property {string} temperature_state.comment.fr
         * @property {string} temperature_state.statusLabel
         * @property {number} nb_thermostats
         * @property {number} nb_sensors
         * @property {number} room_max_temp
         * @property {number} room_min_temp
         * @property {boolean} is_automatic_mode
         * @property {null} earliest_disconnection_time
         * @property {object} co2_state
         * @property {object} co2_state.comment
         * @property {string} co2_state.comment.it
         * @property {string} co2_state.comment.de
         * @property {string} co2_state.comment.en
         * @property {string} co2_state.comment.fr
         * @property {string} co2_state.statusLabel
         * @property {object} events_state
         * @property {object} events_state.comment
         * @property {string} events_state.comment.it
         * @property {string} events_state.comment.de
         * @property {string} events_state.comment.en
         * @property {string} events_state.comment.fr
         * @property {string} events_state.statusLabel
         * @property {object} battery_state
         * @property {object} battery_state.comment
         * @property {string} battery_state.comment.it
         * @property {string} battery_state.comment.de
         * @property {string} battery_state.comment.en
         * @property {string} battery_state.comment.fr
         * @property {string} battery_state.statusLabel
         */

        let airQualityActionsExecuted = diagnosticRequest.get(db.DiagnosticRequest.AIR_QUALITY_ACTIONS_EXECUTED);
        /**
         *
         * @type {{devicesStatus: {comment: *, device: *, statusLabel: *}[], comment: {fr: string, en: string, it: string, de: string}, statusLabel: string}}  airQualityStatus
         */
        let airQualityStatus = diagnosticRequest.get(db.DiagnosticRequest.AIR_QUALITY_STATUS);

        let item = {
            'title': 'Air',
            'description': airQualityStatus.comment?.[this.props.i18n.resolvedLanguage],
            'icon': convertColorsToIconTag(airQualityStatus.statusLabel), //info,success,warning,danger
            'infoMessage': '',
            'warningMessage': ''
        };

        let firstLevelQuestions = airQuestions.firstLevelQuestions.map(question => ({
            key: question.key,
            label: t(question.label)
        }));

        let secondLevelQuestions = airQuestions.secondLevelQuestions?.[firstLevelAnswer?.key]?.map(question => ({
            key: question.key,
            label: t(question.label),
            actions: question.actions
        })) ?? [];

        return <div>
            <div className={'system-diagnostics-result-item'}>
                <div className='system-diagnostics-result-item-header'>
                    <div className='system-diagnostics-result-item-group'>
                        <div style={{alignSelf: 'center'}}>
                            {item.icon === 'info' && <img src={infoIcon} className='system-diagnostics-result-item-icon'/>}
                            {item.icon === 'success' && <img src={successIcon} className='system-diagnostics-result-item-icon'/>}
                            {item.icon === 'warning' && <img src={warningIcon} className='system-diagnostics-result-item-icon'/>}
                            {item.icon === 'danger' && <img src={errorIcon} className='system-diagnostics-result-item-icon'/>}
                        </div>
                        <div className='system-diagnostics-result-item-status'>
                            <div className='system-diagnostics-result-item-title'>
                                {t(item.title)}
                            </div>
                            <div className='system-diagnostics-result-item-description'>
                                {t(item.description)}
                            </div>
                        </div>
                    </div>
                    <div className='system-diagnostics-result-item-view-details'>
                        <a onClick={(e) => {
                            e.preventDefault();
                            this.setState(prevState => {
                                return {
                                    viewDetailedOpened:  !prevState.viewDetailedOpened
                                };
                            }, () => this.initChart());
                        }} className='system-diagnostics-result-item-details-label'>{t('View details')}</a>

                        <a onClick={(e) => {
                            e.preventDefault();
                            this.setState(prevState => {
                                return {
                                    viewDetailedOpened:  !prevState.viewDetailedOpened
                                };
                            }, () => this.initChart());
                        }} className={`system-diagnostics-result-item-details-icon arrow ${this.state.viewDetailedOpened ? 'is-active' : ''}`}></a>
                    </div>
                </div>
                {this.state.viewDetailedOpened && (<div className='system-diagnostics-result-item-details'>
                    {item.infoMessage && <div className='system-diagnostics-result-item-details-info'>
                        {t(item.infoMessage)}
                    </div>}
                    {item.warningMessage && <div className='system-diagnostics-result-item-details-warning'>
                        {t(item.warningMessage)}
                    </div>}
                    {item.buttons && <div className='system-diagnostics-result-item-details-buttons'>
                        {item.buttons.map((button, index) => {
                            return <a key={index}
                                target='_blank'
                                href={button.url}
                                className='system-diagnostics-result-item-details-button' rel="noreferrer">
                                {t(button.label)}
                            </a>;
                        })}
                    </div>}

                    <figure className="highcharts-figure" style={{width: '100%'}}>
                        <div id="air-quality-chart" style={{width: '100%'}}></div>
                    </figure>

                    {
                        !airQualityActionsExecuted && <>
                            <div className={'form-group full-w'}>
                                <label style={{width: 300}}>{t('Please select and answer')} *</label>
                                <SelectSingle
                                    t={t}
                                    value={firstLevelAnswer?.key}
                                    options={firstLevelQuestions}
                                    style={{maxWidth: 600}}
                                    onChange={firstLevelAnswer => this.setState({firstLevelAnswer}) }
                                />
                            </div>
                            {
                                firstLevelAnswer && <div className={'form-group full-w'}>
                                    <label style={{width: 300}}>{t('Please select and answer')} *</label>
                                    <SelectSingle
                                        t={t}
                                        value={secondLevelAnswer?.key}
                                        options={secondLevelQuestions}
                                        style={{maxWidth: 600}}
                                        onChange={secondLevelAnswer => this.setState({secondLevelAnswer})}
                                    />
                                </div>
                            }
                            {
                                (
                                    firstLevelQuestions.length === 0 ||
                                    (
                                        firstLevelQuestions.length > 0 &&
                                        this.state.firstLevelAnswer != null
                                    )
                                ) &&
                                (
                                    secondLevelQuestions.length === 0 ||
                                    (
                                        secondLevelQuestions.length > 0 &&
                                        this.state.secondLevelAnswer != null
                                    )
                                ) &&
                                this.getActionsText()
                            }

                            <button
                                className='room-reporting-export-button'
                                style={{float: 'right'}}
                                onClick={this.executeCo2Actions}
                            >
                                {t('Execute the action')}
                            </button>
                        </>
                    }

                    {
                        airQualityActionsExecuted && <div className='system-diagnostics-result-item-details-info'>
                            {t('Some actions for this diagnostic have been already executed. Please open a new diagnostic if you would like to execute more diagnostic actions.')}
                        </div>
                    }
                </div>
                )}
            </div>
        </div>
    }
}

DiagnosticResultItemAir.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    history: PropTypes.any.isRequired,
    diagnosticRequest: PropTypes.object.isRequired,
    refresh: PropTypes.func.isRequired
};

export default DiagnosticResultItemAir;