import React, {Component} from 'react';
import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import Footer from '../footer';
import translate from '../translate';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {redirectIfNotLogged} from '../../lib/util';
import {merge} from 'lodash';

export class PageCookieDeclaration extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){
        redirectIfNotLogged(this.props.history);

        let headings = document.querySelectorAll('.accordeon__heading');

        headings.forEach(function (item) {

            item.addEventListener('click', function () {
                this.classList.toggle('is-active');
            });

        });
        
        let hello = document.querySelector('#cookieConsentContainer');
        const script = document.createElement('script');
        script.src = 'https://consent.cookiebot.com/c6c71e3c-3ce8-4fc7-89e0-18c7315694d9/cd.js';
        script.async = true;

        hello.appendChild(script);
    }

    render() {
        const {t} = this.props;

        return (
            <div className="wrapper d-flex align-items-stretch faq-page">
                <Sidebar {...this.props}/>
                <div className="content">
                    <Navbar {...this.props}/>
                    <div className="inner user-management-container">
                        <Breadcrumbs {...this.props} elements={[
                            {
                                link: '/dashboard',
                                name: t('Home')
                            },
                            {
                                link: null,
                                name: t('Cookie declaration')
                            }
                        ]}/>
                        <h1>{t('Cookie declaration')}</h1>
                        <div className="grid-cols flex-xs-column-reverse flex-sm-column-reverse flex-md-column-reverse flex-lg-row">
                            <div className="card card-outer full-w">
                                <div id ="cookieConsentContainer"></div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}

PageCookieDeclaration.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequiredcmg
};

export default translate(PageCookieDeclaration);