import React, {Component} from 'react';
import PropTypes from 'prop-types';
import db from '../../lib/structure.js';
import Parse from 'parse';
import Loader from '../loader';
import {isValidCo2Value, redirectIfNotLogged, toPointerFromId, truncate} from '../../lib/util';
import MultiSelect from '../multiselect';
import swal from 'sweetalert';
import {LIVE_REPORT_CUSTOM_SELECTED_COLUMNS} from '../../lib/localStorageStructure';

function getColorFromCustomThresholds(co2, customThresholds,){
    let colorName;
    if(customThresholds){
        let co2Thresholds = customThresholds.map(threshold => threshold.threshold).slice(0, 2);
        for(const [i, co2Threshold] of co2Thresholds.entries()) {
            if(co2 <= co2Threshold) {
                colorName = customThresholds[i].colorName;
                break;
            }
        }

        if(!colorName) colorName = customThresholds[2].colorName;
    } else {
        if(co2 < 1000)
            colorName = db.Device.FORCE_LED_COLOR$GREEN;
        else if (co2 < 1500)
            colorName = db.Device.FORCE_LED_COLOR$YELLOW;
        else
            colorName = db.Device.FORCE_LED_COLOR$RED;
    }

    return colorName;
}

function getIconFromColorName(colorName, co2){
    if(colorName === db.Device.FORCE_LED_COLOR$GREEN)
        return <span>{co2} ppm <div className={'env-indicator-circle env-indicator-circle-green'}></div></span>;
    else if(colorName === db.Device.FORCE_LED_COLOR$YELLOW)
        return <span>{co2} ppm <div className={'env-indicator-circle env-indicator-circle-yellow'}></div></span>;
    else if(colorName === db.Device.FORCE_LED_COLOR$TURNED_OFF)
        return <span>{co2} ppm <div className={'env-indicator-circle env-indicator-circle-grey'}></div></span>;
    else if(colorName === db.Device.FORCE_LED_COLOR$RED)
        return <span>{co2} ppm <div className={'env-indicator-circle env-indicator-circle-red'}></div></span>;

    return <span>{co2} ppm <div className={'env-indicator-circle env-indicator-circle-white'}></div></span>;
}

function format(key, room, home, props){
    const {t, history} = props;

    let showEstimatedRoomTemperature = room?.get(db.Room.SHOW_ESTIMATED_ROOM_TEMP_APP_CHART) ??
        home?.get(db.Home.SHOW_ESTIMATED_ROOM_TEMP_APP_CHART) ?? false;

    let value = room.get(key);

    if(showEstimatedRoomTemperature && key === db.Room.AVG_TEMP_LAST_HOUR){
        value = room.get(db.Room.AVG_ROOM_EST_TEMP_LAST_HOUR);
    }

    let customThresholds = home.get(db.Home.THRESHOLDS);

    let map = {
        [db.Room.AVG_TEMP_LAST_HOUR]: (value) => `${value == null || isNaN(value) ? 'N/A' : value.toFixed(1)} °C`,
        [db.Room.AVG_HUM_LAST_HOUR]: (value) => `${value == null || isNaN(value) ? 'N/A' : Math.round(value, 1)} %`,
        [db.Room.ENERGY_SAVING_PERCENTAGE]: (value) => `${value == null || isNaN(value) ? 'N/A' : Math.round(value, 0)} %`,
        [db.Room.TEMP_MAX]: (value) => `${value == null || isNaN(value) ? 'N/A' : value} °C`,
        [db.Room.TEMP_MIN]: (value) => `${value == null || isNaN(value) ? 'N/A' : value} °C`,
        [db.Room.PRESENCE_FORECAST_ENABLED]: value => value === true ? t(`class.label.${db.Room.PRESENCE_FORECAST_ENABLED}.true`) : t(`class.label.${db.Room.PRESENCE_FORECAST_ENABLED}.false`),
        [db.Room.ROOM_NAME]: value => <a href="#"
            className="ml-auto align-items-center link" onClick={() => history.push(`/room/${room.id}`)}>
            {value}
        </a>,
        [db.Room.CO2]: co2 => {
            if(!co2) return '-';

            co2 = Math.round(co2);

            if(isValidCo2Value(co2) && co2 < 400) co2 = 400;

            let colorName = getColorFromCustomThresholds(co2, customThresholds);

            return getIconFromColorName(colorName, co2);
        },
        [db.Room.ROOM_TYPE]: value => value != null ? t(`class.label.${db.classes.RoomType}.${db.RoomType.NAME}$${value}`) : 'N/A'
    }

    return map[key]?.(value) ?? value;
}

let columns = [
    db.Room.ROOM_NAME,
    db.Room.FLOOR,
    db.Room.ROOM_TYPE,
    db.Room.TEMP_MAX,
    db.Room.TEMP_MIN,
    db.Room.AVG_TEMP_LAST_HOUR,
    db.Room.AVG_HUM_LAST_HOUR,
    db.Room.CO2,
    db.Room.ENERGY_SAVING_PERCENTAGE,
    db.Room.PRESENCE_FORECAST_ENABLED
];

let defaultSelectedColumns = [
    db.Room.ROOM_NAME,
    db.Room.TEMP_MAX,
    db.Room.AVG_TEMP_LAST_HOUR,
    db.Room.CO2
];

class LiveTable extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading: true,
            rooms: [],
            selectedColumns: []
        };
    }

    async componentDidMount() {
        let customSelectedColumns = localStorage.getItem(LIVE_REPORT_CUSTOM_SELECTED_COLUMNS);
        let selectedColumns  = defaultSelectedColumns;

        if(customSelectedColumns)
            selectedColumns = customSelectedColumns.split(',');

        await redirectIfNotLogged(this.props.history);
        await this.getRooms();
        const home = await this.getHome();

        this.setState({
            selectedColumns: selectedColumns
                .map(column => ({label: this.props.t(`class.label.${column}`), value: column}))
        });
    }

    async getHome(){
        let homeId = localStorage.getItem('buildingSelection');
        try {
            let home = await new Parse.Query(db.classes.Home).get(homeId);
            this.setState({home});

            return home;
        } catch(e){
            console.error(e);
        }
    }

    async getRooms() {
        let homeId = localStorage.getItem('buildingSelection');
        let query = new Parse.Query(db.classes.Room);
        query.equalTo(db.Room.HOME, toPointerFromId(homeId, db.classes.Home));
        query.notEqualTo(db.Room.DELETED, true);
        query.notEqualTo(db.Room.HIDDEN, true);
        query.limit(1000);
        let rooms = await query.find();
        this.setState({rooms, loading: false});
    }

    render() {
        const {t} = this.props;
        const {rooms, loading, home} = this.state;

        return (
            <>
                <div className={'flex-wrapper justify-end'}>
                    <MultiSelect
                        options={columns.map(column => ({label: t(`class.label.${column}`), value: column}))}
                        values={this.state.selectedColumns}
                        t={t}
                        onChange={async selectedColumns => {
                            if(selectedColumns.length > 4){
                                await swal({
                                    title: t('Warning'),
                                    icon: 'warning',
                                    text: 'Maximum selected columns reached. Please deselect another columns and retry.',
                                    dangerMode: true,
                                    timeout: 1000
                                });

                                return;
                            }

                            localStorage.setItem(
                                LIVE_REPORT_CUSTOM_SELECTED_COLUMNS,
                                selectedColumns.map(item => item.value)
                            );

                            this.setState({selectedColumns});
                        }}
                        style={{marginBottom: 20}}
                        className={'building-report-live'}
                    />
                </div>
                <div className="grid-cols flex-xs-column-reverse flex-sm-column-reverse flex-md-column-reverse flex-lg-row">
                    <div className="card card-outer full-w mt-20">
                        { loading &&  <Loader size={'medium'}/>}
                        { (rooms.length === 0 && !loading) &&  t('No room data available')}
                        { rooms.length > 0 &&
                            <table className="table building-report-live-table">
                                <thead>
                                    <tr>
                                        {
                                            this.state.selectedColumns?.map((column, i) => {
                                                return <th key={i} style={{minWidth: 150}}>
                                                    {truncate(column.label)}
                                                </th>
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        home && rooms && rooms.map(room => {
                                            return <tr key={room.id}>
                                                {
                                                    this.state.selectedColumns?.map((column, i) => {
                                                        return <td key={i}>
                                                            {format(column.value, room, home, this.props)}
                                                        </td>
                                                    })
                                                }
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </>

        );
    }
}

LiveTable.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    history: PropTypes.any.isRequired
};

export default LiveTable;