'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import checkedIcon from '../../assets/images/ic_checked.svg';

/**
 * Usage example:

 *  <MultiSelect
 *        placeholder={'Choose...'}
 *       {label: 'Tesla', value: 'tesla'},
 *       {label: 'BWM', value: 'bmw'},
 *       {label: 'Audi', value: 'audi'},
 *      {label: 'Volkswagen', value: 'vw'},
 *       {label: 'Toyota', value: 'toyota'},
 *   ]} values={[{value:'bmw', label: 'BMW'}, {value:'audi', label: 'Audi'}]} t={t}
 *   onChange={(selectedOptions) => console.log(selectedOptions)}/>
 *
 */
class MultiSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: false
        };
        this.handleOptionClick = this.handleOptionClick.bind(this);
        this.isOptionSelected = this.isOptionSelected.bind(this);

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({active: false});
        }
    }

    handleOptionClick(option) {
        let newSelectedOptions = [];
        if (this.isOptionSelected(option)) {
            newSelectedOptions = this.props.values.filter(
                (selectedOption) => selectedOption.value !== option.value);
        } else {
            newSelectedOptions = [...this.props.values, option];
        }

        this.props.onChange(newSelectedOptions);
    }

    isOptionSelected(option) {
        return this.props.values.find((selectedOption) => selectedOption.value === option.value);
    }

    renderSelectedOptions() {
        const {values, t} = this.props;
        if (values && values.length > 0) {
            const displayedLabels = values.slice(0, 2).map(option => option.label).join(', ');
            let andMore = '';
            if (values.length > 2) {
                andMore = (<span className='and-more'>{'&'} {values.length - 2} {t('more')}</span>)
            }
            return <div>{displayedLabels} {andMore}</div>
        } else {
            return <div>
                {this.props.placeholder ? this.props.placeholder : t('Please select options')}
            </div>
        }
    }

    render(){
        const {t, style, options} = this.props;

        return  <div ref={this.wrapperRef} className={`multiselect-custom ${this.state.active ? 'active' : ''} ${this.props.error ? 'error' : ''} ${this.props.disabled ? 'disabled' : ''} ${this.props.className}`} style={style}>
            <div className={`multiselect-custom-status ${this.props.width ? '' : 'width-200'} hide-overflow`}
                onClick={() => {
                    if (this.props.disabled) {
                        return;
                    }
                    this.setState({
                        active: !this.state.active
                    });
                }}>
                {this.renderSelectedOptions()}
            </div>
            <ul className="multiselect-custom-list" style={this.props.listStyle}>
                {options && options.length > 0 && options.map(option => {
                    return (
                        <li key={option.value}
                            className={`multiselect-custom-item ${this.isOptionSelected(option) ? 'selected' : ''}`}
                            onClick={() => this.handleOptionClick(option)}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <span>{option.label}</span>
                                <span>{this.isOptionSelected(option) && <img src={checkedIcon} />}</span>
                            </div>
                        </li>
                    )
                })}
            </ul>

            {this.props.error && (<div className="multiselect-custom-error">
                {this.props.errorMessage ? this.props.errorMessage : t('This field is required')}
            </div>)}
        </div>
    }
}

MultiSelect.propTypes = {
    options: PropTypes.array,
    values: PropTypes.array,
    onChange: PropTypes.func,
    t: PropTypes.func,
    style: PropTypes.object,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    width: PropTypes.string,
    listStyle: PropTypes.object
};

export default MultiSelect;