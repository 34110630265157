import React, {Component} from 'react';
import translate from '../../translate';
import TemperatureFeedback from './steps/temperature-feedback';
import FinalResult from './steps/final-result';
import GravityFeedback from './steps/gravity-feedback';
import PropTypes from 'prop-types';
import {merge} from 'lodash';

class RoomFeedback extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentStep: 'temperature-usage-feedback'
        };

        this.steps = ['temperature-usage-feedback', 'gravity-radiators-feedback', 'final-result']

        this.continueNextStep = this.continueNextStep.bind(this);

        this.roomFeedbackAccordeon = React.createRef();
    }

    componentDidMount() {}

    async sendRoomFeedback(results){
        let {
            temperature,
            humidity,
            roomId,
            i18n
        } = this.props;

        let {roomFeedbackId} = await Parse.Cloud.run('send-room-feedback', merge({}, results, {
            language: i18n?.language,
            temperature,
            humidity,
            roomId,
            roomFeedbackId: this.state.roomFeedbackId
        }));

        this.setState({roomFeedbackId});
    }

    continueNextStep(stepName, results){
        let {
            temperatureFeedbackValue,
            usageFeedbackValue,
            gravityFeedbackValue,
            radiatorsFeedbackValue,
            email,
            text
        } = results;

        this.sendRoomFeedback(results);

        let index = this.steps.indexOf(stepName);

        let currentStep = this.steps[index + 1]

        let newState = {
            currentStep,
            temperatureFeedbackValue,
            usageFeedbackValue,
            gravityFeedbackValue,
            radiatorsFeedbackValue,
            email,
            text
        };

        if(currentStep === 'final-result'){
            newState.roomFeedbackId = null;
        }

        this.setState(newState, () => {
            this.roomFeedbackAccordeon.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        })
    }

    render() {
        const {t} = this.props;

        let index = this.steps.indexOf(this.state.currentStep);

        return <div className="informer">
            <div className="accordeon" id='room-feedback-accordeon' ref={this.roomFeedbackAccordeon}>
                <div className={`accordeon__heading ${this.state.roomFeedbackOpen ? 'is-active' : ''}`}
                    onClick={() => this.setState({roomFeedbackOpen: !this.state.roomFeedbackOpen})}
                >
                    <div className="informer__header" >
                        <div className="informer__heading">{t('Give a room feedback')}</div>
                        <div className="informer__desc">
                            {t('Leave your feedback about the comfort of this room.')}
                        </div>
                    </div>
                </div>
                <div className="accordeon__pane">
                    <div className="accordeon__pane-main" style={{overflow: 'hidden'}}>
                        {index === 0 && <TemperatureFeedback continueNextStep={this.continueNextStep}/>}

                        {index === 1 && <GravityFeedback
                            continueNextStep={this.continueNextStep}
                            temperatureFeedbackValue={this.state.temperatureFeedbackValue}
                            usageFeedbackValue={this.state.usageFeedbackValue}
                        />}

                        {index === 2 && <FinalResult
                            continueNextStep={this.continueNextStep}
                            temperatureFeedbackValue={this.state.temperatureFeedbackValue}
                            usageFeedbackValue={this.state.usageFeedbackValue}
                            gravityFeedbackValue={this.state.gravityFeedbackValue}
                            radiatorsFeedbackValue={this.state.radiatorsFeedbackValue}
                        />}
                    </div>
                </div>
            </div>
        </div>;
    }
}

RoomFeedback.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    temperature: PropTypes.number,
    humidity: PropTypes.number,
    roomId: PropTypes.string,
    homeId: PropTypes.string
};

export default translate(RoomFeedback);