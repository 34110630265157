import React, {Component} from 'react';
import PropTypes from 'prop-types';
import translate from '../translate';
import Breadcrumbs from '../breadcrumbs';
import Footer from '../footer';
import paths from '../../lib/paths';
import i18next from '../i18n';
import logoImg from '../../assets/images/logo.png';

class PageStartConfiguration extends Component {
    constructor(props){
        super(props);
    }

    changeLanguage(language){
        i18next.changeLanguage(language);
        document.cookie = 'i18next=' + language;
    }

    render(){
        let {t} = this.props;

        let currentLanguage = this.props.i18n.resolvedLanguage;

        return (
            <div className={'page-start-configuration'}>
                <div className="wrapper d-flex align-items-stretch room-report-container">
                    <div className="content">
                        <nav
                            className="navbar navbar-expand-lg navbar-expand-md navbar-expand-sm navbar-expand-xs navbar-light">
                            <img className="logo__img img-responsive" src={logoImg} alt="logo"
                                title=""/>
                            <div className="container-fluid">
                                <ul className="nav navbar-nav ml-auto">
                                    <li className="nav-item dropdown dropdown-custom language-dropdown
                             d-flex align-items-center">
                                        <a className="nav-link dropdown-toggle" href="#" id="languageDropdown"
                                            role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {this.props.i18n.resolvedLanguage}
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="languageDropdown">
                                            <a className={`dropdown-item ${this.props.i18n.resolvedLanguage === 'en' ? 'selected' : ''}`}
                                                href="#"
                                                onClick={() => this.changeLanguage('en')}>{t('English')}</a>
                                            <a className={`dropdown-item ${this.props.i18n.resolvedLanguage === 'it' ? 'selected' : ''}`}
                                                href="#"
                                                onClick={() => this.changeLanguage('it')}>{t('Italian')}</a>
                                            <a className={`dropdown-item ${this.props.i18n.resolvedLanguage === 'fr' ? 'selected' : ''}`}
                                                href="#"
                                                onClick={() => this.changeLanguage('fr')}>{t('French')}</a>
                                            <a className={`dropdown-item ${this.props.i18n.resolvedLanguage === 'de' ? 'selected' : ''}`}
                                                href="#"
                                                onClick={() => this.changeLanguage('de')}>{t('German')}</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </nav>
                        <div className="inner">
                            <div className="container-fluid">
                                <Breadcrumbs {...this.props} elements={[
                                    {
                                        link: '/page-start-configuration',
                                        name: t('Start')
                                    },
                                    {
                                        link: null,
                                        name: t('First steps')
                                    }
                                ]}/>
                                <section>
                                    <h4 className={'title-item'}>{t('1. Establish network connection')}</h4>
                                    <div className={'text-section'}>
                                        <p>{t('Connect your CLEVERON device to a network. You can find detailed instructions here:')}<br/></p>
                                        <div className={'pdf-download'}>
                                            <div className={'pdfIcon'}>

                                            </div>
                                            <a target="_blank"
                                                rel="noopener noreferrer"
                                                href={`/assets/images/page-start-configuration/Sense_ConnectionGuide_B2C_${currentLanguage?.toUpperCase() ?? 'DE'}.pdf`}>{t('CLEVER Sense First Steps EN.pdf.')}</a>
                                        </div>
                                    </div>
                                </section>

                                <section>
                                    <h4 className={'title-item'}>{t('2. CLEVERON Building Manager')}</h4>
                                    <div className={'text-section'}>
                                        <p>{t('Organize and monitor your CLEVERON devices with the Building Manager App on iOS and Android in your browser on')} <a href={paths.registrationEmail}>{'app.cleveron.ch'}</a></p>
                                    </div>
                                </section>

                                <section className={'section-cleveron-icon'}>
                                    <a className={'cleveron-icon'}></a>
                                </section>
                                <section className={'section-store-icons'}>
                                    <a target="_blank" rel="noopener noreferrer" href={'https://play.google.com/store/apps/details?id=cleveron.buildingmanager'} className={'googleplay-icon'}></a>
                                    <a target="_blank" rel="noopener noreferrer" href={'https://apps.apple.com/us/app/cleveron/id1549851945'} className={'appstore-icon'}></a>
                                </section>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                </div>
            </div>
        );
    }
}

PageStartConfiguration.propTypes = {
    t: PropTypes.func,
    i18n: PropTypes.any
};

export default translate(PageStartConfiguration);