import React, {Component} from 'react';
import Breadcrumbs from '../breadcrumbs';
import Footer from '../footer';
import translate from '../translate';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {merge} from 'lodash';
import Parse from 'parse';
import i18next from '../i18n';
import moment from 'moment';
import {VerifaliaRestClient} from 'verifalia';
import {initializeParse} from '../../lib/parse-utils';
import logoImg from '../../assets/images/logo.png';
import paths from '../../lib/paths';
import db from '../../lib/structure';

const isValidEmail = require('is-valid-email');

const verifalia = new VerifaliaRestClient({
    username: 'fca8acfd21c14cbe84e96ce20a175e63',
    password: 'x3U)vHVgESqU'
});

export class PageQrCodePortalDiscoverCleveronEmail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            roomFeedbackEmailOpen: (new Array(20)).fill(false),
            email: localStorage.getItem('room-feedback-email'),
            validEmail: !!localStorage.getItem('room-feedback-email'),
            emailTouched: !!localStorage.getItem('room-feedback-email'),
            emailLoading: false,
            documentationLoading: false,
            validatedEmail: localStorage.getItem('room-feedback-email'),
            validationEmailTexts: [],
            documentations: []
        };

        this.validateEmail = this.validateEmail.bind(this);
        this.loadDocumentations = this.loadDocumentations.bind(this);
    }

    async componentDidMount() {
        try {
            await initializeParse(Parse);

            this.loadDocumentations();

            this.setState({loading: true});

        } catch (err) {
            console.error(err);
        }
        this.setState({loading: false});
    }

    changeLanguage(language) {
        i18next.changeLanguage(language);
        moment.locale(language);
        document.cookie = 'i18next=' + language;
    }

    async validateEmail(e){
        if(this.state.validEmail && this.state.validatedEmail === this.state.email)
            return;

        let email;
        if(e) email = e.target.value;
        else email = this.state.email;

        if(email === '' || email == null)
            return this.setState({validEmail: false, emailTouched: true});

        this.setState({emailLoading: true});

        if(!isValidEmail(email))
            return this.setState({validEmail: false, emailTouched: true, emailLoading: false, validationEmailTexts: [
                'The email is invalid. Please check that is contains the symbol "@" and that the domain refers to a valid mailbox.',
            ]});

        let result = await verifalia
            .emailValidations
            .submit(email, true);

        let classification = result.entries[0].classification;

        if(classification === 'Deliverable' || classification === 'Risky') {
            this.setState({validEmail: true, emailTouched: true, emailLoading: false, validatedEmail: email});
            localStorage.setItem('room-feedback-email', email);
        } else {
            this.setState({validEmail: false, emailTouched: true, emailLoading: false, validationEmailTexts: [
                'The email is invalid. Please check that is contains the symbol "@" and that it refers to a valid mailbox.',
                'Contact our <a href="mailto:support@cleveron.ch">support</a> if your e-mail was not recognised as valid.'
            ]});
        }
    }

    async loadDocumentations(){
        this.setState({documentationLoading: true});
        let response = await Parse.Cloud.run(
            'crm-documentations',
            Object.fromEntries(new URLSearchParams(this.props.history.location.search))
        );

        this.setState({documentations: response.documentations, documentationLoading: false});
    }

    render() {
        const {t} = this.props;

        let emailField = () => {
            return <div className={`input-field ${!this.state.validEmail  && this.state.emailTouched ? 'error' : ''}`}>
                <label htmlFor="field-4">{t('E-mail')}<span>*</span>
                </label>
                <div className="input-field__input">
                    <input className="input" type="email" name="text-field" placeholder={t('Please enter your e-mail')} id="field-4" required
                        value={this.state.email}
                        onChange={(e) => {
                            this.setState({email: e.target.value});
                        }}
                        onKeyPress={async (e) => {
                            if(e.key === 'Enter') {
                                e.preventDefault();
                                await this.validateEmail(e);
                            }
                        }}
                        onBlur={this.validateEmail}
                    />
                    {
                        !this.state.validEmail && !this.state.emailLoading && this.state.emailTouched && <svg className="icon icon-error-status input-notification__icon">
                            <use href="/assets/images/sprite.svg#error-status"></use>
                        </svg>
                    }
                    {
                        this.state.emailLoading && <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                            viewBox="0 0 100 100" enableBackground="new 0 0 100 100" className={'icon-loader'}>
                            <path fill="grey" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                <animateTransform
                                    attributeName="transform"
                                    attributeType="XML"
                                    type="rotate"
                                    dur="1s"
                                    from="0 50 50"
                                    to="360 50 50"
                                    repeatCount="indefinite" />
                            </path>
                        </svg>
                    }
                </div>
                {
                    this.state.email !== '' && !this.state.validEmail  && this.state.emailTouched && <small className="input-notification">
                        {this.state.validationEmailTexts.map((message, i) => <span key={i}>{t(message)}&nbsp;</span>)}
                    </small>
                }

                {
                    (!this.state.email || !this.state.validEmail) && <div className="input-field__input mt-10">
                        <input
                            type={'button'}
                            onClick={async (e) => {
                                e.preventDefault();
                                await this.validateEmail();
                            }}
                            className="btn btn--primary"
                            name="send_data"
                            value={t('Verify')}
                        />
                    </div>
                }

            </div>
        }

        let languages = {
            it: 'Italian',
            en: 'English',
            fr: 'French',
            de: 'German'
        }

        let documentations = this.state.documentations
            .filter(documentation => documentation[db.Documentations.STATUS] === db.Documentations.STATUS$ONLINE)
            .filter(documentation =>
                documentation[db.Documentations.VISIBLE_ON].indexOf(db.Documentations.VISIBLE_ON$QR_CODE) >= 0);

        return (
            <div className="wrapper d-flex align-items-stretch room-report-container">
                {/* eslint-disable max-len */}
                <div className="content">
                    <nav
                        className="navbar navbar-expand-lg navbar-expand-md navbar-expand-sm navbar-expand-xs navbar-light">
                        <img className="logo__img img-responsive" src={logoImg} alt="logo"
                            title="" 
                            onClick={() => {
                                this.props.history.push(
                                    paths.roomReport.replace(
                                        ':id', localStorage.getItem('roomFeedbackRoomId')
                                    ));
                            }}
                        />
                        <div className="container-fluid">
                            <ul className="nav navbar-nav ml-auto">
                                <li className="nav-item dropdown dropdown-custom language-dropdown
                             d-flex align-items-center">
                                    <a className="nav-link dropdown-toggle" href="#" id="languageDropdown"
                                        role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {this.props.i18n.resolvedLanguage}
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="languageDropdown">
                                        <a className={`dropdown-item ${this.props.i18n.resolvedLanguage === 'en' ? 'selected' : ''}`}
                                            href="#"
                                            onClick={() => this.changeLanguage('en')}>{t('English')}</a>
                                        <a className={`dropdown-item ${this.props.i18n.resolvedLanguage === 'it' ? 'selected' : ''}`}
                                            href="#"
                                            onClick={() => this.changeLanguage('it')}>{t('Italian')}</a>
                                        <a className={`dropdown-item ${this.props.i18n.resolvedLanguage === 'fr' ? 'selected' : ''}`}
                                            href="#"
                                            onClick={() => this.changeLanguage('fr')}>{t('French')}</a>
                                        <a className={`dropdown-item ${this.props.i18n.resolvedLanguage === 'de' ? 'selected' : ''}`}
                                            href="#"
                                            onClick={() => this.changeLanguage('de')}>{t('German')}</a>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </nav>
                    <div className="inner">
                        <div className="container-fluid">
                            <Breadcrumbs {...this.props} elements={[
                                {
                                    link: paths.roomReport.replace(':id', localStorage.getItem('roomFeedbackRoomId')),
                                    name: t('Room report page')
                                },
                                {
                                    link: paths.roomFeedbackDiscoverCleveron,
                                    name: t('Discover CLEVERON')
                                },
                                {
                                    link: paths.roomFeedbackDiscoverCleveronFaq,
                                    name: t('FAQ')
                                }
                            ]}/>

                            <h1>{t('Frequently Asked Questions')}</h1>

                            {
                                this.state.documentationLoading === false && documentations.length === 0 &&
                                <p>{t('No documentation found.')}</p>
                            }

                            {
                                this.state.documentationLoading === false && documentations
                                    .map((documentation, i) => {
                                        return  <div key={i} className="informer">
                                            <div className="accordeon" id={`room-feedback-accordeon-${i}`}>
                                                <div className={`accordeon__heading ${this.state.roomFeedbackEmailOpen[i] ? 'is-active' : ''}`}
                                                    onClick={() => {
                                                        if(this.state.email && this.state.validEmail){
                                                            Parse.Cloud.run('zoho-flow-execute-hook', {
                                                                email: this.state.email,
                                                                user_type_qr: localStorage.getItem('roomFeedbackUserType'),
                                                                building_id: localStorage.getItem('roomFeedbackHomeId'),
                                                                room_id: localStorage.getItem('roomFeedbackRoomId')
                                                            });
                                                            return window.open(documentation[`${db.Documentations.LINK}${languages[this.props.i18n.resolvedLanguage]}`], '_blank').focus();
                                                        }

                                                        this.setState(prev => {
                                                            prev.roomFeedbackEmailOpen[i] =  !prev.roomFeedbackEmailOpen[i];
                                                            return prev;
                                                        });
                                                    }}
                                                >
                                                    <div className="informer__header" >
                                                        <div className="informer__heading">{documentation[`${db.Documentations.TITLE}${languages[this.props.i18n.resolvedLanguage]}`]}</div>
                                                        <div className="informer__desc">
                                                            {documentation[`${db.Documentations.SUBTITLE}${languages[this.props.i18n.resolvedLanguage]}`]}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordeon__pane">
                                                    <div className="accordeon__pane-main" style={{overflow: 'hidden'}}>
                                                        <form id='temp-usage-form' style={{
                                                            background: 'white'
                                                        }}
                                                        className={'informer__feedback room-feedback'}
                                                        onSubmit={e => {e.preventDefault()}}
                                                        name="temp-usage-form">
                                                            {emailField()}

                                                            {
                                                                this.state.email && this.state.validEmail && <input onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState(prev => {
                                                                        prev.roomFeedbackEmailOpen[i] =  !prev.roomFeedbackEmailOpen[i];
                                                                        return prev;
                                                                    });
                                                                    Parse.Cloud.run('zoho-flow-execute-hook', {
                                                                        email: this.state.email,
                                                                        user_type_qr: localStorage.getItem('roomFeedbackUserType'),
                                                                        building_id: localStorage.getItem('roomFeedbackHomeId'),
                                                                        room_id: localStorage.getItem('roomFeedbackRoomId')
                                                                    });
                                                                    window.open(documentation[`${db.Documentations.LINK}${languages[this.props.i18n.resolvedLanguage]}`], '_blank').focus();
                                                                }}
                                                                className="btn btn--primary"
                                                                type="submit" name="send_data"
                                                                value={t('Go to link')}
                                                                />
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                            }
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}

PageQrCodePortalDiscoverCleveronEmail.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired,
    match: PropTypes.any
};

const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(PageQrCodePortalDiscoverCleveronEmail));