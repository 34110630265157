let steps = {
    temperature_feedback: {
        title: 'How is the temperature in the room?',
        text: '',
        options: {
            TOO_COLD: 'tooCold',
            TOO_HOT: 'tooHot',
            COMFORTABLE: 'comfortable'
        },
        button: 'Send',
        next: 'usage_feedback'
    },
    usage_feedback: {
        pre_text: 'Thanks! The system will already react in the next minutes.',
        title: 'How long will you use this room?',
        options: {
            SHORT_USAGE: 'shortUsage',
            MEDIUM_USAGE: 'mediumUsage',
            LONG_USAGE: 'longUsage',
            RECURRING_WEEKLY: 'recurringWeekly'
        },
        text: {},
        button: 'Send',
        next: 'gravity_feedback'
    },
    gravity_feedback: {
        pre_text: {
            thanks: 'Thanks for your feedback! The system will react during the period you specified. Please re-scan this QR code if the temperature is not comfortable in the next 1 hour.',
            wantToContinue: 'Is not the first time that this happens? Do you want to analyse better the situation? Please continue with the next 2 steps (2 mins)'
        },
        title: {
            tooCold: 'How cold is in the room more precisely?',
            tooHot: 'How hot is in the room more precisely?'
        },
        options: {
            tooCold: {
                VERY: 'very',
                BUT_I_CAN_CONTINUE: 'butICanContinue',
                BUT_OK_TO_CHANGE_MAX_TEMP: 'butOkToChangeMaxTemp'
            },
            tooHot: {
                VERY: 'very',
                BUT_I_CAN_CONTINUE: 'butICanContinue',
                BUT_OK_TO_CHANGE_MAX_TEMP: 'butOkToChangeMaxTemp'
            }
        },

        options_old: {
            1: [
                {
                    value: 1,
                    label: 'Very cold I should wear a jacket',
                },
                {
                    value: 2,
                    label: 'Cold but I can continue my activity.',
                },
                {
                    value: 3,
                    label: 'Cold and I want you to increase the temperature of the room continuously by 1° and monitor the situation in the next period.',
                }
            ],
            2: [
                {
                    value: 1,
                    label: 'Very hot, I have to open the windows for very long time.',
                },
                {
                    value: 2,
                    label: 'Hot but I can continue my activity for now.',
                },
                {
                    value: 3,
                    label: 'Hot and I want you to decrease the temperature of the room continuously by -1°. I will monitor the situation and scan again if this didn t helped.',
                }
            ],
        },
        text: {
            tooCold: {
                very: 'Very cold I should wear a jacket',
                butICanContinue: 'Cold but I can continue my activity.',
                butOkToChangeMaxTemp: 'Cold but I\'m okay to increase the temperature configured on this room by 1° and monitor the situation in the next hours/days.'
            },
            tooHot: {
                very: 'Very hot, I have to open the windows for very long time.',
                butICanContinue: 'Hot but I can continue my activity for now.',
                butOkToChangeMaxTemp: 'Hot but I\'m okay to decrease the temperature configured on this room by 1° and monitor the situation in the next hours/days.'
            },
            thanks: {
                very: 'Thanks for the additional information provided! It s urgent? Do you want to open a ticket case on our customer support? Send an email to <a href="mailto: support@cleveron.ch">support@cleveron.ch</a>, a person from our staff will evaluate the situation and respond to do you as soon as possible.',
                butICanContinue: 'Thanks for the additional information provided! This will help us to improve the system and react in case of anomalies. Please re-scan the QR code if the situation is not getting better in the next hours.',
                butOkToChangeMaxTemp: 'Thanks for the additional information provided! We have modified the temperature in this room. Please re-scan the QR code if the situation is not getting better in the next hours / days.'
            }
        },
        button: 'Send',
        next: 'radiators_feedback'
    },
    radiators_feedback: {
        pre_text: {
            1: {
                1: 'It s urgent? Do you want to open a ticket case on our customer support? Send an email to <a href="mailto: support@cleveron.ch">support@cleveron.ch</a> person from our staff will evaluate the situation e respond you in the next 2h.',
                2: '',
                3: 'Thanks! We have modified the temperature in this room. Please rescan the QR code if is still not comfortable in the future.'
            },
            2: {
                3: 'Thanks! We have modified the temperature in this room. Please rescan the QR code if is still not comfortable in the future.'
            }
        },
        title: 'How many radiators are warm?',
        options: {
            ALL: 'all',
            NONE: 'none',
            SOME: 'some'
        },
        text: {
            all: 'All',
            none: 'None',
            some: 'Some'
        },
        button: 'Send',
        next: 'final'
    },
    result: {
        results: [
            'Il disppositivo non si connette da più di 1 giorno. Per favore controllare che il dispositivo sia acceso. Importante! Non smontare il dispositivo. In caso di difficoltà o dubbi contattare il responsabile.',
            'The radiator 2100455 has low battery. Please charge it with a micro-usb like explained in this guide, without to un-mount it. Ask the building administraor is is not posible without demounting it.',
            'The radiator 2100455 has low signal strength so it may have difficulties to connect. Please note that a temperature of 23° is assured even if no connection is available but we advise to contacting the buildign administrator to improve the wifi coverage in this room.',
            'The maximal temperature for this room is XX°. Please contact the administrator if this problem persist.',
            'We have resetted and recalibrated the device so the temperature should be more comfortable in the next hour',
            'The system seems seems to have reacted correctly with the data we have. Please do a re-calibration if the problem persists.',
            'Please check with your building responsible. Possible problems at the radiators or at the central heating system.',
            'Are you still not content? You can open a ticket if the situation is urgent.'
        ],
        feedback: [
            'Was this process user friendly and clear? 0-10',
            'Do you have improvements to suggest? Textarea',
            'How likely you would reccomend CLEVERON to a friend or collegue? 0-10',
            'How would your rate your overall comfort until now? 0-10'
        ],
        button: 'Send'
    }
}

module.exports = steps;