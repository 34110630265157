'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import db from '../../lib/structure';
import infoIcon from '../../assets/images/ic_info.svg';
import successIcon from '../../assets/images/ic_diagnostics_success.svg';
import warningIcon from '../../assets/images/ic_diagnostics_warning.svg';
import errorIcon from '../../assets/images/ic_diagnostics_error.svg';
import {requestCalendar} from '../page-room/room-utils';
import CardRoomCalendar from '../page-room/room-calendar';
import moment from 'moment';
import {convertColorsToIconTag} from "../../lib/util";

class DiagnosticResultItemProgramAndEvents extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            supportRequests: [],
            events: []
        };
    }

    async componentDidMount() {
        let supportRequest = this.props.diagnosticRequest.get(db.DiagnosticRequest.SUPPORT_REQUEST);
        let dateOfProblem = supportRequest.get(db.SupportRequest.DATE_OF_PROBLEM);
        let room = supportRequest.get(db.SupportRequest.ROOM);
        let home = room.get(db.Room.HOME);

        let events = await requestCalendar(room.id, home.id, null, null, moment(dateOfProblem));

        this.setState({supportRequest, events, room});
    }

    render() {
        const {t, diagnosticRequest} = this.props;

        let eventsStatus = diagnosticRequest.get(db.DiagnosticRequest.EVENTS_STATUS);

        let item = {
            'title': 'Program & Events',
            'description': eventsStatus?.comment?.[this.props.i18n.resolvedLanguage],
            'icon': convertColorsToIconTag(eventsStatus.statusLabel), //info,success,warning,danger
            'infoMessage': '',
            'warningMessage': ''
        };
        return <div>
            <div className={'system-diagnostics-result-item'}>
                <div className='system-diagnostics-result-item-header'>
                    <div className='system-diagnostics-result-item-group'>
                        <div style={{alignSelf: 'center'}}>
                            {item.icon === 'info' && <img src={infoIcon} className='system-diagnostics-result-item-icon'/>}
                            {item.icon === 'success' && <img src={successIcon} className='system-diagnostics-result-item-icon'/>}
                            {item.icon === 'warning' && <img src={warningIcon} className='system-diagnostics-result-item-icon'/>}
                            {item.icon === 'danger' && <img src={errorIcon} className='system-diagnostics-result-item-icon'/>}
                        </div>
                        <div className='system-diagnostics-result-item-status'>
                            <div className='system-diagnostics-result-item-title'>
                                {t(item.title)}
                            </div>
                            <div className='system-diagnostics-result-item-description'>
                                {t(item.description)}
                            </div>
                        </div>
                    </div>
                    <div className='system-diagnostics-result-item-view-details'>
                        <a onClick={(e) => {
                            e.preventDefault();
                            this.setState(prevState => {
                                return {
                                    viewDetailedOpened:  !prevState.viewDetailedOpened
                                };
                            });
                        }} className='system-diagnostics-result-item-details-label'>{t('View details')}</a>

                        <a onClick={(e) => {
                            e.preventDefault();
                            this.setState(prevState => {
                                return {
                                    viewDetailedOpened:  !prevState.viewDetailedOpened
                                };
                            });
                        }} className={`system-diagnostics-result-item-details-icon arrow ${this.state.viewDetailedOpened ? 'is-active' : ''}`}></a>
                    </div>
                </div>
                {this.state.viewDetailedOpened && (<div className='system-diagnostics-result-item-details'>
                    {item.infoMessage && <div className='system-diagnostics-result-item-details-info'>
                        {t(item.infoMessage)}
                    </div>}
                    {item.warningMessage && <div className='system-diagnostics-result-item-details-warning'>
                        {t(item.warningMessage)}
                    </div>}
                    {item.buttons && <div className='system-diagnostics-result-item-details-buttons'>
                        {item.buttons.map((button, index) => {
                            return <a key={index}
                                target='_blank'
                                href={button.url}
                                className='system-diagnostics-result-item-details-button' rel="noreferrer">
                                {t(button.label)}
                            </a>;
                        })}
                    </div>}
                    {/*
                        this.props.diagnosticRequest
                            .get(db.DiagnosticRequest.SUPPORT_REQUEST)
                            .get(db.SupportRequest.ROOM) && <RoomProgram t={t}
                            roomId={this.props.diagnosticRequest.get(db.DiagnosticRequest.SUPPORT_REQUEST).get(db.SupportRequest.ROOM).id}
                            room={this.props.diagnosticRequest.get(db.DiagnosticRequest.SUPPORT_REQUEST).get(db.SupportRequest.ROOM)}
                            onRoomDetailSaved={() => {
                                console.log('we');
                            }}
                        />
                    */}
                    {
                        this.state.events.length === 0 && <CardRoomCalendar
                            t={t}
                            events={this.state.events}
                            room={this.state.room}
                            i18n={this.props.i18n}
                            style={{display: 'block', width: '100%'}}
                        />
                    }
                </div>
                )}
            </div>
        </div>
    }
}

DiagnosticResultItemProgramAndEvents.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    history: PropTypes.any.isRequired,
    diagnosticRequest: PropTypes.object,
    refresh: PropTypes.func.isRequired
};

export default DiagnosticResultItemProgramAndEvents;