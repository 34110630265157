import React from 'react';
import PropTypes from 'prop-types';
import diagnosticsIcon from '../../assets/images/ic_diagnostics.svg';
import FakeDiagnosticsProgressBar from './fake-diagnostics-progress-bar';

export default function DiagnosticsStarter(props) {
    const {t, loading, onStart} = props;

    return (
        <div style={{height: '600px'}}>
            <div className='card card-outer h-100'>
                <div className='system-diagnostics-starter-wrapper'>
                    <div className='system-diagnostics-starter-icon'>
                        <img src={diagnosticsIcon} />
                    </div>
                    <div className='system-diagnostics-starter-title'>
                        {!loading && <span>{t(props.labels?.description || 'Run diagnostics for your building to check all systems')}</span>}
                        {loading && <span>{props.labels?.title || t('We\'re are running diagnostics for your building')}</span>}
                    </div>
                    <div className='system-diagnostics-starter-comment'>
                        {loading && <span>{t('This may take a few minutes')}</span>}
                    </div>
                    <div className='system-diagnostics-starter-button'>
                        {!loading &&
                        <button className='btn btn-green' onClick={onStart}>{t('Run diagnostics')}</button>}
                    </div>
                    {loading && <FakeDiagnosticsProgressBar t={t} />}
                </div>
            </div>
        </div>

    )
}

DiagnosticsStarter.propTypes = {
    t: PropTypes.func,
    onStart: PropTypes.func,
    loading: PropTypes.bool,
    labels: PropTypes.object
};
