import React, {Component} from 'react';
import Parse from 'parse';
import PropTypes from 'prop-types';
import translate from '../translate';
import {get} from 'lodash';
import i18next from './../i18n';
import swal from 'sweetalert';
import {toPointerFromId} from '../../lib/util';
import * as db from '../../lib/structure';
import Loader from '../loader';

class PageFeedback extends Component {

    constructor(props){
        super(props);

        this.state = {
            roomId: this.props.match.params.id,
            room: null,
            roomName: '',
            temperature: 0,
            humidity: 0,
            email: '',
            text: '',
            buttonStatus: true,
            dropDownValue: -1,
            dropDownText: '',
            selectTypeActive: false,
            types: [],
            loading: true,
        };

        this.changeLanguage = this.changeLanguage.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.getRoomFromId = this.getRoomFromId.bind(this);
        this.showNoDetailsFound = this.showNoDetailsFound.bind(this);
    }

    componentDidMount(){
        this.refreshLanguage();
        this.getRoomInfo();
        this.getRoomFromId();
    }

    async getRoomFromId(){
        try {
            let query = new Parse.Query('Room');
            query.include(db.Room.HOME);
            let room = await query.get(this.props.match.params.id);
            this.setState({room});
        } catch (e) {
            console.error(e);
        }
    }

    refreshLanguage(){
        const {t} = this.props;
        let selectFeedback = t('Select feedback');
        let comfortable = t('Comfortable');
        let tooWarm = t('Too warm');
        let tooCold = t('Too cold');

        this.setState({
            dropDownText: selectFeedback,
            types: [
                {
                    text: comfortable,
                    value: 0
                },
                {
                    text: tooWarm,
                    value: 1
                },
                {
                    text: tooCold,
                    value: 2
                }
            ]
        });
    }

    changeLanguage(language){
        i18next.changeLanguage(language);
        document.cookie = 'i18next=' + language;
        this.refreshLanguage();
    }

    async handleSubmit(e){
        e.preventDefault();
        const {t} = this.props;
        let swalTitle = `${t('Missing input Information')}`;

        if (this.state.dropDownValue === -1) {
            let swalText = `${t('Please select a feedback value from the dropdown menu')}`;
            swal(swalTitle, swalText, 'error');
        } else if (this.state.email === '' && this.state.text !== '') {
            let swalText = `${t('Please enter a valid email address')}`;
            swal(swalTitle, swalText, 'error');
        } else {
            await this.sendData();
        }
    }

    handleDropDownChange(e){
        this.setState({
            dropDownText: e.target.text,
            dropDownValue: e.target.value
        });
    }

    async sendData(){
        const {t} = this.props;
        try {
            let RoomFeedback = Parse.Object.extend('RoomFeedback');
            let roomFeedback = new RoomFeedback();

            roomFeedback.set('text', this.state.text);
            roomFeedback.set('email', this.state.email);
            roomFeedback.set('temperature', this.state.temperature);
            roomFeedback.set('humidity', this.state.humidity);
            roomFeedback.set('room', toPointerFromId(this.state.roomId, db.classes.Room));
            roomFeedback.set('selectText', this.state.dropDownText);
            roomFeedback.set('feedbackValue', Number(this.state.dropDownValue));
            roomFeedback.set('language', this.props.i18n.resolvedLanguage);
            await roomFeedback.save();

            this.setState({
                email: '',
                text: '',
                dropDownValue: -1,
                dropDownText: 'Select feedback',

            });

            let swalText = t('Thank you for your feedback!');
            swal(swalText, '', 'success');
        } catch (e) {
            console.error(e);
        }
    }

    handleInputChange(e){
        let name = e.target.name;
        let value = e.target.value;
        this.setState({[name]: value});
    }

    async getRoomInfo(){
        try {

            this.setState({loading: true});
            let result = await Parse.Cloud.run('client-room-roomNameFromId', {roomId: this.state.roomId});
            let roomMeasurements = await Parse.Cloud.run('client-room-lastTemperatureAndHumidityRoomOpen', {roomId: this.state.roomId});

            this.setState({
                roomName: result.roomName,
                homeName: result.homeName,
                temperature: roomMeasurements.tempRoom,
                humidity: roomMeasurements.humRoom,
                loading: false,
            });
        } catch (e) {
            console.error(e);
            this.setState({loading: false});
        }

    }

    showNoDetailsFound(){
        const {t} = this.props;
        let message = t('No data found for room ');

        return <table className="table mobile-white-text">
            <tbody>
                <tr>
                    <td>{t('Building')}</td>
                    <td>
                        {
                            this.state.homeName
                        }
                    </td>
                </tr>
                <tr>
                    <td>{t('Room Name')}</td>
                    <td>{this.state.roomName}</td>
                </tr>
                <tr>
                    <td>{t('Temperature')}</td>
                    <td>{message}</td>
                </tr>
                <tr>
                    <td>{t('Humidity')}</td>
                    <td>{message}</td>
                </tr>
            </tbody>
        </table>;
    }

    showRoomDetails(){
        const {t} = this.props;

        return (
            <table className="table mobile-white-text">
                <tbody>
                    <tr>
                        <td>{t('Building')}</td>
                        <td>
                            {
                                this.state.homeName
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>{t('Room Name')}</td>
                        <td>{this.state.roomName}</td>
                    </tr>
                    <tr>
                        <td>{t('Temperature')}</td>
                        <td>{this.state.temperature.toFixed(1)}°C</td>
                    </tr>
                    <tr>
                        <td>{t('Humidity')}</td>
                        <td>{this.state.humidity.toFixed(1)}%</td>
                    </tr>
                </tbody>
            </table>
        )

    }

    render(){
        const {t} = this.props;

        const disabled = this.state.email === '' || this.state.dropDownValue === -1;
        let showTable = true;

        if (!this.state.roomName) {
            return (
                <div className={'feedback-page'}>
                    {
                        get(this.props, 'shared.generalError.visible') && <div className={'alert alert-danger'}>
                            {get(this.props, 'shared.generalError.message')}
                        </div>
                    }
                    <div className={'back-image'}/>
                    <a className="auth-logo" onClick={() => this.props.history.push('/')}
                        href="#">{t('Cleveron')}</a>
                    <div className="card auth-card card-no-data">
                        <div className="card-body">
                            <div className="m-b-36">
                                <div className="dropdown pull-right auth-dropdown">
                                    <button className="dropdown-toggle mobile-white-text" type="button"
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        {this.props.i18n.resolvedLanguage}
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a className="dropdown-item" href="#"
                                            onClick={() => this.changeLanguage('en')}>{t('English')}</a>
                                        <a className="dropdown-item" href="#"
                                            onClick={() => this.changeLanguage('it')}>{t('Italian')}</a>
                                        <a className="dropdown-item" href="#"
                                            onClick={() => this.changeLanguage('fr')}>{t('French')}</a>
                                        <a className="dropdown-item" href="#"
                                            onClick={() => this.changeLanguage('de')}>{t('German')}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="header">
                                {this.state.loading ? (
                                    <div>
                                        <div className="title mobile-white-text">{t('Feedback')}</div>
                                        <div
                                            className="subtitle mobile-white-text">{t('Please tell us about the room temperature')}</div>
                                        <br/><br/>
                                        <Loader/>
                                    </div>

                                ) : (
                                    <div className="title mobile-white-text">{t('Room not found')}</div>
                                )}
                                <br/><br/><br/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (!this.state.temperature && !this.state.humidity) {
            showTable = false;
        }

        return (
            <div className={'feedback-page'}>
                {
                    get(this.props, 'shared.generalError.visible') && <div className={'alert alert-danger'}>
                        {get(this.props, 'shared.generalError.message')}
                    </div>
                }
                <div className={'back-image'}/>
                <a className="auth-logo" onClick={() => this.props.history.push('/')}
                    href="#">{t('Cleveron')}</a>
                <div className="card auth-card card-feedback">
                    <div className="card-body">
                        <div className="m-b-36">
                            <div className="dropdown pull-right auth-dropdown">
                                <button className="dropdown-toggle mobile-white-text" type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    {this.props.i18n.resolvedLanguage}
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item" href="#"
                                        onClick={() => this.changeLanguage('en')}>{t('English')}</a>
                                    <a className="dropdown-item" href="#"
                                        onClick={() => this.changeLanguage('it')}>{t('Italian')}</a>
                                    <a className="dropdown-item" href="#"
                                        onClick={() => this.changeLanguage('fr')}>{t('French')}</a>
                                    <a className="dropdown-item" href="#"
                                        onClick={() => this.changeLanguage('de')}>{t('German')}</a>
                                </div>
                            </div>
                        </div>
                        <div className="header">
                            <div className="title mobile-white-text">{t('Feedback')}</div>
                            <div className="subtitle mobile-white-text">{t('Please tell us about the room temperature')}
                                <br/><br/><br/>
                            </div>
                            <div className="title mobile-white-text">
                                {t('Room measurements:')}
                            </div>
                            {showTable && this.showRoomDetails()}
                            {!showTable && this.showNoDetailsFound()}
                        </div>
                        <form className="p-b-32" onSubmit={this.handleSubmit}>

                            <div className={`select-custom ${this.state.selectTypeActive ? 'active' : ''}`}
                                onClick={() => this.setState({
                                    selectTypeActive: !this.state.selectTypeActive
                                })}
                            >
                                <div className="select-custom-status dropdown-format">{this.state.dropDownText}</div>
                                <ul className="select-custom-list dropdown-format">
                                    {
                                        this.state.types.map(type => {
                                            return <li key={type.value}
                                                className="select-custom-item"
                                                onClick={() => this.handleDropDownChange({
                                                    target: {
                                                        text: type.text,
                                                        value: type.value
                                                    }
                                                })}
                                            >{type.text}</li>
                                        })
                                    }
                                </ul>
                            </div>

                            <br/>

                            <div className="form-group">
                                <input type="email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleInputChange}
                                    placeholder={t('E-Mail')}
                                />
                            </div>
                            <div className="input-group m-b-32">
                                <textarea className="form-control" id="textarea" style={{height: '100px'}}
                                    name="text"
                                    placeholder={t('Enter your feedback')}
                                    value={this.state.text}
                                    onChange={this.handleInputChange}/>
                            </div>
                            <button type="submit" className="btn"
                                disabled={disabled}
                            >
                                {t('Submit')}
                            </button>
                        </form>

                    </div>
                </div>
            </div>

        )

    }
}

PageFeedback.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    history: PropTypes.any.isRequired,
    match: PropTypes.any
};

export default translate(PageFeedback);