import React from 'react';
import PropTypes from 'prop-types';
import lowBatteryIcon from '../../../assets/images/ic_low-battery-tooltip.svg';

export default function LowBatteryTooltip(props) {
    const {t, show} = props;

    return (
        <div style={{position: 'relative'}}>
            <div style={{display: show ? 'block' : 'none', position: 'absolute', top: '-50px', right: '50px', zIndex: 3000}} className='low-battery'>
                <div>
                    <div className='low-battery-icon'><img src={lowBatteryIcon} /></div>
                    <h2>{t('Low Battery')}</h2>
                    <div className='low-battery-text'>{t('The device will maintain a confortable temperature until it will be recharged.')}</div>
                    <div className='low-battery-percentage'>{`${props.percentage}%`} {t('remaining')}</div>
                </div>
            </div>
        </div>
    )
}

LowBatteryTooltip.propTypes = {
    t: PropTypes.func,
    show: PropTypes.bool,
    percentage: PropTypes.number
};