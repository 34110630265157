import React, {Component} from 'react';
import PropTypes from 'prop-types';
import db from '../../lib/structure.js';
import Parse from 'parse';
import Loader from '../loader';
import {getLastMonthNumberWithYear, redirectIfNotLogged, toPointerFromId, truncate} from '../../lib/util';
import MultiSelect from '../multiselect';
import RoomReportingDatePicker from '../page-room/room-reporting-date-picker';

import tempEmpty from '../../assets/images/indicators/temp-empty.svg';
import tempWeekends from '../../assets/images/indicators/temp-weekends.svg';
import timeHumidityLow from '../../assets/images/indicators/time-hum-low.svg';
import co2Occupied from '../../assets/images/indicators/co2-occupied.svg';
import timeHumidityHigh from '../../assets/images/indicators/time-hum-high.svg';
import tempDay from '../../assets/images/indicators/temp-day.svg';
import tempNight from '../../assets/images/indicators/temp-night.svg';
import tempOccupied from '../../assets/images/indicators/temp-occupied.svg';
import swal from 'sweetalert';
import moment from 'moment';
import {MONTHLY_REPORT_CUSTOM_SELECTED_COLUMNS} from '../../lib/localStorageStructure';
function getColorFromCustomThresholds(co2, customThresholds,){
    let colorName;
    if(customThresholds){
        let co2Thresholds = customThresholds.map(threshold => threshold.threshold).slice(0, 2);
        for(const [i, co2Threshold] of co2Thresholds.entries()) {
            if(co2 <= co2Threshold) {
                colorName = customThresholds[i].colorName;
                break;
            }
        }

        if(!colorName) colorName = customThresholds[2].colorName;
    } else {
        if(co2 < 1000)
            colorName = db.Device.FORCE_LED_COLOR$GREEN;
        else if (co2 < 1500)
            colorName = db.Device.FORCE_LED_COLOR$YELLOW;
        else
            colorName = db.Device.FORCE_LED_COLOR$RED;
    }

    return colorName;
}

function getIconFromColorName(colorName, co2){
    if(colorName === db.Device.FORCE_LED_COLOR$GREEN)
        return <span>{co2} ppm <div className={'env-indicator-circle env-indicator-circle-green'}></div></span>;
    else if(colorName === db.Device.FORCE_LED_COLOR$YELLOW)
        return <span>{co2} ppm <div className={'env-indicator-circle env-indicator-circle-yellow'}></div></span>;
    else if(colorName === db.Device.FORCE_LED_COLOR$TURNED_OFF)
        return <span>{co2} ppm <div className={'env-indicator-circle env-indicator-circle-grey'}></div></span>;
    else if(colorName === db.Device.FORCE_LED_COLOR$RED)
        return <span>{co2} ppm <div className={'env-indicator-circle env-indicator-circle-red'}></div></span>;

    return <span>{co2} ppm <div className={'env-indicator-circle env-indicator-circle-white'}></div></span>;
}

function format(key, value, props, home){
    let customThresholds = home.get(db.Home.THRESHOLDS);

    let map = {
        [db.RoomMonthlyReport.ROOM]: room => <a href="#"
            className="ml-auto align-items-center link" onClick={() => props.history.push(`/room/${room.id}`)}>
            {room.get(db.Room.ROOM_NAME)}
        </a>,
        [db.RoomMonthlyReport.AVERAGE_CO_WHEN_OCCUPIED]: co2 => {
            if(!co2) return '-';

            co2 = Math.round(co2);

            let colorName = getColorFromCustomThresholds(co2, customThresholds);

            return getIconFromColorName(colorName, co2);
        },
        [db.RoomMonthlyReport.HUM_HIGH_RATIO]: (value) => `${value == null || isNaN(value) ? 'N/A' : Math.round(value, 1)} h`,
        [db.RoomMonthlyReport.HUM_LOW_RATIO]: (value) => `${value == null || isNaN(value) ? 'N/A' : Math.round(value, 1)} h`,
        [db.RoomMonthlyReport.AVERAGE_TEMP_WHEN_EMPTY]: (value) => `${value == null || isNaN(value) ? 'N/A' : value.toFixed(1)} °C`,
        [db.RoomMonthlyReport.AVERAGE_TEMP_WEEKEND]: (value) => `${value == null || isNaN(value) ? 'N/A' : value.toFixed(1)} °C`,
        [db.RoomMonthlyReport.AVERAGE_TEMP_DAY]: (value) => `${value == null || isNaN(value) ? 'N/A' : value.toFixed(1)} °C`,
        [db.RoomMonthlyReport.AVERAGE_TEMP_WHEN_OCCUPIED]: (value) => `${value == null || isNaN(value) ? 'N/A' : value.toFixed(1)} °C`,
        [db.RoomMonthlyReport.AVERAGE_TEMP_NIGHT]: (value) => `${value == null || isNaN(value) ? 'N/A' : value.toFixed(1)} °C`
    }

    if(value == null) return 'N/A';

    return map[key]?.(value) ?? value;
}

const columns = [
    db.RoomMonthlyReport.AVERAGE_TEMP_WHEN_EMPTY,
    db.RoomMonthlyReport.AVERAGE_TEMP_WEEKEND,
    db.RoomMonthlyReport.AVERAGE_TEMP_DAY,
    db.RoomMonthlyReport.HUM_LOW_RATIO,
    db.RoomMonthlyReport.AVERAGE_TEMP_WHEN_OCCUPIED,
    db.RoomMonthlyReport.AVERAGE_CO_WHEN_OCCUPIED,
    db.RoomMonthlyReport.AVERAGE_TEMP_NIGHT,
    db.RoomMonthlyReport.HUM_HIGH_RATIO,
    db.RoomMonthlyReport.ROOM
];

const defaultSelectedColumns = [
    db.RoomMonthlyReport.ROOM,
    db.RoomMonthlyReport.AVERAGE_TEMP_DAY,
    db.RoomMonthlyReport.AVERAGE_TEMP_NIGHT,
    db.RoomMonthlyReport.AVERAGE_CO_WHEN_OCCUPIED,
];
const indicatorsIcons = {
    [db.RoomMonthlyReport.AVERAGE_TEMP_WHEN_EMPTY]: tempEmpty,
    [db.RoomMonthlyReport.AVERAGE_TEMP_WEEKEND]: tempWeekends,
    [db.RoomMonthlyReport.AVERAGE_TEMP_DAY]: tempDay,
    [db.RoomMonthlyReport.AVERAGE_TEMP_NIGHT]: tempNight,
    [db.RoomMonthlyReport.AVERAGE_TEMP_WHEN_OCCUPIED]: tempOccupied,
    [db.RoomMonthlyReport.AVERAGE_CO_WHEN_OCCUPIED]: co2Occupied,
    [db.RoomMonthlyReport.HUM_HIGH_RATIO]: timeHumidityHigh,
    [db.RoomMonthlyReport.HUM_LOW_RATIO]: timeHumidityLow,
    [db.RoomMonthlyReport.ROOM]: tempEmpty,
};

class AnalyticsTable extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading: false,
            roomMonthlyReports: [],
            selectedColumns: [],
            reportMode: 'Monthly'
        };
    }

    async componentDidMount() {
        let customSelectedColumns = localStorage.getItem(MONTHLY_REPORT_CUSTOM_SELECTED_COLUMNS);

        let selectedColumns  = defaultSelectedColumns;

        if(customSelectedColumns)
            selectedColumns = customSelectedColumns.split(',');

        await redirectIfNotLogged(this.props.history);

        let {lastMonth, year, week} = getLastMonthNumberWithYear();

        await this.getRoomMonthlyReport(lastMonth, year);
        await this.getRoomWeeklyReport(week, year);
        await this.getHome();

        this.setState({
            selectedColumns: selectedColumns
                .map(column => ({label: this.props.t(`class.label.${db.classes.RoomMonthlyReport}.${column}`), value: column}))
        });
    }

    async getRoomMonthlyReport(month, year){
        this.setState({loading: true});
        let homeId = localStorage.getItem('buildingSelection');
        let roomMonthlyReports = await new Parse.Query(db.classes.RoomMonthlyReport)
            .equalTo(db.RoomMonthlyReport.MONTH, month)
            .equalTo(db.RoomMonthlyReport.YEAR, year)
            .equalTo(db.RoomMonthlyReport.HOME, toPointerFromId(homeId, db.classes.Home))
            .select(columns)
            .find();
        this.setState({roomMonthlyReports, loading: false});
    }

    async getRoomWeeklyReport(week, year){
        this.setState({loading: true});

        const homeId = localStorage.getItem('buildingSelection');
        const nowWeek = moment().isoWeek();
        const nowYear = moment().year();

        let roomWeeklyReports = await new Parse.Query(db.classes.RoomWeeklyReport)
            .equalTo(db.RoomWeeklyReport.ISO_WEEK_NUMBER, week || nowWeek)
            .equalTo(db.RoomWeeklyReport.YEAR, year || nowYear)
            .equalTo(db.RoomWeeklyReport.HOME, toPointerFromId(homeId, db.classes.Home))
            .descending(db.RoomWeeklyReport.AVERAGE_CO_WHEN_OCCUPIED)
            .select(columns)
            .find();

        this.setState({roomWeeklyReports, loading: false});
    }

    async getHome(){
        let homeId = localStorage.getItem('buildingSelection');
        let home = await new Parse.Query(db.classes.Home).get(homeId);

        this.setState({home});
    }

    render() {
        const {t} = this.props;
        const {roomMonthlyReports, roomWeeklyReports,  loading, home} = this.state;

        const reports = this.state.reportMode === 'Monthly'? roomMonthlyReports : roomWeeklyReports;
        let {lastMonth, year} = getLastMonthNumberWithYear();
        return (
            <>
                <RoomReportingDatePicker
                    month={lastMonth}
                    year={year}
                    t={t}
                    style={{}}
                    onDateChange={(week, month, year) => {
                        this.getRoomMonthlyReport(month, year);
                        this.getRoomWeeklyReport(week, year);
                    }}
                    onModeChange={reportMode => this.setState({reportMode})}
                >
                    <MultiSelect
                        options={columns.map(column => ({label: t(`class.label.${db.classes.RoomMonthlyReport}.${column}`), value: column}))}
                        values={this.state.selectedColumns}
                        t={t}
                        onChange={async selectedColumns => {
                            if(selectedColumns.length > 4){
                                await swal({
                                    title: t('Warning'),
                                    icon: 'warning',
                                    text: 'Maximum selected columns reached. Please deselect another columns and retry.',
                                    dangerMode: true,
                                    timeout: 1000
                                });

                                return;
                            }

                            localStorage.setItem(
                                MONTHLY_REPORT_CUSTOM_SELECTED_COLUMNS,
                                selectedColumns.map(item => item.value)
                            );

                            this.setState({selectedColumns})
                        }}
                        className={'column-selector'}
                    />
                </RoomReportingDatePicker>
                {/**
                 <div className={'card-info reporting-indicator-list'}>
                     <RoomReportingIndicator
                         icon={energyIcon}
                         title={t('Energy Saved')}
                         value={'20'}
                     />
                     <RoomReportingIndicator
                         icon={energyIcon}
                         title={t('Energy Saved')}
                         value={'20'}
                     />
                 </div>
                */}

                <div className="grid-cols flex-xs-column-reverse flex-sm-column-reverse flex-md-column-reverse flex-lg-row">
                    <div className="card card-outer full-w mt-20">
                        { loading &&  <Loader size={'medium'}/>}
                        { (reports.length === 0 && !loading) &&  t('No room data available')}
                        { home && reports.length > 0 &&
                            <table className="table">
                                <thead>
                                    <tr>
                                        {
                                            this.state.selectedColumns?.map((column, i) => {
                                                return <th key={i}>
                                                    <span title={t(column.label)}>{truncate(column.label)}</span>
                                                </th>
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        reports && reports.map(report => {
                                            return <tr key={report.id}>
                                                {
                                                    this.state.selectedColumns?.map((column, i) => {
                                                        return <td key={i}>
                                                            {format(column.value, report.get(column.value), this.props, home)}
                                                        </td>
                                                    })
                                                }
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </>
        );
    }
}

AnalyticsTable.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    history: PropTypes.any.isRequired
};

export default AnalyticsTable;