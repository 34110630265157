'use strict';

import React from 'react';
import PropTypes from 'prop-types';

class LoaderV2 extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
        let size = this.props.size || 'medium';

        let height = 100;

        switch (size){
            case 'small':
                height = 50;
                break;
            case 'medium':
                height = 100;
                break;
            case 'large':
                height = 150;
                break;
        }

        return <div className={'loader-container'}>
            <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                viewBox="0 0 100 100" enableBackground="new 0 0 100 100"
                style={{
                    height,
                    display: 'block',
                    margin: 'auto'
                }}
            >
                <path fill={this.props.color || 'white'} d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                    <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        dur="1s"
                        from="0 50 50"
                        to="360 50 50"
                        repeatCount="indefinite" />
                </path>
            </svg>
        </div>;
    }
}

LoaderV2.propTypes = {
    size: PropTypes.oneOf([
        'small',
        'medium',
        'large'
    ]),
    message: PropTypes.string,
    style: PropTypes.string,
    color: PropTypes.string
};

LoaderV2.defaultProps = {
    size: 'medium'
};

export default LoaderV2;