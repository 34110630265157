'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {
    addCloseButtonToDatepicker,
    redirectIfNotLogged,
    toPointer,
    toPointerFromId,
    userIsInRole
} from '../../lib/util';

import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import translate from '../translate';
import * as db from '../../lib/structure';
import moment from 'moment-timezone';
import SelectSingle from '../component-select-single/select-single';
import MultiSelect from '../multiselect';
import swal from 'sweetalert';
import structureLocalStorage from '../../lib/localStorageStructure';
import Parse from 'parse';
import localStorageDb from '../../lib/localStorageStructure';

class PageSupportRequestOpenNew extends React.Component {
    constructor(props){
        super(props);

        let supportRequest = this.initSupportRequest(props);

        this.state = {
            loading: false,
            supportRequest,
            roomDevices: [],
            selectedDevices: [],
            ownerOptions: []
        };

        this.initDatePicker = this.initDatePicker.bind(this);
        this.updateSupportRequest = this.updateSupportRequest.bind(this);
        this.getRoomDevices = this.getRoomDevices.bind(this);
        this.goToNextStep = this.goToNextStep.bind(this);
        this.getBuildingUsers = this.getBuildingUsers.bind(this);
    }

    async componentDidMount(){
        await redirectIfNotLogged(this.props.history);

        this.initDatePicker(this.state.supportRequest.get(db.SupportRequest.DATE_OF_PROBLEM));

        let roomDevices = await this.getRoomDevices(this.props.match.params.id);

        this.setState({roomDevices, selectedDevices: roomDevices.map(device => ({
            label: device.get(db.Device.SERIAL_NUMBER),
            value: device.get(db.Device.SERIAL_NUMBER),
            object: device
        }))});

        const isSupportRole = userIsInRole(db.roles.SUPPORT);

        if(isSupportRole) {
            await this.getBuildingUsers();
        }
    }

    async getBuildingUsers(){
        let users = await Parse.Cloud.run('getUserInfo', {
            'sort': this.state.sort,
            'orderBy': this.state.orderBy,
            homeId: localStorage.getItem(localStorageDb.BUILDING_SELECTION)
        });

        this.setState({ownerOptions: users.map(user => {
            return {
                key: user.id,
                label: `${user.get(db._User.USERNAME)} (${user.get(db._User.EMAIL)})`
            }
        })});
    }

    async getRoomDevices(roomId){
        let devices = await new Parse.Query(db.classes.Device)
            .equalTo(db.Device.ROOM_ID, toPointerFromId(roomId, db.classes.Room))
            .find();

        return devices;
    }

    initSupportRequest(props){
        let user = Parse.User.current();
        let isSupportRole = userIsInRole(db.roles.SUPPORT);
        let selectedBuildingId = localStorage.getItem(structureLocalStorage.BUILDING_SELECTION);
        let supportRequest = new Parse.Object(db.classes.SupportRequest);

        supportRequest.set(db.SupportRequest.ROOM, toPointer(props?.location?.state?.room));
        supportRequest.set(db.SupportRequest.USER, user);
        if(!isSupportRole) supportRequest.set(db.SupportRequest.OWNER, user);
        supportRequest.set(db.SupportRequest.HOME, toPointerFromId(selectedBuildingId, 'Home'));
        supportRequest.set(db.SupportRequest.STATUS, db.SupportRequest.STATUS$DRAFT);

        return supportRequest;
    }

    updateSupportRequest(fieldName, value){
        this.setState(prev => {
            let supportRequest = prev.supportRequest;

            supportRequest.set(fieldName, value);

            prev.supportRequest = supportRequest;

            return prev;
        });
    }

    initDatePicker(){
        $('#date-picker').datepicker({
            todayHighlight: true,
            format: 'dd M yyyy',
            autoclose: true,
            maxViewMode: 0,
            weekStart: 1,
            language: this.props.i18n.resolvedLanguage,
            startDate: moment().subtract(1, 'month').toDate()
        })
            .on('changeDate', (e) => {
                let date = moment(e.date);

                this.updateSupportRequest(db.SupportRequest.DATE_OF_PROBLEM, date.toDate());
            }).on('show', (e) => {
                addCloseButtonToDatepicker(e.currentTarget.id, this.props.t);
            });
    }

    async goToNextStep(){
        const {t} = this.props;

        try {
            let supportRequest = this.state.supportRequest;

            supportRequest.set(
                db.SupportRequest.RELATED_DEVICES,
                this.state.selectedDevices.map(device => device.object)
            );

            if(!supportRequest.get(db.SupportRequest.INITIAL_SITUATION)) throw Error(t('Fill all the required field before continuing.'))
            if(!supportRequest.get(db.SupportRequest.DATE_OF_PROBLEM)) throw Error(t('Fill all the required field before continuing.'))
            if(!supportRequest.get(db.SupportRequest.PRIORITY)) throw Error(t('Fill all the required field before continuing.'))
            if(!supportRequest.get(db.SupportRequest.OWNER)) throw Error(t('Fill all the required field before continuing.'))

            supportRequest = await supportRequest.save();

            await swal({title: t('Success'), text: ' ', icon: 'success', button: [''], timer: 1000});

            this.props.history.push({
                pathname: '/support-request/:id/diagnostic'.replace(':id', supportRequest.id),
                state: {
                    supportRequest,
                    room: this.props.location?.state?.room,
                    from: 'support-request-open-new'
                }
            });
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }

    }

    render(){
        const {t} = this.props;

        const initialSituationOptions =  [
            {
                key: 'tooCold',
                label:  t(`class.label.${db.classes.SupportRequest}.${db.SupportRequest.INITIAL_SITUATION}.${db.SupportRequest.INITIAL_SITUATION$TOO_COLD}`) // Too cold
            },{
                key: 'tooHot',
                label: t(`class.label.${db.classes.SupportRequest}.${db.SupportRequest.INITIAL_SITUATION}.${db.SupportRequest.INITIAL_SITUATION$TOO_HOT}`) //'Too hot'
            },
            {
                key: 'connectionIssue',
                label: t(`class.label.${db.classes.SupportRequest}.${db.SupportRequest.INITIAL_SITUATION}.${db.SupportRequest.INITIAL_SITUATION$CONNECTION_ISSUE}`) //'Device(s) have connection issues'
            },
            {
                key: 'brokenVandalism',
                label: t(`class.label.${db.classes.SupportRequest}.${db.SupportRequest.INITIAL_SITUATION}.${db.SupportRequest.INITIAL_SITUATION$BROKEN_VANDALISM}`) //'Device(s) are broken (vandalism)'
            },
            {
                key: 'batteryDischarged',
                label: t(`class.label.${db.classes.SupportRequest}.${db.SupportRequest.INITIAL_SITUATION}.${db.SupportRequest.INITIAL_SITUATION$BATTERY_DISCHARGED}`) //'Battery is discharged'
            },
            {
                key: 'displayedTemperatureIsWrong',
                label: t(`class.label.${db.classes.SupportRequest}.${db.SupportRequest.INITIAL_SITUATION}.${db.SupportRequest.INITIAL_SITUATION$DISPLAYED_TEMPERATURE_IS_WRONG}`) //'Displayed temperature is wrong'
            },
            {
                key: 'temperatureRegulationWrong',
                label: t(`class.label.${db.classes.SupportRequest}.${db.SupportRequest.INITIAL_SITUATION}.${db.SupportRequest.INITIAL_SITUATION$TEMPERATURE_REGULATION_WRONG}`) //'Temp regulation is wrong'
            },
            {
                key: 'sensorColorsWrong',
                label: t(`class.label.${db.classes.SupportRequest}.${db.SupportRequest.INITIAL_SITUATION}.${db.SupportRequest.INITIAL_SITUATION$SENSOR_COLOR_WRONG}`) //'Sensor shows wrong colours'
            }
        ];
        const prioritiesOptions =  [
            {
                key: 'high',
                label: t(`class.label.${db.classes.SupportRequest}.${db.SupportRequest.PRIORITY}.${db.SupportRequest.PRIORITY$HIGH}`) //ASAP
            },{
                key: 'medium',
                label: t(`class.label.${db.classes.SupportRequest}.${db.SupportRequest.PRIORITY}.${db.SupportRequest.PRIORITY$MEDIUM}`)//t('Today')
            },{
                key: 'normal',
                label: t(`class.label.${db.classes.SupportRequest}.${db.SupportRequest.PRIORITY}.${db.SupportRequest.PRIORITY$NORMAL}`) //t('1-2 days')
            },{
                key: 'low',
                label: t(`class.label.${db.classes.SupportRequest}.${db.SupportRequest.PRIORITY}.${db.SupportRequest.PRIORITY$LOW}`) //t('3-5 days')
            }
        ];
        let devicesOptions = this.state.roomDevices.map(device => ({
            label: device.get(db.Device.SERIAL_NUMBER),
            value: device.get(db.Device.SERIAL_NUMBER),
            object: device
        }));

        const isSupportRole = userIsInRole(db.roles.SUPPORT);

        return <div className={'system-diagnostics'}>
            <div className="wrapper d-flex align-items-stretch">
                <Sidebar {...this.props}/>
                <div className="content">
                    <Navbar {...this.props}/>
                    <div className="inner">
                        <Breadcrumbs {...this.props} elements={[
                            {
                                link: '/asdfasdf',
                                name: 'Room'
                            },
                            {
                                link: null,
                                name: t('Support request')
                            }
                        ]}/>
                        <div className='system-diagnostics-header'>
                            <div className='system-diagnostics-header-title full-w'>
                                <button className='room-reporting-export-button' style={{float: 'right'}}
                                    onClick={this.goToNextStep}
                                >
                                    {t('Continue')}
                                </button>
                                {
                                    this.props.location?.state?.room && <button className='room-reporting-export-button' style={{float: 'left'}}
                                        onClick={() => this.props.history.push({
                                            pathname: '/room/:id'.replace(':id', this.props.location?.state?.room?.id)
                                        })}
                                    >
                                        {t('Go back to the room')}
                                    </button>
                                }

                                <h1 style={{textAlign: 'center'}}>
                                    {t('Open a new support request for room')} {this.props.location?.state?.room?.get?.(db.Room.ROOM_NAME)}
                                </h1>
                            </div>
                        </div>
                        <div className='card card-outer full-w' style={{padding: '20px'}}>
                            <div className={'form-group'}>
                                <label style={{width: 300}}>{t('What is the initial situation?')} *</label>
                                <SelectSingle
                                    t={t}
                                    value={this.state.supportRequest.get(db.SupportRequest.INITIAL_SITUATION)}
                                    options={initialSituationOptions}
                                    onChange={option =>
                                        this.updateSupportRequest(db.SupportRequest.INITIAL_SITUATION, option.key)
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label style={{width: 300}}>{t('When was the problem?')} *</label>
                                <input type="text" className="datepicker-input" readOnly id="date-picker"/>
                            </div>
                            <div className="form-group">
                                <label style={{width: 300}}>{t('How high is the priority of this issue?')} *</label>
                                <SelectSingle
                                    t={t}
                                    value={this.state.supportRequest.get(db.SupportRequest.PRIORITY)}
                                    options={prioritiesOptions}
                                    onChange={option =>
                                        this.updateSupportRequest(db.SupportRequest.PRIORITY, option.key)
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label style={{width: 300}}>{t('Is the issue related to a specific device/devices?')}</label>
                                <MultiSelect
                                    options={devicesOptions}
                                    values={this.state.selectedDevices}
                                    t={t}
                                    onChange={selectedDevices => this.setState({selectedDevices})}
                                    className={'building-report-live'}
                                    width={'auto'}
                                    listStyle={{maxWidth: '100%'}}
                                />
                            </div>
                            {
                                isSupportRole &&
                                this.state.ownerOptions.length > 0 &&
                                <div className={'form-group'}>
                                    <label style={{width: 300}}>{t('Select the owner')}</label>
                                    <SelectSingle
                                        t={t}
                                        value={this.state.supportRequest?.get(db.SupportRequest.OWNER)?.objectId}
                                        options={this.state.ownerOptions}
                                        onChange={option =>
                                            this.updateSupportRequest(
                                                db.SupportRequest.OWNER,
                                                toPointerFromId(option.key, db.classes._User)
                                            )
                                        }
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

PageSupportRequestOpenNew.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    history: PropTypes.any.isRequired,
    location: PropTypes.object
};

export default translate(PageSupportRequestOpenNew);
