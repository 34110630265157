import React, {Component} from 'react';
import PropTypes from 'prop-types';
import translate from '../translate';
import {redirectIfNotLogged} from '../../lib/util';
import paths from '../../lib/paths';
import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';

class PageIntegrations extends Component {
    constructor(props){
        super(props);

        this.state = {};
    }

    async componentDidMount(){
        let user = await redirectIfNotLogged(this.props.history);

        this.setState({user});
    }

    render(){
        let {t} = this.props;

        return (
            <div className={'page-dashboard'}>
                <div className="wrapper d-flex align-items-stretch">
                    <Sidebar {...this.props}/>
                    <div className="content">
                        <Navbar {...this.props}/>
                        <div className="inner">
                            <Breadcrumbs {...this.props} elements={[
                                {
                                    link: '/dashboard',
                                    name: t('Home')
                                },
                                {
                                    link: paths.integrations,
                                    name: 'API'
                                }
                            ]}/>
                            <h1>{t('API')}</h1>
                            <p>
                                {t('Refer to <a href="https://docs.cleveron.ch" target="_blank">API documentation</a> or contact <a href="mailto: support@cleveron.ch">support@cleveron.ch</a> for more info.')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

PageIntegrations.propTypes = {
    history: PropTypes.any,
    t: PropTypes.func
};

export default translate(PageIntegrations);