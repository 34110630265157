import React from 'react';
import PropTypes from 'prop-types';
import successIcon from '../../assets/images/ic_diagnostics_success.svg';
import warningIcon from '../../assets/images/ic_diagnostics_warning.svg';
import infoIcon from '../../assets/images/ic_info.svg';
import errorIcon from '../../assets/images/ic_diagnostics_error.svg';

export default class BuildingOnboardFormItemBRInformation extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            viewDetailedOpened: false
        };
    }

    render() {
        let {t, item} = this.props;

        return (
            <div className={`system-diagnostics-result-item ${item.icon}`}>
                <div className='system-diagnostics-result-item-header'>
                    <div className='system-diagnostics-result-item-group'>
                        <div style={{alignSelf: 'center'}}>
                            {item.icon === 'info' && <img src={infoIcon} className='system-diagnostics-result-item-icon'/>}
                            {item.icon === 'success' && <img src={successIcon} className='system-diagnostics-result-item-icon'/>}
                            {item.icon === 'warning' && <img src={warningIcon} className='system-diagnostics-result-item-icon'/>}
                            {item.icon === 'danger' && <img src={errorIcon} className='system-diagnostics-result-item-icon'/>}
                        </div>
                        <div className='system-diagnostics-result-item-status'>
                            <div className='system-diagnostics-result-item-title'>
                                {t(item.title)}
                            </div>
                            <div className='system-diagnostics-result-item-description'>
                                {t(item.description)}
                            </div>
                        </div>
                    </div>
                    {(item.icon !== 'success' && item.icon !== 'info') && (<div className='system-diagnostics-result-item-view-details'>
                        <a onClick={(e) => {
                            e.preventDefault();
                            this.setState(prevState => {
                                return {
                                    viewDetailedOpened:  !prevState.viewDetailedOpened
                                };
                            });
                        }} className='system-diagnostics-result-item-details-label'>{t('View details')}</a>

                        <a onClick={(e) => {
                            e.preventDefault();
                            this.setState(prevState => {
                                return {
                                    viewDetailedOpened:  !prevState.viewDetailedOpened
                                };
                            });
                        }} className={`system-diagnostics-result-item-details-icon arrow ${this.state.viewDetailedOpened ? 'is-active' : ''}`}></a>
                    </div>)}
                </div>
                {this.state.viewDetailedOpened && (<div className='system-diagnostics-result-item-details'>
                    {item.infoMessage && <div className='system-diagnostics-result-item-details-info'>
                        {t(item.infoMessage)}
                    </div>}
                    {item.warningMessage && <div className='system-diagnostics-result-item-details-warning'>
                        {t(item.warningMessage)}
                    </div>}
                    {item.buttons && <div className='system-diagnostics-result-item-details-buttons'>
                        {item.buttons.map((button, index) => {
                            return <a key={index}
                                target='_blank'
                                href={button.url}
                                className='system-diagnostics-result-item-details-button' rel="noreferrer">
                                {t(button.label)}
                            </a>;
                        })}
                    </div>}
                </div>
                )}
            </div>
        )
    }
}

BuildingOnboardFormItemBRInformation.propTypes = {
    t: PropTypes.func,
    item: PropTypes.object
};
