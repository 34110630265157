const paths = require('./paths');
const Parse = require('parse');

let servers = {
    stage: 'https://server.stage.cleveron.ch',
    live: 'https://server.cleveron.ch',
    local: 'http://app.cleveron.ch.localhost'
    //local: 'https://server.stage.cleveron.ch'
};

export function initializeParse(Parse1){
    if(!Parse1)
        Parse1 = Parse;

    Parse1.initialize('NjSUT8HxvCz706ldcwUn');
    //Parse1.initialize('cleveron-stage-server');

    Parse1.serverURL = `${servers.live}/api`;
    
    if(NODE_ENV === 'test' || NODE_ENV === 'development'){
        Parse1.serverURL = `${servers.local}/api`;
    }
    if(NODE_ENV === 'dev-remote'){
        Parse1.serverURL = `${servers.live}/api`;
    }
    if(NODE_ENV === 'stage'){
        Parse1.serverURL = `${servers.stage}/api`;
    }

    window.Parse = Parse1;

    return Promise.resolve();
}

/**
 *
 * @param history
 * @param path
 * @returns {Parse.Object || null}
 */
export function redirectIfLogged(history, path){
    let user = Parse.User.current();

    if (user) return history.push(path || paths.building.replace(':floorNumber', '0'));
}