'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {merge} from 'lodash';
import {connect} from 'react-redux';
import {redirectIfNotLogged} from '../../lib/util';
import translate from '../translate';
import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import db from '../../lib/structure';
import Parse from 'parse';
import structureLocalStorage from '../../lib/localStorageStructure';
import paths from '../../lib/paths';
import {renderRoomTickets} from '../../lib/ui-components';

class PageBuildingRoomTickets extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            loading: false,
            roomTickets: [],
            issueCategories: []
        };
    }

    async componentDidMount(){
        await redirectIfNotLogged(this.props.history);
        await this.refresh();
    }

    async getRoomTickets(){
        let selectedBuildingId = localStorage.getItem(structureLocalStorage.BUILDING_SELECTION);

        let result = await Parse.Cloud.run('get-building-room-tickets', {buildingId: selectedBuildingId});

        return result;
    }

    async getCrmOperation(referenceNumber){
        if(!referenceNumber) return;

        let result = await Parse.Cloud.run('get-crm-operation-from-reference-number', {referenceNumber});

        this.setState({operation: result});
    }

    async refresh(){
        let {roomTickets, operations} = await this.getRoomTickets();

        let issueCategories = await new Parse.Query(db.classes.IssueCategory).find();

        const crmOperation =
            await this.getCrmOperation(operations?.[0]?.get?.(db.OperationTask.REFERENCE_NUMBER));

        this.setState({issueCategories, roomTickets, operations, crmOperation});
    }
    render(){
        const {t} = this.props;

        return <div className={'system-diagnostics'}>
            <div className="wrapper d-flex align-items-stretch">
                <Sidebar {...this.props}/>
                <div className="content">
                    <Navbar {...this.props}/>
                    <div className="inner">
                        <Breadcrumbs {...this.props} elements={[
                            {
                                link: null,
                                name: 'Home'
                            },
                            {
                                link: paths.buildingSupportRequests,
                                name: t('Operations report')
                            }
                        ]}/>
                        <h1>{t('Operations report')}</h1>
                        <div
                            className="grid-cols flex-xs-column-reverse flex-sm-column-reverse flex-md-column-reverse
                                        flex-lg-row" style={{marginBottom: 20}}>
                            <div className="card card-outer full-w">
                                {
                                    this.state.operation && <>
                                        <h4>{t('Operation summary')}:</h4>
                                        <p>
                                            {this.state.operation[db.ZohoOperation.CUSTOMER_NOTE]}
                                        </p>
                                        <br/>
                                    </>
                                }

                                {renderRoomTickets(this.state.roomTickets, t,  this.getRoomTickets)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

PageBuildingRoomTickets.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired
};

/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(PageBuildingRoomTickets));
