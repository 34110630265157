import fetchAndCheck from './advanced-fetch';

//import cfg           from '../conf';

export function loadingCantonData(){
    return {
        type: 'loading-canton-data',
        payload: fetchAndCheck('test', {credentials: 'include'})
    };
}

export function activateConnectionTimeoutError(){
    return {
        type: 'activate-connection-timeout-error'
    }
}

export function deactivateConnectionTimeoutError(){
    return {
        type: 'deactivate-connection-timeout-error'
    }
}