'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {floorNameFromNumber, redirectIfNotLogged, userIsInRole} from '../../lib/util';

import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import translate from '../translate';
import * as db from '../../lib/structure';
import moment from 'moment-timezone';
import ReactQuill from 'react-quill';
import swal from 'sweetalert';
import _ from 'lodash';
import Footer from '../footer';
import structureLocalStorage from '../../lib/localStorageStructure';

class PageSupportRequestDetails extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            loading: false,
            supportRequestComments: [],
            answerHtml: 'Write your text here...',
            showMessageToCustomer: !userIsInRole(db.roles.SUPPORT),
            sendNotification: false
        };

        this.updateSupportRequest = this.updateSupportRequest.bind(this);
        this.addNewSupportRequestComment = this.addNewSupportRequestComment.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.refreshSupportRequestComments = this.refreshSupportRequestComments.bind(this);
        this.deleteSupportRequestComment = this.deleteSupportRequestComment.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
    }

    async componentDidMount(){
        await redirectIfNotLogged(this.props.history);

        const supportRequest = await new Parse.Query(db.classes.SupportRequest)
            .include([
                db.SupportRequest.ROOM,
                db.SupportRequest.RELATED_DEVICES,
                db.SupportRequest.OWNER
            ])
            .get(this.props.match.params.id);

        let diagnosticRequest = await this.getAssociatedDiagnosticRequest(supportRequest);

        const room = supportRequest.get(db.SupportRequest.ROOM);

        const supportRequestComments = await this.getSupportRequestComments(supportRequest);

        this.setState({supportRequest, room, supportRequestComments, diagnosticRequest});
    }

    async refreshSupportRequestComments(){
        const supportRequestComments = await this.getSupportRequestComments(this.state.supportRequest);

        this.setState({supportRequestComments});
    }

    updateSupportRequest(fieldName, value){
        this.setState(prev => {
            let supportRequest = prev.supportRequest;

            supportRequest.set(fieldName, value);

            prev.supportRequest = supportRequest;
        });
    }

    async getSupportRequestComments(supportRequest){
        let supportRequestComments = await new Parse.Query(db.classes.SupportRequestComment)
            .equalTo(db.SupportRequestComment.SUPPORT_REQUEST, supportRequest)
            .include(db.SupportRequestComment.ADDED_BY)
            .notEqualTo(db.SupportRequestComment.DELETED, true)
            .find();

        return supportRequestComments;
    }

    async addNewSupportRequestComment(){
        const {t} = this.props;

        let isSupportRole = userIsInRole(db.roles.SUPPORT);

        if(this.state.showMessageToCustomer === true && !isSupportRole) {
            let confirmed = await swal({
                title: t('Are you sure to proceed?'),
                icon: 'warning',
                text: t('This will send a notification to the cleveron support and open the current support request.'),
                buttons: [t('Cancel'), t('Confirm')],
                dangerMode: true
            });

            if(!confirmed) return;
        }

        if(this.state.showMessageToCustomer === true && isSupportRole) {
            let confirmed = await swal({
                title: t('Are you sure to proceed?'),
                icon: 'warning',
                text: t('This will send a notification to the customer and this message will be visible.'),
                buttons: [t('Cancel'), t('Confirm')],
                dangerMode: true
            });

            if(!confirmed) return;
        }

        let supportRequestComment = await new Parse.Object(db.classes.SupportRequestComment)
            .set(db.SupportRequestComment.SUPPORT_REQUEST, this.state.supportRequest)
            .set(db.SupportRequestComment.COMMENT_HTML, this.state.answerHtml)
            .set(db.SupportRequestComment.SHOW_TO_CUSTOMER, this.state.showMessageToCustomer === true)
            .set(db.SupportRequestComment.SEND_NOTIFICATION, this.state.sendNotification === true)
            .set(db.SupportRequestComment.ADDED_BY, Parse.User.current())
            .save();

        let supportRequest = await this.state.supportRequest
            .set(db.SupportRequest.STATUS, db.SupportRequest.STATUS$OPEN)
            .save()

        this.setState({answerHtml: 'Write you answer here...'});

        await swal({title: t('Success'), text: ' ', icon: 'success', button: [''], timer: 1000});

        const supportRequestComments = await this.getSupportRequestComments(this.state.supportRequest);

        this.setState({supportRequestComments, supportRequest});
    }

    async getAssociatedDiagnosticRequest(supportRequest){
        let diagnosticRequest = await new Parse.Query(db.classes.DiagnosticRequest)
            .equalTo(db.DiagnosticRequest.SUPPORT_REQUEST, supportRequest)
            .first();

        return diagnosticRequest;
    }

    async changeStatus() {
        const {t} = this.props;
        let supportRequest = this.state.supportRequest;
        let currentStatus = supportRequest.get(db.SupportRequest.STATUS);

        let getNextStatus = async (currentStatus) => {
            let {t} = this.props;

            //--------------- BATCH TAG --------------------------------------------
            let statusToChoose = [];

            if(currentStatus === db.SupportRequest.STATUS$DRAFT){
                statusToChoose = [
                    db.SupportRequest.STATUS$OPEN,
                    db.SupportRequest.STATUS$RESOLVED
                ];
            }
            if(currentStatus === db.SupportRequest.STATUS$OPEN){
                statusToChoose = [
                    db.SupportRequest.STATUS$RESOLVED
                ];
            }

            let typeButtons = {
                cancel: 'Cancel'
            };
            statusToChoose.forEach(status => typeButtons[status] = _.capitalize(status));

            let choosedStatus = await swal('Change the status', {
                title: 'Change support request status',
                text: 'Please select the desired status',
                buttons: typeButtons
            });

            if(choosedStatus === 'cancel') return;

            if(
                currentStatus === db.SupportRequest.STATUS$DRAFT &&
                choosedStatus === db.SupportRequest.STATUS$OPEN
            ){
                let confirmed = await swal({
                    title: t('Are you sure to proceed?'),
                    icon: 'warning',
                    text: t('This will send a notification to the cleveron support.'),
                    buttons: [t('Cancel'), t('Confirm')],
                    dangerMode: true
                });

                if(!confirmed) return;
            }

            return choosedStatus;
        }

        let nextStatus = await getNextStatus(currentStatus);

        if(!nextStatus) return;

        supportRequest.set(db.SupportRequest.STATUS, nextStatus);

        supportRequest = await supportRequest.save()

        this.setState({supportRequest});

        await swal({title: t('Success'), text: ' ', icon: 'success', button: [''], timer: 1000});
    }

    async deleteSupportRequestComment(supportRequestComment){
        const {t} = this.props;
        try {
            let confirmed = await swal({
                title: t('Are you sure to proceed?'),
                icon: 'warning',
                text: t('This action cannot be undone.'),
                buttons: [t('Cancel'), t('Confirm')],
                dangerMode: true
            });

            if(!confirmed) return;

            supportRequestComment.set(
                db.SupportRequestComment.DELETED,
                true
            );

            await supportRequestComment.save();

            swal({
                title: t('Success'),
                text: ' ',
                icon: 'success',
                button: [''],
                timer: 1000
            });

            await this.refreshSupportRequestComments();
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    render(){
        const {t} = this.props;

        let supportRequest = this.state.supportRequest;
        let room = this.state.room;

        const hasWritePermission = localStorage.getItem('user:hasWritePermission') === 'true';

        let status = supportRequest?.get(db.SupportRequest.STATUS);
        let priority = supportRequest?.get(db.SupportRequest.PRIORITY);
        let owner = supportRequest?.get(db.SupportRequest.OWNER);
        let home = supportRequest?.get(db.SupportRequest.HOME);
        let initialSituation = supportRequest?.get(db.SupportRequest.INITIAL_SITUATION);
        let dateOfProblem = supportRequest?.get(db.SupportRequest.DATE_OF_PROBLEM);
        dateOfProblem = dateOfProblem != null ? moment(dateOfProblem) : null;
        let relatedDevices = supportRequest?.get(db.SupportRequest.RELATED_DEVICES) ?? [];
        let floor = room?.get(db.Room.FLOOR);
        let roomName = room?.get(db.Room.ROOM_NAME);
        let referenceNumber = supportRequest?.get(db.SupportRequest.REFERENCE_NUMBER) ?? 0;
        var zerofilled = ('00000'+referenceNumber).slice(-5);
        let referenceNumberString = '#' + zerofilled;
        let currentUser = Parse.User.current();
        let createdAt = supportRequest?.get(db.SupportRequest.CREATED_AT);

        createdAt = createdAt == null ? null : moment(createdAt);

        let homeId = home != null ? home.id : localStorage.getItem(structureLocalStorage.BUILDING_SELECTION);
        let isSupportRole = userIsInRole(db.roles.SUPPORT);

        return <div className={'system-diagnostics'}>
            <div className="wrapper d-flex align-items-stretch">
                <Sidebar {...this.props}/>
                <div className="content">
                    <Navbar {...this.props}/>
                    <div className="inner">
                        <Breadcrumbs {...this.props} elements={[
                            {
                                link: `/room/${room?.id}`,
                                name: t('Room')
                            },
                            {
                                link: null,
                                name: t('Support request')
                            }
                        ]}/>
                        <div className='system-diagnostics-header'>
                            <div className='system-diagnostics-header-title full-w'>
                                {
                                    room && <button className='room-reporting-export-button' style={{float: 'left'}}
                                        onClick={() => this.props.history.push({
                                            pathname: `/room/${room?.id}`
                                        })}
                                    >
                                        {t('Return to room')}
                                    </button>
                                }

                                <h1 style={{textAlign: 'center'}}>
                                    {t('Support request')}&nbsp;{referenceNumberString}
                                    {
                                        isSupportRole && <span>&nbsp;({initialSituation})</span>
                                    }
                                </h1>
                            </div>
                        </div>
                        <div className='card card-outer full-w' style={{padding: '20px'}}>
                            <div className={'support-request-details'}>
                                <div className={'support-request-details-left'}>
                                    <div className={'item-grid'}>
                                        <div className={'support-request-item'}>
                                            <div className={'small-title'}>{t('Room')}</div>
                                            <div className={'text'}>
                                                <a href={`/room/${room?.id}`}>{roomName ? roomName : t('Not defined')}</a>
                                            </div>
                                        </div>
                                        <div className={'support-request-item'}>
                                            <div className={'small-title'}>{t('Location')}</div>
                                            <div className={'text'}>{floor ? t(floorNameFromNumber(floor)) : t('Not defined')}</div>
                                        </div>
                                        <div className={'support-request-item'}>
                                            <div className={'small-title'}>{t('Operation reference number')}</div>
                                            <div className={'text'}>{t('N/A')}</div>
                                        </div>
                                        <div className={'support-request-item'}>
                                            <div className={'small-title'}>{t(`class.label.SupportRequest.${db.SupportRequest.STATUS}`)}</div>
                                            <div className={'text'}>
                                                {
                                                    status && <div className='support-request-item-status'>
                                                        <div className={`support-request-item-status-${status}`}>{t(`class.label.SupportRequest.status.${status}`)}</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className={'support-request-item'}>
                                            <div className={'small-title'}>{t(`class.label.SupportRequest.${db.SupportRequest.PRIORITY}`)}</div>
                                            <div className={'text'}>
                                                {
                                                    priority && <div className='support-request-item-priority'>
                                                        <div className={`support-request-item-priority-${priority}`}>{t(`class.label.SupportRequest.${db.SupportRequest.PRIORITY}.${priority}`)}</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className={'support-request-item'}>
                                            <div className={'small-title'}>{t(`class.label.SupportRequest.${db.SupportRequest.DATE_OF_PROBLEM}`)}</div>
                                            <div className={'text'}>{dateOfProblem ? moment(dateOfProblem).format('DD/MM/YYYY') : t('Not defined')}</div>
                                        </div>
                                        <div className={'support-request-item'}>
                                            <div className={'small-title'}>{t(`class.label.SupportRequest.${db.SupportRequest.OWNER}`)}</div>
                                            <div className={'text'}>{owner?.get(db._User.USERNAME) || owner?.get(db._User.EMAIL)}</div>
                                        </div>
                                        {
                                            isSupportRole && <div className={'support-request-item'}>
                                                <div className={'small-title'}>{t(`class.label.SupportRequest.${db.SupportRequest.CREATED_AT}`)}</div>
                                                <div className={'text'}>{createdAt?.format('DD/MM/YYYY HH:mm')}</div>
                                            </div>
                                        }
                                    </div>
                                    <h3>{t('Description')}</h3>
                                    <div dangerouslySetInnerHTML={{
                                        // eslint-disable-next-line max-len
                                        __html: this.state.supportRequest?.get(db.SupportRequest.COMMENT) ?? t('Not defined')
                                    }} />
                                    <h3 style={{marginBottom: 40}}>
                                        {t('Message')}
                                    </h3>

                                    {
                                        this.state.supportRequestComments.map(supportRequestComment => {
                                            let user = supportRequestComment.get(db.SupportRequestComment.ADDED_BY);
                                            let showToCustomer = supportRequestComment.get(db.SupportRequestComment.SHOW_TO_CUSTOMER);

                                            return <>
                                                <div key={supportRequestComment.id}
                                                    className={'support-request-message'} style={{backgroundColor: showToCustomer === true ? '' : '#feff9c'}}
                                                >
                                                    <div
                                                        className={'support-request-html'}
                                                        dangerouslySetInnerHTML={{
                                                            // eslint-disable-next-line max-len
                                                            __html: supportRequestComment.get(db.SupportRequestComment.COMMENT_HTML)
                                                        }}
                                                    ></div>
                                                    <div className={'support-request-date'}>
                                                        {
                                                            user && <span>{t('Added by')} {user.get(db._User.EMAIL) || user.get(db._User.USERNAME)} </span>
                                                        }

                                                        {moment(supportRequestComment.get(db.SupportRequestComment.CREATED_AT)).format('DD/MM/YYYY HH:mm')}

                                                        {
                                                            showToCustomer === false && <span> ({t('private')}) </span>
                                                        }
                                                        <a href={'javascript: ;'}
                                                            style={{marginLeft: 10}}
                                                            onClick={() =>
                                                                this.deleteSupportRequestComment(supportRequestComment)
                                                            }
                                                        >{t('Delete')}</a>
                                                    </div>
                                                </div>
                                            </>
                                        })
                                    }

                                    <p style={{marginTop: 20}}>
                                        {t('Add your answer')}:
                                    </p>

                                    <ReactQuill
                                        className={'support-request-comment-add-new'}
                                        theme="snow"
                                        value={this.state.answerHtml}
                                        onChange={comment => {
                                            this.setState({answerHtml: comment});
                                        }}
                                    />

                                    {
                                        isSupportRole &&<>
                                            <div className="form-group">
                                                <div className="checkbox-custom" style={{marginLeft: 'auto', marginTop: 10}}>
                                                    <input type="checkbox" id="showMessageToCustomer"
                                                        checked={this.state.showMessageToCustomer}
                                                        onChange={() => {
                                                            this.setState({
                                                                showMessageToCustomer: !this.state.showMessageToCustomer
                                                            });
                                                        }}
                                                        disabled={false}
                                                    />
                                                    <label htmlFor="showMessageToCustomer">{t('Show to customer')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="checkbox-custom" style={{marginLeft: 'auto', marginTop: 10}}>
                                                    <input type="checkbox" id="sendNotification"
                                                        checked={this.state.sendNotification}
                                                        onChange={() => {
                                                            this.setState({
                                                                sendNotification: !this.state.sendNotification
                                                            });
                                                        }}
                                                        disabled={false}
                                                    />
                                                    <label htmlFor="sendNotification">{t('Send notification')}</label>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    <button className='room-reporting-export-button'
                                        style={{float: 'right', marginTop: 20}}
                                        disabled={!hasWritePermission}
                                        onClick={this.addNewSupportRequestComment}
                                    >
                                        {t('Add an answer')}
                                    </button>
                                </div>
                                <div className={'support-request-details-right'}>
                                    <button className='room-reporting-export-button'
                                        onClick={() => this.props.history.push({
                                            pathname: '/support-request/:id/diagnostic'.replace(':id', supportRequest?.id),
                                            state: {
                                                from: 'support-request-detail'
                                            }
                                        })}
                                    >
                                        {t('See diagnostic')}
                                    </button>
                                    <button className='room-reporting-export-button'
                                        onClick={() => this.changeStatus()}
                                        disabled={status === db.SupportRequest.STATUS$RESOLVED || !hasWritePermission}
                                    >
                                        {t('Change status')}
                                    </button>
                                    <button className='room-reporting-export-button'
                                        data-toggle="modal"
                                        data-target="#support-request-schedule-call"
                                    >
                                        {t('Schedule a call')}
                                    </button>
                                    <div className={'item-grid flex-column'}>
                                        <div className={'support-request-item'}>
                                            <div className={'small-title'}>{t('Related devices')}</div>
                                            <div className={'related-devices-list'}>
                                                {
                                                    relatedDevices.map(device => {
                                                        return <div className={'related-devices-list-item'} key={device.id}>
                                                            <div className={'device-type-icon device-type-icon__therm'} />
                                                            <div className={'text'}>{device.get(db.Device.SERIAL_NUMBER)}</div>
                                                        </div>;
                                                    })
                                                }
                                            </div>
                                        </div>
                                        {
                                            isSupportRole && <div className={'support-request-item'}>
                                                <div className={'small-title'}>Useful links</div>
                                                <div className={'related-devices-list'}>
                                                    <a href={`https://admindashboard.simplyhome.tech/homes/${homeId}/room-temperature-chart?selectedRoomId=${room?.id}&startDate=${dateOfProblem?.format('DD-MM-YYYY')}&endDate=${dateOfProblem?.format('DD-MM-YYYY')}`}
                                                        target={'_blank'}
                                                        rel="noreferrer">
                                                        Admin dashboard chart
                                                    </a>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer {...this.props}/>
                </div>
            </div>

            <div className="modal" id="support-request-schedule-call" tabIndex="-1" role="dialog"
                aria-labelledby="clearCacheModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="clearCacheModalLabel">{t('Schedule a call')}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                    <path fill="#222"
                                        d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                </svg>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>{t('Schedule a call')}</p>

                            {
                                supportRequest != null && currentUser != null && <iframe width='100%' height='700px'
                                    src={`https://cleveron.zohobookings.eu/portal-embed#/customer/67150000001770452?TicketReferenceNumber=${referenceNumberString.replace('#', '')}&Email=${currentUser.get(db._User.EMAIL)}&Name=${currentUser.get(db._User.NAME)}&SupportRequestId=${supportRequest.id}&Username=${currentUser.get(db._User.USERNAME)}&Comment=${supportRequest.get(db.SupportRequest.COMMENT)||''}&SupportRequestUrl=${`https://app.cleveron.ch/support-request/${supportRequest.id}`}`}
                                    frameBorder='0' allowFullScreen=''>
                                </iframe>
                            }

                            <div className="actions">
                                <button type="button" className="cancel-form-btn" data-dismiss="modal">{t('Close')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

PageSupportRequestDetails.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.any.isRequired,
    location: PropTypes.object
};

export default translate(PageSupportRequestDetails);
