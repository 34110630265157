let cnf = {};

cnf.i18n = {
    //ns: ['common', 'portal'],
    locales: ['en', 'de', 'fr', 'it'],
    preload: ['en', 'de', 'fr', 'it'],
    whitelist: ['en', 'de', 'fr', 'it'],
    nonExplicitSupportedLngs: true,
    fallbackLng: 'de',
    saveMissing: NODE_ENV !== 'production',
    updateMissing: true,
    nsSeparator: ':::',
    keySeparator: '::',
    debug: false,
    detection: {
        order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
        lookupCookie: 'i18next',
        lookupLocalStorage: 'i18nextLng',
        lookupQuerystring: 'webview_language',
        caches: ['localStorage', 'cookie']
    },
    react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default',
        useSuspense: false
    },
    backend: {
        projectId: '9aed8c59-8208-4aae-9a52-bfa0ab6fadad',
        apiKey: NODE_ENV !== 'production' ? '8a56fd6b-91cf-4ffd-aa57-a59b755649ef' : undefined,
        referenceLng: 'de',
        allowedAddOrUpdateHosts: (hostname) => {
            if(hostname.includes('cleveron.ch')) return true;
            if(hostname.includes('surge.sh')) return true;
            if(hostname.includes('stage.simplyhome.tech')) return true;

            return false;
        }
    },
    locizeLastUsed: {
        // the id of your locize project
        projectId: '9aed8c59-8208-4aae-9a52-bfa0ab6fadad',

        // add an api key if you want to send missing keys
        apiKey: NODE_ENV !== 'production' ? '8a56fd6b-91cf-4ffd-aa57-a59b755649ef' : undefined,

        // the reference language of your project
        referenceLng: 'de',

        // debounce interval to send data in milliseconds
        debounceSubmit: 1000,

        // hostnames that are allowed to send last used data
        // please keep those to your local system, staging, test servers (not production)
        allowedHosts: ['app.cleveron.ch.localhost', 'webapp.stage.simplyhome.tech']
    },
};

export default cnf;