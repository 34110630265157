'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {merge} from 'lodash';
import {connect} from 'react-redux';
import {redirectIfNotLogged, toPointerFromId, userIsInRole} from '../../lib/util';
import translate from '../translate';
import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import db from '../../lib/structure';
import Parse from 'parse';
import structureLocalStorage from '../../lib/localStorageStructure';
import paths from '../../lib/paths';

class BuildingSupportRequestsPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            loading: false,
            supportRequests: [],
            deletedSuportRequests: []
        };
    }

    async componentDidMount(){
        await redirectIfNotLogged(this.props.history);
        this.getSupportRequests();
    }

    async getSupportRequests(){
        let selectedBuildingId = localStorage.getItem(structureLocalStorage.BUILDING_SELECTION);

        let supportRequests = await new Parse.Query(db.classes.SupportRequest)
            .equalTo(db.SupportRequest.HOME, toPointerFromId(selectedBuildingId, 'Home'))
            .include(db.SupportRequest.USER)
            .include(db.SupportRequest.ROOM)
            .descending(db.SupportRequest.CREATED_AT)
            .notEqualTo(db.SupportRequest.DELETED, true)
            .find();

        let deletedSuportRequests = await new Parse.Query(db.classes.SupportRequest)
            .equalTo(db.SupportRequest.HOME, toPointerFromId(selectedBuildingId, 'Home'))
            .include(db.SupportRequest.USER)
            .include(db.SupportRequest.ROOM)
            .descending(db.SupportRequest.CREATED_AT)
            .equalTo(db.SupportRequest.DELETED, true)
            .find();

        this.setState({supportRequests, deletedSuportRequests});
    }

    render(){
        const {t} = this.props;
        
        let isSupportRole = userIsInRole(db.roles.SUPPORT);

        let renderSupportRequestsTable = (supportRequests) => {
            return <table className="table">
                <thead>
                    <tr>
                        <th>
                            {t('Reference number')}
                        </th>
                        <th className="show-desktop">
                            {t('Room')}
                        </th>
                        <th className="show-desktop">
                            {t(`class.label.SupportRequest.${db.SupportRequest.STATUS}`)}
                        </th>
                        <th className="show-desktop">
                            {t(`class.label.SupportRequest.${db.SupportRequest.PRIORITY}`)}
                        </th>
                        <th className="show-desktop">
                            {t('Go to support request')}
                        </th>
                        <th className="show-tablet"/>
                    </tr>
                </thead>
                <tbody>
                    {
                        supportRequests.map(supportRequest => {
                            let referenceNumber = supportRequest
                                .get(db.SupportRequest.REFERENCE_NUMBER);
                            let room = supportRequest.get(db.SupportRequest.ROOM);
                            var zerofilled = ('00000'+referenceNumber).slice(-5);
                            let referenceNumberString = '#' + zerofilled;
                            let status = supportRequest?.get(db.SupportRequest.STATUS);
                            let priority = supportRequest?.get(db.SupportRequest.PRIORITY);

                            return  <tr key={supportRequest.id}>
                                <td>{referenceNumberString}</td>
                                <td>{room?.get(db.Room.ROOM_NAME)}</td>
                                <td>
                                    <div className='support-request-item-status' style={{maxWidth: 100}}>
                                        <div className={`support-request-item-status-${status}`}>{t(`class.label.SupportRequest.status.${status}`)}</div>
                                    </div>
                                </td>
                                <td>
                                    {
                                        priority && <div className='support-request-item-priority' style={{maxWidth: 100}}>
                                            <div className={`support-request-item-priority-${priority}`}>{t(`class.label.SupportRequest.${db.SupportRequest.PRIORITY}.${priority}`)}</div>
                                        </div>
                                    }
                                </td>
                                <td>
                                    <a
                                        href="#"
                                        className="ml-auto p-2 align-items-center link"
                                        onClick={() => this.props.history.push(paths.supportRequestDetails.replace(':id', supportRequest.id))}>
                                        {t('Go to support request details')}
                                    </a>
                                </td>
                            </tr>
                        })
                    }
                    {
                        this.state.supportRequests.length === 0 && <p style={{marginTop: 20}}>{t('There are no support requests')}.</p>
                    }
                </tbody>
            </table>
        }

        return <div className={'system-diagnostics'}>
            <div className="wrapper d-flex align-items-stretch">
                <Sidebar {...this.props}/>
                <div className="content">
                    <Navbar {...this.props}/>
                    <div className="inner">
                        <Breadcrumbs {...this.props} elements={[
                            {
                                link: null,
                                name: 'Home'
                            },
                            {
                                link: paths.buildingSupportRequests,
                                name: t('Support requests')
                            }
                        ]}/>
                        <h1>{t('Support requests')}</h1>
                        <div
                            className="grid-cols flex-xs-column-reverse flex-sm-column-reverse flex-md-column-reverse
                                        flex-lg-row" style={{marginBottom: 20}}>
                            <div className="card card-outer full-w">
                                {renderSupportRequestsTable(this.state.supportRequests)}
                            </div>
                        </div>
                        {
                            isSupportRole === true && <>
                                <h1>{t('Deleted requests')}</h1>
                                <div
                                    className="grid-cols flex-xs-column-reverse flex-sm-column-reverse flex-md-column-reverse
                                        flex-lg-row">
                                    <div className="card card-outer full-w">
                                        {renderSupportRequestsTable(this.state.deletedSuportRequests)}
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    }
}

BuildingSupportRequestsPage.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired
};

/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(BuildingSupportRequestsPage));
