'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {get, merge} from 'lodash';
import translate from '../translate';
import logoImgMini from '../../assets/images/logo-mini.png';
import qrCodeIcon from '../../assets/images/code_icon.png';
import QrCodeExplanation from './qr-code-explanation';
import LanguagePicker from '../language-picker';

const paths = require('../../lib/paths');

// catch the event and make changes accordingly

class PageStart extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            username: '',
            password: '',
            showQrCodeExplanation: false
        };

        this.logIn = this.logIn.bind(this);
        this.register = this.register.bind(this);
        this.webViewQrCodeCallback = this.webViewQrCodeCallback.bind(this);
        this.toggleQrCodeExplanation = this.toggleQrCodeExplanation.bind(this);
    }
    componentDidMount(){
        window.webViewQrCodeCallback = this.webViewQrCodeCallback;
    }

    webViewQrCodeCallback(result){
        if(result.startsWith('https://room.cleveron.ch/room/'))
            this.props.history.push(paths.roomReport.replace(':id', result.replace('https://room.cleveron.ch/room/', '')));
        else if(result.startsWith('https://devices.cleveron.ch/device/')){
            let macAddress = result.replace('https://devices.cleveron.ch/device/', '');
            this.props.history.push(paths.roomReport.replace(':id', macAddress));
        }
        else
            alert(result);
    }

    logIn(event){
        event.preventDefault();

        this.props.history.push(paths.login + '?source=start')
    }

    register(event){
        event.preventDefault();

        this.props.history.push(paths.registrationEmail)
    }

    toggleQrCodeExplanation(){
        this.setState({
            showQrCodeExplanation: !this.state.showQrCodeExplanation
        });
    }

    render(){
        const {t} = this.props;

        return <div className={'page-start'}>
            {
                get(this.props, 'shared.generalError.visible') && <div className={'alert alert-danger'}>
                    {get(this.props, 'shared.generalError.message')}
                </div>
            }
            <div className={'back-image'}></div>
            <a className="auth-logo" onClick={() => this.props.history.push('/start')}
                href="#">{t('Cleveron')}</a>
            <div className="card auth-card card-login">
                <div className="card-body">
                    <div className="m-b-36">
                        <LanguagePicker t={t} i18n={this.props.i18n} />
                    </div>
                    <div className="header">
                        <div className="title">{t('Welcome')}</div>
                        <div className="auth-start-logo"><img src={logoImgMini} alt="Cleveron" /></div>
                    </div>
                    <form className="p-b-32" onSubmit={this.logIn}>
                        <button type="submit" name="login-button" className="btn login-button">
                            {t('Sign in to my account')}
                        </button>
                    </form>
                    <p className="or">{t('OR')}</p>
                    <form className="p-b-32" onSubmit={this.register}>
                        <label htmlFor="go-to-registration" className={'label'}>{t('I\'m new and want to register my device')} </label>
                        <button type="submit" id='go-to-registration'>{t('Add your new device')}</button>
                    </form>
                    <div className="separator"></div>
                    <form className="p-b-32">
                        <label htmlFor="login-button" className={'label'}>{t('Get my room report via QR-Code')} <a onClick={this.toggleQrCodeExplanation}><i className='fa fa-exclamation-circle'/></a></label>
                        <button type="submit" className="btn" id='scan-qr-code-button'><a href="qrcode://" className="scan-qr-link"><img src={qrCodeIcon} /> {t('Scan the QR-Code')}</a></button>
                    </form>
                </div>
                {this.state.showQrCodeExplanation && <QrCodeExplanation t={t} onClose={this.toggleQrCodeExplanation} />}
            </div>
        </div>
    }
}

PageStart.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired,
};
/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {PageStart

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(PageStart));