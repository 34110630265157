import PropTypes from 'prop-types';

import React, {Component} from 'react';
import translate from '../translate';
import * as db from '../../lib/structure';
import moment from 'moment';

class RoomFacilityManagerFeedbackList extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {t, feedbacks}  = this.props;
        if (!feedbacks || feedbacks.length === 0) return '';

        return <div id="room-feedback-list">
            <h1 className="room-feedback-header">{t('Feedbacks')}</h1>

            {feedbacks.map(feedback => {

                return <article key={feedback.id} className="report content-new">
                    <div className="row">
                        <div className="col-8">
                            <div className="report__value">{t('FM Feedback')}</div>
                        </div>
                        <div className="col-4 text-right">
                            {feedback.get(db.RoomFMFeedback.RESOLVED) && (<div className='report_feedback__status'>
                                {t('Resolved')}
                            </div>)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="report_event__desc">{feedback.get(db.RoomFMFeedback.COMMENT)}</div>
                        </div>
                    </div>
                    <div className="report_event__desc">
                        <i className="fa fa-clock-o"></i>
                        <span className="event-date">
                            {moment(feedback.get(db.RoomFMFeedback.CREATED_AT)).format('DD MMM YYYY, HH:mm')}
                            <span> ({feedback.get(db.RoomFMFeedback.EMAIL)})</span>
                        </span>
                    </div>
                </article>
            })}
        </div>
    }
}

RoomFacilityManagerFeedbackList.propTypes = {
    t: PropTypes.any,
    roomId: PropTypes.string,
    homeId: PropTypes.string,
    i18n: PropTypes.object,
    feedbacks: PropTypes.array
};

export default translate(RoomFacilityManagerFeedbackList);