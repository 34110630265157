'use strict';

import React, {Fragment} from 'react';
import PropType from 'prop-types';
import translate from '../translate';
import paths from '../../lib/paths';

class Footer extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
        const {t} = this.props;

        return <Fragment>
            <div className="footer">
                <div className="copy">{t('Copyright © 2021 Cleveron AG. All rights reserved.')}
                </div>
                <ul className="breadcrumbs list-unstyled">
                    <li><a href="/dashboard">{t('Dashboard')}</a></li>
                    <li><a href="https://info.cleveron.ch/data-privacy-app" target={'_blank'}>{t('Data privacy')}</a></li>
                    <li><a href="https://info.cleveron.ch/impressum-app" target={'_blank'}>{t('Impressum')}</a></li>
                    <li><a href={paths.cookieDeclaration}>{t('Cookie declaration')}</a></li>
                    <li><a href="mailto: support@cleveron.ch">{t('Support')}</a></li>
                    <li><a href="mailto: info@cleveron.ch">{t('Contacts')}</a></li>
                    <li><a href="#" data-toggle="modal" data-target="#clearCacheModal" >{t('Clear cache')}</a></li>
                </ul>

                <div className="modal" id="clearCacheModal" tabIndex="-1" role="dialog"
                    aria-labelledby="clearCacheModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="clearCacheModalLabel">{t('Clear cache')}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <path fill="#222"
                                            d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>{t('Are you sure to proceed?')}</p>

                                <div className="actions">
                                    <button type="button" className="cancel-form-btn" data-dismiss="modal">{t('Close')}</button>
                                    <button type="button" className="submit-form-btn" data-dismiss="modal" onClick={(e) => {
                                        e.preventDefault();

                                        try{
                                            localStorage.clear();
                                            location.reload();
                                        } catch (e) {
                                            console.error(e);
                                            location.reload();
                                        }
                                    }}>{t('Clear cache')}</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>;
    }
}

Footer.propTypes = {
    t: PropType.func
};

export default translate(Footer);