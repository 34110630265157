const paths = {
    home: '/',
    dashboard: '/dashboard',
    login: '/login',
    building: '/building/:floorNumber',
    onBoardBuilding: '/onboard-building/:buildingId',
    buildingLink: '/building',
    documents: '/documents',
    profile: '/profile',
    buildingSettings: '/building-settings',
    forgotPassword: '/forgot-password',
    room: '/room/:id',
    error: '/error/:code/:message',
    userManagement: '/userManagement/',
    resetPassword: '/resetPassword/',
    invalidLink: '/invalidLink/',
    adminPanel: '/admin-panel',
    roomFeedback: '/room-feedback/:id',
    roomFeedbackDiscoverCleveron: '/discover-cleveron',
    roomFeedbackDiscoverCleveronFaq: '/discover-cleveron/faq',
    holidays: '/holidays',
    roomReport: '/room/:id/report',
    faq: '/faq',
    fuelHistory: '/fuel-history',
    buildings: '/buildings',
    registrationEmail: '/registration-email',
    registrationCreateHome: '/registration-create-home',
    registrationAddDeviceToRoom: '/registration-add-device-to-room',
    registrationAddUserAndPassword: '/registration-add-user-and-password',
    start: '/start',
    integrations: '/integrations',
    startConfiguration: '/start-configuration',
    roomManagement: '/room-management',
    systemDiagnostics: '/system-diagnostics',
    supportRequestOpenNew: '/room/:id/support-request-open-new',
    supportRequestDiagnostic: '/support-request/:id/diagnostic',
    supportRequestDetails: '/support-request/:id',
    analytics: '/analytics',
    cookieDeclaration: '/cookie-declaration',
    buildingSupportRequests: '/building-support-requests',
    supportRequestList: '/support-request-list',
    floorPlan: '/floor-plan',
    helpdesk: '/helpdesk',
    buildingOperationReport: '/building-operations-report'
};

module.exports = paths;
