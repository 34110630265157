'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {redirectIfNotLogged} from '../../lib/util';

import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import translate from '../translate';
import * as db from '../../lib/structure';
import swal from 'sweetalert';
import _ from 'lodash';
import Footer from '../footer';
import BuildingOnboardFormItemBuildingInformation from './building-onboard-form-item-building-information';
import BuildingOnboardFormItemITInformation from './building-onboard-form-item-it-info';
import BuildingOnboardFormItemBRInformation from './building-onboard-form-item-resp-info';
import BuildingOnboardFormItemBuildingUsersInformation from './building-onboard-form-item-user-list';
import BuildingOnboardFormItemBuildingRoomList from './building-onboard-form-room-list';
import Loader from '../loader';

class PageBuildingOnboardForms extends React.Component {
    constructor(props){
        super(props);

        this.state = {};

        this.loadFormStatus = this.loadFormStatus.bind(this);
    }

    async componentDidMount(){
        await redirectIfNotLogged(this.props.history);

        await this.loadFormStatus();
    }

    async loadFormStatus(){
        try {
            this.setState({loading: true});
            let building = await Parse.Cloud.run('get-onboarding-building', {homeId: this.props.match.params.buildingId});

            this.setState({building});
        } catch (e) {
            console.error(e);
        } finally {
            this.setState({loading: false});
        }
    }

    render(){
        const {t} = this.props;
        const {building} = this.state;

        let getIconFromStatus = (status) => {
            if(status === db.ZohoBuilding.INFO_STATUS$NEEDED) return 'warning';
            if(status === db.ZohoBuilding.INFO_STATUS$TO_CHECK) return 'info';

            return 'success';
        };

        let getDescriptionFromStatus = (status) => {
            if(status === db.ZohoBuilding.INFO_STATUS$NEEDED) return 'Please fill the formular';
            if(status === db.ZohoBuilding.INFO_STATUS$TO_CHECK) return 'Data are in review';

            return 'The data have been verified.'
        };

        let buildingInfoStatus = building?.[db.ZohoBuilding.BUILDING_INFORMATION_STATUS];
        let buildingResponsibleStatus = building?.[db.ZohoBuilding.BUILDING_RESPONSIBLE_INFO_STATUS];
        let itInfoStatus = building?.[db.ZohoBuilding.IT_INFO_STATUS];
        let userListStatus = building?.[db.ZohoBuilding.USER_LIST_STATUS];
        let roomListStatus = building?.[db.ZohoBuilding.ROOM_LIST_STATUS];
        let crmBuildingId = building?.[db.ZohoBuilding.ID];
        let cloudBuildingId = this.props.match.params.buildingId;
        let buildingName = building?.[db.ZohoBuilding.NAME];

        let items = [
            {
                'title': 'Add building information',
                'description': getDescriptionFromStatus(buildingInfoStatus),
                'icon': getIconFromStatus(buildingInfoStatus),
                'infoMessage': '',
                'warningMessage': '',
                'buttons': [
                    {label: 'Fill the formular', url: `https://forms.cleveron.ch/cleveronag/form/BuildingTest/formperma/nXiT1yN7rN3x8fiPOyGTY1rt5DhDXsCzH6aIguOgXkI?building_id=${crmBuildingId}&cloud_id=${cloudBuildingId}&name=${buildingName}`, action: ''}
                ]
            },
            {
                'title': 'Add building responsible information',
                'description': getDescriptionFromStatus(buildingResponsibleStatus),
                'icon': getIconFromStatus(buildingResponsibleStatus), //info,success,warning,danger
                'infoMessage': '',
                'warningMessage': '',
                'buttons': [
                    {label: 'Fill the formular', url: `https://forms.zohopublic.eu/cleveronag/form/AddContacttoBuilding/formperma/L6JmLJmde-YP5udCFmGUM2cK9t-B5kpqjFrP-W0HAKI?cloud_id=${cloudBuildingId}&building_name=${buildingName}&role=BR&building_id=${crmBuildingId}`, action: ''}
                ]
            },
            {
                'title': 'Add building IT information',
                'description': getDescriptionFromStatus(itInfoStatus),
                'icon': getIconFromStatus(itInfoStatus), //info,success,warning,danger
                'infoMessage': '',
                'warningMessage': '',
                'buttons': [
                    {label: 'Fill the formular', url: `https://forms.zohopublic.eu/cleveronag/form/AddContacttoBuilding/formperma/L6JmLJmde-YP5udCFmGUM2cK9t-B5kpqjFrP-W0HAKI?cloud_id=${cloudBuildingId}&building_name=${buildingName}&role=IT&building_id=${crmBuildingId}`, action: ''}
                ]
            },
            {
                'title': 'Add building users information',
                'description': getDescriptionFromStatus(userListStatus),
                'icon': getIconFromStatus(userListStatus), //info,success,warning,danger
                'infoMessage': '',
                'warningMessage': '',
                'buttons': [
                    {label: 'Fill the formular', url: `https://forms.zohopublic.eu/cleveronag/form/UsersList/formperma/QLQb5_MGwdPqWZSLytmZl7V1f1W5b7pYGlBV5APaWoM?building_id=${crmBuildingId}&cloud_id=${cloudBuildingId}&building_name=${buildingName}`, action: ''}
                ]
            },
            {
                'title': 'Add building rooms information',
                'description': getDescriptionFromStatus(roomListStatus),
                'icon': getIconFromStatus(roomListStatus), //info,success,warning,danger
                'infoMessage': '',
                'warningMessage': '',
                'buttons': [
                    {label: 'Fill the formular', url: `https://forms.zohopublic.eu/cleveronag/form/RoomList/formperma/i5UXceHtNNk0VEdxLnC7CRY2LcSNVfZkvERmQ1NrZRs?cloud_id=${cloudBuildingId}&building_name=${buildingName}`, action: ''}
                ]
            },
        ]

        return <div className={'system-diagnostics'}>
            <div className="wrapper d-flex align-items-stretch">
                <Sidebar {...this.props}/>
                <div className="content">
                    <Navbar {...this.props}/>
                    <div className="inner">
                        <Breadcrumbs {...this.props} elements={[
                            {
                                link: '',
                                name: buildingName
                            },
                            {
                                link: null,
                                name: t('Data collection')
                            }
                        ]}/>
                        <div className='system-diagnostics-header'>
                            <div className='system-diagnostics-header-title full-w'>
                                <button className='room-reporting-export-button' style={{float: 'left'}}
                                    onClick={() => this.props.history.push({
                                        pathname: '/buildings'
                                    })}
                                >
                                    {t('Return to buildings')}
                                </button>
                                <h1 style={{textAlign: 'center'}}>
                                    {t('Building data collection for building')} {buildingName}
                                </h1>
                            </div>
                        </div>
                        <div className='card card-outer full-w' style={{padding: '20px'}}>

                            <div className='system-diagnostics-result-header'>
                                <h4>{this.state.building?.[db.ZohoBuilding.STATUS_MESSAGE]}</h4>
                                <div className='system-diagnostics-result-date'></div>
                            </div>
                            <div className='system-diagnostics-result-text'>
                            </div>
                            <div className='system-diagnostics-result-items'>
                                {
                                    this.state.loading === true && <Loader />
                                }
                                {
                                    this.state.loading === false && <>
                                        {
                                            buildingInfoStatus !== db.ZohoBuilding.INFO_STATUS$NOT_NEEDED &&
                                            <BuildingOnboardFormItemBuildingInformation
                                                {...this.props} item={items[0]}
                                            />
                                        }

                                        {
                                            buildingResponsibleStatus !== db.ZohoBuilding.INFO_STATUS$NOT_NEEDED &&
                                            <BuildingOnboardFormItemBRInformation {...this.props} item={items[1]}/>
                                        }
                                        {
                                            itInfoStatus !== db.ZohoBuilding.INFO_STATUS$NOT_NEEDED &&
                                            <BuildingOnboardFormItemITInformation {...this.props} item={items[2]}/>
                                        }
                                        {
                                            userListStatus !== db.ZohoBuilding.INFO_STATUS$NOT_NEEDED &&
                                            <BuildingOnboardFormItemBuildingUsersInformation
                                                {...this.props} item={items[3]}
                                            />
                                        }
                                        {
                                            roomListStatus !== db.ZohoBuilding.INFO_STATUS$NOT_NEEDED &&
                                            <BuildingOnboardFormItemBuildingRoomList
                                                {...this.props} item={items[4]}
                                            />
                                        }
                                    </>
                                }
                            </div>

                        </div>
                    </div>
                    <Footer {...this.props}/>
                </div>
            </div>
        </div>
    }
}

PageBuildingOnboardForms.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.any.isRequired,
    location: PropTypes.object
};

export default translate(PageBuildingOnboardForms);
