import React from 'react';
import PropTypes from 'prop-types';
import DiagnosticsItem from './diagnostics-item';

export default function DiagnosticsResult(props) {
    const {t, result} = props;

    return (

        <div>
            <div className='card card-outer' style={{padding: '20px'}}>
                <div className='system-diagnostics-result-header'>
                    <h4>{t(result.title)}</h4>
                    <div className='system-diagnostics-result-date'>{result.generatedAt}</div>
                </div>
                <div className='system-diagnostics-result-text'>
                    {t(result.resultText)}
                </div>
                <div className='system-diagnostics-result-items'>
                    {result.items && result.items.map((item, index) =>
                        <DiagnosticsItem key={index} item={item} t={t} />)}
                </div>
            </div>
        </div>
    )
}

DiagnosticsResult.propTypes = {
    t: PropTypes.func,
    loading: PropTypes.bool,
    result: PropTypes.object
};
