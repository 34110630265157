'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import qrCodeImage from '../../assets/images/qrcode.png';

class QrCodeExplanation extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;

        return <div className="qrinfo">
            <div className="qrinfo_content">
                <p>{t('If your room is <b>Cleveron optimized</b>')}</p>
                <p>{t('you\'ll see the QR code on your wall.')}</p>
            </div>
            <div className="qrinfo_qrcode">
                <p>{t('Scan it and see the room report.')}</p>
                <a href="#"><img src={qrCodeImage} alt="images" /></a>
            </div>
            <div className="qrinfo_position">
                <a onClick={() => this.props.onClose()}><i className="fa fa-times"></i></a>
            </div>
        </div>
    }
}

QrCodeExplanation.propTypes = {
    t: PropTypes.func,
    onClose: PropTypes.func
};

export default QrCodeExplanation;