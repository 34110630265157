import React from 'react';
import PropTypes from 'prop-types';

function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export default class FakeDiagnosticsProgressBar extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            progressPercentage: 0,
            intervalId: 0
        };
    }

    componentDidMount() {
        const newIntervalId = setInterval(() => {
            this.setState(prevState => {
                return {
                    ...prevState,
                    progressPercentage: prevState.progressPercentage < 95
                        ? prevState.progressPercentage + randomIntFromInterval(1, 3)
                        : prevState.progressPercentage
                };
            });
        }, 1000);

        this.setState(prevState => {
            return {
                ...prevState,
                intervalId: newIntervalId,
            };
        });
    }

    componentWillUnmount(){
        clearInterval(this.state.intervalId);
    }

    render() {
        const {t} = this.props;

        return (
            <div>
                <div className='system-diagnostics-starter-progress'>
                    <div className='progress' style={{height: '8px'}}>
                        <div className='progress-bar'
                            role='progressbar'
                            style={{
                                width: `${10 + this.state.progressPercentage}%`,
                                borderRadius: '12px',
                                backgroundColor: '#7ab828'
                            }}
                            aria-valuenow={this.state.progressPercentage}
                            aria-valuemin='0'
                            aria-valuemax='100'></div>
                    </div>
                </div>
                <div className='system-diagnostics-starter-progress-remaining'>
                </div>
            </div>
        )
    }
}

FakeDiagnosticsProgressBar.propTypes = {
    t: PropTypes.func
};
