import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import swal from 'sweetalert';
import Parse from 'parse';
import db from '../../../lib/structure';
import {addCloseButtonToDatepicker, toPointerFromId} from '../../../lib/util';
import i18next from '../../i18n';

class ExportMeasurementsModal extends Component {

    constructor(props){
        super(props);

        this.state = {
            dateFrom: moment().subtract(1, 'days'),
            dateTo: moment(),
            deviceId: '',
            linkToExportData: ''
        };

        this.initDatePicker = this.initDatePicker.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.verifyInput = this.verifyInput.bind(this);
        this.getDeviceId = this.getDeviceId.bind(this);
        this.createLink = this.createLink.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.exportMeasurements = this.exportMeasurements.bind(this);
        this.downloadURI = this.downloadURI.bind(this);
    }

    downloadURI(uri) {
        var link = document.createElement('a');
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    exportMeasurements() {
        this.downloadURI(this.state.linkToExportData);
        $('#exportMeasurementsModal .close').click();
    }

    async componentDidMount() {
        let deviceId = await this.getDeviceId();
        this.createLink(deviceId);
        this.initDatePicker();

        i18next.on('languageChanged', this.handleLanguageChange);

        this.setState({
            home: this.props.room.get(db.Room.HOME)
        });
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps){
        if (nextProps.room)
            this.setState({
                room: nextProps.room
            });
    }

    componentWillUnmount(){
        i18next.off('languageChanged', this.handleLanguageChange);
    }

    initDatePicker(){
        $('#dateFromMeasurements').datepicker({
            todayHighlight: true,
            format: 'dd M yyyy',
            autoclose: true,
            maxViewMode: 0,
            forceParse: false,
            language: this.props.i18n.resolvedLanguage,
            weekStart: 1
        })
            .on('changeDate', (e) => {
                let date = moment(e.date);
                this.setState({dateFrom: date.clone()}, () => this.createLink(this.state.deviceId));
            })
            .on('show', (e) => {
                try{
                    addCloseButtonToDatepicker(e.currentTarget.id, this.props.t);
                } catch (e){
                    console.error(e);
                }
            });

        $('#dateToMeasurements').datepicker({
            todayHighlight: true,
            format: 'dd M yyyy',
            autoclose: true,
            maxViewMode: 0,
            forceParse: false,
            language: this.props.i18n.resolvedLanguage,
            weekStart: 1
        })
            .on('changeDate', (e) => {
                let date = moment(e.date);
                this.setState({dateTo: date.clone()}, () => this.createLink(this.state.deviceId));
            })
            .on('show', (e) => {
                addCloseButtonToDatepicker(e.currentTarget.id, this.props.t);
            });
    }

    handleLanguageChange(){
        $('#dateFromMeasurements').datepicker('destroy');
        $('#dateToMeasurements').datepicker('destroy');

        this.initDatePicker();
    }

    handleInputChange(e) {
        let hourMinute = e.target.value.split(':');
        if (e.target.name === 'startTime') {
            this.setState({dateFrom: this.state.dateFrom.hour(hourMinute[0]).minute(hourMinute[1])});
        }

        if (e.target.name === 'endTime') {
            this.setState({dateTo: this.state.dateTo.hour(hourMinute[0]).minute(hourMinute[1])});
        }

    }

    verifyInput(){
        let {dateFrom, dateTo} = this.state;
        const {t} = this.props;

        if (moment(dateTo).isBefore(moment(dateFrom))) {
            let messageText = t('Invalid Date');
            swal(messageText, '', 'error');
            return false;
        }
        if (moment(dateTo).isBefore(moment())) {
            let messageText = t('End date already happened');
            swal(messageText, '', '');
            return false;
        }
        return true;

    }

    async getDeviceId() {
        let query = new Parse.Query(db.classes.Device);
        let roomId = this.props.room.id;
        query.equalTo(db.Device.ROOM_ID, toPointerFromId(roomId, 'Room'));
        query.containedIn(db.Device.DEVICE_TYP, ['sense', 'sensp', 'senco']);
        let sense = await query.first();

        if (sense) {
            this.setState({deviceId: sense.id});

            return sense.id;
        }
    }

    createLink(deviceId) {
        const {dateFrom, dateTo} = this.state;

        if(!deviceId) return '#';

        const dateFormat = 'YYYY-MM-DD';
        let startDate = dateFrom.format(dateFormat);
        let endDate = dateTo.format(dateFormat);

        let linkToExportData = `https://server.cleveron.ch/export/device/${deviceId}/xls?startDate=${startDate}&endDate=${endDate}`;
        this.setState({linkToExportData});
    }

    render() {
        const {t} = this.props;
        const {deviceId} = this.state;
        const dateFormat = 'DD MMM YYYY';
        const language = this.props.i18n.resolvedLanguage;

        if (!deviceId) return '';

        return  <div className="modal fade" id="exportMeasurementsModal" tabIndex="-1" role="dialog" aria-labelledby="exportMeasurementsLabel"
            aria-hidden="true">
            <div className="modal-dialog" role="document" style={{maxWidth: '661px'}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exportMeasurementsLabel">{t('Export Measurements')}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <path fill="#222"
                                    d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                            </svg>
                        </button>
                    </div>
                    <div className="modal-body"><div className={'room-create-calendar-event'}>
                        <div className="card-wrap mb-0">
                            <div id="calendar-event-form">
                                <div className="row">
                                    <div className="col-5 pr-0">
                                        <label htmlFor="dateFromMeasurements" className="datepicker__label">{t('Start date')}</label>
                                        <input type="text" name="dateFromMeasurements" className="p-2 datepicker-input" readOnly
                                            id="dateFromMeasurements"
                                            value={this.state.dateFrom.locale(language).format(dateFormat)}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-5 pr-0">
                                        <label htmlFor="dateToMeasurements" className="datepicker__label">{t('End date')}</label>
                                        <input type="text" name="dateToMeasurements" className="p-2 datepicker-input" readOnly
                                            id="dateToMeasurements"
                                            value={this.state.dateTo.locale(language).format(dateFormat)}/>
                                    </div>
                                </div>
                                <div className="buttons-wr">
                                    <button className="btn btn-green" onClick={this.exportMeasurements}>
                                        {t('Export')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

ExportMeasurementsModal.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    roomId: PropTypes.string,
    sortEvents: PropTypes.func,
    room: PropTypes.object,
    homeId: PropTypes.string,
};

export default ExportMeasurementsModal;