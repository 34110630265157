import React, {Component, Fragment} from 'react';
import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import Footer from '../footer';
import translate from '../translate';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import db from '../../lib/structure.js';
import Parse from 'parse';
import {redirectIfNotLogged, toPointerFromId} from '../../lib/util';
import {merge} from 'lodash';
import LiveTable from './live-table';
import AnalyticsTable from './analytics-table';

class BuildingReport extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading: true,
            rooms: [],
            selectedColumns: [
                {label: 'Room name', value: db.Room.ROOM_NAME},
                {label: 'Floor', value: db.Room.FLOOR},
                {label: 'Max. Temperature', value: db.Room.TEMP_MAX},
                {label: 'Min. Temperature', value: db.Room.TEMP_MIN},
                {label: 'Current temp.', value: db.Room.AVG_TEMP_LAST_HOUR},
                {label: 'Current co2', value: db.Room.CO2}
            ]
        };
    }

    async componentDidMount() {
        await redirectIfNotLogged(this.props.history);
        await this.getRooms();
        this.handleTabs();
    }

    async getRooms() {
        let homeId = localStorage.getItem('buildingSelection');
        let query = new Parse.Query(db.classes.Room);
        query.equalTo(db.Room.HOME, toPointerFromId(homeId, db.classes.Home));
        query.notEqualTo(db.Room.ROOM_TYPE, db.Room.ROOM_TYPE$RESERVE);
        query.notEqualTo(db.Room.DELETED, true);
        query.notEqualTo(db.Room.HIDDEN, true);
        query.limit(1000);
        let rooms = await query.find();
        this.setState({rooms, loading: false});
    }

    handleTabs() {
        $('.nav-tabs').find('li a').last().click();
        var url = document.URL;
        var hash = url.substring(url.indexOf('#'));

        $('.nav-tabs').find('li a').each(function(key, val) {
            if (hash === $(val).attr('href')) {
                $(val).click();
            }
            $(val).click(function() {
                location.hash = $(this).attr('href');
            });
        });

        $('.room-tab-select').on('click', function (){
            $(this).toggleClass('active');
        });
    }

    render() {
        const {t} = this.props;
        const currentTab = window.location.hash === '#analytics' ? 'analytics' : 'live';

        return (
            <Fragment>
                <div className="page-building-report">

                    <div className="wrapper d-flex align-items-stretch">
                        <Sidebar {...this.props}/>
                        <div className="content">
                            <Navbar {...this.props}/>
                            <div className="inner page-building-report-container">
                                <Breadcrumbs {...this.props} elements={[
                                    {
                                        link: '/dashboard',
                                        name: t('Home')
                                    },
                                    {
                                        link: null,
                                        name: t('Analytics')
                                    }
                                ]}/>
                                <div className="d-flex">
                                    <h1>{t('Analytics')}</h1>
                                </div>
                                <ul className="nav nav-tabs header-tabs hide-sm" role="tablist" id='room-tabs-no-mobile'>

                                    <li className="nav-item">
                                        <a className={`nav-link ${currentTab === 'live' ? 'active' : ''} show`}
                                            id="room-tab-overview"
                                            data-toggle="tab"
                                            href="#live"
                                            role="tab"
                                            aria-controls="overview"
                                            aria-selected="true">
                                            {t('Live')}
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a className={`nav-link ${currentTab === 'analytics' ? 'active' : ''} show`}
                                            id="room-tab-reporting"
                                            data-toggle="tab"
                                            href="#analytics"
                                            role="tab"
                                            aria-controls="reporting"
                                            aria-selected="false">
                                            {t('Reports')}
                                        </a>
                                    </li>

                                </ul>

                                <div className="tab-content">
                                    <div
                                        className={`tab-pane fade ${currentTab === 'live' ? 'active show' : ''}`}
                                        id='room-tab-overview-content'
                                        role="tabpanel"
                                        aria-labelledby="live">
                                        <LiveTable history={this.props.history} t={t}/>
                                    </div>
                                    <div
                                        className={`tab-pane fade ${currentTab === 'analytics' ? 'active show' : ''}`}
                                        id='room-tab-reporting-content'
                                        role="tabpanel"
                                        aria-labelledby="analytics">
                                        <AnalyticsTable history={this.props.history} t={t}/>
                                    </div>
                                </div>
                            </div>
                            <Footer/>
                        </div>
                    </div>
                </div>

            </Fragment>

        );
    }
}

BuildingReport.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired
};

const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(BuildingReport));