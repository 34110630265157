import React from 'react';
import PropType from 'prop-types';

class Threshold extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: false
        };
    }

    render() {
        if (!this.props.thresholds) return '';

        const {t} = this.props;

        return (
            <div className="form-group">
                <label htmlFor={this.props.name}>{t('Threshold')}</label>
                <div className="input-field-color">
                    <input

                        readOnly={this.props.readOnly}
                        type="text"
                        className="input"
                        name={this.props.name}
                        id={this.props.name}
                        maxLength={6}
                        value={this.props.thresholds[this.props.index].threshold || ''}
                        onChange={(e) => {
                            this.props.onThresholdChange(e.target.value, this.props.index)
                        }}
                    />
                    <div className={`choices-color select-color ${this.state.active ? 'active' : ''}`}
                        onClick={() => this.setState({
                            active: !this.state.active
                        })}
                    >
                        <div className={'choices'}>
                            <div className="select-color-status choices__item choices__item--selectable" data-item=""
                                data-value="gray" aria-selected="true"><span
                                    className={`choice-color choice-color--${this.props.thresholds[this.props.index].colorName}`}></span></div>

                            <ul className="select-color-list choices__list choices__list--dropdown">
                                {
                                    this.props.colors.map(color => {
                                        return <li key={color.name}
                                            className={'select-color-item choices__item choices__item--choice choices__item--selectable'}
                                            onClick={() => this.props.onColorChange(color, this.props.index)}
                                        >
                                            <span className={`choice-color choice-color--${color.name}`}></span>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Threshold.propTypes = {
    t: PropType.func,
    readOnly: PropType.bool,
    name: PropType.string,
    thresholds: PropType.array,
    index: PropType.number,
    onColorChange: PropType.func,
    onThresholdChange: PropType.func,
    colors: PropType.array
};

export default Threshold;