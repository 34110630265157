import React, {Component} from 'react';
import translate from '../../../translate';
import PropTypes from 'prop-types';
import roomFeedbackSteps from '../../room_feedback_steps';
import Notification from '../../../notification';

class FinalResult extends Component {
    constructor(props) {
        super(props);

        this.state = {
            steps: []
        };
    }

    componentDidMount() {
        /*let addStep = () => {

            if(steps[currentStep] == null) return;

            this.setState(prev => {
                let array = this.state.steps;

                array.push({description: steps[currentStep]});

                prev.steps = array;

                return prev;
            });

            currentStep++;

            setTimeout(() => {
                addStep();
            }, 1000);
        }*/
    }

    render() {
        return <form className="informer__feedback room-feedback animate__animated animate__fadeInRight animate__fast" action="/" method="POST"
            name="room-feedback">

            {false && <Notification message={roomFeedbackSteps.result.pre_text}/>}

            {
                false && this.state.steps.map((step, i) => {
                    return <p key={i}>
                        {
                            (this.state.steps.length - 1) === i &&  <svg version="1.1" id="L9"
                                xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                                viewBox="0 0 100 100" enableBackground="new 0 0 100 100"
                                style={{
                                    width: '3rem',
                                    transform: 'translateY(30%)'
                                }}
                            >
                                <path fill="grey" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                    <animateTransform
                                        attributeName="transform"
                                        attributeType="XML"
                                        type="rotate"
                                        dur="1s"
                                        from="0 50 50"
                                        to="360 50 50"
                                        repeatCount="indefinite" />
                                </path>
                            </svg>
                        }
                        {
                            (this.state.steps.length - 1) !== i &&<svg className="icon icon-ice-crystal room-feedback__icon"
                                style={{
                                    width: '2rem',
                                    transform: 'translateY(30%)',
                                    marginRight: 10
                                }}
                            >
                                <use href="/assets/images/sprite.svg#check"></use>
                            </svg>
                        }

                        {step.description}
                    </p>
                })
            }

            {
                this.props.gravityFeedbackValue ===
                roomFeedbackSteps.gravity_feedback.options.tooCold.BUT_I_CAN_CONTINUE &&
                <Notification message={roomFeedbackSteps.gravity_feedback.text.thanks.butICanContinue}/>
            }
            {
                this.props.gravityFeedbackValue ===
                roomFeedbackSteps.gravity_feedback.options.tooCold.BUT_OK_TO_CHANGE_MAX_TEMP &&
                <Notification message={roomFeedbackSteps.gravity_feedback.text.thanks.butOkToChangeMaxTemp}/>
            }
            {
                (this.props.gravityFeedbackValue === roomFeedbackSteps.gravity_feedback.options.tooCold.VERY ||
                this.props.gravityFeedbackValue === roomFeedbackSteps.gravity_feedback.options.tooHot.VERY) &&
                <Notification
                    message={roomFeedbackSteps.gravity_feedback.text.thanks.very}
                />
            }
        </form>
    }
}

FinalResult.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    continueNextStep: PropTypes.func.isRequired,
    temperatureFeedbackValue: PropTypes.string.isRequired,
    usageFeedbackValue: PropTypes.string.isRequired,
    gravityFeedbackValue: PropTypes.string.isRequired,
    radiatorsFeedbackValue: PropTypes.string.isRequired
};

export default translate(FinalResult);