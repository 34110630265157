import temperatureIcon from '../assets/images/ic_temperature.svg';
import humidityIcon from '../assets/images/ic_humidity.svg';
import energyIcon from '../assets/images/ic_room_energy.svg';
import co2Icon from '../assets/images/ic_co2.svg';
import co2Occupied from '../assets/images/indicators/co2-occupied.svg';
import tempEmpty from '../assets/images/indicators/temp-empty.svg';
import tempWeekends from '../assets/images/indicators/temp-weekends.svg';
import timeHumidityLow from '../assets/images/indicators/time-hum-low.svg';
import timeHumidityHigh from '../assets/images/indicators/time-hum-high.svg';
import tempDay from '../assets/images/indicators/temp-day.svg';
import tempNight from '../assets/images/indicators/temp-night.svg';
import tempOccupied from '../assets/images/indicators/temp-occupied.svg';
import occupancy from '../assets/images/indicators/occupancy.svg';
import buildingAnalytics from '../assets/images/icon-building-analytics.svg';

const icons = {
    temperatureIcon,
    humidityIcon,
    energyIcon,
    co2Icon,
    tempEmpty,
    tempWeekends,
    timeHumidityLow,
    co2Occupied,
    timeHumidityHigh,
    tempDay,
    tempNight,
    tempOccupied,
    occupancy,
    buildingAnalytics
};

export default icons;