import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import db from '../../../lib/structure';
import moment from 'moment';
import swal from 'sweetalert';

class SupportRequestItem extends PureComponent {
    constructor() {
        super();
    }

    componentDidMount() {

    }

    render() {
        const {t, supportRequest} = this.props;
        const hasWritePermission = localStorage.getItem('user:hasWritePermission') === 'true';

        return (
            <div className="card card-outer full-w">
                <div className='support-request-item'>
                    {
                        supportRequest.get(db.SupportRequest.STATUS) === db.SupportRequest.STATUS$RESOLVED &&
                        <div className='support-request-item-status'>
                            <div className='support-request-item-status-resolved'>{t('Resolved')}</div>
                        </div>
                    }
                    {
                        supportRequest.get(db.SupportRequest.STATUS) === db.SupportRequest.STATUS$OPEN &&
                        <div className='support-request-item-status'>
                            <div className='support-request-item-status-pending'>{t('Pending')}</div>
                        </div>
                    }
                    {
                        supportRequest.get(db.SupportRequest.STATUS) === db.SupportRequest.STATUS$DRAFT &&
                        <div className='support-request-item-status'>
                            <div className='support-request-item-status-draft'>{t('Draft')}</div>
                        </div>
                    }
                    <div className='support-request-item-container'>
                        <div className='support-request-item-text' style={{flex: 1}}>
                            <a className='support-request-item-title' href={'javascript:void(0)'} onClick={() => {
                                this.props.history.push({
                                    pathname: `/support-request/${supportRequest.id}`
                                });
                            }}>
                                {t(`class.label.SupportRequest.initialSituation.${supportRequest.get(db.SupportRequest.INITIAL_SITUATION)}`)}
                            </a>
                            <div className='support-request-item-description'>
                            </div>
                            <div className='support-request-item-text-details'>
                                <div className='support-request-item-text-details-item'>
                                    {supportRequest.get(db.SupportRequest.USER)?.get(db._User.EMAIL)}
                                </div>
                                <span className='support-request-item-text-details-delimiter'>{'|'}</span>
                                <div className='support-request-item-text-details-item'>
                                    {moment(supportRequest.get(db.SupportRequest.CREATED_AT)).format('DD MMM YYYY, HH:mm')}
                                </div>
                            </div>
                            <div className='support-request-item-buttons'>
                                <button onClick={async () => {
                                    let response = await swal({
                                        title: t('Are you sure?'),
                                        icon: 'warning',
                                        text: t('This ticket will be deleted and cannot be recovered.'),
                                        className: 'allow-admin-action-modal',
                                        buttons: [t('Cancel'), t('Confirm')],
                                    });

                                    if (!response) throw new Error(t('Action aborted by user'));

                                    supportRequest.set(db.SupportRequest.DELETED, true);

                                    await supportRequest.save();

                                    this.props.refreshList();

                                    await swal({title: t('Success'), text: ' ', icon: 'success', button: [''], timer: 1000});
                                }}
                                disabled={!hasWritePermission}
                                className='support-request-item-buttons-delete'>
                                    {t('Delete support request')}
                                </button>
                                {supportRequest.get(db.SupportRequest.STATUS) !== db.SupportRequest.STATUS$RESOLVED &&
                                    <button onClick={async () => {
                                        let response = await swal({
                                            title: t('Are you sure?'),
                                            icon: 'warning',
                                            className: 'allow-admin-action-modal',
                                            text: t('This ticket will be set to resolved and cannot be re-opened.'),
                                            buttons: [t('Cancel'), t('Confirm')],
                                        });

                                        if (!response) throw new Error(t('Action aborted by user'));

                                        supportRequest.set(db.SupportRequest.STATUS, db.SupportRequest.STATUS$RESOLVED);

                                        await supportRequest.save();

                                        await this.props.refreshList();

                                        await swal({title: t('Success'), text: ' ', icon: 'success', button: [''], timer: 1000});
                                    }} disabled={!hasWritePermission} className='support-request-item-buttons-resolve'>
                                        {t('Resolve')}
                                    </button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

SupportRequestItem.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    supportRequest: PropTypes.object,
    refreshList: PropTypes.func,
    history: PropTypes.any
};

export default SupportRequestItem;