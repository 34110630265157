import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import swal from 'sweetalert';
import db from '../../../client/lib/structure'
import * as paths from '../../lib/paths';
import {resetLocalStorage, setUserLocalStorage} from "../../lib/util";

class SeeAsUser extends Component {
    constructor(props){
        super(props);

        this.state = {
            users: [],
            loadedUsers: false,
            selectedUser: '',
            selectTypeActive: ''
        };

        this.getAllUsers = this.getAllUsers.bind(this);
        this.displayUsers = this.displayUsers.bind(this);
        this.switchUser = this.switchUser.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount(){
        await this.getAllUsers()
    }

    async getAllUsers(){
        let query = new Parse.Query('User');
        query.notEqualTo(db._User.IS_DEVICE, true);
        query.notEqualTo(db._User.DELETED, true);
        query.ascending(db._User.USERNAME);
        query.limit(100000);

        let users = await query.find();
        this.setState({
            users: users,
            loadedUsers: true
        });
    }

    handleChange(user){
        this.setState({selectedUser: user.get(db._User.USERNAME)});
    }

    async switchUser(){
        const {t} = this.props;

        if (!this.state.selectedUser) {
            let errorMessage = t('Please select a user');
            await swal('Error!', errorMessage, 'error');
            return;
        }

        let sessionKey = await Parse.Cloud.run('getSessionKey', {desiredUser: this.state.selectedUser}, {});

        if (sessionKey) {
            try {
                let user = await Parse.User.become(sessionKey, {});

                resetLocalStorage();

                await setUserLocalStorage();

                this.props.history.push(paths.buildings);
                location.reload();
            } catch (err) {
                let errorMessage = t('Failed to login as user ');
                errorMessage += this.state.selectedUser;
                await swal('Error!', errorMessage, 'error');
            }
        }
    }

    displayUsers(){
        if (this.state.loadedUsers) {
            return this.state.users.map(user => {
                return (
                    <Fragment key={user.id}>
                        <option onClick={() => this.switchUser()}>
                            {user.get(db._User.USERNAME)} {user.get(db._User.EMAIL)} {user.get(db._User.CITY)}
                        </option>
                    </Fragment>
                )
            });
        }
    }

    render(){
        const {t} = this.props;
        return (
            <Fragment>
                <div className="card-seeAsUser mt-4">
                    <div className="card card-outer side-card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <div className="card-title mb-0">
                                <h2 className="mb-0">{t('Switch to selected user')}</h2>
                            </div>
                        </div>
                        <div className="card-wrap mb-0">
                            <div id="see-as-user-form">
                                <div className="buttons-wr">
                                    <button className='btn btn-green' type='submit' data-toggle="modal" data-target="#seeAsUserModal">
                                        {t('Switch user')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="seeAsUserModal" tabIndex="-1" role="dialog"
                    aria-labelledby="seeAsUserModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content edit-info">
                            <div className="modal-header">
                                <h5 className="modal-title" id="seeAsUserModalLabel">{t('Switch user')}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <path fill="#222"
                                            d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className={`select-custom ${this.state.selectTypeActive ? 'active' : ''}`}
                                    onClick={() => this.setState({
                                        selectTypeActive: !this.state.selectTypeActive
                                    })}>
                                    <div className="select-custom-status">{this.state.selectedUser ? this.state.selectedUser:  t('Select user')}</div>
                                    <ul className="select-custom-list">
                                        {
                                            this.state.users.map(user => {
                                                return <li key={user.id}
                                                    className="select-custom-item"
                                                    onClick={() => this.handleChange(user)}
                                                >
                                                    {/* eslint-disable-next-line max-len */}
                                                    {user.get(db._User.USERNAME)} ({user.get(db._User.EMAIL)} {user.get(db._User.CITY)})
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="actions">
                                    <button type="button" className="submit-form-btn" onClick={this.switchUser}>{t('Switch')}</button>
                                    <button type="button" className="cancel-form-btn" data-dismiss="modal">{t('Close')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

SeeAsUser.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    history: PropTypes.any.isRequired,
};

export default SeeAsUser;
