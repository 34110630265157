import React from 'react';
import PropType from 'prop-types';
import {manageError, toPointerFromId} from '../../../lib/util';
import Parse from 'parse';
import swal from 'sweetalert';
import * as db from '../../../lib/structure';
import moment from 'moment';
import ProgramTemperature from './program-temperature';
import ProgramSwitch from './program-switch';

const trackTemperetureChanges = function () {
    $.each(
        $('.program__tr'), function (index, row) {
            const rowItems = $(row).find('.program__item');
            if ($(row).find('.program__item.active').length > 0) {
                $.each(rowItems, function (i, item) {
                    $(item).removeClass(function (index, css) {
                        return (css.match(/\bmove-\S+/g) || []).join(' ');
                    });
                    if ($(item).hasClass('active')) {
                        if ($(rowItems[i + 1]).hasClass('active') || $(rowItems[i - 1]).hasClass('active')) {
                            var startIndex = i;
                            var endIndex = i;
                            while ($(rowItems[startIndex - 1]).hasClass('active') && startIndex >= 1) {
                                startIndex -= 1;
                            }
                            while ($(rowItems[endIndex + 1]).hasClass('active') && endIndex < rowItems.length) {
                                endIndex += 1;
                            }
                            $(rowItems[startIndex]).addClass('move-' + (endIndex - startIndex + 1));
                        }
                    } else {
                        // eslint-disable-next-line no-redeclare,no-redeclare
                        var startIndex = i;
                        // eslint-disable-next-line no-redeclare
                        var endIndex = i;
                        while (!$(rowItems[startIndex - 1]).hasClass('active') && startIndex >= 1) {
                            startIndex -= 1;
                        }
                        while (!$(rowItems[endIndex + 1]).hasClass('active')
                        && endIndex < rowItems.length - endIndex - startIndex) {
                            endIndex += 1;
                        }
                        $(rowItems[startIndex]).addClass('move-' + (endIndex - startIndex + 1));
                    }
                });
            } else {
                $($(row).find('.program__item')[0]).addClass('move-' + (23))
            }
        });
};

export default class RoomProgram extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            presenceForecastEnabled: false,
            editTemperatures: false,
            tempMin: this.props.room.get(db.Room.TEMP_MIN),
            tempMax: this.props.room.get(db.Room.TEMP_MAX),
            allowedTemperatureRange: {
                min: 12,
                max: 26
            }
        };

        this.getWeekMatrix = this.getWeekMatrix.bind(this);
        this.setDayArray = this.setDayArray.bind(this);
        this.getAllRoomPresencePresetForRoom = this.getAllRoomPresencePresetForRoom.bind(this);
        this.saveProgram = this.saveProgram.bind(this);
        this.updateViewPresencePreset = this.updateViewPresencePreset.bind(this);
        this.handleAutomaticProgramChange = this.handleAutomaticProgramChange.bind(this);
        this.getPresenceForecastEnabled = this.getPresenceForecastEnabled.bind(this);
        this.getAutomaticPresenceForecast = this.getAutomaticPresenceForecast.bind(this);
        this.handleProgramTemperatureChange = this.handleProgramTemperatureChange.bind(this);
        this.handleEditProgram = this.handleEditProgram.bind(this);
        this.handleCancelEditProgram = this.handleCancelEditProgram.bind(this);
        this.initProgramHandlers = this.initProgramHandlers.bind(this);
        this.showProgramSwitcherModal = this.showProgramSwitcherModal.bind(this);
    }

    async componentDidMount() {
        this.initProgramHandlers(this.props.room.get(db.Room.TEMP_MIN), this.props.room.get(db.Room.TEMP_MAX));

        let presenceForecastEnabled = await this.getPresenceForecastEnabled();

        if (presenceForecastEnabled) {
            this.getAutomaticPresenceForecast();
        } else {
            this.getAllRoomPresencePresetForRoom();
        }
    }

    showProgramSwitcherModal(option) {
        if ((option.value === 'manual' && this.state.presenceForecastEnabled) ||
            (option.value === 'automatic' && !this.state.presenceForecastEnabled)) {
            $('#presetDataModalOpener').trigger('click');
        }
    }

    getWeekMatrix() {
        let week = new Array(7);

        for (var i = 0; i < 7; i++) {
            week[i] = $('.program__cells')
                .children()
                .eq(i)
                .children()
                .map((a, b) => $(b).hasClass('active') ? 1 : 0)



                .toArray();
        }

        return week;
    }

    setDayArray(dayIndex, programArray) {
        $('.program__cells')
            .children()
            .eq(dayIndex)
            .children()
            .map((index, b) => {
                if (!programArray) {
                    if ($(b).hasClass('active')) {
                        $(b).toggleClass('active');
                    }

                    return;
                }

                let threshold = 0;

                if(index > 17) {
                    threshold = 0.05;
                }

                if (programArray[index] > threshold) {
                    if (!$(b).hasClass('active')) {
                        $(b).toggleClass('active');
                    }
                    return;
                }

                if (programArray[index] === threshold) {
                    if ($(b).hasClass('active')) {
                        $(b).toggleClass('active');
                    }
                    return;
                }
            })
            .toArray();
    }

    /**
     *
     * @param {Parse.Object} roomId
     */
    getAllRoomPresencePresetForRoom() {
        let query = new Parse.Query('RoomPresencePreset');

        return query
            .equalTo('room', toPointerFromId(this.props.roomId, 'Room'))
            .find()
            .then(presencePresets => {
                this.setState({presencePresets});

                this.updateViewPresencePreset(presencePresets);

                trackTemperetureChanges();
            });
    }

    updateViewPresencePreset(presencePresets) {
        presencePresets = presencePresets || this.state.presencePresets;

        if(!presencePresets) return;

        let presencePresetMap = {};

        presencePresets.forEach(presencePreset => {
            presencePresetMap[presencePreset.get(db.RoomPresencePreset.APPLIES_AT_DAY)] =
                presencePreset.get(db.RoomPresencePreset.PRESENCE_PRESET);
        });

        let days = [0, 1, 2, 3, 4, 5, 6];

        days.forEach(dayIndex =>
            this.setDayArray(dayIndex, presencePresetMap[dayIndex])
        );
    }

    async saveProgram() {
        let {t} = this.props;

        //Save room min max
        this.props.room.set(db.Room.TEMP_MIN, this.state.tempMin);
        this.props.room.set(db.Room.TEMP_MAX, this.state.tempMax);
        await this.props.room.save();
        await this.props.onRoomDetailSaved();
        this.setState({editTemperatures: false});

        if(this.state.presenceForecastEnabled) {
            this.getAutomaticPresenceForecast();
            swal({title: t('Saved'), text: ' ', icon: 'success', button: [''], timer: 1000});
            return;
        }

        let dayHours = this.getWeekMatrix();

        let roomPresencePresets = Object.keys(dayHours).map((day, i) => {
            let roomPresencePreset = new Parse.Object('RoomPresencePreset');
            roomPresencePreset.set('appliesAtDay', i);
            roomPresencePreset.set('presencePreset', dayHours[i]);
            roomPresencePreset.set('isFixed', true);

            let room = new Parse.Object('Room');
            room.id = this.props.roomId;
            roomPresencePreset.set('room', room);

            return roomPresencePreset;
        });

        // Save changes
        try {
            await Parse.Cloud.run('client-preset-delete-all-presence-presets-for-room', {roomId: this.props.roomId});
            await Parse.Object.saveAll(roomPresencePresets);
            await this.getAllRoomPresencePresetForRoom();
            swal({
                title: t('Program saved successfully'),
                text: ' ',
                icon: 'success',
                button: [''],
                timer: 1000
            })
        } catch (err) {
            return await manageError(err);
        }
    }

    handleEditProgram() {
        this.setState({
            editTemperatures: true
        });
    }

    handleCancelEditProgram() {
        this.setState({
            editTemperatures: false,
            tempMin: this.props.room.get(db.Room.TEMP_MIN),
            tempMax: this.props.room.get(db.Room.TEMP_MAX)
        });
    }

    labelCels(tempMin, tempMax){
        // Labelling cells
        $.each($('.program__item'), function (i, item) {
            $(item).empty();
            $(item).append('<b></b>');
            $(item).append('<span class=\'tempr tempr18\'>' + tempMin + '°C</span><span class=\'tempr tempr20\'>' + tempMax + '°C</span>');
        });
    }

    initProgramHandlers(tempMin, tempMax) {
        let self = this;
        let t = this.props.t;

        $('body').unbind();
        $(document).unbind();
        $('.program__tr').unbind();

        $('.program__scroll.right').on('click', function () {
            let scroll = $('.program').scrollLeft() + 50;
            $('.program').scrollLeft(scroll);
            $('.program__scroll.left').css('display', 'flex');
        });

        $('.program__scroll.left').on('click', function () {
            let scroll = $('.program').scrollLeft() - 50;
            $('.program').scrollLeft(scroll);
            $('.program__scroll.right').show();
        });

        $('.program').on('scroll', function () {
            let $width = $('.program').outerWidth();
            let $scrollWidth = $('.program')[0].scrollWidth;
            let $scrollLeft = $('.program').scrollLeft();

            if ($scrollWidth - $width === $scrollLeft) {
                $('.program__scroll.right').hide();
            }
            if ($scrollLeft === 0) {
                $('.program__scroll.left').hide();
            }
        });

        $('.js-activate-table').on('click', function () {
            $('.js-activate-table').hide();
            $('.program__hours').addClass('active');
            $('.program__cells').addClass('active').removeClass('disabled');
        });

        $('.js-cancel').on('click', () => {
            $('.js-activate-table').show();
            $('.program__cells').removeClass('active').addClass('disabled');
            self.updateViewPresencePreset();
            let tempMin = this.props.room.get(db.Room.TEMP_MIN);
            let tempMax = this.props.room.get(db.Room.TEMP_MAX);
            self.labelCels(tempMin, tempMax);
            trackTemperetureChanges();
        });

        $('.js-reset').on('click',  () => {
            swal({
                title: t('Are you sure?'),
                text: t('Once you reset, you will not be able to recover this config'),
                icon: 'warning',
                buttons: [t('Cancel'), t('Reset')],
                dangerMode: true
            })
                .then((willDelete) => {
                    if (willDelete) {
                        return Parse.Cloud.run('client-preset-delete-all-presence-presets-for-room',
                            {roomId: self.props.roomId})
                            .then(() => {
                                $('.js-activate-table').show();
                                $('.program__cells').removeClass('active');
                                $('.program__item').removeClass('active');
                                $('.program__hours').removeClass('active');

                                this.setState({editTemperatures: false});

                                return self.getAllRoomPresencePresetForRoom();
                            })
                            .then(() => swal({title: t('Saved'), text: ' ', icon: 'success', button: [''], timer: 1000}))
                            .catch(function (err) {
                                console.error(err, err.stack);
                            });
                    }
                });
        });

        $('.js-save').on('click', function () {
            $('.js-activate-table').show();
            $('.program__cells').removeClass('active').addClass('disabled');
            // $('.program__hours').removeClass('active');

            self.saveProgram();
        });

        var isMouseDown = false;

        $('body').on('mousedown', '.program__cells.active .program__item', function () {
            if (!isMouseDown) {
                isMouseDown = true;
                $(this).toggleClass('active');
            }

            return false;
        }).on('mouseenter', '.program__cells.active .program__item', function () {
            if (isMouseDown) {
                $(this).toggleClass('active');
                trackTemperetureChanges();
            }
        });

        $(document).mouseup(function () {
            isMouseDown = false;
        });

        this.labelCels(tempMin, tempMax);

        trackTemperetureChanges();

        $('.program__tr').on('click mouseup', function () {
            trackTemperetureChanges();
        });
    }

    async getPresenceForecastEnabled() {
        let presenceForecastEnabled = this.props.room.get(db.Room.PRESENCE_FORECAST_ENABLED);
        this.setState({presenceForecastEnabled});

        return presenceForecastEnabled;
    }

    async getAutomaticPresenceForecast() {
        let forecast = this.props.room.get(db.Room.WEEKLY_PRESENCE_FORECAST);
        if (forecast) {
            let days = [0, 1, 2, 3, 4, 5, 6];
            days.forEach(dayIndex =>
                this.setDayArray(dayIndex, forecast[dayIndex])
            );
        }
        trackTemperetureChanges();
    }

    handleProgramTemperatureChange(operation, temperatureType) {

        if (temperatureType === 'min') {
            if (operation === 'increment') {
                this.setState((prevState) => {
                    return prevState.tempMin + 0.5 <= prevState.tempMax ? {
                        tempMin: prevState.tempMin + 0.5
                    } : {};
                }, () =>  this.labelCels(this.state.tempMin, this.state.tempMax));
            } else if (operation === 'decrement') {
                if (this.state.tempMin - 0.5 < this.state.allowedTemperatureRange[temperatureType]) {
                    const errorMessage = this.props.t('Lowest allowed temperature') + ': ' + this.state.allowedTemperatureRange[temperatureType] + '°C';
                    swal(errorMessage, '', 'error');
                    return;
                }

                this.setState((prevState) => ({
                    tempMin: prevState.tempMin - 0.5
                }), () =>  this.labelCels(this.state.tempMin,this.state.tempMax));
            }
        } else if (temperatureType === 'max') {
            if (operation === 'increment') {
                if (this.state.tempMax + 0.5 > this.state.allowedTemperatureRange[temperatureType]) {
                    const errorMessage = this.props.t('Maximum allowed temperature') + ': ' + this.state.allowedTemperatureRange[temperatureType] + '°C';
                    swal(errorMessage, '', 'error');
                    return;
                }

                this.setState((prevState) => ({
                    tempMax: prevState.tempMax + 0.5
                }), () =>  this.labelCels(this.state.tempMin,this.state.tempMax));
            } else if (operation === 'decrement') {
                this.setState((prevState) => {
                    return prevState.tempMax - 0.5 >= prevState.tempMin ? {
                        tempMax: prevState.tempMax - 0.5
                    } : {};
                }, () =>  this.labelCels(this.state.tempMin,this.state.tempMax));
            }
        }

    }

    async handleAutomaticProgramChange() {
        const value = this.state.presenceForecastEnabled;

        if (!value) {

            this.props.room.set(db.Room.PRESENCE_FORECAST_ENABLED, true);
            this.props.room.set(db.Room.PRESENCE_DATA_CONFIRMATION_DATE, moment().toDate());
            this.props.room.set(db.Room.PRESENCE_DATA_CONFIRMATION_USER, Parse.User.current());

            try {
                await this.props.room.save();
            } catch (err) {
                console.error(err);
            }
            this.setState({presenceForecastEnabled: true});
            this.getAutomaticPresenceForecast();
        } else {

            this.props.room.set(db.Room.PRESENCE_FORECAST_ENABLED, false);
            try {
                await this.props.room.save();
            } catch (err) {
                console.error(err);
            }
            this.setState({presenceForecastEnabled: false});
            this.getAllRoomPresencePresetForRoom();
        }
    }

    render() {
        const {t, room} = this.props;
        let active = this.state.presenceForecastEnabled ? 'automatic' : '';

        const hasWritePermission = localStorage.getItem('user:hasWritePermission') === 'true';
        const roomHasPresenceSensors = room.get(db.Room.CO2) != null;

        return <div className="full-w">

            <div className={`program-card-header-mobile ${this.state.editTemperatures ? 'edit-mode': ''}`}>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}} >
                    <h2>{t('Program')}</h2>
                    {
                        hasWritePermission && <button type="button" className="edit-modal-btn js-activate-table"
                            onClick={this.handleEditProgram}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                <path fill="#D5D5D5"
                                    d="M24.4241432,10.8047398 L19.9365098,6 L5,20.9365098 L5,25.5323589 L9.59584916,25.5323589 L24.4241432,10.8047398 Z M8.71980568,23.7743806 L6.78695388,23.7743806 L6.78695388,21.6255487 L19.9365098,8.71098229 L22.0119725,10.7710606 L8.71980568,23.7743806 Z M11.8937737,26.6813212 L14.0149192,24.8044897 L27.5900002,24.8044897 L25.6813212,26.6813212 L11.8937737,26.6813212 Z"/>
                            </svg>
                        </button>
                    }
                </div>

                {
                    hasWritePermission && <div className="automatic-controls">
                        <p>{t('Automatic')}</p>
                        <input type="checkbox" id="automatic-program" className="custom-toggle" checked={this.state.presenceForecastEnabled} /*onChange={this.handleAutomaticProgramChange}*/ data-toggle="modal" data-target="#presenceDataModal"/>
                        <label htmlFor="automatic-program" className="ml-2 custom-toggle-btn"></label>
                    </div>
                }

                {
                    hasWritePermission && <div className="program-edit">
                        <button type="submit" className="submit-form-btn reset js-reset">{t('Reset')}</button>
                        <button type="submit" className="submit-form-btn transparent js-cancel"
                            onClick={this.handleCancelEditProgram}>{t('Cancel')}</button>
                        <button type="submit" className="submit-form-btn save js-save">{t('Save')}</button>
                    </div>
                }
            </div>

            {hasWritePermission && !this.state.editTemperatures &&
                <div>
                    <div className='automatic-mode-switcher'>
                        <ProgramSwitch onChange={this.showProgramSwitcherModal} options={[
                            {value: 'manual', label: t('Manual')},
                            {value: 'automatic', label: t('Automatic')}
                        ]} value={this.state.presenceForecastEnabled ? {value: 'automatic'} : {value: 'manual'}}/>
                    </div>
                    <div id='presetDataModalOpener' data-toggle="modal" data-target="#presenceDataModal"></div>
                </div>
            }

            <div className={`card card-outer full-w program-card ${this.state.editTemperatures ? 'edit-mode': ''}`}>
                <div className="card-header">
                    <div className="card-title d-flex justify-content-between align-items-center w-100">

                        <div className="d-flex flex-row program-title">
                            <h2>{t('Program')}</h2>
                            {
                                hasWritePermission && <button type="button" className="edit-modal-btn js-activate-table"
                                    onClick={this.handleEditProgram}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                        <path fill="#D5D5D5"
                                            d="M24.4241432,10.8047398 L19.9365098,6 L5,20.9365098 L5,25.5323589 L9.59584916,25.5323589 L24.4241432,10.8047398 Z M8.71980568,23.7743806 L6.78695388,23.7743806 L6.78695388,21.6255487 L19.9365098,8.71098229 L22.0119725,10.7710606 L8.71980568,23.7743806 Z M11.8937737,26.6813212 L14.0149192,24.8044897 L27.5900002,24.8044897 L25.6813212,26.6813212 L11.8937737,26.6813212 Z"/>
                                    </svg>
                                </button>
                            }
                        </div>

                        <div className={`program-temperatures ${this.state.editTemperatures ? 'editing-temperatures' : ''}`}>
                            <ProgramTemperature type='min' t={t} value={this.state.tempMin}
                                edit={this.state.editTemperatures && hasWritePermission}
                                onChange={this.handleProgramTemperatureChange}/>
                            <ProgramTemperature type='max' t={t} value={this.state.tempMax}
                                edit={this.state.editTemperatures && hasWritePermission}
                                onChange={this.handleProgramTemperatureChange}/>
                        </div>

                        <div className="automatic-controls">
                            <p>{t('Automatic program')}</p>
                            <input type="checkbox" id="automatic-program" className="custom-toggle" checked={this.state.presenceForecastEnabled}
                                data-toggle="modal" data-target="#presenceDataModal" disabled={!hasWritePermission}/>
                            <label htmlFor="automatic-program" className="ml-2 custom-toggle-btn"></label>
                        </div>

                        <div className="program-edit">
                            <button type="submit" className="submit-form-btn reset js-reset" disabled={!hasWritePermission}>{t('Reset')}</button>
                            <button type="submit" className="submit-form-btn transparent js-cancel"
                                onClick={this.handleCancelEditProgram} disabled={!hasWritePermission}>{t('Cancel')}</button>
                            <button type="submit" className="submit-form-btn save js-save" disabled={!hasWritePermission}>{t('Save')}</button>
                        </div>

                    </div>
                </div>
                <div className={`program-temperatures-mobile ${this.state.editTemperatures ? 'editing-temperatures-mobile' : ''}`}>
                    <ProgramTemperature type='min' t={t} value={this.state.tempMin}
                        edit={this.state.editTemperatures && hasWritePermission}
                        onChange={this.handleProgramTemperatureChange}/>
                    <ProgramTemperature type='max' t={t} value={this.state.tempMax}
                        edit={this.state.editTemperatures && hasWritePermission}
                        onChange={this.handleProgramTemperatureChange}/>
                </div>

                <div className={`program ${active}`}>
                    <div className="program__outer">
                        <div className="program__days">
                            <span>{t('Sun')}</span>
                            <span>{t('Mon')}</span>
                            <span>{t('Tue')}</span>
                            <span>{t('Wed')}</span>
                            <span>{t('Thu')}</span>
                            <span>{t('Fri')}</span>
                            <span>{t('Sat')}</span>
                        </div>
                        <div className="program__cells disabled">
                            <div className="program__tr">
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                            </div>
                            <div className="program__tr">
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                            </div>
                            <div className="program__tr">
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                            </div>
                            <div className="program__tr">
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                            </div>
                            <div className="program__tr">
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                            </div>
                            <div className="program__tr">
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                            </div>
                            <div className="program__tr">
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                                <div className="program__item"><b></b></div>
                            </div>
                        </div>
                    </div>
                    <div className={`program__hours ${active}`}>
                        <span className="non-hour"></span>
                        {/* eslint-disable react/jsx-no-literals */}
                        <span className="static">0:00</span>
                        <span>1:00</span>
                        <span>2:00</span>
                        <span className="static">3:00</span>
                        <span>4:00</span>
                        <span>5:00</span>
                        <span className="static">6:00</span>
                        <span>7:00</span>
                        <span>8:00</span>
                        <span className="static">9:00</span>
                        <span>10:00</span>
                        <span>11:00</span>
                        <span className="static">12:00</span>
                        <span>13:00</span>
                        <span>14:00</span>
                        <span className="static">15:00</span>
                        <span>16:00</span>
                        <span>17:00</span>
                        <span className="static">18:00</span>
                        <span>19:00</span>
                        <span>20:00</span>
                        <span className="static">21:00</span>
                        <span>22:00</span>
                        <span>23:00</span>
                        {/* eslint-enable react/jsx-no-literals */}
                    </div>
                </div>
                <div className="program__scroll left">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <g fill="none" fillRule="evenodd">
                            <path fill="#666" fillRule="nonzero" stroke="#9B9B9B" strokeWidth=".3"
                                d="M15.9334476,11.4592757 C16.0743064,11.5893093 16.15,11.7895035 16.15,12 C16.15,12.203392 16.0781692,12.3844231 15.9376779,12.5361679 L9.08614552,19.9158594 C8.94367967,20.0697373 8.77028916,20.15 8.57416268,20.15 C8.39076414,20.15 8.20003245,20.0647545 8.06671201,19.9205461 C7.77851802,19.6352068 7.77851802,19.1502146 8.06233095,18.8436675 L14.4173126,11.9999986 L8.06671201,5.16085618 C7.77851802,4.87551683 7.77851802,4.39052464 8.05788368,4.08898664 C8.32763047,3.77114547 8.79636298,3.77114547 9.08600329,4.08398715 L15.9334476,11.4592757 Z"
                                transform="rotate(90 12 12)"/>
                            <rect width="24" height="24"/>
                        </g>
                    </svg>
                </div>
                <div className="program__scroll right">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <g fill="none" fillRule="evenodd">
                            <path fill="#666" fillRule="nonzero" stroke="#9B9B9B" strokeWidth=".3"
                                d="M15.9334476,11.4592757 C16.0743064,11.5893093 16.15,11.7895035 16.15,12 C16.15,12.203392 16.0781692,12.3844231 15.9376779,12.5361679 L9.08614552,19.9158594 C8.94367967,20.0697373 8.77028916,20.15 8.57416268,20.15 C8.39076414,20.15 8.20003245,20.0647545 8.06671201,19.9205461 C7.77851802,19.6352068 7.77851802,19.1502146 8.06233095,18.8436675 L14.4173126,11.9999986 L8.06671201,5.16085618 C7.77851802,4.87551683 7.77851802,4.39052464 8.05788368,4.08898664 C8.32763047,3.77114547 8.79636298,3.77114547 9.08600329,4.08398715 L15.9334476,11.4592757 Z"
                                transform="rotate(90 12 12)"/>
                            <rect width="24" height="24"/>
                        </g>
                    </svg>
                </div>

                <div className="modal fade" id="presenceDataModal" tabIndex="-1" role="dialog"
                    aria-labelledby="presenceDataModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content confirmation">
                            <div className="modal-header">
                                <h5 className="modal-title" id="presenceDataModalLabel">{t('Presence Data')}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <path fill="#222"
                                            d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                    </svg>
                                </button>
                            </div>
                            {
                                roomHasPresenceSensors&& <div className="modal-body">
                                    {!active &&
                                <p>{t('Confirm that you have the permission to see presence data of room\'s users.')}</p>}
                                    {active && <p>{t('Are you sure you want to activate manual room presence')}</p>}
                                    <div className="actions">
                                        <button type="button" className="cancel-form-btn"
                                            data-dismiss="modal">{t('Close')}</button>
                                        {!active && <button type="button" className="submit-form-btn" data-dismiss="modal"
                                            onClick={this.handleAutomaticProgramChange}>{t('Confirm')}</button>}
                                        {active && <button type="button" className="submit-form-btn" data-dismiss="modal"
                                            onClick={this.handleAutomaticProgramChange}>{t('Yes')}</button>}

                                    </div>
                                </div>
                            }
                            {
                                !active && !roomHasPresenceSensors && <div className="modal-body">
                                    <p>{t('The automatic mode is not available in a room without CLEVER Sense.')}</p>
                                    <div className="actions">
                                        <button
                                            type="button"
                                            className="cancel-form-btn"
                                            data-dismiss="modal">
                                            {t('Close')}
                                        </button>
                                    </div>
                                </div>
                            }
                            {
                                !roomHasPresenceSensors && active && <div className="modal-body">
                                    <p>{t('Are you sure you want to activate manual room presence')}</p>
                                    <div className="actions">
                                        <button type="button" className="cancel-form-btn"
                                            data-dismiss="modal">{t('Close')}
                                        </button>
                                        <button type="button" className="submit-form-btn" data-dismiss="modal"
                                            onClick={this.handleAutomaticProgramChange}>{t('Confirm')}
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

RoomProgram.propTypes = {
    t: PropType.func,
    roomId: PropType.string,
    room: PropType.object,
    onRoomDetailSaved: PropType.func,
};