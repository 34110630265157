import PropTypes from 'prop-types';

import React, {Component} from 'react';
import translate from '../translate';
import db from '../../lib/structure';
import swal from 'sweetalert';
import Parse from 'parse';
import {VerifaliaRestClient} from 'verifalia';

const isValidEmail = require('is-valid-email');

const compress = new Compress();

const verifalia = new VerifaliaRestClient({
    username: 'fca8acfd21c14cbe84e96ce20a175e63',
    password: 'x3U)vHVgESqU'
});

class LoginCard extends Component {

    constructor(props) {
        super(props);

        this.loginAccordeon = React.createRef();

        this.state = {
            loginCardOpen: false,
            username: '',
            password: ''
        };

        this.login = this.login.bind(this);
    }

    componentDidMount(){}

    async login(){
        const {t} = this.props;

        try {
            await Parse.User.logIn(this.state.username, this.state.password);
            await swal({title: t('Success'), text: ' ', icon: 'success', button: [''], timer: 1000});
            location.reload();
        } catch (e) {
            console.error(e.message);
            swal(t('Error'), t(e.message), 'error');
        }
    }

    async logout(){
        await Parse.User.logOut();
        location.reload();
    }

    render() {
        const {t}  = this.props;

        let currentUser = Parse.User.current();

        return <div className="informer">
            <div className="accordeon" id='room-facility-manager-feedback-accordeon' ref={this.loginAccordeon}>
                <div className={`accordeon__heading ${this.state.loginCardOpen ? 'is-active' : ''}`}
                    onClick={() => this.setState({
                        loginCardOpen: !this.state.loginCardOpen
                    })}
                >
                    <div className="informer__header" >
                        <div className="informer__heading">{currentUser ? t('Logout'): t('Login as FM')}</div>
                        <div className="informer__desc">
                            {currentUser ? t('Logout from your account') : t('Are you the FM? Log in to access advanced features.')}
                        </div>
                    </div>
                </div>
                <div className="accordeon__pane">
                    <div className="accordeon__pane-main" style={{overflow: 'hidden'}}>
                        {
                            !currentUser && <>
                                <div className={'input-field'}>
                                    <label htmlFor={db._User.USERNAME}  className="datepicker__label">{t('Username')}<span>*</span>
                                    </label>
                                    <div className="input-field__input">
                                        <input className="input" type="text" name={db._User.USERNAME} placeholder={t('Please enter your username / email')} required
                                            value={this.state.username}
                                            onChange={(e) => this.setState({username: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className={'input-field'}>
                                    <label htmlFor={db._User.PASSWORD}  className="datepicker__label">{t('Password')}<span>*</span>
                                    </label>
                                    <div className="input-field__input">
                                        <input className="input" type="text" name={db._User.PASSWORD} placeholder={t('Please enter your password')} required
                                            value={this.state[db._User.PASSWORD]}
                                            onChange={(e) => this.setState({[db._User.PASSWORD]: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="buttons-wr">
                                    <button className={'btn btn--primary'}
                                        onClick={() => this.login()}>{t('Login')}</button>
                                </div>
                            </>
                        }
                        {
                            currentUser && <div className="buttons-wr">
                                <button className={'btn btn--primary'}
                                    onClick={() => this.logout()}>{t('Logout')}</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>;
    }
}

LoginCard.propTypes = {
    t: PropTypes.any,
    roomId: PropTypes.string,
    homeId: PropTypes.string,
    i18n: PropTypes.object,
    onLoginSucceded: PropTypes.func
};

export default translate(LoginCard);