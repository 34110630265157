if ($(window).width() < 992 && $(window).width() > 768) {
    $('.sidebar').addClass('active');
}

$(document).ready(function() {

    $('body').on('click', 'a[data-toggle="collapse"]', function() {
        if (!$(this).hasClass('minus')) {
            $('a[data-toggle="collapse"]').removeClass('minus');
            $(this).addClass('minus');
        } else {
            $(this).removeClass('minus');
        }
    });

    /*$('.view-more').on('click', function() {
        $(this).toggleClass('active').siblings('.card-wrap').toggleClass('active');
        if ($(this).hasClass('active')) {
            $(this).text('Hide');
        } else {
            $(this).text('View more');
        }
    });*/

    //This just adds an active to brother and to the element itself
    $('.view-more-tabs').on('click', function() {
        $(this).toggleClass('active').siblings('.collapse-tabs').toggleClass('active');
        if ($(this).hasClass('active')) {
            $(this).find('span').text('Hide');
        } else {
            $(this).find('span').text('View more');
        }
    });

    $('#chat__button').on('click', function () {
        if ($(window).width() < 578) {
            $('body').css('overflow','hidden');
        }
        $(this).toggleClass('opened');
        $('.new-conversation').removeClass('opened');
        $('.history-chat').removeClass('opened');
        $('#chat__window').toggleClass('opened');
    });

    $('.chat__button-close').on('click', function () {
        if ($(window).width() < 578) {
            $('body').css('overflow','visible');
        }
        $('#chat__button').toggleClass('opened');
        $('.chat__wr').removeClass('opened');
    });

    $('.js-new-conversation').on('click', function () {
        $('.new-conversation').addClass('opened');
    });

    $('.js-back-chat').on('click', function () {
        $(this).closest('.chat__wr').removeClass('opened');
    });

    $('.js-open-history').on('click', function () {
        $('.history-chat').addClass('opened');
    });
});


