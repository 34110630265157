'use strict';
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import _, {get, isEmpty, merge} from 'lodash';
import {connect} from 'react-redux';
import translate from '../translate';
import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import Footer from '../footer';
import Parse from 'parse';
import {floorNameFromNumber, manageError, splitRoomByFloor, toPointerFromId, userIsInRole} from '../../lib/util';
import swal from 'sweetalert';
import db from '../../lib/structure';
import localStorageDb from '../../lib/localStorageStructure';
import Loader from '../loader';
import deleteIcon from '../../assets/images/ic_delete_grey.svg';
import settingsGreyIcon from '../../assets/images/ic_settings_grey.svg';
import editGreyicon from '../../assets/images/ic_edit_grey.svg';
import structureLocalStorage from '../../lib/localStorageStructure';

class UserManagementPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            users: [],
            currentUser: null,
            isAdmin: true, // default true, otherwise it always redirects since render gets called multiple times
            userToDelete: null,
            sort: 'ascending',
            orderBy: db._User.LAST_NAME,
            displayDropdown: false,
            orderByTypes: [
                {
                    name: 'First Name',
                    dbName: db._User.NAME
                },
                {
                    name: 'Last Name',
                    dbName: db._User.LAST_NAME
                },
                {
                    name: 'Email',
                    dbName: db._User.EMAIL
                }
            ],
            loading: false,
            roomsByFloor: [],
            buildingManagers: [],
            newUser: {}
        };

        this.updatedFirstName = React.createRef();
        this.updatedLastName = React.createRef();
        this.updatedEmail = React.createRef();
        this.currentUserId = null;

        this.loadUsers = this.loadUsers.bind(this);
        this.loadRooms = this.loadRooms.bind(this);
        this.deleteConfirmed = this.deleteConfirmed.bind(this);
        this.sortTable = this.sortTable.bind(this);
        this.createNewUser = this.createNewUser.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    async componentDidMount(){
        try {
            let buildingManagers = [];

            await this.loadUsers();
            await this.loadRooms();

            const buildingAdministratorId = localStorage.getItem('buildingAdministratorId')

            for (let user of this.state.users) {
                let admin = user.id === buildingAdministratorId;
                if (admin) buildingManagers.push(user.id);
            }
            this.setState({buildingManagers})

        } catch (err) {
            console.error(err);
            manageError(err, this.props);
        }
    }

    async loadUsers(){
        let users = [];
        try {
            this.setState({loading: true});
            users = await Parse.Cloud.run('getUserInfo', {
                'sort': this.state.sort,
                'orderBy': this.state.orderBy,
                homeId: localStorage.getItem(localStorageDb.BUILDING_SELECTION)
            });

            users = users
                .filter(user =>
                    user.get(db._User.TYPE_OF_CUSTOMER) !== db._User.TYPE_OF_CUSTOMER$READ_ONLY_DEMO_USER ||
                    user.id === Parse.User.current().id
                );
        } catch (e) {
            console.error(e);
        } finally {
            this.setState({users: users, loading: false});
        }
    }

    editButtonPressed(user){
        this.setState({
            currentUser: user
        });

        user.get(db._User.NAME) !== undefined ? this.updatedFirstName.current.value = user.get(db._User.NAME) : this.updatedFirstName.current.value = '';
        user.get(db._User.LAST_NAME) !== undefined ? this.updatedLastName.current.value = user.get(db._User.LAST_NAME) : this.updatedLastName.current.value = '';
        user.get(db._User.EMAIL) !== undefined ? this.updatedEmail.current.value = user.get(db._User.EMAIL) : this.updatedEmail.current.value = '';
        this.currentUserId = user.id;

    }

    saveButtonPressed(){
        Parse.Cloud.run('updateUserInfo', {
            firstName: this.updatedFirstName.current.value,
            lastName: this.updatedLastName.current.value,
            email: this.updatedEmail.current.value,
            currentUserId: this.currentUserId
        }).then(() => {
            this.loadUsers();
        })
            .catch(console.error);
    }

    async activeButtonPressed(e, user){
        let query = new Parse.Query('User');
        query.equalTo('objectId', user.id);
        let parseUser = await query.first({sessionToken: Parse.Session.current()});
        if (parseUser.get(db._User.ACTIVE) !== false) {
            parseUser.set(db._User.ACTIVE, false);
        } else {
            parseUser.set(db._User.ACTIVE, true);
        }
        await parseUser.save();
        await this.loadUsers();
    }

    deleteButtonPressed(user){
        this.setState({
            userToDelete: user
        });
    }

    async deleteConfirmed(){
        let currUser = this.state.userToDelete;
        let query = new Parse.Query(db.classes._User);

        query.equalTo('objectId', currUser.id);
        try {
            let user = await query.first()
            user.set(db._User.DELETED, true);
            await user.save();
            this.loadUsers();

            await swal('Success', 'User deleted sucessfully', 'success');
        } catch (err) {
            console.error(err);
            await swal('Error', err.message, 'error');
        }
    }



    async sortTable(direction, attribute){
        await this.setState({
            sort: direction,
            orderBy: attribute
        });
        await this.loadUsers();
    }

    async loadRooms(){
        let currentHome = localStorage.getItem('buildingSelection');
        let query = new Parse.Query('Room');
        query.equalTo('home', toPointerFromId(currentHome, 'Home'));
        query.include('home');
        query.notEqualTo(db.Room.HIDDEN, true);
        query.notEqualTo(db.Room.DELETED, true);

        let rooms = await query.find();
        let roomsByFloor = splitRoomByFloor(rooms);
        this.setState({roomsByFloor, rooms});
    }

    getRoomSelectionModal() {
        const {roomsByFloor} = this.state;
        const {t} = this.props;

        if (isEmpty(roomsByFloor)) return;

        return Object.keys(roomsByFloor).map(floor => {
            return (
                <div key={floor}>
                    <div className="floor-header">{t(floorNameFromNumber(floor))}</div>
                    {roomsByFloor[floor].map(room => {
                        return <div key={room.id} className="checkbox__wrapper">
                            <div className="checkbox-custom">
                                <input type="checkbox" checked={true} id={room.id}/>
                                <label htmlFor={room.id}>{room.get(db.Room.ROOM_NAME)}</label>
                            </div>
                        </div>
                    })}
                </div>
            )
        })

    }

    async createNewUser(){
        try {
            let user = this.state.newUser;
            user[db._User.USERNAME] = user[db._User.EMAIL];
            let requiredFields = [
                db._User.USERNAME,
                db._User.PASSWORD,
                db._User.EMAIL,
                db._User.CITY,
                db._User.LANGUAGE,
                db._User.TYPE_OF_CUSTOMER
            ];
            const languages = ['IT', 'DE', 'FR', 'EN'];
            const typesOfCustomer = [
                db.Home.TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER,
                db.Home.TYPE_OF_CUSTOMER$FULL_SYSTEM_CUSTOMER,
                db.Home.TYPE_OF_CUSTOMER$READ_ONLY_DEMO_USER
            ];

            for (let requiredField of requiredFields) {
                if (_.isEmpty(user[requiredField])) throw new Error(`${requiredField} is required`);
            }

            let userLangauge = user[db._User.LANGUAGE];
            let typeOfCustomer = user[db._User.TYPE_OF_CUSTOMER];
            let writeAccess = user['writeAccess'] === true;
            delete user.writeAccess;

            if(languages.indexOf(userLangauge) < 0) throw new Error(`Language should be on of ${languages.join(',')}`);
            if(typesOfCustomer.indexOf(typeOfCustomer) < 0) throw new Error(`TypeOfCustomer should be on of ${typesOfCustomer.join(',')}`);

            let userObject = new Parse.User(_.merge({
                utcTime: new Date(),
                isDevice: false
            }, user));

            let oldSessionToken = Parse.User.current().getSessionToken();

            let signedUser = await userObject.signUp();

            await Parse.User.become(oldSessionToken);
            let selectedBuildingId = localStorage.getItem(structureLocalStorage.BUILDING_SELECTION);

            await Parse.Cloud.run('link-user-to-building-with-role', {
                userId: signedUser.id,
                buildingId: selectedBuildingId,
                writeAccess,
                administrator: false
            });

            await swal('Success', 'User signup completed', 'success');
        } catch (e) {
            await swal('Error', e.message, 'error');
        }
    }

    onInputChange(event){
        let name = event.target.name;
        let value = event.target.value;

        this.setState(prev => {
            prev.newUser[name] = value;

            return prev;
        });
    }

    render(){
        const isAdmin = localStorage.getItem('user:isAdmin') === 'true';
        const hasWritePermission = localStorage.getItem('user:hasWritePermission') === 'true';
        const isSupportRole = userIsInRole(db.roles.SUPPORT);
        let isZIDBuilding = localStorage.getItem('buildingSelection') === 'X6EitoEDpX';
        if (!isAdmin && !isZIDBuilding && !isSupportRole) {
            this.props.history.push('/invalidLink');
        }
        const languages = ['IT', 'DE', 'FR', 'EN'];
        const typesOfCustomer = [
            db.Home.TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER,
            db.Home.TYPE_OF_CUSTOMER$FULL_SYSTEM_CUSTOMER,
            db.Home.TYPE_OF_CUSTOMER$READ_ONLY_DEMO_USER
        ];

        const {t} = this.props;
        return <div className={'page-usermanagement'}>
            {
                get(this.props, 'shared.generalError.visible') && <div className={'alert alert-danger'}>
                    {get(this.props, 'shared.generalError.message')}
                </div>
            }
            <div className="wrapper d-flex align-items-stretch">
                <Sidebar {...this.props}/>
                <div className="content">
                    <Navbar {...this.props}/>
                    <div className="inner user-management-container">
                        <Breadcrumbs {...this.props} elements={[
                            {
                                link: '/dashboard',
                                name: t('Home')
                            },
                            {
                                link: null,
                                name: t('User Management')
                            }
                        ]}/>


                        <div>
                            {
                                isSupportRole && <button
                                    className='room-reporting-export-button'
                                    style={{
                                        float: 'right',
                                        position: 'absolute',
                                        right: 35
                                    }}
                                    data-toggle="modal"
                                    data-target="#newUser"
                                >
                                    {t('New user')}
                                </button>
                            }

                            <h1>{t('User Management')}</h1>
                        </div>

                        {this.state.loading ? <Loader/> : ''}
                        <div
                            className={`select-custom floor-select sort-select ${this.state.displayDropdown ? 'active' : ''}`}
                            onClick={() => {
                                this.setState({displayDropdown: !this.state.displayDropdown})
                            }}
                        >
                            <div className="select-custom-status">{t('Sort by')}</div>
                            <ul className="select-custom-list">
                                {
                                    this.state.orderByTypes.map(type => {
                                        return <li key={type.name}
                                            className="select-custom-item"
                                            onClick={() => {
                                                this.sortTable('ascending', type.dbName)
                                            }}
                                        >{t(type.name)}</li>
                                    })
                                }
                            </ul>
                        </div>
                        <div
                            className="grid-cols flex-xs-column-reverse flex-sm-column-reverse flex-md-column-reverse
                            flex-lg-row">
                            <div className="card card-outer full-w">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>
                                                {t('Username')}
                                                <div className="sorting-header">
                                                    <div className="sorting-header__arrow"
                                                        onClick={(() => this.sortTable('ascending', db._User.USERNAME))}/>
                                                    <div className="sorting-header__arrow"
                                                        onClick={(() => this.sortTable('descending', db._User.USERNAME))}/>
                                                </div>
                                            </th>

                                            <th className="hide-lg">
                                                {t('First Name')}
                                                <div className="sorting-header">
                                                    <div className="sorting-header__arrow"
                                                        onClick={(() => this.sortTable('ascending', db._User.NAME))}/>
                                                    <div className="sorting-header__arrow"
                                                        onClick={(() => this.sortTable('descending', db._User.NAME))}/>
                                                </div>
                                            </th>

                                            <th className="hide-lg">
                                                {t('Last Name')}
                                                <div className="sorting-header">
                                                    <div className="sorting-header__arrow"
                                                        onClick={(() => this.sortTable('ascending', db._User.LAST_NAME))}/>
                                                    <div className="sorting-header__arrow"
                                                        onClick={(() => this.sortTable('descending', db._User.LAST_NAME))}/>
                                                </div>
                                            </th>

                                            <th className="hide-lg">
                                                {t('Email')}
                                                <div className="sorting-header">
                                                    <div className="sorting-header__arrow"
                                                        onClick={(() => this.sortTable('ascending', db._User.EMAIL))}/>
                                                    <div className="sorting-header__arrow"
                                                        onClick={(() => this.sortTable('descending', db._User.EMAIL))}/>
                                                </div>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.users.map(user =>
                                            <tr key={user.id}
                                                className={`${user.get(db._User.ACTIVE) !== false ? '' : 'disabled'} ${hasWritePermission? '': 'read-only'}`}>
                                                <td>
                                                    {user.get(db._User.USERNAME)}
                                                    <small className="m-download-date">{user.get(db._User.EMAIL)}</small>
                                                </td>
                                                <td className="hide-lg">
                                                    {user.get(db._User.NAME)}
                                                    <small className="m-download-date">{user.get(db._User.EMAIL)}</small>
                                                </td>
                                                <td className="hide-lg">
                                                    {user.get(db._User.LAST_NAME)}
                                                </td>
                                                <td className="hide-lg">
                                                    {user.get(db._User.EMAIL)}
                                                </td>
                                                <td>
                                                    <button
                                                        className="action-btn"
                                                        onClick={e => this.activeButtonPressed(e, user)}
                                                        disabled={!hasWritePermission}
                                                    >
                                                        {user.get(db._User.ACTIVE) !== false ? t('Active') : t('Inactive')}
                                                    </button>
                                                    <div className="actions">
                                                        {
                                                            hasWritePermission && <button data-toggle="modal"
                                                                data-target="#editUser"
                                                                className="edit-modal-btn"
                                                                onClick={() => this.editButtonPressed(user)}
                                                            >
                                                                <img src={editGreyicon}/>
                                                            </button>
                                                        }

                                                        {
                                                            NODE_ENV !== 'production' && <button
                                                                data-toggle="modal"
                                                                data-target="#roomAccess"
                                                                className="edit-modal-btn"
                                                            >
                                                                <img src={settingsGreyIcon}/>
                                                            </button>
                                                        }

                                                        {
                                                            NODE_ENV !== 'production' &&
                                                            this.state.buildingManagers.indexOf(user.id) === -1 &&
                                                            hasWritePermission &&
                                                            <button className="edit-modal-btn"
                                                                data-toggle="modal"
                                                                data-target="#deleteUser"
                                                                onClick={() => this.deleteButtonPressed(user)}>
                                                                <img src={deleteIcon}/>
                                                            </button>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/*Edit User Modal*/}
                    <div className="modal fade" id="editUser" tabIndex="-1" role="dialog"
                        aria-labelledby="editRoomLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content edit-photo">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="editUserLabel">{t('Edit user information')}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                            viewBox="0 0 18 18">
                                            <path fill="#222"
                                                d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                        </svg>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <form action="">
                                        <div className="form-group">
                                            <label>{t('First Name')}</label>
                                            <input type="text" ref={this.updatedFirstName}/>
                                        </div>
                                        <div className="form-group">
                                            <label>{t('Last name')}</label>
                                            <input type="text" ref={this.updatedLastName}/>
                                        </div>
                                        {/*<div className="form-group">
                                            <label>{t('Email')}</label>
                                            <input type="text" ref={this.updatedEmail}/>
                                        </div>*/}
                                        <button type="submit" className="submit-form-btn" data-dismiss='modal'
                                            onClick={(e) => this.saveButtonPressed(e)}
                                        >{t('Save')}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Create User Modal*/}
                    <div className="modal fade" id="newUser" tabIndex="-1" role="dialog"
                        aria-labelledby="editRoomLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content edit-photo">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="editUserLabel">{t('Create new user')}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                            viewBox="0 0 18 18">
                                            <path fill="#222"
                                                d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                        </svg>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <form action="">
                                        <div className="form-group">
                                            <label>{t('First Name')}</label>
                                            <input type="text" name={db._User.NAME} onChange={this.onInputChange} />
                                        </div>
                                        <div className="form-group">
                                            <label>{t('Last name')}</label>
                                            <input type="text" name={db._User.LAST_NAME} onChange={this.onInputChange}/>
                                        </div>
                                        <div className="form-group">
                                            <label>{t('Password')}</label>
                                            <input type="text" name={db._User.PASSWORD} onChange={this.onInputChange}/>
                                        </div>
                                        <div className="form-group">
                                            <label>{t('Email')}</label>
                                            <input type="text" name={db._User.EMAIL} onChange={this.onInputChange}/>
                                        </div>
                                        <div className="form-group">
                                            <label>{t(`Language (one of ${languages.join(',')})`)}</label>
                                            <input type="text" name={db._User.LANGUAGE} onChange={this.onInputChange}/>
                                        </div>
                                        <div className="form-group">
                                            <label>{t('City')}</label>
                                            <input type="text" name={db._User.CITY} onChange={this.onInputChange}/>
                                        </div>
                                        <div className="form-group">
                                            <label>{t(`Type of customer (one of ${typesOfCustomer.join(',')})`)}</label>
                                            <input type="text" name={db._User.TYPE_OF_CUSTOMER} onChange={this.onInputChange}/>
                                        </div>
                                        <div className="form-group">
                                            <div className="checkbox-custom">
                                                <input type="checkbox" id="allowHolidayOnQrCodePortal"
                                                    checked={this.state.newUser.writeAccess}
                                                    onChange={() => {
                                                        this.setState(prev => {
                                                            prev.newUser.writeAccess = !this.state.newUser.writeAccess;
                                                            return prev;
                                                        });
                                                    }}
                                                    disabled={false}
                                                />
                                                <label htmlFor="allowHolidayOnQrCodePortal">{t('Write access')}</label>
                                            </div>
                                        </div>
                                        <button type="submit" className="submit-form-btn" data-dismiss='modal'
                                            onClick={(e) => this.createNewUser()}
                                        >{t('Create new user')}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*delete User Modal*/}
                    <div className="user-management-container modal fade" id="deleteUser" tabIndex="-1" role="dialog"
                        aria-labelledby="deleteUserLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content edit-photo">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="deleteUserLabel">{t('Delete User')}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                            viewBox="0 0 18 18">
                                            <path fill="#222"
                                                d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                        </svg>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="text">{t('Are you sure you want to delete this user?')}
                                    </div>
                                    <form action="">
                                        <div className="actions">
                                            <button type="submit" className="submit-form-btn" data-dismiss="modal"
                                                onClick={(e) => this.deleteConfirmed(e)}>{t('Yes')}</button>
                                            <button className="cancel-form-btn" data-dismiss="modal">{t('Cancel')}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*start room access modal*/}
                    <div className="user-management-container modal fade" id="roomAccess" tabIndex="-1" role="dialog"
                        aria-labelledby="deleteUserLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content room-access">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="roomAccessLabel">{t('Access to rooms')}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                            viewBox="0 0 18 18">
                                            <path fill="#222"
                                                d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                        </svg>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <form className="change-temperature__form">
                                        {/* eslint-disable react/jsx-no-literals */}
                                        {
                                            this.getRoomSelectionModal()
                                        }
                                        {/* eslint-enable react/jsx-no-literals */}
                                        {/*todo: uncomment as soon as functionality implemented*/}
                                        {/*<div className="actions">*/}
                                        {/*    <button type="submit" className="submit-form-btn">{t('Save')}</button>*/}
                                        {/*    <button className="cancel-form-btn">{t('Cancel')}</button>*/}
                                        {/*</div>*/}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*end room access modal*/}
                    <Footer/>
                </div>
            </div>
        </div>
    }
}

UserManagementPage.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired
};

/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(UserManagementPage));