import structureLocalStorage from '../../lib/localStorageStructure';
import Parse from 'parse';
import * as db from '../../lib/structure';
import {toPointerFromId} from '../../lib/util';
import moment from "moment";

export async function requestCalendar(homeId, roomId, column = null, order = null, customDay = moment()){
    let selectedBuildingId = localStorage.getItem(structureLocalStorage.BUILDING_SELECTION);

    let query1 = new Parse.Query('CalendarEvent');
    query1.equalTo(db.CalendarEvent.HOME, toPointerFromId(selectedBuildingId, 'Home'));
    query1.doesNotExist(db.CalendarEvent.ROOM);

    let query2 = new Parse.Query('CalendarEvent');
    query2.equalTo(db.CalendarEvent.ROOM, toPointerFromId(roomId, 'Room'));

    let query3 = new Parse.Query(db.classes.CalendarEvent);

    try {
        query3._orQuery([query1, query2]);
        query3.greaterThan(db.CalendarEvent.END_DATE, customDay.toDate());
        query3.notEqualTo(db.CalendarEvent.DELETED, true);

        if (column != null) {
            if (order === 'ascending') {
                query3.ascending(column);
            } else {
                query3.descending(column);
            }
        } else {
            query3.ascending(db.CalendarEvent.START_DATE);
        }

        let events = await query3.find();

        return events;
    } catch (error) {
        console.error(error);
    }
}