import React from 'react';
import PropType from 'prop-types';
import * as db from '../../../lib/structure';
import chroma from 'chroma-js';
import {countNullValues, round} from '../../../lib/util';
import Loader from '../../loader';

const TOO_MUCH_EMPTY_VALUES_COUNT = 100;

export default class RoomCO2Chart extends React.Component {
    constructor(props){
        super(props);

        this.state = {};

        this.prepareMonthlyAverageCO2Report = this.prepareMonthlyAverageCO2Report.bind(this);
        this.getAverageCO2Matrix = this.getAverageCO2Matrix.bind(this);
        this.renderMatrix = this.renderMatrix.bind(this);
    }

    async componentDidUpdate(prevProps){
        if (this.props.homeReport === prevProps.homeReport) return;

        await this.prepareMonthlyAverageCO2Report();
    }

    colorByCO2(value){
        if(!value) {
            return 'grey';
        }

        if(value === 'N/A') {
            return 'grey';
        }

        if(value == null){
            return 'grey';
        }

        if(value < 200) value = 200;
        if(value >2500) value = 2500;

        let scale = chroma.scale(['rgba(122,194,87,0.40)','rgb(201,150,69)', 'rgb(217,63,63)'])
            .domain([200, 1200, 2500] , 7, 'log')
            .gamma(1.2);

        let color = scale(value).hex();

        return color;
    }

    async prepareMonthlyAverageCO2Report(){
        const homeReport = this.props.homeReport;
        if (!homeReport) {
            return;
        }

        const hourlyCo2Average = homeReport.get(db.RoomMonthlyReport.HOURLY_CO2_AVERAGE);

        const averageCO2Matrix = this.getAverageCO2Matrix(hourlyCo2Average);

        let numberNullValues = countNullValues(averageCO2Matrix);

        this.setState({numberNullValues}, () => {
            setTimeout(() => this.renderMatrix(averageCO2Matrix), 0);
        });
    }

    renderMatrix(averageCO2Matrix){
        if (averageCO2Matrix) {
            document.querySelectorAll('.avgCO2 .avgCO2__cells').forEach(el => {
                const rows = Array.from(el.children);
                rows.forEach((v, i) => {
                    const dark = Array.from(v.children);
                    dark.forEach((cell, num) => {
                        cell.children[0].style.background = this.colorByCO2(averageCO2Matrix[i][num]);
                        $(cell).append('<span class=\'co2 co2Avg\' style="display: block; color:black">' + (averageCO2Matrix[i][num] == null ? 'N/A' : averageCO2Matrix[i][num].toFixed(0)) + '</span>');
                    });
                });
            });
        }
    }

    getDay(day){
        return day;
    }

    getAverageCO2Matrix(hourlyCo2Average){
        var preset = [
            /*eslint-disable */
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Sunday
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Monday
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Tuesday
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Wednesday
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Thursday
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Friday
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Saturday
            /*eslint-enable */
        ];

        if(!hourlyCo2Average) return preset;

        return hourlyCo2Average;
    }

    render(){
        const {t, loading} = this.props;

        let reportDataAvailable = true;
        if (!this.props.homeReport || this.state.numberNullValues >= TOO_MUCH_EMPTY_VALUES_COUNT) {
            reportDataAvailable = false;
        }

        let monthsLabel = {
            1: t('January'),
            2: t('February'),
            3: t('March'),
            4: t('April'),
            5: t('May'),
            6: t('June'),
            7: t('July'),
            8: t('August'),
            9: t('September'),
            10: t('October'),
            11: t('November'),
            12: t('December')
        };

        let title = '';
        const isoWeek = this.props.homeReport?.get(db.HomeReport.ISO_WEEK);

        if(isoWeek != null)
            title = t('Weekly average CO2');
        else
            title = t('Monthly average CO2');

        return <div className="full-w">
            {
                <div className="card card-outer full-w co2-card">
                    <div className="card-header">
                        <div className="card-title d-flex">
                            <h2>
                                {title}
                            </h2>
                        </div>
                    </div>
                    {loading && <Loader />}
                    {
                        !loading && reportDataAvailable && <div className="avgCO2">
                            <div className="avgCO2__outer">
                                <div className="avgCO2__days">
                                    <span>{t('Sun')}</span>
                                    <span>{t('Mon')}</span>
                                    <span>{t('Tue')}</span>
                                    <span>{t('Wed')}</span>
                                    <span>{t('Thu')}</span>
                                    <span>{t('Fri')}</span>
                                    <span>{t('Sat')}</span>
                                </div>
                                <div className="avgCO2__cells">
                                    <div className="avgCO2__tr">
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                    </div>
                                    <div className="avgCO2__tr">
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                    </div>
                                    <div className="avgCO2__tr">
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                    </div>
                                    <div className="avgCO2__tr">
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                    </div>
                                    <div className="avgCO2__tr">
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                    </div>
                                    <div className="avgCO2__tr">
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                    </div>
                                    <div className="avgCO2__tr">
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                        <div className="avgCO2__item"><b></b></div>
                                    </div>
                                </div>
                            </div>
                            <div className="avgCO2__hours">
                                <span className="non-hour"></span>
                                {/* eslint-disable react/jsx-no-literals */}
                                <span className="static">0:00</span>
                                <span className="static">3:00</span>
                                <span className="static">6:00</span>
                                <span className="static">9:00</span>
                                <span className="static">12:00</span>
                                <span className="static">15:00</span>
                                <span className="static">18:00</span>
                                <span className="static">21:00</span>
                                <span className="static last">00:00</span>
                                {/* eslint-enable react/jsx-no-literals */}
                            </div>
                        </div>
                    }

                    {
                        !loading && !reportDataAvailable && <div className="text-center" id="co2-no-report-data">
                            {t('No report data available')}
                        </div>
                    }
                </div>
            }
        </div>

    }
}

RoomCO2Chart.propTypes = {
    t: PropType.func,
    roomId: PropType.string,
    room: PropType.object,
    homeReport: PropType.object,
    i18n: PropType.object,
    loading: PropType.bool
};