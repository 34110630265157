export const south = {
    '2017': [-5.05, -0.66, 3.31, 4.13, 8.62, 14.05, 14.17, 14.65, 7.85, 7.32, 0.35 - 3.49],
    '2018': [-1.45, -5.56, -1.24, 5.49, 9.01, 13, 15.19, 14.87, 11.83, 7.59, 1.9 - 1.33],
    '2019': [-4.45, 0.59, 1.29, 3.26, 5.28, 14.39, 15.31, 14.25, 10.58, 7.26, 0.19, -0.45],
    '2020': [-1.06, 0.29, 0.42, 5.59, 9, 10.87, 14.45, 14.38, 10.8, 4.17, 3.29, -2.55],
    '2021': [-4.57, -0.4, 0.1, 1.51, 5.65, 13.41, 13.44, 12.76, 11.14, 5.38, 1, -0.81],
    '2022': [-0.85, -0.96, 0.58, 1.51, 5.65, 13.41, 13.44, 12.76, 11.14, 5.38, 1, -0.81]
};

export const north = {
    '2017': [-7.28, -2.32, 0.77, 0.61, 6.02, 11.51, 11.34, 12.18, 5.09, 5.06, -2.2, -5.53],
    '2018': [-2.75, -8.69, -3.55, 4.01, 6.77, 10.04, 12.45, 12.19, 9.5, 5.44, 0.53, -3.03],
    '2019': [-8.22, -1.51, -1.43, 0.76, 2.14, 12.02, 12.49, 11.92, 8.25, 5.9, -1.5, -1.69],
    '2020': [-2.29, -1.74, -1.93, 4.13, 5.94, 8, 11.62, 11.62, 8.7, 2.15, 2.04, -4.12],
    '2021': [-7.01, -1.65, -2.65, -1.05, 2.28, 10.79, 10.45, 9.64, 9.01, 3.71, -1.15, -3.03],
    '2022': [-3.91, -3.58, -0.91, -1.05, 2.28, 10.79, 10.45, 9.64, 9.01, 3.71, -1.15, -3.03]
}