'use strict';

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {get, isEqual, merge, set} from 'lodash';
import Parse from 'parse';

import {connect} from 'react-redux';
import translate from '../translate';
import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import Footer from '../footer';
import * as db from '../../lib/structure';
import {makeId, manageError, redirectIfNotLogged, toPointerFromId} from '../../lib/util';
import Loader from '../loader';
import swal from 'sweetalert';
import paths from '../../lib/paths';
import Threshold from '../threshold';
import MultiSelect from '../multiselect';
import structureLocalStorage, {LIVE_REPORT_CUSTOM_SELECTED_COLUMNS} from '../../lib/localStorageStructure';

class PageBuildingSettings extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            roomTypes: [],
            selectedRoomTypes: [],
            batchRoomMaxTemp: 20,
            batchRoomMinTemp: 18,
            city: '',
            area: '',
            build_year: '',
            windows: '',
            people: '',
            type: '',
            heatingSystemValue: '',
            typeValue: '',
            firstname: '',
            lastname: '',
            username: '',
            email: '',
            location: '',
            old_password: '',
            new_password: '',
            confirm_password: '',
            holiday: false,
            types: [
                {
                    name: 'Detached House',
                    extSide: 4,
                    roof: 1,
                    floor: 1
                },
                {
                    name: 'Attached House',
                    extSide: 2,
                    roof: 1,
                    floor: 1
                }
            ],
            offlineDevicesCb: false,
            showAllDevices: false,
            energyReportCb: false,
            allowHolidayOnQrCodePortal: false,
            allowTemperatureModificationOnQrCodePortal: false,
            [db.Home.SHOW_TEMPERATURE_QR_CODE_PORTAL]: false,
            [db.Home.SHOW_CO2_QR_CODE_PORTAL]: false,
            [db.Home.ALLOW_DAY_EVENT_CREATION_QR_CODE_PORTAL]: false,
            showPassword: false,
            thresholds: null,
            initialThresholds: null,
            defaultThresholds: [
                {threshold: 1000, colorName: db.Device.FORCE_LED_COLOR$GREEN, colorValue: '#7ace4c'},
                {threshold: 1500, colorName: db.Device.FORCE_LED_COLOR$YELLOW, colorValue: '#ffea00'},
                {threshold: 2000, colorName: db.Device.FORCE_LED_COLOR$RED, colorValue: '#e50404'}
            ],
            colors: [
                {name: db.Device.FORCE_LED_COLOR$GREEN, value: '#7ace4c'},
                {name: db.Device.FORCE_LED_COLOR$TURNED_OFF, value: '#898989'},
                {name: db.Device.FORCE_LED_COLOR$YELLOW, value: '#ffea00'},
                {name: db.Device.FORCE_LED_COLOR$RED, value: '#e50404'}
            ],
            languageValue: null,
            languages: [
                {
                    key: 'de',
                    value: 'German'
                },
                {
                    key: 'en',
                    value: 'English'
                },
                {
                    key: 'it',
                    value: 'Italian'
                },
                {
                    key: 'fr',
                    value: 'French'
                }
            ],
            rooms: []
        };

        this.changeInputField = this.changeInputField.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.getHome = this.getHome.bind(this);
        this.getHomeSystem = this.getHomeSystem.bind(this);
        this.renderContent = this.renderContent.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.handleThresholdChange = this.handleThresholdChange.bind(this);
        this.thresholdsWereChanged = this.thresholdsWereChanged.bind(this);
        this.handleThresholds = this.handleThresholds.bind(this);
        this.verifyThresholds = this.verifyThresholds.bind(this);
        this.languageLabel = this.languageLabel.bind(this);
        this.setHomeFieldsToState = this.setHomeFieldsToState.bind(this);
        this.getRoomTypes = this.getRoomTypes.bind(this);
        this.getRooms = this.getRooms.bind(this);
        this.applyTemperatureModificationsOnRooms = this.applyTemperatureModificationsOnRooms.bind(this);
    }

    async componentDidMount(){
        let user = await redirectIfNotLogged(this.props.history);

        this.getRoomTypes();
        this.getRooms();

        if (user) {
            this.setState({user: user});

            let homeId = localStorage.getItem('buildingSelection');

            let home = await this.getHome(homeId);

            this.setHomeFieldsToState(home);

            this.setState({user});
            this.getHomeSystem();
        }
    }

    setHomeFieldsToState(home){
        this.setState({
            [db.Home.ALLOW_HOLIDAY_ON_QR_CODE_PORTAL]: home.get(db.Home.ALLOW_HOLIDAY_ON_QR_CODE_PORTAL) ?? true,
            [db.Home.ALLOW_TEMPERATURE_MODIFICATION_ON_QR_CODE_PORTAL]:
                home.get(db.Home.ALLOW_TEMPERATURE_MODIFICATION_ON_QR_CODE_PORTAL) ?? true,
            [db.Home.SHOW_TEMPERATURE_QR_CODE_PORTAL]:
                home.get(db.Home.SHOW_TEMPERATURE_QR_CODE_PORTAL) ?? true,
            [db.Home.SHOW_CO2_QR_CODE_PORTAL]: home.get(db.Home.SHOW_CO2_QR_CODE_PORTAL) ?? true,
            [db.Home.ALLOW_DAY_EVENT_CREATION_QR_CODE_PORTAL]:
                home.get(db.Home.ALLOW_DAY_EVENT_CREATION_QR_CODE_PORTAL) ?? false
        });
    }

    handleColorChange(color, index){
        let threshold = {
            ...this.state.thresholds[index]
        };
        threshold.colorName = color.name;
        threshold.colorValue = color.value;
        let updatedThresholds = [...this.state.thresholds];
        updatedThresholds[index] = threshold;
        this.setState({
            thresholds: updatedThresholds
        });
    }

    handleThresholdChange(value, index){
        const intRegex = /^[0-9\b]+$/;

        if (value === '' || intRegex.test(value)) {
            let intValue = parseInt(value);
            let threshold = {
                ...this.state.thresholds[index]
            };
            threshold.threshold = intValue;
            let updatedThresholds = [...this.state.thresholds];
            updatedThresholds[index] = threshold;
            this.setState({
                thresholds: updatedThresholds
            });
        }
    }

    changeInputField(e){
        let name = e.target.name;
        let value = e.target.value;

        let home = this.state.home;
        let devicesConfig = this.state.home.get(db.Home.DEVICES_CONFIG);

        switch (name) {
            case db.Home.AREA:
            case db.Home.YEAR:
            case db.Home.WINDOWS:
            case db.Home.PEOPLE:
                this.setState(prev => {
                    prev.home.set(name, parseInt(value));

                    return prev;
                });
                break;
            case db.Home.HOME_NAME:
            case db.Home.QR_CODE_PORTAL_ALLOWED_DOMAIN:
            case db.Home.CITY:
                home.set(name, value);
                this.setState({home});
                break;
            case db.Home.TYPE:
                this.setState(prev => {
                    prev.home.set(name, JSON.stringify(value));
                    prev.typeValue = value.name;

                    return prev;
                });
                break;
            case db.Home.LANGUAGE:
                this.setState(prev => {
                    prev.home.set(name, value.key);
                    prev.languageValue = value.key;

                    return prev;
                });
                break;
            case db.Home.SYSTEM:
                this.setState(prev => {
                    prev.home.set(db.Home.SYSTEM, value);

                    return prev;
                });
                break;
            case db._User.NAME:
            case db._User.LAST_NAME:
            case db._User.USERNAME:
            case db._User.EMAIL:
            case db._User.LOCATION:
                this.setState(prev => {
                    prev.user.set(name, value);

                    return prev;
                });
                break;
            case db.Home.DEVICES_OFFLINE_NOTIFICATION:
                this.state.offlineDevicesCb ?
                    this.setState({offlineDevicesCb:false}) : this.setState({offlineDevicesCb:true});
                break;
            case db.Home.ENERGY_REPORT_NOTIFICATION:
                this.state.energyReportCb ?
                    this.setState({energyReportCb:false}) : this.setState({energyReportCb:true});
                break;
            case db.Home.ALLOW_HOLIDAY_ON_QR_CODE_PORTAL:
                this.state.allowHolidayOnQrCodePortal ?
                    this.setState({allowHolidayOnQrCodePortal:false}) :
                    this.setState({allowHolidayOnQrCodePortal:true});
                break;
            case db.Home.ALLOW_TEMPERATURE_MODIFICATION_ON_QR_CODE_PORTAL:
                this.state.allowTemperatureModificationOnQrCodePortal ?
                    this.setState({allowTemperatureModificationOnQrCodePortal:false}) :
                    this.setState({allowTemperatureModificationOnQrCodePortal:true});
                break;
            case db.Home.SHOW_CO2_QR_CODE_PORTAL:
            case db.Home.SHOW_TEMPERATURE_QR_CODE_PORTAL:
            case db.Home.ALLOW_DAY_EVENT_CREATION_QR_CODE_PORTAL:
                this.setState({[name]: !this.state[name]});
                break;
            case db.Home.DEVICES_CONFIG$WIFI_NAME:
                devicesConfig = set(devicesConfig, 'deviceConfig.data[0].wifiName', value);
                home.set(db.Home.DEVICES_CONFIG, devicesConfig);
                this.setState({home});
                break;
            case db.Home.DEVICES_CONFIG$WIFI_PASSWORD:
                devicesConfig = set(devicesConfig, 'deviceConfig.data[0].wifiPassword', value);
                home.set(db.Home.DEVICES_CONFIG, devicesConfig);
                this.setState({home});
                break;
            case db.Home.ALLOW_ADMIN_ACTIONS:
                home.set(db.Home.ALLOW_ADMIN_ACTIONS, !home.get(db.Home.ALLOW_ADMIN_ACTIONS));
                home.set(db.Home.ALLOW_ADMIN_ACTIONS_EDIT_USER, this.state.user);
                home.set(db.Home.ALLOW_ADMIN_ACTIONS_EDIT_DATE, new Date());
                this.setState({home});
                break;
            default:
        }
    }

    thresholdsWereChanged(){
        return !isEqual(this.state.thresholds, this.state.initialThresholds) && this.state.initialThresholds;
    }

    async verifyThresholds() {
        const valuesAreValidIntegers = this.state.thresholds.every((item) => {
            return !isNaN(item.threshold);
        })

        if (!valuesAreValidIntegers) {
            let errorMessage = this.props.t('Threshold value cannot be empty');
            await swal(errorMessage, '', 'error');
            return false;
        }

        const valuesAreIncreasing = this.state.thresholds.every((item, index, thresholds) => {
            if (index > 0 && item.threshold <= thresholds[index - 1].threshold) {
                return false;
            }
            return true;
        })

        if (!valuesAreIncreasing) {
            let errorMessage = this.props.t('Threshold values should be specified in increasing manner from top to bottom');
            await swal(errorMessage, '', 'error');
            return false;
        }
        return true;
    }

    async handleThresholds() {
        const {t} = this.props;

        if (! (await this.verifyThresholds())) {
            return false;
        }

        let agreed = false;
        if (this.thresholdsWereChanged()) {
            agreed = await swal({
                title: t('Warning'),
                icon: 'warning',
                text: t('This will change the current behavior of the colors and the thresholds according to the new configuration. Are you sure you want to proceed?'),
                buttons: [t('Cancel'), t('Confirm')],
                dangerMode: true
            });
        }

        if (agreed || !this.state.initialThresholds) {
            this.state.home.set(db.Home.THRESHOLDS, this.state.thresholds);
            return true;
        } else {
            this.setState({thresholds: this.state.initialThresholds});
            return true;
        }
    }

    async saveChanges(event, tabName){
        const {t} = this.props;
        event.preventDefault();

        try {
            if (tabName === 'all') {
                if (! (await this.handleThresholds())) {
                    return;
                }
                //this case for mobile as there is one submit button for all tabs
                // do request for all data (general, my info and change password)
                this.state.home.set(db.Home.ALLOW_HOLIDAY_ON_QR_CODE_PORTAL, this.state.allowHolidayOnQrCodePortal);
                this.state.home.set(db.Home.ALLOW_TEMPERATURE_MODIFICATION_ON_QR_CODE_PORTAL,
                    this.state.allowTemperatureModificationOnQrCodePortal);

                this.state.user.save()
                    .then(() => this.state.home.save())
                    .then(() => swal({
                        title: t('Data saved'),
                        text: ' ',
                        icon: 'success',
                        button: [''],
                        timer: 1000
                    }))
                    .catch(manageError);
            } else if (tabName === 'my_info') { // this for my info tab 'save changes' button
                this.state.user.save()
                    .then(() => swal({
                        title: t('Data saved'),
                        text: ' ',
                        icon: 'success',
                        button: [''],
                        timer: 1000
                    }))
                    .catch(manageError);
            } else if (tabName === 'general_info') { // this for change password tab 'save changes' button
                this.state.home.save()
                    .then(() => swal({
                        title: t('Data saved'),
                        text: ' ',
                        icon: 'success',
                        button: [''],
                        timer: 1000
                    }))
                    .catch(manageError);
            } else if (tabName === 'notifications') { // this for notification tab 'save changes' button
                this.state.home.set(db.Home.ENERGY_REPORT_NOTIFICATION, this.state.energyReportCb);
                this.state.home.set(db.Home.DEVICES_OFFLINE_NOTIFICATION, this.state.offlineDevicesCb);

                this.state.home.save()
                    .then(() => swal({
                        title: t('Data saved'),
                        text: ' ',
                        icon: 'success',
                        button: [''],
                        timer: 1000
                    }))
                    .catch(manageError);
            } else if (tabName === 'settings') {

                if (! (await this.handleThresholds())) {
                    return;
                }

                this.state.home.set(db.Home.ALLOW_HOLIDAY_ON_QR_CODE_PORTAL,
                    this.state.allowHolidayOnQrCodePortal);
                this.state.home.set(db.Home.ALLOW_TEMPERATURE_MODIFICATION_ON_QR_CODE_PORTAL,
                    this.state.allowTemperatureModificationOnQrCodePortal);
                this.state.home.set(db.Home.SHOW_CO2_QR_CODE_PORTAL,
                    this.state[db.Home.SHOW_CO2_QR_CODE_PORTAL]);
                this.state.home.set(db.Home.SHOW_TEMPERATURE_QR_CODE_PORTAL,
                    this.state[db.Home.SHOW_TEMPERATURE_QR_CODE_PORTAL]);
                this.state.home.set(db.Home.ALLOW_DAY_EVENT_CREATION_QR_CODE_PORTAL,
                    this.state[db.Home.ALLOW_DAY_EVENT_CREATION_QR_CODE_PORTAL]);

                let dirtyKeys = this.state.home.dirtyKeys();

                if(
                    dirtyKeys.indexOf(db.Home.ALLOW_ADMIN_ACTIONS) >= 0 &&
                        this.state.home.get(db.Home.ALLOW_ADMIN_ACTIONS) === true
                ){
                    let verifyText = makeId(6);
                    let text = await swal({
                        title: t('Warning'),
                        icon: 'warning',
                        text: t('Are you sure to allow automatic WiFi configuration? Warning, this can lead to connection lost to the devices. Please enter the verification code "<verify_code>" in the input below or contact support@cleveron.ch for more information.')
                            .replace('<verify_code>', verifyText),
                        content: 'input',
                        className: 'allow-admin-action-modal',
                        buttons: [t('Cancel'), t('Confirm')],
                        dangerMode: true
                    });

                    if (!text) throw new Error(t('Action aborted by user'));

                    if(verifyText.length !== text.length || verifyText !== text){
                        throw new Error(t('Code verification failed!'));
                    }
                }

                if(
                    dirtyKeys.indexOf(db.Home.DEVICES_CONFIG) >= 0 &&
                    (this.state.home.get(db.Home.ALLOW_ADMIN_ACTIONS) === false ||
                        !this.state.home.get(db.Home.ALLOW_ADMIN_ACTIONS))
                ){
                    let verifyText = makeId(6);
                    let text = await swal({
                        title: t('Warning'),
                        icon: 'warning',
                        text: t('Are you sure to change the WiFi configuration? Warning, this can lead to connection lost to the devices. Please enter the verification code "<verify_code>" in the input below or contact support@cleveron.ch for more information.')
                            .replace('<verify_code>', verifyText),
                        content: 'input',
                        buttons: [t('Cancel'), t('Confirm')],
                        dangerMode: true
                    });

                    if (!text) throw new Error(t('Action aborted by user'));

                    if(verifyText.length !== text.length || verifyText !== text){
                        throw new Error(t('Code verification failed!'));
                    }
                }

                await this.state.home.save();
                swal({
                    title: t('Data saved'),
                    text: ' ',
                    icon: 'success',
                    button: [''],
                    timer: 1000
                });
            }
        }catch (e) {
            return await manageError(e);
        }
    }

    async getHome(homeId){
        let query = new Parse.Query('Home');
        query.include(db.Home.SYSTEM);

        try {
            let home = await query.get(homeId);
            if (!home) return Promise.reject(Error('Home not found'));

            if (home) {
                let allowHolidayOnQrCodePortalFromDb = home.get(db.Home.ALLOW_HOLIDAY_ON_QR_CODE_PORTAL);
                let allowTemperatureModificationOnQrCodePortalFromDb =
                    home.get(db.Home.ALLOW_TEMPERATURE_MODIFICATION_ON_QR_CODE_PORTAL);
                let thresholds = home.get(db.Home.THRESHOLDS);
                this.setState({offlineDevicesCb: home.get(db.Home.DEVICES_OFFLINE_NOTIFICATION),
                    energyReportCb: home.get(db.Home.ENERGY_REPORT_NOTIFICATION),
                    allowHolidayOnQrCodePortal: typeof allowHolidayOnQrCodePortalFromDb === 'undefined'
                        ? true
                        : !!home.get(db.Home.ALLOW_HOLIDAY_ON_QR_CODE_PORTAL),
                    allowTemperatureModificationOnQrCodePortal:
                        typeof allowTemperatureModificationOnQrCodePortalFromDb === 'undefined'
                            ? true
                            : !!home.get(db.Home.ALLOW_TEMPERATURE_MODIFICATION_ON_QR_CODE_PORTAL),
                    thresholds: typeof thresholds === 'undefined' ? this.state.defaultThresholds : thresholds,
                    initialThresholds: thresholds
                })
            }

            this.setState({home});
            return home;
        } catch (e) {
            return await manageError(e);
        }
    }

    getHomeSystem(){
        let query = new Parse.Query('HomeSystem');

        return query
            .find()
            .then(homeSystems => {
                this.setState({homeSystems: homeSystems});
            })
            .catch(manageError);
    }

    async getRooms(){
        let selectedBuildingId = localStorage.getItem(structureLocalStorage.BUILDING_SELECTION);
        let rooms = await new Parse.Query(db.classes.Room)
            .notEqualTo(db.Room.DELETED, true)
            .notEqualTo(db.Room.HIDDEN, true)
            .select([
                db.Room.ROOM_NAME,
                db.Room.ROOM_TYPE,
                db.Room.ROOM_CODE
            ])
            .equalTo(db.Room.HOME, toPointerFromId(selectedBuildingId, db.classes.Home))
            .limit(10000)
            .find();

        let roomTypeMap = {};
        rooms.forEach(room => {
            let roomType = room.get(db.Room.ROOM_TYPE);

            if(roomTypeMap[roomType] == null) roomTypeMap[roomType] = [];
            roomTypeMap[roomType].push(room);
        });

        this.setState({rooms, roomTypeMap});
    }

    async getRoomTypes(){
        let roomTypes = await new Parse.Query(db.classes.RoomType)
            .find();

        this.setState({roomTypes});
    }

    languageLabel(languageCode){
        const {t} = this.props;
        let label = '';
        languageCode = languageCode.toLowerCase();
        if (languageCode.startsWith('en')) {
            label = t('English');
        } else if (languageCode.startsWith('de')) {
            label = t('German');
        } else if (languageCode.startsWith('it')) {
            label = t('Italian');
        } else if (languageCode.startsWith('fr')) {
            label = t('French');
        } else {
            label = t('English');
        }
        return label;
    }

    async applyTemperatureModificationsOnRooms(e){
        e.preventDefault()

        const {t} = this.props;
        try {
            let values = this.state.selectedRoomTypes;
            let valuesValue = values.map(values => values.value);

            let roomList = this.state.rooms.filter(room => valuesValue.indexOf(room.get(db.Room.ROOM_TYPE)) >= 0);

            if(this.state.batchRoomMaxTemp < 19 || this.state.batchRoomMinTemp < 16) {
                let text = await swal({
                    title: t('Warning'),
                    icon: 'warning',
                    text: t(`Are you sure to change the wanted temperature to ${this.state.batchRoomMaxTemp}°C and the temperature during absences to ${this.state.batchRoomMinTemp}°C? This may impact the confort of the building users.`),
                    buttons: [t('Cancel'), t('Confirm')],
                    dangerMode: true
                });

                if (!text) throw new Error(t('Action aborted by user'));
            }

            if(this.state.batchRoomMinTemp < 12)
                throw new Error(t('We don\'t allow a temperature lower than 12° because building damage risk. Please contact us on support@cleveron.ch for further info.'))

            let roomsToBeSaved = roomList.map(room => {
                room.set(db.Room.TEMP_MAX, this.state.batchRoomMaxTemp);
                room.set(db.Room.TEMP_MIN, this.state.batchRoomMinTemp);

                return room;
            });

            await Parse.Object.saveAll(roomsToBeSaved);

            await swal({title: t('Success'), text: ' ', icon: 'success', button: [''], timer: 1000});

            $('#editInfoRoom .close').click();
        } catch (e){
            manageError(e, this.props);
        }
    }

    renderContent(){
        const {t} = this.props;

        if (!this.state.home || !this.state.user) return <Loader/>;

        let homeType = this.state.home.get(db.Home.TYPE) ? JSON.parse(this.state.home.get(db.Home.TYPE)) : null;
        let homeName = this.state.home.get(db.Home.HOME_NAME);
        let devicesConfig = this.state.home.get(db.Home.DEVICES_CONFIG);
        let configOnDevice = get(devicesConfig, 'deviceConfig.data[0]');
        let wifiName = get(configOnDevice, 'wifiName');
        let wifiPassword = get(configOnDevice, 'wifiPassword');
        let allowAdminActions = this.state.home.get(db.Home.ALLOW_ADMIN_ACTIONS);
        let showPassword = this.state.showPassword;

        const typeOfCustomer = localStorage.getItem(`home:${db.Home.TYPE_OF_CUSTOMER}`);
        const isAdmin = localStorage.getItem('user:isAdmin') === 'true';
        const hasWritePermission = localStorage.getItem('user:hasWritePermission') === 'true';

        let renderSettingsContent = () => {
            let options = this.state.roomTypes.map(roomType => ({
                label: t(`class.label.${db.classes.RoomType}.${db.RoomType.NAME}$${roomType.get(db.RoomType.NAME)}`),
                value: roomType.get(db.RoomType.NAME)
            }));

            let values = this.state.selectedRoomTypes;
            let valuesValue = values
                .map(values => values.value)

            let roomList = this.state.rooms.filter(room => valuesValue.indexOf(room.get(db.Room.ROOM_TYPE)) >= 0);

            return <Fragment>
                {
                    typeOfCustomer === db._User.TYPE_OF_CUSTOMER$FULL_SYSTEM_CUSTOMER && <Fragment>
                        <h5 className={'settings-section-title'}>{t('QR code portal settings')}</h5>

                        <div className="checkbox-custom">
                            <input type="checkbox" id="allowHolidayOnQrCodePortal"
                                name={db.Home.ALLOW_HOLIDAY_ON_QR_CODE_PORTAL}
                                checked={this.state.allowHolidayOnQrCodePortal}
                                onChange={(e) => this.changeInputField(e)}
                                disabled={!hasWritePermission}
                            />
                            <label htmlFor="allowHolidayOnQrCodePortal">{t('Allow the insertion of vacations on the QR code portal')}</label>
                        </div>

                        <div className="checkbox-custom">
                            <input type="checkbox" id={db.Home.SHOW_TEMPERATURE_QR_CODE_PORTAL}
                                name={db.Home.SHOW_TEMPERATURE_QR_CODE_PORTAL}
                                checked={this.state[db.Home.SHOW_TEMPERATURE_QR_CODE_PORTAL]}
                                onChange={(e) => this.changeInputField(e)}
                                disabled={!hasWritePermission}
                            />
                            <label htmlFor={db.Home.SHOW_TEMPERATURE_QR_CODE_PORTAL}>{t(`class.label.Home.${db.Home.SHOW_TEMPERATURE_QR_CODE_PORTAL}`)}</label>
                        </div>

                        <div className="checkbox-custom">
                            <input type="checkbox" id={db.Home.SHOW_CO2_QR_CODE_PORTAL}
                                name={db.Home.SHOW_CO2_QR_CODE_PORTAL}
                                checked={this.state[db.Home.SHOW_CO2_QR_CODE_PORTAL]}
                                onChange={(e) => this.changeInputField(e)}
                                disabled={!hasWritePermission}
                            />
                            <label htmlFor={db.Home.SHOW_CO2_QR_CODE_PORTAL}>{t(`class.label.Home.${db.Home.SHOW_CO2_QR_CODE_PORTAL}`)}</label>
                        </div>

                        <div className="checkbox-custom">
                            <input type="checkbox" id={db.Home.ALLOW_DAY_EVENT_CREATION_QR_CODE_PORTAL}
                                name={db.Home.ALLOW_DAY_EVENT_CREATION_QR_CODE_PORTAL}
                                checked={this.state[db.Home.ALLOW_DAY_EVENT_CREATION_QR_CODE_PORTAL]}
                                onChange={(e) => this.changeInputField(e)}
                                disabled={!hasWritePermission}
                            />
                            <label htmlFor={db.Home.ALLOW_DAY_EVENT_CREATION_QR_CODE_PORTAL}>{t(`class.label.Home.${db.Home.ALLOW_DAY_EVENT_CREATION_QR_CODE_PORTAL}`)}</label>
                        </div>

                        <div className="form-group">
                            <label>{t('Allow domain')}</label>
                            <input
                                type="text"
                                placeholder={t('')}
                                name={db.Home.QR_CODE_PORTAL_ALLOWED_DOMAIN}
                                value={this.state.home.get(db.Home.QR_CODE_PORTAL_ALLOWED_DOMAIN)}
                                onChange={this.changeInputField}
                            />
                        </div>
                        <div className="divider"/>
                    </Fragment>
                }

                {
                    <Fragment>
                        <h5 className="settings-section-title mt-40">{t('Threshold settings')}</h5>
                        <div className="fields fields--colors">
                            <Threshold
                                readOnly={false}
                                name="firstThreshold"
                                colors={this.state.colors}
                                t={t}
                                thresholds={this.state.thresholds}
                                index={0}
                                onColorChange={this.handleColorChange}
                                onThresholdChange={this.handleThresholdChange} />
                            <Threshold
                                readOnly={false}
                                name="secondThreshold"
                                colors={this.state.colors}
                                t={t}
                                thresholds={this.state.thresholds}
                                index={1}
                                onColorChange={this.handleColorChange}
                                onThresholdChange={this.handleThresholdChange} />
                            <Threshold
                                readOnly={false}
                                name="thirdThreshold"
                                colors={this.state.colors}
                                t={t}
                                thresholds={this.state.thresholds}
                                index={2}
                                onColorChange={this.handleColorChange}
                                onThresholdChange={this.handleThresholdChange} />
                        </div>

                        <div className="divider"/>
                    </Fragment>
                }
                {
                    isAdmin && typeOfCustomer === db._User.TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER &&
                    <Fragment>
                        <h5 className={'settings-section-title mt-40'}>{t('WiFi settings')} {homeName}</h5>
                        <div className="form-group">
                            <label>{t('SSID')}</label>
                            <input
                                type="text"
                                placeholder={t('')}
                                name={'wifiName'}
                                value={wifiName}
                                onChange={this.changeInputField}
                                maxLength={31}
                            />
                        </div>

                        <div className="form-group">
                            <label>{t('Password')}</label>
                            <input
                                type={showPassword ? 'text': 'password'}
                                placeholder={t('')}
                                name={'wifiPassword'}
                                value={wifiPassword}
                                onChange={this.changeInputField}
                                maxLength={63}
                            />
                        </div>

                        <div className="checkbox-custom">
                            <input type="checkbox"
                                id={'showPassword'}
                                name={'showPassword'}
                                checked={showPassword}
                                onChange={(e) => this.changeInputField(e)}
                                disabled={!hasWritePermission}
                            />
                            <label htmlFor={'showPassword'}>{t('Show password')}</label>
                        </div>

                        <div className="checkbox-custom">
                            <input type="checkbox" id={db.Home.ALLOW_ADMIN_ACTIONS}
                                name={db.Home.ALLOW_ADMIN_ACTIONS}
                                checked={allowAdminActions}
                                onChange={(e) => this.changeInputField(e)}
                                disabled={!hasWritePermission}
                            />
                            <label htmlFor={db.Home.ALLOW_ADMIN_ACTIONS}>{t('Allow automatic configuration at the next connection.')}</label>
                        </div>

                        {
                            allowAdminActions &&
                            this.state.home.dirtyKeys().indexOf(db.Home.ALLOW_ADMIN_ACTIONS) < 0 &&<p>
                                <svg className="icon icon-warning-status" style={{
                                    height: 30,
                                    width: 30,
                                    marginRight: 10,
                                    marginBottom: -10,
                                    fill: 'orange'
                                }}>
                                    <use href={'/assets/images/sprite.svg#warning-status'}></use>
                                </svg>
                                {t('The configuration is in progress.')}
                            </p>
                        }

                        <a href={'#'} onClick={() => this.props.history.push(paths.adminPanel)}>
                            {t('Check the Admin-Panel to see the status of the WiFi configuration.')}
                        </a>
                        <div className="divider"/>
                    </Fragment>
                }
                <h5 className={'settings-section-title'}>{t('Batch modify room temperature')}</h5>
                <div className="modal fade" id="editInfoRoom" tabIndex="-1" role="dialog" aria-labelledby="editRoomLabel"
                    aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content edit-photo">
                            <div className="modal-header">
                                <h5 className="modal-title" id="editRoomLabel">{t('Batch modify room temperature')}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <path fill="#222"
                                            d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>{t('Temp maximum')}</label>
                                    <input type="number"
                                        min={12}
                                        max={30}
                                        step={0.5}
                                        name={'batchRoomMaxTemp'}
                                        value={this.state.batchRoomMaxTemp}
                                        onChange={(e) => {
                                            let value = parseFloat(e.target.value);

                                            if(value > 30) value = 30;

                                            this.setState({batchRoomMaxTemp: parseFloat(value.toFixed(1))})
                                        }}/>
                                </div>

                                <div className="form-group">
                                    <label>{t('Temp minimum')}</label>
                                    <input type="number"
                                        min={12}
                                        max={30}
                                        step={0.5}
                                        name={'batchRoomMinTemp'}
                                        value={this.state.batchRoomMinTemp}
                                        onChange={(e) => {
                                            let value = parseFloat(e.target.value);

                                            if(value < 12) value = 12;

                                            this.setState({batchRoomMinTemp: parseFloat(value.toFixed(1))})
                                        }}/>
                                </div>
                                <div className="form-group">
                                    <label>{t('Room type')}</label>
                                    <MultiSelect
                                        options={options}
                                        values={values}
                                        t={t}
                                        onChange={selectedRoomTypes => this.setState({selectedRoomTypes})}
                                        style={{marginBottom: 20, maxWidth: '100%'}}
                                        className={'building-report-live'}
                                        width={'auto'}
                                        listStyle={{maxWidth: '100%'}}
                                    />
                                </div>
                                {t('The temperature will be modified on the following rooms')}:
                                <p>
                                    {
                                        roomList.map((room, i) => <b key={i}>{room.get(db.Room.ROOM_NAME)} {room.get(db.Room.ROOM_CODE) ? `(${room.get(db.Room.ROOM_CODE)})` : ''}{i < roomList.length - 1 ? ',': ''} </b>)
                                    }
                                </p>

                                <button className={`submit-form-btn ${roomList.length === 0 ? 'disabled' : ''}`} onClick={this.applyTemperatureModificationsOnRooms}
                                    disabled={roomList.length === 0}
                                >{t('Modify the temperature')}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <button type="button" className="submit-form-btn" data-toggle="modal"
                    data-target="#editInfoRoom">
                    {t('Batch modify room temperature')}
                </button>
                <div className="divider"/>
            </Fragment>
        };

        return <div className="inner">
            <Breadcrumbs {...this.props} elements={[
                {
                    link: '/dashboard',
                    name: t('Home')
                },
                {
                    link: null,
                    name: t('Building Settings')
                }
            ]}/>
            <h1>{t('Building Settings')}</h1>
            <div className="full-w card card-outer profile-outer">
                <div className="profile-inner">
                    <div className="profile-photo" style={this.state.home.get(db.Home.MAIN_PHOTO) ?
                        {backgroundImage: `url(${this.state.home.get(db.Home.MAIN_PHOTO).url()})`} : {}}>
                        <input type="file"/>
                    </div>
                    <div className="profile-name">
                        <h4>{this.state.user.get(db._User.NAME)} {this.state.user.get(db._User.LAST_NAME)}</h4>
                    </div>
                </div>
                <div className="hide-xs">
                    <ul className="nav nav-tabs header-tabs big" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active show"
                                id="general-tab" data-toggle="tab"
                                href="#general-tab-c" role="tab"
                                aria-controls="general-tab" aria-selected="true">{t('General')}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="info-tab" data-toggle="tab"
                                href="#notifications-tab-c" role="tab" aria-controls="info-tab"
                                aria-selected="false">{t('Notifications')}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="info-tab" data-toggle="tab"
                                href="#settings-tab-c" role="tab" aria-controls="info-tab"
                                aria-selected="false">{t('Settings')}</a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane fade active show" id="general-tab-c"
                            role="tabpanel" aria-labelledby="general-tab">
                            <form>
                                <div className="form-group">
                                    <label>{t('Building name')}</label>
                                    <input
                                        type="text"
                                        placeholder={t('p.es. Building 1')}
                                        name={db.Home.HOME_NAME}
                                        value={this.state.home.get(db.Home.HOME_NAME)}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Address')}</label>
                                    <input
                                        type="text"
                                        name={db.Home.ADDRESS}
                                        value={this.state.home.get(db.Home.ADDRESS) || ''}
                                        disabled={true}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('City')}</label>
                                    <input
                                        type="text"
                                        placeholder={t('p.es. Lugano')}
                                        name={db.Home.CITY}
                                        value={this.state.home.get(db.Home.CITY)}
                                        disabled={true}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('ZIP-Code')}</label>
                                    <input
                                        type="text"
                                        name={db.Home.ZIP_CODE}
                                        value={this.state.home.get(db.Home.ZIP_CODE)}
                                        disabled={true}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Language')}</label>
                                    <div className={`select-custom ${this.state.selectLanguageActive ? 'active' : ''}`}
                                        onClick={() => this.setState({
                                            selectLanguageActive: !this.state.selectLanguageActive
                                        })}
                                    >
                                        <div className="select-custom-status">
                                            {this.state.languageValue || this.state.home.get(db.Home.LANGUAGE)
                                                ? this.languageLabel(
                                                    this.state.languageValue || this.state.home.get(db.Home.LANGUAGE))
                                                : t('Select language')}</div>
                                        <ul className="select-custom-list">
                                            {
                                                this.state.languages.map(language => {
                                                    return <li key={language.key}
                                                        className="select-custom-item"
                                                        onClick={() => this.changeInputField({
                                                            target: {
                                                                name: db.Home.LANGUAGE,
                                                                value: language
                                                            }
                                                        })}
                                                    >{t(language.value)}</li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>{t('Area')}</label>
                                    <input
                                        type="number"
                                        placeholder={45}
                                        name={db.Home.AREA}
                                        value={this.state.home.get(db.Home.AREA)}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Build year')}</label>
                                    <input
                                        type="number"
                                        placeholder={1980}
                                        name={db.Home.YEAR}
                                        value={this.state.home.get(db.Home.YEAR)}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Windows')}</label>
                                    <input
                                        type="number"
                                        placeholder={4}
                                        name={db.Home.WINDOWS}
                                        value={this.state.home.get(db.Home.WINDOWS)}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('People')}</label>
                                    <input
                                        type="number"
                                        placeholder={3}
                                        name={db.Home.PEOPLE}
                                        value={this.state.home.get(db.Home.PEOPLE)}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Type')}</label>
                                    <div className={`select-custom ${this.state.selectTypeActive ? 'active' : ''}`}
                                        onClick={() => this.setState({
                                            selectTypeActive: !this.state.selectTypeActive
                                        })}
                                    >
                                        <div className="select-custom-status">
                                            {homeType ? homeType.name : t('Select Type')}</div>
                                        <ul className="select-custom-list">
                                            {
                                                this.state.types.map(type => {
                                                    return <li key={type.name}
                                                        className="select-custom-item"
                                                        onClick={() => this.changeInputField({
                                                            target: {
                                                                name: 'type',
                                                                value: type
                                                            }
                                                        })}
                                                    >{t(type.name)}</li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>{t('Heating System')}</label>
                                    <div className={`select-custom ${this.state.selectSystemActive ? 'active' : ''}`}
                                        onClick={() => this.setState({
                                            selectSystemActive: !this.state.selectSystemActive
                                        })}
                                    >
                                        <div className="select-custom-status">
                                            {this.state.home.get(db.Home.SYSTEM) ? t(this.state.home.get(db.Home.SYSTEM).get(db.HomeSystem.SYSTEM_NAME)) : t('Select System')}
                                        </div>
                                        <ul className="select-custom-list">
                                            {
                                                this.state.homeSystems && this.state.homeSystems.map(homeSystem => {
                                                    return <li key={homeSystem.id}
                                                        className="select-custom-item"
                                                        onClick={() => this.changeInputField({
                                                            target: {
                                                                name: db.Home.SYSTEM,
                                                                value: homeSystem,
                                                            }
                                                        })}
                                                    >{t(homeSystem.get(db.HomeSystem.SYSTEM_NAME))}</li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="divider"/>
                                <button
                                    className="submit-form-btn"
                                    onClick={(e) => this.saveChanges(e, 'general_info')}
                                    disabled={!hasWritePermission}
                                >{t('Save changes')}</button>
                            </form>
                        </div>
                        <div className="tab-pane fade notifications" id="notifications-tab-c"
                            role="tabpanel" aria-labelledby="general-tab">
                            <form>
                                <div className="form-group">
                                    <div className="checkbox-custom">
                                        <input type="checkbox" id="cb"
                                            name={db.Home.DEVICES_OFFLINE_NOTIFICATION}
                                            checked={this.state.offlineDevicesCb}
                                            onChange={(e) => this.changeInputField(e)}
                                            disabled={!hasWritePermission}
                                        />
                                        <label htmlFor="cb">{t('Offline Devices')}</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="checkbox-custom">
                                        <input type="checkbox" id="cb2"
                                            name={db.Home.ENERGY_REPORT_NOTIFICATION}
                                            checked={this.state.energyReportCb}
                                            onChange={(e) => this.changeInputField(e)}
                                            disabled={!hasWritePermission}
                                        />
                                        <label htmlFor="cb2">{t('Energy Report')}</label>
                                    </div>
                                </div>
                                <div className="divider"/>
                                <button
                                    className="submit-form-btn"
                                    onClick={(e) => this.saveChanges(e, 'notifications')}
                                    disabled={!hasWritePermission}
                                >{t('Save changes')}</button>
                            </form>
                        </div>
                        <div className="tab-pane fade settings" id="settings-tab-c"
                            role="tabpanel" aria-labelledby="general-tab">
                            <form>
                                {renderSettingsContent()}

                                <button
                                    className="submit-form-btn"
                                    onClick={(e) => this.saveChanges(e, 'settings')}
                                    disabled={!hasWritePermission}
                                >{t('Save changes')}</button>
                            </form>
                        </div>
                    </div>
                </div>
                {/*mobile profile*/}
                <form className="show-xs">
                    <div className="accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                        <div className="accordion-item">
                            <div role="tab" id="headingOne" className="accordion-header">
                                <a className="collapsed closed-tab" data-toggle="collapse" data-parent="#accordionEx"
                                    href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    {t('General')} <i/>
                                </a>
                            </div>
                            <div id="collapseOne" className="collapse accordion-body" role="tabpanel"
                                aria-labelledby="headingOne" data-parent="#accordionEx">
                                <div className="form-group">
                                    <label>{t('Building name')}</label>
                                    <input
                                        type="text"
                                        placeholder={t('p.es. Building 1')}
                                        name={db.Home.HOME_NAME}
                                        value={this.state.home.get(db.Home.HOME_NAME) || ''}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Address')}</label>
                                    <input
                                        type="text"
                                        name={db.Home.ADDRESS}
                                        value={this.state.home.get(db.Home.ADDRESS) || ''}
                                        disabled={true}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('City')}</label>
                                    <input
                                        type="text"
                                        placeholder={t('p.es. Lugano')}
                                        name={db.Home.CITY}
                                        value={this.state.home.get(db.Home.CITY) || ''}
                                        disabled={true}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('ZIP-Code')}</label>
                                    <input
                                        type="text"
                                        name={db.Home.ZIP_CODE}
                                        value={this.state.home.get(db.Home.ZIP_CODE) || ''}
                                        disabled={true}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Language')}</label>
                                    <div className={`select-custom ${this.state.selectLanguageActive ? 'active' : ''}`}
                                        onClick={() => this.setState({
                                            selectLanguageActive: !this.state.selectLanguageActive
                                        })}
                                    >
                                        <div className="select-custom-status">
                                            {this.state.languageValue || this.state.home.get(db.Home.LANGUAGE)
                                                ? this.languageLabel(
                                                    this.state.languageValue || this.state.home.get(db.Home.LANGUAGE))
                                                : t('Select language')}</div>
                                        <ul className="select-custom-list">
                                            {
                                                this.state.languages.map(language => {
                                                    return <li key={language.key}
                                                        className="select-custom-item"
                                                        onClick={() => this.changeInputField({
                                                            target: {
                                                                name: db.Home.LANGUAGE,
                                                                value: language
                                                            }
                                                        })}
                                                    >{t(language.value)}</li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>{t('Area')}</label>
                                    <input
                                        type="number"
                                        placeholder={45}
                                        name={db.Home.AREA}
                                        value={this.state.home.get(db.Home.AREA)}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Build year')}</label>
                                    <input
                                        type="number"
                                        placeholder={1980}
                                        name={db.Home.YEAR}
                                        value={this.state.home.get(db.Home.YEAR)}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Windows')}</label>
                                    <input
                                        type="number"
                                        placeholder={4}
                                        name={db.Home.WINDOWS}
                                        value={this.state.home.get(db.Home.WINDOWS)}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('People')}</label>
                                    <input
                                        type="number"
                                        placeholder={3}
                                        name={db.Home.PEOPLE}
                                        value={this.state.home.get(db.Home.PEOPLE)}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Type')}</label>
                                    <div className={`select-custom ${this.state.selectTypeActive ? 'active' : ''}`}
                                        onClick={() => this.setState({
                                            selectTypeActive: !this.state.selectTypeActive
                                        })}
                                    >
                                        <div className="select-custom-status">
                                            {homeType ? homeType.name : t('Select Type')}</div>
                                        <ul className="select-custom-list">
                                            {
                                                this.state.types.map(type => {
                                                    return <li key={type.name}
                                                        className="select-custom-item"
                                                        onClick={() => this.changeInputField({
                                                            target: {
                                                                name: 'type',
                                                                value: type
                                                            }
                                                        })}
                                                    >{t(type.name)}</li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>{t('Heating System')}</label>
                                    <div className={`select-custom ${this.state.selectSystemActive ? 'active' : ''}`}
                                        onClick={() => this.setState({
                                            selectSystemActive: !this.state.selectSystemActive
                                        })}
                                    >
                                        <div className="select-custom-status">
                                            {this.state.home.get(db.Home.SYSTEM) ? t(this.state.home.get(db.Home.SYSTEM).get(db.HomeSystem.SYSTEM_NAME)) : t('Select System')}
                                        </div>
                                        <ul className="select-custom-list">
                                            {
                                                this.state.homeSystems && this.state.homeSystems.map(homeSystem => {
                                                    return <li key={homeSystem.id}
                                                        className="select-custom-item"
                                                        onClick={() => this.changeInputField({
                                                            target: {
                                                                name: db.Home.SYSTEM,
                                                                value: homeSystem,
                                                            }
                                                        })}
                                                    >{t(homeSystem.get(db.HomeSystem.SYSTEM_NAME))}</li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item notifications">
                            <div role="tab" id="headingThree" className="accordion-header">
                                <a className="collapsed closed-tab" data-toggle="collapse" data-parent="#accordionEx"
                                    href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    {t('Notifications')} <i/>
                                </a>
                            </div>
                            <div id="collapseThree" className="collapse accordion-body" role="tabpanel"
                                aria-labelledby="headingThree" data-parent="#accordionEx">
                                <div className="form-group">
                                    <div className="checkbox-custom">
                                        <input type="checkbox" id="offlineDevicesCb"
                                            name={db.Home.DEVICES_OFFLINE_NOTIFICATION}
                                            checked={this.state.home.get(db.Home.DEVICES_OFFLINE_NOTIFICATION)}
                                            onChange={(e) => this.changeInputField(e)}
                                            disabled={!hasWritePermission}
                                        />
                                        <label htmlFor="offlineDevicesCb">{t('Offline Devices')}</label>
                                    </div>
                                    <div className="checkbox-custom">
                                        <input type="checkbox" id="energyReportCb"
                                            name={db.Home.ENERGY_REPORT_NOTIFICATION}
                                            checked={this.state.home.get(db.Home.ENERGY_REPORT_NOTIFICATION)}
                                            onChange={(e) => this.changeInputField(e)}
                                            disabled={!hasWritePermission}
                                        />
                                        <label htmlFor="energyReportCb">{t('Energy Report')}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item settings">
                            <div role="tab" id="headingFour" className="accordion-header">
                                <a className="collapsed closed-tab" data-toggle="collapse" data-parent="#accordionEx"
                                    href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    {t('Settings')} <i/>
                                </a>
                            </div>
                            <div id="collapseFour" className="collapse accordion-body" role="tabpanel"
                                aria-labelledby="headingFour" data-parent="#accordionEx">
                                {renderSettingsContent()}
                            </div>
                        </div>
                    </div>
                    <button
                        className="submit-form-btn show-xs"
                        id={'save-profile-changes'}
                        onClick={(e) => this.saveChanges(e, 'all')}
                        disabled={!hasWritePermission}
                    >{t('Save changes')}</button>
                </form>
            </div>
        </div>;
    }

    render(){
        return <div className={'page-profile'}>
            {
                get(this.props, 'shared.general.Error.visible') && <div className={'alert alert-danger'}>
                    {get(this.props, 'shared.general.Error.message')}
                </div>
            }

            <div className="wrapper d-flex align-items-stretch">
                <Sidebar {...this.props}/>
                <div className="content">
                    <Navbar {...this.props}/>
                    {this.renderContent()}
                    <Footer/>
                </div>
            </div>
        </div>
    }

}

PageBuildingSettings.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired
};

/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(PageBuildingSettings));