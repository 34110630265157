'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class SelectSingle extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false
        };
    }

    async componentDidMount() {
    }

    render() {
        const {t} = this.props;

        let selectedOption;

        if(this.props.value){
            selectedOption = _.find(this.props.options, {key: this.props.value});
        }

        return  <div className={`select-custom ${this.state.active ? 'active' : ''}`}
            onClick={() => this.setState({
                active: !this.state.active
            })}
            style={this.props.style}
        >
            <div className="select-custom-status">
                {selectedOption ? selectedOption.label : t('Select an option')}
            </div>
            <ul className="select-custom-list">
                {
                    this.props.options.map(option => {
                        return <li key={option.key}
                            className="select-custom-item"
                            onClick={() => this.props.onChange(option)}
                        >{t(option.label)}</li>
                    })
                }
            </ul>
        </div>
    }
}

SelectSingle.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    options: PropTypes.array,
    onChange: PropTypes.func,
    value: PropTypes.string,
    style: PropTypes.object
};

export default SelectSingle;