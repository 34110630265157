'use strict';

import React, {Fragment} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import db from '../../../../client/lib/structure';
import swal from 'sweetalert';
import deleteIcon from '../../../assets/images/ic_delete_grey.svg';

class CardRoomCalendar extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            events: [],
            orderByTypes: [
                {
                    name: 'Name',
                    dbName: db.CalendarEvent.EVENT_NAME
                },
                {
                    name: 'Start',
                    dbName: db.CalendarEvent.START_DATE
                },
                {
                    name: 'End',
                    dbName: db.CalendarEvent.END_DATE
                }
            ],
            orderBy: db.CalendarEvent.START_DATE,
            sort: 'ascending',
            viewMoreButtonActive: false,
            viewMoreButtonText: '',
            event: '',
            dateTo: '',
            dateFrom: '',
            eventName: '',

        };

        this.viewMoreButtonClicked = this.viewMoreButtonClicked.bind(this);
        this.deleteIconClicked = this.deleteIconClicked.bind(this);
        this.deleteConfirmed = this.deleteConfirmed.bind(this);
        this.displayTableContent = this.displayTableContent.bind(this);
    }

    deleteIconClicked(event){
        let dateFormat = 'DD MMM YYYY, HH:mm';

        let eventName = event.get('eventName');
        let dateFrom = moment(event.get('startDate')).format(dateFormat);
        let dateTo = moment(event.get('endDate')).format(dateFormat);
        this.setState({event, eventName, dateFrom, dateTo});
    }

    async deleteConfirmed(){
        try {
            const {t} = this.props;
            let message = t('Event deleted!');
            this.state.event.set(db.CalendarEvent.DELETED, true);

            await this.state.event.save();
            this.setState({
                event: null,
                dateFrom: '',
                dateTo: ''
            });
            this.props.sortEvents();
            swal(message, '', 'success');

        } catch (err) {
            console.error(err);
            swal('Error!', err, 'error');
        }

    }

    async viewMoreButtonClicked(){
        const {t} = this.props;

        let viewMoreText = t('View more');
        let hideText = t('Hide');
        this.state.viewMoreButtonActive ?
            await this.setState({
                viewMoreButtonActive: false, viewMoreButtonText: viewMoreText
            })
            :
            await this.setState({viewMoreButtonActive: true, viewMoreButtonText: hideText});

        this.displayTable();
    }

    displayTableContent(event, loaded) {
        const {t} = this.props;
        let dateFormat = 'DD MMM YYYY, HH:mm';
        let eventName = '';

        if (!event.get('eventName')) {
            if (event.get('eventType') === 'heat') eventName = t('Heating');
            if (event.get('eventType') === 'no-heat') eventName = t('No heating');
        }

        const hasWritePermission = localStorage.getItem('user:hasWritePermission') === 'true';
        const home = event.get(db.CalendarEvent.HOME);
        const room = event.get(db.CalendarEvent.ROOM);
        // eslint-disable-next-line no-unused-vars
        const isHomeEvent = home != null && room == null;
        const isRoomEvent = room != null && home != null;

        return <tr className={loaded ? 'table-row loaded' : 'table-row'} key={event.id}>
            <td>
                {event.get('eventName') ? event.get('eventName') : eventName}
                <small className='m-download-date'>
                    {moment(event.get('startDate')).format(dateFormat)} - {moment(event.get('endDate')).format(dateFormat)}
                </small>
            </td>
            <td className='hide-sm'>{moment(event.get('startDate')).format(dateFormat)}</td>
            <td className='hide-sm'>{moment(event.get('endDate')).format(dateFormat)}</td>
            <td className='delete-btn-small'>
                {
                    hasWritePermission && isRoomEvent && <button className="edit-modal-btn"
                        data-toggle="modal"
                        data-target="#deleteEvent"
                        id="deleteButton"
                        onClick={() => this.deleteIconClicked(event)}
                    >
                        <img src={deleteIcon}/>
                    </button>
                }
            </td>
        </tr>

    }

    displayTable(){

        if (this.state.viewMoreButtonActive) {
            return this.props.events.map((event) => this.displayTableContent(event))
        }

        return this.props.events.map((event, i) => {
            if (i < 5) {
                return this.displayTableContent(event);
            }

            return this.displayTableContent(event, true);
        })
    }

    async componentDidMount(){
        this.props.sortEvents();
    }

    render(){
        const {t} = this.props;

        return <Fragment>
            <div className='grid-cols' style={this.props.style}>
                <div className="card card-outer full-w event-card" id="calendar-container">
                    <div className="card-header">
                        <div className="card-title d-flex">
                            <h2>{t('Calendar Events')}</h2>
                        </div>
                    </div>

                    <div className='card-wrap'>
                        {
                            this.props.events.length === 0 ? <h5>{t('No events')}</h5> : <table className='table'>
                                <thead>
                                    <tr>
                                        <th className='table-head'>
                                            <strong>{t('Event Name')}</strong>
                                            <div className='sorting-header'>
                                                <div className='sorting-header__arrow'
                                                    onClick={() => this.props.sortEvents('ascending', db.CalendarEvent.EVENT_NAME)}/>
                                                <div className='sorting-header__arrow'
                                                    onClick={() => this.props.sortEvents('descending', db.CalendarEvent.EVENT_NAME)}/>
                                            </div>
                                        </th>
                                        <th className='table-head hide-sm date'>
                                            <strong>{t('Start')}</strong>
                                            <div className='sorting-header'>
                                                <div className='sorting-header__arrow'
                                                    onClick={() => this.props.sortEvents('ascending', db.CalendarEvent.START_DATE)}/>
                                                <div className='sorting-header__arrow'
                                                    onClick={() => this.props.sortEvents('descending', db.CalendarEvent.START_DATE)}/>
                                            </div>
                                        </th>
                                        <th className='table-head hide-sm date'>
                                            <strong>{t('End')}</strong>
                                            <div className='sorting-header'>
                                                <div className='sorting-header__arrow'
                                                    onClick={() => this.props.sortEvents('ascending', db.CalendarEvent.END_DATE)}/>
                                                <div className='sorting-header__arrow'
                                                    onClick={() => this.props.sortEvents('descending', db.CalendarEvent.END_DATE)}/>
                                            </div>
                                        </th>
                                        <th className='table-head hide-sm date'>
                                            <strong>{t('Action')}</strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.displayTable()}
                                </tbody>
                            </table>
                        }
                    </div>
                    {this.props.events.length > 5 &&
                    <button onClick={() => this.viewMoreButtonClicked()}
                        className="view-more">{this.state.viewMoreButtonActive ? t('Hide') : t('View more')}</button>
                    }
                </div>

            </div>

            <div className="modal fade" id="deleteEvent" tabIndex="-1" role="dialog"
                aria-labelledby="deleteEventLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content edit-info">
                        <div className="modal-header">
                            <h5 className="modal-title"
                                id="deleteEventLabel">{t('Are you sure you want to delete this event?')}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                    viewBox="0 0 18 18">
                                    <path fill="#222"
                                        d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                </svg>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="text">
                                <table className="delete-event-card mb-2">
                                    <tbody>
                                        <tr>
                                            <td>{t('Event Name')}:</td>
                                            <td>{this.state.eventName}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('Start Date')}:</td>
                                            <td>{this.state.dateFrom}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('End Date')}:</td>
                                            <td>{this.state.dateTo}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="actions">
                                <button type="submit" className="submit-form-btn" data-dismiss="modal"
                                    onClick={(e) => this.deleteConfirmed(e)}>{t('Yes')}</button>
                                <button className="cancel-form-btn" data-dismiss="modal">{t('Cancel')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>;
    }
}

CardRoomCalendar.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    roomId: PropTypes.string,
    sortEvents: PropTypes.func,
    events: PropTypes.array,
    style: PropTypes.object
};

export default CardRoomCalendar;
