'use strict';

import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import {locizePlugin} from 'locize';
import Locize from 'i18next-locize-backend';
import Detection from 'i18next-browser-languagedetector';
import locizeLastUsed from 'locize-lastused';

import i18nextConf from './conf/conf';

i18next
    .use(Detection)
    .use(initReactI18next)
    .use(locizePlugin)
    .use(Locize)
    .use(locizeLastUsed)
    .init(i18nextConf.i18n);

export default i18next;