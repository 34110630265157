'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default class WindowOpeningDistributionFigure extends React.Component {
    constructor(props){
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.initChart(this.props);
    }

    initChart(props){
        const {t} = props;

        const data = props.coarseCo2Ratios.map((coarseCo2, i) => {
            let color = '';

            let coarseCo2Ratio = this.props.coarseCo2Ratios[i];
            let coarseNumberWindowOpening = this.props.coarseNumberWindowOpenings[i];
            let y = coarseCo2Ratio < 10 ? 10 : coarseCo2Ratio;
            let z = coarseNumberWindowOpening;

            let name = '';
            switch (i) {
                case 0: name = `${t('coarseNumberWindowOpenings.0')} <br/>${coarseCo2Ratio}%`; color =  '#72e7ff'; break;
                case 1: name = `${t('coarseNumberWindowOpenings.1')} <br/>${coarseCo2Ratio}%`; color =  '#7ace4c'; break;
                case 2: name = `${t('coarseNumberWindowOpenings.2')} <br/>${coarseCo2Ratio}%`; color =  '#7ace4c'; break;
                case 3: name = `${t('coarseNumberWindowOpenings.3')} <br/>${coarseCo2Ratio}%`; color =  '#fdd74d'; break;
                case 4: name = `${t('coarseNumberWindowOpenings.4')} <br/>${coarseCo2Ratio}%`; color =  '#ff5858'; break;
            }

            return {name, y, z, percentage: coarseCo2Ratio, coarseNumberWindowOpening, color};
        });

        Highcharts.chart('windows-opnening-chat', {
            chart: {
                type: 'variablepie',
                height: 700
            },

            title: {
                text: ''
            },

            subtitle: {
                text: ''
            },

            caption: {
                text: ''
            },

            legend: {
                enabled: false
            },

            series: [{
                data,
                tooltip: {
                    pointFormat: `${t('Window openings')}: <b>{point.coarseNumberWindowOpening}</b>`
                },
                colorByPoint: true
            }]
        });
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.month !== this.props.month) this.initChart(nextProps);
        if(nextProps.week !== this.props.week) this.initChart(nextProps);
        if(!_.isEqual(nextProps.coarseNumberWindowOpenings, this.props.coarseNumberWindowOpenings))
            this.initChart(nextProps);
    }

    render() {
        const {t} = this.props;

        return <div className="card card-outer climate-outer-bottom" style={{marginBottom: 20}}>
            <div className="card-header">
                <div className="card-title climate-control d-flex ">
                    <h2>{t('Window openings by air quality band')}</h2>
                </div>
            </div>
            <div className="chart-lines__outer">
                <div className={'windows-opening-distribution-figure-outer'}>
                    <figure className="highcharts-figure">
                        <div id="windows-opnening-chat"></div>
                    </figure>
                </div>
            </div>
        </div>;
    }
}

WindowOpeningDistributionFigure.propTypes = {
    t: PropTypes.any,
    month: PropTypes.number,
    year: PropTypes.number,
    week: PropTypes.number,
    windowsOpeningDistributionFigure: PropTypes.array,
    coarseCo2Ratios: PropTypes.array,
    coarseNumberWindowOpenings: PropTypes.array
};