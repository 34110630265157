import React, {Component} from 'react';
import PropTypes from 'prop-types';
import translate from '../translate';

class Notification extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        let {t} = this.props;

        return <div
            className={`notification notification notification--${this.props.type ? this.props.type : 'success'}`}
            ref={this.props.ref}>
            {
                this.props.icon && <svg className={`icon icon-${this.props.icon} notification__icon`}>
                    <use href={`/static/assets/images/sprite.svg#${this.props.icon}`}></use>
                </svg>
            }
            <span className="notification__text">{t(this.props.message)}</span>
            {
                this.props.closeButton && <button className="notification__close" onClick={this.props.close}>
                    <svg className="icon icon-close notification__close-icon">
                        <use href="/static/assets/images/sprite.svg#close"></use>
                    </svg>
                </button>
            }
        </div>
    }
}

Notification.propTypes = {
    message: PropTypes.string.isRequired,
    type: PropTypes.string,
    icon: PropTypes.string,
    closeButton: PropTypes.bool,
    close: PropTypes.func,
    ref: PropTypes.any,
    i18n: PropTypes.any,
    t: PropTypes.func
};

export default translate(Notification);