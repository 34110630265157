'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import db from '../../lib/structure';
import infoIcon from '../../assets/images/ic_info.svg';
import successIcon from '../../assets/images/ic_diagnostics_success.svg';
import warningIcon from '../../assets/images/ic_diagnostics_warning.svg';
import errorIcon from '../../assets/images/ic_diagnostics_error.svg';
import {convertColorsToIconTag, getIconFromWifiDb} from '../../lib/util';

class DiagnosticResultItemConnection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            supportRequests: []
        };
    }

    async componentDidMount() {
        let supportRequests = await new Parse.Query(db.classes.SupportRequest).find();

        this.setState({supportRequests});
    }

    render() {
        const {t, diagnosticRequest} = this.props;
        /** @typedef {object} DeviceProperty
         * @property {string} device
         * @property {number} serial_number
         * @property {string} device_type
         * @property {boolean} is_disconnected
         * @property {string} disconnection_time
         * @property {object} battery_state
         * @property {object} battery_state.comment
         * @property {string} battery_state.comment.it
         * @property {string} battery_state.comment.de
         * @property {string} battery_state.comment.en
         * @property {string} battery_state.comment.fr
         * @property {string} battery_state.statusLabel
         * @property {number} remaining_battery
         * @property {number} wifi_state
         * @property {number} nb_data_points
         * @property {number} meas_density
         * @property {null} device_temp_correction
         * @property {boolean} is_outlier
         * @property {string} turn_on_properties
         * @property {number} min_co2
         * @property {boolean} faulty
         * @property {boolean} faulty_motor
         * @property {object} connection_state
         * @property {object} connection_state.comment
         * @property {string} connection_state.comment.it
         * @property {string} connection_state.comment.de
         * @property {string} connection_state.comment.en
         * @property {string} connection_state.comment.fr
         * @property {string} connection_state.statusLabel
         */

        /** @typedef {object} FeedbackProperties
         * @property {number} min_battery_voltage
         * @property {number} min_estimated_battery_voltage
         * @property {string} most_distant_time_of_last_connection
         * @property {number} disconnected_prob
         * @property {number} worst_meas_density
         * @property {number} nb_devices
         * @property {number} nb_good_devices
         * @property {number} nb_outliers
         * @property {} other_feedbacks
         * @property {number} nb_days_with_feedbacks_in_last_month
         * @property {number} nb_weeks_with_feedbacks_in_last_month
         * @property {number} nb_days_with_feedbacks_last_week
         * @property {number} current_room_calibration_offset
         * @property {string[]} faulty_devices
         * @property {string[]} faulty_motors
         * @property {number} calibration_offset
         * @property {number} std_calibration_offset
         */

        /** @typedef {object} FeedbackConclusions
         * @property {string} room_id
         * @property {boolean} is_disconnection
         * @property {boolean} battery_depleted
         * @property {boolean} is_outlier
         * @property {boolean} is_wifi_unstable
         * @property {boolean} is_disconnection_global
         * @property {object} connection_state
         * @property {object} connection_state.comment
         * @property {string} connection_state.comment.it
         * @property {string} connection_state.comment.de
         * @property {string} connection_state.comment.en
         * @property {string} connection_state.comment.fr
         * @property {string} connection_state.statusLabel
         * @property {string} feedback_urgency
         * @property {string} faulty_devices
         * @property {string} faulty_motors
         * @property {string} calibration
         * @property {object} temperature_state
         * @property {object} temperature_state.comment
         * @property {string} temperature_state.comment.it
         * @property {string} temperature_state.comment.de
         * @property {string} temperature_state.comment.en
         * @property {string} temperature_state.comment.fr
         * @property {string} temperature_state.statusLabel
         * @property {number} nb_thermostats
         * @property {number} nb_sensors
         * @property {number} room_max_temp
         * @property {number} room_min_temp
         * @property {boolean} is_automatic_mode
         * @property {null} earliest_disconnection_time
         * @property {object} co2_state
         * @property {object} co2_state.comment
         * @property {string} co2_state.comment.it
         * @property {string} co2_state.comment.de
         * @property {string} co2_state.comment.en
         * @property {string} co2_state.comment.fr
         * @property {string} co2_state.statusLabel
         * @property {object} events_state
         * @property {object} events_state.comment
         * @property {string} events_state.comment.it
         * @property {string} events_state.comment.de
         * @property {string} events_state.comment.en
         * @property {string} events_state.comment.fr
         * @property {string} events_state.statusLabel
         * @property {object} battery_state
         * @property {object} battery_state.comment
         * @property {string} battery_state.comment.it
         * @property {string} battery_state.comment.de
         * @property {string} battery_state.comment.en
         * @property {string} battery_state.comment.fr
         * @property {string} battery_state.statusLabel
         */

        /**
         *
         * @type {{devicesStatus: {comment: *, device: *, statusLabel: *}[], comment: {fr: string, en: string, it: string, de: string}, statusLabel: string}}  connectionStatus
         */
        let connectionStatus = diagnosticRequest.get(db.DiagnosticRequest.CONNECTION_STATUS);
        /** @type {DeviceDiagnosticResult} devicesDiagnosticResults */
        let devicesDiagnosticResults = diagnosticRequest.get(db.DiagnosticRequest.DEVICES_DIAGNOSTIC_RESULTS);

        let item = {
            'title': 'Connection',
            'description': connectionStatus.comment?.[this.props.i18n.resolvedLanguage],
            'icon': convertColorsToIconTag(connectionStatus.statusLabel), //info,success,warning,danger
            'infoMessage': '',
            'warningMessage': '',
            'table': {
                'title': '',
                'headers': ['Serial number', 'Status'],
                'columns': ['serialNumber', 'comment'],
                'rows': connectionStatus.devicesStatus?.map(deviceStatus => {
                    /**
                     * @type {DeviceProperty} deviceProperty
                     */
                    let deviceProperty = deviceStatus?.deviceProperties;
                    let comment = deviceStatus?.comment?.[this.props.i18n.resolvedLanguage];
                    let statusLabel = deviceStatus?.statusLabel;

                    return {
                        serialNumber: deviceProperty?.serial_number,
                        comment: comment
                    };
                })
            }
        };

        return <div>
            <div className={'system-diagnostics-result-item'}>
                <div className='system-diagnostics-result-item-header'>
                    <div className='system-diagnostics-result-item-group'>
                        <div style={{alignSelf: 'center'}}>
                            {item.icon === 'info' && <img src={infoIcon} className='system-diagnostics-result-item-icon'/>}
                            {item.icon === 'success' && <img src={successIcon} className='system-diagnostics-result-item-icon'/>}
                            {item.icon === 'warning' && <img src={warningIcon} className='system-diagnostics-result-item-icon'/>}
                            {item.icon === 'danger' && <img src={errorIcon} className='system-diagnostics-result-item-icon'/>}
                        </div>
                        <div className='system-diagnostics-result-item-status'>
                            <div className='system-diagnostics-result-item-title'>
                                {t(item.title)}
                            </div>
                            <div className='system-diagnostics-result-item-description'>
                                {t(item.description)}
                            </div>
                        </div>
                    </div>
                    <div className='system-diagnostics-result-item-view-details'>
                        <a onClick={(e) => {
                            e.preventDefault();
                            this.setState(prevState => {
                                return {
                                    viewDetailedOpened:  !prevState.viewDetailedOpened
                                };
                            });
                        }} className='system-diagnostics-result-item-details-label'>{t('View details')}</a>

                        <a onClick={(e) => {
                            e.preventDefault();
                            this.setState(prevState => {
                                return {
                                    viewDetailedOpened:  !prevState.viewDetailedOpened
                                };
                            });
                        }} className={`system-diagnostics-result-item-details-icon arrow ${this.state.viewDetailedOpened ? 'is-active' : ''}`}></a>
                    </div>
                </div>
                {this.state.viewDetailedOpened && (<div className='system-diagnostics-result-item-details'>
                    {item.infoMessage && <div className='system-diagnostics-result-item-details-info'>
                        {t(item.infoMessage)}
                    </div>}
                    {item.warningMessage && <div className='system-diagnostics-result-item-details-warning'>
                        {t(item.warningMessage)}
                    </div>}
                    {item.buttons && <div className='system-diagnostics-result-item-details-buttons'>
                        {item.buttons.map((button, index) => {
                            return <a key={index}
                                target='_blank'
                                href={button.url}
                                className='system-diagnostics-result-item-details-button' rel="noreferrer">
                                {t(button.label)}
                            </a>;
                        })}
                    </div>}

                    {item.table && <div className="table-responsive"><table className="table">
                        <thead className="thead-light">
                            <tr>
                                {item.table.headers.map((header, index) =>
                                    <th key={index} scope="col">{t(header)}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {item.table.rows.map((header, index) => {
                                const row = item.table.columns.map((column, colIndex) => {
                                    return <td key={colIndex}>{t(header[column])}</td>
                                });
                                return <tr key={index}>{row}</tr>
                            })}
                        </tbody>
                    </table></div>}

                </div>
                )}
            </div>
        </div>
    }
}

DiagnosticResultItemConnection.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    history: PropTypes.any.isRequired,
    diagnosticRequest: PropTypes.object
};

export default DiagnosticResultItemConnection;