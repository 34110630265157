'use strict';

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import Parse from 'parse';
import db from '../../lib/structure';
import infoIcon from '../../assets/images/ic_info.svg';
import successIcon from '../../assets/images/ic_diagnostics_success.svg';
import warningIcon from '../../assets/images/ic_diagnostics_warning.svg';
import errorIcon from '../../assets/images/ic_diagnostics_error.svg';
import moment from 'moment-timezone';
import SelectSingle from '../component-select-single/select-single';
import swal from 'sweetalert';
import {convertColorsToIconTag} from '../../lib/util';

let temperatureQuestions = {
    firstLevelQuestions: [
        {
            key: 'theRoomTemperatureIsTooColdOrTooHot',
            label: 'firstLevelQuestion.theRoomTemperatureIsTooColdOrTooHot' // The tempeature of the room is too hot or too cold
        },
        {
            key: 'theWebappDisplayedTemperatureIsIncorrect',
            label: 'firstLevelQuestion.theWebappDisplayedTemperatureIsIncorrect' // The tempeature of the room is too hot or too cold
        },
        {
            key: 'comfortNotGoodWhenDevicesOffline',
            label: 'firstLevelQuestion.comfortNotGoodWhenDevicesOffline' // The tempeature of the room is too hot or too cold
        },
        {
            key: 'devicesOfflineForMoreThan1Day',
            label: 'firstLevelQuestion.devicesOfflineForMoreThan1Day' // I dispostivi sono offline da più di 24 ore
        },
        {
            key: 'radiatorStateInWebappIsWrong',
            label: 'firstLevelQuestion.radiatorStateInWebappIsWrong' // The radiator state showed in the webapp is not correct
        },
    ],
    secondLevelQuestions: {
        theRoomTemperatureIsTooColdOrTooHot: [
            {
                key: 'slowInReachingTargetTemperature',
                label: 'secondLevelQuestions.theRoomTemperatureIsTooColdOrTooHot.slowInReachingTargetTemperature', // La temperature non sale abbastanza in fredda la mattino
                actions: [db.diagnosticActions.HEAT_X_HOURS_BEFORE]
            },
            {
                key: 'alwaysTooColdOrHot',
                label: 'secondLevelQuestions.theRoomTemperatureIsTooColdOrTooHot.alwaysTooColdOrHot'//In this room it's always too cold / too hot:
            },
            {
                key: 'irregularTemperatureRegulation',
                label: 'secondLevelQuestions.theRoomTemperatureIsTooColdOrTooHot.irregularTemperatureRegulation',//La temperature all'interno della stanza è troppo irregolate (Troppo caldo e poi troppo freddo).
                actions: [
                    db.diagnosticActions.REDUCE_SLEEP_TIME,
                    db.diagnosticActions.ACTIVATE_PID,
                    db.diagnosticActions.DOUBLE_REPETITION
                ]
            },
            {
                key: 'theRadiatorTemperatureIsTooHot',
                label: 'secondLevelQuestions.theRoomTemperatureIsTooColdOrTooHot.theRadiatorTemperatureIsTooHot',//La temperatura del radiatore quando è acceso è troppo calda (scotta) (proposta motor on / 2)
                actions: [db.diagnosticActions.HALF_MOTOR_ON_VALUE]
            },
            {
                key: 'temperatureFollowTargetTempButTooHotOrTooCold',
                label: 'secondLevelQuestions.theRoomTemperatureIsTooColdOrTooHot.temperatureFollowTargetTempButTooHotOrTooCold'//La temperatura viene regolata correttamente rispetto alla target temp ma è troppo calda/fredda per gli utilizzatori della stanza
            },
            {
                key: 'roomIsExposedToSunAndItIsAlwaysTooHotWhenSun',
                label: 'secondLevelQuestions.theRoomTemperatureIsTooColdOrTooHot.roomIsExposedToSunAndItIsAlwaysTooHotWhenSun',//La stanza è esposta al sole ed è spesso troppo calda quando c'è il sole
                actions: [db.diagnosticActions.ACTIVATE_SUN]
            },
            {
                key: 'theTargetTempIsNotCorrectInRelationToRoomUsage',
                label: 'secondLevelQuestions.theRoomTemperatureIsTooColdOrTooHot.theTargetTempIsNotCorrectInRelationToRoomUsage',//La temperatura target non è corretta in relazione all'utilizzazione reale del locale
                actions: [db.diagnosticActions.ACTIVATE_AUTOMATIC_PROGRAM]
            },
            {
                key: 'temperatureDoesNotFollowTargetTemp',
                label: 'secondLevelQuestions.theRoomTemperatureIsTooColdOrTooHot.temperatureDoesNotFollowTargetTemp',//La temperatura non segue la target temp
                actions: [db.diagnosticActions.CALIBRATE_MOTOR]
            }
        ],
        theWebappDisplayedTemperatureIsIncorrect: [
            {
                key: 'showedTempIsDifferentFromMeasuredTemp',
                label: 'secondLevelQuestions.theRoomTemperatureIsTooColdOrTooHot.showedTempIsDifferentFromMeasuredTemp',//La temperatura misurata e visualizzata è diversa da quella misurata con un termometro esterno.
                actions: [db.diagnosticActions.TEMPERATURE_CALIBRATION_TO_MEASURED]
            },
            {
                key: 'qrCodeTemperatureIsDifferentFromMeasuredTemp',
                label: 'secondLevelQuestions.theRoomTemperatureIsTooColdOrTooHot.qrCodeTemperatureIsDifferentFromMeasuredTemp',//Scanning QR Code the temperature is not the same as and external thermometer
                actions: [db.diagnosticActions.TEMPERATURE_CALIBRATION_TO_MEASURED]
            },
            {
                key: 'temperatureDoesNotGoDownDuringNight',
                label: 'secondLevelQuestions.theRoomTemperatureIsTooColdOrTooHot.temperatureDoesNotGoDownDuringNight',//La temperatura non scende durante la notte
                actions: [db.diagnosticActions.CALIBRATE_MOTOR]
            },
            {
                key: 'temperatureDoesNotFollowTargetTemp',
                label: 'secondLevelQuestions.theRoomTemperatureIsTooColdOrTooHot.temperatureDoesNotFollowTargetTemp',//La temperatura non segue la target temp
                actions: [db.diagnosticActions.CALIBRATE_MOTOR]
            }
        ],
        comfortNotGoodWhenDevicesOffline: [
            {
                key: 'tempTooLowWhenDevicesOffline',
                label: 'secondLevelQuestions.theRoomTemperatureIsTooColdOrTooHot.tempTooLowWhenDevicesOffline',//La temperatura durante che I dispositivi sono offline è troppo bassa
                actions: [db.diagnosticActions.INCREASE_AUTONOMOUS_TEMP]
            },
            {
                key: 'tempTooHighWhenDevicesOffline',
                label: 'secondLevelQuestions.theRoomTemperatureIsTooColdOrTooHot.tempTooHighWhenDevicesOffline',//La temperature durante che I dispositivi sono offline è troppo alta
                actions: [db.diagnosticActions.DECREASE_AUTONOMOUS_TEMP]
            }
        ],
        devicesOfflineForMoreThan1Day: [],
        radiatorStateInWebappIsWrong: []
    },
    thirdLevelQuestions: {
        temperatureFollowTargetTempButTooHotOrTooCold: [
            {
                key: 'temperatureTooHighDuringPresence',
                label: 'thirdLevelQuestions.theRoomTemperatureIsTooColdOrTooHot.temperatureTooHighDuringPresence',//La temperature della stanza segue la target temp ma è troppo alta durante la presenza delle persone
                actions: [db.diagnosticActions.DECREASE_MAX_TEMP]
            },
            {
                key: 'temperatureTooLowDuringPresence',
                label: 'thirdLevelQuestions.theRoomTemperatureIsTooColdOrTooHot.temperatureTooLowDuringPresence',//La temperature della stanza segue la target temp ma è troppo bassa durante la presenza delle persone
                actions: [db.diagnosticActions.INCREASE_MAX_TEMP]
            },
            {
                key: 'temperatureTooHighDuringAbsence',
                label: 'thirdLevelQuestions.theRoomTemperatureIsTooColdOrTooHot.temperatureTooHighDuringAbsence',//La temperature della stanza segue la target temp ma è troppo alta durante l'assenza delle persone
                actions: [db.diagnosticActions.DECREASE_MIN_TEMP]
            },
            {
                key: 'temperatureTooLowDuringAbsence',
                label: 'thirdLevelQuestions.theRoomTemperatureIsTooColdOrTooHot.temperatureTooLowDuringAbsence',//La temperature della stanza segue la target temp ma è troppo bassa durante l'assenza delle persone
                actions: [db.diagnosticActions.INCREASE_MIN_TEMP]
            }
        ],
        alwaysTooColdOrHot: [
            {
                key: 'alwaysTooCold',
                label: 'thirdLevelQuestions.theRoomTemperatureIsTooColdOrTooHot.alwaysTooCold',//In this room it is always too Cold
                actions: [db.diagnosticActions.TEMPERATURE_CALIBRATION_DOWN]
            },
            {
                key: 'alwaysTooHot',
                label: 'thirdLevelQuestions.theRoomTemperatureIsTooColdOrTooHot.alwaysTooHot',//In this room it is always too hot
                actions: [db.diagnosticActions.TEMPERATURE_CALIBRATION_UP]
            },
        ]
    },
    /**
     *  <li>Calibration of the temperature of the room</li>
     *  <li>Calibration and check of the regulator control system</li>
     * <li>Reduce the target temperature when the room is not used</li>
     */
    actions: {
        temperatureCalibrationDown: {
            label: 'diagnostic.action.temperatureCalibrationDown'//Calibration of the temperature of the room
        },
        temperatureCalibrationUp: {
            label: 'diagnostic.action.temperatureCalibrationUp'
        },
        decreaseMaxTemp: {
            label: 'diagnostic.action.decreaseMaxTemp'//Reduce the target temperature when the room is not used
        },
        increaseMaxTemp: {
            label: 'diagnostic.action.increaseMaxTemp'
        },
        decreaseMinTemp: {
            label: 'diagnostic.action.decreaseMinTemp'
        },
        increaseMinTemp: {
            label: 'diagnostic.action.increaseMinTemp'
        },
        heatXHoursBefore: {
            label: 'diagnostic.action.heatXHoursBefore'
        },
        activateAutomaticProgram: {
            label: 'diagnostic.action.activateAutomaticProgram'
        },
        activateSun: {
            label: 'diagnostic.action.activateSun'
        },
        halfMotorOnValue: {
            label: 'diagnostic.action.halfMotorOnValue'
        },
        doubleRepetition: {
            label: 'diagnostic.action.doubleRepetition'
        },
        activatePid: {
            label: 'diagnostic.action.activatePid'
        },
        reduceSleepTime: {
            label: 'diagnostic.action.reduceSleepTime'
        },
        calibrateMotor: {
            label: 'diagnostic.action.calibrateMotor'//Calibration and check of the regulator control system
        },
        temperatureCalibrationToMeasured: {
            label: 'diagnostic.action.temperatureCalibrationToMeasured'
        }
    }
};

class DiagnosticResultItemTemperature extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            firstLevelAnswer: null,
            secondLevelAnswer: null,
            thirdLevelAnswer: null
        };

        this.initChart = this.initChart.bind(this);
        this.executeTemperatureActions = this.executeTemperatureActions.bind(this);
        this.getActionsText = this.getActionsText.bind(this);
        this.getActions = this.getActions.bind(this);
    }

    async componentDidMount() {
        let supportRequest = this.props.diagnosticRequest.get(db.DiagnosticRequest.SUPPORT_REQUEST);
        let room = supportRequest.get(db.SupportRequest.ROOM);
        let roomDevices = await new Parse.Query(db.classes.Device).equalTo(db.Device.ROOM, room);
        let home = await supportRequest?.get(db.SupportRequest.HOME)?.fetch();

        this.setState({supportRequest, room, roomDevices, home});
    }

    initChart(){
        let {
            roomDevices,
            viewDetailedOpened,
            room,
            home
        } = this.state;

        let showEstimatedRoomTemperature = room?.get(db.Room.SHOW_ESTIMATED_ROOM_TEMP_APP_CHART) ??
            home?.get(db.Home.SHOW_ESTIMATED_ROOM_TEMP_APP_CHART) ?? false;

        if(viewDetailedOpened !== true) return;

        function getEuropeZurichTimestamp(date){
            let offset = Math.abs(moment.tz.zone('Europe/Zurich').utcOffset(date.clone().valueOf())) * 60 * 1000;
            return date.clone().tz('Europe/Zurich').valueOf() + offset;
        }

        function getDeviceName(device){
            let serialNumber = device.get(db.Device.SERIAL_NUMBER);
            let deviceTyp = device.get(db.Device.DEVICE_TYP);
            let name = deviceTyp === db.Device.DEVICE_TYP$SENSP ? 'Sensor' :
                deviceTyp === db.Device.DEVICE_TYP$THERM ? 'Thermostate' : 'N/A';

            return `${name} ${serialNumber}`;
        }

        const {t, diagnosticRequest} = this.props;

        let measurements = diagnosticRequest.get(db.DiagnosticRequest.MEASUREMENTS);
        let supportRequest = diagnosticRequest.get(db.DiagnosticRequest.SUPPORT_REQUEST);
        let relatedDevices = supportRequest.get(db.SupportRequest.RELATED_DEVICES);
        let sensor = roomDevices.filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$SENSP)?.[0];

        let thermoTemperatureChartSeries = [];
        relatedDevices.forEach(device => {
            thermoTemperatureChartSeries.push({
                name: getDeviceName(device),
                data: measurements
                    .filter(measurement => {
                        return measurement[db.Measurement.DEVICE_ID] === device.id;
                    })
                    .map(measurement => {
                        let createdAt = measurement[db.Measurement.CREATED_AT];
                        let measuredTemp = measurement[db.Measurement.MEASURED_TEMP];

                        return [getEuropeZurichTimestamp(moment(createdAt)), measuredTemp];
                    })
            });
        });

        let roomTemperatureChartSeries = [];

        if(sensor) {
            roomTemperatureChartSeries.push({
                name: sensor.get(db.Device.SERIAL_NUMBER),
                data: measurements
                    .filter(measurement => {
                        return measurement[db.Measurement.DEVICE_ID] === sensor.id;
                    })
                    .map(measurement => {
                        let createdAt = measurement[db.Measurement.CREATED_AT];
                        let temp = measurement[db.Measurement.TEMP];

                        return [getEuropeZurichTimestamp(moment(createdAt)), temp];
                    })
            });
        } else {
            relatedDevices.forEach(device => {
                roomTemperatureChartSeries.push({
                    name: getDeviceName(device),
                    data: measurements
                        .filter(measurement => {
                            return measurement[db.Measurement.DEVICE_ID] === device.id;
                        })
                        .map(measurement => {
                            let createdAt = measurement[db.Measurement.CREATED_AT];
                            let temp = measurement[db.Measurement.TEMP];

                            if(showEstimatedRoomTemperature === true &&
                                measurement[db.Measurement.ESTIMATED_ROOM_TEMP] != null
                            ) {
                                temp = measurement[db.Measurement.ESTIMATED_ROOM_TEMP];
                            }

                            return [getEuropeZurichTimestamp(moment(createdAt)), temp];
                        })
                });
            });
        }

        let targetTemperatureChartSerie = [];
        relatedDevices.forEach(device => {
            targetTemperatureChartSerie.push({
                name: getDeviceName(device),
                //lineWidth: 0,
                data: measurements
                    .filter(measurement => {
                        return measurement[db.Measurement.DEVICE_ID] === device.id;
                    })
                    .map(measurement => {
                        let createdAt = measurement[db.Measurement.CREATED_AT];
                        let targetTemp = measurement[db.Measurement.TARGET_TEMP];

                        return [getEuropeZurichTimestamp(moment(createdAt)), targetTemp];
                    })
            });
        });

        let motorPositionChartSerie = [];
        relatedDevices.forEach(device => {
            motorPositionChartSerie.push({
                name: device.get(db.Device.SERIAL_NUMBER),
                data: measurements
                    .filter(measurement => {
                        return measurement[db.Measurement.DEVICE_ID] === device.id;
                    })
                    .map(measurement => {
                        let createdAt = measurement[db.Measurement.CREATED_AT];
                        let motorTargetState = measurement[db.Measurement.MOTOR_TARGET_STATE];

                        return [getEuropeZurichTimestamp(moment(createdAt)), motorTargetState];
                    })
            });
        });
        Highcharts.chart('thermo-temperature-chart', {
            chart: {
                type: 'spline',
                zoomType: 'x',
                height: 200
            },

            xAxis: {
                type: 'datetime',
            },

            yAxis: {
                min: 15,
                max: 35
            },

            title: {
                text: t('Thermo temperature chart')
            },

            subtitle: {
                text: ''
            },

            caption: {
                text: ''
            },

            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
            },
            credits: {
                enabled: false
            },
            series: thermoTemperatureChartSeries
        });
        Highcharts.chart('room-temperature-chart', {
            chart: {
                type: 'spline',
                zoomType: 'x',
                height: 200
            },

            xAxis: {
                type: 'datetime',
            },

            yAxis: {
                min: 15
            },

            title: {
                text: t('Estimated room temperature')
            },

            subtitle: {
                text: ''
            },

            caption: {
                text: ''
            },

            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
            },
            credits: {
                enabled: false
            },
            series: roomTemperatureChartSeries
        });
        Highcharts.chart('target-temperature-chart', {
            chart: {
                type: 'spline',
                zoomType: 'x',
                height: 200
            },
            yAxis: {
                title: {
                    text: t('Temperature °C')
                },
            },

            xAxis: {
                type: 'datetime',
            },

            title: {
                text: t('Target temperature')
            },

            subtitle: {
                text: ''
            },

            caption: {
                text: ''
            },

            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
            },
            credits: {
                enabled: false
            },
            series: targetTemperatureChartSerie
        });
        Highcharts.chart('motor-position-chart', {
            chart: {
                type: 'spline',
                zoomType: 'x',
                height: 300
            },
            yAxis: {
                title: {
                    text: t('Motor position') + '%'
                },
                min: -10,
                max: 100,
                plotBands: [
                    {
                        from: -10,
                        to: 0,
                        color: 'rgba(195,223,255,0.6)',
                        label: {
                            text: t('Radiator is turned off'),
                            align: 'center',
                            style: {
                                color: '#666666',
                                fontSize: '0.8em'
                            }
                        }
                    },
                    {
                        from: 1,
                        to: 20,
                        color: 'rgba(255,105,0,0.25)',
                        label: {
                            text: t('Radiator is turned on slightly'),
                            align: 'center',
                            style: {
                                color: '#666666',
                                fontSize: '0.8em'
                            }
                        }
                    },
                    {
                        from: 20,
                        to: 100,
                        color: 'rgba(255,16,0,0.20)',
                        label: {
                            text: t('Radiator is turned on'),
                            align: 'center',
                            style: {
                                color: '#666666',
                                fontSize: '0.8em'
                            }
                        }
                    }
                ]
            },

            xAxis: {
                type: 'datetime',
            },

            title: {
                text: t('Motor position') + ' %'
            },

            subtitle: {
                text: ''
            },

            caption: {
                text: ''
            },

            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
            },
            credits: {
                enabled: false
            },
            series: motorPositionChartSerie
        });
    }

    getActions(){
        let {firstLevelAnswer, secondLevelAnswer, thirdLevelAnswer} = this.state;
        let actions = [];

        if(thirdLevelAnswer){
            actions = thirdLevelAnswer.actions || [];
        } else if(secondLevelAnswer){
            actions = secondLevelAnswer.actions || [];
        } else if(firstLevelAnswer){
            actions = firstLevelAnswer.actions || [];
        } else {
            actions = [];
        }

        return actions;
    }

    getActionsText(){
        const {t} = this.props;
        const actions = this.getActions();

        return <div className='system-diagnostics-result-item-details-info'>
            {t('Thanks for your inputs! Based on your answers the system will perform the following actions to improve the situation')}:
            <br/>
            <ul>
                {
                    actions.map(actionKey => {
                        return <li key={actionKey}>
                            {t(temperatureQuestions.actions?.[actionKey]?.label)}
                        </li>
                    })
                }
            </ul>
            <br/>
            {t('Please click on "Execute actions" to perform these modification in the system. The modifications will have affect in the next hours. If the situation is not getting better in the next days, please re-do a diagnostic or send this ticket to the support by clicking "Save and submit to the customer service" button.')}
            <br/>
            <br/>
            {t('Good continuation with the energy optimization!')}
        </div>;
    }

    async executeTemperatureActions(){
        let {diagnosticRequest, t} = this.props;
        let {supportRequest} = this.state;


        try {
            diagnosticRequest.set(db.DiagnosticRequest.TEMPERATURE_ACTIONS_EXECUTED, new Date());

            await diagnosticRequest.save();

            let actions = this.getActions();

            await Parse.Cloud.run('execute-room-diagnostic-actions', {
                supportRequestId: supportRequest.id,
                actions
            });

            await new Parse.Object(db.classes.SupportRequestComment)
                .set(db.SupportRequestComment.COMMENT_HTML, ReactDOMServer.renderToStaticMarkup(this.getActionsText()))
                .set(db.SupportRequestComment.SUPPORT_REQUEST, supportRequest)
                .set(db.SupportRequestComment.SHOW_TO_CUSTOMER, true)
                .set(db.SupportRequestComment.ADDED_BY, Parse.User.current())
                .set(db.SupportRequestComment.ACTIONS, actions)
                .save();

            await swal({title: t('Success'), text: ' ', icon: 'success', button: [''], timer: 1000});

            this.props.refresh();
        } catch (e){
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    render() {
        const {t, diagnosticRequest} = this.props;
        let {firstLevelAnswer, secondLevelAnswer, thirdLevelAnswer, room, home} = this.state;

        /**
         * @type {{devicesStatus: {comment: *, device: *, statusLabel: *}[], comment: {fr: string, en: string, it: string, de: string}, statusLabel: string}} temperatureStatus
         */
        let temperatureStatus = diagnosticRequest.get(db.DiagnosticRequest.TEMPERATURE_STATUS);
        let temperatureActionsExecuted = diagnosticRequest.get(db.DiagnosticRequest.TEMPERATURE_ACTIONS_EXECUTED);

        let item = {
            'title': 'Temperature',
            'description': temperatureStatus?.comment?.[this.props.i18n.resolvedLanguage],
            'icon': convertColorsToIconTag(temperatureStatus.statusLabel), //info,success,warning,danger
            'infoMessage': '',
            'warningMessage': ''
        };

        let firstLevelQuestions = temperatureQuestions.firstLevelQuestions.map(question => ({
            key: question.key,
            label: t(question.label)
        }));

        // eslint-disable-next-line max-len
        let secondLevelQuestions = temperatureQuestions.secondLevelQuestions?.[firstLevelAnswer?.key]?.map(question => ({
            key: question.key,
            label: t(question.label),
            actions: question.actions
        })) ?? [];

        let thirdLevelQuestions = temperatureQuestions.thirdLevelQuestions?.[secondLevelAnswer?.key]?.map(question => ({
            key: question.key,
            label: t(question.label),
            actions: question.actions
        })) ?? [];

        return <div>
            <div className={'system-diagnostics-result-item'}>
                <div className='system-diagnostics-result-item-header'>
                    <div className='system-diagnostics-result-item-group'>
                        <div style={{alignSelf: 'center'}}>
                            {item.icon === 'info' && <img src={infoIcon} className='system-diagnostics-result-item-icon'/>}
                            {item.icon === 'success' && <img src={successIcon} className='system-diagnostics-result-item-icon'/>}
                            {item.icon === 'warning' && <img src={warningIcon} className='system-diagnostics-result-item-icon'/>}
                            {item.icon === 'danger' && <img src={errorIcon} className='system-diagnostics-result-item-icon'/>}
                        </div>
                        <div className='system-diagnostics-result-item-status'>
                            <div className='system-diagnostics-result-item-title'>
                                {t(item.title)}
                            </div>
                            <div className='system-diagnostics-result-item-description'>
                                {t(item.description)}
                            </div>
                        </div>
                    </div>
                    <div className='system-diagnostics-result-item-view-details'>
                        <a onClick={(e) => {
                            e.preventDefault();
                            this.setState(prevState => {
                                return {
                                    viewDetailedOpened:  !prevState.viewDetailedOpened
                                };
                            }, () =>  this.initChart());
                        }} className='system-diagnostics-result-item-details-label'>{t('View details')}</a>

                        <a onClick={(e) => {
                            e.preventDefault();
                            this.setState(prevState => {
                                return {
                                    viewDetailedOpened:  !prevState.viewDetailedOpened
                                };
                            }, () => this.initChart());
                        }} className={`system-diagnostics-result-item-details-icon arrow ${this.state.viewDetailedOpened ? 'is-active' : ''}`}></a>
                    </div>
                </div>
                {this.state.viewDetailedOpened && (<div className='system-diagnostics-result-item-details' style={{width: '100%'}}>
                    {item.infoMessage && <div className='system-diagnostics-result-item-details-info'>
                        {t(item.infoMessage)}
                    </div>}
                    {item.warningMessage && <div className='system-diagnostics-result-item-details-warning'>
                        {t(item.warningMessage)}
                    </div>}
                    {item.buttons && <div className='system-diagnostics-result-item-details-buttons'>
                        {item.buttons.map((button, index) => {
                            return <a
                                key={index}
                                target='_blank'
                                href={button.url}
                                className='system-diagnostics-result-item-details-button' rel="noreferrer">
                                {t(button.label)}
                            </a>;
                        })}
                    </div>}

                    <figure className="highcharts-figure" style={{width: '100%'}}>
                        <div id="thermo-temperature-chart" style={{width: '100%'}}></div>
                    </figure>
                    <figure className="highcharts-figure" style={{width: '100%'}}>
                        <div id="room-temperature-chart" style={{width: '100%'}}></div>
                    </figure>
                    <figure className="highcharts-figure" style={{width: '100%'}}>
                        <div id="target-temperature-chart" style={{width: '100%'}}></div>
                    </figure>
                    <figure className="highcharts-figure" style={{width: '100%'}}>
                        <div id="motor-position-chart" style={{width: '100%'}}></div>
                    </figure>

                    {
                        !temperatureActionsExecuted && <>
                            <div className={'form-group full-w'}>
                                <label style={{width: 300}}>{t('Please select and answer')} *</label>
                                <SelectSingle
                                    t={t}
                                    value={firstLevelAnswer?.key}
                                    options={firstLevelQuestions}
                                    style={{maxWidth: 600}}
                                    onChange={answer => this.setState({firstLevelAnswer: answer})}
                                />
                            </div>
                            {
                                this.state.firstLevelAnswer  && secondLevelQuestions.length > 0 &&
                                    <div className={'form-group full-w'}>
                                        <label style={{width: 300}}>{t('Please select and answer')} *</label>
                                        <SelectSingle
                                            t={t}
                                            value={secondLevelAnswer?.key}
                                            options={secondLevelQuestions}
                                            style={{maxWidth: 600}}
                                            onChange={answer => this.setState({secondLevelAnswer: answer})}
                                        />
                                    </div>
                            }

                            {
                                this.state.firstLevelAnswer &&
                                this.state.secondLevelAnswer &&
                                thirdLevelQuestions.length > 0 &&
                                    <div className={'form-group full-w'}>
                                        <label style={{width: 300}}>{t('Please select and answer')} *</label>
                                        <SelectSingle
                                            t={t}
                                            value={thirdLevelAnswer?.key}
                                            options={thirdLevelQuestions}
                                            style={{maxWidth: 600}}
                                            onChange={answer => this.setState({thirdLevelAnswer: answer})}
                                        />
                                    </div>
                            }

                            {
                                (
                                    firstLevelQuestions.length === 0 ||
                                    (firstLevelQuestions.length > 0 && firstLevelAnswer != null )
                                ) &&
                                (
                                    secondLevelQuestions.length === 0 ||
                                    (secondLevelQuestions.length > 0 && secondLevelAnswer != null)
                                )  &&
                                (
                                    thirdLevelQuestions.length === 0 ||
                                    (thirdLevelQuestions.length > 0 && thirdLevelAnswer != null)
                                ) &&
                                this.getActionsText()
                            }

                            <button className='room-reporting-export-button' style={{float: 'right'}}
                                onClick={this.executeTemperatureActions}
                            >
                                {t('Execute the action')}
                            </button>
                        </>
                    }

                    {
                        temperatureActionsExecuted && <div className='system-diagnostics-result-item-details-info'>
                            {t('Some actions for this diagnostic have been already executed. Please open a new diagnostic if you would like to execute more diagnostic actions.')}
                        </div>
                    }

                </div>
                )}
            </div>
        </div>
    }
}

DiagnosticResultItemTemperature.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    history: PropTypes.any.isRequired,
    diagnosticRequest: PropTypes.object.isRequired,
    refresh: PropTypes.func.isRequired
};

export default DiagnosticResultItemTemperature;