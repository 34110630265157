'use strict';

import React, {Fragment} from 'react';
import PropType from 'prop-types';

class Breadcrumbs extends React.Component {
    constructor(props){
        super(props);
    }

    render(){

        return <Fragment>
            <ul className="breadcrumbs list-unstyled">
                {this.props.elements && this.props.elements.map(element => {
                    return <li key={element.name}>
                        <a href="#"
                            onClick={() => this.props.history.push(element.link || '#')}>
                            {element.name}
                        </a>
                    </li>
                })}
            </ul>
        </Fragment>;
    }
}

Breadcrumbs.propTypes = {
    t: PropType.func,
    elements: PropType.array,
    history: PropType.any
};

export default Breadcrumbs;
