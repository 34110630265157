'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import i18next from './../i18n';
import {get, merge} from 'lodash';

import translate from '../translate';

class InvalidLink extends React.Component {
    constructor(props){
        super(props);

    }

    componentDidMount(){
    }

    changeLanguage(language){
        i18next.changeLanguage(language);
        document.cookie = 'i18next=' + language;
    }

    render(){
        const {t} = this.props;

        return <div className={'login-page'}>
            {
                get(this.props, 'shared.generalError.visible') && <div className={'alert alert-danger'}>
                    {get(this.props, 'shared.generalError.message')}
                </div>
            }
            <div className={'back-image'}/>
            <div className="auth-logo">{t('Cleveron')}</div>
            <div className="card auth-card card-forgot-password">
                <div className="card-body">
                    <div className="m-b-36">
                        <div className="dropdown pull-right auth-dropdown">
                            <button className="dropdown-toggle " type="button" id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                {this.props.i18n.resolvedLanguage}
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a className="dropdown-item" href="#"
                                    onClick={() => this.changeLanguage('en')}>{t('English')}</a>
                                <a className="dropdown-item" href="#"
                                    onClick={() => this.changeLanguage('it')}>{t('Italian')}</a>
                                <a className="dropdown-item" href="#"
                                    onClick={() => this.changeLanguage('fr')}>{t('French')}</a>
                                <a className="dropdown-item" href="#"
                                    onClick={() => this.changeLanguage('de')}>{t('German')}</a>
                            </div>
                        </div>
                    </div>
                    <div className="header">
                        <div className="title">{t('Invalid Link')}</div>
                        <div
                            className="subtitle">
                            <a href={'/'}>{t('Something went wrong. Click here to go back')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

InvalidLink.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired,
};
/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(InvalidLink));