module.exports = {
    PARSE_CURRENT_USER: 'Parse/NjSUT8HxvCz706ldcwUn/currentUser',
    BUILDING_ADMINISTRATOR_ID: 'buildingAdministratorId	xAupzcGLCF',
    USER_ROLES: 'user:roles',
    USER_HAS_READ_PERMISSION: 'user:hasReadPermission',
    USER_HAS_WRITE_PERMISSION: 'user:hasWritePermission',
    HOME_TYPE_OF_CUSTOMER: 'home:typeOfCustomer',
    BUILDING_SELECTION: 'buildingSelection',
    ROOM_FEEDBACK_ID: 'roomFeedbackHomeId',
    I18NEXTLNG: 'i18nextLng',
    ROOM_FEEDBACK_ROOM_ID: 'roomFeedbackRoomId',
    ROOM_FEEDBACK_HOME_ID: 'roomFeedbackHomeId',
    USER_ID_ADMIN: 'user:isAdmin',
    LAST_ACTIVITY_TIMESTAMP: 'lastActivityTimestamp',
    LIVE_REPORT_CUSTOM_SELECTED_COLUMNS: 'liveReportCustomSelectedColumns',
    MONTHLY_REPORT_CUSTOM_SELECTED_COLUMNS: 'monthlyReportCustomSelectedColumns',
    BUILDING_SELECTED_FLOOR: 'buildingSelectedFloor'
};