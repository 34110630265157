'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {get, merge} from 'lodash';

import translate from '../translate';
import Parse from 'parse';
import LanguagePicker from '../language-picker';
import logoImgMini from '../../assets/images/logo-mini.png';

class ForgotPasswordPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            email: '',
            users: {},
            name: '',
            serverMail: '',
            emailExists: false,
            currentPage: 'forgot-password'
        };

        this.send = this.send.bind(this);
        this.changeInputField = this.changeInputField.bind(this);
        this.renderForm = this.renderForm.bind(this);
    }

    async componentDidMount(){
    }

    send(event){
        event.preventDefault();
        if (this.state.email) {

            this.getUser()
                .then(() => this.routeChange())
                .catch(console.error);
        }
    }

    routeChange(){
        if (this.state.emailExists) {
            this.setState({
                currentPage: 'email-sent'
            });
            this.renderForm();
        } else {
            this.setState({
                currentPage: 'email-not-found'
            });
            this.renderForm();
        }
    }

    async getUser(){
        try {
            let userExists = await Parse.Cloud.run('checkIfMailExists', {email: this.state.email});
            if (userExists) {
                await Parse.Cloud.run('resetPw', {email: this.state.email});
                this.setState({emailExists: true, email: ''});
            } else {
                this.setState({emailExists: false, email: ''});
            }

        } catch (err) {
            console.error(err);
        }
    }

    changeInputField(e){
        let value = e.target.value;
        this.setState({email: value});

    }

    handleEnterKeyPress(e){
        if (e.target.charCode === 13) {
            this.send();
        }
    }

    renderForm(){
        const {t} = this.props;

        switch (this.state.currentPage) {
            case 'forgot-password':
                return <div>
                    <div className="header">
                        <div className="title">{t('Forgot password?')}</div>
                        <div className="auth-start-logo"><img src={logoImgMini} alt="Cleveron" /></div>
                        <div
                            className="subtitle">{t('Please enter your email and we will immediately send you an e-mail containing all the information on how to choose a new password')}</div>
                    </div>

                    <form className="p-b-32" onSubmit={this.send}>
                        <div className="form-group">
                            <input type="email"
                                className="form-control"
                                id="email"
                                name={'email'}
                                aria-describedby="emailHelp"
                                placeholder={t('Email')}
                                value={this.state.email}
                                onChange={this.changeInputField}
                                onKeyPress={this.handleEnterKeyPress}
                            />
                        </div>
                        <button type="submit"
                            className="btn"
                            disabled={!this.state.email}
                        >{t('Send')}
                        </button>
                    </form>
                </div>;
            case 'email-not-found':
                return <div>
                    <div className="header">
                        <div className="title">{t('Error!')}</div>
                        <div
                            className="subtitle">{t('E-Mail not found. Please try again')}</div>
                    </div>
                    <form className="p-b-32" onSubmit={this.send}>
                        <div className="form-group">
                            <input type="email"
                                className="form-control"
                                id="email"
                                name={'email'}
                                aria-describedby="emailHelp"
                                placeholder={t('Email')}
                                value={this.state.email}
                                onChange={this.changeInputField}
                                onKeyPress={this.handleEnterKeyPress}
                            />
                        </div>
                        <button type="submit"
                            className="btn"
                            disabled={!this.state.email}
                        >{t('Send')}
                        </button>
                    </form>
                </div>;
            case 'email-sent':
                return <div className='wat'>
                    <div className="header">
                        <div className="title">{t('Success!')}</div>
                        <div className="subtitle">{t('E-Mail sent')}</div>
                        <div
                            className="subtitle">{t('Please check your mailbox. If you didn\'t receive an E-Mail, please contact us under')}</div>
                        <a href="mailto: support@cleveron.ch">support@cleveron.ch</a>
                        <br/>
                        <br/>
                        <a
                            href={'#'}
                            onClick={() => this.props.history.push('/login')}>{t('Back to login page')}</a>
                    </div>
                </div>;
        }
    }

    goBack(event){
        event.preventDefault();

        this.props.history.goBack();
    }

    render(){
        const {t} = this.props;
        let currentPage = 'forgot-password';

        return <div className={'login-page'}>
            {
                get(this.props, 'shared.generalError.visible') && <div className={'alert alert-danger'}>
                    {get(this.props, 'shared.generalError.message')}
                </div>
            }
            <div className={'back-image'}/>
            <a className="auth-logo" onClick={() => this.props.history.push('/')}
                href="#">{t('Cleveron')}</a>
            <div className="card auth-card card-forgot-password">
                <div className="card-body">
                    <div className="mobile_signup_header">
                        <p>{t('Forgot password?')}</p>
                        <div className="mobile_signup_header_posi">
                            <a onClick={this.goBack.bind(this)}><i className="fa fa-angle-left"></i><span>{t('Back')}</span></a>
                        </div>
                    </div>
                    <div className="m-b-36">
                        <LanguagePicker t={t} i18n={this.props.i18n} />
                    </div>
                    {this.renderForm(currentPage)}
                </div>
            </div>
        </div>
    }
}

ForgotPasswordPage.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired,
};
/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(ForgotPasswordPage));