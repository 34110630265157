'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import db from '../../../lib/structure';

export default class RoomRecommendationList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            roomRecommendations: [
                {},
                {},
                {},
            ]
        };
    }

    componentDidMount() {
        let roomRecommendations = this.loadRoomRecomandations();

        roomRecommendations = [
            {},
            {},
            {},
        ];

        this.setState({roomRecommendations});
    }

    async loadRoomRecomandations(){
        let roomRecommendations = new Parse.Query(db.classes.RoomRecommendation)
            .equalTo(db.RoomRecommendation.ROOM, this.props.room)
            .equalTo(db.RoomRecommendation.MONTH, this.props.month)
            .equalTo(db.RoomRecommendation.YEAR, this.props.year)
            .find();

        return roomRecommendations;
    }

    render() {
        return <div className={'room-recommendations'}>
            <h4>Recommendations</h4>
            <div className={'room-recommendations-list'}>
                {
                    this.state.roomRecommendations.map((roomRecommendation, i) => {
                        return <div className={'room-recommendations-list-item'} key={i}>
                            <button type="button" className="edit-icon" onClick={() => {}}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                    <path fill="#D5D5D5"
                                        d="M24.4241432,10.8047398 L19.9365098,6 L5,20.9365098 L5,25.5323589 L9.59584916,25.5323589 L24.4241432,10.8047398 Z M8.71980568,23.7743806 L6.78695388,23.7743806 L6.78695388,21.6255487 L19.9365098,8.71098229 L22.0119725,10.7710606 L8.71980568,23.7743806 Z M11.8937737,26.6813212 L14.0149192,24.8044897 L27.5900002,24.8044897 L25.6813212,26.6813212 L11.8937737,26.6813212 Z"/>
                                </svg>
                            </button>
                            <div className={'title'}>We’ve noticed a high level of the CO2 in your room!</div>
                            <div className={'comment'}>The average co2 of the room is higher than 60% of the rooms in Switzerland. We suggest informing your building users to open the windows more frequently.</div>
                        </div>;
                    })
                }
            </div>
        </div>
    }
}

RoomRecommendationList.propTypes = {
    t: PropTypes.any,
    room: PropTypes.object,
    month: PropTypes.number,
    year: PropTypes.number
};