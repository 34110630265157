'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Parse from 'parse';
import {get, merge} from 'lodash';
import {manageError, resetLocalStorage} from '../../lib/util';
import translate from '../translate';
import {redirectIfLogged} from '../../lib/parse-utils';
import LanguagePicker from '../language-picker';
import logoImgMini from '../../assets/images/logo-mini.png';
import db from '../../lib/structure';

const paths = require('../../lib/paths');

// catch the event and make changes accordingly

class LoginPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            username: '',
            password: ''
        };

        this.logIn = this.logIn.bind(this);
        this.changeInputField = this.changeInputField.bind(this);
    }

    componentDidMount(){
        let urlSearchParams = new URLSearchParams(this.props?.location?.search);
        let source = urlSearchParams?.get('source');
        let username = urlSearchParams?.get('username') || '';
        let password = urlSearchParams?.get('password') || '';
        this.goBack = this.goBack.bind(this);
        redirectIfLogged(this.props.history);

        this.setState({source, username, password});
    }

    goBack(event){
        event.preventDefault();

        this.props.history.goBack();
    }

    async logIn(event){
        event.preventDefault();
        try {
            let user = await Parse.User.logIn(this.state.username, this.state.password)

            resetLocalStorage();

            const adminRoleQuery = new Parse.Query(Parse.Role);
            adminRoleQuery.equalTo(db._Role.USERS, user);
            let roles = await adminRoleQuery.find();
            let rolesNames = roles.map(role => role.get(db._Role.NAME));

            localStorage.setItem('user:roles', rolesNames);

            if(user.id === 'AxA3LXX6xd'){
                localStorage.setItem('demoAccount', 'true');
            }

            window.pagesense = window.pagesense || [];
            window.pagesense.push(['identifyUser', user?.get(db._User.EMAIL)]);

            this.props.history.push(paths.buildings)
        } catch (e){
            manageError(e, this.props)
        }
    }

    changeInputField(e){
        let name = e.target.name;
        let value = e.target.value;

        this.setState({[name]: value});
    }

    getUrlVars(){
        var vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
            function (m, key, value){
                vars[key] = value;
            });
        return vars;
    }

    render(){
        const {t} = this.props;

        return <div className={'login-page'}>
            {
                get(this.props, 'shared.generalError.visible') && <div className={'alert alert-danger'}>
                    {get(this.props, 'shared.generalError.message')}
                </div>
            }

            <div className={'back-image'}></div>

            <a className="auth-logo" onClick={() => this.props.history.push('/')}
                href="#">{t('Cleveron')}</a>

            <div className="card auth-card card-login">
                <div className="card-body">
                    <div className="mobile_signup_header">
                        <p>{t('Sign in')}</p>
                        {
                            this.state.source === 'start' &&  <div className="mobile_signup_header_posi">
                                <a onClick={this.goBack.bind(this)}><i className="fa fa-angle-left"></i><span>{t('Back')}</span></a>
                            </div>
                        }
                    </div>
                    <div className="m-b-36">
                        <LanguagePicker t={t} i18n={this.props.i18n} />
                    </div>
                    <div className="header">
                        <div className="title">{t('Log In')}</div>
                        <div className="auth-start-logo"><img src={logoImgMini} alt="Cleveron" /></div>
                        <div className="subtitle">{t('Sign-in to your account')}
                        </div>
                    </div>
                    <form className="p-b-32" onSubmit={this.logIn}>
                        <div className="form-group">
                            <label htmlFor="username">{t('Username')} /{t('E-mail')}</label>
                            <input type="text"
                                className="form-control"
                                id="username"
                                name={'username'}
                                aria-describedby=""
                                placeholder={t('Ex. john@gmail.com')}
                                value={this.state.username}
                                onChange={this.changeInputField}
                            />
                        </div>
                        <div className="form-group m-b-32">
                            <label htmlFor="password">{t('Password')}</label>
                            <input type="password"
                                className="form-control"
                                id="password"
                                name={'password'}
                                placeholder={t('Password')}
                                value={this.state.password}
                                onChange={this.changeInputField}
                            />
                        </div>
                        <button type="submit"
                            className="btn">{t('Sign in')}
                        </button>
                    </form>
                    <div className="footer-links">
                        <a
                            href={'#'}
                            onClick={() => this.props.history.push('/forgot-password')}>{t('Forgot password?')}</a>
                        <p>{t('Don\'t have an account?')} <a href={'#'} onClick={() => this.props.history.push(paths.registrationEmail)}>{t('Sign up')}</a></p>
                    </div>
                </div>
            </div>
        </div>
    }
}

LoginPage.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired,
};
/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(LoginPage));