'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../translate';
import {connect} from 'react-redux';
import moment from 'moment-timezone';
import {merge} from 'lodash';

import Parse from 'parse';
import * as db from '../../../lib/structure';
import {addCloseButtonToDatepicker, getMeasurementFromDemoRoom, isValidCo2Value, manageError} from '../../../lib/util';
import Loader from '../../loader';
import i18next from '../../i18n';

import windowsAlertPointUp from '../../../assets/images/windows-open-point-up.svg';
import windowAlertPointDown from '../../../assets/images/window-alert-point-down.png';

let okToResize = true;

class RoomClimate extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            loading: {
                temp: false
            },
            showCo2: false
        };

        this.loadMeasurements = this.loadMeasurements.bind(this);
        this.createSeries = this.createSeries.bind(this);
        this.setupChartist = this.setupChartist.bind(this);
        this.initDatePicker = this.initDatePicker.bind(this);
        this.updateChart = this.updateChart.bind(this);
        this.renderTempChart = this.renderTempChart.bind(this);
        this.renderHumidityChart = this.renderHumidityChart.bind(this);
        this.renderCo2Chart = this.renderCo2Chart.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }

    async componentDidMount(){
        this.initDatePicker();

        let room = await this.getRoom(this.props.roomId);

        this.setState({room});

        this.updateChart(
            room,
            moment().tz('Europe/Zurich').startOf('day').toDate(),
            moment().tz('Europe/Zurich').endOf('day').toDate()
        );

        i18next.on('languageChanged', this.handleLanguageChange);
    }

    componentWillUnmount(){
        i18next.off('languageChanged', this.handleLanguageChange);
    }

    handleLanguageChange(){
        const date = $('#date-picker').datepicker('getDate');
        $('#date-picker').datepicker('destroy');
        this.initDatePicker(date);
    }

    async getRoom(roomId){
        return new Parse.Query(db.classes.Room)
            .include(db.Room.HOME)
            .get(roomId);
    }

    async getWindowsOpeningArray(room, day, month, year){
        let roomDailyReport = await new Parse.Query(db.classes.RoomDailyReport)
            .equalTo(db.RoomDailyReport.ROOM, room)
            .equalTo(db.RoomDailyReport.DAY, day)
            .equalTo(db.RoomDailyReport.MONTH, month)
            .equalTo(db.RoomDailyReport.YEAR, year)
            .first();

        if(!roomDailyReport) return [[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]];

        let hourlyWindowsOpening = roomDailyReport.get(db.RoomDailyReport.HOURLY_WINDOWS_OPENING);

        if(!hourlyWindowsOpening) return [[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]];

        hourlyWindowsOpening = hourlyWindowsOpening.map(windows => {
            if(windows === null) return 0;

            return windows.length
        });

        return hourlyWindowsOpening;
    }

    initDatePicker(date){
        $('#date-picker').datepicker({
            todayHighlight: true,
            format: 'dd M yyyy',
            autoclose: true,
            maxViewMode: 0,
            weekStart: 1,
            language: this.props.i18n.resolvedLanguage
        })
            .on('changeDate', (e) => {
                let date = moment(e.date);

                if(this.state.room)
                    this.updateChart(this.state.room, date.startOf('day').toDate(), date.endOf('day').toDate());
            }).on('show', (e) => {
                addCloseButtonToDatepicker(e.currentTarget.id, this.props.t);
            });

        $('#date-picker').datepicker('setDate', date || moment().toDate())
    }

    async updateChart(room, dStart, dEnd){
        let min = room.get(db.Room.TEMP_MIN);
        let max = room.get(db.Room.TEMP_MAX) + 1;
        let day = moment(dStart).date();
        let month = moment(dStart).month() + 1;
        let year = moment(dStart).year();
        let openWindow = await this.getWindowsOpeningArray(room, day, month, year)

        await this.loadMeasurements(room.id, dStart, dEnd)
            .then(measurements => this.createSeries(measurements, dStart, openWindow, room))
            .then(data => {
                let tempArray = data.temp.series[0].map(tempSerie => tempSerie.value === 'NaN' ? null : parseFloat(tempSerie.value)).filter(temp => temp != null);
                let presenceArray = data?.presence?.series?.[0]
                    .map(presenceSerie => presenceSerie.value === 'NaN' ? null : parseFloat(presenceSerie.value))
                    .filter(presence => presence != null)
                    .filter(presence => !isNaN(presence));

                let maxTemp = Math.max.apply(null, tempArray);
                let minTemp = Math.min.apply(null, tempArray);

                if (maxTemp > max) {
                    max = maxTemp;
                    max += 1;
                }

                if (minTemp < min) {
                    min = minTemp;
                    min -= 1;
                }

                this.setupChartist(data.temp, '.temp-chart-lines', min, max);
                this.setupChartist(data.humidity, '.humidity-chart-lines', 0, 70);
                this.setupChartist(data.co2, '.co2-chart-lines', 300, 2500);

                if(presenceArray){
                    let maxPresence = Math.max.apply(null, presenceArray);

                    this.setupChartist(
                        data.presence,
                        '.presence-chart-lines',
                        0,
                        maxPresence + 20
                    );
                }
            })
            .catch(manageError);
    }

    async loadMeasurements(roomId, dStart, dEnd) {
        this.setState({loading: {temp: true}});

        try {
            let measurements = await Parse.Cloud.run('get-room-measurements', {roomId, fromDate: dStart, toDate: dEnd, deviceTypes: [
                db.Device.DEVICE_TYP$SENSP,
                db.Device.DEVICE_TYP$THERM,
                db.Device.DEVICE_TYP$SENSE,
                db.Device.DEVICE_TYP$SENCO
            ]});

            this.setState({loading: {temp: false}});

            return measurements;
        } catch (err) {
            this.setState({loading: {temp: false}});
            manageError(err);
        }
    }

    /**
     *
     * @param measurements
     * @param dStart
     * @param openWindow
     * @param room
     * @return {Promise<{temp: {series: [*], labels: *}, co2: ({series: [*], labels: *}|null), humidity: {series: [*], labels: *}, presence: ({series: [*], labels: *}|null)}>}
     */
    async createSeries(measurements, dStart, openWindow, room){
        let home = await room.get(db.Room.HOME);
        if(openWindow){
            if(openWindow.length !== 24) {
                console.error('Open window array on room has a wrong length');
                openWindow = null;
            }
        }

        let tempSerie = Array(24).fill(0);
        let humSerie = Array(24).fill(0);
        let co2Serie = Array(24).fill(0);
        let presenceSerie = Array(24).fill(null);

        let openWindowSerie = Array(24).fill(false);

        let countTemp = Array(24).fill(0);
        let countHum = Array(24).fill(0);
        let countCo2 = Array(24).fill(0);
        let countPresence = Array(24).fill(0);

        let today = moment();
        let currentHour = today.hour();

        let co2IsPresent = false;

        let showOnlySensorOnChart = true;
        let showEstimatedRoomTemperature = room?.get(db.Room.SHOW_ESTIMATED_ROOM_TEMP_APP_CHART) ??
            home?.get(db.Home.SHOW_ESTIMATED_ROOM_TEMP_APP_CHART) ?? false;

        /*room.get(db.Room.SHOW_ONLY_SENSOR_ON_WEB_APP_CHART) ??
        home?.get(db.Home.SHOW_ONLY_SENSOR_ON_WEB_APP_CHART);*/

        let sensors;
        let roomHasSensor;
        if(showOnlySensorOnChart === true){
            let connectedDevices = await (new Parse.Query(db.classes.Device))
                .equalTo(db.Device.ROOM_ID, room)
                .greaterThanOrEqualTo(db.Device.LAST_MEASUREMENT_DATE, moment().subtract(1, 'hour').toDate())
                .select([db.Device.DEVICE_TYP, db.Device.LAST_MEASUREMENT_DATE])
                .find();

            sensors = connectedDevices
                .filter(device =>
                    device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$SENSE ||
                    device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$SENSP ||
                    device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$SENCO
                );

            roomHasSensor =  sensors.length > 0;
        }

        measurements && measurements.forEach(measurement => {
            let deviceTyp = measurement.get(db.Measurement.DEVICE_TYP);
            let date = moment(measurement.get(db.Measurement.CREATED_AT) || measurement.get('timestamp'));
            let hour = date.tz('Europe/Zurich').hour();

            let isBeforeToday = moment(dStart).isBefore(today.startOf('day'));
            if(!isBeforeToday && hour > currentHour) return;

            let hum = measurement.get(db.Measurement.HUM);
            let temp = measurement.get(db.Measurement.TEMP);
            let co2 = measurement.get(db.Measurement.CO2);
            let presence = measurement.get(db.Measurement.PRESENCE);
            //let targetTemp = measurement.get(db.Measurement.TARGET_TEMP);

            if(isValidCo2Value(co2) && co2 < 400) co2 = 400;

            if(showEstimatedRoomTemperature){
                temp = measurement.get(db.Measurement.ESTIMATED_ROOM_TEMP) ?? temp;
            }

            openWindowSerie[hour] = openWindow == null ? false : openWindow[hour] === 1;

            if (deviceTyp === db.Device.DEVICE_TYP$SENSE ||
                deviceTyp === db.Device.DEVICE_TYP$SENSP ||
                deviceTyp === db.Device.DEVICE_TYP$SENCO
            ) {
                if (temp === -1)
                    return;
                if(temp != null){
                    tempSerie[hour] += temp;
                    countTemp[hour]++;
                }

                if(hum != null) {
                    humSerie[hour] += hum;
                    countHum[hour]++;
                }

                if(presence != null){
                    let takeIntoAccount = false;

                    if(presenceSerie[hour] === 0 && presence === 0){
                        takeIntoAccount = false;
                    } else if (presenceSerie[hour] === 0 && presence !== 0){
                        takeIntoAccount = true;
                    } else if (presenceSerie[hour] > 0 && presence === 0) {
                        takeIntoAccount = false;
                    } else if (presenceSerie[hour] > 0 && presence > 0){
                        takeIntoAccount = true;
                    }

                    if(presenceSerie[hour] == null){
                        presenceSerie[hour] = presence;
                        countPresence[hour]++;
                    } else if(takeIntoAccount){
                        presenceSerie[hour] += presence;
                        countPresence[hour]++;
                    }
                }
            }

            if (isValidCo2Value(co2)) {
                co2Serie[hour] += co2;
                countCo2[hour]++;
            }

            if(roomHasSensor && showOnlySensorOnChart === true) return;

            if (deviceTyp === 'therm') {
                if(temp != null){
                    tempSerie[hour] += temp;
                    countTemp[hour]++;
                }

                if(hum != null) {
                    humSerie[hour] += hum;
                    countHum[hour]++;
                }

                if (presence != null) {
                    presenceSerie[hour] += presence * 100;
                    countPresence[hour]++;
                }
            }
        });

        tempSerie = tempSerie.map((sumTemp, h) => {
            return sumTemp / countTemp[h];
        });

        humSerie = humSerie.map((sumHum, h) => {
            return sumHum / countHum[h];
        });

        tempSerie = tempSerie.map((temp, h) => ({
            meta: `${temp.toFixed(1)} °C`,
            value: temp.toFixed(1),
            openWindow: openWindowSerie[h]
        }));
        humSerie = humSerie.map((hum, h) => ({
            meta: `${hum.toFixed(0)} %`,
            value: hum.toFixed(0),
            openWindow: openWindowSerie[h]
        }));

        co2IsPresent = await this.roomHasCo2Device();

        if (co2IsPresent) {
            this.setState({showCo2: true});

            co2Serie = co2Serie.map((sumCo2, h) => {
                return sumCo2 / countCo2[h];
            });

            co2Serie = co2Serie.map((co2, h) => ({
                meta: `${co2.toFixed(0)} ppm`,
                value: co2.toFixed(0),
                openWindow: openWindowSerie[h]
            }));

            presenceSerie = presenceSerie.filter(sumPresence => sumPresence != null).map((sumPresence, h) => {
                if(sumPresence === 0)
                    return {
                        meta: '0 %',
                        value: 0
                    };

                let count = countPresence[h];
                let value = count <= 0 ? 0 : sumPresence / countPresence[h];

                if(value > 100) value = 100;
                return {
                    meta: `${value.toFixed(0)} %`,
                    value: value
                }
            });
        }

        let temp = {
            labels: Array(24).fill(0).map((value, i) => `${i}:00`),
            series: [
                tempSerie
            ]
        };

        let humidity = {
            labels: Array(24).fill(0).map((value, i) => `${i}:00`),
            series: [
                humSerie
            ]
        };

        let co2ChartSerie = {
            labels: Array(24).fill(0).map((value, i) => `${i}:00`),
            series: [
                co2Serie
            ]
        };

        let presenceChartSerie = {
            labels: Array(24).fill(0).map((value, i) => `${i}:00`),
            series: [
                presenceSerie
            ]
        }

        return {
            temp,
            humidity,
            co2: co2IsPresent ? co2ChartSerie : null,
            presence: co2IsPresent ? presenceChartSerie : null
        };
    }

    async roomHasCo2Device() {
        let sensors = this.props.devices.filter(device => device.get(db.Device.DEVICE_TYP) === 'senco' || device.get(db.Device.DEVICE_TYP) === 'sensp');
        let thermosWNBP = this.props.devices.filter(device => {
            let deviceTyp = device.get(db.Device.DEVICE_TYP);
            let firmwareVersion = device.get(db.Device.VERSION_FIRMWARE);

            if(
                deviceTyp === db.Device.DEVICE_TYP$THERM &&
                firmwareVersion?.startsWith('6.')
            ) return true;

            return false;
        });
        return sensors.length > 0 || thermosWNBP.length > 0;
    }

    setupChartist(data, elementClassName, min, max){
        if (data === null) return;

        var options = {
            showArea: true,
            fullWidth: true,
            chartPadding: {
                top: 0,
                left: 10,
                right: 30
            },
            lineSmooth: Chartist.Interpolation.cardinal({
                fillHoles: true,
            }),
            plugins: [
                Chartist.plugins.tooltip()
            ]
        };

        if (min) options.low = min;
        if (max) options.high = max;

        var responsiveOptions = [
            ['screen and (max-width: 567px)', {
                width: 620
            }]
        ];

        // eslint-disable-next-line new-cap
        var chart = Chartist.Line(elementClassName, data, options, responsiveOptions);

        $(window).on('resize', function (){
            if(okToResize){
                okToResize = false;
                chart.update();
                setTimeout(() => okToResize = true, 1000);
            }
        });

        $(window).on( 'hashchange', function() {
            if (location.hash === '#overview') {
                chart.update();
            }
        });

        chart.on('created', function (){
            $('.ct-point').each(function (){
                $(this).clone().addClass('w-white-border').insertBefore(this);
            });

        });

        chart.on('draw', function(context) {
            if (context.type === 'point') {
                if (context.series[context.index].openWindow) {
                    const pointDown = context.y > 100;
                    const openWindowImageUrl = pointDown
                        ? windowAlertPointDown
                        : windowsAlertPointUp;

                    context.element.replace(new Chartist.Svg('image', {
                        height: 97,
                        width: 62,
                        x: context.x - (62 / 2),
                        y: pointDown ? context.y - 95 : context.y - 8,
                        'xlink:href': openWindowImageUrl,
                        cx: [context.x],
                        cy: [context.y],
                        r: [5],
                        'ct:value': context.value.y,
                        'ct:meta': context.meta,
                        style: 'pointer-events: all !important',
                        class: 'ct-point',
                    }));
                }
            }
        });

        let attachMouseEnterPointerToChart = (id) => {
            $('body').on('mouseenter', `${id} .ct-point`, function (){
                var val = $(this).attr('ct:meta'); //Format is done in the this.createSeries function
                $(this).prev('.w-white-border').css({'stroke-width': '18px', 'stroke': '#fff'});
                $(`${id} .chartist-tooltip`).text(val).addClass('tooltip-show');
            }).on('mouseleave', `${id} .ct-point`, function (){
                $(this).prev('.w-white-border').css({'stroke-width': '6px', 'stroke': '#fff'});
                $(`${id} .chartist-tooltip`).removeClass('tooltip-show');
            });
        }
        attachMouseEnterPointerToChart('#tmp');
        attachMouseEnterPointerToChart('#hum');
        attachMouseEnterPointerToChart('#co2');
        attachMouseEnterPointerToChart('#presence');
    }

    render(){
        return (
            <div>
                {this.renderTempChart()}
                {this.renderHumidityChart()}
                {this.state.showCo2 && this.renderCo2Chart()}
                {this.state.showCo2 && this.renderPresenceChart()}
            </div>
        )
    }

    renderTempChart(){
        const {t} = this.props;

        return <div className="card card-outer climate-outer" id="tmp">
            <div className="card-header">
                <div className="card-title climate-control d-flex ">
                    <h2>{t('Climate control')} (°C)</h2>
                    <div className="datepicker-outer">
                        <input type="text" className="datepicker-input" readOnly id="date-picker"/>
                        <div className="calendar_ico">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <g fill="none" fillRule="evenodd">
                                    <path fill="#CCC" fillRule="nonzero"
                                        d="M18.5714286,5.61904762 L16.2857143,5.61904762 L16.2857143,4.66666667 C16.2857143,4.34285714 16.0380952,4.0952381 15.7142857,4.0952381 C15.3904762,4.0952381 15.1428571,4.34285714 15.1428571,4.66666667 L15.1428571,5.61904762 L8.66666667,5.61904762 L8.66666667,4.66666667 C8.66666667,4.34285714 8.41904762,4.0952381 8.0952381,4.0952381 C7.77142857,4.0952381 7.52380952,4.34285714 7.52380952,4.66666667 L7.52380952,5.61904762 L5.04761905,5.61904762 C4.51428571,5.61904762 4.0952381,6.03809524 4.0952381,6.57142857 L4.0952381,18.952381 C4.0952381,19.4857143 4.51428571,19.9047619 5.04761905,19.9047619 L18.5714286,19.9047619 C19.1047619,19.9047619 19.5238095,19.4857143 19.5238095,18.952381 L19.5238095,6.57142857 C19.5238095,6.03809524 19.1047619,5.61904762 18.5714286,5.61904762 Z M7.52380952,6.76190476 L7.52380952,7.71428571 C7.52380952,8.03809524 7.77142857,8.28571429 8.0952381,8.28571429 C8.41904762,8.28571429 8.66666667,8.03809524 8.66666667,7.71428571 L8.66666667,6.76190476 L15.1428571,6.76190476 L15.1428571,7.71428571 C15.1428571,8.03809524 15.3904762,8.28571429 15.7142857,8.28571429 C16.0380952,8.28571429 16.2857143,8.03809524 16.2857143,7.71428571 L16.2857143,6.76190476 L18.3809524,6.76190476 L18.3809524,9.42857143 L5.23809524,9.42857143 L5.23809524,6.76190476 L7.52380952,6.76190476 Z M5.23809524,18.7619048 L5.23809524,10.5714286 L18.3809524,10.5714286 L18.3809524,18.7619048 L5.23809524,18.7619048 Z"/>
                                    <path fill="#CCC" fillRule="nonzero"
                                        d="M14 15.5238095L9.80952381 15.5238095C9.48571429 15.5238095 9.23809524 15.7714286 9.23809524 16.0952381 9.23809524 16.4190476 9.48571429 16.6666667 9.80952381 16.6666667L14 16.6666667C14.3238095 16.6666667 14.5714286 16.4190476 14.5714286 16.0952381 14.5714286 15.7714286 14.3238095 15.5238095 14 15.5238095zM14 12.8571429L9.80952381 12.8571429C9.48571429 12.8571429 9.23809524 13.1047619 9.23809524 13.4285714 9.23809524 13.752381 9.48571429 14 9.80952381 14L14 14C14.3238095 14 14.5714286 13.752381 14.5714286 13.4285714 14.5714286 13.1047619 14.3238095 12.8571429 14 12.8571429z"/>
                                    <rect width={24} height={24}/>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className="chart-lines__outer">
                <div className={'temp-chart-outer'}>
                    <div className="temp-chart-lines">
                        {this.state.loading.temp && <Loader/>}
                        {/* <ChartLines /> */}
                    </div>
                </div>

                <div className="chart-legend">
                    <div className="chart-legend__item">
                        {t('Indoor temp')}
                    </div>
                    {/*<div className="chart-legend__item half-opacity">
                            {t('Outdoor temp')}
                        </div>*/}
                </div>
            </div>
        </div>;
    }

    renderHumidityChart(){
        const {t} = this.props;

        return <div className="card card-outer climate-outer-bottom" id="hum">
            <div className="card-header">
                <div className="card-title climate-control d-flex ">
                    <h2>{t('Climate control')} (%)</h2>
                </div>
            </div>
            <div className="chart-lines__outer">
                <div className={'humidity-chart-outer'}>
                    <div className="humidity-chart-lines">
                        {this.state.loading.temp && <Loader/>}
                        {/* <ChartLines /> */}
                    </div>
                </div>
                <div className="chart-legend">
                    {/*<div className="chart-legend__item half-opacity">
                            {t('Outdoor temp')}
                        </div>*/}
                    <div className="chart-legend__item low-opacity">
                        {t('Humidity')}
                    </div>
                </div>
            </div>
        </div>;
    }

    renderCo2Chart(){
        const {t} = this.props;

        return <div className="card card-outer climate-outer" id="co2">
            <div className="card-header">
                <div className="card-title climate-control d-flex ">
                    <h2>{t('Air quality')} (ppm)</h2>
                </div>
            </div>
            <div className="chart-lines__outer">
                <div className={'co2-chart-outer'}>
                    <div className="co2-chart-lines">
                        {this.state.loading.temp && <Loader/>}
                        {/* <ChartLines /> */}
                    </div>
                </div>
                <div className="chart-legend">
                    {/*<div className="chart-legend__item half-opacity">
                            {t('Outdoor temp')}
                        </div>*/}
                    <div className="chart-legend__item low-opacity">
                        {t('Co2')}
                    </div>
                </div>
            </div>
        </div>;
    }

    renderPresenceChart(){
        const {t} = this.props;

        return <div className="card card-outer climate-outer" id="presence">
            <div className="card-header">
                <div className="card-title climate-control d-flex ">
                    <h2>{t('Measured occupancy chart title')} (%)</h2>
                </div>
            </div>
            <div className="chart-lines__outer">
                <div className={'presence-chart-outer'}>
                    <div className="presence-chart-lines">
                        {this.state.loading.temp && <Loader/>}
                    </div>
                </div>
                <div className="chart-legend">
                    <div className="chart-legend__item low-opacity">
                        {t('Occupancy')}
                    </div>
                </div>
            </div>
        </div>;
    }
}

RoomClimate.propTypes = {
    roomId: PropTypes.string,
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    devices: PropTypes.array
};

/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(RoomClimate));