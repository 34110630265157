'use strict';

import React, {Fragment} from 'react';
import i18next from '../i18n';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import Parse from 'parse';
import * as db from '../../lib/structure';
import * as paths from '../../lib/paths';
import moment from 'moment';
import {get, isEmpty} from 'lodash';
import {redirectIfNotLogged, resetLocalStorage, setLocalStorageUserParameters, toPointerFromId} from '../../lib/util';
import logoImg from '../../assets/images/logo.png';
import structureLocalStorage from '../../lib/localStorageStructure';

class Navbar extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            selectBuildingActive: false,
            homes: [],
            currentHomeName: '',
            sidebarCollapsed: false
        };

        this.logout = this.logout.bind(this);
        this.getHomes = this.getHomes.bind(this);
        this.changeCurrentHome = this.changeCurrentHome.bind(this);
        this.navClick = this.navClick.bind(this);
        this.sendVerificationEmail = this.sendVerificationEmail.bind(this);
    }

    async componentDidMount(){
        try {
            let user = await redirectIfNotLogged(this.props.history);

            user = user.fetch();

            let sidebarCollapsed = localStorage.getItem('sidebarCollapsed');

            sidebarCollapsed ? sidebarCollapsed = sidebarCollapsed.toLowerCase() === 'true' : '';
            if (sidebarCollapsed) {
                $('.sidebar').addClass('active');
                $('.content').addClass('active');
                $('.navbar').addClass('active');
            }
            let homes = await this.getHomes();
            let numberDevicesInMountMode = await this.getMountModeDevices();
            let currentHome = localStorage.getItem('buildingSelection');
            let home = await (new Parse.Query(db.classes.Home)).get(currentHome);
            this.setState({
                user,
                homes,
                currentHome: homes.filter(home => home.id === currentHome)[0],
                numberDevicesInMountMode,
                home
            });

            $('.close-nav').on('click', function (){
                $('.sidebar').removeClass('opened-mobile');
                $('.chat').show();
                $('html').css('position', 'relative');
            });
        } catch (err) { console.error(err); }
    }

    async getHomes(){
        try {
            let query = new Parse.Query('Home');
            query.notEqualTo(db.Home.HIDDEN, true);
            query.notEqualTo(db.Home.DELETED, true);
            query.select([
                db.Home.OBJECT_ID,
                db.Home.HOME_NAME,
                db.Home.OWNER,
                db.Home.TYPE_OF_CUSTOMER,
                db.Home.DATA_COLLECTION_STATUS
            ])
            return query.find();
        } catch (err) { console.error(err); }
    }

    changeLanguage(language){
        i18next.changeLanguage(language);
        moment.locale(language);
        document.cookie = 'i18next=' + language;
    }

    logout(){
        Parse.User.logOut()
            .then(() => {
                localStorage.removeItem('demoAccount');
                this.props.history.push('/login');
            })
            .catch(err => swal('Error', err.message, 'error'));
    }

    navClick(){
        if ($(window).width() > 768) {

            if ($('.sidebar').hasClass('active')) {
                $('.sidebar').removeClass('active');
                $('.content').removeClass('active');
                $('.navbar').removeClass('active');
                localStorage.setItem('sidebarCollapsed', 'false');
            } else {
                $('.sidebar').addClass('active');
                $('.content').addClass('active');
                $('.navbar').addClass('active');
                localStorage.setItem('sidebarCollapsed', 'true');
            }
        } else {
            $('.sidebar').addClass('opened-mobile');
            $('.chat').hide();
            $('html').css('position', 'fixed');
        }
    }

    async changeCurrentHome(home) {
        resetLocalStorage();

        await setLocalStorageUserParameters(home);

        this.setState({currentHome: home});

        location.reload();
    }

    async sendVerificationEmail(e){
        e.preventDefault();
        const {t} = this.props;
        let user = Parse.User.current();
        let email = user.get(db._User.EMAIL);

        try {
            await Parse.Cloud.run('requestPasswordReset', {email: user.get(db._User.EMAIL)});

            swal(t('Success'),  t('Verification email sent! Please check your inbox at ') + email + '.', 'success');
        } catch (e){
            console.error(e);
            swal({title: 'Error', text: e.message, icon: 'error', button: [''], timer: 1000});
        }
    }

    async getMountModeDevices(){
        let selectedBuildingId = localStorage.getItem(structureLocalStorage.BUILDING_SELECTION);
        let query = new Parse.Query(db.classes.Device);
        query.equalTo(db.Device.DEVICE_STATE_FLAG, db.Device.DEVICE_STATE_FLAG$IN_MOUNT);
        query.equalTo(db.Device.DEVICE_TYP, db.Device.DEVICE_TYP$THERM)
        query.notEqualTo(db.Home.HIDDEN, true);
        query.notEqualTo(db.Home.DELETED, true);
        query.include(db.Device.ROOM_ID)
        query.equalTo(db.Device.HOME, toPointerFromId(selectedBuildingId))
        let devices =  await query.find();

        devices = devices.filter(device => device.get(db.Device.ROOM_ID)?.get(db.Room.ROOM_NAME) !== 'to-configure');

        return devices.length;
    }

    render(){
        const {t} = this.props;
        const {homes, selectTypeActive, currentHome, home} = this.state;

        let currentHomeName = '';

        if (!isEmpty(currentHome)) {
            currentHomeName = currentHome.get(db.Home.HOME_NAME);
        }

        let user = Parse.User.current();

        if(!user) return '';

        const typeOfCustomer = user.get(db._User.TYPE_OF_CUSTOMER);
        const registrationMethod = user.get(db._User.REGISTRATION_METHOD);

        return <Fragment>
            <nav
                className="navbar fixed-top navbar-expand-lg navbar-expand-md navbar-expand-sm navbar-expand-xs navbar-light">
                <div className="container-fluid">
                    <div className="collapse navbar-collapse " id="navbarSupportedContent">
                        <button type="button" className="sidebarCollapse show-md" onClick={this.navClick}>
                            <span></span>
                        </button>
                        <div className="logo header__logo mobile-logo">
                            <a className="logo__wrap" href="/">
                                <img className="logo__img img-responsive" src={logoImg} alt="logo"
                                    width="135" title=""/>
                            </a>
                        </div>

                        <ul className="nav navbar-nav ml-auto">

                            <li className="nav-item dropdown dropdown-custom d-flex align-items-center" id="building-dropdown">
                                <div className={`choose-building select-custom ${selectTypeActive ? 'active' : ''}`}
                                    onClick={() => this.setState({
                                        selectTypeActive: !selectTypeActive
                                    })}>
                                    <div className="select-custom-status width-200 hide-overflow">{currentHomeName}</div>
                                    <ul className="select-custom-list">
                                        {
                                            homes.length > 0 && homes.map(home => {
                                                return <li key={home.id} className="select-custom-item" onClick={() => this.changeCurrentHome(home)}>{home.get(db.Home.HOME_NAME)}</li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </li>

                            <li className="nav-item dropdown dropdown-custom language-dropdown
                             d-flex align-items-center">
                                <a className="nav-link dropdown-toggle" href="#" id="languageDropdown"
                                    role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {this.props.i18n.resolvedLanguage}
                                </a>
                                <div className="dropdown-menu" aria-labelledby="languageDropdown">
                                    <a className={`dropdown-item ${this.props.i18n.resolvedLanguage === 'en' ? 'selected' : ''}`} href="#"
                                        onClick={() => this.changeLanguage('en')}>{t('English')}</a>
                                    <a className={`dropdown-item ${this.props.i18n.resolvedLanguage === 'it' ? 'selected' : ''}`} href="#"
                                        onClick={() => this.changeLanguage('it')}>{t('Italian')}</a>
                                    <a className={`dropdown-item ${this.props.i18n.resolvedLanguage === 'fr' ? 'selected' : ''}`} href="#"
                                        onClick={() => this.changeLanguage('fr')}>{t('French')}</a>
                                    <a className={`dropdown-item ${this.props.i18n.resolvedLanguage === 'de' ? 'selected' : ''}`} href="#"
                                        onClick={() => this.changeLanguage('de')}>{t('German')}</a>
                                </div>
                            </li>
                            <li className="nav-item dropdown dropdown-custom profile-dropdown">
                                <a className="nav-link dropdown-toggle d-flex align-items-center" href="#"
                                    id="navbarDropdown"
                                    role="button" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <span className="hide-small">{t('Hello')}, {user.get(db._User.USERNAME)}</span>
                                    <div className="user-logo" style={currentHome && currentHome.get(db.Home.MAIN_PHOTO) ? {
                                        backgroundImage: `url(${currentHome.get(db.Home.MAIN_PHOTO).url()}`
                                    } : {}}>

                                    </div>
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <ul className="list-unstyled">
                                        <li>
                                            <a className="dropdown-item" href={paths.profile}>{t('Profile')}</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href={paths.buildingOperationReport}>{t('Operations')}</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href={paths.buildingSettings}>{t('Building Settings')}</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href={paths.buildings}>{t('Building selection')}</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item logout-ico"
                                                href="#" onClick={() => this.logout()}>{t('Logout')}</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            {
                !get(user, 'attributes.emailVerified') &&
                typeOfCustomer === db._User.TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER &&
                registrationMethod === db._User.REGISTRATION_METHOD$MANUALLY_REGISTERED &&
                <div className={'alert alert-warning'}>
                    {t('Please confirm your email address. Check your inbox for the verification link. Your account may be suspended if the email is not confirmed with 60 days from the registration date. For questions contact us at <a href="mailto: support@cleveron.ch">support@cleveron.ch</a>')}
                    &nbsp;<span>{t('Click')} <a href={'#'} onClick={this.sendVerificationEmail}>{t('here')}</a>, {t('to resend the verification email.')}</span><span>{t('For questions contact us at <a href="mailto: support@cleveron.ch">support@cleveron.ch</a>')}</span>
                </div>
            }
            {
                this.state.numberDevicesInMountMode > 0 &&
                typeOfCustomer === db._User.TYPE_OF_CUSTOMER$FULL_SYSTEM_CUSTOMER &&
                <div className={'alert alert-warning'}>
                    {t('Some devices are in mount-mode and they will not regulate the temperature property. Please remove the mount-mode from devices as soon as you have finished the maintenance.')}
                    &nbsp;<span>{t('For questions contact us at <a href="mailto: support@cleveron.ch">support@cleveron.ch</a>')}</span>
                </div>
            }
            {
                this.state.home &&
                this.state.home.get(db.Home.SUMMER_MODE) === true &&
                this.state.home.get(db.Home.SUMMER_MODE_MOTOR_VALUE) === 0 &&
                <div className={'alert alert-warning'}>
                    {t('The Summer mode is activated for this building.')}
                </div>
            }
            {
                home?.get(db.Home.DATA_COLLECTION_STATUS) === db.Home.DATA_COLLECTION_STATUS$OPEN &&
                <div className={'alert alert-warning'}>
                    {t('This feature is in beta test. Please note that the update of the status can take up to 10 minutes.')}
                </div>
            }
        </Fragment>;
    }
}

Navbar.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired
};

export default Navbar;