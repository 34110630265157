'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import i18next from '../i18n';

class LanguagePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.changeLanguage = this.changeLanguage.bind(this);
        this.languageLabel = this.languageLabel.bind(this);
    }

    changeLanguage(language){
        i18next.changeLanguage(language);
        document.cookie = 'i18next=' + language;
    }

    languageLabel(languageCode){
        const {t} = this.props;
        let label;
        switch (languageCode) {
            case 'en': label = t('English'); break;
            case 'de': label = t('German'); break;
            case 'it': label = t('Italian'); break;
            case 'fr': label = t('French'); break;
            default: label = t('English');
        }
        return label;
    }

    render() {
        const {t} = this.props;

        return  <div className="dropdown pull-right auth-dropdown">
            <button className="dropdown-toggle " type="button" id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                {this.languageLabel(this.props.i18n.resolvedLanguage)}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a className="dropdown-item" href="#"
                    onClick={() => this.changeLanguage('en')}>{t('English')}</a>
                <a className="dropdown-item" href="#"
                    onClick={() => this.changeLanguage('it')}>{t('Italian')}</a>
                <a className="dropdown-item" href="#"
                    onClick={() => this.changeLanguage('fr')}>{t('French')}</a>
                <a className="dropdown-item" href="#"
                    onClick={() => this.changeLanguage('de')}>{t('German')}</a>
            </div>
        </div>
    }
}

LanguagePicker.propTypes = {
    t: PropTypes.func,
    i18n: PropTypes.object
};

export default LanguagePicker;