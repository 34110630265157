import React from 'react';
import PropTypes from 'prop-types';
import autonomousIcon from '../../../assets/images/ic_auto.svg';

export default function AutonomousModeTooltip(props) {
    const {t, show} = props;

    return (
        <div style={{position: 'relative'}}>
            <div style={{display: show ? 'block' : 'none', position: 'absolute', top: '-50px', right: '50px', zIndex: 3000}} className='offline-device'>
                <div>
                    <div className='offline-device-icon'><img src={autonomousIcon} height={50}/></div>
                    <div style={{padding: '10px 20px 0 20px'}}>
                        <h2>{t('Autonomous mode')}</h2>
                        <div className='offline-device-text'>
                            {t('In autonomous mode, the CLEVER Thermo regulates the room temperature autonomously without the control from the CLEVER Cloud.')}
                        </div>
                        <div className='offline-device-text' style={{marginTop: '12px', marginBottom: '12px'}}>
                            {t('This mode is used in case of short-term network interruptions. The device automatically connects to the CLEVER Cloud as soon as the network is available again.')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

AutonomousModeTooltip.propTypes = {
    t: PropTypes.func,
    show: PropTypes.bool
};