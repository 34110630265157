'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import db from '../../../lib/structure';
import moment from 'moment';
import swal from 'sweetalert';

export default class RoomFMFeedback extends React.Component {
    constructor(props){
        super(props);

        this.state = {};
        this.resolveFeedback = this.resolveFeedback.bind(this);
        this.deleteFeedback = this.deleteFeedback.bind(this);
    }

    async resolveFeedback() {
        try {
            this.props.feedback.set(db.RoomFMFeedback.RESOLVED, true);
            await this.props.feedback.save();
            this.props.onChange();
        } catch (err) {
            console.error(err);
            swal('Error!', err, 'error');
        }
    }

    async deleteFeedback() {
        let {feedback, t, onChange} = this.props;

        try {

            swal({
                title: t('Are you sure?'),
                text: t('Are you sure you want to delete this feedback?'),
                icon: 'warning',
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        feedback.set(db.RoomFMFeedback.DELETED, true);
                        feedback.save().then(() => onChange());
                    }
                });

        } catch (err) {
            console.error(err);
            swal('Error!', err, 'error');
        }
    }

    render() {
        const {t, feedback, index} = this.props;
        const imageUrl = feedback.get(db.RoomFMFeedback.FILE) ? feedback.get(db.RoomFMFeedback.FILE)._url : null;
        const hasWritePermission = localStorage.getItem('user:hasWritePermission') === 'true';

        return (
            <>
                {index !== 0 && <hr style={{width: '100%'}} />}
                <div className='room-fm-feedback'>
                    {feedback.get(db.RoomFMFeedback.RESOLVED) && (<div className='room-fm-feedback-status'>
                        <div className='room-fm-feedback-status-resolved'>{t('Resolved')}</div>
                    </div>)}
                    <div className='room-fm-feedback-container'>
                        <div className='room-fm-feedback-text' style={{flex: 1}}>
                            <div className='room-fm-feedback-title'>
                                {t('Feedback from the QR Code portal')}
                            </div>
                            <div className='room-fm-feedback-description'>
                                {feedback.get(db.RoomFMFeedback.COMMENT)}
                            </div>
                            {imageUrl &&
                        <div className='room-fm-feedback-image-mobile' style={{backgroundImage:  `url(${imageUrl})`}}
                            onClick={() => window.open(imageUrl, '_blank')}></div>}
                            <div className='room-fm-feedback-text-details'>
                                <div className='room-fm-feedback-text-details-item'>
                                    {feedback.get(db.RoomFMFeedback.EMAIL)}
                                </div>
                                <span className='room-fm-feedback-text-details-delimiter'>{'|'}</span>
                                <div className='room-fm-feedback-text-details-item'>
                                    {moment(feedback.get(db.RoomFMFeedback.CREATED_AT)).format('DD MMM YYYY, HH:mm')}
                                </div>
                            </div>
                            <div className='room-fm-feedback-buttons'>
                                <button onClick={this.deleteFeedback} disabled={!hasWritePermission} className='room-fm-feedback-buttons-delete'>{t('Delete feedback')}</button>
                                {!feedback.get(db.RoomFMFeedback.RESOLVED) &&
                            <button onClick={this.resolveFeedback} disabled={!hasWritePermission} className='room-fm-feedback-buttons-resolve'>
                                {t('Resolve')}
                            </button>}
                            </div>
                        </div>
                        {imageUrl &&
                    <div className='room-fm-feedback-image' style={{backgroundImage:  `url(${imageUrl})`}}
                        onClick={() => window.open(imageUrl, '_blank')}></div>}
                    </div>
                </div>
            </>
        )
    }
}

RoomFMFeedback.propTypes = {
    t: PropTypes.func,
    feedback: PropTypes.object,
    onChange: PropTypes.func,
    index: PropTypes.number
};
