'use strict';

import {activateConnectionTimeoutError, deactivateConnectionTimeoutError} from './actions';
import swal from 'sweetalert';
import * as db from './structure';
import Parse from 'parse';
import moment from 'moment';
import React from 'react';
import _ from 'lodash';
import config from '../../cnf/app';

import localStorageStructure from './localStorageStructure';
import paths from './paths';
import plugIcon from '../assets/images/plug.svg';
import batteryLowIcon from '../assets/images/ic_battery_low.svg';
import batteryLowGreenIcon from '../assets/images/ic_battery_low_green.svg';
import batteryHalfIcon from '../assets/images/ic_battery_half.svg';
import batteryFullIcon from '../assets/images/ic_battery_full.svg';

import wifiIcon1 from '../assets/images/ic_wifi_1.svg';
import wifiIcon2 from '../assets/images/ic_wifi_2.svg';
import wifiIcon3 from '../assets/images/ic_wifi_3.svg';
import wifiIcon4 from '../assets/images/ic_wifi_4.svg';
import wifiIcon5 from '../assets/images/ic_wifi_5.svg';
import MbOVCKNNAA from '../../client/assets/data/MbOVCKNNAA.json';
import ww9N617FKr from '../../client/assets/data/ww9N617FKr.json';
import xcKUQHhB1q from '../../client/assets/data/xcKUQHhB1q.json';

import OdowlELZ2l from '../../client/assets/data/co2/19-25/OdowlELZ2l.json';
import RWBxLO6MCN from '../../client/assets/data/co2/19-25/RWBxLO6MCN.json';
import XoinQXpIBG from '../../client/assets/data/co2/19-25/XoinQXpIBG.json';

/**
 *
 * @param {Parse.Object} parseObject
 * @return {{__type: string, className: string, objectId: string}}
 */
export const toPointer = (parseObject) => {
    let className = parseObject.className;
    return {
        '__type': 'Pointer',
        'className': className,
        'objectId': parseObject.id
    };
};

/**
 * Get pointer from parse object id.
 *
 * @param parseObjectId
 * @param className
 * @return {{__type: string, className: *, objectId: *}}
 */
export const toPointerFromId = (parseObjectId, className) => {
    return {
        '__type': 'Pointer',
        'className': className,
        'objectId': parseObjectId
    };
};

/**
 *
 * @param err
 * @param propsContext
 * @returns {*}
 */
export const manageError = (err, propsContext) => {
    console.error(err);

    if (err.code === 100) {//Connection timeout
        if (propsContext) {
            setTimeout(() => {
                propsContext.dispatch(deactivateConnectionTimeoutError());
            }, 5000);
            return propsContext.dispatch(activateConnectionTimeoutError());
        }
    }

    if (err.code === Parse.Error.INVALID_SESSION_TOKEN) {//Connection timeout
        Parse.User.logOut();
        window.location = '/login';
    }

    swal(propsContext.t('Error'), propsContext.t(err.message), 'error');
};

export const floorNameFromNumber = (floorNumber) => {

    let floorNameMap = {
        '-7': 'Floor -7',
        '-6': 'Floor -6',
        '-5': 'Floor -5',
        '-4': 'Floor -4',
        '-3': 'Floor -3',
        '-2': 'Second Basement',
        '-1': 'Basement',
        0: 'Ground floor',
        1: 'First floor',
        2: 'Second floor',
        3: 'Third floor',
        4: 'Fourth floor',
        5: 'Fifth floor',
        6: 'Sixth floor',
        7: 'Seventh floor',
        8 : 'Eighth floor',
        9 : 'Ninth floor',
        10 : 'Tenth floor',
        11 : 'Eleventh floor',
        'undefined': 'undefined'
    };

    return floorNameMap[floorNumber];
};

export const calculateMeanTempAndHumidity = (rooms) => {
    if (!rooms) return;

    let meanTemp = 0, meanHumidity = 0, meanCo2 = 0;
    let countTemp = 0, countHum = 0, countCo2 = 0;

    rooms.forEach(room => {
        if (room.get(db.Room.AVG_TEMP_LAST_HOUR) != null) {
            meanTemp += parseFloat(room.get(db.Room.AVG_TEMP_LAST_HOUR));
            countTemp++;
        }
        if (room.get(db.Room.AVG_HUM_LAST_HOUR) != null) {
            meanHumidity += parseFloat(room.get(db.Room.AVG_HUM_LAST_HOUR));
            countHum++;
        }
        if(room.get(db.Room.CO2)) {
            meanCo2 += room.get(db.Room.CO2);
            countCo2++;
        }
    });

    meanTemp = meanTemp / countTemp;
    meanHumidity = meanHumidity / countHum;
    meanCo2 = meanCo2 / countCo2;

    if (!meanTemp || isNaN(meanTemp)) return;

    return {
        meanTemp: meanTemp.toFixed(1),
        meanHumidity: meanHumidity.toFixed(1),
        meanCo2: meanCo2.toFixed(1)
    }
};

/**
 * Split rooms by floor.
 *
 * @param rooms
 * @return {object}
 */
export const splitRoomByFloor = (rooms) => {
    if (!rooms) return {};

    let floors = {};

    rooms.forEach(room => {
        let floor = room.get(db.Room.FLOOR);

        if (!floors[floor]) {
            floors[floor] = [];
        }

        floors[floor].push(room);
    });

    floors = _(floors).toPairs().sortBy(0).fromPairs().value();

    return floors;
};

/**
 * ForEach function for arrays that works for async calls
 * @param array
 * @param callback
 * @returns {Promise<void>}
 */
export async function asyncForEach(array, callback){
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

export function voltToPerc(volt){
    const lessThan10 = 2.9;
    const lessThan15 = 3;
    const lessThan25 = 3.25;
    const lessThan50 = 3.59;
    const lessThan75 = 3.94;
    const lessThan100 = 4.3;

    if (volt == null) return null;
    if (volt === -1) return -1;

    if (volt <= lessThan10) return 10;
    if (volt <= lessThan15) return 15;
    if (volt <= lessThan25) return 25;
    if (volt <= lessThan50) return 50;
    if (volt <= lessThan75) return 75;
    if (volt <= lessThan100) return 100;

    return 100;
}

export function voltColorIcon(value){
    if (value === -1) return {
        color: null,
        icon: plugIcon
    };

    if (value <= 10) return {
        color: '#ef7373',
        icon: batteryLowIcon
    };

    if (value <= 30) return {
        color: '#ffb643',
        icon: batteryLowGreenIcon
    };

    if (value <= 75) return {
        color: '#7ace4c',
        icon: batteryHalfIcon
    };

    return {
        color: '#7ace4c',
        icon: batteryFullIcon
    };
}

export function roundedBatteryPercentage(percentage){
    if (percentage === -1) return null;

    if (percentage <= 10) return 10;

    if (percentage <= 30) return 30;

    if (percentage <= 50) return 50;

    if (percentage <= 75) return 75;

    return 100;
}

export function getIconFromWifiDb(wifiDb, lastMeasurementDate, device){
    if (
        moment(lastMeasurementDate)
            .isBefore(moment()
                .subtract(config.system.timeUntilDeviceOffline.value, config.system.timeUntilDeviceOffline.unit)))
        return 'none';

    if(device != null){
        let versionFirmware = device.get(db.Device.VERSION_FIRMWARE);
        let nbIotSignalQuality = device.get(db.Device.NB_IOT_SIGNAL_QUALITY);

        if(versionFirmware?.startsWith('6.') && nbIotSignalQuality != null){
            let siq = parseInt(nbIotSignalQuality.split(',')[0]);

            if(isNaN(siq)) return 'full';

            if (siq < 2) return  'low';
            if (siq <= 5) return 'mid';
            if (siq <= 10) return  'high';
        }
    } else {
        if (!wifiDb) return 'none';
        if (wifiDb < -90) return  'low';
        if (wifiDb >= -90 && wifiDb < -80) return 'mid';
        if (wifiDb >= -80 && wifiDb < -70) return  'high';
        if (wifiDb >= -70 && wifiDb < -60) return 'full';
    }

    return 'full';
}

export function isDeviceOffline(device){
    let deviceType = device.get(db.Device.DEVICE_TYP);
    let lastMeasurementDate = device.get(db.Device.LAST_MEASUREMENT_DATE);

    if(!lastMeasurementDate) return true;

    lastMeasurementDate = moment(lastMeasurementDate);
    let offlineTimeline;
    if(
        deviceType === db.Device.DEVICE_TYP$SENSP ||
        deviceType === db.Device.DEVICE_TYP$SENCO ||
        deviceType === db.Device.DEVICE_TYP$SENSE
    ) {
        offlineTimeline = moment()
            .subtract(
                config.system.timeUntilOfflineSensor.value,
                config.system.timeUntilOfflineSensor.unit
            );
    } else if(deviceType === db.Device.DEVICE_TYP$THERM){
        offlineTimeline = moment()
            .subtract(
                config.system.timeUntilDeviceOffline.value,
                config.system.timeUntilDeviceOffline.unit
            );
    } else {
        return false;
    }

    return lastMeasurementDate.isBefore(offlineTimeline);
}

export function isDeviceAutonomousMode(device){
    let deviceTyp = device.get(db.Device.DEVICE_TYP);
    let lastMeasurementDate = device.get(db.Device.LAST_MEASUREMENT_DATE);

    if(deviceTyp !== db.Device.DEVICE_TYP$THERM) return false;

    if(!lastMeasurementDate) return true;

    lastMeasurementDate = moment(lastMeasurementDate);
    let offlineTimeline = moment()
        .subtract(
            config.system.timeUntilAutonomousMode.value,
            config.system.timeUntilAutonomousMode.unit
        );

    return lastMeasurementDate.isBefore(offlineTimeline);
}

export function checkAdminPrivileges(){
    let currentUser = Parse.User.current();
    let buildingAdministratorId = localStorage.getItem('buildingAdministratorId');

    return currentUser.id === buildingAdministratorId;

}

export const isHtml = (string) => {
    const htmlTags = require('html-tags');

    const basic = /\s?<!doctype html>|(<html\b[^>]*>|<body\b[^>]*>|<x-[^>]+>)+/i;
    const full = new RegExp(htmlTags.map(tag => `<${tag}\\b[^>]*>`).join('|'), 'i');

    return basic.test(string) || full.test(string);
}

export const htmlT = function (t){

    let newT = (text) => {
        let tText = t(text);

        if (isHtml(tText)){
            return <span dangerouslySetInnerHTML={{__html: tText}}></span>
        }

        return tText;
    }

    return newT;
};

export const getCategoryOfSaving = (value) => {
    if(!value) value = 5;

    let saving = [
        5,
        10,
        15,
        20,
        25,
        30,
        35,
        40,
        45,
        50,
        55,
        60,
        65
    ];

    let label = [
        '5%',
        '5-10%',
        '15-20%',
        '20-25%',
        '25-30%',
        '30-35%',
        '35-40%',
        '40-45%',
        '45-50%',
        '50-55%',
        '55-60%',
        '60-65%',
        '65-70%'
    ];

    for(let i = 0; i< saving.length; i++){
        if(value <= saving[i]){
            return label[i];
        }
    }
}

export function getMeasurementFromDemoRoom(roomId, dStart){
    let data = {
        MbOVCKNNAA,
        ww9N617FKr,
        xcKUQHhB1q
    }

    let co2 = {
        OdowlELZ2l,
        RWBxLO6MCN,
        XoinQXpIBG
    }

    switch (roomId){
        case 'ILqx506p4C': //Sala Riunioni piccola
            roomId = 'xcKUQHhB1q';
            break;
        case '3q2XgFucGI': //Servizi Sociali
            roomId = 'ww9N617FKr';
            break;
        case 'EZaaxz63NA':
            roomId = 'MbOVCKNNAA';
            break;
    }

    if(moment(dStart).isAfter(moment().endOf('day')))
        return [];

    let weeksMap = {};

    if(!data[roomId]) return;

    let keys = Object.keys(data[roomId].result);
    let tempMeasurements = data[roomId].result;

    let co2Measurements = co2.RWBxLO6MCN.result['Device$CenGvH7Mj9(sensp)'];
    let measurements = keys.map(key => tempMeasurements[key]).flat().concat(co2Measurements);

    measurements.forEach(measure => {
        let week = moment(measure.timestamp.iso).day();

        if(weeksMap[week] == null) weeksMap[week] = [];

        weeksMap[week].push(measure);
    });

    let currentDayOfWeek = moment(dStart).day();

    let parseObjectMeasurements =  weeksMap[currentDayOfWeek]
        /**
         * @param {{targetMotorState: number. hum: number, temp: number, device: string}} measure
         */
        .map(measure => {
            let deviceTyp = null;
            let measurement = new Parse.Object(db.classes.Measurement);

            if(measure.device.includes('(therm)')){
                deviceTyp = db.Device.DEVICE_TYP$THERM;
                measurement.set(db.Measurement.HUM, measure.hum);
                measurement.set(db.Measurement.TEMP, measure.temp);
                measurement.set(db.Measurement.CO2, measure.co2);
            } else if (measure.device.includes('(sensp)')){
                deviceTyp = db.Device.DEVICE_TYP$SENSP;
                measurement.set(db.Measurement.HUM, null);
                measurement.set(db.Measurement.TEMP, null);
                measurement.set(db.Measurement.CO2, measure.co2);
            }

            measurement.set(db.Measurement.CREATED_AT, moment(measure.timestamp.iso).toDate());
            measurement.set(db.Measurement.MOTOR_TARGET_STATE, measure.targetMotorState);
            measurement.set('timestamp', moment(measure.timestamp.iso).toDate());
            measurement.set(db.Measurement.DEVICE_TYP, deviceTyp);

            return measurement;
        });

    let lastMeasurements = keys.map(key => (tempMeasurements[key].shift()));
    lastMeasurements.push(co2Measurements.shift()) ;

    return {
        measurements: parseObjectMeasurements,
        lastMeasurements
    }
}

let roomDevices = {
    'ILqx506p4C': [
        {
            serial: '289847',
            macAddress: 'D7:61:65:F7:05:C6',
            type: db.Device.DEVICE_TYP$THERM
        },
        {
            serial: '947829',
            macAddress: '97:CD:14:82:12:01',
            type: db.Device.DEVICE_TYP$THERM
        },
        {
            serial: '969060',
            macAddress: '4F:5C:34:B7:CF:90',
            type: db.Device.DEVICE_TYP$SENSP
        }
    ],
    '3q2XgFucGI': [
        {
            serial: '494899',
            macAddress: 'A6:74:A7:C1:0F:12',
            type: db.Device.DEVICE_TYP$THERM
        },
        {
            serial: '686969',
            macAddress: 'EA:3F:91:9C:B4:63',
            type: db.Device.DEVICE_TYP$THERM
        },
        {
            serial: '334858',
            macAddress: '5C:86:A9:18:CB:1B',
            type: db.Device.DEVICE_TYP$SENSP
        }
    ],
    'EZaaxz63NA': [
        {
            serial: '485900',
            macAddress: '40:71:EE:4F:C6:81',
            type: db.Device.DEVICE_TYP$THERM
        },
        {
            serial: '123789',
            macAddress: '62:8F:DF:10:4B:1E',
            type: db.Device.DEVICE_TYP$SENSP
        }
    ]
};

let roomNames = {
    'ILqx506p4C': 'Sala Riunioni piccola',
    '3q2XgFucGI': 'Servizi Sociali',
    'EZaaxz63NA': 'Sala Matrimoni'
}

export function getDemoDevices(roomId, lastMeasurements){
    let devicesInfo = roomDevices[roomId];

    let devices = lastMeasurements.map((measure, i) => {
        let device = new Parse.Object(db.classes.Device);
        let deviceTyp = null;

        if(measure.device.includes('(therm)')){
            deviceTyp = db.Device.DEVICE_TYP$THERM;
            device.set(db.Device.LAST_MEASUREMENT, (new Parse.Object(db.classes.Measurement))
                .set(db.Measurement.MOTOR_TARGET_STATE, measure.targetMotorState));
            device.set(db.Device.BATTERY_VOLTAGE, 4.2);
        } else if (measure.device.includes('(sensp)')){
            deviceTyp = db.Device.DEVICE_TYP$SENSP;
            device.set(db.Device.LAST_MEASUREMENT, (new Parse.Object(db.classes.Measurement))
                .set(db.Measurement.CO2, measure.co2));
            device.set(db.Device.BATTERY_VOLTAGE, -1);
        }

        device.set(db.Device.MAC_ADDRESS, devicesInfo[i].macAddress);
        device.set(db.Device.SERIAL_NUMBER, devicesInfo[i].serial);
        device.set(db.Device.DEVICE_TYP, deviceTyp);
        device.set(db.Device.WIFI_STRENGTH, -62);

        return device;
    });

    return devices
}

export function getAllDemoDevices(){
    let rooms = Object.keys(roomDevices);

    return rooms.map(roomId => {
        return roomDevices[roomId].map(({type, macAddress, serial}) => {

            let device = new Parse.Object(db.classes.Device);

            type === db.Device.DEVICE_TYP$THERM ?
                device.set(db.Device.BATTERY_VOLTAGE, 4.2) :
                device.set(db.Device.BATTERY_VOLTAGE, -1);

            device.set(db.Device.MAC_ADDRESS, macAddress);
            device.set(db.Device.SERIAL_NUMBER, serial);
            device.set(db.Device.WIFI_STRENGTH, -62);
            device.set(db.Device.DEVICE_TYP, type);

            let lastMeasurement = (new Parse.Object(db.classes.Measurement));

            device.set(db.Device.LAST_MEASUREMENT, lastMeasurement);

            type === db.Device.DEVICE_TYP$THERM ?
                device.set(db.Device.LAST_MEASUREMENT_DATE, moment().subtract(20, 'minutes').toDate()) :
                device.set(db.Device.LAST_MEASUREMENT_DATE, moment().subtract(5, 'minutes').toDate());

            let room = new Parse.Object(db.classes.Room);
            room.id = roomId;
            room.set(db.Room.ROOM_NAME, roomNames[roomId]);

            device.set(db.Device.ROOM_ID, room);

            return device;
        })
    }).flat();
}

export function hasWritePermission(object) {
    let user = Parse.User.current();
    let objectACL = object.getACL();

    if(!objectACL) return false;

    return objectACL.getWriteAccess(user);
}

export function hasReadPermission(object){
    let user = Parse.User.current();
    let objectACL = object.getACL();

    if(!objectACL) return false;

    return objectACL.getReadAccess(user);
}

export function userIsInRole(role){
    let roles =  localStorage.getItem('user:roles');

    if(!roles) return false;

    roles = roles.split(',');

    return roles.indexOf(role) >= 0;
}

export function round(x, n) {
    var a = Math.pow(10, n);
    return (Math.round(x * a) / a);
}

function getDay(day){
    return (day + 3) % 7;
}

export function countNullValues(roomData){
    let count = 0;
    [0,1,2,3,4,5,6].forEach(day => {
        let dayReport  =  roomData[getDay(day)];

        if(dayReport == null){
            count += 24;
            return;
        }

        [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23].forEach(hour => {

            if(dayReport[hour] == null){
                count++;
            }

        });
    });

    return count;
}

export function makeId(length) {
    var result           = [];
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result.push(characters.charAt(Math.floor(Math.random() *
            charactersLength)));
    }
    return result.join('');
}

/**
 *
 * @param history
 * @param path
 * @returns {Parse.Object || null}
 */
export async function redirectIfNotLogged(history, path){
    let user = Parse.User.current();

    if(!user){
        resetCache()
        window.location = paths.login;
    }

    const buildingSelection = localStorage.getItem('buildingSelection');
    const buildingAdministratorId = localStorage.getItem('buildingAdministratorId');
    const typeOfCustomer = localStorage.getItem(`home:${db.Home.TYPE_OF_CUSTOMER}`);
    const hasReadPermission = localStorage.getItem('user:hasReadPermission') === 'true';
    const hasWritePermission = localStorage.getItem('user:hasWritePermission') === 'true';
    const isAdmin = localStorage.getItem('user:isAdmin') === 'true';

    if(
        buildingSelection == null ||
        buildingAdministratorId == null ||
        typeOfCustomer == null ||
        hasReadPermission == null ||
        hasWritePermission == null ||
        isAdmin == null
    ){
        if(history.location.pathname !== paths.buildings)
            window.location = paths.buildings;
    }

    if(!user.get(db._User.TYPE_OF_CUSTOMER))
        await user.fetch({fields: [db._User.TYPE_OF_CUSTOMER, db._User.EMAIL_VERIFIED]});

    return user;
}

export function resetCache(e){
    e && e.preventDefault();

    try{
        localStorage.clear();
        location.reload();
    } catch (e) {
        console.error(e);
        location.reload();
    }
}

export function addCloseButtonToDatepicker(datepickerId, translate){
    if ($('.datepicker-close').length === 0) {
        if($('.datepicker-dropdown tfoot')){
            $('.datepicker-dropdown tfoot')
                .append(`<tr><th colspan="7" onclick="$('#${datepickerId}').datepicker('hide');" class="datepicker-close">${translate('Close')}</th></tr>`);
        }
    }
}

export function resetLocalStorage(){
    localStorage.removeItem('buildingSelection');
    localStorage.removeItem('buildingAdministratorId');
    localStorage.removeItem(`home:${db.Home.TYPE_OF_CUSTOMER}`);
    localStorage.removeItem('user:hasReadPermission');
    localStorage.removeItem('user:hasWritePermission');
    localStorage.removeItem('user:isAdmin');
    localStorage.removeItem('user:roles');
    localStorage.removeItem('demoAccount');
}

export async function setUserLocalStorage(){
    const user = Parse.User.current();
    const adminRoleQuery = new Parse.Query(Parse.Role);
    adminRoleQuery.equalTo(db._Role.USERS, user);
    let roles = await adminRoleQuery.find();
    let rolesNames = roles.map(role => role.get(db._Role.NAME));

    localStorage.setItem('user:roles', rolesNames);

    user.id === 'AxA3LXX6xd' && localStorage.setItem('demoAccount', 'true');
}

export async function setLocalStorageUserParameters(home){
    const user = Parse.User.current();
    const adminRoleQuery = new Parse.Query(Parse.Role);
    adminRoleQuery.equalTo(db._Role.USERS, user);
    let roles = await adminRoleQuery.find();
    let rolesNames = roles.map(role => role.get(db._Role.NAME));

    localStorage.setItem('buildingSelection', home?.id);
    localStorage.setItem('buildingAdministratorId', home?.get(db.Home.OWNER)?.id);
    localStorage.setItem(`home:${db.Home.TYPE_OF_CUSTOMER}`, home?.get(db.Home.TYPE_OF_CUSTOMER));
    localStorage.setItem(`home:${db.Home.CITY}`, home.get(db.Home.CITY));
    localStorage.setItem(`home:${db.Home.COUNTRY}`, home.get(db.Home.COUNTRY));
    localStorage.setItem(`home:${db.Home.HOME_NAME}`, home.get(db.Home.HOME_NAME));

    localStorage.setItem('user:roles', rolesNames);
    localStorage.setItem('user:hasReadPermission',
        hasReadPermission(home) ||
        rolesNames.indexOf('Admin') >= 0 ||
        rolesNames.indexOf('Support') >= 0
    );
    localStorage.setItem('user:hasWritePermission',
        hasWritePermission(home) ||
        rolesNames.indexOf('Admin') >= 0 ||
        rolesNames.indexOf('Support') >= 0
    );
    localStorage.setItem('user:isAdmin', checkAdminPrivileges());

    user.id === 'AxA3LXX6xd' && localStorage.setItem('demoAccount', 'true');

    window.pagesense = window.pagesense || [];
    window.pagesense.push(['buildingCity', home.get(db.Home.CITY)]);
    window.pagesense.push(['buildingCountry', home.get(db.Home.COUNTRY)]);
}

export function sendDataToSalesIq(){
    try {
        var user = localStorage.getItem('Parse/NjSUT8HxvCz706ldcwUn/currentUser');

        if(user == null) return;

        user = JSON.parse(user);

        let homeName = localStorage.getItem('home:homeName');

        var data = {
            username: user.username,
            email: user.email,
            lastName: user.lastName,
            name: user.name,
            typeOfCustomer: user.typeOfCustomer,
            buildingName: homeName
        };

        console.log('salesIqData', data);

        $zoho.salesiq.visitor.info(data);
    } catch (e) {
        console.error(e);
    }
}

export function wifiIcon(num){
    let icons = [
        null,
        wifiIcon1,
        wifiIcon2,
        wifiIcon3,
        wifiIcon4,
        wifiIcon5,
    ]

    return icons[num];
}

/**
 *
 * @param acl
 * @returns {{writeUsers: string[], readUsers: string[]}}
 */
export function getReadWriteUsers(acl) {
    let homeACLObject = acl.toJSON();
    let keys = Object.keys(homeACLObject);

    let readUsers = keys.filter(key => !key.startsWith('role:')).filter(key => homeACLObject[key].read);
    let writeUsers = keys.filter(key => !key.startsWith('role:')).filter(key => homeACLObject[key].write);

    return {
        readUsers,
        writeUsers
    }
}

/**
 *
 * @param readUsers
 * @param writeUsers
 * @returns {Parse.ACL}
 */
export function createBasicACL(readUsers, writeUsers){
    let acl = new Parse.ACL();
    for(let readUser of readUsers){
        acl.setReadAccess(readUser, true);
    }

    for(let writeUser of writeUsers){
        acl.setWriteAccess(writeUser, true);
    }

    acl.setRoleReadAccess(db.roles.ADMIN, true);
    acl.setRoleWriteAccess(db.roles.ADMIN, true);
    acl.setRoleReadAccess(db.roles.LEAN_MANAGEMENT, true);
    acl.setRoleWriteAccess(db.roles.LEAN_MANAGEMENT, true);
    acl.setPublicReadAccess(false);
    acl.setPublicWriteAccess(false);

    return acl;
}

/**
 *
 * @param object1
 * @param object2
 * @returns {Parse.Object} object2
 */
export function copyACLToObject(object1, object2){
    if(!object1) throw Error('object1 is not defined');
    if(!object2) throw Error('Room is not defined');
    let object1ACL = object1.getACL();
    if(!object1ACL) throw Error('object1ACL ACL is not defined');

    let {readUsers, writeUsers} = getReadWriteUsers(object1ACL);
    let object2ACL = createBasicACL(readUsers, writeUsers);

    object2.setACL(object2ACL);
}

export function updateTimestamp(){
    let currentUser = localStorage.getItem('Parse/NjSUT8HxvCz706ldcwUn/currentUser') ?
        JSON.parse(localStorage.getItem('Parse/NjSUT8HxvCz706ldcwUn/currentUser')): null;

    let buildingSelectedId = localStorage.getItem('buildingSelection');
    let lastActivityTimestamp = localStorage.getItem(localStorageStructure.LAST_ACTIVITY_TIMESTAMP);

    lastActivityTimestamp = lastActivityTimestamp ?
        moment(lastActivityTimestamp) : null; //if null means that never visited this page before

    let lastActivityDay = lastActivityTimestamp ? Math.abs(lastActivityTimestamp.diff(moment(), 'hours')) : null;

    if(lastActivityDay > 12 || lastActivityDay == null){
        localStorage.setItem(localStorageStructure.LAST_ACTIVITY_TIMESTAMP, moment());
        Parse.Cloud.run(db.functions.WEBAPP_UPDATE_LAST_LOGIN, {
            userId: currentUser?.objectId,
            buildingSelectedId,
            lastActivityTimestamp: lastActivityTimestamp ? lastActivityTimestamp.toDate() : moment()
        });
    }
}

/**
 *
 * @return {{month: (*|number), year: (*|number)}}
 */
export function getLastMonthNumberWithYear(){
    let month = moment().month();
    let year = moment().year();

    if(month === 0){
        month = 12;
        year = year -1;
    } else
        month;

    let week = moment().isoWeek();

    return {
        lastMonth: month,
        year,
        week
    }
}

/**
 *
 * @param room
 * @param averageInternalTemp
 * @param averageExternalTemp
 * @param numberMonthlyMeasurement
 * @returns {number}
 */
export function calculateRoomSavingsPercentage(
    room, averageInternalTemp, averageExternalTemp, numberMonthlyMeasurement) {

    let numberOfRadiators = room.get(db.Room.NUMBER_RADIATORS);
    let roomHasRadiators = numberOfRadiators > 0;
    let roomMax = room.get(db.Room.TEMP_MAX);

    let referenceInternalTemp = Math.max(23, roomMax);

    //((21° x 7h) + (23° x 17h))/ 24h
    let avgDanfossTemp = ((21 * 7) + (referenceInternalTemp * 17))/ 24;

    if(!averageInternalTemp)
        averageInternalTemp = 20;

    let avgRoomSavingPercentage = ((avgDanfossTemp - averageExternalTemp) -
        (averageInternalTemp - averageExternalTemp)) /
        (avgDanfossTemp - averageExternalTemp) * 100;

    if(!avgRoomSavingPercentage || numberMonthlyMeasurement < 10){
        avgRoomSavingPercentage = 5;
    }

    if(avgRoomSavingPercentage < 5){
        avgRoomSavingPercentage = 5;
    }

    if(!roomHasRadiators) avgRoomSavingPercentage = 0;

    return avgRoomSavingPercentage;
}

export function truncate(input, maxLength = 40) {
    return input.length > maxLength ? `${input.substring(0, maxLength)}...` : input;
}

export function getStartEndDatesFromWeek(week, year){
    let start = moment().year(year).isoWeek(week).startOf('isoWeek');
    let end = start.clone().endOf('isoWeek');

    return {
        start,
        end
    }
}

export function getStartEndDateFromMonth(month, year){
    month = month -1;
    let start = moment().year(year).month(month).startOf('month');
    let end = start.clone().endOf('month');

    return {
        start,
        end
    }
}

/**
 *
 * @param {Parse.Object} home
 * @return {{owner: *, area: *, zip: *, country: *, address: *, city: *, year: *, typeOfCustomer: *, homeName: *, numSensp: *, language: *, numTherm: *, heatingSystem: *, canton: *}}
 */
export function extractHomeFields(home){
    if(!home) return;

    const owner =     home.get(db.Home.OWNER);
    const homeName =  home.get(db.Home.HOME_NAME);
    const area =      home.get(db.Home.AREA);
    const city =      home.get(db.Home.CITY);
    const address =   home.get(db.Home.ADDRESS);
    const year =      home.get(db.Home.YEAR);
    const language =  home.get(db.Home.LANGUAGE);

    const canton =        home.get(db.Home.CANTON);
    const zip =           home.get(db.Home.ZIP)
    const country =       home.get(db.Home.COUNTRY);
    const heatingSystem = home.get(db.Home.HEATING_SYSTEM_TYPE);

    const numTherm = home.get(db.Home.NUMBER_RADIATORS);
    const numSensp = home.get(db.Home.NUMBER_SENSP);
    const typeOfCustomer = home.get(db.Home.TYPE_OF_CUSTOMER);

    return {
        owner,
        homeName,
        area,
        city,
        address,
        year,
        language,
        canton,
        zip,
        country,
        heatingSystem,
        numTherm,
        numSensp,
        typeOfCustomer
    }
}

/**
 *
 * @param {Parse.Object} room
 * @return {{installationCompleted: *, data: *, installationComment: *, oldTempMax: *, energySavingPercentage: *, mainPhotoUrl: *, tempMax: *, createdAt: *, presenceForecastEnabled: *, lastUpdateAvgTemp: *, roomType: *, updatedAt: *, height: *, area: *, deviceConfig: *, avgTempLastHour: *, roomProgram: *, thermSleepTimeMinutes: *, motorOnValue: *, presenceDataConfirmationDate: *, roomName: *, installationCompletedDate: *, numberSense: *, minValueWhenMaxTempPreset: *, numberSenco: *, monitoringActivated: *, stateFlag: *, numberSensp: *, tempMin: *, pidActive: *, uniqueId: *, hidden: *, co2: *, presenceDataConfirmationUser: *, mainPhoto: *, weeklyPresenceForecast: *, heatingSlopeActive: *, avgHumLastHour: *, roomSize: *, sunRadiated: *, correctionTemp: *, floor: *, numberRadiators: *, temperaturePredictionFormula: *, ACL: *, lastMonthlyPresenceForecastUpdate: *, windows: *, home: *, oldTempMin: *, hide: *, deleted: *, emergencyTemperature: *}}
 */
export function extractRoomFields(room){
    let createdAt = room.get(db.Room.CREATED_AT);
    let updatedAt = room.get(db.Room.UPDATED_AT);
    let ACL = room.get(db.Room.ACL);
    let roomName = room.get(db.Room.ROOM_NAME);
    let tempMin = room.get(db.Room.TEMP_MIN);
    let tempMax = room.get(db.Room.TEMP_MAX);
    let area = room.get(db.Room.AREA);
    let height = room.get(db.Room.HEIGHT);
    let windows = room.get(db.Room.WINDOWS);
    let home = room.get(db.Room.HOME);
    let data = room.get(db.Room.DATA);
    let stateFlag = room.get(db.Room.STATE_FLAG);
    let correctionTemp = room.get(db.Room.CORRECTION_TEMP);
    let floor = room.get(db.Room.FLOOR);
    let mainPhoto = room.get(db.Room.MAIN_PHOTO);
    let numberRadiators = room.get(db.Room.NUMBER_RADIATORS);
    let numberSense = room.get(db.Room.NUMBER_SENSE);
    let numberSensp = room.get(db.Room.NUMBER_SENSP);
    let numberSenco = room.get(db.Room.NUMBER_SENCO);
    let motorOnValue = room.get(db.Room.MOTOR_ON_VALUE);
    let pidActive = room.get(db.Room.PID_ACTIVE);
    let minValueWhenMaxTempPreset = room.get(db.Room.MIN_VALUE_WHEN_MAX_TEMP_PRESET);
    let oldTempMax = room.get(db.Room.OLD_TEMP_MAX);
    let oldTempMin = room.get(db.Room.OLD_TEMP_MIN);
    let presenceForecastEnabled = room.get(db.Room.PRESENCE_FORECAST_ENABLED);
    let deviceConfig = room.get(db.Room.DEVICE_CONFIG);
    let hide = room.get(db.Room.HIDE);
    let emergencyTemperature = room.get(db.Room.EMERGENCY_TEMPERATURE);
    let monitoringActivated = room.get(db.Room.MONITORING_ACTIVATED);
    let sunRadiated = room.get(db.Room.SUN_RADIATED);
    let temperaturePredictionFormula = room.get(db.Room.TEMPERATURE_PREDICTION_FORMULA);
    let heatingSlopeActive = room.get(db.Room.HEATING_SLOPE_ACTIVE);
    let energySavingPercentage = room.get(db.Room.ENERGY_SAVING_PERCENTAGE);
    let roomProgram = room.get(db.Room.ROOM_PROGRAM);
    let presenceDataConfirmationDate = room.get(db.Room.PRESENCE_DATA_CONFIRMATION_DATE);
    let presenceDataConfirmationUser = room.get(db.Room.PRESENCE_DATA_CONFIRMATION_USER);
    let uniqueId = room.get(db.Room.UNIQUE_ID);
    let roomSize = room.get(db.Room.ROOM_SIZE);
    let roomType = room.get(db.Room.ROOM_TYPE);
    let mainPhotoUrl = room.get(db.Room.MAIN_PHOTO_URL);
    let thermSleepTimeMinutes = room.get(db.Room.THERMO_SLEEP_TIME_MINUTES);
    let hidden = room.get(db.Room.HIDDEN);
    let deleted = room.get(db.Room.DELETED);
    let avgTempLastHour = room.get(db.Room.AVG_TEMP_LAST_HOUR);
    let avgHumLastHour = room.get(db.Room.AVG_HUM_LAST_HOUR);
    let co2 = room.get(db.Room.CO2);
    let lastUpdateAvgTemp = room.get(db.Room.LAST_UPDATE_AVG_TEMP);
    let installationCompleted = room.get(db.Room.INSTALLATION_COMPLETED);
    let installationComment = room.get(db.Room.INSTALLATION_COMMENT);
    let installationCompletedDate = room.get(db.Room.INSTALLATION_COMPLETED_DATE);
    let weeklyPresenceForecast = room.get(db.Room.WEEKLY_PRESENCE_FORECAST);
    let lastMonthlyPresenceForecastUpdate = room.get(db.Room.LAST_MONTHLY_PRESENCE_FORECAST_UPDATE);
    let technicalComments = room.get(db.Room.TECHNICAL_COMMENTS);
    let thermoSleepMinutes = room.get(db.Room.THERMO_SLEEP_TIME_MINUTES);

    return {
        createdAt,
        updatedAt,
        ACL,
        roomName,
        tempMin,
        tempMax,
        area,
        height,
        windows,
        home,
        data,
        stateFlag,
        correctionTemp,
        floor,
        mainPhoto,
        numberRadiators,
        numberSense,
        numberSensp,
        numberSenco,
        motorOnValue,
        pidActive,
        minValueWhenMaxTempPreset,
        oldTempMax,
        oldTempMin,
        presenceForecastEnabled,
        deviceConfig,
        hide,
        emergencyTemperature,
        monitoringActivated,
        sunRadiated,
        temperaturePredictionFormula,
        heatingSlopeActive,
        energySavingPercentage,
        roomProgram,
        presenceDataConfirmationDate,
        presenceDataConfirmationUser,
        uniqueId,
        roomSize,
        roomType,
        mainPhotoUrl,
        thermSleepTimeMinutes,
        hidden,
        deleted,
        avgTempLastHour,
        avgHumLastHour,
        co2,
        lastUpdateAvgTemp,
        installationCompleted,
        installationComment,
        installationCompletedDate,
        weeklyPresenceForecast,
        lastMonthlyPresenceForecastUpdate,
        technicalComments,
        thermoSleepMinutes
    }
}

/**
 *
 * @param {Parse.Object} device
 * @return {{motorMouvementSaveRatio: *, lastResponseMotorCommandValue: *, defectScore: *, lastModeChange: *, deviceName: *, configWifiPassword: *, roomId: *, mode: *, createdAt: *, motorPosition: *, forceLedColor: *, counterMotorTags: *, lastDeviceStateFlagChange: *, lastMeasurement: *, defectPrediction: *, humidity: *, headerRequestHost: *, presence: *, deviceStateFlag: *, defectScoreNormalized: *, connectionWifiSsid: *, updatedAt: *, deviceConfig: *, defectActualStatus: *, serialNumber: *, wifiStrength: *, versionFirmware: *, staticIp: *, firmwareTestingStatus: *, roomTempCorrection: *, tempTargetTempRmse: *, configWifiSsid: *, motorAnalysisKpi: *, macAddress: *, connectionWifiPassword: *, totalMeasurements: *, maxConsecutiveMotorResponseValue: *, lastMeasurementDate: *, tempMin: *, lastResponseMotorCommandValueDate: *, tempDefault: *, motorMouvements: *, numberMotorPositionResponseValue: *, numberRoomFeedbacks: *, numberMotorPositionValue: *, co2: *, motorSpeed: *, motorEnergySavingActive: *, deviceTyp: *, temperature: *, localIp: *, lastResponseSleepCommandValue: *, firmwareTestingActive: *, measuredTemp: *, correctionTemp: *, commercialLabel: *, objectId: *, motorTags: *, owner: *, dischargeSlope: *, batteryVoltage: *, headerXRequestPort: *, ACL: *, installationInfo: *, numberCommandValue: *, home: *, mountedEngine: *, deleted: *, motorCurrentLimits: *, ledColorConfig: *, numberConsecutiveMotorResponseValue: *, numberOfMeasurements: *}}
 */
export function extractDeviceFields(device){
    let objectId = device.get(db.Device.OBJECT_ID);
    let createdAt = device.get(db.Device.CREATED_AT);
    let updatedAt = device.get(db.Device.UPDATED_AT);
    let ACL = device.get(db.Device.ACL);
    let deviceName = device.get(db.Device.DEVICE_NAME);
    let owner = device.get(db.Device.OWNER);
    let roomId = device.get(db.Device.ROOM_ID);
    let tempMin = device.get(db.Device.TEMP_MIN);
    let tempDefault = device.get(db.Device.TEMP_DEFAULT);
    let macAddress = device.get(db.Device.MAC_ADDRESS);
    let deviceConfig = device.get(db.Device.DEVICE_CONFIG);
    let staticIp = device.get(db.Device.STATIC_IP);
    let deviceStateFlag = device.get(db.Device.DEVICE_STATE_FLAG);
    let motorMouvements = device.get(db.Device.MOTOR_MOUVEMENTS);
    let totalMeasurements = device.get(db.Device.TOTAL_MEASUREMENTS);
    let motorMouvementSaveRatio = device.get(db.Device.MOTOR_MOUVEMENT_SAVE_RATIO);
    let deviceTyp = device.get(db.Device.DEVICE_TYP);
    let serialNumber = device.get(db.Device.SERIAL_NUMBER);
    let installationInfo = device.get(db.Device.INSTALLATION_INFO);
    let motorEnergySavingActive = device.get(db.Device.MOTOR_ENERGY_SAVING_ACTIVE);
    let lastMeasurementDate = device.get(db.Device.LAST_MEASUREMENT_DATE);
    let lastMeasurement = device.get(db.Device.LAST_MEASUREMENT);
    let correctionTemp = device.get(db.Device.CORRECTION_TEMP);
    let wifiStrength = device.get(db.Device.WIFI_STRENGTH);
    let batteryVoltage = device.get(db.Device.BATTERY_VOLTAGE);
    let dischargeSlope = device.get(db.Device.DISCHARGE_SLOPE);
    let mode = device.get(db.Device.MODE);
    let home = device.get(db.Device.HOME);
    let lastModeChange = device.get(db.Device.LAST_MODE_CHANGE);
    let lastDeviceStateFlagChange = device.get(db.Device.LAST_DEVICE_STATE_FLAG_CHANGE);
    let co2 = device.get(db.Device.CO2);
    let measuredTemp = device.get(db.Device.MEASURED_TEMP);
    let humidity = device.get(db.Device.HUMIDITY);
    let presence = device.get(db.Device.PRESENCE);
    let motorPosition = device.get(db.Device.MOTOR_POSITION);
    let localIp = device.get(db.Device.LOCAL_IP);
    let versionFirmware = device.get(db.Device.VERSION_FIRMWARE);
    let configWifiSsid = device.get(db.Device.CONFIG_WIFI_SSID);
    let configWifiPassword = device.get(db.Device.CONFIG_WIFI_PASSWORD);
    let connectionWifiSsid = device.get(db.Device.CONNECTION_WIFI_SSID);
    let connectionWifiPassword = device.get(db.Device.CONNECTION_WIFI_PASSWORD);
    let lastResponseMotorCommandValue = device.get(db.Device.LAST_RESPONSE_MOTOR_COMMAND_VALUE);
    let lastResponseMotorCommandValueDate = device.get(db.Device.LAST_RESPONSE_MOTOR_COMMAND_VALUE_DATE);
    let lastResponseSleepCommandValue = device.get(db.Device.LAST_RESPONSE_SLEEP_COMMAND_VALUE);
    let commercialLabel = device.get(db.Device.COMMERCIAL_LABEL);
    let headerXRequestPort = device.get(db.Device.HEADER_X_REQUEST_PORT);
    let headerRequestHost = device.get(db.Device.HEADER_REQUEST_HOST);
    let motorCurrentLimits = device.get(db.Device.MOTOR_CURRENT_LIMITS);
    let motorSpeed = device.get(db.Device.MOTOR_SPEED);
    let firmwareTestingActive = device.get(db.Device.FIRMWARE_TESTING_ACTIVE);
    let firmwareTestingStatus = device.get(db.Device.FIRMWARE_TESTING_STATUS);
    let forceLedColor = device.get(db.Device.FORCE_LED_COLOR);
    let ledColorConfig = device.get(db.Device.LED_COLOR_CONFIG);
    let mountedEngine = device.get(db.Device.MOUNTED_ENGINE);
    let temperature = device.get(db.Device.TEMPERATURE);
    let roomTempCorrection = device.get(db.Device.ROOM_TEMP_CORRECTION);
    let motorTags = device.get(db.Device.MOTOR_TAG);
    let counterMotorTags = device.get(db.Device.COUNTER_MOTOR_TAGS);
    let numberMotorPositionValue = device.get(db.Device.NUMBER_MOTOR_POSITION_VALUE);
    let numberMotorPositionResponseValue = device.get(db.Device.NUMBER_MOTOR_RESPONSE_VALUE);
    let numberCommandValue = device.get(db.Device.NUMBER_COMMAND_VALUE);
    let motorAnalysisKpi = device.get(db.Device.MOTOR_ANALYSIS_KPI);
    let defectActualStatus = device.get(db.Device.DEFECT_ACTUAL_STATUS);
    let defectPrediction = device.get(db.Device.DEFECT_PREDICTION);
    let defectScore = device.get(db.Device.DEFECT_SCORE);
    let defectScoreNormalized = device.get(db.Device.DEFECT_SCORE_NORMALIZED);
    let deleted = device.get(db.Device.DELETED);
    let numberRoomFeedbacks = device.get(db.Device.NUMBER_ROOM_FEEDBACKS);
    let tempTargetTempRmse = device.get(db.Device.TEMP_TARGET_TEMP_RMSE);
    let numberOfMeasurements = device.get(db.Device.NUMBER_OF_MEASUREMENTS);
    let numberConsecutiveMotorResponseValue = device.get(db.Device.NUMBER_CONSECUTIVE_MOTOR_RESPONSE_VALUE);
    let maxConsecutiveMotorResponseValue = device.get(db.Device.MAX_CONSECUTIVE_MOTOR_RESPONSE_VALUE);

    return {
        objectId,
        createdAt,
        updatedAt,
        ACL,
        deviceName,
        owner,
        roomId,
        tempMin,
        tempDefault,
        macAddress,
        deviceConfig,
        staticIp,
        deviceStateFlag,
        motorMouvements,
        totalMeasurements,
        motorMouvementSaveRatio,
        deviceTyp,
        serialNumber,
        installationInfo,
        motorEnergySavingActive,
        lastMeasurementDate,
        lastMeasurement,
        correctionTemp,
        wifiStrength,
        batteryVoltage,
        dischargeSlope,
        mode,
        home,
        lastModeChange,
        lastDeviceStateFlagChange,
        co2,
        measuredTemp,
        humidity,
        presence,
        motorPosition,
        localIp,
        versionFirmware,
        configWifiSsid,
        configWifiPassword,
        connectionWifiSsid,
        connectionWifiPassword,
        lastResponseMotorCommandValue,
        lastResponseMotorCommandValueDate,
        lastResponseSleepCommandValue,
        commercialLabel,
        headerXRequestPort,
        headerRequestHost,
        motorCurrentLimits,
        motorSpeed,
        firmwareTestingActive,
        firmwareTestingStatus,
        forceLedColor,
        ledColorConfig,
        mountedEngine,
        temperature,
        roomTempCorrection,
        numberMotorPositionValue,
        numberMotorPositionResponseValue,
        numberCommandValue,
        motorTags,
        counterMotorTags,
        motorAnalysisKpi,
        defectActualStatus,
        defectPrediction,
        defectScore,
        defectScoreNormalized,
        numberRoomFeedbacks,
        tempTargetTempRmse,
        numberOfMeasurements,
        numberConsecutiveMotorResponseValue,
        maxConsecutiveMotorResponseValue,
        deleted
    }
}

/**
 *
 * @param {Parse.Object} diagnosticRequest
 * @return {{eventsStatus, endDate, resultData, airQualityStatus, ACL, devicesDiagnosticResults, temperatureStatus, diagnosticProperties, createdAt, diagnosticConclusions, batteryStatus, context, connectionStatus, startDate, updatedAt, measurements, programStatus}}
 */
export function extractDiagnosticRequestFields(diagnosticRequest){
    let createdAt = diagnosticRequest.get(db.DiagnosticRequest.CREATED_AT)
    let updatedAt = diagnosticRequest.get(db.DiagnosticRequest.UPDATED_AT)
    let ACL = diagnosticRequest.get(db.DiagnosticRequest.ACL)
    let startDate = diagnosticRequest.get(db.DiagnosticRequest.START_DATE)
    let endDate = diagnosticRequest.get(db.DiagnosticRequest.END_DATE)
    let context = diagnosticRequest.get(db.DiagnosticRequest.CONTEXT)
    let batteryStatus = diagnosticRequest.get(db.DiagnosticRequest.BATTERY_STATUS)
    let connectionStatus = diagnosticRequest.get(db.DiagnosticRequest.CONNECTION_STATUS)
    let measurements = diagnosticRequest.get(db.DiagnosticRequest.MEASUREMENTS)
    let programStatus = diagnosticRequest.get(db.DiagnosticRequest.PROGRAM_STATUS)
    let eventsStatus = diagnosticRequest.get(db.DiagnosticRequest.EVENTS_STATUS)
    let airQualityStatus = diagnosticRequest.get(db.DiagnosticRequest.AIR_QUALITY_STATUS)
    let temperatureStatus = diagnosticRequest.get(db.DiagnosticRequest.TEMPERATURE_STATUS)
    let devicesDiagnosticResults = diagnosticRequest.get(db.DiagnosticRequest.DEVICES_DIAGNOSTIC_RESULTS)
    let diagnosticProperties = diagnosticRequest.get(db.DiagnosticRequest.DIAGNOSTIC_PROPERTIES)
    let diagnosticConclusions = diagnosticRequest.get(db.DiagnosticRequest.DIAGNOSTIC_CONCLUSIONS)
    let resultData = diagnosticRequest.get(db.DiagnosticRequest.RESULT_DATA);

    return {
        createdAt,
        updatedAt,
        ACL,
        startDate,
        endDate,
        context,
        batteryStatus,
        connectionStatus,
        measurements,
        programStatus,
        eventsStatus,
        airQualityStatus,
        temperatureStatus,
        devicesDiagnosticResults,
        diagnosticProperties,
        diagnosticConclusions,
        resultData
    }
}

export function getVoltageIcon(device){
    let deviceTyp = device.get(db.Device.DEVICE_TYP);
    let volt = device.get(db.Device.BATTERY_VOLTAGE);
    let deviceStateFlag = device.get(db.Device.DEVICE_STATE_FLAG);

    let voltageIcon = null;
    let deviceTypeIcon = null;

    let percentage = voltToPerc(volt);
    if (deviceTyp === 'sense') percentage = -1;

    switch (deviceTyp){
        case db.Device.DEVICE_TYP$SENSE:
        case db.Device.DEVICE_TYP$SENSP:
        case db.Device.DEVICE_TYP$SENCO:
            voltageIcon = plugIcon;
            deviceTypeIcon = 'sens';
            break;
        case db.Device.DEVICE_TYP$THERM:
            voltageIcon = voltColorIcon(percentage).icon;
            deviceTypeIcon = 'therm';
    }

    return {
        voltageIcon,
        deviceTypeIcon
    }
}

export function convertColorsToIconTag(color){
    if(color === 'green') return 'success';
    if(color === 'yellow') return 'warning';
    if(color === 'red') return 'danger';
    if(color === 'blu') return 'info';

    return 'success';
}


export function isValidCo2Value(co2){
    return co2 != null && co2 >= 100;
}
