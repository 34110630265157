'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import moment from 'moment';
import Loader from '../loader';
import i18next from '../i18n';

import clearDayIcon from '../../assets/images/weather/wi-day-sunny.svg';
import clearNightIcon from '../../assets/images/weather/wi-night-clear.svg';
import rainIcon from '../../assets/images/weather/wi-rain.svg';
import snowIcon from '../../assets/images/weather/wi-snow.svg';
import sleetIcon from '../../assets/images/weather/wi-sleet.svg';
import windIcon from '../../assets/images/weather/wi-day-cloudy-gusts.svg';
import fogIcon from '../../assets/images/weather/wi-day-fog.svg';
import cloudyIcon from '../../assets/images/weather/wi-cloudy.svg';
import partlyCloudyDayIcon from '../../assets/images/weather/wi-day-cloudy.svg';
import partlyCloudyNightIcon from '../../assets/images/weather/wi-night-partly-cloudy.svg';
import hailIcon from '../../assets/images/weather/wi-hail.svg';
import thunderstormIcon from '../../assets/images/weather/wi-day-thunderstorm.svg';
import tornadoIcon from '../../assets/images/weather/wi-tornado.svg';
import db from '../../lib/structure';

function capitalizeFirstLetter(string){
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function formatDate(dateTime){
    moment.locale(i18next.language);

    let dayName = capitalizeFirstLetter(dateTime.format('ddd'));
    let dayOfMonth = dateTime.format('D');
    let monthName = capitalizeFirstLetter(dateTime.format('MMM'));

    return `${dayName}, ${dayOfMonth} ${monthName}`;
}

function formatTemperature(temp){
    return Math.round(temp);
}

class CardWeather extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            collapsed: false,
            selectedTab: 'today'
        };

        this.getWeatherNow = this.getWeatherNow.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    async componentDidMount(){
        //if(NODE_ENV === 'production' || NODE_ENV === 'test')
        await this.getWeatherNow();
    }

    async getWeatherNow(){
        let getWeather = async () => {
            let currentHome = localStorage.getItem('buildingSelection');
            let query = new Parse.Query(db.classes.Home);
            let language = 'de';

            if (this.props.i18n) {
                language = this.props.i18n.resolvedLanguage;
            }

            try {
                let home1 = await query.get(currentHome);
                let city = home1.get(db.Home.CITY);

                this.setState({city, home: home1});

                if(!city) throw Error('No city is set');

                let searchCityText = city;

                let country = home1.get(db.Home.COUNTRY);

                if(country){
                    if(country === db.Home.COUNTRY$SWITZERLAND){
                        searchCityText += ',CH';
                    } else if (country === db.Home.COUNTRY$GERMANY){
                        searchCityText += ',DE';
                    } else if (country === db.Home.COUNTRY$ITALY){
                        searchCityText += ',IT';
                    }
                }

                let weather = await Parse.Cloud.run('getWeatherNowNew', {city: searchCityText, language});

                if(!weather?.list) throw new Error('No data available.');

                let list = weather.list;
                let weatherCity = weather.city;

                let weekForecast = [];
                let todayForecast = [];
                let currentTemp;
                let currentIconName;
                let currentIconCode;
                let currentWeatherDescription;
                let currentCity = `${weatherCity.name}, ${weatherCity.country}`;

                let tempMinOfDefinedDay=100000;
                let tempMaxOfDefinedDay=-100000;
                let definedDay;
                let definedDate;

                if(list.length > 0){
                    let firstElement = list[0];
                    let date = moment.unix(firstElement.dt);
                    let temp = firstElement.main.temp;
                    let weatherIcon = firstElement.weather?.[0]?.main;
                    let iconCode = firstElement.weather?.[0]?.icon;
                    let description = firstElement.weather?.[0]?.description;

                    if(moment().isSame(date, 'day')){
                        currentTemp = temp;
                        currentIconName = weatherIcon;
                        currentIconCode = iconCode;
                        currentWeatherDescription = description;
                    }
                }

                list.forEach(hourForecast => {
                    let date = moment.unix(hourForecast.dt);
                    let temp = hourForecast.main.temp;
                    let humidity = hourForecast.main.humidity;
                    let tempMin = hourForecast.main.temp_min;
                    let tempMax = hourForecast.main.temp_max;
                    let weatherIcon = hourForecast.weather?.[0]?.main;
                    let currentDayOfMonth = date.date();
                    let prevDate = date.clone().subtract(3, 'hour');
                    let iconCode = hourForecast.weather?.[0]?.icon;
                    let description = hourForecast.weather?.[0]?.description;

                    let hourForecastObject = {
                        prevDate,
                        date,
                        temp,
                        humidity,
                        tempMin,
                        tempMax,
                        weatherIcon,
                        iconCode,
                        description
                    };

                    if(!definedDay){
                        definedDay = currentDayOfMonth;
                        definedDate = date;
                    }
                    if(definedDay !== currentDayOfMonth && !moment().isSame(date, 'day')){
                        weekForecast.push({
                            date: definedDate,
                            tempMin: tempMinOfDefinedDay,
                            tempMax: tempMaxOfDefinedDay
                        });

                        definedDay = currentDayOfMonth;
                        definedDate = date;
                        tempMinOfDefinedDay= 100000;
                        tempMaxOfDefinedDay= -100000;
                    }

                    tempMinOfDefinedDay = Math.min(tempMinOfDefinedDay, tempMin);
                    tempMaxOfDefinedDay = Math.max(tempMaxOfDefinedDay, tempMax);

                    if(moment().isSame(date, 'day')){
                        todayForecast.push(hourForecastObject);
                    }
                });

                this.setState({
                    todayForecast,
                    weekForecast,
                    currentTemp,
                    currentIconName,
                    currentIconCode,
                    currentWeatherDescription,
                    currentCity,
                    loading: false
                });
            } catch (err) {
                console.error(err);
                this.setState({loading: false, error: true});
            }
        }
        if(this.state.loading) return;

        this.setState({loading: true}, () => {
            getWeather();
        });
    }

    toggleCollapse(){
        this.setState({collapsed: !this.state.collapsed});
    }

    render(){
        const {t} = this.props;
        const {loading, home, city, currentTemp} = this.state;

        if(loading) return <Loader/>;

        let measurementDate;

        let title = city;

        if(home?.get(db.Home.COUNTRY)){
            title += `, ${t(home?.get(db.Home.COUNTRY))}`
        }

        return <div className={'weather-card'}>
            <div className="card card-outer side-card">
                <div className="card-header w-border">
                    <div className="card-title">
                        <h2>{this.state.currentCity}</h2>
                        {/*<span>12 Jul 2018, 11:04 am</span>*/}
                        {
                            measurementDate && measurementDate.isValid() && <span>
                                {measurementDate.format('DD')}&nbsp;
                                {t(measurementDate.format('MMMM'))}&nbsp;
                                {measurementDate.format('YYYY')},&nbsp;
                                {measurementDate.format('HH')}:{measurementDate.format('mm')}
                            </span>
                        }
                    </div>
                </div>
                <div>
                    {
                        this.state.error && <div>{t('No weather data found')}</div>
                    }
                    {
                        !this.state.error && <div>
                            <div className="weather">
                                <div className="weather-deg">{formatTemperature(currentTemp)}°</div>
                                <div className="weather-status">
                                    <div className="partly-cloudy">
                                        {
                                            this.state.currentIconCode && <img
                                                src={`https://openweathermap.org/img/wn/${this.state.currentIconCode}@2x.png`}
                                                alt=""
                                                style={{height: 80, width: 'auto'}}
                                            />
                                        }
                                    </div>
                                    <h3>{this.state.currentWeatherDescription}</h3>
                                </div>
                            </div>
                            <div className={`collapse-tabs ${this.state.collapsed ? 'active' : ''}`}>

                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="home-tab" data-toggle="tab"
                                            href="#" role="tab" aria-controls="home" aria-selected="true"
                                            onClick={() => this.setState({selectedTab: 'today'})}
                                        >
                                            {t('Today')}
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link show" id="profile-tab" data-toggle="tab"
                                            href="#" role="tab" aria-controls="profile" aria-selected="true"
                                            onClick={() => this.setState({selectedTab: 'week'})}
                                        >
                                            {t('Week')}
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className={`tab-pane fade ${this.state.selectedTab === 'today' ? 'show active' : ''}`} id="home" role="tabpanel"
                                         aria-labelledby="home-tab">
                                        {
                                            this.state.todayForecast && this.state.todayForecast
                                                .map((day, i) => {
                                                    return <div key={i} className="weather-history">
                                                        <div className="weather-history-name">
                                                            {day.prevDate.format('LT')} - {day.date.format('LT')}
                                                        </div>
                                                        <div className="weather-history-deg">
                                                            {formatTemperature(day.tempMin)}°/
                                                            {formatTemperature(day.tempMax)}°
                                                            {
                                                                day.iconCode && <img
                                                                    src={`https://openweathermap.org/img/wn/${day.iconCode}@2x.png`}
                                                                    alt=""
                                                                    style={{height: 40, width: 'auto'}}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                })
                                        }
                                    </div>
                                    <div className={`tab-pane fade ${this.state.selectedTab === 'week' ? 'show active' : ''}`} id="profile" role="tabpanel"
                                         aria-labelledby="profile-tab">
                                        {
                                            this.state.weekForecast && this.state.weekForecast
                                                .map((day, i) => {
                                                    return <div key={i} className="weather-history">
                                                        <div className="weather-history-name">
                                                            {formatDate(day.date)}
                                                        </div>
                                                        <div className="weather-history-deg">
                                                            {formatTemperature(day.tempMin)}°/
                                                            {formatTemperature(day.tempMax)}°
                                                        </div>
                                                    </div>
                                                })
                                        }
                                    </div>
                                </div>
                            </div>
                            <button className={`view-more-tabs responsive-show ${this.state.collapsed ? 'active' : ''}`}
                                onClick={this.toggleCollapse}
                            >
                                <span>{this.state.collapsed ? t('Hide') : t('View more')}</span>
                            </button>
                        </div>
                    }

                </div>
            </div>
        </div>
    }
}

CardWeather.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired
};

export default CardWeather;