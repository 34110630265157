import React, {Component} from 'react';
import {merge} from 'lodash';
import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import db from '../../lib/structure';
import Footer from '../footer';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import translate from '../translate';
import Loader from '../loader';
import Parse from 'parse';
import {
    getAllDemoDevices,
    getIconFromWifiDb,
    isDeviceOffline,
    redirectIfNotLogged,
    toPointerFromId,
    voltColorIcon,
    voltToPerc,
    wifiIcon
} from '../../lib/util'
import moment from 'moment';
import localStorageStructure from '../../lib/localStorageStructure';

class AdminPanelPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            devices: [],
            showAllDevices: false,
            loading: {
                devices: false,
                home: false
            }
        };

        this.checkBoxToggled = this.checkBoxToggled.bind(this);
        this.displayTableContent = this.displayTableContent.bind(this);
        this.displayTable = this.displayTable.bind(this);
    }

    async componentDidMount(){
        await redirectIfNotLogged(this.props.history);
        let home = await this.loadHome();
        await this.loadDevices(home);

    }

    async loadHome(){
        this.setState({loading: {home: true}});

        let home = await (new Parse.Query(db.classes.Home))
            .get(localStorage.getItem(localStorageStructure.BUILDING_SELECTION));

        this.setState({home, loading: {home: false}});

        return home;
    }

    async loadDevices(home){
        let buildingSelection = localStorage.getItem('buildingSelection');

        let hideOfflineDevices = home.get(db.Home.WEB_UI_HIDE_OFFLINE_DEVICES);

        await this.setState({loading: {devices: true}});

        let query = new Parse.Query(db.classes.Device);
        query.descending(db.Device.ROOM_ID);
        query.exists(db.Device.ROOM_ID);
        query.include(db.Device.ROOM_ID);
        query.equalTo(db.Device.HOME, toPointerFromId(buildingSelection, db.classes.Home));
        query.limit(1000);
        query.exclude([
            db.Device.CONNECTION_WIFI_PASSWORD,
            db.Device.CONFIG_WIFI_PASSWORD
        ]);

        if(hideOfflineDevices === true)
            query.greaterThanOrEqualTo(db.Device.LAST_MEASUREMENT_DATE, moment().subtract(1, 'day').toDate());

        let devices = await query.find();

        let filteredDevices = devices.filter(device => {
            let room = device.get(db.Device.ROOM_ID);

            if(!room) return false;

            let hidden = room.get(db.Room.HIDDEN);
            let deleted = room.get(db.Room.DELETED);
            let toConfigureRoom = room.get(db.Room.ROOM_NAME) === 'to-configure';
            let reserveRoom = room.get(db.Room.ROOM_TYPE) === db.Room.ROOM_TYPE$RESERVE;

            return hidden !== true && deleted !== true && toConfigureRoom !== true && reserveRoom !== true;
        })

        await this.setState({devices: filteredDevices, loading: {devices: false}});
    }

    async checkBoxToggled() {

        this.state.showAllDevices ? this.setState({showAllDevices:false}) : this.setState({showAllDevices:true});
    }

    displayTable() {
        const {t} = this.props;
        let devicesToDisplay = this.state.devices;

        if (this.state.showAllDevices) {

            devicesToDisplay = devicesToDisplay.filter(device => {
                return isDeviceOffline(device);
            });
        }

        if (!devicesToDisplay.length > 0 && !this.state.loading.devices) {
            return <div className={'no-document-text'}>
                {t('No result to display')}</div>
        }

        const typeOfCustomer = localStorage.getItem(`home:${db.Home.TYPE_OF_CUSTOMER}`);
        
        return <table className="table">
            <thead>
                <tr>
                    <th>
                        {t('Device')}
                    </th>
                    <th className="show-desktop">
                        {t('Wifi')}
                    </th>

                    {
                        typeOfCustomer !== db.Home.TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER && <th
                            className="show-desktop">
                            {t('Battery')}
                        </th>
                    }
                    <th className="show-desktop">
                        {t('Go to Room')}
                    </th>
                    <th className="show-tablet"/>
                </tr>
            </thead>
            <tbody>
                {this.displayTableContent(devicesToDisplay)}
            </tbody>
        </table>

    }

    displayTableContent(devicesToDisplay) {
        const {t} = this.props;

        const typeOfCustomer = localStorage.getItem(`home:${db.Home.TYPE_OF_CUSTOMER}`);

        return devicesToDisplay.map(device => {

            let lastMeasurementDate = device.get(db.Device.LAST_MEASUREMENT_DATE) ? moment(device.get(db.Device.LAST_MEASUREMENT_DATE)).format('DD MMM YYYY, HH:mm') : '';
            let lastMeasurement = device.get(db.Device.LAST_MEASUREMENT);
            let wifiLevel = getIconFromWifiDb(device.get(db.Device.WIFI_STRENGTH),
                device.get(db.Device.LAST_MEASUREMENT_DATE), device);
            let batteryVoltage = device.get(db.Device.BATTERY_VOLTAGE);
            let room = device.get(db.Device.ROOM_ID);
            let configuredWifiName = device.get(db.Device.CONFIG_WIFI_SSID);
            let connectedWifiName = device.get(db.Device.CONNECTION_WIFI_SSID);
            let isOffline = isDeviceOffline(device);

            let svgNum = 5; // for no connection
            if (wifiLevel === 'full') svgNum = 1;
            if (wifiLevel === 'high') svgNum = 2;
            if (wifiLevel === 'mid') svgNum = 3;
            if (wifiLevel === 'low') svgNum = 4;
            if(isOffline) svgNum = 5;

            let batteryIconUrl, deviceType;

            if (device.get(db.Device.DEVICE_TYP) === 'sense' || device.get(db.Device.DEVICE_TYP) === 'sensp')  deviceType = t('CLEVER Sense');
            if (device.get(db.Device.DEVICE_TYP) === 'therm') deviceType = t('CLEVER Thermo');

            if(!lastMeasurement){
                batteryIconUrl = voltColorIcon(0).icon;
            } else {
                batteryIconUrl = voltColorIcon(voltToPerc(batteryVoltage)).icon;
            }

            let roomName = room ? room.get(db.Room.ROOM_NAME) : 'Not defined';

            return <tr key={device.id}>
                <td>
                    {`${deviceType}: ${device.get(db.Device.SERIAL_NUMBER)}`}
                </td>
                <td className="show-desktop">
                    <div className="device-item__icon" style={
                        {
                            backgroundImage: `url(${wifiIcon(svgNum)})`,
                            backgroundSize: '100% 100%',
                            marginTop: connectedWifiName === configuredWifiName ? 'unset': 10
                        }
                    }/>
                    {
                        connectedWifiName === configuredWifiName && <span>{configuredWifiName}</span>
                    }
                    {
                        connectedWifiName !== configuredWifiName && <>
                            <small>{t('WiFi configuration on the device')}: <b>{device.get(db.Device.CONFIG_WIFI_SSID)}</b></small><br/>
                            <small>{t('Last connected WiFi')}: <b>{device.get(db.Device.CONNECTION_WIFI_SSID)}</b></small>
                        </>
                    }
                </td>

                {
                    typeOfCustomer !== db.Home.TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER && <td className="show-desktop">
                        {!batteryIconUrl && <span className="pull-left" style={{margin: 10}}>-</span>}
                        <div className="device-item__icon" style={{backgroundImage:  `url(${batteryIconUrl})`}}/>
                    </td>
                }
                <td className="show-tablet">

                    <div className="actions d-flex align-items-center">
                        <div className="device-item__icon" style={
                            {backgroundImage: `url(${wifiIcon(svgNum)})`, backgroundSize: '100% 100%'}
                        }/>
                        {!batteryIconUrl && <span className="pull-left" style={{margin: '10px 20px 10px 11px'}}>-</span>}
                        {
                            batteryIconUrl && <div className="device-item__icon" style={{backgroundImage:  `url(${batteryIconUrl})`}}/>
                        }

                        {lastMeasurementDate}
                        {!lastMeasurementDate && <small>{t('No connection')}</small>}

                        {
                            room != null && <a
                                href="#"
                                className="ml-auto p-2 align-items-center link"
                                onClick={() => this.props.history.push(`/room/${room.id}`)}>
                                {roomName}
                            </a>
                        }
                    </div>
                </td>
                <td className="show-desktop">
                    <a href="#" className="d-flex link" onClick={() => this.props.history.push(`/room/${device.get(db.Device.ROOM_ID).id}`)}>
                        {roomName}
                    </a>
                </td>
            </tr>
        }

        )
    }

    render() {
        const {t} = this.props;

        return (

            <div className="page-admin-panel">
                <div className="wrapper d-flex align-items-stretch">
                    <Sidebar {...this.props}/>
                    <div className="content">
                        <Navbar {...this.props}/>
                        <div className="inner page-admin-container">
                            <Breadcrumbs {...this.props} elements={[
                                {
                                    link: '/dashboard',
                                    name: t('Home')
                                },
                                {
                                    link: null,
                                    name: t('Admin Panel')
                                }
                            ]}/>
                            <h1>{t('Admin Panel')}</h1>
                            {
                                (this.state.loading.devices || this.state.loading.home) && <Loader/>
                            }
                            {
                                (!this.state.loading.devices && !this.state.loading.home) && <>
                                    <div className="checkbox-custom">
                                        <input type="checkbox" id="cb" checked={this.state.showAllDevices} onChange={() => this.checkBoxToggled()}/>
                                        <label htmlFor="cb">{t('Only show disconnected devices')}</label>
                                    </div>

                                    <div
                                        className="grid-cols flex-xs-column-reverse flex-sm-column-reverse flex-md-column-reverse
                                        flex-lg-row">
                                        <div className="card card-outer full-w">
                                            {this.displayTable()}
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <Footer/>
                    </div>
                </div>
            </div>
        );
    }
}

AdminPanelPage.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired
};

/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(AdminPanelPage));