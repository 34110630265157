import React from 'react';
import PropType from 'prop-types';
import * as db from '../../../lib/structure';
import i18next from '../../i18n';
import Loader from '../../loader';
import {countNullValues, round} from '../../../lib/util';

const TOO_MUCH_EMPTY_VALUES_COUNT = 100;

export default class RoomPresenceChart extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            numberNullValues: 0,
        };

        this.prepareMonthlyAveragePresenceReport = this.prepareMonthlyAveragePresenceReport.bind(this);
        this.getAveragePresenceMatrix = this.getAveragePresenceMatrix.bind(this);
        this.renderMatrix = this.renderMatrix.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }

    componentDidMount() {
        i18next.on('languageChanged', this.handleLanguageChange);
    }

    componentWillUnmount() {
        i18next.off('languageChanged', this.handleLanguageChange);
    }

    async componentDidUpdate(prevProps){
        if (this.props.homeReport === prevProps.homeReport) return;

        await this.prepareMonthlyAveragePresenceReport();
    }

    handleLanguageChange(){
        this.prepareMonthlyAveragePresenceReport();
    }

    async prepareMonthlyAveragePresenceReport() {
        const homeReport = this.props.homeReport;
        if (!homeReport) {
            return;
        }

        const hourlyPresenceAverage = homeReport.get(db.RoomMonthlyReport.HOURLY_PRESENCE_AVERAGE);
        const averagePresenceMatrix = this.getAveragePresenceMatrix(hourlyPresenceAverage);

        let numberNullValues = countNullValues(averagePresenceMatrix);

        this.setState({numberNullValues}, () => {
            setTimeout(() => this.renderMatrix(averagePresenceMatrix), 0);
        });
    }

    renderMatrix(averagePresenceMatrix){
        if (averagePresenceMatrix) {
            document.querySelectorAll('.avgPresence .avgTemp__cells').forEach(el => {
                const rows = Array.from(el.children);
                rows.forEach((v, i) => {
                    const dark = Array.from(v.children);
                    dark.forEach((cell, num) => {
                        let color = '#7ac25766';
                        let value = averagePresenceMatrix[i][num];

                        let threshold = 0;

                        if(num > 17) {
                            threshold = 0.05;
                        }

                        if(value > threshold) {
                            color = '#7ac257';
                        }

                        cell.children[0].style.background = color;
                        $(cell).append('<span class=\'tempr temprAvg\' style="display: block; color:black"></span>');
                    });
                });
            });
        }

    }

    getAveragePresenceMatrix(hourlyPresenceAverage){
        var preset = [
            /*eslint-disable */
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Sunday
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Monday
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Tuesday
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Wednesday
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Thursday
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Friday
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Saturday
            /*eslint-enable */
        ];

        if(!hourlyPresenceAverage) return preset;

        return hourlyPresenceAverage;
    }

    render(){
        const {t, loading, homeReport} = this.props;

        let reportDataAvailable = true;
        if (!homeReport || this.state.numberNullValues >= TOO_MUCH_EMPTY_VALUES_COUNT) {
            reportDataAvailable = false;
        }

        let monthsLabel = {
            1: t('January'),
            2: t('February'),
            3: t('March'),
            4: t('April'),
            5: t('May'),
            6: t('June'),
            7: t('July'),
            8: t('August'),
            9: t('September'),
            10: t('October'),
            11: t('November'),
            12: t('December')
        };

        let title = '';
        const isoWeek = this.props.homeReport?.get(db.HomeReport.ISO_WEEK);

        if(isoWeek != null)
            title = t('Measured occupancy ');
        else
            title = t('Measured occupancy ');

        return <div className="full-w">
            <div className="card card-outer full-w program-card">
                <div className="card-header">
                    <div className="card-title d-flex">
                        <h2>
                            {t('Measured occupancy ')}
                        </h2><br/>
                    </div>
                </div>

                {loading && <Loader />}
                {
                    !loading && reportDataAvailable && <div className="avgPresence automatic">
                        <div className="avgTemp__outer automatic">
                            <div className="avgTemp__days">
                                <span>{t('Sun')}</span>
                                <span>{t('Mon')}</span>
                                <span>{t('Tue')}</span>
                                <span>{t('Wed')}</span>
                                <span>{t('Thu')}</span>
                                <span>{t('Fri')}</span>
                                <span>{t('Sat')}</span>
                            </div>
                            <div className="avgTemp__cells">
                                <div className="avgTemp__tr">
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                </div>
                                <div className="avgTemp__tr">
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                </div>
                                <div className="avgTemp__tr">
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                </div>
                                <div className="avgTemp__tr">
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                </div>
                                <div className="avgTemp__tr">
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                </div>
                                <div className="avgTemp__tr">
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                </div>
                                <div className="avgTemp__tr">
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                </div>
                            </div>
                        </div>
                        <div className="avgTemp__hours">
                            <span className="non-hour"></span>
                            {/* eslint-disable react/jsx-no-literals */}
                            <span className="static">0:00</span>
                            <span className="static">3:00</span>
                            <span className="static">6:00</span>
                            <span className="static">9:00</span>
                            <span className="static">12:00</span>
                            <span className="static">15:00</span>
                            <span className="static">18:00</span>
                            <span className="static">21:00</span>
                            <span className="static last">00:00</span>
                            {/* eslint-enable react/jsx-no-literals */}
                        </div>
                    </div>
                }

                {
                    !loading && !reportDataAvailable && <div className="text-center" id="co2-no-report-data">
                        {t('No report data available')}
                    </div>
                }
            </div>
        </div>

    }
}

RoomPresenceChart.propTypes = {
    t: PropType.func,
    room: PropType.object,
    i18n: PropType.object,
    loading: PropType.bool,
    homeReport: PropType.object
};