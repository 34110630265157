import React from 'react';
import PropType from 'prop-types';

export default class ProgramSwitch extends React.Component {

    constructor(props){
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(selectedOption) {
        this.props.onChange(selectedOption);
    }

    render() {
        const {options, value} = this.props;
        if (value === null) {
            return '';
        }

        return (
            <div className='program-switch-container'>
                {options.map(option => {
                    return <div key={option.value}
                        className={`program-switch-option ${value.value === option.value ? 'program-switch-selected' : ''}`}
                        onClick={() => this.handleChange(option)}>
                        {option.label}
                    </div>
                })}
            </div>
        );
    }
}

ProgramSwitch.propTypes = {
    options: PropType.array,
    value: PropType.object,
    onChange: PropType.func,
};