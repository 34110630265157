import React, {Component, Fragment} from 'react';
import * as db from '../../lib/structure';
import PropTypes from 'prop-types';
import Parse from 'parse';
import Loader from '../loader';
import swal from 'sweetalert';

class CardSummerMode extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading: false,
            summerMode: false,
            home: {},
            modalOpen: false
        };

        this.buttonPressed = this.buttonPressed.bind(this);
    }

    async componentDidMount(){
        try {
            let currentHome = localStorage.getItem('buildingSelection');
            let home = await (new Parse.Query(db.classes.Home)).get(currentHome);
            let summerMode = home.get(db.Home.SUMMER_MODE);
            this.setState({summerMode, home});
            this.buttonPressed = this.buttonPressed.bind(this);
        } catch (err) {
            console.error(err);
        }
    }

    async buttonPressed() {
        const {t} = this.props;
        let {home, summerMode} = this.state;

        home.set(db.Home.SUMMER_MODE, !summerMode);

        try {
            await home.save();
            this.setState({summerMode: !summerMode, modalOpen: false});
            await swal({title: t('Success'), text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (err) { console.error(err) }
    }

    render(){
        const {t} = this.props;
        const {loading, summerMode} = this.state;

        return (
            <Fragment>
                {loading && <Loader size={'medium'}/>}
                {!loading &&
                <div className="card-summer-mode">
                    <div className="card card-outer side-card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <div className="card-title mb-0">
                                <h2 className="mb-0">{t('Summer mode')}</h2>
                            </div>
                        </div>
                        <div className="card-wrap mb-0">
                            <div id="summer-mode-form">
                                <div className="buttons-wr">
                                    {summerMode && <button type="submit" className='btn btn-green' onClick={() => this.setState({modalOpen: true})}>{t('Enabled')}</button>}
                                    {!summerMode && <button type="submit" className='btn btn-green disabled'  onClick={() => this.setState({modalOpen: true})}>{t('Disabled')}</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }

                <div className="modal fade show" id="summerModeModal" tabIndex="-1" role="dialog"
                    aria-labelledby="summerModeModalLabel" aria-hidden="true" style={{display: this.state.modalOpen ? 'block' : 'none'}}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content edit-info">
                            <div className="modal-header">
                                <h5 className="modal-title" id="summerModeModalLabel">{t('Summer mode')}</h5>
                                <button type="button" className="close" onClick={() => this.setState({modalOpen: false})}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <path fill="#222"
                                            d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="modal-body">
                                {!summerMode && <p>{t('Are you sure? Make sure that you have turned off the central heating system first, as the temperature control will be disabled when this option is active. All thermostats will be opened completely to prevent the valves from getting stuck.')}</p>}
                                {summerMode && <p>{t('Are you sure you want to disable Summer mode?')}</p>}
                                <div className="actions">
                                    {summerMode && <button type="button" className="submit-form-btn" data-dismiss="modal" onClick={this.buttonPressed}>{t('Disable')}</button>}
                                    {!summerMode && <button type="button" className="submit-form-btn" data-dismiss="modal" onClick={this.buttonPressed}>{t('Enable')}</button>}
                                    <button type="button" className="cancel-form-btn"  onClick={() => this.setState({modalOpen: false})}>{t('Close')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.modalOpen && <div className="modal-backdrop show"></div>
                }

            </Fragment>
        );
    }
}

CardSummerMode.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired
};

export default CardSummerMode;