'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {get, isEqual, merge, set} from 'lodash';
import Parse from 'parse';

import {connect} from 'react-redux';
import translate from '../translate';
import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import Footer from '../footer';
import * as db from '../../lib/structure';
import {makeId, manageError, redirectIfNotLogged} from '../../lib/util';
import Loader from '../loader';
import swal from 'sweetalert';
import assert from 'assert';

class PageProfile extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            city: '',
            area: '',
            build_year: '',
            windows: '',
            people: '',
            type: '',
            heatingSystemValue: '',
            typeValue: '',
            firstname: '',
            lastname: '',
            username: '',
            email: '',
            location: '',
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            holiday: false,
            types: [
                {
                    name: 'Detached House',
                    extSide: 4,
                    roof: 1,
                    floor: 1
                },
                {
                    name: 'Attached House',
                    extSide: 2,
                    roof: 1,
                    floor: 1
                }
            ],
            offlineDevicesCb: false,
            showAllDevices: false,
            energyReportCb: false,
            allowHolidayOnQrCodePortal: false,
            allowTemperatureModificationOnQrCodePortal: false,
            showPassword: false,
            thresholds: null,
            initialThresholds: null,
            defaultThresholds: [
                {threshold: 1000, colorName: db.Device.FORCE_LED_COLOR$GREEN, colorValue: '#7ace4c'},
                {threshold: 1500, colorName: db.Device.FORCE_LED_COLOR$YELLOW, colorValue: '#ffea00'},
                {threshold: 2000, colorName: db.Device.FORCE_LED_COLOR$RED, colorValue: '#e50404'}
            ],
            colors: [
                {name: db.Device.FORCE_LED_COLOR$GREEN, value: '#7ace4c'},
                {name: db.Device.FORCE_LED_COLOR$TURNED_OFF, value: '#898989'},
                {name: db.Device.FORCE_LED_COLOR$YELLOW, value: '#ffea00'},
                {name: db.Device.FORCE_LED_COLOR$RED, value: '#e50404'}
            ]
        };

        this.changeInputField = this.changeInputField.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.getHome = this.getHome.bind(this);
        this.getHomeSystem = this.getHomeSystem.bind(this);
        this.renderContent = this.renderContent.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.handleThresholdChange = this.handleThresholdChange.bind(this);
        this.thresholdsWereChanged = this.thresholdsWereChanged.bind(this);
        this.handleThresholds = this.handleThresholds.bind(this);
        this.verifyThresholds = this.verifyThresholds.bind(this);
    }

    async componentDidMount(){
        let user = await redirectIfNotLogged(this.props.history);

        if (user) {
            this.setState({user: user});

            let homeId = localStorage.getItem('buildingSelection');

            await this.getHome(homeId);

            this.setState({user});
            this.getHomeSystem();
        }
    }

    handleColorChange(color, index){
        let threshold = {
            ...this.state.thresholds[index]
        };
        threshold.colorName = color.name;
        threshold.colorValue = color.value;
        let updatedThresholds = [...this.state.thresholds];
        updatedThresholds[index] = threshold;
        this.setState({
            thresholds: updatedThresholds
        });
    }

    handleThresholdChange(value, index){
        const intRegex = /^[0-9\b]+$/;

        if (value === '' || intRegex.test(value)) {
            let intValue = parseInt(value);
            let threshold = {
                ...this.state.thresholds[index]
            };
            threshold.threshold = intValue;
            let updatedThresholds = [...this.state.thresholds];
            updatedThresholds[index] = threshold;
            this.setState({
                thresholds: updatedThresholds
            });
        }
    }

    changeInputField(e){
        let name = e.target.name;
        let value = e.target.value;

        let home = this.state.home;
        let devicesConfig = this.state.home.get(db.Home.DEVICES_CONFIG);

        switch (name) {
            case db.Home.AREA:
            case db.Home.YEAR:
            case db.Home.WINDOWS:
            case db.Home.PEOPLE:
                this.setState(prev => {
                    prev.home.set(name, parseInt(value));

                    return prev;
                });
                break;
            case db.Home.HOME_NAME:
            case db.Home.QR_CODE_PORTAL_ALLOWED_DOMAIN:
            case db.Home.CITY:
                home.set(name, value);
                this.setState({home});
                break;
            case db.Home.TYPE:
                this.setState(prev => {
                    prev.home.set(name, JSON.stringify(value));
                    prev.typeValue = value.name;

                    return prev;
                });
                break;
            case db.Home.SYSTEM:
                this.setState(prev => {
                    prev.home.set(db.Home.SYSTEM, value);

                    return prev;
                });
                break;
            case db._User.NAME:
            case db._User.LAST_NAME:
            case db._User.USERNAME:
            case db._User.EMAIL:
            case db._User.LOCATION:
            case db._User.MAILING_STREET:
            case db._User.MAILING_ZIP_CODE:
            case db._User.MAILING_CITY:
                this.setState(prev => {
                    prev.user.set(name, value);

                    return prev;
                });
                break;
            case db._User.PHONE:
            case db._User.MOBILE_PHONE:
                this.setState(prev => {
                    prev.user.set(name, value.replace(/\D/g,''));
                    return prev;
                });
                break;
            case db.Home.DEVICES_OFFLINE_NOTIFICATION:
                this.state.offlineDevicesCb ?
                    this.setState({offlineDevicesCb:false}) : this.setState({offlineDevicesCb:true});
                break;
            case db.Home.ENERGY_REPORT_NOTIFICATION:
                this.state.energyReportCb ?
                    this.setState({energyReportCb:false}) : this.setState({energyReportCb:true});
                break;
            case db.Home.ALLOW_HOLIDAY_ON_QR_CODE_PORTAL:
                this.state.allowHolidayOnQrCodePortal ?
                    this.setState({allowHolidayOnQrCodePortal:false}) :
                    this.setState({allowHolidayOnQrCodePortal:true});
                break;
            case db.Home.ALLOW_TEMPERATURE_MODIFICATION_ON_QR_CODE_PORTAL:
                this.state.allowTemperatureModificationOnQrCodePortal ?
                    this.setState({allowTemperatureModificationOnQrCodePortal:false}) :
                    this.setState({allowTemperatureModificationOnQrCodePortal:true});
                break;
            case db.Home.DEVICES_CONFIG$WIFI_NAME:
                devicesConfig = set(devicesConfig, 'deviceConfig.data[0].wifiName', value);
                home.set(db.Home.DEVICES_CONFIG, devicesConfig);
                this.setState({home});
                break;
            case db.Home.DEVICES_CONFIG$WIFI_PASSWORD:
                devicesConfig = set(devicesConfig, 'deviceConfig.data[0].wifiPassword', value);
                home.set(db.Home.DEVICES_CONFIG, devicesConfig);
                this.setState({home});
                break;
            case db.Home.ALLOW_ADMIN_ACTIONS:
                home.set(db.Home.ALLOW_ADMIN_ACTIONS, !home.get(db.Home.ALLOW_ADMIN_ACTIONS));
                home.set(db.Home.ALLOW_ADMIN_ACTIONS_EDIT_USER, this.state.user);
                home.set(db.Home.ALLOW_ADMIN_ACTIONS_EDIT_DATE, new Date());
                this.setState({home});
                break;
            case 'showPassword':
            case 'oldPassword':
            case 'newPassword':
            case 'confirmPassword':
                this.setState({[name]: value});
                break;
            default:
        }
    }

    thresholdsWereChanged(){
        return !isEqual(this.state.thresholds, this.state.initialThresholds) && this.state.initialThresholds;
    }

    async verifyThresholds() {
        const valuesAreValidIntegers = this.state.thresholds.every((item) => {
            return !isNaN(item.threshold);
        })

        if (!valuesAreValidIntegers) {
            let errorMessage = this.props.t('Threshold value cannot be empty');
            await swal(errorMessage, '', 'error');
            return false;
        }

        const valuesAreIncreasing = this.state.thresholds.every((item, index, thresholds) => {
            if (index > 0 && item.threshold <= thresholds[index - 1].threshold) {
                return false;
            }
            return true;
        })

        if (!valuesAreIncreasing) {
            let errorMessage = this.props.t('Threshold values should be specified in increasing manner from top to bottom');
            await swal(errorMessage, '', 'error');
            return false;
        }
        return true;
    }

    async handleThresholds() {
        const {t} = this.props;

        if (! (await this.verifyThresholds())) {
            return false;
        }

        let agreed = false;
        if (this.thresholdsWereChanged()) {
            agreed = await swal({
                title: t('Warning'),
                icon: 'warning',
                text: t('This will change the current behavior of the colors and the thresholds according to the new configuration. Are you sure you want to proceed?'),
                buttons: [t('Cancel'), t('Confirm')],
                dangerMode: true
            });
        }

        if (agreed || !this.state.initialThresholds) {
            this.state.home.set(db.Home.THRESHOLDS, this.state.thresholds);
            return true;
        } else {
            this.setState({thresholds: this.state.initialThresholds});
            return true;
        }
    }

    async saveChanges(event, tabName){
        const {t} = this.props;
        event.preventDefault();

        try {
            if (tabName === 'all') {
                if (! (await this.handleThresholds())) {
                    return;
                }
                //this case for mobile as there is one submit button for all tabs
                // do request for all data (general, my info and change password)
                this.state.home.set(db.Home.ALLOW_HOLIDAY_ON_QR_CODE_PORTAL, this.state.allowHolidayOnQrCodePortal);
                this.state.home.set(db.Home.ALLOW_TEMPERATURE_MODIFICATION_ON_QR_CODE_PORTAL,
                    this.state.allowTemperatureModificationOnQrCodePortal);

                this.state.user.save()
                    .then(() => this.state.home.save())
                    .then(() => swal({
                        title: t('Data saved'),
                        text: ' ',
                        icon: 'success',
                        button: [''],
                        timer: 1000
                    }))
                    .catch(manageError);
            } else if (tabName === 'my_info') { // this for my info tab 'save changes' button
                this.state.user.save()
                    .then(() => swal({
                        title: t('Data saved'),
                        text: ' ',
                        icon: 'success',
                        button: [''],
                        timer: 1000
                    }))
                    .catch(manageError);
            } else if (tabName === 'general_info') { // this for change password tab 'save changes' button
                this.state.home.save()
                    .then(() => swal({
                        title: t('Data saved'),
                        text: ' ',
                        icon: 'success',
                        button: [''],
                        timer: 1000
                    }))
                    .catch(manageError);
            } else if (tabName === 'change-password') {
                let user = Parse.User.current();

                assert(!!this.state.oldPassword, 'Current password is mandatory');

                try{
                    await user.verifyPassword(this.state.oldPassword);
                } catch (e){
                    throw new Error('Verification of the current password failed. Please re-try.')
                }

                assert(!!this.state.newPassword && !!this.state.confirmPassword, 'New password and confirm password are mandatory.');
                assert(this.state.newPassword === this.state.confirmPassword, 'Passwords should be identical.');
                assert(this.state.newPassword.length >= 6, 'Password length should be of at least 6 characters.');
                assert(this.state.confirmPassword.length >= 6, 'Password length should be of at least 6 characters.');

                //https://stackoverflow.com/questions/12090077/
                //javascript-regular-expression-password-validation-having-special-characters
                let verifyPasswordPolicy = (newPasswrod, username, email,  name) => {
                    function checkConsecutiveLetters(name, login, consecutive = 3) {
                        if(!name) return;

                        let pattern = [];
                        name = name.replace(/\s+/, '');
                        for (let i = 0, l = name.length - consecutive; i <= l; i++) {
                            pattern.push(`${name[i]}(?=${name.slice(i + 1, i + consecutive)})`);
                        }

                        return new RegExp(pattern.join('|'), 'i').test(login);
                    }

                    let numbers =  /[0-9]/.test(newPasswrod);
                    let specialChars = /(?=.*[!@#$%^&*])/.test(newPasswrod);
                    let upperLetters = /[A-Z]/.test(newPasswrod);
                    let lowerLetters = /[a-z]/.test(newPasswrod);
                    let accountName = newPasswrod.includes(username) && newPasswrod.includes(email);
                    let twoConsecutiveCharsOfUserPlainName = checkConsecutiveLetters(name, newPasswrod, 3);

                    let categoriesMet = [specialChars, upperLetters, lowerLetters, numbers]
                        .filter(item => item === true)
                        .length;

                    if(categoriesMet < 3) throw new Error('Password should contains at least 3 of the 4 following categories: Upper case letters, lower case letters, numbers, special characters.');
                    if(accountName === true || twoConsecutiveCharsOfUserPlainName === true) throw new Error('Password must not contain the account name or more than 2 consecutive characters of the user\'s plain name');
                };

                let username = user.getUsername();
                let email = user.getEmail();
                let name = user.get(db._User.NAME);

                verifyPasswordPolicy(this.state.newPassword, username, email, name);

                user.set(db._User.PASSWORD, this.state.newPassword);
                await user.save();
                this.setState({
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                });
                await swal({
                    title: t('Passwrod reset successfull!'),
                    text: ' ',
                    icon: 'success',
                    button: [''],
                    timer: 1000
                });
            } else if (tabName === 'notifications') { // this for notification tab 'save changes' button
                this.state.home.set(db.Home.ENERGY_REPORT_NOTIFICATION, this.state.energyReportCb);
                this.state.home.set(db.Home.DEVICES_OFFLINE_NOTIFICATION, this.state.offlineDevicesCb);

                this.state.home.save()
                    .then(() => swal({
                        title: t('Data saved'),
                        text: ' ',
                        icon: 'success',
                        button: [''],
                        timer: 1000
                    }))
                    .catch(manageError);
            } else if (tabName === 'settings') {

                if (! (await this.handleThresholds())) {
                    return;
                }

                this.state.home.set(db.Home.ALLOW_HOLIDAY_ON_QR_CODE_PORTAL, this.state.allowHolidayOnQrCodePortal);
                this.state.home.set(db.Home.ALLOW_TEMPERATURE_MODIFICATION_ON_QR_CODE_PORTAL,
                    this.state.allowTemperatureModificationOnQrCodePortal);

                let dirtyKeys = this.state.home.dirtyKeys();

                if(
                    dirtyKeys.indexOf(db.Home.ALLOW_ADMIN_ACTIONS) >= 0 &&
                        this.state.home.get(db.Home.ALLOW_ADMIN_ACTIONS) === true
                ){
                    let verifyText = makeId(6);
                    let text = await swal({
                        title: t('Warning'),
                        icon: 'warning',
                        text: t('Are you sure to allow automatic WiFi configuration? Warning, this can lead to connection lost to the devices. Please enter the verification code "<verify_code>" in the input below or contact support@cleveron.ch for more information.')
                            .replace('<verify_code>', verifyText),
                        content: 'input',
                        className: 'allow-admin-action-modal',
                        buttons: [t('Cancel'), t('Confirm')],
                        dangerMode: true
                    });

                    if (!text) throw new Error(t('Action aborted by user'));

                    if(verifyText.length !== text.length || verifyText !== text){
                        throw new Error(t('Code verification failed!'));
                    }
                }

                if(
                    dirtyKeys.indexOf(db.Home.DEVICES_CONFIG) >= 0 &&
                    (this.state.home.get(db.Home.ALLOW_ADMIN_ACTIONS) === false ||
                        !this.state.home.get(db.Home.ALLOW_ADMIN_ACTIONS))
                ){
                    let verifyText = makeId(6);
                    let text = await swal({
                        title: t('Warning'),
                        icon: 'warning',
                        text: t('Are you sure to change the WiFi configuration? Warning, this can lead to connection lost to the devices. Please enter the verification code "<verify_code>" in the input below or contact support@cleveron.ch for more information.')
                            .replace('<verify_code>', verifyText),
                        content: 'input',
                        buttons: [t('Cancel'), t('Confirm')],
                        dangerMode: true
                    });

                    if (!text) throw new Error(t('Action aborted by user'));

                    if(verifyText.length !== text.length || verifyText !== text){
                        throw new Error(t('Code verification failed!'));
                    }
                }

                await this.state.home.save();
                swal({
                    title: t('Data saved'),
                    text: ' ',
                    icon: 'success',
                    button: [''],
                    timer: 1000
                });
            }
        }catch (e) {
            return await manageError(e, this.props);
        }
    }

    async getHome(homeId){
        let query = new Parse.Query('Home');
        query.include(db.Home.SYSTEM);

        try {
            let home = await query.get(homeId);
            if (!home) return Promise.reject(Error('Home not found'));

            if (home) {
                let allowHolidayOnQrCodePortalFromDb = home.get(db.Home.ALLOW_HOLIDAY_ON_QR_CODE_PORTAL);
                let allowTemperatureModificationOnQrCodePortalFromDb =
                    home.get(db.Home.ALLOW_TEMPERATURE_MODIFICATION_ON_QR_CODE_PORTAL);
                let thresholds = home.get(db.Home.THRESHOLDS);
                this.setState({offlineDevicesCb: home.get(db.Home.DEVICES_OFFLINE_NOTIFICATION),
                    energyReportCb: home.get(db.Home.ENERGY_REPORT_NOTIFICATION),
                    allowHolidayOnQrCodePortal: typeof allowHolidayOnQrCodePortalFromDb === 'undefined'
                        ? true
                        : !!home.get(db.Home.ALLOW_HOLIDAY_ON_QR_CODE_PORTAL),
                    allowTemperatureModificationOnQrCodePortal:
                        typeof allowTemperatureModificationOnQrCodePortalFromDb === 'undefined'
                            ? true
                            : !!home.get(db.Home.ALLOW_TEMPERATURE_MODIFICATION_ON_QR_CODE_PORTAL),
                    thresholds: typeof thresholds === 'undefined' ? this.state.defaultThresholds : thresholds,
                    initialThresholds: thresholds
                })
            }

            this.setState({home});
            return home;
        } catch (e) {
            return await manageError(e);
        }
    }

    getHomeSystem(){
        let query = new Parse.Query('HomeSystem');

        return query
            .find()
            .then(homeSystems => {
                this.setState({homeSystems: homeSystems});
            })
            .catch(manageError);
    }

    renderContent(){
        const {t} = this.props;

        if (!this.state.home || !this.state.user) return <Loader/>;

        const hasWritePermission = localStorage.getItem('user:hasWritePermission') === 'true';

        return <div className="inner">
            <Breadcrumbs {...this.props} elements={[
                {
                    link: '/dashboard',
                    name: t('Home')
                },
                {
                    link: null,
                    name: t('Profile')
                }
            ]}/>
            <h1>{t('Profile')}</h1>
            <div className="full-w card card-outer profile-outer">
                <div className="hide-xs">
                    <ul className="nav nav-tabs header-tabs big" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active show" id="info-tab" data-toggle="tab"
                                href="#info-tab-c" role="tab" aria-controls="info-tab"
                                aria-selected="false">{t('My info')}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="info-tab" data-toggle="tab"
                                href="#change-password" role="tab" aria-controls="info-tab"
                                aria-selected="false">{t('Change password')}</a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane fade active show" id="info-tab-c" role="tabpanel" aria-labelledby="info-tab">
                            <form>
                                <div className="form-group">
                                    <label>{t('First Name')}</label>
                                    <input
                                        type="text"
                                        placeholder={t('p.es. Paolo')}
                                        name={db._User.NAME}
                                        value={this.state.user.get(db._User.NAME)}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Last name')}</label>
                                    <input
                                        type="text"
                                        placeholder={t('p.es. Rossi')}
                                        name={db._User.LAST_NAME}
                                        value={this.state.user.get(db._User.LAST_NAME)}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Email')}</label>
                                    <input
                                        type="email"
                                        placeholder={t('p.es. paolo.rossi@gmail.com')}
                                        name={db._User.EMAIL}
                                        value={this.state.user.get(db._User.EMAIL)}
                                        disabled={true}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Mailing Street')}</label>
                                    <input
                                        type="text"
                                        name={db._User.MAILING_STREET}
                                        value={this.state.user.get(db._User.MAILING_STREET)}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Mailing City')}</label>
                                    <input
                                        type="text"
                                        name={db._User.MAILING_CITY}
                                        value={this.state.user.get(db._User.MAILING_CITY) || ''}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Mailing ZIP-Code')}</label>
                                    <input
                                        type="text"
                                        name={db._User.MAILING_ZIP_CODE}
                                        value={this.state.user.get(db._User.MAILING_ZIP_CODE) || ''}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Phone')}</label>
                                    <input
                                        type="text"
                                        name={db._User.PHONE}
                                        value={this.state.user.get(db._User.PHONE) || ''}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Mobile Phone')}</label>
                                    <input
                                        type="text"
                                        name={db._User.MOBILE_PHONE}
                                        value={this.state.user.get(db._User.MOBILE_PHONE) || ''}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="divider"/>
                                <button
                                    className="submit-form-btn"
                                    onClick={(e) => this.saveChanges(e, 'my_info')}
                                >{t('Save changes')}</button>
                            </form>
                        </div>
                        <div className="tab-pane fade" id="change-password" role="tabpanel" aria-labelledby="info-tab">
                            <form>
                                <div className="form-group">
                                    <label>{t('Current Password')}</label>
                                    <input
                                        type="password"
                                        placeholder={''}
                                        name={'oldPassword'}
                                        value={this.state.oldPassword}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('New Password')}</label>
                                    <input
                                        type="password"
                                        placeholder={''}
                                        name={'newPassword'}
                                        value={this.state.newPassword}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Confirm password')}</label>
                                    <input
                                        type="password"
                                        placeholder={''}
                                        name={'confirmPassword'}
                                        value={this.state.confirmPassword}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <p style={{color: 'orange'}}>
                                    {t('The password should follow the following rules')}:
                                    <ul>
                                        <li>{t('Password length at least 6 characters')}</li>
                                        <li>{t('Password contains at least 3 of the 4 following categories: Upper case letters, lower case letters, numbers, special characters.')}</li>
                                        <li>{t('Password must not contain the account name or more than 2 consecutive characters of the user\'s plain name')}</li>
                                    </ul>
                                </p>
                                <p>{t('You don\'t remember you current password? Contact support@cleveron.ch to reset it and receive a new temporary password.')}</p>
                                <div className="divider"/>
                                <button
                                    className="submit-form-btn"
                                    onClick={(e) => this.saveChanges(e, 'change-password')}
                                >{t('Save changes')}</button>
                            </form>
                        </div>
                    </div>
                </div>
                {/*mobile profile*/}
                <form className="show-xs">
                    <div className="accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                        <div className="accordion-item">
                            <div role="tab" id="headingTwo" className="accordion-header">
                                <a className="collapsed closed-tab" data-toggle="collapse" data-parent="#accordionEx"
                                    href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    {t('My info')} <i/>
                                </a>
                            </div>
                            <div id="collapseTwo" className="collapse accordion-body" role="tabpanel"
                                aria-labelledby="headingTwo" data-parent="#accordionEx">
                                <div className="form-group">
                                    <label>{t('First Name')}</label>
                                    <input
                                        type="text"
                                        placeholder={t('p.es. Paolo')}
                                        name={db._User.NAME}
                                        value={this.state.user.get(db._User.NAME)}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Last name')}</label>
                                    <input
                                        type="text"
                                        placeholder={t('p.es. Rossi')}
                                        name={db._User.LAST_NAME}
                                        value={this.state.user.get(db._User.LAST_NAME)}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Email')}</label>
                                    <input
                                        type="email"
                                        placeholder={t('p.es. paolo.rossi@gmail.com')}
                                        name={db._User.EMAIL}
                                        value={this.state.user.get(db._User.EMAIL)}
                                        disabled={true}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Mailing Street')}</label>
                                    <input
                                        type="text"
                                        name={db._User.MAILING_STREET}
                                        value={this.state.user.get(db._User.MAILING_STREET) || ''}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Mailing City')}</label>
                                    <input
                                        type="text"
                                        name={db._User.MAILING_CITY}
                                        value={this.state.user.get(db._User.MAILING_CITY) || ''}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Mailing ZIP-Code')}</label>
                                    <input
                                        type="text"
                                        name={db._User.MAILING_ZIP_CODE}
                                        value={this.state.user.get(db._User.MAILING_ZIP_CODE) || ''}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Phone')}</label>
                                    <input
                                        type="text"
                                        name={db._User.PHONE}
                                        value={this.state.user.get(db._User.PHONE) || ''}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Mobile Phone')}</label>
                                    <input
                                        type="text"
                                        name={db._User.MOBILE_PHONE}
                                        value={this.state.user.get(db._User.MOBILE_PHONE) || ''}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <button
                                    className="submit-form-btn show-xs"
                                    id={'save-profile-changes'}
                                    onClick={(e) => this.saveChanges(e, 'all')}
                                    disabled={!hasWritePermission}
                                >{t('Save changes')}</button>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <div role="tab" id="headingTwo" className="accordion-header">
                                <a className="collapsed closed-tab" data-toggle="collapse" data-parent="#accordionEx"
                                    href="#collapse3" aria-expanded="false" aria-controls="collapseTwo">
                                    {t('Change password')} <i/>
                                </a>
                            </div>
                            <div id="collapse3" className="collapse accordion-body" role="tabpanel"
                                aria-labelledby="headingTwo" data-parent="#accordionEx">
                                <div className="form-group">
                                    <label>{t('Current Password')}</label>
                                    <input
                                        type="password"
                                        placeholder={''}
                                        name={'oldPassword'}
                                        value={this.state.oldPassword}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('New Password')}</label>
                                    <input
                                        type="password"
                                        placeholder={''}
                                        name={'newPassword'}
                                        value={this.state.newPassword}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('Confirm password')}</label>
                                    <input
                                        type="password"
                                        placeholder={''}
                                        name={'confirmPassword'}
                                        value={this.state.confirmPassword}
                                        onChange={this.changeInputField}
                                    />
                                </div>
                                <p>{t('You don\'t remember you current password? Contact support@cleveron.ch to reset it and receive a new temporary password.')}</p>
                                <button
                                    className="submit-form-btn"
                                    onClick={(e) => this.saveChanges(e, 'change-password')}
                                >{t('Save changes')}</button>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>;
    }

    render(){
        return <div className={'page-profile'}>
            {
                get(this.props, 'shared.general.Error.visible') && <div className={'alert alert-danger'}>
                    {get(this.props, 'shared.general.Error.message')}
                </div>
            }

            <div className="wrapper d-flex align-items-stretch">
                <Sidebar {...this.props}/>
                <div className="content">
                    <Navbar {...this.props}/>
                    {this.renderContent()}
                    <Footer/>
                </div>
            </div>
        </div>
    }

}

PageProfile.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired
};

/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(PageProfile));