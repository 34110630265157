import React from 'react';
import PropTypes from 'prop-types';
import offlineIcon from '../../../assets/images/ic_offline-tooltip.svg';

export default function DeviceOfflineTooltip(props) {
    const {t, show} = props;

    return (
        <div style={{position: 'relative'}}>
            <div style={{display: show ? 'block' : 'none', position: 'absolute', top: '-50px', right: '50px', zIndex: 3000}} className='offline-device'>
                <div>
                    <div className='offline-device-icon'><img src={offlineIcon} /></div>
                    <div style={{padding: '10px 20px 0 20px'}}>
                        <h2>{t('Your device is offline')}</h2>
                        <div className='offline-device-text'>
                            {t('Your device keeps working, but data transfer has stopped!')}
                        </div>
                        <div className='offline-device-text' style={{marginTop: '12px', marginBottom: '12px'}}>
                            {t('Please check your wi-fi connection.')}
                        </div>
                    </div>
                    <div className='offline-device-footer'>
                        {t('If you weren\'t able to define the problem, please contact us')}
                        {' '}
                        <a href='mailto:support@cleveron.ch'>{'support@cleveron.ch'}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

DeviceOfflineTooltip.propTypes = {
    t: PropTypes.func,
    show: PropTypes.bool
};