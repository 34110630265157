import React from 'react';
import PropType from 'prop-types';
import moment from 'moment';
import {getStartEndDateFromMonth, getStartEndDatesFromWeek} from '../../../lib/util';

function getIsoWeekOfDate(month, year){
    return moment(`01/${month}/${year}`, 'DD/MM/YYYY').isoWeek();
}

export default class RoomReportingDatePicker extends React.Component {

    constructor(props){
        super(props);
        let date = moment(`01/${this.props.month}/${this.props.year}`, 'DD/MM/YYYY');
        let week = date.startOf('month').isoWeek();

        this.state = {
            monthSelectActive: false,
            yearSelectActive: false,
            selectedMonthIndex: this.props.month,
            selectedYear: this.props.year,
            week,
            numberOfWeek: null,
            selectDateModeActive: false,
            selectDateMode: 'Monthly'
        }

        this.onMonthSelect = this.onMonthSelect.bind(this);
        this.onYearSelect = this.onYearSelect.bind(this);
        this.onWeekSelect = this.onWeekSelect.bind(this);
    }

    onMonthSelect(monthIndex) {
        let week = getIsoWeekOfDate(monthIndex, this.state.selectedYear);
        this.setState({
            selectedMonthIndex: monthIndex,
            week,
        }, () => this.props.onDateChange(week, monthIndex, this.state.selectedYear));
    }

    onYearSelect(year) {
        let week = getIsoWeekOfDate(this.state.selectedMonthIndex, year);
        this.setState({
            selectedYear: year,
            week,
        }, () => this.props.onDateChange(week, this.state.selectedMonthIndex, year));
    }

    onWeekSelect(week){
        let month = moment().isoWeek(week).year(this.state.selectedYear).month() + 1;

        this.setState(
            {week, selectedMonthIndex: month},
            () => this.props.onDateChange(week, month, this.state.selectedYear)
        );
    }

    getYears() {
        let years = [];
        const currentYear = new Date().getFullYear();
        for(let year=2017; year <= currentYear; year++) {
            years.push(year);
        }
        return years;
    }

    onSelectDateModeChange(mode){
        this.setState({selectDateMode: mode});
        this.props.onModeChange(mode);
    }

    render() {
        const {t} = this.props;
        if (!this.props.year || !this.props.month) return '';

        let monthsLabel = {
            1: t('January'),
            2: t('February'),
            3: t('March'),
            4: t('April'),
            5: t('May'),
            6: t('June'),
            7: t('July'),
            8: t('August'),
            9: t('September'),
            10: t('October'),
            11: t('November'),
            12: t('December')
        };

        let years = this.getYears();

        let dateLabel= '';
        if(this.state.selectDateMode === 'Weekly'){
            let {start, end} = getStartEndDatesFromWeek(this.state.week, this.state.selectedYear);
            dateLabel = <span>{start.format('MMM DD')}&nbsp;-&nbsp;{end.format('MMM DD, YYYY')}</span>;
        } else {
            let {start, end} = getStartEndDateFromMonth(this.state.selectedMonthIndex, this.state.selectedYear);
            dateLabel = <span>{start.format('MMM DD')}&nbsp;-&nbsp;{end.format('MMM DD, YYYY')}</span>;
        }

        return (

            <div className='room-reporting-date-picker-container' style={this.props.style}>
                <div className={'dropdown-selector'}>
                    {
                        true &&
                        <div className={`select-custom ${this.state.selectDateModeActive ? 'active' : ''}`}
                            style={{width: '160px'}}
                            onClick={() => this.setState({selectDateModeActive: !this.state.selectDateModeActive})}>
                            <div className="select-custom-status">
                                {t(this.state.selectDateMode)}
                            </div>
                            <ul className="select-custom-list">
                                <li className="select-custom-item"
                                    onClick={() => this.onSelectDateModeChange('Monthly')}
                                >
                                    {t('Monthly')}
                                </li>
                                <li className="select-custom-item"
                                    onClick={() => this.onSelectDateModeChange('Weekly')}
                                >
                                    {t('Weekly')}
                                </li>
                            </ul>
                        </div>
                    }

                    <div className={`select-custom ${this.state.monthSelectActive ? 'active' : ''}`}
                        style={{width: '160px'}}
                        onClick={() => this.setState({monthSelectActive: !this.state.monthSelectActive})}>
                        <div className="select-custom-status">
                            {monthsLabel[this.state.selectedMonthIndex]}
                        </div>
                        <ul className="select-custom-list">
                            {
                                Object.entries(monthsLabel).map(month => {
                                    return <li key={month[0]}
                                        className="select-custom-item"
                                        onClick={() => this.onMonthSelect(parseInt(month[0]))}
                                    >{month[1]}</li>
                                })
                            }
                        </ul>
                    </div>
                    <div className={`select-custom ${this.state.yearSelectActive ? 'active' : ''}`}
                        style={{width: '130px'}}
                        onClick={() => this.setState({yearSelectActive: !this.state.yearSelectActive})}>
                        <div className="select-custom-status">
                            {this.state.selectedYear}
                        </div>
                        <ul className="select-custom-list">
                            {
                                years.map(year => {
                                    return <li key={year}
                                        className="select-custom-item"
                                        onClick={() => this.onYearSelect(year)}
                                    >{year}</li>
                                })
                            }
                        </ul>
                    </div>
                </div>

                {
                    true &&
                    <div className={'week-date-selector'}>
                        <a
                            onClick={() => this.onWeekSelect(this.state.week === 1 ? 52 : this.state.week - 1)}
                            className={`arrow-left ${this.state.selectDateMode === 'Monthly' ? 'disabled': ''}`}></a>
                        <div className={'date-range'}>
                            {dateLabel}
                        </div>

                        <a
                            onClick={() => this.onWeekSelect(this.state.week === 52 ? 1 : this.state.week + 1)}
                            className={`arrow-right ${this.state.selectDateMode === 'Monthly' ? 'disabled': ''}`}></a>
                    </div>
                }

                {this.props.children}
            </div>
        );
    }

}

RoomReportingDatePicker.propTypes = {
    t: PropType.func,
    month: PropType.number,
    year: PropType.number,
    onDateChange: PropType.func,
    onModeChange: PropType.func,
    style: PropType.object,
    children: PropType.any
};