import PropTypes from 'prop-types';

import React, {Component} from 'react';
import translate from '../translate';
import moment from 'moment';
import i18next from '../i18n';
import db from '../../lib/structure';
import swal from 'sweetalert';
import Parse from 'parse';
import {addCloseButtonToDatepicker, toPointerFromId} from '../../lib/util';
import {VerifaliaRestClient} from 'verifalia';

const isValidEmail = require('is-valid-email');

const verifalia = new VerifaliaRestClient({
    username: 'fca8acfd21c14cbe84e96ce20a175e63',
    password: 'x3U)vHVgESqU'
});

class RoomHoliday extends Component {

    constructor(props) {
        super(props);

        this.roomHolidayAccordeon = React.createRef();
        this.initDatePicker = this.initDatePicker.bind(this);
        this.displayTime = this.displayTime.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.verifyInput = this.verifyInput.bind(this);
        this.createDBEvent = this.createDBEvent.bind(this);
        this.saveButtonPressed = this.saveButtonPressed.bind(this);
        this.validateEventName = this.validateEventName.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validateDateRange = this.validateDateRange.bind(this);
        this.shouldDisableSaveButton = this.shouldDisableSaveButton.bind(this);

        this.state = this.getInitialState(props);
    }

    getInitialState(props) {
        let nextFiveMins = moment().format('mm') % 15;
        return  {
            eventName: '',
            email: '',
            validEmail: false,
            emailTouched: false,
            emailLoading: false,
            validationEmailTexts: [],
            validatedEmail: '',
            invalidDateRange: false,
            invalidDateRangeText: '',
            dateFromEvent: moment().add(15 - nextFiveMins, 'minutes'),
            dateToEvent: moment().add(15 - nextFiveMins + 15, 'minutes'),
            heating: 'no-heat',
            homeId: props.homeId,
            roomId: props.roomId,
            eventNameIsBlank: true,
            eventNameTouched: false
        };
    }

    async validateEmail(e){
        if(this.state.validEmail && this.state.validatedEmail === this.state.email) return;
        let email = e ? e.target.value : this.state.email;

        if(email === '' || email == null)
            return this.setState({validEmail: false, emailTouched: true});

        this.setState({emailLoading: true});

        if(!isValidEmail(email))
            return this.setState({validEmail: false, emailTouched: true, emailLoading: false, validationEmailTexts: [
                'The email is invalid. Please check that is contains the symbol "@" and that the domain refers to a valid mailbox.',
            ]});

        let result = await verifalia
            .emailValidations
            .submit(email, true);

        let classification = result.entries[0].classification;

        if(classification === 'Deliverable' || classification === 'Risky') {
            this.setState({validEmail: true, emailTouched: true, emailLoading: false, validatedEmail: email});
        } else {
            this.setState({validEmail: false, emailTouched: true, emailLoading: false, validationEmailTexts: [
                'The email is invalid. Please check that is contains the symbol "@" and that it refers to a valid mailbox.',
                'Contact our <a href="mailto:support@cleveron.ch">support</a> if your e-mail was not recognised as valid.'
            ]});
        }
    }

    resetForm(){
        this.setState(this.getInitialState(this.props));
    }

    async componentDidMount() {
        this.initDatePicker();
        i18next.on('languageChanged', this.handleLanguageChange);
    }

    componentWillUnmount(){
        i18next.off('languageChanged', this.handleLanguageChange);
    }

    initDatePicker(){
        $('#holidayDateFromEvent').datepicker({
            todayHighlight: true,
            format: 'dd M yyyy',
            autoclose: true,
            maxViewMode: 0,
            forceParse: false,
            language: this.props.i18n.resolvedLanguage,
            weekStart: 1
        })
            .on('changeDate', (e) => {
                let date = moment(e.date);
                // eslint-disable-next-line max-len
                this.setState({dateFromEvent: this.state.dateFromEvent.year(date.year()).month(date.month()).date(date.date())});
                this.validateDateRange();
            }).on('show', (e) => {
                addCloseButtonToDatepicker(e.currentTarget.id, this.props.t);
            });

        $('#holidayDateToEvent').datepicker({
            todayHighlight: true,
            format: 'dd M yyyy',
            autoclose: true,
            maxViewMode: 0,
            forceParse: false,
            language: this.props.i18n.resolvedLanguage,
            weekStart: 1
        })
            .on('changeDate', (e) => {
                let date = moment(e.date);
                // eslint-disable-next-line max-len
                this.setState({dateToEvent: this.state.dateToEvent.year(date.year()).month(date.month()).date(date.date())});
                this.validateDateRange();
            }).on('show', (e) => {
                addCloseButtonToDatepicker(e.currentTarget.id, this.props.t);
            });
    }

    handleLanguageChange(){
        $('#dateFromEvent').datepicker('destroy');
        $('#dateToEvent').datepicker('destroy');

        this.initDatePicker();
    }

    handleInputChange(e) {
        let hourMinute = e.target.value.split(':');
        if (e.target.name === 'startTime') {
            this.setState({dateFromEvent: this.state.dateFromEvent.hour(hourMinute[0]).minute(hourMinute[1])});
            this.validateDateRange();
        }

        if (e.target.name === 'endTime') {
            this.setState({dateToEvent: this.state.dateToEvent.hour(hourMinute[0]).minute(hourMinute[1])});
            this.validateDateRange();
        }

        if (e.target.name === 'eventName') {
            this.setState({eventName: e.target.value});
        }
    }

    displayTime(name) {
        const timeFormat = 'HH:mm';
        let timesArray = [];
        let time = moment('00:00', timeFormat);
        timesArray.push(time.format(timeFormat));

        // fill array with 00:00 to 23:45
        for (let i = 0; i < 95; i++) {
            timesArray.push(time.add(15, 'minutes').format(timeFormat));
        }

        return timesArray.map(time => {
            return <label key={time} className="dropdown-item__label dropdown-item select-reason">
                {time}
                <input type="radio" name={name}
                    aria-describedby="12" value={time} onChange={this.handleInputChange}
                />
            </label>
        });

    }

    validateEventName(e) {
        if (!e.target.value) {
            this.setState({eventNameIsBlank: true, eventNameTouched: true});
        } else {
            this.setState({eventNameIsBlank: false, eventNameTouched: true});
        }
    }

    validateDateRange() {
        let {dateFromEvent, dateToEvent} = this.state;
        if (moment(dateToEvent).isBefore(moment(dateFromEvent))) {
            this.setState({invalidDateRange: true, invalidDateRangeText: 'Invalid Date'});
            return false;
        }
        if (moment(dateToEvent).isBefore(moment())) {
            this.setState({invalidDateRange: true, invalidDateRangeText: 'End date already happened'});
            return false;
        }

        this.setState({invalidDateRange: false, invalidDateRangeText: ''});
        return true;
    }

    async verifyInput() {
        let {eventName} = this.state;
        const {t} = this.props;

        await this.validateEmail();
        let {validatedEmail, validEmail, validationEmailTexts} = this.state;
        if (!validatedEmail || !validEmail) {
            let messageText = validationEmailTexts.map((message, i) => <span key={i}>{t(message)}&nbsp;</span>);
            swal(messageText, '', '');
            return false;
        }

        if (!eventName) {
            this.setState({eventNameIsBlank: true, eventNameTouched: true});
            return false;
        }

        this.validateDateRange();
        if (this.state.invalidDateRange) {
            let messageText = t(this.state.invalidDateRangeText);
            swal(messageText, '', 'error');
            return false;
        }

        return true;

    }

    async saveButtonPressed() {
        let validInput = await this.verifyInput();
        if (validInput) {
            await this.createDBEvent();
        }
    }

    shouldDisableSaveButton() {
        return this.state.eventNameIsBlank || !this.state.validEmail || this.state.invalidDateRange
    }

    async createDBEvent(){
        const {t} = this.props;
        try {
            let calendarEvent = new Parse.Object(db.classes.CalendarEvent);
            let startDate = this.state.dateFromEvent.toDate();
            let endDate = this.state.dateToEvent.toDate();
            let swalText = t('Created Event');
            let eventName = this.state.eventName;

            calendarEvent.set(db.CalendarEvent.EVENT_NAME, eventName);
            calendarEvent.set(db.CalendarEvent.EMAIL, this.state.validatedEmail);
            calendarEvent.set(db.CalendarEvent.START_DATE, startDate);
            calendarEvent.set(db.CalendarEvent.END_DATE, endDate);
            calendarEvent.set(db.CalendarEvent.ACTIVE, true);
            calendarEvent.set(db.CalendarEvent.ADDED_FROM, db.CalendarEvent.ADDED_FROM$QR_CODE_PORTAL);
            calendarEvent.set(db.CalendarEvent.EVENT_TYPE, this.state.heating);
            calendarEvent.set(db.CalendarEvent.HOME, toPointerFromId(this.props.homeId, 'Home'));
            calendarEvent.set(db.CalendarEvent.ROOM, toPointerFromId(this.props.roomId, 'Room'));

            await calendarEvent.save();
            swal(swalText, '', 'success');

            this.resetForm();
            this.props.onAdd();
        } catch (err) {
            console.error(err);
            swal('Error', err.message, 'error');
        }
    }

    render() {
        const {t}  = this.props;
        const dateFormat = 'DD MMM YYYY';
        const timeFormat = 'HH:mm';
        const language = this.props.i18n.resolvedLanguage;

        let emailField = () => {
            return <div className={`input-field ${!this.state.validEmail  && this.state.emailTouched ? 'error' : ''}`}>
                <label htmlFor="eventEmail" className="datepicker__label">{t('E-mail')}<span>*</span>
                </label>
                <div className="input-field__input">
                    <input className="input" type="email" name="eventEmail" placeholder={t('Please enter your e-mail')} id="eventEmail" required
                        value={this.state.email}
                        onChange={(e) => {
                            this.setState({email: e.target.value});
                        }}
                        onKeyPress={async (e) => {
                            if(e.key === 'Enter') {
                                e.preventDefault();
                                await this.validateEmail(e);
                            }
                        }}
                        onBlur={this.validateEmail}
                    />
                    {
                        !this.state.validEmail && !this.state.emailLoading && this.state.emailTouched && <svg className="icon icon-error-status input-notification__icon">
                            <use href="/assets/images/sprite.svg#error-status"></use>
                        </svg>
                    }
                    {
                        this.state.emailLoading && <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                            viewBox="0 0 100 100" enableBackground="new 0 0 100 100" className={'icon-loader'}>
                            <path fill="grey" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                <animateTransform
                                    attributeName="transform"
                                    attributeType="XML"
                                    type="rotate"
                                    dur="1s"
                                    from="0 50 50"
                                    to="360 50 50"
                                    repeatCount="indefinite" />
                            </path>
                        </svg>
                    }
                </div>
                {
                    this.state.email !== '' && !this.state.validEmail  && this.state.emailTouched && <small className="input-notification">
                        {this.state.validationEmailTexts.map((message, i) => <span key={i}>{t(message)}&nbsp;</span>)}
                    </small>
                }
            </div>
        }

        return <div className="informer">
            <div className="accordeon" id='room-holiday-accordeon' ref={this.roomHolidayAccordeon}>
                <div className={`accordeon__heading ${this.state.roomHolidayOpen ? 'is-active' : ''}`}
                    onClick={() => this.setState({roomHolidayOpen: !this.state.roomHolidayOpen})}
                >
                    <div className="informer__header" >
                        <div className="informer__heading">{t('Add holiday')}</div>
                        <div className="informer__desc">
                            {t('Add holiday event to this room.')}
                        </div>
                    </div>
                </div>
                <div className="accordeon__pane">
                    <div className="accordeon__pane-main" style={{overflow: 'hidden'}}>

                        <div id="room-holiday-event-form" className="room-holiday-event">

                            {emailField()}

                            <div className={`input-field ${this.state.eventNameIsBlank && this.state.eventNameTouched ? 'error' : ''}`}>
                                <label htmlFor="eventName" className="datepicker__label">{t('Event name')}<span>*</span>
                                </label>
                                <div className="input-field__input">
                                    <input className="input" type="email" name="eventName" placeholder={t('Please enter event name')} id="eventName" required
                                        value={this.state.eventName}
                                        onChange={this.handleInputChange}
                                        onKeyPress={async (e) => {
                                            if(e.key === 'Enter') {
                                                e.preventDefault();
                                                await this.validateEventName(e);
                                            }
                                        }}
                                        onBlur={this.validateEventName}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6 pr-0">
                                    <label htmlFor="holidayDateFromEvent" className="datepicker__label">{t('Start date')}</label>
                                    <input type="text" name="holidayDateFromEvent" className="p-2 datepicker-input w-100" readOnly
                                        id="holidayDateFromEvent"
                                        value={this.state.dateFromEvent.locale(language).format(dateFormat)}/>
                                </div>
                                <div className="col-2 pickers__divider d-flex justify-content-center align-items-end">
                                    <span className="range-divider">-</span>
                                </div>
                                <div className="col-4 col-lg-3 pr-0 pl-0 calendar-event-select">
                                    <label htmlFor="dropdownMenuButtonStart1">{t('Start time')}</label>
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-secondary dropdown-toggle select-reason-button"
                                            type="button"
                                            id="dropdownMenuButtonStart1"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            {this.state.dateFromEvent.format(timeFormat)}
                                        </button>
                                        <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButtonStart1">
                                            {this.displayTime('startTime')}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 pr-0">
                                    <label htmlFor="holidayDateToEvent" className="datepicker__label">{t('End date')}</label>
                                    <input type="text" name="holidayDateToEvent" className="p-2 datepicker-input w-100" readOnly
                                        id="holidayDateToEvent"
                                        value={this.state.dateToEvent.locale(language).format(dateFormat)}/>
                                </div>
                                <div className="col-2 pickers__divider d-flex justify-content-center align-items-end">
                                    <span className="range-divider">-</span>
                                </div>
                                <div className="col-4 col-lg-3 pr-0 pl-0 calendar-event-select">
                                    <label htmlFor="dateFromEvent">{t('End time')}</label>
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-secondary dropdown-toggle select-reason-button"
                                            type="button"
                                            id="dropdownMenuButtonEnd1"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            {this.state.dateToEvent.format(timeFormat)}
                                        </button>
                                        <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButtonEnd1">
                                            {this.displayTime('endTime')}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="buttons-wr">

                                <button id="btn-save-holiday-event" className={`btn btn--primary ${this.shouldDisableSaveButton() ? 'disabled' : ''}`}
                                    onClick={this.saveButtonPressed}>{t('Save')}</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>;
    }
}

RoomHoliday.propTypes = {
    t: PropTypes.any,
    roomId: PropTypes.string,
    homeId: PropTypes.string,
    i18n: PropTypes.object,
    onAdd: PropTypes.func,
};

export default translate(RoomHoliday);