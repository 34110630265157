'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {get, merge} from 'lodash';
import translate from '../translate';
import Loader from '../loader';
import paths from '../../lib/paths';
import LanguagePicker from '../language-picker';
import logoImgMini from '../../assets/images/logo-mini.png';

// catch the event and make changes accordingly
function isValidEmail(email) {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    return email.match(validRegex) != null;
}
class PageRegistrationEmail extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            email: '',
            password: '',
            invalid: {

            },
            errorMessages: {

            }
        };

        this.register = this.register.bind(this);
        this.changeInputField = this.changeInputField.bind(this);
        this.areFieldsValid = this.areFieldsValid.bind(this);
    }

    componentDidMount(){
        //Check if registration data has already been entered before
        this.setState({
            email: localStorage.getItem('registration-email')
        });

        document.currentComponent = this;
    }

    areFieldsValid(){
        let {t} = this.props;

        let invalid = {};
        let errorMessages = {};
        let fieldsError = false;

        if(!this.state.email){
            invalid.email = true;
            errorMessages.email =  t('E-mail is required.');
            fieldsError = true;
        } else {
            if(!isValidEmail(this.state.email)) {
                invalid.email = true;
                errorMessages.email =  t('Please enter a valid email address.');
                fieldsError = true;
            }
        }
        if(!this.state.password){
            invalid.password = true;
            errorMessages.password =  t('Password is required.');
            fieldsError = true;
        } else {
            if(this.state.password.length < 5){
                invalid.password = true;
                errorMessages.password =  t('The password is invalid. It must have at least 5 characters.');
                fieldsError = true;
            }
        }

        this.setState(prev => {
            prev.invalid = invalid;
            prev.errorMessages = errorMessages;

            return prev;
        });

        return fieldsError === false;
    }

    async register(event){
        event.preventDefault();

        try {
            if(!this.areFieldsValid()) return;

            //Save data to local storage
            localStorage.setItem('registration-email', this.state.email);
            localStorage.setItem('registration-password', this.state.password);

            window.location.assign('/registration-add-device-to-room');
        } catch (e) {
            console.error(e);
            this.setState({error: e.message})
        }
    }

    changeInputField(e){
        let name = e.target.name;
        let value = e.target.value;

        this.setState({[name]: value});
    }

    getUrlVars(){
        var vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
            function (m, key, value){
                vars[key] = value;
            });
        return vars;
    }

    goBack(event){
        event.preventDefault();

        this.props.history.goBack();
    }

    render(){
        const {t} = this.props;

        const currentLanguage = this.props.i18n.resolvedLanguage;

        return <div className={'page-registration-email'}>
            {
                get(this.props, 'shared.generalError.visible') && <div className={'alert alert-danger'}>
                    {get(this.props, 'shared.generalError.message')}
                </div>
            }
            <div className={'back-image'}></div>
            <a className="auth-logo" onClick={() => this.props.history.push('/start')}
                href="#">{t('Cleveron')}</a>

            <div className="card auth-card card-login">
                <div className="registration-sidebar">
                    <div className="registration-sidebar-logo"><img src={logoImgMini} alt="Cleveron" /></div>
                    <div className="registration-sidebar-title">{t('Device Setup')}</div>
                </div>
                <div className="card-body">
                    {/*<div className="mobile-header">*/}
                    <div className="mobile_signup_header">
                        <p>{t('Sign up')}</p>
                        <div className="mobile_signup_header_posi">
                            <a onClick={this.goBack.bind(this)}><i className="fa fa-angle-left"></i><span>{t('Back')}</span></a>
                        </div>
                    </div>
                    <div className="m-b-36">
                        <LanguagePicker t={t} i18n={this.props.i18n} />
                    </div>
                    <div className="header">
                        <div className="step-header">
                            <span className="step-title">{t('Step')}  {'1/3:'}</span>
                            <span className="title">{t('Create an account')}</span>
                        </div>
                        <div className="auth-start-logo">
                            <img src={logoImgMini} alt="Cleveron" />
                            {/*<div className="auth-start-title">{t('Device Setup')}</div>*/}
                        </div>
                        {/*<div className="subtitle">{t('Register with your CLEVER Sense device')}</div>*/}
                        {
                            get(this.state, 'error') && <div className={'alert alert-danger'}>
                                {get(this.state, 'error')}
                            </div>
                        }
                    </div>
                    {/*</div>*/}
                    <div className="mobile-rounded-borders">
                        <form className="p-b-32" onSubmit={this.register}>
                            <div className="form-group form-group-email">
                                <label htmlFor="email" className={'label'} style={{width: 'unset'}}>{t('E-mail')}</label>
                                <input type="email"
                                    className={`form-control ${this.state.invalid.email ? 'is-invalid' : ''}`}
                                    id="email"
                                    name={'email'}
                                    aria-describedby=""
                                    placeholder={t('Ex. john@gmail.com')}
                                    value={this.state.email}
                                    onChange={this.changeInputField}
                                />
                                {
                                    !this.state.invalid.email && <small className="form-text help-text">
                                        {t('Please enter your e-mail.')}
                                    </small>
                                }
                                {
                                    this.state.invalid.email && <div className={'invalid-feedback'}>
                                        {this.state.errorMessages.email}
                                    </div>
                                }
                            </div>

                            <div className="form-group form-group-password">
                                <label htmlFor="password" className={'label'} style={{width: 'unset'}}>{t('Password')}</label>
                                <input type="password"
                                    className={`form-control ${this.state.invalid.password ? 'is-invalid' : ''}`}
                                    id="password"
                                    name={'password'}
                                    aria-describedby=""
                                    placeholder={t('Please enter your password')}
                                    value={this.state.password}
                                    onChange={this.changeInputField}
                                />
                                {
                                    !this.state.invalid.password && <small className="form-text help-text">
                                        {t('Password should have at least 5 characters.')}
                                    </small>
                                }
                                {
                                    this.state.invalid.password && <div className={'invalid-feedback'}>
                                        {this.state.errorMessages.password}
                                    </div>
                                }
                            </div>

                            <button type="submit"
                                id={'button-register'}
                                className="btn"
                                onClick={this.register}>
                                {
                                    this.state.loading && <Loader style={{backgroundColor: '#a5d525'}}/>
                                }
                                {
                                    !this.state.loading && t('Continue')
                                }
                            </button>
                        </form>
                    </div>
                    <div className="footer-links">
                        <div style={{marginBottom: 8}}>
                            {t('Have an account?')} &nbsp;
                            <a href={'#'} onClick={() => this.props.history.push(paths.login)}>{t('Sign in')}</a>
                        </div>
                        <div>
                            <a  target={'_blank'}
                                rel="noopener noreferrer"
                                href={`/assets/images/page-start-configuration/Sense_ConnectionGuide_B2C_${currentLanguage?.toUpperCase() ?? 'DE'}.pdf`}>{t('Download the user guide')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

PageRegistrationEmail.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired,
};
/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(PageRegistrationEmail));