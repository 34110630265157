'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {merge, pick} from 'lodash';
import translate from '../../translate';
import {connect} from 'react-redux';
import * as db from '../../../lib/structure';

class EditRoomInformationModal extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            room: props.room
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps){
        this.setState({room: nextProps.room});
    }

    onInputChange(event){
        let name = event.target.name;
        let value = event.target.value;

        if (name === db.Room.TEMP_MIN || name === db.Room.TEMP_MAX || name === db.Room.EMERGENCY_TEMPERATURE) {
            value = Math.round(value * 2) / 2; // only allows multiple of 0.5
            this.setState(prev => {
                prev.room[name] = parseFloat(value);

                return prev;
            });
            return;
        }

        this.setState(prev => {
            prev.room[name] = parseInt(value);

            return prev;
        });
    }

    onSave(){
        this.props.onSave(pick(this.state.room, [
            db.Room.AREA,
            db.Room.HEIGHT,
            db.Room.WINDOWS,
            db.Room.FLOOR
        ]))
            .then(() => $('#editInfoModal .close').click());
    }

    render(){
        const {t} = this.props;

        if (!this.state.room) return '';

        return (
            <div className="modal fade show" id="editInfoModal" tabIndex={-1} role="dialog"
                aria-labelledby="editInfoLabel">
                <div className="modal-dialog" role="document">
                    <div className="modal-content  edit-info">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editInfoLabel">{t('Edit room information')}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                                    <path fill="#222"
                                        d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                </svg>
                            </button>
                        </div>
                        <div className="modal-body">
                            {/*
                                //Add a modal to confirm that they undersdand the implications of this change
                                <div className="form-group">
                                <label>{t('Emergency temperature')}</label>
                                <input type="number"
                                    name={db.Room.EMERGENCY_TEMPERATURE}
                                    value={this.state.room[db.Room.EMERGENCY_TEMPERATURE] || 23}
                                    onChange={this.onInputChange}
                                    step='0.5'
                                    min='15'
                                    max='30'
                                />
                            </div>
                            */}
                            <div className="form-group">
                                <label>{t('Area')}</label>
                                <input type="number"
                                    name={db.Room.AREA}
                                    value={this.state.room[db.Room.AREA]}
                                    onChange={this.onInputChange}/>
                            </div>
                            <div className="form-group">
                                <label>{t('Height')}</label>
                                <input type="number"
                                    name={db.Room.HEIGHT}
                                    value={this.state.room[db.Room.HEIGHT]}
                                    onChange={this.onInputChange}/>
                            </div>
                            <div className="form-group">
                                <label>{t('Window')}</label>
                                <input type="number"
                                    name={db.Room.WINDOWS}
                                    value={this.state.room[db.Room.WINDOWS]}
                                    onChange={this.onInputChange}/>
                            </div>
                            <div className="form-group">
                                <label>{t('Floor')}</label>
                                <input type="number"
                                    name={db.Room.FLOOR}
                                    value={this.state.room[db.Room.FLOOR]}
                                    onChange={this.onInputChange}/>
                            </div>
                            <button type="submit" className="submit-form-btn" onClick={this.onSave}>{t('Save')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

EditRoomInformationModal.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    room: PropTypes.object,
    onSave: PropTypes.func
};

/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(EditRoomInformationModal));