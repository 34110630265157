'use strict';

const cfg = global.cfg || {};

cfg.app = {};

cfg.system = {
    timeUntilDeviceOffline: {
        value: 1,
        unit: 'month'
    },
    timeUntilAutonomousMode: {
        value: 1,
        unit: 'hours'
    },
    timeUntilOfflineSensor: {
        value: 1,
        unit: 'hour'
    }
}

cfg.console = {};

module.exports = cfg;
