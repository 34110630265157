'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../translate';
import {connect} from 'react-redux';
import Parse from 'parse';
import * as db from '../../../lib/structure';
import swal from 'sweetalert';
import {manageError} from '../../../lib/util';
import {merge, pick} from 'lodash';

const compress = new Compress();

class EditRoomDetailsModal extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            file: null,
            fileName: null,
            roomJson: {},
            selectRoomTypeActive: false,
            roomTypes: []
        };

        this.uploadFile = this.uploadFile.bind(this);
        this.onInputChange = this.onInputChange.bind(this);

    }

    componentDidMount(){
        const t = this.props.t;

        this.getRoomTypes();

        compress.attach('#profilePhotoFileUpload', {
            size: 4,
            quality: .75
        }).then((data) => {
            let file = data[0];

            if (file.initialHeightInPx > file.initialWidthInPx) {
                return swal(
                    t('Error'),
                    t('The width of the image should be bigger that it\'s height. If you are using the phone please take the photo with the phone in horizontal position.'),
                    'error'
                );
            }

            this.setState({file: data[0]});
        })
            .catch(manageError);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps){
        if (nextProps.room)
            this.setState({
                roomName: nextProps.room[db.Room.ROOM_NAME],
                roomJson: nextProps.roomJson,
                room: nextProps.room
            });
    }

    onInputChange(event){
        let name = event.target.name;
        let value = event.target.value;

        this.setState(prev => {

            if(name === db.Room.ROOM_NAME){
                prev.roomJson[name] = value;
            } else {
                prev.roomJson[name] = parseInt(value);
            }

            return prev;
        });
    }

    async uploadFile() {
        let t = this.props.t;

        try {
            let saveRoom = async (file) => {
                await this.props.onSave({
                    ...pick(this.state.roomJson, [
                        db.Room.ROOM_NAME,
                        db.Room.AREA,
                        db.Room.HEIGHT,
                        db.Room.WINDOWS,
                        db.Room.FLOOR,
                        db.Room.ROOM_TYPE
                    ]),
                    [db.Room.MAIN_PHOTO]: file
                });
                swal({
                    title: t('Data saved'),
                    text: ' ',
                    icon: 'success',
                    button: [''],
                    timer: 1000
                });
                $('#editInfoRoom .close').click();
                this.props.onRoomDetailSaved();
            }

            if (!this.state.file) return await saveRoom();

            var name = 'photo.png';

            var parseFile = new Parse.File(name, {base64: this.state.file.data});

            let file = await parseFile.save();

            await saveRoom(file);
        } catch (e) {
            return await manageError(e);
        }
    }

    async getRoomTypes(){
        let roomTypes = await new Parse.Query(db.classes.RoomType)
            .find();

        this.setState({roomTypes});
    }

    render(){
        const {t} = this.props;

        return (
            <div className="modal fade" id="editInfoRoom" tabIndex="-1" role="dialog" aria-labelledby="editRoomLabel"
                aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content edit-photo">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editRoomLabel">{t('Edit room details')}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                    <path fill="#222"
                                        d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                </svg>
                            </button>
                        </div>
                        <div className="modal-body">

                            {this.state.roomJson && (

                                <div>
                                    <div className="form-group">
                                        <label>{t('Room name')}</label>
                                        <input type="text"
                                            name={db.Room.ROOM_NAME}
                                            placeholder={t('Enter the room name')}
                                            value={this.state.roomJson[db.Room.ROOM_NAME]}
                                            onChange={this.onInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>{t('Floor')}</label>
                                        <input type="number"
                                            name={db.Room.FLOOR}
                                            value={this.state.roomJson[db.Room.FLOOR]}
                                            onChange={this.onInputChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label>{t('Height')}</label>
                                        <input type="number"
                                            name={db.Room.HEIGHT}
                                            value={this.state.roomJson[db.Room.HEIGHT]}
                                            onChange={this.onInputChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label>{t('Window')}</label>
                                        <input type="number"
                                            name={db.Room.WINDOWS}
                                            value={this.state.roomJson[db.Room.WINDOWS]}
                                            onChange={this.onInputChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label>{t('Area')}</label>
                                        <input type="number"
                                            name={db.Room.AREA}
                                            value={this.state.roomJson[db.Room.AREA]}
                                            onChange={this.onInputChange}/>
                                    </div>

                                    <div className="form-group">
                                        <label>{t('Area')}</label>
                                        <input type="number"
                                            name={db.Room.AREA}
                                            value={this.state.roomJson[db.Room.AREA]}
                                            onChange={this.onInputChange}/>
                                    </div>
                                    
                                    <div className="form-group">
                                        <label>{t('Room type')}</label>
                                        <div className={`select-custom ${this.state.selectRoomTypeActive ? 'active' : ''}`}
                                            style={{maxWidth: '100%'}}
                                            onClick={() => this.setState({
                                                selectRoomTypeActive: !this.state.selectRoomTypeActive
                                            })}
                                        >
                                            <div className="select-custom-status">
                                                {this.state.roomJson[db.Room.ROOM_TYPE] == null ?
                                                    t('Select room type') : t(`class.label.${db.classes.RoomType}.${db.RoomType.NAME}$${this.state.roomJson[db.Room.ROOM_TYPE]}`)}</div>
                                            <ul className="select-custom-list" style={{maxWidth: '100%'}}>
                                                {
                                                    this.state.roomTypes.map(roomType => {
                                                        return <li key={roomType.id}
                                                            name={db.Room.ROOM_TYPE}
                                                            className="select-custom-item"
                                                            style={{maxWidth: '100%'}}
                                                            onClick={() => {
                                                                this.setState(prev => {
                                                                    prev.roomJson[db.Room.ROOM_TYPE] =
                                                                        roomType.get(db.RoomType.NAME);

                                                                    return prev;
                                                                });
                                                            }}
                                                        >{t(`class.label.${db.classes.RoomType}.${db.RoomType.NAME}$${roomType.get(db.RoomType.NAME)}`)}</li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="form-group">
                                <label>{t('Room photo')}</label>
                                <div className="upload">
                                    <input
                                        type="file"
                                        id="profilePhotoFileUpload"
                                        accept="image/*"
                                    />
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 108">
                                        <g fill="none" fillRule="evenodd">
                                            <path fill="#CCC" fillRule="nonzero"
                                                d="M97.5,28.1 L85.7,28.1 L85.7,18.4 C85.7,16.7 84.4,15.4 82.7,15.4 L10.5,15.4 C8.8,15.4 7.5,16.7 7.5,18.4 L7.5,75.1 C7.5,76.8 8.8,78.1 10.5,78.1 L21.2,78.1 L21.2,87.7 C21.2,89.4 22.5,90.7 24.2,90.7 L97.5,90.7 C99.2,90.7 100.5,89.4 100.5,87.7 L100.5,31.1 C100.5,29.4 99.2,28.1 97.5,28.1 Z M13.5,21.4 L79.7,21.4 L79.7,72.1 L13.5,72.1 L13.5,21.4 Z M94.5,84.6 L27.2,84.6 L27.2,78 L82.8,78 C84.5,78 85.8,76.7 85.8,75 C85.8,74.7 85.8,74.5 85.7,74.2 L85.7,34.1 L94.5,34.1 C94.5,34.1 94.5,84.6 94.5,84.6 Z"/>
                                            <path fill="#CCC" fillRule="nonzero"
                                                d="M55.5,42.9 C60.4,42.9 64.4,38.9 64.4,34 C64.4,29.1 60.4,25.1 55.5,25.1 C50.6,25.1 46.6,29.1 46.6,34 C46.6,38.9 50.6,42.9 55.5,42.9 Z M55.5,31.1 C57.1,31.1 58.4,32.4 58.4,34 C58.4,35.6 57.1,36.9 55.5,36.9 C53.9,36.9 52.6,35.6 52.6,34 C52.6,32.4 53.9,31.1 55.5,31.1 Z"/>
                                            <path fill="#CCC" fillRule="nonzero"
                                                d="M63.6,47.8 C63,47.1 62.2,46.7 61.3,46.7 C60.4,46.7 59.5,47.1 59,47.8 L54,54 L42.3,37 C41.7,36.2 40.8,35.7 39.8,35.7 C38.8,35.7 37.9,36.2 37.3,37 L19.8,62.6 C19.2,63.5 19.1,64.7 19.6,65.7 C20.1,66.7 21.1,67.3 22.3,67.3 L49.6,67.3 L57.4,67.3 L73,67.3 C74.2,67.3 75.2,66.6 75.7,65.6 C76.2,64.6 76.1,63.3 75.3,62.4 L63.6,47.8 Z M49.6,61.3 L28,61.3 L39.8,44 L51.7,61.3 L49.6,61.3 Z M59,61.3 L57.6,59.2 L61.4,54.5 L66.8,61.3 L59,61.3 Z"/>
                                            <rect width="108" height="108"/>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            {
                                this.state.file && <div>
                                    {t('Selected file')}: {this.state.file.alt}
                                </div>
                            }
                            <button className="submit-form-btn" onClick={this.uploadFile}>{t('Save')}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

EditRoomDetailsModal.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    room: PropTypes.object,
    roomJson: PropTypes.object,
    onRoomDetailSaved: PropTypes.func,
    onSave: PropTypes.func
};

/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(EditRoomDetailsModal));