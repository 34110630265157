'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {htmlT} from '../../lib/util'

// import fetch from
const translate = (WrappedComponent) => {

    class TranslatedComponent extends React.Component {

        static get component(){
            return WrappedComponent;
        }

        constructor(props){
            super(props);
        }

        render(){
            if (!this.props.i18n.isInitialized) {
                return <div className="loader-container"><span className="loader loader--medium"></span></div>;
            }

            return React.cloneElement(<WrappedComponent {...this.props} />, {
                t: htmlT(this.props.t)
            })
        }
    }

    TranslatedComponent.propTypes = {
        i18n: PropTypes.object,
        t: PropTypes.any
    };

    return withTranslation(null, {withRef: true})(TranslatedComponent);
};

export default translate;
