import React from 'react';
import PropTypes from 'prop-types';
import PropType from 'prop-types';
import * as db from '../../../lib/structure';
import moment from 'moment-timezone';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import {
    getIconFromWifiDb,
    isDeviceAutonomousMode,
    isDeviceOffline,
    roundedBatteryPercentage,
    voltColorIcon,
    voltToPerc,
} from '../../../lib/util';
import swal from 'sweetalert';
import ReactTooltip from 'react-tooltip'
import Loader from '../../loader';
import AddDeviceToRoomModal from '../add-device-to-room-modal';
import plugIcon from '../../../assets/images/plug.svg';
import LowBatteryTooltip from './low-battery-tooltip';
import DeviceOfflineToolTip from './device-offline-tooltip';
import AutonomousModeTooltip from './autonomous-mode-tooltip';
import ReturnDeviceForm from '../return-device-form';
import Parse from 'parse';

const returnDeviceFormUrl = {
    it: 'https://forms.cleveron.ch/cleveronag/form/Formulariodiritorno/formperma/8EkB2zpcCmD1BU3TcWLpaKHrXLi_Uy9SY-m-eurAtqs',
    de: 'https://forms.cleveron.ch/cleveronag/form/ReturnForm/formperma/08Y_3qUGlVUcDIg65WUGtQz9GDLFZS6C7OA_U0ltkHc',
    fr: 'https://forms.cleveron.ch/cleveronag/form/ReturnForm/formperma/08Y_3qUGlVUcDIg65WUGtQz9GDLFZS6C7OA_U0ltkHc',
    en: 'https://forms.cleveron.ch/cleveronag/form/ReturnForm/formperma/08Y_3qUGlVUcDIg65WUGtQz9GDLFZS6C7OA_U0ltkHc'
}

export default class RoomDeviceList extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            devices: this.props.devices,
            devicesWifiValues: [],
            loading: {
                devices: false
            },
            deviceMeasurements: [],
            deviceDetailsOpen: [],
            devicesShowLowBatteryTooltip: [],
            devicesShowOfflineTooltip: [],
            showAutonomousModeTooltip: [],
            deviceForReturn: null,
            deviceForReturnJson: null,
            returnDeviceFormOpened: false
        };

        this.toggleMountFlag = this.toggleMountFlag.bind(this);
        this.getMotorPosition = this.getMotorPosition.bind(this);
        this.openReturnDeviceForm = this.openReturnDeviceForm.bind(this);
    }

    async componentDidMount(){
        let currentHome = localStorage.getItem('buildingSelection');
        let home = await (new Parse.Query(db.classes.Home))
            .select([
                db.Home.SUMMER_MODE
            ])
            .get(currentHome);
        this.setState({home});
    }

    async toggleMountFlag(device){
        const {t} = this.props;
        const hasWritePermission = localStorage.getItem('user:hasWritePermission') === 'true';

        if(!hasWritePermission) throw Error('Modification not permitted. Contact support@cleveron.ch for support.')

        let flag = device.get(db.Device.DEVICE_STATE_FLAG);
        let text = '';
        let title = t('Are you sure?');

        if (flag !== 'in-mount') {
            text = t('Do you want to enable mount mode for this device?');
        }

        try {
            let wantsToggle = await swal({
                title: title,
                text: text,
                buttons: true
            });

            if (wantsToggle) {
                if (flag === 'in-mount') {
                    device.set(db.Device.DEVICE_STATE_FLAG, 'online');
                } else {
                    device.set(db.Device.DEVICE_STATE_FLAG, 'in-mount');
                }
                await device.save();
                await swal({title: t('Success'), text: ' ', icon: 'success', button: [''], timer: 1000});
                this.props.loadDevices();
            }
        } catch (err) {
            console.error(err);
            swal('Error', err.message, 'error');
        }
    }

    getMotorPosition(device){
        let {t} = this.props;
        
        let motorPositionIcon = motorPosition => {
            if(motorPosition > 0){
                return <div className="device-item__icon heating"
                    data-tip={`${t('The radiator is currently heating')} (${t('Motor position')}: ${motorPosition}%)`}
                />;
            }

            return <div className="device-item__icon cooling" data-tip={`${t('Cooling')} (${t('Motor position')}: ${motorPosition}%)`}/>;
        }

        if(!device) return '';

        if (device.get(db.Device.DEVICE_TYP) !== 'therm') return '';

        let motorPosition = device.get(db.Device.MOTOR_POSITION);
        let lastResponseMotorPosition = device.get(db.Device.LAST_RESPONSE_MOTOR_COMMAND_VALUE);

        if(lastResponseMotorPosition != null)
            return motorPositionIcon(lastResponseMotorPosition);

        return motorPositionIcon(motorPosition);
    }

    openReturnDeviceForm(device) {
        this.setState({
            deviceForReturn: device,
            deviceForReturnJson: device.toJSON(),
            returnDeviceFormOpened: true}, () => {
            ($('#returnFormOpener')[0]).click();
        });

    }

    render(){
        const {t} = this.props;

        const language = this.props.i18n.resolvedLanguage;

        /*let numberThermo = 0;
        if(this.state.devices.length > 0)
            numberThermo = this.state.devices
                .filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$THERM)
                .length;*/

        /*console.table(this.state.devices.map(device => {
           let deviceTyp = device.get(db.Device.DEVICE_TYP);
           let volt = device.get(db.Device.BATTERY_VOLTAGE);
           let wifiStrength = device.get(db.Device.WIFI_STRENGTH);
           let lastMeasurementDate = device.get(db.Device.LAST_MEASUREMENT_DATE);
           let stateFlagClass = device.get(db.Device.DEVICE_STATE_FLAG) === 'in-mount' ? ' in-mount' : ' in-mount--off';
           let serial = device.get(db.Device.SERIAL_NUMBER);
           let deviceStateFlag = device.get(db.Device.DEVICE_STATE_FLAG);
           let isInMountMode = deviceStateFlag === 'in-mount' && deviceTyp === db.Device.DEVICE_TYP$THERM;
           let isOffline = isDeviceOffline(device);
           let isAutonomousMode = isDeviceAutonomousMode(device);

           return {
               serial,
               deviceTyp,
               isInMountMode,
               isAutonomousMode,
               isOffline,
               volt,
               wifiStrength,
               stateFlagClass,
               lastMeasurementDate: moment(lastMeasurementDate).diff(moment(), 'days')
           };
        }));*/

        const hasWritePermission = localStorage.getItem('user:hasWritePermission') === 'true';

        return <>
            <div className='add-device-wrapper-mobile'>
                <h2>{this.state.devices.length > 0 && `${this.state.devices.length}`} {t('Devices')}</h2>
                {
                    hasWritePermission && <a
                        className="icon-add-device"
                        data-toggle="modal"
                        data-target="#modal-room-actions"
                    >
                        {t('+ Add new')}
                    </a>
                }
            </div>
            <div className="card card-outer side-card device-list-card">
                <AddDeviceToRoomModal t={t} roomId={this.props.room.id}
                    onAfterSubmitSuccess={() => this.props.loadDevices()}/>

                <div className="card-header">
                    <div className="card-title device-list-card-title">
                        <div className='add-device-wrapper'>
                            <h2>{t('Devices list')} {this.state.devices.length > 0 && `(${this.state.devices.length})`}</h2>
                            {
                                hasWritePermission && <a
                                    className="icon-add-device"
                                    data-toggle="modal"
                                    data-target="#modal-room-actions">
                                    {t('+ Add new device')}
                                </a>
                            }
                        </div>
                    </div>
                </div>
                <div className="device-list">
                    {
                        this.state.loading.devices && <Loader/>
                    }
                    {this.state.devices.length > 0 && this.state.devices.map((device, i) => {
                        let percentage = 0;

                        let deviceTyp = device.get(db.Device.DEVICE_TYP);
                        let volt = device.get(db.Device.BATTERY_VOLTAGE);
                        let wifiStrength = device.get(db.Device.WIFI_STRENGTH);
                        let lastMeasurementDate = device.get(db.Device.LAST_MEASUREMENT_DATE);
                        let stateFlagClass = device.get(db.Device.DEVICE_STATE_FLAG) === 'in-mount' ? ' in-mount' : ' in-mount--off';
                        let serial = device.get(db.Device.SERIAL_NUMBER);
                        let macAddress = device.get(db.Device.MAC_ADDRESS);
                        let deviceStateFlag = device.get(db.Device.DEVICE_STATE_FLAG);
                        let isInMountMode = deviceStateFlag === 'in-mount' && deviceTyp === db.Device.DEVICE_TYP$THERM;
                        let isOffline = isDeviceOffline(device);
                        let isAutonomousMode = isDeviceAutonomousMode(device);
                        let isThermo;
                        let configuredWifiName = device.get(db.Device.CONFIG_WIFI_SSID);
                        let connectedWifiName = device.get(db.Device.CONNECTION_WIFI_SSID);
                        let buildingInSummerMode = this.state.home && this.state.home.get(db.Home.SUMMER_MODE);

                        percentage = voltToPerc(volt);
                        if (deviceTyp === 'sense') percentage = -1;

                        // let hoverText = isInMountMode ? `${t('Disable mount mode')}` : `${t('Enable mount mode')}`;
                        let icon = getIconFromWifiDb(wifiStrength, lastMeasurementDate, device);

                        if(isOffline) icon = 'none';

                        let voltageIcon = null;
                        let deviceTypeIcon = null;
                        let batteryIsLow = false;

                        switch (deviceTyp){
                            case db.Device.DEVICE_TYP$SENSE:
                            case db.Device.DEVICE_TYP$SENSP:
                            case db.Device.DEVICE_TYP$SENCO:
                                voltageIcon = plugIcon;
                                deviceTypeIcon = 'sens';
                                break;
                            case db.Device.DEVICE_TYP$THERM:
                                voltageIcon = voltColorIcon(percentage).icon;
                                batteryIsLow = percentage !== -1 && percentage <= 10;
                                deviceTypeIcon = 'therm';
                                isThermo = true;
                        }

                        if (deviceTyp && deviceTyp.startsWith('router')) {
                            deviceTypeIcon = 'router';
                        }

                        let mountModeHoverText = isInMountMode ?
                            `${t('Mount mode enabled')}` : `${t('Mount mode disabled')}`;

                        let motorPositionIcon = this.getMotorPosition(device);

                        return <div key={i} className={`device-item signal--${icon} ${stateFlagClass} ${batteryIsLow ? 'battery-low' : ''} ${isInMountMode ? 'mount-mode': ''}`}>

                            <div className='device-item__header'>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    {
                                        <div className={`device-type-icon device-type-icon__${deviceTypeIcon}`} />
                                    }

                                    <div className="device-item__title">
                                        {serial}
                                    </div>

                                    <div className="device-item__short-title">
                                        {serial}
                                    </div>
                                </div>

                                <ReactTooltip/>

                                <div className="device-item__icon-list">
                                    {
                                        !isAutonomousMode &&
                                        !isOffline &&
                                        !isInMountMode &&
                                        !buildingInSummerMode &&
                                        motorPositionIcon
                                    }

                                    {
                                        isAutonomousMode && !isOffline && !isInMountMode && <>
                                            <div className="device-item__icon autonomous"
                                                onMouseEnter={() => this.setState(prevState => {
                                                    prevState.showAutonomousModeTooltip[i] = true;
                                                    return {
                                                        showAutonomousModeTooltip: [
                                                            ...prevState.showAutonomousModeTooltip
                                                        ],
                                                    };
                                                })}
                                                onMouseLeave={() => this.setState(prevState => {
                                                    prevState.showAutonomousModeTooltip[i] = false;
                                                    return {
                                                        showAutonomousModeTooltip: [
                                                            ...prevState.showAutonomousModeTooltip
                                                        ],
                                                    };
                                                })}
                                            />
                                            <AutonomousModeTooltip
                                                t={t}
                                                show={this.state.showAutonomousModeTooltip[i]}/>
                                        </>
                                    }

                                    {
                                        isOffline && <div className="device-item__icon"/>
                                    }

                                    {
                                        isInMountMode && <div className="device-item__icon mount-mode" data-tip={mountModeHoverText}/>
                                    }

                                    {isOffline && <div>
                                        <div className="device-item__icon wifi"
                                            onMouseEnter={() => this.setState(prevState => {
                                                prevState.devicesShowOfflineTooltip[i] = true;
                                                return {
                                                    devicesShowOfflineTooltip: [...prevState.devicesShowOfflineTooltip],
                                                };
                                            })}
                                            onMouseLeave={() => this.setState(prevState => {
                                                prevState.devicesShowOfflineTooltip[i] = false;
                                                return {
                                                    devicesShowOfflineTooltip: [...prevState.devicesShowOfflineTooltip],
                                                };
                                            })}/>
                                        <DeviceOfflineToolTip t={t} show={this.state.devicesShowOfflineTooltip[i]} />
                                    </div>}

                                    {!isOffline && <div className="device-item__icon wifi"/> }

                                    {batteryIsLow && <div>
                                        <div className="device-item__icon" style={
                                            {backgroundImage: `url(${voltageIcon})`}
                                        }
                                        onMouseEnter={() => this.setState(prevState => {
                                            prevState.devicesShowLowBatteryTooltip[i] = true;
                                            return {
                                                devicesShowLowBatteryTooltip:
                                                    [...prevState.devicesShowLowBatteryTooltip],
                                            };
                                        })}
                                        onMouseLeave={() => this.setState(prevState => {
                                            prevState.devicesShowLowBatteryTooltip[i] = false;
                                            return {
                                                devicesShowLowBatteryTooltip:
                                                    [...prevState.devicesShowLowBatteryTooltip],
                                            };
                                        })}/>
                                        <LowBatteryTooltip
                                            t={t}
                                            show={this.state.devicesShowLowBatteryTooltip[i]}
                                            percentage={percentage} />
                                    </div>
                                    }

                                    {!batteryIsLow &&
                                        <div className="device-item__icon" style={
                                            {backgroundImage: `url(${voltageIcon})`}
                                        } data-tip={isThermo ? `${t('Battery')}: ${roundedBatteryPercentage(percentage)}%` : null} />
                                    }

                                    <a onClick={(e) => {
                                        e.preventDefault();
                                        this.setState(prevState => {
                                            prevState.deviceDetailsOpen[i] = !prevState.deviceDetailsOpen[i];
                                            return {
                                                deviceDetailsOpen: [...prevState.deviceDetailsOpen],
                                            };
                                        });
                                    }} className={`device-item__icon arrow ${this.state.deviceDetailsOpen[i] ? 'is-active' : ''}`}></a>
                                </div>
                            </div>

                            {this.state.deviceDetailsOpen[i] && (<div className='device-item__details'>
                                <div className="device-item__outer">
                                    <small>{t('Mac address')}: {macAddress}</small>
                                    <small>{t('Serial number')}: {serial}</small>
                                    <small>{t('IP')}: {device.get(db.Device.LOCAL_IP)}</small>
                                    {
                                        connectedWifiName === configuredWifiName && <small>
                                            {t('WiFi SSID')}: {configuredWifiName}
                                        </small>
                                    }
                                    {
                                        connectedWifiName !== configuredWifiName && <>
                                            <small>{t('WiFi configuration on the device')}: {configuredWifiName}</small>
                                            <small>{t('Last connected WiFi')}: {connectedWifiName || ''}</small>
                                        </>
                                    }
                                    {
                                        !isOffline && !isAutonomousMode &&
                                        <small>{t('Last update')}: {moment(lastMeasurementDate).tz('Europe/Zurich').format('DD/MM/YYYY HH:mm')}</small>
                                    }
                                    {
                                        deviceTyp === 'therm' && <div className="device-item-maintenance-mode"
                                            onClick={() => this.toggleMountFlag(device)}
                                        >
                                            <p>{t('Mount mode')}</p>
                                            <input type="checkbox"
                                                className="custom-toggle"
                                                checked={isInMountMode}
                                                disabled={!hasWritePermission}
                                            />
                                            <label className="ml-2 custom-toggle-btn"></label>
                                        </div>
                                    }

                                    <a className='return-device-link'
                                        href={returnDeviceFormUrl[language] || 'https://forms.zoho.eu/cleveronag/form/ReturnForm'}
                                        target={'_blank'} rel="noreferrer">
                                        {t('Return Device')}
                                    </a>
                                </div>
                            </div>
                            )}
                        </div>
                    })}
                </div>
                <a id='returnFormOpener' data-toggle="modal" data-target="#returnDeviceForm" data-backdrop="static" data-keyboard="false"></a>
                <ReturnDeviceForm t={t}
                    device={this.state.deviceForReturn}
                    devices={this.props.devices}
                    deviceJson={this.state.deviceForReturnJson} />
            </div>
        </>
    }
}

RoomDeviceList.propTypes = {
    t: PropTypes.func,
    history: PropTypes.any,
    i18n: PropTypes.any,
    roomId: PropTypes.string,
    room: PropType.object,
    devices: PropTypes.array,
    loadDevices: PropTypes.func
};