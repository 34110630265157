'use strict';
import {merge} from 'lodash';
import {combineReducers} from 'redux';

const initialState = {};

export function createReducer(initState, handlers){
    return function reducer(state, action){
        if (!state) return initState;

        // eslint-disable-next-line no-prototype-builtins
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](state, action);
        } else {
            return state;
        }
    };
}

let shared = createReducer(initialState, {
    'activate-connection-timeout-error': (state) => {
        return merge({}, state, {
            generalError: {
                visible: true,
                message: 'Connection timeout'
            }
        });
    },
    'deactivate-connection-timeout-error': (state) => {
        return merge({}, state, {
            generalError: {
                visible: false,
                message: ''
            }
        });
    }
});

export const portalApp = combineReducers({
    shared
});
