'use strict';

import React, {Fragment} from 'react';
import PropType from 'prop-types';

class PageError extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
        const {t} = this.props;

        return <Fragment>
            <div className="wrapper d-flex align-items-stretch">
                <div className="content">
                    <h1>{t('Error')} {this.props.match.params.code} </h1>
                    <p>{this.props.match.params.message}</p>
                </div>
            </div>
        </Fragment>;
    }
}

PageError.propTypes = {
    match: PropType.any,
    t: PropType.func
};

export default PageError;
