import React, {Component} from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import translate from '../translate';
import * as db from '../../lib/structure';
import {copyACLToObject, toPointerFromId} from '../../lib/util';
import Parse from 'parse';

class AddRoomModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            [db.Room.FLOOR]: 0
        };

        this.addRoom = this.addRoom.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    async addRoom(e){
        e.preventDefault();
        let {t} = this.props;
        try{
            if(!this.state[db.Room.ROOM_NAME] || this.state[db.Room.ROOM_NAME] === '') throw new  Error('Room name is required.');
            if(this.state[db.Room.FLOOR] == null) throw new  Error('Floor number name is required.');

            let currentHomeId = localStorage.getItem('buildingSelection');

            let home = await (new Parse.Query(db.classes.Home)).get(currentHomeId);

            //Create a room (Room 1, 2, 3 ...)
            let roomObject = new Parse.Object(db.classes.Room);
            roomObject.set(db.Room.ROOM_NAME, this.state[db.Room.ROOM_NAME]);
            roomObject.set(db.Room.HOME, toPointerFromId(currentHomeId, db.classes.Home));
            roomObject.set(db.Room.MAIN_PHOTO_URL, 'https://ik.imagekit.io/sh/shutterstock_575394781_xugf8fHgRAXwT.jpg');
            roomObject.set(db.Room.FLOOR, parseInt(this.state[db.Room.FLOOR]));

            copyACLToObject(home, roomObject);

            await roomObject.save();

            await swal({title: t('Success'), text: ' ', icon: 'success', button: [''], timer: 1000});

            $('#modal-dashboard-actions .close').click();

            if(this.props.onAfterSubmitSuccess) this.props.onAfterSubmitSuccess();
        }catch (e) {
            console.error(e);
            swal(t('Error'), t(e.message), 'error');
        }
    }

    onInputChange(event){
        let name = event.target.name;
        let value = event.target.value;

        this.setState(prev => {
            prev[name] = value;

            return prev;
        });
    }

    render(){
        let {t} = this.props;

        return (<div className="modal fade" id="modal-dashboard-actions" tabIndex="-1" role="dialog"
            aria-labelledby="summerModeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content edit-info">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('Add room')}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <path fill="#222"
                                    d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                            </svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>{t('Room name')}</label>
                            <input type="text"
                                name={db.Room.ROOM_NAME}
                                placeholder={t('Enter the room name')}
                                value={this.state[db.Room.ROOM_NAME]}
                                onChange={this.onInputChange}
                            />
                        </div>

                        <div className="form-group">
                            <label>{t('Floor')}</label>
                            <input type="number"
                                name={db.Room.FLOOR}
                                placeholder={t('Ex. -1')}
                                value={this.state[db.Room.FLOOR]}
                                onChange={this.onInputChange}
                            />
                        </div>

                        <div className="actions">
                            <button type="button" className="submit-form-btn" data-dismiss="modal" onClick={this.addRoom}>{t('Add room')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

AddRoomModal.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    onAfterSubmitSuccess: PropTypes.func
};

export default translate(AddRoomModal);