import React, {Component, Fragment} from 'react';
import translate from '../../../translate';
import roomFeedbackSteps from '../../room_feedback_steps';
import PropTypes from 'prop-types';
import {VerifaliaRestClient} from 'verifalia';

const isValidEmail = require('is-valid-email');

const verifalia = new VerifaliaRestClient({
    username: 'fca8acfd21c14cbe84e96ce20a175e63',
    password: 'x3U)vHVgESqU'
});

class TemperatureFeedback extends Component {
    constructor(props) {
        super(props);

        this.state = {
            temperatureFeedback: false,
            temperatureFeedbackValue: null,
            usageFeedback: false,
            usageFeedbackValue: null,
            email: localStorage.getItem('room-feedback-email'),
            validEmail: !!localStorage.getItem('room-feedback-email'),
            emailTouched: !!localStorage.getItem('room-feedback-email'),
            emailLoading: false,
            validatedEmail: localStorage.getItem('room-feedback-email'),
            validationEmailTexts: [],
            sent: {
                temperatureFeedback: false,
                usageFeedback: false
            }
        };

        this.temperatureFeedbackBlock = React.createRef();
        this.usageFeedbackBlock = React.createRef();
        this.textAreaCommentBlock = React.createRef();
        this.textResultBlock = React.createRef();
        this.buttonBlock = React.createRef();

        this.validateEmail = this.validateEmail.bind(this);
    }

    componentDidMount() {
        //$('#field-4').blur(function(event) {
        //    event.target.checkValidity();
        //}).bind('invalid', function(event) {
        //    document.forms['temp-usage-form'].reportValidity();
        //    setTimeout(function() { $(event.target).focus();}, 50);
        //});
    }

    async validateEmail(e){
        if(this.state.validEmail && this.state.validatedEmail === this.state.email) return;
        let email;

        if(e) email = e.target.value;
        else email = this.state.email;

        if(email === '' || email == null)
            return this.setState({validEmail: false, emailTouched: true});

        this.setState({emailLoading: true});

        if(!isValidEmail(email))
            return this.setState({validEmail: false, emailTouched: true, emailLoading: false, validationEmailTexts: [
                'The email is invalid. Please check that is contains the symbol "@" and that the domain refers to a valid mailbox.',
            ]});

        let result = await verifalia
            .emailValidations
            .submit(email, true);

        let classification = result.entries[0].classification;

        if(classification === 'Deliverable' || classification === 'Risky') {
            this.setState({validEmail: true, emailTouched: true, emailLoading: false, validatedEmail: email});
            localStorage.setItem('room-feedback-email', email);
        } else {
            this.setState({validEmail: false, emailTouched: true, emailLoading: false, validationEmailTexts: [
                'The email is invalid. Please check that is contains the symbol "@" and that it refers to a valid mailbox.',
                'Contact our <a href="mailto:support@cleveron.ch">support</a> if your e-mail was not recognised as valid.'
            ]});
        }
    }

    render() {
        const {t} = this.props;

        let emailField = () => {
            return <div className={`input-field ${!this.state.validEmail  && this.state.emailTouched ? 'error' : ''}`}>
                <label htmlFor="field-4">{t('E-mail')}<span>*</span>
                </label>
                <div className="input-field__input">
                    <input className="input" type="email" name="text-field" placeholder={t('Please enter your e-mail')} id="field-4" required
                        value={this.state.email}
                        onChange={(e) => {
                            this.setState({email: e.target.value});
                        }}
                        onKeyPress={async (e) => {
                            if(e.key === 'Enter') {
                                e.preventDefault();
                                await this.validateEmail(e);
                            }
                        }}
                        onBlur={this.validateEmail}
                    />
                    {
                        !this.state.validEmail && !this.state.emailLoading && this.state.emailTouched && <svg className="icon icon-error-status input-notification__icon">
                            <use href="/assets/images/sprite.svg#error-status"></use>
                        </svg>
                    }
                    {
                        this.state.emailLoading && <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                            viewBox="0 0 100 100" enableBackground="new 0 0 100 100" className={'icon-loader'}>
                            <path fill="grey" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                <animateTransform
                                    attributeName="transform"
                                    attributeType="XML"
                                    type="rotate"
                                    dur="1s"
                                    from="0 50 50"
                                    to="360 50 50"
                                    repeatCount="indefinite" />
                            </path>
                        </svg>
                    }
                </div>
                {
                    this.state.email !== '' && !this.state.validEmail  && this.state.emailTouched && <small className="input-notification">
                        {this.state.validationEmailTexts.map((message, i) => <span key={i}>{t(message)}&nbsp;</span>)}
                    </small>
                }

                {
                    (!this.state.email || !this.state.validEmail) && <div className="input-field__input mt-10">
                        <input
                            type={'button'}
                            onClick={async (e) => {
                                e.preventDefault();
                                await this.validateEmail();
                            }}
                            className="btn btn--primary"
                            name="send_data"
                            value={t('Verify')}
                        />
                    </div>
                }
            </div>
        }
        let temperatureFeedback = () => {
            let options = roomFeedbackSteps.temperature_feedback.options;

            let onChangeTemperatureFeedback = (value) => {
                this.setState({
                    temperatureFeedbackValue: value,
                    temperatureFeedback: true
                }, () => {
                    this.usageFeedbackBlock.current.scrollIntoView({behavior: 'smooth', block: 'center'})
                });
            }

            return <Fragment>
                <div ref={this.temperatureFeedbackBlock}>
                    <h5>{t(roomFeedbackSteps.temperature_feedback.title)}</h5>
                </div>
                <div className="room-feedback__header">
                    <fieldset className="room-feedback__fields">
                        <div className="radio-label">
                            <input className="radio-label__input" type="radio"
                                name="name-temp-feedback-opt-1" id="temp-feedback-opt-1"
                                value={'temp-feedback-opt-1'}
                                checked={options.TOO_COLD === this.state.temperatureFeedbackValue}
                                onChange={() => onChangeTemperatureFeedback(options.TOO_COLD)}
                            />
                            <label className="radio-label__main" htmlFor="temp-feedback-opt-1"
                                onChange={() => onChangeTemperatureFeedback(options.TOO_COLD)}>{t('Too cold')}
                            </label>
                        </div>
                        <div className="radio-label">
                            <input className="radio-label__input" type="radio"
                                name="name-temp-feedback-opt-2" id="temp-feedback-opt-2"
                                value={'temp-feedback-opt-2'}
                                checked={options.COMFORTABLE === this.state.temperatureFeedbackValue}
                                onChange={() => onChangeTemperatureFeedback(options.COMFORTABLE)}
                            />
                            <label className="radio-label__main" htmlFor="temp-feedback-opt-2"
                                onChange={() => onChangeTemperatureFeedback(options.COMFORTABLE)}
                            >{t('Comfortable')}</label>
                        </div>
                        <div className="radio-label">
                            <input className="radio-label__input" type="radio"
                                name="name-temp-feedback-opt-3" id="temp-feedback-opt-3"
                                value={'temp-feedback-opt-3'}
                                checked={options.TOO_HOT === this.state.temperatureFeedbackValue}
                                onChange={() => onChangeTemperatureFeedback(options.TOO_HOT)}
                            />
                            <label className="radio-label__main" htmlFor="temp-feedback-opt-3"
                                onChange={() => onChangeTemperatureFeedback(options.TOO_HOT)}
                            >{t('Too hot')}</label>
                        </div>
                    </fieldset>
                    <div className="room-feedback__aside">
                        <svg className="icon icon-ice-crystal room-feedback__icon">
                            <use
                                href="/assets/images/sprite.svg#ice-crystal"></use>
                        </svg>
                    </div>
                </div>
            </Fragment>
        }
        let usageFeedback = () => {
            let options = roomFeedbackSteps.usage_feedback.options;

            let onChangeUsageFeedback = (value) => {
                this.setState({
                    usageFeedbackValue: value,
                    usageFeedback: true
                }, () => {
                    this.buttonBlock.current.scrollIntoView({behavior: 'smooth', block: 'center'})
                });
            }

            return <div className={'animate__animated animate__fadeInDown animate__fast'} ref={this.usageFeedbackBlock}>
                <div><h5>{t(roomFeedbackSteps.usage_feedback.title)}</h5></div>
                <fieldset className={'room-feedback__header '}>
                    <div className="room-feedback__fields">
                        <div className="radio-label">
                            <input className="radio-label__input" type="radio"
                                name="name-usage-opt-1" id="usage-opt-1"
                                checked={options.SHORT_USAGE === this.state.usageFeedbackValue}
                                value={'usage-opt-1'}
                                onChange={() => onChangeUsageFeedback(options.SHORT_USAGE)}
                            />
                            <label className="radio-label__main" htmlFor="usage-opt-1"
                                onChange={() => onChangeUsageFeedback(options.SHORT_USAGE)}
                            >1h</label>
                        </div>
                        <div className="radio-label">
                            <input className="radio-label__input" type="radio"
                                name="name-usage-opt-2" id="usage-opt-2"
                                value={'usage-opt-2'}
                                checked={options.MEDIUM_USAGE === this.state.usageFeedbackValue}
                                onChange={() => onChangeUsageFeedback(options.MEDIUM_USAGE)}
                            />
                            <label className="radio-label__main" htmlFor="usage-opt-2"
                                onChange={() => onChangeUsageFeedback(options.MEDIUM_USAGE)}
                            >4h</label>
                        </div>
                        <div className="radio-label">
                            <input className="radio-label__input" type="radio"
                                name="name-usage-opt-3" id="usage-opt-3"
                                value={'usage-opt-3'}
                                checked={options.LONG_USAGE === this.state.usageFeedbackValue}
                                onChange={() => onChangeUsageFeedback(options.LONG_USAGE)}
                            />
                            <label className="radio-label__main" htmlFor="usage-opt-3"
                                onChange={() => onChangeUsageFeedback(options.LONG_USAGE)}
                            >8h</label>
                        </div>
                    </div>
                    <div className="room-feedback__aside">

                    </div>
                </fieldset>
            </div>
        }
        let textArea = () => {
            return <div className="input-field animate__animated animate__fadeInDown animate__fast"
                ref={this.textAreaCommentBlock}>
                <div className="input-field__input">
                    <textarea
                        className="textarea" rows="5" name="user_feedback"
                        placeholder={t('Leave your feedback')}
                        value={this.state.text}
                        onChange={e => {
                            this.setState({text: e.target.value})
                        }}
                    ></textarea>
                </div>
            </div>;
        }

        return <form id='temp-usage-form' style={{
            background: 'white'
        }}
        className={'informer__feedback room-feedback'}
        onSubmit={() => {
            
            this.props.continueNextStep('temperature-usage-feedback', this.state)
        }}
        name="temp-usage-form">
            {emailField()}

            {this.state.validEmail && this.state.emailTouched && temperatureFeedback()}

            {this.state.validEmail && this.state.temperatureFeedback && usageFeedback()}

            {this.state.validEmail && this.state.temperatureFeedback && this.state.usageFeedback && textArea()}

            {
                this.state.validEmail &&
                this.state.temperatureFeedback &&
                this.state.usageFeedback && <input ref={this.buttonBlock}
                    className="btn btn--primary"
                    type="submit" name="send_data"
                    value={'Send'}
                />
            }
        </form>;
    }
}

TemperatureFeedback.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    continueNextStep: PropTypes.func.isRequired
};

export default translate(TemperatureFeedback);