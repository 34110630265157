import React, {Component, Fragment} from 'react';
import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import Footer from '../footer';
import translate from '../translate';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {get, groupBy, merge, sum} from 'lodash';
import Chart from 'chart.js';
import moment from 'moment';
import swal from 'sweetalert';
import Parse from 'parse';
import db from '../../lib/structure';
import {addCloseButtonToDatepicker, redirectIfNotLogged, toPointerFromId} from '../../lib/util';
import i18next from '../i18n';
import addIcon from '../../assets/images/ic_add.svg';
import uploadIcon from '../../assets/images/ic_upload.svg';
import calendarIcon from '../../assets/images/ic_calendar.svg';
import fileIcon from '../../assets/images/ic_file.svg';
import downloadIcon from '../../assets/images/ic_download.svg';
import deleteIcon from '../../assets/images/ic_delete_grey.svg';
import structureLocalStorage from "../../lib/localStorageStructure";

export class FuelHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            consumptionUnit: 'liter',
            consumptionValue: 0,
            cost: 0,
            heatingType: '',
            dateFrom: moment(),
            dateTo: moment(),
            measurements: [],
            selectedMeasurement: '',
            file: null,
            fileName: null,
            withCleveron: true,
            dataWithCleveron: [],
            dataWithoutCleveron: [],
            years: [],
            measurementChart: '',
            maxFuel: 0
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.saveButtonPressed = this.saveButtonPressed.bind(this);
        this.verifyInput = this.verifyInput.bind(this);
        this.getFuelMeasurements = this.getFuelMeasurements.bind(this);
        this.updateChartData = this.updateChartData.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.initChart = this.initChart.bind(this);
        this.initDatePicker = this.initDatePicker.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }

    async getFuelMeasurements() {
        try {
            let homeId = localStorage.getItem('buildingSelection');

            let query = new Parse.Query(db.classes.FuelMeasurement);
            query.equalTo(db.FuelMeasurement.BUILDING, toPointerFromId(homeId, 'Home'));
            query.notEqualTo(db.FuelMeasurement.DELETED, true);
            query.ascending(db.FuelMeasurement.START_DATE);
            query.limit(200);
            let measurements = await query.find();

            this.setState({measurements});
        } catch (err) { console.error(err); }

    }

    async componentDidMount() {
        try {
            await redirectIfNotLogged(this.props.history);
            await this.getFuelMeasurements();
            let {years, dataWithCleveron, dataWithoutCleveron} = this.updateChartData();
            this.initDatePicker();
            this.initChart(years, dataWithCleveron, dataWithoutCleveron);
        } catch (err) { 
            console.error(err);
        }

        i18next.on('languageChanged', this.handleLanguageChange);
    }

    initDatePicker() {
        $('#date-picker-from').datepicker({
            todayHighlight: true,
            format: 'dd M yyyy',
            autoclose: true,
            maxViewMode: 0,
            forceParse: false,
            language: this.props.i18n.resolvedLanguage,
            weekStart: 1
        })
            .on('changeDate', (e) => {
                let date = moment(e.date);
                this.setState({dateFrom: this.state.dateFrom
                    .year(date.year()).month(date.month()).date(date.date())});
            }).on('show', (e) => {
                addCloseButtonToDatepicker(e.currentTarget.id, this.props.t);
            });

        $('#date-picker-to').datepicker({
            todayHighlight: true,
            format: 'dd M yyyy',
            autoclose: true,
            maxViewMode: 0,
            forceParse: false,
            language: this.props.i18n.resolvedLanguage,
            weekStart: 1
        })
            .on('changeDate', (e) => {
                let date = moment(e.date);
                this.setState({dateTo: this.state.dateTo
                    .year(date.year()).month(date.month()).date(date.date())});
            }).on('show', (e) => {
                addCloseButtonToDatepicker(e.currentTarget.id, this.props.t);
            });
    }

    componentWillUnmount(){
        i18next.off('languageChanged', this.handleLanguageChange);
    }

    handleLanguageChange(){
        $('#date-picker-from').datepicker('destroy');
        $('#date-picker-to').datepicker('destroy');

        this.initDatePicker();
    }

    initChart(years, dataWithCleveron, dataWithoutCleveron ) {
        const {t} = this.props;

        dataWithCleveron = this.state.dataWithCleveron || dataWithCleveron;
        dataWithoutCleveron = this.state.dataWithoutCleveron || dataWithoutCleveron;
        years = this.state.years || years;

        if (this.state.measurementChart) {
            this.state.measurementChart.destroy();
        }

        let measurements = this.state.measurements.map(measurement => measurement.get(db.FuelMeasurement.LITER));
        let maxFuel = Math.max.apply(null, measurements);
        let maxFuelIncreased = (maxFuel * 40/100) + maxFuel;

        let normalConsumptionLabel = t('Normal consumption');
        let withCleveronLabel = t('With CLEVERON');

        const measurementChartCtx = document.getElementById('measurement-chart').getContext('2d');
        const measurementChart = new Chart(measurementChartCtx, {
            type: 'bar',
            data: {
                labels: years,
                datasets: [{
                    label: normalConsumptionLabel,
                    data: dataWithoutCleveron,
                    backgroundColor: '#ffb643',
                    maxBarThickness: 50,
                    showLine: false,
                    barPercentage: 1,
                    categoryPercentage: 0.4,
                }, {
                    label: withCleveronLabel,
                    data: dataWithCleveron,
                    backgroundColor: '#99d754',
                    maxBarThickness: 50,
                    showLine: false,
                    barPercentage: 1,
                    categoryPercentage: 0.4,
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false,
                            color: '#f3f3f3',
                            drawBorder: false,
                            zeroLineColor: 'transparent'
                        },
                        ticks: {
                            beginAtZero: true,
                            fontColor: '#999',
                            lineWidth: 50,
                            fontSize: 13,
                            stepSize: 500
                        },
                    }],
                    yAxes: [{
                        gridLines: {
                            color: '#f3f3f3',
                            drawBorder: false,
                            zeroLineColor: 'transparent'
                        },
                        ticks: {
                            beginAtZero: true,
                            fontColor: '#999',
                            lineWidth: 50,
                            fontSize: 13,
                            //stepSize: Math.round(maxFuelIncreased / 5),
                            //max: maxFuelIncreased
                        },
                    }]
                },
                legend: {
                    align: 'end',
                    labels: {
                        fontColor: '#222',
                        fontSize: 16,
                        fontStyle: 'bold',
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
                    }
                },
                hover: {
                    animationDuration: 0
                },
                animation: {
                    duration: 0,
                    onComplete: function() {
                        let chartInstance = this.chart,
                            ctx = chartInstance.ctx;

                        ctx.font = Chart.helpers.fontString(
                            Chart.defaults.global.defaultFontSize,
                            Chart.defaults.global.defaultFontStyle,
                            Chart.defaults.global.defaultFontFamily);
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'bottom';
                        //ctx.fillStyle = 'red';

                        this.data.datasets.forEach(function(dataset, i) {
                            var meta = chartInstance.controller.getDatasetMeta(i);

                            meta.data.forEach(function(bar, index) {
                                var data = dataset.data[index];
                                ctx.fillStyle = bar._model.backgroundColor;
                                ctx.fillText(data, bar._model.x, bar._model.y - 5);
                            });

                        });
                    }
                },
                /*onResize: function(chart, size) {
                  chart.options.legend.display = size.width > 768;
                  chart.update();
                },*/
            },
            plugins: {
                beforeInit: doResponsive,
                resize: doResponsive
            }
        });

        this.setState({measurementChart});

        function doResponsive(instance) {
            //let width = instance.chart.width;
            //instance.options.legend.display = width > 500;

            let width = (window.innerWidth > 0) ? window.innerWidth : screen.width;

            if (width > 500) {
                instance.options.legend.display = true;
            } else {
                instance.options.legend.display = false;
            }
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        const {measurements} = this.state;
        let prevMeasurements = get(prevState, 'measurements');
        if (prevMeasurements.length !== measurements.length) {
            this.updateChartData();
        }

    }

    updateChartData() {
        const {measurements} = this.state;

        let allData = [];
        measurements.forEach(measurement => {
            let year = moment(measurement.get(db.FuelMeasurement.START_DATE)).format('YYYY');
            let withCleveron = measurement.get(db.FuelMeasurement.WITH_CLEVERON)
            let liters = measurement.get(db.FuelMeasurement.LITER);
            let kwh =  measurement.get(db.FuelMeasurement.KWH);
            let consumptionUnit = measurement.get(db.FuelMeasurement.CONSUM)

            allData.push({year, withCleveron, liters, kwh, consumptionUnit});
        });

        let groupedByYear = groupBy(allData, 'year');
        let years = [];
        let dataWithCleveron = [];
        let dataWithoutCleveron = [];
        let consumptionUnit = 'liter';

        for (const [key, value] of Object.entries(groupedByYear)) {
            years.push(key);
            let wc = [];
            let woc = [];
            value.forEach(value => {
                if (value.liters && value.withCleveron) wc.push(value.liters);
                if (value.liters && !value.withCleveron) woc.push(value.liters);
                if (value.kwh && value.withCleveron) wc.push(value.kwh);
                if (value.kwh && !value.withCleveron) woc.push(value.kwh);
                if(value.consumptionUnit === 'kwh') consumptionUnit = 'kwh';
            });

            dataWithCleveron.push(sum(wc) ? sum(wc) : '');
            dataWithoutCleveron.push(sum(woc) ? sum(woc) : '');
        }

        this.setState({years, dataWithCleveron, dataWithoutCleveron, consumptionUnit});

        return {years, dataWithCleveron, dataWithoutCleveron};
    }

    handleInputChange(e) {
        if(e.target.name === 'liter') {
            if (e.target.value !== 'checked') this.setState({consumptionUnit: 'liter'});
        }
        if (e.target.name === 'kwh') {
            if (e.target.value !== 'checked') this.setState({consumptionUnit: 'kwh'});
        }
        if (e.target.name === 'consumption') {
            this.setState({consumptionValue: e.target.value});
        }
        if (e.target.name === 'heatingType') {
            this.setState({heatingType: e.target.value});
        }
        if (e.target.name === 'cost') {
            this.setState({cost: e.target.value});
        }
        if (e.target.name === 'csvUpload') {
            let file = e.target.files[0];
            let fileName = e.target.files[0].name;
            this.setState({file, fileName});
        }
        if (e.target.name === 'withCleveron') {
            this.state.withCleveron ?  this.setState({withCleveron: false}) : this.setState({withCleveron: true});
        }

    }

    async saveButtonPressed() {
        try {
            let validInput = this.verifyInput();
            if (validInput) {
                await this.createDBMeasurement();
                this.updateChartData();
            }
            await this.getFuelMeasurements();
            this.initChart();
        } catch (err) { console.error(err); }

    }

    async createDBMeasurement() {
        const {t} = this.props;
        const {consumptionUnit, consumptionValue, cost, heatingType, dateFrom, dateTo, withCleveron} = this.state;
        let selectedHomeId = localStorage.getItem('buildingSelection');

        let home = await (new Parse.Query(db.classes.Home)).get(selectedHomeId);

        try {

            let FuelMeasurement = Parse.Object.extend(db.classes.FuelMeasurement);
            let fuelMeasurement = new FuelMeasurement();
            let swalText = t('Added Fuel Measurement');

            if (consumptionUnit === 'liter') fuelMeasurement.set(db.FuelMeasurement.LITER, parseFloat(consumptionValue));
            if (consumptionUnit === 'kwh') fuelMeasurement.set(db.FuelMeasurement.KWH, parseFloat(consumptionValue));

            fuelMeasurement.set(db.FuelMeasurement.PRICE, parseFloat(cost));
            fuelMeasurement.set(db.FuelMeasurement.HEATING_TYPE, heatingType);
            fuelMeasurement.set(db.FuelMeasurement.START_DATE, dateFrom.toDate());
            fuelMeasurement.set(db.FuelMeasurement.END_DATE, dateTo.toDate());
            fuelMeasurement.set(db.FuelMeasurement.BUILDING, toPointerFromId(home.id, 'Home'));
            fuelMeasurement.set(db.FuelMeasurement.WITH_CLEVERON, withCleveron)

            await fuelMeasurement.save();
            swal(swalText, '', 'success');

        } catch (err) {
            console.error(err);
            swal('Error', err.message, 'error');
        }
    }

    verifyInput(){
        let {dateFrom, dateTo, consumptionValue, cost} = this.state;
        const {t} = this.props;

        if (cost < 0) {
            let messageText = t('Cost can\'t be negative');
            swal(messageText, '', 'error');
        }
        if (consumptionValue < 0) {
            let messageText = t('Consumption can\'t be negative');
            swal(messageText, '', 'error');
        }
        if (moment(dateTo).isBefore(moment(dateFrom))) {
            let messageText = t('Invalid Date');
            swal(messageText, '', 'error');
            return false;
        }

        return true;
    }

    deleteIconClicked(measurement) {
        this.setState({selectedMeasurement: measurement});
    }

    async deleteConfirmed() {
        const {t} = this.props;
        let message = t('Measurement deleted')
        let query = new Parse.Query(db.classes.FuelMeasurement);
        let measurement = await query.get(this.state.selectedMeasurement.id)
        measurement.set(db.FuelMeasurement.DELETED, true);
        await measurement.save();
        swal(message, '', 'success');
        await this.getFuelMeasurements();
        this.initChart();

    }

    async uploadFile() {
        const {t} = this.props;
        let {file, fileName} = this.state;

        if (!file) {
            let message = t('Please select a file');
            swal(message, '', 'error');
            return;
        }

        try {
            let message = t('Uploaded file successfully');
            let errMessage = t('Failed to extract any data from file');

            let selectedBuildingId = localStorage.getItem(structureLocalStorage.BUILDING_SELECTION);

            fileName = fileName.replace('(', '');
            fileName = fileName.replace(')', '');
            fileName = fileName.replace(' ', '');
            fileName = fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');

            let parseFile = new Parse.File(fileName, file);
            parseFile = await parseFile.save();
            let result = await Parse.Cloud.run('extractCSVData', {csvUrl: parseFile.url(), buildingId: selectedBuildingId});
            await this.getFuelMeasurements();
            if (result.length > 0) {
                swal(message, '', 'success');
            } else {
                swal(errMessage, '', 'error');
            }

        } catch (err) {
            console.error(err);
            swal(err, '', 'error');
            this.setState({file: null, fileName: null});
        }

        this.setState({file: null, fileName: null});
    }

    render() {
        const {t} = this.props;
        const {consumptionUnit, measurements, withCleveron} = this.state;
        const language = this.props.i18n.resolvedLanguage;
        const dateFormat = 'DD MMMM YYYY';

        let consumptionPlaceholder = '', selectedMeasurement = '', cost = '', liters = '',
            heatingType = '', dateFrom = '', dateTo = '';
        if (consumptionUnit === 'liter') consumptionPlaceholder = t('Liter') + ' / kWh';
        if (consumptionUnit === 'kwh') consumptionPlaceholder = 'kWh';
        if (this.state.selectedMeasurement) {
            selectedMeasurement = this.state.selectedMeasurement;
            cost = selectedMeasurement.get(db.FuelMeasurement.PRICE);
            liters = selectedMeasurement.get(db.FuelMeasurement.LITER);
            heatingType = selectedMeasurement.get(db.FuelMeasurement.HEATING_TYPE);
            dateFrom = moment(selectedMeasurement.get(db.FuelMeasurement.START_DATE)).format(dateFormat);
            dateTo = moment(selectedMeasurement.get(db.FuelMeasurement.END_DATE)).format(dateFormat);

        }

        const hasWritePermission = localStorage.getItem('user:hasWritePermission') === 'true';
        const homeCountry = localStorage.getItem(`home:${db.Home.COUNTRY}`);

        return (
            <Fragment>
                <div className="wrapper d-flex align-items-stretch faq-page">
                    <Sidebar {...this.props}/>
                    <div className="content">
                        <Navbar {...this.props}/>
                        <div className="inner user-management-container">
                            <Breadcrumbs {...this.props} elements={[
                                {
                                    link: '/dashboard',
                                    name: t('Home')
                                },
                                {
                                    link: null,
                                    name: t('Consumption history')
                                }
                            ]}/>
                            <h1>{t('Consumption history')}</h1>
                            <div className="grid-cols flex-column fuelHistory">

                                <div className="content-fuel">
                                    <div className="content-fuel__heading">{t('Measurement')}</div>
                                    <span className="content__heading-info">{consumptionPlaceholder}</span>
                                    <div className="measurement-chart table-responsive">
                                        <canvas id="measurement-chart" width="400" height="150"></canvas>
                                    </div>
                                </div>

                                {/*<div className="card card-outer">*/}
                                <div className="data-section">
                                    <div className="content-fuel mt-3">
                                        <div className="content-fuel__heading data-section__header">{t('Data')}</div>
                                        {
                                            hasWritePermission && <div className="data-section__controls">
                                                <a className="data-section__control d-md-none" data-toggle="modal" data-target="#addNewMeasureModal" href="javascript:void(0)"
                                                    data-modal-win-trigger="measurement">
                                                    <img className='icon icon-upload data-section__control-icon' src={addIcon}/>
                                                    <div className="data-section__control-text">{t('New')}</div>
                                                </a>
                                                <a className="data-section__control d-none d-md-inline-block" data-toggle="modal" data-target="#addNewMeasureModal" href="javascript:void(0)"
                                                    data-modal-win-trigger="measurement">
                                                    <img className='icon icon-upload data-section__control-icon' src={addIcon}/>
                                                    <div className="data-section__control-text">{t('Add new measure')}</div>
                                                </a>
                                                <a className="data-section__control d-none d-md-inline-block" data-toggle="modal" data-target="#importMeasurementModal" href="javascript:void(0)"
                                                    data-modal-win-trigger="import-measurement">
                                                    <img className='icon icon-upload data-section__control-icon' src={uploadIcon}/>
                                                    <div className="data-section__control-text">{t('Import measurements from CSV')}</div>
                                                </a>
                                            </div>
                                        }

                                        { measurements.length === 0 &&
                                            <p>{t('No measurements available')}</p>
                                        }
                                        { measurements.length > 0 &&
                                        <table className="data-table table">
                                            <thead>
                                                <tr>
                                                    <th>{t('Consumption')}</th>
                                                    <th>{t('Cost')}&nbsp;
                                                        {
                                                            homeCountry === 'Germany' && <span>EUR</span>
                                                        }
                                                        {
                                                            homeCountry !== 'Germany' && <span>CHF</span>
                                                        }
                                                    </th>
                                                    <th>{t('Type of heating')}</th>
                                                    <th>{t('Date From')}</th>
                                                    <th>{t('Date To')}</th>
                                                    <th>{t('Action')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* eslint-disable max-len */}

                                                {
                                                    measurements.map((measurement => {
                                                        let consumptionValue = '';
                                                        if (measurement.get(db.FuelMeasurement.LITER) != null) consumptionValue = measurement.get(db.FuelMeasurement.LITER) + ' L'
                                                        if (measurement.get(db.FuelMeasurement.KWH) != null) consumptionValue = measurement.get(db.FuelMeasurement.KWH) + ' kWh'
                                                        return (
                                                            <tr key={measurement.id}>
                                                                <td>{consumptionValue}</td>
                                                                <td>{measurement.get(db.FuelMeasurement.PRICE)}</td>
                                                                <td>{measurement.get(db.FuelMeasurement.HEATING_TYPE)}</td>
                                                                <td>{moment(measurement.get(db.FuelMeasurement.START_DATE)).format(dateFormat)}</td>
                                                                <td>{moment(measurement.get(db.FuelMeasurement.END_DATE)).format(dateFormat)}</td>
                                                                <td className='delete-btn-small'>
                                                                    <button className="edit-modal-btn"
                                                                        data-toggle="modal"
                                                                        data-target="#deleteEvent"
                                                                        id="deleteButton"
                                                                        onClick={() => this.deleteIconClicked(measurement)}
                                                                    >
                                                                        <img src={deleteIcon}/>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }))
                                                }
                                                {/* eslint-enable max-len */}
                                            </tbody>
                                        </table>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                        <Footer/>
                    </div>
                </div>

                <div className="modal fade" id="addNewMeasureModal" tabIndex="-1" role="dialog"
                    aria-labelledby="summerModeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content edit-info">
                            <div className="modal-header">
                                <h5 className="modal-title" id="summerModeModalLabel">{t('Add new Measurement')}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <path fill="#222"
                                            d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="modal-body fuelHistory">
                                <div className="modal-body">
                                    <div className="text">
                                        <div className="checkbox-custom">
                                            <input type="checkbox" name="withCleveron" id="cb" checked={withCleveron} onChange={this.handleInputChange}/>
                                            <label htmlFor="cb">{t('With Cleveron')}</label>
                                        </div>

                                        <div className="row calendar-event-radio">
                                            <label className="radio__label">
                                                {t('Liter')}
                                                <input
                                                    type="radio"
                                                    name="liter"
                                                    aria-describedby="Heat"
                                                    value="liter"
                                                    onChange={this.handleInputChange}
                                                    checked={consumptionUnit === 'liter'}

                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="radio__label">
                                                kWh
                                                <input
                                                    type="radio"
                                                    name="kwh"
                                                    aria-describedby="kWh"
                                                    value="kwh"
                                                    onChange={this.handleInputChange}
                                                    checked={consumptionUnit === 'kwh'}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>

                                        <div className="form-group">
                                            <label>{t('Consumption (Liter or kWh)')}</label>
                                            <input type="number" min="0" placeholder={consumptionPlaceholder} name="consumption" onChange={this.handleInputChange}/>
                                        </div>

                                        <div className="form-group">
                                            <label>{t('Cost')}</label>
                                            <input type="number" min="0" placeholder={t('CHF')} name="cost" onChange={this.handleInputChange}/>
                                        </div>

                                        <div className="form-group">
                                            <label>{t('Type of heating')}</label>
                                            <input type="text" placeholder={t('eg. Oil, Gas, etc.')} name="heatingType" onChange={this.handleInputChange}/>
                                        </div>

                                        <div className="form-group">
                                            <label>{t('Start')}</label>
                                            <div className="datepicker-outer-full-width">
                                                <input type="text" className="datepicker-input" readOnly id="date-picker-from" value={this.state.dateFrom.locale(language).format(dateFormat)}/>
                                                <img id="calendar-ico-start" src={calendarIcon} alt="calendarIcon"/>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>{t('End')}</label>
                                            <div className="datepicker-outer-full-width">
                                                <input type="text" className="datepicker-input" readOnly id="date-picker-to" value={this.state.dateTo.locale(language).format(dateFormat)}/>
                                                <img id="calendar-ico-end" src={calendarIcon} alt="calendarIcon"/>
                                            </div>
                                        </div>

                                        <div className="actions">
                                            <button className="submit-form-btn" data-dismiss="modal" onClick={this.saveButtonPressed}>{t('Save')}</button>
                                            <button className="cancel-form-btn" data-dismiss="modal">{t('Cancel')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="importMeasurementModal" tabIndex="-1" role="dialog"
                    aria-labelledby="summerModeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content edit-info">
                            <div className="modal-header">
                                <h5 className="modal-title" id="summerModeModalLabel">{t('Import Measurements from CSV')}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <path fill="#222"
                                            d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="modal-body fuelHistory">
                                <div className="form-group">

                                    <div className="input-files">
                                        <div className="input-files__file">
                                            <img className='icon icon-file input-files__icon' src={fileIcon}/>
                                            <span className="input-files__filename">{t('Download sample file')}</span>
                                            <a className="input-files__download" href={'/assets/files/FuelConsumptionTemplate.csv'} download={'FuelConsumptionTemplate.csv'}>
                                                <img className='icon icon-download input-files__download-icon' src={downloadIcon}/>
                                            </a>
                                        </div>
                                    </div>

                                    <label>{t('Upload space')}</label>
                                    <div className="upload">
                                        <input
                                            type="file"
                                            id="profilePhotoFileUpload"
                                            accept=".xls, xlsx., .csv,application/msword"
                                            name="csvUpload"
                                            onChange={this.handleInputChange}
                                        />
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 108">
                                            <g fill="none" fillRule="evenodd">
                                                <path fill="#CCC" fillRule="nonzero"
                                                    d="M97.5,28.1 L85.7,28.1 L85.7,18.4 C85.7,16.7 84.4,15.4 82.7,15.4 L10.5,15.4 C8.8,15.4 7.5,16.7 7.5,18.4 L7.5,75.1 C7.5,76.8 8.8,78.1 10.5,78.1 L21.2,78.1 L21.2,87.7 C21.2,89.4 22.5,90.7 24.2,90.7 L97.5,90.7 C99.2,90.7 100.5,89.4 100.5,87.7 L100.5,31.1 C100.5,29.4 99.2,28.1 97.5,28.1 Z M13.5,21.4 L79.7,21.4 L79.7,72.1 L13.5,72.1 L13.5,21.4 Z M94.5,84.6 L27.2,84.6 L27.2,78 L82.8,78 C84.5,78 85.8,76.7 85.8,75 C85.8,74.7 85.8,74.5 85.7,74.2 L85.7,34.1 L94.5,34.1 C94.5,34.1 94.5,84.6 94.5,84.6 Z"/>
                                                <path fill="#CCC" fillRule="nonzero"
                                                    d="M55.5,42.9 C60.4,42.9 64.4,38.9 64.4,34 C64.4,29.1 60.4,25.1 55.5,25.1 C50.6,25.1 46.6,29.1 46.6,34 C46.6,38.9 50.6,42.9 55.5,42.9 Z M55.5,31.1 C57.1,31.1 58.4,32.4 58.4,34 C58.4,35.6 57.1,36.9 55.5,36.9 C53.9,36.9 52.6,35.6 52.6,34 C52.6,32.4 53.9,31.1 55.5,31.1 Z"/>
                                                <path fill="#CCC" fillRule="nonzero"
                                                    d="M63.6,47.8 C63,47.1 62.2,46.7 61.3,46.7 C60.4,46.7 59.5,47.1 59,47.8 L54,54 L42.3,37 C41.7,36.2 40.8,35.7 39.8,35.7 C38.8,35.7 37.9,36.2 37.3,37 L19.8,62.6 C19.2,63.5 19.1,64.7 19.6,65.7 C20.1,66.7 21.1,67.3 22.3,67.3 L49.6,67.3 L57.4,67.3 L73,67.3 C74.2,67.3 75.2,66.6 75.7,65.6 C76.2,64.6 76.1,63.3 75.3,62.4 L63.6,47.8 Z M49.6,61.3 L28,61.3 L39.8,44 L51.7,61.3 L49.6,61.3 Z M59,61.3 L57.6,59.2 L61.4,54.5 L66.8,61.3 L59,61.3 Z"/>
                                                <rect width="108" height="108"/>
                                            </g>
                                        </svg>
                                    </div>
                                    {
                                        this.state.fileName && <div>
                                            {t('Selected file')}: {this.state.fileName}
                                        </div>
                                    }
                                    <button className="submit-form-btn" onClick={this.uploadFile} data-dismiss="modal">{t('Save')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Delete Modal*/}
                <div className="modal fade" id="deleteEvent" tabIndex="-1" role="dialog"
                    aria-labelledby="deleteEventLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content edit-info">
                            <div className="modal-header">
                                <h5 className="modal-title"
                                    id="deleteEventLabel">{t('Are you sure you want to delete this measurement?')}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                        viewBox="0 0 18 18">
                                        <path fill="#222"
                                            d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="text">
                                    <table className="delete-event-card mb-2">
                                        <tbody>
                                            <tr>
                                                <td>{t('Consumption (Liters)')}:</td>
                                                <td>{liters}</td>
                                            </tr>
                                            <tr>
                                                <td>{t('Cost')} <span>(CHF)</span>:</td>
                                                <td>{cost}</td>
                                            </tr>
                                            <tr>
                                                <td>{t('Type of heating')}:</td>
                                                <td>{heatingType}</td>
                                            </tr>
                                            <tr>
                                                <td>{t('Date From')}:</td>
                                                <td>{dateFrom}</td>
                                            </tr>
                                            <tr>
                                                <td>{t('Date To')}:</td>
                                                <td>{dateTo}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="actions">
                                    <button type="submit" className="submit-form-btn" data-dismiss="modal"
                                        onClick={(e) => this.deleteConfirmed(e)}>{t('Yes')}</button>
                                    <button className="cancel-form-btn" data-dismiss="modal">{t('Cancel')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

FuelHistory.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired
};

const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(FuelHistory));