import React, {Component} from 'react';
import Breadcrumbs from '../breadcrumbs';
import Footer from '../footer';
import translate from '../translate';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {merge} from 'lodash';
import Parse from 'parse';
import i18next from '../i18n';
import moment from 'moment';
import {initializeParse} from '../../lib/parse-utils';
import logoImg from '../../assets/images/logo.png';
import paths from '../../lib/paths';

export class PageQrCodePortalDiscoverCleveron extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        try {
            await initializeParse(Parse);

            this.setState({loading: true});

        } catch (err) {
            console.error(err);
        }
        this.setState({loading: false});
    }

    changeLanguage(language) {
        i18next.changeLanguage(language);
        moment.locale(language);
        document.cookie = 'i18next=' + language;
    }

    render() {
        const {t} = this.props;

        let roles = [
            'Collaborator',
            'Teacher',
            'Student',
            'Guest'
        ];

        return (
            <div className="wrapper d-flex align-items-stretch room-report-container">
                {/* eslint-disable max-len */}
                <div className="content">
                    <nav
                        className="navbar navbar-expand-lg navbar-expand-md navbar-expand-sm navbar-expand-xs navbar-light">
                        <img className="logo__img img-responsive" src={logoImg} alt="logo"
                            title="" onClick={() => {
                                this.props.history.push(
                                    paths.roomReport.replace(
                                        ':id', localStorage.getItem('roomFeedbackRoomId')
                                    )
                                );
                            }}/>
                        <div className="container-fluid">
                            <ul className="nav navbar-nav ml-auto">
                                <li className="nav-item dropdown dropdown-custom language-dropdown
                             d-flex align-items-center">
                                    <a className="nav-link dropdown-toggle" href="#" id="languageDropdown"
                                        role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {this.props.i18n.resolvedLanguage}
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="languageDropdown">
                                        <a className={`dropdown-item ${this.props.i18n.resolvedLanguage === 'en' ? 'selected' : ''}`}
                                            href="#"
                                            onClick={() => this.changeLanguage('en')}>{t('English')}</a>
                                        <a className={`dropdown-item ${this.props.i18n.resolvedLanguage === 'it' ? 'selected' : ''}`}
                                            href="#"
                                            onClick={() => this.changeLanguage('it')}>{t('Italian')}</a>
                                        <a className={`dropdown-item ${this.props.i18n.resolvedLanguage === 'fr' ? 'selected' : ''}`}
                                            href="#"
                                            onClick={() => this.changeLanguage('fr')}>{t('French')}</a>
                                        <a className={`dropdown-item ${this.props.i18n.resolvedLanguage === 'de' ? 'selected' : ''}`}
                                            href="#"
                                            onClick={() => this.changeLanguage('de')}>{t('German')}</a>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </nav>
                    <div className="inner">
                        <div className="container-fluid">
                            <Breadcrumbs {...this.props} elements={[
                                {
                                    link: paths.roomReport.replace(':id', localStorage.getItem('roomFeedbackRoomId')),
                                    name: t('Room report page')
                                },
                                {
                                    link: null,
                                    name: t('Discover CLEVERON')
                                }
                            ]}/>

                            <h1>{t('Which of the following roles would suit you?')}</h1>

                            {
                                roles.map((role, i) => {
                                    return <article className="report content-new room-feedback" key={i} onClick={() => {
                                        localStorage.setItem('roomFeedbackUserType', role);
                                        this.props.history.push(paths.roomFeedbackDiscoverCleveronFaq)
                                    }}>
                                        <div className="report__value">{t(role)}</div>
                                        <div
                                            className="report__desc feedback-text"></div>
                                        <div
                                            className="report__desc feedback-text-mobile"></div>
                                        <svg className="report__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24">
                                            <path fill="#222" stroke="#222"
                                                d="M15.2211553,11.9515632 L15.1880729,11.9216918 L7.71302149,4.54135523 C7.65995928,4.48881862 7.58898106,4.48881862 7.53969709,4.54135523 C7.48939396,4.5911601 7.48939396,4.65266369 7.53916878,4.69843255 L14.9357319,12 L14.5752714,12.355831 L7.53969709,19.3010452 C7.48939396,19.35085 7.48939396,19.4123536 7.53969709,19.4586448 C7.56564502,19.4843357 7.60387918,19.5 7.62635929,19.5 C7.65959703,19.5 7.67999414,19.491345 7.71351873,19.4581531 L15.1875756,12.0787998 C15.2196485,12.0470447 15.2272727,12.0294309 15.2272727,12 C15.2272727,11.9687978 15.2194984,11.9501961 15.2211553,11.9515632 Z"
                                                opacity=".6"/>
                                        </svg>
                                    </article>
                                })
                            }

                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}

PageQrCodePortalDiscoverCleveron.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired,
    match: PropTypes.any
};

const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(PageQrCodePortalDiscoverCleveron));