import React from 'react';
import PropType from 'prop-types';
import {countNullValues, round} from '../../../lib/util';
import Parse from 'parse';
import * as db from '../../../lib/structure';
import chroma from 'chroma-js';
import {get} from 'lodash';
import i18next from '../../i18n';
import iconSuccess from '../../../assets/images/ic_success.svg';
import iconDanger from '../../../assets/images/ic_danger.svg';
import iconError from '../../../assets/images/ic_error.svg';
import Loader from '../../loader';

const TOO_MUCH_EMPTY_VALUES_COUNT = 100;

export default class RoomTemperatureChart extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            numberNullValues: 0
        };

        this.prepareMonthlyAverageTemperatureReport = this.prepareMonthlyAverageTemperatureReport.bind(this);
        this.getAverageTemperatureMatrix = this.getAverageTemperatureMatrix.bind(this);
        this.getHomeReport = this.getHomeReport.bind(this);
        this.renderMatrix = this.renderMatrix.bind(this);
        this.loadAllTheHomeReportPhrases = this.loadAllTheHomeReportPhrases.bind(this);
        this.createCodePhraseMap = this.createCodePhraseMap.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }

    componentDidMount() {
        i18next.on('languageChanged', this.handleLanguageChange);
    }

    componentWillUnmount() {
        i18next.off('languageChanged', this.handleLanguageChange);
    }

    async componentDidUpdate(prevProps){
        if (this.props.homeReport === prevProps.homeReport) return;

        await this.prepareMonthlyAverageTemperatureReport();
    }

    handleLanguageChange(){
        this.prepareMonthlyAverageTemperatureReport();
    }

    colorByTemperature(value){
        if(!value) {
            return 'grey';
        }

        if(value === 'N/A') {
            return 'grey';
        }

        let scale = chroma.scale(['#93baff','#e3efff', '#ffa161'])
            .domain([8, 30] , 7, 'log')
            .gamma(1.2);

        let color;

        try {
            color = scale(value).hex();

            return color;
        } catch (e) {
            return 'grey';
        }
    }

    createCodePhraseMap(homeReportPhrases){
        let mapCodePhraseMap = {};

        homeReportPhrases.forEach(homeReportPhrase => {
            mapCodePhraseMap[homeReportPhrase.get(db.HomeReportPhrase.CODE)] = {
                it: homeReportPhrase.get(db.HomeReportPhrase.TEXT_IT),
                de: homeReportPhrase.get(db.HomeReportPhrase.TEXT_DE),
                fr: homeReportPhrase.get(db.HomeReportPhrase.TEXT_FR),
                en: homeReportPhrase.get(db.HomeReportPhrase.TEXT_EN)
            }
        });

        return mapCodePhraseMap;
    }

    loadAllTheHomeReportPhrases(){
        let query = new Parse.Query(db.classes.HomeReportPhrase);
        query.limit(10000);

        return query.find();
    }

    countNullValues(roomData){
        let count = 0;
        [0,1,2,3,4,5,6].forEach(day => {
            let dayReport  =  roomData[this.getDay(day)];

            if(dayReport == null){
                count += 24;
                return;
            }

            [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23].forEach(hour => {

                if(dayReport[hour] == null){
                    count++;

                }

            });
        });

        return count;
    }

    async prepareMonthlyAverageTemperatureReport(){
        const homeReport = this.props.homeReport;
        if (!homeReport) {
            return;
        }

        const hourlyTemperatureAverage = homeReport.get(db.RoomMonthlyReport.HOURLY_TEMPERATURE_AVERAGE);
        const averageTemperatureMatrix = this.getAverageTemperatureMatrix(hourlyTemperatureAverage);

        const numberNullValues = countNullValues(averageTemperatureMatrix);

        this.setState({numberNullValues});

        this.renderMatrix(averageTemperatureMatrix);
    }

    async getComment(homeReport, roomData) {
        let homeReportPhreases = await this.loadAllTheHomeReportPhrases();
        let mapCodePhraseMap = this.createCodePhraseMap(homeReportPhreases);

        let roomMin = this.props.room.get(db.Room.TEMP_MIN)
        let roomMax = this.props.room.get(db.Room.TEMP_MAX);
        let numberOfSensors = this.props.room.get(db.Room.NUMBER_SENSE) +
            this.props.room.get(db.Room.NUMBER_SENCO) + this.props.room.get(db.Room.NUMBER_SENSP);
        let roomHasSensor = numberOfSensors > 0;

        let successIconPath = iconSuccess;
        let warningIconPath = iconDanger;
        let dangerIconPath = iconError;
        let icon = successIconPath;

        let selectedPhrases = homeReport.get(db.HomeReport.ROOM_COMMENTS);
        let comments = get(selectedPhrases, `[${roomData.roomId}].comments`) || [];

        let nullValues = this.countNullValues(roomData);

        if(comments.length === 0){

            if(nullValues > 48) {
                comments.push(7);
                comments.push(1);
            } else
                comments.push(3);

            if(roomMin > 18){
                comments.push(4);
            }
            if(roomMax > 23){
                comments.push(5);
            }

            if(!roomHasSensor)
                comments.push(11);
        }

        if(comments.indexOf(0) >= 0) {
            icon = successIconPath;
            if(comments.length > 0) comments = removeItemAll(comments, 0);
        } else if(comments.indexOf(1) >= 0){
            icon = warningIconPath;
            if(comments.length > 0) comments = removeItemAll(comments, 1);
        } else if(comments.indexOf(2) >= 0) {
            icon = dangerIconPath;
            if (comments.length > 0) comments = removeItemAll(comments, 2);
        }

        function removeItemAll(arr, value) {
            var i = 0;
            while (i < arr.length) {
                if (arr[i] === value) {
                    arr.splice(i, 1);
                } else {
                    ++i;
                }
            }
            return arr;
        }

        let commentText = comments.map(commentCode => mapCodePhraseMap[commentCode][this.props.i18n.resolvedLanguage]).join(' ');

        return {commentText, icon, comments};
    }

    renderMatrix(averageTemperatureMatrix, commentText, icon, comments){
        if (icon && document.querySelector('.avgTemp__icon')) {
            document.querySelector('.avgTemp__icon').src = icon;
        }

        if (commentText && document.querySelector('.avgTemp__comment')) {
            document.querySelector('.avgTemp__comment').innerHTML = commentText;
        }

        if (averageTemperatureMatrix) {
            document.querySelectorAll('.avgTemp .avgTemp__cells').forEach(el => {
                const rows = Array.from(el.children);
                rows.forEach((v, i) => {
                    const dark = Array.from(v.children);
                    dark.forEach((cell, num) => {
                        cell.children[0].style.background = this.colorByTemperature(averageTemperatureMatrix[i][num]);
                        $(cell).append('<span class=\'tempr temprAvg\' style="display: block; color:black">' + (averageTemperatureMatrix[i][num] == null ? 'N/A' : averageTemperatureMatrix[i][num].toFixed(1) + '°') + '</span>');
                    });
                });
            });
        }
    }

    async getHomeReport(){
        const homeId = localStorage.getItem('buildingSelection');
        const homeQuery = (new Parse.Query(db.classes.Home))
            .equalTo(db.Home.ENERGY_REPORT_COMPLIANT, true)
            .equalTo(db.Home.OBJECT_ID, homeId);

        const homeReport = await (new Parse.Query(db.classes.HomeReport))
            .matchesQuery(db.HomeReport.HOME, homeQuery)
            .include(db.HomeReport.HOME)
            .addDescending([db.HomeReport.YEAR, db.HomeReport.MONTH])
            .select([
                db.HomeReport.OBJECT_ID,
                db.HomeReport.HOME,
                db.HomeReport.YEAR,
                db.HomeReport.MONTH,
                db.HomeReport.DATA,
                db.HomeReport.ROOM_COMMENTS
            ])
            .first();

        return homeReport;
    }

    getDay(day){
        return day;
    }

    getAverageTemperatureMatrix(hourlyTemperatureAverage){
        var preset = [
            /*eslint-disable */
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Sunday
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Monday
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Tuesday
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Wednesday
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Thursday
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Friday
            [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], //Saturday
            /*eslint-enable */
        ];

        if(!hourlyTemperatureAverage) return preset;

        return hourlyTemperatureAverage;
    }

    render(){
        const {t, homeReport, loading} = this.props;

        let monthsLabel = {
            1: t('January'),
            2: t('February'),
            3: t('March'),
            4: t('April'),
            5: t('May'),
            6: t('June'),
            7: t('July'),
            8: t('August'),
            9: t('September'),
            10: t('October'),
            11: t('November'),
            12: t('December')
        };

        let reportDataAvailable = true;
        if (!homeReport || this.state.numberNullValues >= TOO_MUCH_EMPTY_VALUES_COUNT) {
            reportDataAvailable = false;
        }

        let title = '';
        const isoWeek = homeReport?.get(db.RoomWeeklyReport.ISO_WEEK_NUMBER);

        if(isoWeek != null)
            title = t('Weekly average temperature');
        else
            title = t('Monthly average temperature');

        return <div className="full-w">
            <div className="card card-outer full-w program-card">
                <div className="card-header">
                    <div className="card-title d-flex">
                        <h2>
                            {title}
                        </h2>
                    </div>
                    <span>

                    </span>
                </div>
                {loading && <Loader />}
                {
                    !loading && reportDataAvailable && <div className="avgTemp">
                        <div className="avgTemp__outer">
                            <div className="avgTemp__days">
                                <span>{t('Sun')}</span>
                                <span>{t('Mon')}</span>
                                <span>{t('Tue')}</span>
                                <span>{t('Wed')}</span>
                                <span>{t('Thu')}</span>
                                <span>{t('Fri')}</span>
                                <span>{t('Sat')}</span>
                            </div>
                            <div className="avgTemp__cells">
                                <div className="avgTemp__tr">
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                </div>
                                <div className="avgTemp__tr">
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                </div>
                                <div className="avgTemp__tr">
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                </div>
                                <div className="avgTemp__tr">
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                </div>
                                <div className="avgTemp__tr">
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                </div>
                                <div className="avgTemp__tr">
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                </div>
                                <div className="avgTemp__tr">
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                    <div className="avgTemp__item"><b></b></div>
                                </div>
                            </div>
                        </div>
                        <div className="avgTemp__hours">
                            <span className="non-hour"></span>
                            {/* eslint-disable react/jsx-no-literals */}
                            <span className="static">0:00</span>
                            <span className="static">3:00</span>
                            <span className="static">6:00</span>
                            <span className="static">9:00</span>
                            <span className="static">12:00</span>
                            <span className="static">15:00</span>
                            <span className="static">18:00</span>
                            <span className="static">21:00</span>
                            <span className="static last">00:00</span>
                            {/* eslint-enable react/jsx-no-literals */}
                        </div>

                        {
                            !loading && reportDataAvailable && <div className="doc__icon__wrapper">
                                <img src=""  alt="" className="doc__icon__pic avgTemp__icon"/>
                                <h2 className="doc__icon__text avgTemp__comment"></h2>
                            </div>
                        }
                    </div>
                }

                {
                    !loading && !reportDataAvailable && <div className="text-center" id="co2-no-report-data">
                        {t('No report data available')}
                    </div>
                }
            </div>
        </div>

    }
}

RoomTemperatureChart.propTypes = {
    t: PropType.func,
    roomId: PropType.string,
    room: PropType.object,
    homeReport: PropType.object,
    i18n: PropType.object,
    loading: PropType.bool
};