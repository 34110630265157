import React from 'react';
import PropType from 'prop-types';
import arrowUpImage from '../../../assets/images/ic_arrow_up.svg'
import arrowDownImage from '../../../assets/images/ic_arrow_down.svg'
import incrementImage from '../../../assets/images/ic_increment.svg'
import decrementImage from '../../../assets/images/ic_decrement.svg'

export default class ProgramTemperature extends React.Component {

    constructor(props){
        super(props);

        this.state = {
        };

        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
    }

    increment(e) {
        e.preventDefault();
        this.props.onChange('increment', this.props.type);
    }

    decrement(e) {
        e.preventDefault();
        this.props.onChange('decrement', this.props.type);
    }

    render() {
        const {edit, t, type, value} = this.props;

        return (
            <div className={`program-temperature program-temperature-type-${type} ${edit ? 'editing' : ''}`}>
                <div className='program-temperature-title'>
                    {type === 'min' && t('Temp minimum')}
                    {type === 'max' && t('Temp maximum')}
                </div>
                <div className={'program-temperature-values program-temperature-controls'}>
                    {edit && <div className='program-temperature-decrement'>
                        <a href="#" onClick={(e) => this.decrement(e)}>
                            <img src={decrementImage} />
                        </a>
                    </div>}
                    {!edit && type === 'min' && <div className='program-temperature-min-icon'>
                        <img src={arrowDownImage} />
                    </div>}
                    {!edit && type === 'max' && <div className='program-temperature-max-icon'>
                        <img src={arrowUpImage} />
                    </div>}
                    <div className='program-temperature-value-wrapper'>

                        <div className='program-temperature-value'>
                            {`${value == null ? 'N/A' : value.toFixed(1)}°C`}
                        </div>
                    </div>
                    {edit && <div className='program-temperature-increment'>
                        <a href="#" onClick={(e) => this.increment(e)}>
                            <img src={incrementImage} />
                        </a>
                    </div>}
                </div>
            </div>
        );
    }
}

ProgramTemperature.propTypes = {
    t: PropType.func,
    edit: PropType.bool,
    type: PropType.string,
    value: PropType.number,
    onChange: PropType.func,
};