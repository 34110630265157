'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {merge} from 'lodash';
import translate from '../../translate';
import {connect} from 'react-redux';
import RoomFMFeedback from './room-fm-feedback';

class RoomFMFeedbackList extends React.Component {
    constructor(props){
        super(props);

        this.state = {};
    }

    render() {
        const {t, feedbacks} = this.props;
        if (!feedbacks) return '';

        return (
            <div className="card card-outer full-w">
                <div className="card-header">
                    <div className="card-title">
                        <h2>{t('Feedback')} {feedbacks.length > 0 &&
                            <>({feedbacks.length} {' '}
                                {feedbacks.length === 1 && t('request')}
                                {feedbacks.length > 1 && t('requests')})
                            </>}
                        </h2>
                    </div>
                </div>
                <div className='room-fm-feedback-list'>
                    {
                        feedbacks.length === 0 && <h5>{t('No feedbacks to show')}</h5>
                    }
                    {
                        feedbacks.length > 0 && feedbacks.map((feedback, index) =>
                            <RoomFMFeedback
                                onChange={this.props.onChange}
                                key={index}
                                feedback={feedback}
                                t={t}
                                index={index} />)
                    }
                </div>
            </div>
        )
    }
}

RoomFMFeedbackList.propTypes = {
    t: PropTypes.any,
    feedbacks: PropTypes.array,
    onChange: PropTypes.func
};

/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(RoomFMFeedbackList));