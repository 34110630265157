'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {merge} from 'lodash';
import {connect} from 'react-redux';
import {manageError, redirectIfNotLogged} from '../../lib/util';
import translate from '../translate';
import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import DiagnosticsStarter from './diagnostics-starter';
import DiagnosticsResult from './diagnostics-result';
import moment from 'moment';

class SystemDiagnosticsPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            loading: false,
            diagnosticsResult: null
        };

        this.runDiagnostics = this.runDiagnostics.bind(this);
    }

    async componentDidMount(){
        await redirectIfNotLogged(this.props.history);
    }

    async runDiagnostics(){
        try {
            this.setState({
                loading: true,
                diagnosticsResult: null
            });

            await new Promise(resolve => setTimeout(resolve, 5000));

            // eslint-disable-next-line no-unused-vars
            let fakeItems = [
                {
                    'title': 'Battery',
                    'description': 'Battery is almost or completely discharged',
                    'icon': 'danger', //info,success,warning,danger
                    'infoMessage': 'Please double check room and it\'s setup',
                    'warningMessage': '',
                    'buttons': [
                        {label: 'Contact support team', url: 'http://www.google.com', action: ''},
                        {label: 'Another button', url: 'http://www.cleveron.ch', action: ''}
                    ],
                    'table': {
                        'title': '',
                        'headers': ['Room Name', 'Area', 'Floor', 'Battery'],
                        'columns': ['field1', 'field2', 'field3', 'field4'],  //field_names
                        'rows': [ //data to show for every columns
                            {
                                'field1': 'Room Name',
                                'field2': '3',
                                'field3': '4',
                                'field4': 'Battery Info'
                            },
                            {
                                'field1': 'Room Name2',
                                'field2': '4',
                                'field3': '1',
                                'field4': 'Battery Info'
                            },
                            {
                                'field1': 'Room Name3',
                                'field2': '7',
                                'field3': '1',
                                'field4': 'Battery Info'
                            },
                            {
                                'field1': 'Room Name8',
                                'field2': '3',
                                'field3': '4',
                                'field4': 'Battery Info'
                            },
                            {
                                'field1': 'Room Name5',
                                'field2': '3',
                                'field3': '4',
                                'field4': 'Battery Info'
                            },
                            {
                                'field1': 'Room Name9',
                                'field2': '3',
                                'field3': '4',
                                'field4': 'Battery Info'
                            }
                        ],
                    }
                },
                {
                    'title': 'WiFi',
                    'description': 'Configuration looks good',
                    'icon': 'success', //info,success,warning,danger
                    'infoMessage': '',
                    'warningMessage': '',
                    'table': {
                        'title': '',
                        'headers': ['Room Name', 'Area', 'Floor', 'Battery'],
                        'columns': ['Field1', 'Field2'],  //field_names
                        'rows': [ //data to show for every columns
                            {
                                'field1': '',
                                'field2': ''
                            }
                        ],
                        'buttons': [{label: '', url: '', action: ''}]
                    }
                },
                {
                    'title': 'Savings',
                    'description': 'Average savings. device looks good',
                    'icon': 'success', //info,success,warning,danger
                    'infoMessage': '',
                    'warningMessage': '',
                    'table': {
                        'title': '',
                        'headers': ['Room Name', 'Area', 'Floor', 'Battery'],
                        'columns': ['Field1', 'Field2'],  //field_names
                        'rows': [ //data to show for every columns
                            {
                                'field1': '',
                                'field2': ''
                            }
                        ],
                        'buttons': [{label: '', url: '', action: ''}]
                    }
                },
                {
                    'title': 'Requests',
                    'description': 'There were many tickets for your room',
                    'icon': 'warning', //info,success,warning,danger
                    'infoMessage': 'Please double check room and it\'s setup',
                    'warningMessage': 'Please double check room and it\'s setup',
                    'buttons': [
                        {label: 'Contact support team', url: 'http://www.google.com', action: ''},
                        {label: 'Another button', url: 'http://www.cleveron.ch', action: ''}
                    ],
                    'table': {
                        'title': '',
                        'headers': ['Room Name', 'Area', 'Floor', 'Battery'],
                        'columns': ['field1', 'field2', 'field3', 'field4'],  //field_names
                        'rows': [ //data to show for every columns
                            {
                                'field1': 'Room Name',
                                'field2': '3',
                                'field3': '4',
                                'field4': 'Battery Info'
                            },
                            {
                                'field1': 'Room Name2',
                                'field2': '4',
                                'field3': '1',
                                'field4': 'Battery Info'
                            },
                            {
                                'field1': 'Room Name3',
                                'field2': '7',
                                'field3': '1',
                                'field4': 'Battery Info'
                            },
                            {
                                'field1': 'Room Name8',
                                'field2': '3',
                                'field3': '4',
                                'field4': 'Battery Info'
                            },
                            {
                                'field1': 'Room Name5',
                                'field2': '3',
                                'field3': '4',
                                'field4': 'Battery Info'
                            },
                            {
                                'field1': 'Room Name9',
                                'field2': '3',
                                'field3': '4',
                                'field4': 'Battery Info'
                            }
                        ],
                    }
                },
            ];

            this.setState({
                diagnosticsResult: {
                    'title': 'Building Diagnostics Results',
                    'resultText': 'System is running smooth.',
                    'generatedAt': moment().format('DD/MM/YYYY HH:mm'),
                    'items': [
                        {
                            'title': 'Battery',
                            'description': 'Battery level is very good.',
                            'icon': 'success', //info,success,warning,danger
                            'infoMessage': '',
                            'warningMessage': '',
                        },
                        {
                            'title': 'WiFi',
                            'description': 'Configuration looks good',
                            'icon': 'success', //info,success,warning,danger
                            'infoMessage': '',
                            'warningMessage': ''
                        },
                        {
                            'title': 'Energy savings',
                            'description': 'Average savings per room are very good. Rooms are correctly managed by CLEVERON.',
                            'icon': 'success', //info,success,warning,danger
                            'infoMessage': '',
                            'warningMessage': ''
                        },
                        {
                            'title': 'Feedbacks',
                            'description': 'There were some feedbacks for your rooms. Check it out!',
                            'icon': 'warning', //info,success,warning,danger
                            'infoMessage': '',
                            'warningMessage': '',
                            'buttons': [
                                {label: 'Contact support team', url: 'http://www.google.com', action: ''},
                                {label: 'Another button', url: 'http://www.cleveron.ch', action: ''}
                            ],
                            'table': {
                                'title': '',
                                'headers': ['Room Name', 'Area', 'Floor', 'Feedback'],
                                'columns': ['field1', 'field2', 'field3', 'field4'],  //field_names
                                'rows': [
                                    {
                                        'field1': 'D24',
                                        'field2': '25',
                                        'field3': '1',
                                        'field4': 'Always slightly too warm. Please reduce the temperature of 1° please.'
                                    }
                                ],
                            }
                        },
                    ]
                }
            });
        } catch (err) {
            manageError(err);
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    render(){
        const {t} = this.props;

        return <div className={'system-diagnostics'}>
            <div className="wrapper d-flex align-items-stretch">
                <Sidebar {...this.props}/>
                <div className="content">
                    <Navbar {...this.props}/>
                    <div className="inner">
                        <Breadcrumbs {...this.props} elements={[
                            {
                                link: null,
                                name: 'Home'
                            },
                            {
                                link: '/system-diagnostics',
                                name: t('System Diagnostics')
                            }
                        ]}/>
                        <div className='system-diagnostics-header'>
                            <div className='system-diagnostics-header-title'>
                                <h1>
                                    {t('System Diagnostics')}
                                </h1>
                            </div>

                            <div className='system-diagnostics-header-button'>
                                {this.state.diagnosticsResult && !this.state.loading && (
                                    <button className='btn btn-green'
                                        onClick={this.runDiagnostics}>
                                        {t('Run diagnostics')}
                                    </button>
                                )}
                            </div>
                        </div>

                        {!this.state.diagnosticsResult &&
                        <DiagnosticsStarter
                            loading={this.state.loading}
                            t={t}
                            onStart={this.runDiagnostics} /> }

                        {this.state.diagnosticsResult &&
                        <DiagnosticsResult
                            loading={this.state.loading}
                            t={t}
                            result={this.state.diagnosticsResult} /> }
                    </div>
                </div>
            </div>
        </div>
    }
}

SystemDiagnosticsPage.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired
};

/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(SystemDiagnosticsPage));
