'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import _, {get, merge} from 'lodash';
import swal from 'sweetalert';

import {connect} from 'react-redux';
import Parse from 'parse';
import {
    calculateRoomSavingsPercentage,
    getDemoDevices,
    getLastMonthNumberWithYear,
    getMeasurementFromDemoRoom, isValidCo2Value,
    manageError,
    redirectIfNotLogged,
    toPointerFromId, userIsInRole
} from '../../lib/util';
import translate from '../translate';
import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import Footer from '../footer';
import {north, south} from '../../lib/average-monthly-temperatures';

// room view components
import RoomDetails from './room-details';
import EditRoomDetailsModal from './edit-room-details-modal';
import EditRoomInformationModal from './edit-room-information-modal';
import RoomClimate from './room-live-charts';
import RoomDeviceList from './room-device-list';
import RoomProgram from './room-program';
import CardRoomCalendar from './room-calendar';
import CardCreateCalendarEvent from './room-create-calendarEvent'
import EnvironmentIndex from './environment-index';
import * as db from '../../lib/structure';
import moment from 'moment-timezone';
import ExportMeasurementsModal from './export-measurements-modal';
import RoomTemperatureChart from './room-temperature-chart';
import RoomCO2Chart from './room-co2-chart';
import RoomPresenceChart from './room-presence-chart';
import RoomReportingIndicator from './room-reporting-indicator';
import RoomReportingDatePicker from './room-reporting-date-picker';
import RoomFMFeedbackList from './room-fm-feeback-list'
import WindowOpeningDistributionFigure from './window-opening-distribution-figure';
import RelativeOccupationFigure from './relative-occupation-figure';
import icons from '../../lib/icons';
import RoomRecommendationList from './room-recommendation-list';
import Loader from '../loader';
import structureLocalStorage from '../../lib/localStorageStructure';
import RoomFMFeedback from './room-fm-feeback-list/room-fm-feedback';
import SupportRequestList from './support-request/support-request-list';
import {requestCalendar} from './room-utils';
import {renderRoomTickets} from '../../lib/ui-components';

let getRoomAverageTemp = async (home, room, month, year) => {
    let roomWeekResult = {
        roomId: room.id,
        roomName: room.get(db.Room.ROOM_NAME),
        tempMin: room.get(db.Room.TEMP_MIN),
        tempMax: room.get(db.Room.TEMP_MAX),
        averageInternalTemp: 0,
        averageExternalTemp: 0,
        numberMonthlyMeasurement: 0,
        averageCO2: 0,
        averagePresence: 0
    };

    let query = new Parse.Query(db.classes.RoomDailyReport);
    query.equalTo(db.RoomDailyReport.MONTH, month);
    query.equalTo(db.RoomDailyReport.YEAR, year);
    query.equalTo(db.RoomDailyReport.ROOM, room);

    let roomDailyReports = await query.find();

    if(roomDailyReports.length === 0){
        roomWeekResult.tempMin = null;
        roomWeekResult.tempMax = null;

        return roomWeekResult;
    }

    let daysOfWeek = (new Array(7)).fill(0).map((a, i ) => i);
    let hours = (new Array(24)).fill(0).map((a, i ) => i);

    roomDailyReports.forEach(roomDailyReport => {
        let day = roomDailyReport.get(db.RoomDailyReport.DAY);
        let month = roomDailyReport.get(db.RoomDailyReport.MONTH);
        let year = roomDailyReport.get(db.RoomDailyReport.YEAR);

        let dayOfWeek = moment(`${year}.${month}.${day} 02:00`, 'YYYY.MM.DD HH:mm').day();

        let hourlyTemperatureAverage = roomDailyReport.get(db.RoomDailyReport.HOURLY_TEMPERATURE_AVERAGE);
        let hourlyCo2Average = roomDailyReport.get(db.RoomDailyReport.HOURLY_CO2_AVERAGE);
        let hourlyPresenceAverage = roomDailyReport.get(db.RoomDailyReport.HOURLY_PRESENCE_AVERAGE);
        let hourlyMaxPresence = roomDailyReport.get(db.RoomDailyReport.HOURLY_MAX_PRESENCE);
        let hourlyExternalTemperatureAverage =
            roomDailyReport.get(db.RoomDailyReport.HOURLY_EXTERNAL_TEMPERATURE_AVERAGE);
        let hourlyMaximalExpectedTemperature =
            roomDailyReport.get(db.RoomDailyReport.HOURLY_MAXIMAL_EXPECTED_TEMPERATURE);
        let hourlyRoomMaxTemp = roomDailyReport.get(db.RoomDailyReport.HOURLY_ROOM_MAX_TEMP);
        let hourlyRoomMinTemp = roomDailyReport.get(db.RoomDailyReport.HOURLY_ROOM_MIN_TEMP);
        let hourlyMeasurementCount = roomDailyReport.get(db.RoomDailyReport.HOURLY_MEASUREMENT_COUNT);

        hours.map(hour => {
            if(!hourlyMeasurementCount) return;

            let avgTempCurrentHour = _.get(hourlyTemperatureAverage, hour) ||
                _.get(hourlyTemperatureAverage, hour > 0 ? hour - 1: hour + 1);
            let avgCo2CurrentHour = _.get(hourlyCo2Average, hour);
            let avgPresenceCurrentHour = _.get(hourlyPresenceAverage, hour);
            let maxPresenceCurrentHour = _.get(hourlyMaxPresence, hour);
            let avgExternalTempCurrentHour = _.get(hourlyExternalTemperatureAverage, hour);
            let maxExpectedTempCurrentHour = _.get(hourlyMaximalExpectedTemperature, hour);
            let roomMaxTempCurrentHour = _.get(hourlyRoomMaxTemp, hour);
            let roomMinTempCurrentHour = _.get(hourlyRoomMinTemp, hour);
            let measurementCountCurrentHour = _.get(hourlyMeasurementCount, hour) ||
                _.get(hourlyTemperatureAverage, hour > 0 ? hour - 1: hour + 1);

            if(!measurementCountCurrentHour) return;
            if(!avgTempCurrentHour) return;

            if(!roomWeekResult[dayOfWeek]) roomWeekResult[dayOfWeek] = {};

            if(!roomWeekResult[dayOfWeek][hour])
                roomWeekResult[dayOfWeek][hour] = {
                    avgTemp: 0,
                    avgCo2 : 0,
                    avgPresence: 0,
                    maxPresence: 0,
                    avgExternalTemp: 0,
                    maxExpectedTemp: 0,
                    roomMaxTemp: 0,
                    roomMinTemp: 0,
                    measurementCount: 0
                };
            if(avgTempCurrentHour)
                roomWeekResult[dayOfWeek][hour].avgTemp += avgTempCurrentHour * measurementCountCurrentHour;
            if(avgCo2CurrentHour)
                roomWeekResult[dayOfWeek][hour].avgCo2 += avgCo2CurrentHour * measurementCountCurrentHour;
            roomWeekResult[dayOfWeek][hour].avgPresence += avgPresenceCurrentHour * measurementCountCurrentHour;
            roomWeekResult[dayOfWeek][hour].maxPresence =
                Math.max(roomWeekResult[dayOfWeek][hour].maxPresence, maxPresenceCurrentHour);
            roomWeekResult[dayOfWeek][hour].avgExternalTemp += avgExternalTempCurrentHour * measurementCountCurrentHour;
            roomWeekResult[dayOfWeek][hour].maxExpectedTemp =
                Math.max(roomWeekResult[dayOfWeek][hour].maxExpectedTemp, maxExpectedTempCurrentHour);
            roomWeekResult[dayOfWeek][hour].roomMaxTemp =
                Math.max(roomWeekResult[dayOfWeek][hour].roomMaxTemp, roomMaxTempCurrentHour);
            roomWeekResult[dayOfWeek][hour].roomMinTemp =
                Math.max(roomWeekResult[dayOfWeek][hour].roomMinTemp, roomMinTempCurrentHour);
            roomWeekResult[dayOfWeek][hour].measurementCount += measurementCountCurrentHour;
            roomWeekResult.averageInternalTemp += avgTempCurrentHour * measurementCountCurrentHour;
            roomWeekResult.averageExternalTemp += avgExternalTempCurrentHour * measurementCountCurrentHour;
            roomWeekResult.averageCO2 += avgCo2CurrentHour * measurementCountCurrentHour;
            roomWeekResult.averagePresence += avgPresenceCurrentHour * measurementCountCurrentHour;
            roomWeekResult.numberMonthlyMeasurement += measurementCountCurrentHour;
        });

        return roomWeekResult;
    });

    //Calculate week hour average
    daysOfWeek.map(dayOfWeek => {
        hours.map(hour => {
            if(!roomWeekResult[dayOfWeek]) return;
            if(!roomWeekResult[dayOfWeek][hour]) return;
            if(!roomWeekResult[dayOfWeek][hour].measurementCount) return;

            roomWeekResult[dayOfWeek][hour].avgTemp =
                roomWeekResult[dayOfWeek][hour].avgTemp / roomWeekResult[dayOfWeek][hour].measurementCount;
            roomWeekResult[dayOfWeek][hour].avgCo2 =
                roomWeekResult[dayOfWeek][hour].avgCo2 / roomWeekResult[dayOfWeek][hour].measurementCount;
            roomWeekResult[dayOfWeek][hour].avgPresence =
                roomWeekResult[dayOfWeek][hour].avgPresence / roomWeekResult[dayOfWeek][hour].measurementCount;
            roomWeekResult[dayOfWeek][hour].avgExternalTemp =
                roomWeekResult[dayOfWeek][hour].avgExternalTemp / roomWeekResult[dayOfWeek][hour].measurementCount;
        });
    });

    roomWeekResult.averageInternalTemp = roomWeekResult.averageInternalTemp / roomWeekResult.numberMonthlyMeasurement;
    roomWeekResult.averageExternalTemp = roomWeekResult.averageExternalTemp / roomWeekResult.numberMonthlyMeasurement;
    roomWeekResult.averageCO2 = roomWeekResult.averageCO2 / roomWeekResult.numberMonthlyMeasurement;
    roomWeekResult.averagePresence = roomWeekResult.averagePresence / roomWeekResult.numberMonthlyMeasurement;
    roomWeekResult.averageHumidity = room.get(db.Room.AVG_HUM_LAST_HOUR);

    let bestAverageExternalTemp = roomWeekResult.averageExternalTemp;
    const canton = home.get(db.Home.CANTON);
    if (canton === 'TI') {
        bestAverageExternalTemp = south[year]?.[month - 1] ? south[year][month - 1] : bestAverageExternalTemp;
    } else {
        bestAverageExternalTemp = north[year]?.[month - 1] ? north[year][month - 1] : bestAverageExternalTemp;
    }

    roomWeekResult.energySaved = calculateRoomSavingsPercentage(
        room,
        roomWeekResult.averageInternalTemp,
        bestAverageExternalTemp,
        roomWeekResult.numberMonthlyMeasurement);

    return roomWeekResult;
};

let getRoomAverageTempWeek = async (home, room, month, year, isoWeek) => {
    let roomWeekResult = {
        roomId: room.id,
        roomName: room.get(db.Room.ROOM_NAME),
        tempMin: room.get(db.Room.TEMP_MIN),
        tempMax: room.get(db.Room.TEMP_MAX),
        averageInternalTemp: 0,
        averageExternalTemp: 0,
        numberMonthlyMeasurement: 0,
        averageCO2: 0,
        averagePresence: 0
    };

    let query = new Parse.Query(db.classes.RoomDailyReport);
    query.equalTo(db.RoomDailyReport.ISO_WEEK, isoWeek);
    query.equalTo(db.RoomDailyReport.YEAR, year);
    query.equalTo(db.RoomDailyReport.ROOM, room);

    let roomDailyReports = await query.find();

    let measurementCount = roomDailyReports.reduce((acc, roomDailyReport) => {
        let hourlyMeasurementCount =  roomDailyReport.get(db.RoomDailyReport.HOURLY_MEASUREMENT_COUNT);

        if(hourlyMeasurementCount == null) return acc;

        const sumOfCount = hourlyMeasurementCount.reduce((a, b) => a + b, 0)

        return acc + sumOfCount;
    }, 0);

    if(roomDailyReports.length < 5 || measurementCount < 1000) return roomWeekResult; //12 * 24 * 7 = 2016 -> 50% ~ 1000

    let daysOfWeek = (new Array(7)).fill(0).map((a, i ) => i);
    let hours = (new Array(24)).fill(0).map((a, i ) => i);

    roomDailyReports.forEach(roomDailyReport => {
        let day = roomDailyReport.get(db.RoomDailyReport.DAY);
        let month = roomDailyReport.get(db.RoomDailyReport.MONTH);
        let year = roomDailyReport.get(db.RoomDailyReport.YEAR);

        let dayOfWeek = moment(`${year}.${month}.${day} 02:00`, 'YYYY.MM.DD HH:mm').day();

        let hourlyTemperatureAverage = roomDailyReport.get(db.RoomDailyReport.HOURLY_TEMPERATURE_AVERAGE);
        let hourlyCo2Average = roomDailyReport.get(db.RoomDailyReport.HOURLY_CO2_AVERAGE);
        let hourlyPresenceAverage = roomDailyReport.get(db.RoomDailyReport.HOURLY_PRESENCE_AVERAGE);
        let hourlyMaxPresence = roomDailyReport.get(db.RoomDailyReport.HOURLY_MAX_PRESENCE);
        let hourlyExternalTemperatureAverage =
            roomDailyReport.get(db.RoomDailyReport.HOURLY_EXTERNAL_TEMPERATURE_AVERAGE);
        let hourlyMaximalExpectedTemperature =
            roomDailyReport.get(db.RoomDailyReport.HOURLY_MAXIMAL_EXPECTED_TEMPERATURE);
        let hourlyRoomMaxTemp = roomDailyReport.get(db.RoomDailyReport.HOURLY_ROOM_MAX_TEMP);
        let hourlyRoomMinTemp = roomDailyReport.get(db.RoomDailyReport.HOURLY_ROOM_MIN_TEMP);
        let hourlyMeasurementCount = roomDailyReport.get(db.RoomDailyReport.HOURLY_MEASUREMENT_COUNT);

        hours.map(hour => {
            if(!hourlyMeasurementCount) return;

            let avgTempCurrentHour = _.get(hourlyTemperatureAverage, hour) ||
                _.get(hourlyTemperatureAverage, hour > 0 ? hour - 1: hour + 1);
            let avgCo2CurrentHour = _.get(hourlyCo2Average, hour);
            let avgPresenceCurrentHour = _.get(hourlyPresenceAverage, hour);
            let maxPresenceCurrentHour = _.get(hourlyMaxPresence, hour);
            let avgExternalTempCurrentHour = _.get(hourlyExternalTemperatureAverage, hour);
            let maxExpectedTempCurrentHour = _.get(hourlyMaximalExpectedTemperature, hour);
            let roomMaxTempCurrentHour = _.get(hourlyRoomMaxTemp, hour);
            let roomMinTempCurrentHour = _.get(hourlyRoomMinTemp, hour);
            let measurementCountCurrentHour = _.get(hourlyMeasurementCount, hour) ||
                _.get(hourlyTemperatureAverage, hour > 0 ? hour - 1: hour + 1);

            if(!measurementCountCurrentHour) return;
            if(!avgTempCurrentHour) return;

            if(!roomWeekResult[dayOfWeek]) roomWeekResult[dayOfWeek] = {};

            if(!roomWeekResult[dayOfWeek][hour])
                roomWeekResult[dayOfWeek][hour] = {
                    avgTemp: 0,
                    avgCo2 : 0,
                    avgPresence: 0,
                    maxPresence: 0,
                    avgExternalTemp: 0,
                    maxExpectedTemp: 0,
                    roomMaxTemp: 0,
                    roomMinTemp: 0,
                    measurementCount: 0
                };
            if(avgTempCurrentHour)
                roomWeekResult[dayOfWeek][hour].avgTemp += avgTempCurrentHour * measurementCountCurrentHour;
            if(avgCo2CurrentHour)
                roomWeekResult[dayOfWeek][hour].avgCo2 += avgCo2CurrentHour * measurementCountCurrentHour;
            roomWeekResult[dayOfWeek][hour].avgPresence += avgPresenceCurrentHour * measurementCountCurrentHour;
            roomWeekResult[dayOfWeek][hour].maxPresence =
                Math.max(roomWeekResult[dayOfWeek][hour].maxPresence, maxPresenceCurrentHour);
            roomWeekResult[dayOfWeek][hour].avgExternalTemp += avgExternalTempCurrentHour * measurementCountCurrentHour;
            roomWeekResult[dayOfWeek][hour].maxExpectedTemp =
                Math.max(roomWeekResult[dayOfWeek][hour].maxExpectedTemp, maxExpectedTempCurrentHour);
            roomWeekResult[dayOfWeek][hour].roomMaxTemp =
                Math.max(roomWeekResult[dayOfWeek][hour].roomMaxTemp, roomMaxTempCurrentHour);
            roomWeekResult[dayOfWeek][hour].roomMinTemp =
                Math.max(roomWeekResult[dayOfWeek][hour].roomMinTemp, roomMinTempCurrentHour);
            roomWeekResult[dayOfWeek][hour].measurementCount += measurementCountCurrentHour;
            roomWeekResult.averageInternalTemp += avgTempCurrentHour * measurementCountCurrentHour;
            roomWeekResult.averageExternalTemp += avgExternalTempCurrentHour * measurementCountCurrentHour;
            roomWeekResult.averageCO2 += avgCo2CurrentHour * measurementCountCurrentHour;
            roomWeekResult.averagePresence += avgPresenceCurrentHour * measurementCountCurrentHour;
            roomWeekResult.numberMonthlyMeasurement += measurementCountCurrentHour;
        });

        return roomWeekResult;
    });

    //Calculate week hour average
    daysOfWeek.map(dayOfWeek => {
        hours.map(hour => {
            if(!roomWeekResult[dayOfWeek]) return;
            if(!roomWeekResult[dayOfWeek][hour]) return;
            if(!roomWeekResult[dayOfWeek][hour].measurementCount) return;

            roomWeekResult[dayOfWeek][hour].avgTemp =
                roomWeekResult[dayOfWeek][hour].avgTemp / roomWeekResult[dayOfWeek][hour].measurementCount;
            roomWeekResult[dayOfWeek][hour].avgCo2 =
                roomWeekResult[dayOfWeek][hour].avgCo2 / roomWeekResult[dayOfWeek][hour].measurementCount;
            roomWeekResult[dayOfWeek][hour].avgPresence =
                roomWeekResult[dayOfWeek][hour].avgPresence / roomWeekResult[dayOfWeek][hour].measurementCount;
            roomWeekResult[dayOfWeek][hour].avgExternalTemp =
                roomWeekResult[dayOfWeek][hour].avgExternalTemp / roomWeekResult[dayOfWeek][hour].measurementCount;
        });
    });

    roomWeekResult.averageInternalTemp = roomWeekResult.averageInternalTemp / roomWeekResult.numberMonthlyMeasurement;
    roomWeekResult.averageExternalTemp = roomWeekResult.averageExternalTemp / roomWeekResult.numberMonthlyMeasurement;
    roomWeekResult.averageCO2 = roomWeekResult.averageCO2 / roomWeekResult.numberMonthlyMeasurement;
    roomWeekResult.averagePresence = roomWeekResult.averagePresence / roomWeekResult.numberMonthlyMeasurement;
    roomWeekResult.averageHumidity = room.get(db.Room.AVG_HUM_LAST_HOUR);

    let bestAverageExternalTemp = roomWeekResult.averageExternalTemp;
    const canton = home.get(db.Home.CANTON);
    if (canton === 'TI') {
        bestAverageExternalTemp = south[year][month - 1] ? south[year][month - 1] : bestAverageExternalTemp;
    } else {
        bestAverageExternalTemp = north[year][month - 1] ? north[year][month - 1] : bestAverageExternalTemp;
    }

    roomWeekResult.energySaved = calculateRoomSavingsPercentage(
        room,
        roomWeekResult.averageInternalTemp,
        bestAverageExternalTemp,
        roomWeekResult.numberMonthlyMeasurement);

    return roomWeekResult;
};

const roomMonthlyReportIndicators = [
    db.RoomMonthlyReport.AVERAGE_TEMPERATURE,
    db.RoomMonthlyReport.AVERAGE_HUMIDITY,
    db.RoomMonthlyReport.AVERAGE_CO2,
    db.RoomMonthlyReport.AVERAGE_TEMP_WHEN_EMPTY,
    db.RoomMonthlyReport.AVERAGE_TEMP_WEEKEND,
    db.RoomMonthlyReport.AVERAGE_TEMP_DAY,
    db.RoomMonthlyReport.HUM_LOW_RATIO,
    db.RoomMonthlyReport.AVERAGE_TEMP_WHEN_OCCUPIED,
    db.RoomMonthlyReport.AVERAGE_CO_WHEN_OCCUPIED,
    db.RoomMonthlyReport.AVERAGE_TEMP_NIGHT,
    db.RoomMonthlyReport.HUM_HIGH_RATIO
];

const indicatorsIcons = [
    icons.temperatureIcon,
    icons.humidityIcon,
    icons.co2Icon,
    icons.tempEmpty,
    icons.tempWeekends,
    icons.tempDay,
    icons.timeHumidityLow,
    icons.tempOccupied,
    icons.co2Occupied,
    icons.tempNight,
    icons.timeHumidityHigh
];

function formatIndicator(key, value){
    let map = {
        [db.RoomMonthlyReport.ROOM]: room => room.get(db.Room.ROOM_NAME) || 'N/A',
        [db.RoomMonthlyReport.AVERAGE_CO_WHEN_OCCUPIED]: co2 => {
            if(!co2) return 'N/A';

            co2 = Math.round(co2);

            if(co2 < 1000)
                return <span>{co2} ppm <div className={'env-indicator-circle env-indicator-circle-green'}></div></span>
            else if (co2 < 1500)
                return <span>{co2} ppm <div className={'env-indicator-circle env-indicator-circle-green'}></div></span>

            return <span>{co2} ppm <div className={'env-indicator-circle env-indicator-circle-red'}></div></span>
        },
        [db.RoomMonthlyReport.HUM_HIGH_RATIO]: (value) => `${value == null || isNaN(value) ? 'N/A' : Math.round(value, 1)} h`,
        [db.RoomMonthlyReport.HUM_LOW_RATIO]: (value) => `${value == null || isNaN(value) ? 'N/A' : Math.round(value, 1)} h`,
        [db.RoomMonthlyReport.AVERAGE_TEMP_WHEN_EMPTY]: (value) => `${value == null || isNaN(value) ? 'N/A' : value.toFixed(1)} °C`,
        [db.RoomMonthlyReport.AVERAGE_TEMP_WEEKEND]: (value) => `${value == null || isNaN(value) ? 'N/A' : value.toFixed(1)} °C`,
        [db.RoomMonthlyReport.AVERAGE_TEMP_DAY]: (value) => `${value == null || isNaN(value) ? 'N/A' : value.toFixed(1)} °C`,
        [db.RoomMonthlyReport.AVERAGE_TEMP_WHEN_OCCUPIED]: (value) => `${value == null || isNaN(value) ? 'N/A' : value.toFixed(1)} °C`,
        [db.RoomMonthlyReport.AVERAGE_CO_WHEN_OCCUPIED]: (value) => `${value == null || isNaN(value) ? 'N/A' : value.toFixed(0)} ppm`,
        [db.RoomMonthlyReport.AVERAGE_TEMP_NIGHT]: (value) => `${value == null || isNaN(value) ? 'N/A' : value.toFixed(1)} °C`
    }

    if(value == null) return 'N/A';

    return map[key]?.(value)?.toString() ?? value?.toString();
}

class RoomPage extends React.Component {
    constructor(props){
        super(props);

        let isDemoAccount = localStorage.getItem('demoAccount') === 'true';

        this.state = {
            room: null,
            loadingEnergy: false,
            events: [],
            devices: [],
            homeId: '',
            thermoExists: false,
            co2DeviceExists: isDemoAccount,
            homeReport: null,
            loadingHomeReport: false,
            roomFMFeedbacks: [],
            reportMode: 'Monthly',
            loading: {},
            currentTab: props.location.state?.selectedTab || 'overview',
            roomTickets: []
        };

        this.loadRoom = this.loadRoom.bind(this);
        this.renderRoomContent = this.renderRoomContent.bind(this);
        this.loadDevices = this.loadDevices.bind(this);
        this.onRoomSave = this.onRoomSave.bind(this);
        this.sortEvents = this.sortEvents.bind(this);
        this.checkCo2 = this.checkCo2.bind(this);
        this.loadHomeReport = this.loadHomeReport.bind(this);
        this.getLastCo2Value = this.getLastCo2Value.bind(this);
        this.getCLEVERSense = this.getCLEVERSense.bind(this);
        this.formatValue = this.formatValue.bind(this);
        this.loadRoomFMFeedbacks = this.loadRoomFMFeedbacks.bind(this);
        this.onDateRangeChanged = this.onDateRangeChanged.bind(this);
        this.renderRoomContentQrCode = this.renderRoomContentQrCode.bind(this);
    }

    async checkIfOnlySensor() {
        let {devices} = this.state;
        let thermoExists = false;
        devices.forEach(device => {
            if (device.get(db.Device.DEVICE_TYP) === 'therm') thermoExists = true;
        });
        this.setState({thermoExists});
    }

    async componentDidMount(){
        let urlSearchParams = new URLSearchParams(this.props?.location?.search);
        let mode = urlSearchParams?.get('mode');
        this.setState({mode});
        let user = await redirectIfNotLogged(this.props.history);
        let isDemoAccount = localStorage.getItem('demoAccount') === 'true';
        let avgCo2 = null;
        let avgTemp = null;
        let avgHum = null;
        let result = null;
        let lastMeasurement = get(result, 'lastMeasurements');

        await this.loadDevices(lastMeasurement);
        await this.checkIfOnlySensor();
        await this.checkIfOnlySensor();
        let room = await this.loadRoom(avgCo2, avgTemp, avgHum);

        this.checkCo2();

        if (user) {
            let homeId = localStorage.getItem('buildingSelection');

            let events = await requestCalendar(homeId, this.props.match.params.id);

            this.setState({homeId, events});

            let {lastMonth, year, week} = getLastMonthNumberWithYear();

            await this.loadHomeReport(lastMonth, year);
            await this.loadRoomFMFeedbacks();
            await this.loadRoomMonthlyReport(lastMonth, year);
            await this.loadRoomWeeklyReport(week, year);
            await this.getRoomTickets(room);
        }
    }

    formatValue(value, unit) {
        if (!value) {
            return this.props.t('N/A');
        }

        if (unit === 'celsius') {
            return `${value.toFixed(1)} °C`
        }

        if (unit === 'ppm') {
            return `${value.toFixed(0)} ppm`
        }

        if (unit === 'percent') {
            return `${value.toFixed(0)} %`
        }

        return value;
    }

    async loadRoom(avgCo2, avgTemp, avgHum) {
        let room = await (new Parse.Query(db.classes.Room))
            .get(this.props.match.params.id);
        let isDemoAccount = localStorage.getItem('demoAccount') === 'true';
        if (isDemoAccount) {
            room.set(db.Room.AVG_TEMP_LAST_HOUR, avgTemp)
            room.set(db.Room.AVG_HUM_LAST_HOUR, avgHum);
        }
        this.setState({
            room: room,
            roomJSON: room.toJSON()
        });

        return room;
    }

    async loadRoomFMFeedbacks() {
        let query = new Parse.Query(db.classes.RoomFMFeedback);
        query.notEqualTo(db.RoomFMFeedback.DELETED, true);
        query.equalTo('room', toPointerFromId(this.props.match.params.id, 'Room'));
        query.descending(db.RoomFMFeedback.CREATED_AT);
        query.limit(5);

        try {
            let feedbacks = await query.find();
            this.setState({
                roomFMFeedbacks: feedbacks
            });
            return feedbacks;
        } catch (err) {
            return await manageError(err, this.props);
        }
    }

    async loadDevices(lastMeasurements){
        if(localStorage.getItem('demoAccount') === 'true' && lastMeasurements){
            return this.setState({
                devices: getDemoDevices(this.props.match.params.id, lastMeasurements)
            });
        }

        let query = new Parse.Query('Device');
        query.equalTo(db.Device.ROOM_ID, toPointerFromId(this.props.match.params.id, 'Room'));
        query.select([
            db.Device.WIFI_STRENGTH,
            db.Device.CO2,
            db.Device.TEMPERATURE,
            db.Device.DEVICE_NAME,
            db.Device.OBJECT_ID,
            db.Device.DEVICE_TYP,
            db.Device.MOTOR_POSITION,
            db.Device.LAST_RESPONSE_MOTOR_COMMAND_VALUE,
            db.Device.LAST_RESPONSE_MOTOR_COMMAND_VALUE_DATE,
            db.Device.BATTERY_VOLTAGE,
            db.Device.SERIAL_NUMBER,
            db.Device.MAC_ADDRESS,
            db.Device.LOCAL_IP,
            db.Device.CONFIG_WIFI_SSID,
            db.Device.LAST_MEASUREMENT_DATE,
            db.Device.DEVICE_STATE_FLAG,
            db.Device.CONNECTION_WIFI_SSID,
            db.Device.VERSION_FIRMWARE,
            db.Device.NB_IOT_SIGNAL_QUALITY
        ])
        let devices = await query.find();
        await this.setState({devices: devices});
    }

    getHome(homeId){
        let query = new Parse.Query('Home');

        return query.get(homeId).catch(manageError);
    }

    async loadHomeReport(month, year, isoWeek){

        try {
            /*const homeQuery = (new Parse.Query(db.classes.Home))
                .equalTo(db.Home.ENERGY_REPORT_COMPLIANT, true)
                .equalTo(db.Home.OBJECT_ID, homeId);

            const homeReport = await (new Parse.Query(db.classes.HomeReport))
                .matchesQuery(db.HomeReport.HOME, homeQuery)
                .include(db.HomeReport.HOME)
                .addDescending([db.HomeReport.YEAR, db.HomeReport.MONTH])
                .select([
                    db.HomeReport.OBJECT_ID,
                    db.HomeReport.HOME,
                    db.HomeReport.YEAR,
                    db.HomeReport.MONTH,
                    db.HomeReport.DATA,
                    db.HomeReport.ROOM_COMMENTS
                ])
                .first();*/
            this.setState({loadingHomeReport: true});
            const home = await this.getHome(this.state.homeId);
            let data;

            if(isoWeek != null)
                data = await getRoomAverageTempWeek(home, this.state.room, month, year, isoWeek);
            else
                data = await getRoomAverageTemp(home, this.state.room, month, year);

            let homeReport = new Parse.Object(db.classes.HomeReport);
            homeReport.set(db.HomeReport.DATA, data);
            homeReport.set(db.HomeReport.MONTH, month);
            homeReport.set(db.HomeReport.YEAR, year);
            homeReport.set(db.HomeReport.ISO_WEEK, isoWeek);

            this.setState({homeReport: homeReport, home});
        } catch (e) {
            console.error(e);
        } finally {
            this.setState({loadingHomeReport: false});
        }
    }

    async loadRoomMonthlyReport(month, year){
        this.setState(prev => {
            prev.loading.roomMonthlyReport = true;

            return prev;
        });

        const roomMonthlyReport = await (new Parse.Query(db.classes.RoomMonthlyReport))
            .equalTo(db.RoomMonthlyReport.MONTH, month)
            .equalTo(db.RoomMonthlyReport.YEAR, year)
            .equalTo(db.RoomMonthlyReport.ROOM, this.state.room)
            .first();

        this.setState(prev => {
            prev.roomMonthlyReport = roomMonthlyReport;
            prev.loading.roomMonthlyReport = false;

            return prev;
        });
    }

    async loadRoomWeeklyReport(week, year){
        this.setState(prev => {
            prev.loading.roomWeeklyReport = true;

            return prev;
        });
        const roomWeeklyReport = await (new Parse.Query(db.classes.RoomWeeklyReport))
            .equalTo(db.RoomWeeklyReport.ISO_WEEK_NUMBER, week)
            .equalTo(db.RoomWeeklyReport.YEAR, year)
            .equalTo(db.RoomWeeklyReport.ROOM, this.state.room)
            .first();

        this.setState(prev => {
            prev.roomWeeklyReport = roomWeeklyReport;
            prev.loading.roomWeeklyReport = false;

            return prev;
        });
    }

    onRoomSave(room){
        const t = this.props.t;

        return this.state.room.save(room)
            .then(() => {
                swal({
                    title: t('Room saved!'),
                    text: ' ',
                    icon: 'success',
                    button: [''],
                    timer: 1000
                });

                return this.loadRoom();
            })
            .catch(err => {
                console.error(err);
                swal(t('Error'), t('There was a problem saving the room information'), 'error')
            });
    }

    async sortEvents(order, column){
        let events = await requestCalendar(this.state.homeId, this.props.match.params.id, column, order);

        this.setState({events});
    }

    checkCo2() {
        const {devices} = this.state;

        devices.forEach((device) => {
            let co2 = device.get(db.Device.CO2);

            if(isValidCo2Value(co2)){
                this.setState({co2DeviceExists: true})
            }
        });
    }

    getCLEVERSense(){
        const {devices} = this.state;
        let cleverSenses = devices.filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$SENSP);

        if(cleverSenses.length === 0)
            cleverSenses = devices.filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$SENSE);

        return cleverSenses?.[0];
    }

    getLastCo2Value(){
        let co2 = null;
        let lastMeasurementDate = null;
        let lastHour = moment().subtract(1, 'hour');

        const {devices} = this.state;

        devices.forEach((device) => {
            let deviceType = device.get(db.Device.DEVICE_TYP);

            if (deviceType === 'senco' || deviceType === 'sensp') {
                co2 = device.get(db.Device.CO2);
                lastMeasurementDate = device.get(db.Device.LAST_MEASUREMENT_DATE);
            }
        });

        if(co2 != null && lastMeasurementDate != null){
            if(moment(lastMeasurementDate).isAfter(lastHour, 'minutes')){
                return co2;
            }
        }

        return null;
    }

    render(){
        const {t} = this.props;

        const mode = this.state.mode;

        return <div className={'room-page'}>
            {
                get(this.props, 'shared.generalError.visible') && <div className={'alert alert-danger'}>
                    {get(this.props, 'shared.generalError.message')}
                </div>
            }
            <div className="wrapper d-flex align-items-stretch">
                {mode !== 'qrCode' && <Sidebar {...this.props}/>}
                <div className="content" style={mode === 'qrCode' ? {marginTop: 0} : {}}>
                    {mode !== 'qrCode' && <Navbar {...this.props}/>}
                    {
                        this.state.room &&
                        mode === 'qrCode' && this.renderRoomContentQrCode()
                    }
                    {
                        this.state.room &&
                        mode !== 'qrCode' && this.renderRoomContent()
                    }
                    {mode !== 'qrCode' && <Footer/>}
                </div>
            </div>
            <EditRoomDetailsModal t={t}
                room={this.state.room}
                roomJson={this.state.roomJSON}
                onSave={this.onRoomSave}
                onRoomDetailSaved={() => this.loadRoom()}/>
            <EditRoomInformationModal
                t={t}
                room={this.state.roomJSON}
                onSave={this.onRoomSave}/>
            {this.state.co2DeviceExists && this.state.roomJSON && <ExportMeasurementsModal t={t}
                room={this.state.room}
                roomId={this.state.roomId}
                i18n={this.props.i18n}
                homeId={this.state.homeId}/>}
        </div>
    }

    async onDateRangeChanged(week, month, year){
        if(this.state.reportMode === 'Monthly')
            this.loadHomeReport(month, year);
        else if (this.state.reportMode === 'Weekly')
            this.loadHomeReport(month, year, week);
        this.loadRoomMonthlyReport(month, year);
        this.loadRoomWeeklyReport(week, year);
    }

    async getRoomTickets(room){
        let selectedBuildingId = localStorage.getItem(structureLocalStorage.BUILDING_SELECTION);

        let result = await Parse.Cloud.run('get-building-room-tickets', {buildingId: selectedBuildingId});

        this.setState({
            roomTickets: result.roomTickets.filter(roomTicket => roomTicket.get(db.RoomTicket.ROOM).id === room.id)
        });
    }

    renderRoomContent(){
        const {t} = this.props;
        const {thermoExists, co2DeviceExists, room, homeId} = this.state;
        const roomHasThermo = room.get(db.Room.NUMBER_RADIATORS) > 0;

        const hasWritePermission = localStorage.getItem('user:hasWritePermission') === 'true';
        let uniqueId = room.get(db.Room.UNIQUE_ID);

        const currentTab = this.state.currentTab;

        let isZIDBuilding = localStorage.getItem('buildingSelection') === 'X6EitoEDpX';

        let energySaved = null;
        if(!this.state.loadingHomeReport && this.state.homeReport){
            energySaved = this.state.homeReport.get(db.HomeReport.DATA).energySaved;

            if(isZIDBuilding) energySaved += 20;
        }

        let report = this.state.reportMode === 'Monthly' ? this.state.roomMonthlyReport : this.state.roomWeeklyReport;
        let {lastMonth, year} = getLastMonthNumberWithYear();

        let isSupportRole = userIsInRole(db.roles.SUPPORT);
        let isAdminRole = userIsInRole(db.roles.ADMIN);

        let renderTabs = () => {

            return <>
                <ul className="nav nav-tabs header-tabs hide-sm" role="tablist" id='room-tabs-no-mobile'>

                    <li className="nav-item">
                        <a className={`nav-link ${currentTab === 'overview' ? 'active' : ''} show`}
                            id="room-tab-overview"
                            href="javascript:void(0)"
                            onClick={() => this.setState({currentTab: 'overview'})}
                        >
                            {t('Overview')}
                        </a>
                    </li>

                    <li className="nav-item">
                        <a className={`nav-link ${currentTab === 'reporting' ? 'active' : ''} show`}
                            id="room-tab-reporting"
                            href="javascript:void(0)"
                            onClick={() => this.setState({currentTab: 'reporting'})}
                        >
                            {t('Reporting')}
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${currentTab === 'support-requests' ? 'active' : ''} show`}
                            id="room-tab-support-requests"
                            href="javascript:void(0)"
                            onClick={() => this.setState({currentTab: 'support-requests'})}
                        >
                            {t('Support requests')}
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${currentTab === 'operations' ? 'active' : ''} show`}
                            id="room-tab-support-requests"
                            href="javascript:void(0)"
                            onClick={() => this.setState({currentTab: 'operations'})}
                        >
                            {t('Operations')}
                        </a>
                    </li>
                </ul>

                <div className="select-custom room-tab-select">
                    <div
                        className="select-custom-status">{currentTab === 'reporting' ? t('Reporting') : t('Overview')}</div>
                    <ul className="nav nav-tabs header-tabs select-custom-list" id={'room-tabs-mobile'} role="tablist">
                        <li className="nav-item select-custom-item">
                            <a className={`nav-link ${currentTab === 'overview' ? 'active' : ''} show`}
                                id="room-tab-overview-mobile"
                                href="javascript:void(0)"
                                onClick={() => this.setState({currentTab: 'overview'})}
                            >
                                {t('Overview')}
                            </a>
                        </li>
                        <li className="nav-item select-custom-item">
                            <a className={`nav-link ${currentTab === 'reporting' ? 'active' : ''} show`}
                                id="room-tab-reporting-mobile"
                                href="javascript:void(0)"
                                onClick={() => this.setState({currentTab: 'reporting'})}
                            >
                                {t('Reporting')}
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="tab-content">
                    <div
                        className={`tab-pane fade ${currentTab === 'overview' ? 'active show' : ''}`}
                        id='room-tab-overview-content'
                        role="tabpanel"
                        aria-labelledby="overview">
                        <div className="grid-cols building-view room-view room-details-device-list">
                            <div className="left-col grid-building-view grid-room-view">
                                {
                                    this.state.room && <RoomDetails
                                        room={this.state.room}
                                        home={this.state.home}
                                        t={t}
                                        cleverSense={this.getCLEVERSense()}
                                    />
                                }
                            </div>
                            <div className="right-col grid-building-view grid-room-view">
                                <RoomDeviceList devices={this.state.devices}
                                    roomId={this.props.match.params.id}
                                    room={this.state.room}
                                    t={t}
                                    loadDevices={this.loadDevices}
                                    i18n={this.props.i18n}/>
                            </div>
                        </div>

                        {
                            thermoExists && <div className="grid-cols building-view room-view">
                                <RoomProgram t={t}
                                    roomId={this.props.match.params.id}
                                    room={this.state.room}
                                    onRoomDetailSaved={() => this.loadRoom()} />
                            </div>
                        }

                        <div className="grid-cols building-view room-view">
                            <div className="full-w">
                                <RoomClimate t={t} roomId={this.props.match.params.id} devices={this.state.devices}/>
                            </div>
                        </div>

                        <div className="grid-cols building-view room-view">
                            {
                                co2DeviceExists && <div className="grid-room-view" style={{flex: 1}}>
                                    <EnvironmentIndex
                                        t={t}
                                        roomId={this.props.match.params.id}
                                        room={this.state.room}
                                        devices={this.state.devices}
                                    />
                                </div>
                            }

                            <div className="grid-room-view" style={{flex: 2}}>
                                <RoomFMFeedbackList feedbacks={this.state.roomFMFeedbacks}
                                    onChange={this.loadRoomFMFeedbacks}
                                    t={t}/>
                            </div>
                        </div>

                        {
                            thermoExists && <div className="grid-cols building-view room-view">
                                <div className="left-col grid-building-view grid-room-view">
                                    <CardRoomCalendar t={t} events={this.state.events}
                                        sortEvents={this.sortEvents} room={this.state.room} i18n={this.props.i18n}/>
                                </div>
                                <div className="right-col grid-building-view grid-create-calendar-event">
                                    {
                                        (hasWritePermission || isZIDBuilding) && <CardCreateCalendarEvent t={t}
                                            room={this.state.room}
                                            i18n={this.props.i18n} sortEvents={this.sortEvents}/>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div
                        className={`tab-pane fade ${currentTab === 'reporting' ? 'active show' : ''}`}
                        id='room-tab-reporting-content'
                        role="tabpanel"
                        aria-labelledby="reporting">

                        <div className='room-reporting-header'>
                            <RoomReportingDatePicker
                                month={lastMonth}
                                year={year}
                                t={t}
                                onDateChange={(week, month, year) => this.onDateRangeChanged(week, month, year)}
                                onModeChange={reportMode => {
                                    this.setState({reportMode}, () => {
                                        let date = moment(`01/${lastMonth}/${year}`, 'DD/MM/YYYY');
                                        let week = date.isoWeek();

                                        if(this.state.reportMode === 'Monthly')
                                            this.loadHomeReport(lastMonth, year);
                                        else if (this.state.reportMode === 'Weekly')
                                            this.loadHomeReport(lastMonth, year, week);

                                        this.loadRoomMonthlyReport(lastMonth, year);
                                        this.loadRoomWeeklyReport(week, year);
                                    });
                                }}
                            >
                                <button className='room-reporting-export-button'
                                    data-toggle="modal"
                                    data-target="#exportMeasurementsModal"
                                >
                                    {t('Export Measurements')}
                                </button>
                            </RoomReportingDatePicker>
                        </div>

                        {
                            this.state.reportMode === 'Weekly' &&
                            this.state.loading.roomWeeklyReport === true && <Loader/>
                        }

                        {
                            this.state.reportMode === 'Monthly' &&
                            this.state.loading.roomMonthlyReport === true && <Loader/>
                        }

                        {
                            report &&
                            <div className="card-info reporting-indicator-list">
                                {
                                    report?.get(db.RoomMonthlyReport.MONTH) >= 0 &&
                                    report?.get(db.RoomMonthlyReport.YEAR) >= 2022 &&
                                    roomMonthlyReportIndicators.map((indicatorKey, i) => {
                                        return <RoomReportingIndicator
                                            key={indicatorKey}
                                            icon={indicatorsIcons[i]}
                                            title={t(`class.label.${db.classes.RoomMonthlyReport}.${indicatorKey}`)}
                                            value={
                                                formatIndicator(
                                                    indicatorKey,
                                                    report?.get(indicatorKey)
                                                )
                                            }
                                        />
                                    })
                                }
                            </div>
                        }

                        {
                            false && NODE_ENV !== 'production' && <RoomRecommendationList
                                t={t}
                                room={this.state.room}
                                month={this.state.roomMonthlyReport?.get(db.RoomMonthlyReport.MONTH)}
                                year={this.state.roomMonthlyReport?.get(db.RoomMonthlyReport.YEAR)}
                            />
                        }

                        {
                            report?.get(db.RoomMonthlyReport.WINDOW_OPENING_DISTRIBUTION_FIGURE) &&
                            <WindowOpeningDistributionFigure
                                t={t}
                                week={report?.get(db.RoomWeeklyReport.ISO_WEEK_NUMBER)}
                                month={report?.get(db.RoomMonthlyReport.MONTH)}
                                year={report?.get(db.RoomMonthlyReport.YEAR)}
                                windowsOpeningDistributionFigure={report.get(db.RoomMonthlyReport.WINDOW_OPENING_DISTRIBUTION_FIGURE)}
                                coarseCo2Ratios={report.get(db.RoomMonthlyReport.COARSE_CO_RATIOS)}
                                coarseNumberWindowOpenings={report.get(db.RoomMonthlyReport.COARSE_NUMBER_WINDOW_OPENINGS)}
                            />
                        }

                        {
                            report?.get(db.RoomMonthlyReport.RELATIVE_OCCUPATION_FIGURE) &&
                            <RelativeOccupationFigure
                                t={t}
                                month={report?.get(db.RoomMonthlyReport.MONTH)}
                                year={report?.get(db.RoomMonthlyReport.YEAR)}
                                relativeOccupationFigure={report.get(db.RoomMonthlyReport.RELATIVE_OCCUPATION_FIGURE)}
                            />
                        }

                        {
                            thermoExists && <div
                                className="grid-cols building-view room-view">
                                <RoomTemperatureChart t={t} roomId={this.props.match.params.id} room={this.state.room}
                                    homeReport={report}
                                    i18n={this.props.i18n}
                                    loading={this.state.loadingHomeReport}
                                />
                            </div>
                        }

                        {
                            co2DeviceExists && <div
                                className="grid-cols building-view room-view">
                                <RoomCO2Chart t={t} roomId={this.props.match.params.id}
                                    room={this.state.room}
                                    homeReport={report}
                                    i18n={this.props.i18n}
                                    loading={this.state.loadingHomeReport}/>
                            </div>
                        }

                        {
                            co2DeviceExists &&
                            <div className="grid-cols building-view room-view">
                                <RoomPresenceChart
                                    t={t}
                                    room={this.state.room}
                                    i18n={this.props.i18n}
                                    homeId={this.state.homeId}
                                    homeReport={report}
                                    loading={this.state.loadingHomeReport}
                                />
                            </div>
                        }
                    </div>
                    <div
                        className={`tab-pane fade ${currentTab === 'support-requests' ? 'active show' : ''}`}
                        id='room-tab-support-requests-content'
                        role="tabpanel"
                        aria-labelledby="support-requests"
                    >
                        <button className='room-reporting-export-button' style={{float: 'right'}}
                            disabled={!hasWritePermission}
                            onClick={() => this.props.history.push({
                                pathname: '/room/:id/support-request-open-new'.replace(':id', this.state.room.id),
                                state: {room: this.state.room}
                            })}
                        >
                            {t('Open a support request')}
                        </button>
                        <h2>{t('Support requests')}</h2>
                        {
                            this.state.room && <div className={'full-w'}>
                                <SupportRequestList {...this.props} room={this.state.room}/>
                            </div>
                        }
                    </div>

                    <div
                        className={`tab-pane fade ${currentTab === 'operations' ? 'active show' : ''}`}
                        id='room-tab-operations-content'
                        role="tabpanel"
                        aria-labelledby="operations"
                    >
                        <h2>{t('Operations')}</h2>
                        {
                            this.state.room && <div className={'full-w'}>
                                <div className="card card-outer full-w">
                                    {renderRoomTickets(this.state.roomTickets, t, this)}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </>;
        }

        return <div className="inner">
            <Breadcrumbs {...this.props} elements={[
                {
                    link: '/dashboard',
                    name: t('Home')
                },
                {
                    link: '/building',
                    name: t('Building')
                },
                {
                    link: null,
                    name: this.state.room.get(db.Room.ROOM_NAME)
                }
            ]}/>
            <div className="room-header">
                <div className="room-info-wrapper">
                    <div className="room-name">
                        <h1 className="no-marg">
                            {room.get(db.Room.ROOM_NAME)}
                            {
                                uniqueId && <a>&nbsp;<i className="fa fa-qrcode" aria-hidden="true"></i> {uniqueId.substr(uniqueId.length - 6)}</a>
                            }
                        </h1>
                        {
                            hasWritePermission && <button type="button" className="edit-modal-btn " data-toggle="modal"
                                data-target="#editInfoRoom">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                    <path fill="#D5D5D5"
                                        d="M24.4241432,10.8047398 L19.9365098,6 L5,20.9365098 L5,25.5323589 L9.59584916,25.5323589 L24.4241432,10.8047398 Z M8.71980568,23.7743806 L6.78695388,23.7743806 L6.78695388,21.6255487 L19.9365098,8.71098229 L22.0119725,10.7710606 L8.71980568,23.7743806 Z M11.8937737,26.6813212 L14.0149192,24.8044897 L27.5900002,24.8044897 L25.6813212,26.6813212 L11.8937737,26.6813212 Z"/>
                                </svg>

                            </button>
                        }
                    </div>
                    <div className="room-info">
                        {room.get(db.Room.FLOOR) !== undefined && <div className="room-info-item"><span>{t('Floor')}:</span> {room.get(db.Room.FLOOR)}</div>}
                        {room.get(db.Room.AREA) !== undefined && <div className="room-info-item"><span>{t('Area')}:</span> {room.get(db.Room.AREA)} m<sup>2</sup></div>}
                        {room.get(db.Room.HEIGHT) !== undefined && <div className="room-info-item"><span>{t('Height')}:</span> {room.get(db.Room.HEIGHT)} m</div>}
                        {room.get(db.Room.WINDOWS) !== undefined && <div className="room-info-item"><span>{t('Window')}:</span> {room.get(db.Room.WINDOWS)}</div>}
                        {room.get(db.Room.ROOM_TYPE) !== undefined && <div className="room-info-item"><span>{t('Room type')}:</span> {t(`class.label.${db.classes.RoomType}.${db.RoomType.NAME}$${room.get(db.Room.ROOM_TYPE)}`)}</div>}
                    </div>

                    {
                        (isSupportRole || isAdminRole) && <>
                            <a
                                href={`https://admindashboard.cleveron.ch/homes/${homeId}/room-temperature-chart?selectedRoomId=${room.id}`}
                                target={'_blank'} rel="noreferrer"
                            >{'Admin dashboard chart'}</a><br/>
                        </>
                    }

                </div>
                {/*<div><!-- placeholder for new weather widget --></div>*/}
            </div>
            {renderTabs()}
        </div>
    }
    renderRoomContentQrCode(){
        const {t} = this.props;
        const {thermoExists, co2DeviceExists, room, homeId} = this.state;
        const roomHasPresenceSensors = room.get(db.Room.NUMBER_SENSP) > 0 || room.get(db.Room.NUMBER_SENSE) > 0 ||
            room.get(db.Room.NUMBER_SENCO) >  0;

        const hasWritePermission = localStorage.getItem('user:hasWritePermission') === 'true';
        let uniqueId = room.get(db.Room.UNIQUE_ID);

        const currentTab = window.location.hash === '#reporting' ? 'reporting' : 'overview';

        let isZIDBuilding = localStorage.getItem('buildingSelection') === 'X6EitoEDpX';

        let energySaved = null;
        if(!this.state.loadingHomeReport && this.state.homeReport){
            energySaved = this.state.homeReport.get(db.HomeReport.DATA).energySaved;

            if(isZIDBuilding) energySaved += 20;
        }

        return <div className="inner">
            <Breadcrumbs {...this.props} elements={[
                {
                    link: '/dashboard',
                    name: 'Qr code portal'
                },
                {
                    link: `${location.pathname}/report`,
                    name: this.state.room.get(db.Room.ROOM_NAME)
                }
            ]}/>
            <div className="room-header">
                <div className="room-info-wrapper">
                    <div className="room-name">
                        <h1 className="no-marg">
                            {room.get(db.Room.ROOM_NAME)}
                            {
                                uniqueId && <a>&nbsp;<i className="fa fa-qrcode" aria-hidden="true"></i> {uniqueId.substr(uniqueId.length - 6)}</a>
                            }
                        </h1>
                        {
                            hasWritePermission && <button type="button" className="edit-modal-btn " data-toggle="modal"
                                data-target="#editInfoRoom">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                    <path fill="#D5D5D5"
                                        d="M24.4241432,10.8047398 L19.9365098,6 L5,20.9365098 L5,25.5323589 L9.59584916,25.5323589 L24.4241432,10.8047398 Z M8.71980568,23.7743806 L6.78695388,23.7743806 L6.78695388,21.6255487 L19.9365098,8.71098229 L22.0119725,10.7710606 L8.71980568,23.7743806 Z M11.8937737,26.6813212 L14.0149192,24.8044897 L27.5900002,24.8044897 L25.6813212,26.6813212 L11.8937737,26.6813212 Z"/>
                                </svg>

                            </button>
                        }
                    </div>
                    <div className="room-info">
                        {room.get(db.Room.FLOOR) !== undefined && <div className="room-info-item"><span>{t('Floor')}:</span> {room.get(db.Room.FLOOR)}</div>}
                        {room.get(db.Room.AREA) !== undefined && <div className="room-info-item"><span>{t('Area')}:</span> {room.get(db.Room.AREA)} m<sup>2</sup></div>}
                        {room.get(db.Room.HEIGHT) !== undefined && <div className="room-info-item"><span>{t('Height')}:</span> {room.get(db.Room.HEIGHT)} m</div>}
                        {room.get(db.Room.WINDOWS) !== undefined && <div className="room-info-item"><span>{t('Window')}:</span> {room.get(db.Room.WINDOWS)}</div>}
                    </div>
                </div>
            </div>

            <div className="grid-cols building-view room-view room-details-device-list">
                <div className="left-col grid-building-view grid-room-view">
                    {
                        this.state.room && <RoomDetails
                            room={this.state.room}
                            t={t}
                            cleverSense={this.getCLEVERSense()}
                            showImage={false}
                        />
                    }
                </div>
                <div className="right-col grid-building-view grid-room-view">
                    <RoomDeviceList devices={this.state.devices}
                        roomId={this.props.match.params.id}
                        room={this.state.room}
                        t={t}
                        loadDevices={this.loadDevices}
                        i18n={this.props.i18n}/>
                </div>
            </div>

            {
                thermoExists && <div className="grid-cols building-view room-view">
                    <RoomProgram t={t}
                        roomId={this.props.match.params.id}
                        room={this.state.room}
                        onRoomDetailSaved={() => this.loadRoom()} />
                </div>
            }

            <div className="grid-cols building-view room-view">

                <div className="grid-room-view" style={{flex: 2}}>
                    <RoomFMFeedbackList feedbacks={this.state.roomFMFeedbacks}
                        onChange={this.loadRoomFMFeedbacks}
                        t={t}/>
                </div>
            </div>

            {
                thermoExists && <div className="grid-cols building-view room-view">
                    <div className="left-col grid-building-view grid-room-view">
                        <CardRoomCalendar t={t} events={this.state.events}
                            sortEvents={this.sortEvents} room={this.state.room} i18n={this.props.i18n}/>
                    </div>
                    <div className="right-col grid-building-view grid-create-calendar-event">
                        {
                            (hasWritePermission || isZIDBuilding) && <CardCreateCalendarEvent t={t}
                                room={this.state.room}
                                i18n={this.props.i18n} sortEvents={this.sortEvents}/>
                        }
                    </div>
                </div>
            }
        </div>
    }
}

RoomPage.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.any.isRequired,
    match: PropTypes.any
};
/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(RoomPage));