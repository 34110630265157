import React, {Component} from 'react';
import PropTypes from 'prop-types';
import swal from '@sweetalert/with-react';
import translate from '../../translate';
import * as db from '../../../lib/structure';
import Parse from 'parse';

import serialNumberTutorialImg from '../../../assets/images/page-start-configuration/back-label-example-serial.png';
import macAddressTutorialImg from '../../../assets/images/page-start-configuration/back-label-example-mac-address.png';

class AddDeviceToRoomModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            last4digit: ''
        };

        this.linkDevice = this.linkDevice.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    async linkDevice(e){
        e.preventDefault();

        let {t} = this.props;

        if(this.state.last4digit == null || this.state.last4digit === '') throw new Error('The last 4 digit of the mac address are required.');
        if(this.state[db.Device.SERIAL_NUMBER] == null || this.state[db.Device.SERIAL_NUMBER]  === '') throw new Error('The serial number is required.');

        let currentHomeId = localStorage.getItem('buildingSelection');

        try{
            let verifiedDevicesSerials = await Parse.Cloud.run('registration-process-verify-devices', {
                devices: [{
                    serial: parseInt(this.state[db.Device.SERIAL_NUMBER]),
                    macAddress: this.state.last4digit
                }]
            });

            if(verifiedDevicesSerials.length === 0) throw new Error(t('Was not possible to verify one or more devices. Please check both serial number and last 4 digit of the MAC address.'));

            await Parse.Cloud.run('page-room-link-device-to-room', {
                verifiedDevice: {
                    serial: parseInt(this.state[db.Device.SERIAL_NUMBER]), //4946394
                    macAddress: this.state.last4digit //RY:I9
                },
                roomId: this.props.roomId,
                homeId: currentHomeId
            });

            $('#modal-room-actions .close').click();

            await swal({title: t('Success'), text: ' ', icon: 'success', button: [''], timer: 1000});

            location.reload();
        }catch (e) {
            console.error(e);
            swal(t('Error'), e.message, 'error');
        }
    }

    onInputChange(event){
        let name = event.target.name;
        let value = event.target.value;

        this.setState(prev => {
            prev[name] = value;

            return prev;
        });
    }

    render(){
        let {t} = this.props;

        return (
            <div className="modal fade" id="modal-room-actions" tabIndex="-1" role="dialog"
                aria-labelledby="summerModeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content edit-info">
                        <div className="modal-header">
                            <h5 className="modal-title">{t('Add device')}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                    <path fill="#222"
                                        d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                </svg>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>{t('Serial number')}
                                    <a onClick={(e) => {
                                        e.preventDefault();
                                        swal(<img src={serialNumberTutorialImg} height="130" width="300"></img>); }}>
                                        <i className="fa fa-question-circle" aria-hidden="true"></i>
                                    </a>
                                </label>
                                <input type="number"
                                    name={db.Device.SERIAL_NUMBER}
                                    placeholder={t('Ex. 1100345')}
                                    value={this.state[db.Device.SERIAL_NUMBER]}
                                    onChange={this.onInputChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>
                                    {t('Last 4 digit mac address')}
                                    <a onClick={(e) => {e.preventDefault();swal(<img src={macAddressTutorialImg} height="130" width="300"></img>); }}>
                                        <i className="fa fa-question-circle" aria-hidden="true"></i>
                                    </a>
                                </label>
                                <input type="text"
                                    name={'last4digit'}
                                    placeholder={t('Ex. DG:ED')}
                                    value={this.state.last4digit}
                                    onChange={this.onInputChange}
                                />
                            </div>

                            <div className="actions">
                                <button type="button" className="submit-form-btn" onClick={e => this.linkDevice(e)}>{t('Add device')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

AddDeviceToRoomModal.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    roomId: PropTypes.string,
    onAfterSubmitSuccess: PropTypes.func
};

export default translate(AddDeviceToRoomModal);