'use strict';

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import i18next from './../i18n';
import Parse from 'parse';
import {get, merge} from 'lodash';
import axios from 'axios';
import translate from '../translate';
import swal from '@sweetalert/with-react';
import db from '../../lib/structure';
import serialNumberTutorialImg from '../../assets/images/page-start-configuration/back-label-example-serial.png';
import macAddressTutorialImg from '../../assets/images/page-start-configuration/back-label-example-mac-address.png';
import LanguagePicker from '../language-picker';
import logoImgMini from '../../assets/images/logo-mini.png';
import Loader from '../loader';

const paths = require('../../lib/paths');

class PageRegistrationAddDeviceAndRoom extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            rooms: [
                {
                    name: '',
                    serial: '',
                    macAddress: ''
                }
            ],
            homeName: '',
            city: '',
            popupOverIsOpen: false,
            invalid: {

            },
            errorMessages: {

            },
            currentStep: 'homeSetup',
            stepsInfo: {
                homeSetup: {
                    order: 2,
                    title: 'Setup your home'
                },
                roomsSetup: {
                    order: 3,
                    title: 'Link your device to the room'
                }
            },
            loading: {

            }
        };

        this.register = this.register.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
        this.changeInputField = this.changeInputField.bind(this);
        this.registerWithoutPingDeviceVerification = this.registerWithoutPingDeviceVerification.bind(this);
        this.togglePopover = this.togglePopover.bind(this);
        this.gotoRoomsSetupPage = this.gotoRoomsSetupPage.bind(this);
        this.gotoHomeSetupPage = this.gotoHomeSetupPage.bind(this);
        this.gotoPreviousStep = this.gotoPreviousStep.bind(this);
        this.areFieldsValid = this.areFieldsValid.bind(this);
        this.clearFormData = this.clearFormData.bind(this);
    }

    componentDidMount(){
        //let params = new URLSearchParams(this.props.location.search);

        let storedRooms = localStorage.getItem('registration-rooms') || '';
        let homeId = localStorage.getItem('homeId')|| '';
        let homeName = localStorage.getItem('registration-homeName')|| '';
        let email = localStorage.getItem('registration-email')|| '';
        let password = localStorage.getItem('registration-password')|| '';
        let city = localStorage.getItem('registration-city')|| '';

        let stateFromProps = {
            city,
            email,
            password,
            homeId,
            homeName,
            rooms: storedRooms ? JSON.parse(storedRooms) : [
                {
                    name: '',
                    serial: '',
                    macAddress: ''
                }
            ]
        };

        //Check if registration data has already been entered before
        this.setState(stateFromProps);

        document.currentComponent = this;
    }

    async register(event){
        event.preventDefault();

        let {t} = this.props;

        let verifyDevices = async (rooms) => {
            let result = await Parse.Cloud.run('registration-process-local-ip-device', {
                deviceSerials: rooms.map(room => room.serial)
            });

            let devices = [];

            for(let room of rooms){
                let serial = room.serial;
                let roomName = room.name;
                let localIp = result[serial];
                let macAddress = null;
                try {
                    let response = await axios.get(`http://${localIp}/ping`);

                    if(response.status === 200){
                        macAddress = response.data;
                    }
                } catch (e){
                    throw new Error(t(`Network error: Device ${serial} is not reachable. Please make sure it's correctly configured and connected to your wifi network.`))
                }

                devices.push({
                    serial,
                    localIp,
                    macAddress,
                    roomName
                });
            }

            return devices;
        };

        try { //Check connection to sensor
            //Save data to local storage

            // setter
            localStorage.setItem('registration-rooms', JSON.stringify(this.state.rooms));
            localStorage.setItem('registration-homeName', this.state.homeName);

            let verifiedDevices =  await verifyDevices(this.state.rooms);

            //await Parse.User.become(this.state.sessionToken, {});

            await Parse.Cloud.run('link-devices-to-rooms', {
                verifiedDevices,
                homeName: this.state.homeName
            });

            await swal({title: t('Success'), text: ' ', icon: 'success', button: [''], timer: 1000});

            this.props.history.push(paths.building.replace(':floorNumber', '0'));
        } catch (e) {
            console.error(e);
            this.setState({error: e.message})
        }
    }

    async registerWithoutPingDeviceVerification(event){
        let {t} = this.props;

        this.setState({loading: {accountCreation: true}});

        event.preventDefault();

        let verifyDevices = async (rooms) => {
            let verifiedDevicesSerials = await Parse.Cloud.run('registration-process-verify-devices', {
                devices: rooms.map(room => ({
                    serial: room.serial,
                    macAddress: room.macAddress
                }))
            });

            let roomErrors = false;

            for(let i = 0; i< rooms.length; i++){
                let serial = rooms[i].serial;
                if(verifiedDevicesSerials.indexOf(serial) < 0){
                    roomErrors = true;

                    this.setState(prev => {
                        prev.rooms[i].error = 'Device not found';
                    });
                }
            }

            if(roomErrors) throw new Error(t('Was not possible to verify one or more devices. Please check both serial number and last 4 digit of the MAC address.'));
        };

        try {
            //Verify devices
            await verifyDevices(this.state.rooms);

            let user = await Parse.User.signUp(this.state.email, this.state.password, {
                [db._User.REGISTRATION_METHOD]: db._User.REGISTRATION_METHOD$MANUALLY_REGISTERED,
                [db._User.TYPE_OF_CUSTOMER]: db.Home.TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER,
                [db._User.LANGUAGE]: localStorage.getItem('i18nextLng'),
                [db._User.EMAIL]: this.state.email
            });

            await user.fetch({fields: [db._User.LINKED_HOME]});

            let home = user.get(db._User.LINKED_HOME);

            home.set(db.Home.HOME_NAME, this.state.homeName || this.state.email);
            home.set(db.Home.TYPE_OF_CUSTOMER, db.Home.TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER);
            home.set(db.Home.LANGUAGE, this.props.i18n.resolvedLanguage);

            await home.save();

            //Save data to local storage
            localStorage.setItem('buildingSelection', home.id);
            localStorage.setItem('registration-rooms', JSON.stringify(this.state.rooms));
            localStorage.setItem('registration-homeName', this.state.homeName);
            localStorage.setItem('registration-city', this.state.city);

            await Parse.Cloud.run('link-devices-to-rooms', {
                verifiedRooms: this.state.rooms,
                homeName: this.state.homeName,
                homeId: home.id,
                language: localStorage.getItem('i18nextLng'),
                city: this.state.city
            });

            this.setState({loading: {accountCreation: false}});

            this.clearFormData();

            await swal({title: t('Success'), text: ' ', icon: 'success', button: [''], timer: 1000});

            this.props.history.push(paths.building.replace(':floorNumber', '0'));
        } catch (e) {
            console.error(e);
            this.setState({loading: {accountCreation: false}});
            swal(t('Error'), t(e.message), 'error');
        }
    }

    changeLanguage(language){
        i18next.changeLanguage(language);
        document.cookie = 'i18next=' + language;
    }

    goBack(event){
        event.preventDefault();

        this.props.history.goBack();
    }
    
    changeInputField(e){
        let name = e.target.name;
        let value = e.target.value;

        if(name === 'serial'){
            value = parseInt(value);
        }

        this.setState({[name]: value});
    }

    getUrlVars(){
        var vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
            function (m, key, value){
                vars[key] = value;
            });
        return vars;
    }

    updateRoom(e, index){
        let name = e.target.name;
        let value = e.target.value;

        if(name === 'serial'){
            value = parseInt(value);
        }

        this.setState(prev => {
            prev.rooms[index][name] = value;

            return prev;
        });
    }

    async togglePopover(){
        this.setState({popupOverIsOpen: !this.state.popupOverIsOpen})
    }

    areFieldsValid(){
        let {t} = this.props;

        let invalid = {};
        let errorMessages = {};
        let fieldsError = false;

        if(!this.state.homeName){
            invalid.homeName = true;
            errorMessages.homeName =  t('Home name is required.');
            fieldsError = true;
        }

        this.setState(prev => {
            prev.invalid = invalid;
            prev.errorMessages = errorMessages;
            return prev;
        });

        return fieldsError === false;
    }

    gotoRoomsSetupPage() {
        if(!this.areFieldsValid()) return;

        localStorage.setItem('registration-homeName', this.state.homeName);
        localStorage.setItem('registration-city', this.state.city);

        this.setState({
            currentStep: 'roomsSetup'
        });
    }

    gotoHomeSetupPage() {
        this.setState({
            currentStep: 'homeSetup'
        })
    }

    gotoPreviousStep(event){
        this.state.currentStep === 'homeSetup' ? (this.goBack.bind(this))(event) : this.gotoHomeSetupPage();
    }

    clearFormData() {
        localStorage.removeItem('registration-rooms');
        localStorage.removeItem('registration-homeName');
        localStorage.removeItem('registration-email');
        localStorage.removeItem('registration-password');
        localStorage.removeItem('registration-city');
    }

    render(){
        const {t} = this.props;

        const currentLanguage = this.props.i18n.resolvedLanguage;

        return <div className={'page-registration-email'}>
            {
                get(this.props, 'shared.generalError.visible') && <div className={'alert alert-danger'}>
                    {get(this.props, 'shared.generalError.message')}
                </div>
            }

            <div className={'back-image'}></div>
            <a className="auth-logo" onClick={() => this.props.history.push('/start')}
                href="#">{t('Cleveron')}</a>
            <div className="dropdown auth-dropdown">
                <button className="dropdown-toggle " type="button" id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false"
                    style={{float: 'right'}}
                >
                    {this.props.i18n.resolvedLanguage}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" href="#"
                        onClick={() => this.changeLanguage('en')}>{t('English')}</a>
                    <a className="dropdown-item" href="#"
                        onClick={() => this.changeLanguage('it')}>{t('Italian')}</a>
                    <a className="dropdown-item" href="#"
                        onClick={() => this.changeLanguage('fr')}>{t('French')}</a>
                    <a className="dropdown-item" href="#"
                        onClick={() => this.changeLanguage('de')}>{t('German')}</a>
                </div>
            </div>
            <div className="card auth-card card-login">
                <div className="registration-sidebar">
                    <div className="registration-sidebar-logo"><img src={logoImgMini} alt="Cleveron" /></div>
                    <div className="registration-sidebar-title">{t('Device Setup')}</div>
                </div>
                <div className="card-body" style={{overflowY: 'auto'}}>
                    <div className="mobile_signup_header">
                        <p>
                            <div style={{
                                display: 'inline-block',
                                width: '85%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                paddingLeft: '15%'
                            }}>
                                {t('Step')} {`${this.state.stepsInfo[this.state.currentStep].order}/3:`} <b>{t(this.state.stepsInfo[this.state.currentStep].title)}</b>
                            </div>
                        </p>
                        <div className="mobile_signup_header_posi">
                            <a onClick={this.gotoPreviousStep}><i className="fa fa-angle-left"></i><span>{t('Back')}</span></a>
                        </div>
                    </div>
                    <div className="m-b-36">
                        <LanguagePicker t={t} i18n={this.props.i18n} />
                    </div>
                    <div className="header">
                        <div className="step-header">
                            <span className="step-back" onClick={this.gotoPreviousStep}><a><i className="fa fa-long-arrow-left"></i></a></span>
                            <span className="step-title">{t('Step')}  {`${this.state.stepsInfo[this.state.currentStep].order}/3:`}</span>
                            <span className="title">{t(this.state.stepsInfo[this.state.currentStep].title)}</span>
                        </div>
                        <div className="auth-start-logo"><img src={logoImgMini} alt="Cleveron" /></div>
                        {/*<div className="subtitle">{t('Create the room where your device will be linked to.')}*/}
                        {/*</div>*/}
                        {
                            get(this.state, 'error') && <div className={'alert alert-danger'}>
                                {get(this.state, 'error')}
                            </div>
                        }

                        {
                            this.state.response && <div className={'alert'}>
                                {this.state.response}
                            </div>
                        }

                    </div>
                    <div className="mobile-rounded-borders">
                        <form className="p-b-32" autoComplete="off" onSubmit={this.registerWithoutPingDeviceVerification}>
                            <input autoComplete="off" name="hidden" type="text" style={{display: 'none'}}/>

                            {this.state.currentStep === 'roomsSetup' && (<div>
                                {
                                    this.state.rooms.map((room, i) => {
                                        return <Fragment key={i}>
                                            <div className="form-group room-name">
                                                <label htmlFor="roomName" className={'label'} style={{width: 'unset'}}>{t('Room name')}</label>
                                                <input type="text"
                                                    className="form-control"
                                                    id={`room-name-${i}`}
                                                    name={'name'}
                                                    aria-describedby=""
                                                    placeholder={t('Ex. Office')}
                                                    value={room.name}
                                                    onChange={e => this.updateRoom(e, i)}
                                                    autoComplete="asdfasdf"
                                                />
                                            </div>

                                            <div className="form-group serial">
                                                <label htmlFor="serial" className={'label'} style={{width: 'unset'}}>
                                                    {t('Device serial number')}
                                                    {/* eslint-disable-next-line max-len */}
                                                    <a onClick={(e) => { e.preventDefault(); swal(<img src={serialNumberTutorialImg}
                                                        height="130" width="300"></img>); }}>
                                                        <i className="fa fa-question-circle-o" aria-hidden="true" style={{fontSize: '1.2rem', marginLeft: '5px'}}></i>
                                                    </a>
                                                </label>
                                                <input type="number"
                                                    className="form-control"
                                                    id={`serial-${i}`}
                                                    name={'serial'}
                                                    aria-describedby=""
                                                    placeholder={t('Ex: 1700568')}
                                                    value={room.serial}
                                                    onChange={e => this.updateRoom(e, i)}
                                                />
                                            </div>

                                            <div className="form-group mac-address">
                                                <label htmlFor="macAddress" className={'label'} style={{width: 'unset'}}>
                                                    {t('Last 4 digit of mac address')}
                                                    <a onClick={(e) => {e.preventDefault(); swal(<img src={macAddressTutorialImg} height="130" width="300"></img>); }}>
                                                        <i className="fa fa-question-circle-o" aria-hidden="true" style={{fontSize: '1.2rem', marginLeft: '5px'}}></i>
                                                    </a>
                                                </label>
                                                <input type="text"
                                                    className="form-control"
                                                    id={`macAddress-${i}`}
                                                    name={'macAddress'}
                                                    aria-describedby=""
                                                    placeholder={t('Ex. AB:56')}
                                                    value={room.macAddress}
                                                    onChange={e => this.updateRoom(e, i)}
                                                />

                                            </div>

                                            <hr/>
                                        </Fragment>
                                    })
                                }

                                <div className="form-group">
                                    <div className="data-section__control-text">{t('After the registration it will be possible to add additional rooms and deviecs.')}</div>
                                </div>
                                {
                                    this.state.loading.accountCreation && <Loader/>
                                }
                                {
                                    !this.state.loading.accountCreation && <button type="submit"
                                        className="btn registration-flow-btn"
                                        id={'button-go-dashboard'}
                                    >
                                        {t('Go to your dashboard')}
                                    </button>
                                }
                            </div>)}

                            {this.state.currentStep === 'homeSetup' && (<div>
                                <div className="form-group home-name">
                                    <label htmlFor="homeName" className={'label'} style={{width: 'unset'}}>{t('Home name')}</label>
                                    <input type="text"
                                        className={`form-control ${this.state.invalid.homeName ? 'is-invalid' : ''}`}
                                        id="homeName"
                                        name={'homeName'}
                                        aria-describedby=""
                                        placeholder={t('Please enter you home name')}
                                        value={this.state.homeName}
                                        onChange={this.changeInputField}
                                    />
                                    {
                                        this.state.invalid.homeName && <small className="invalid-feedback">
                                            {this.state.errorMessages.homeName}
                                        </small>
                                    }
                                </div>

                                <div className="form-group city">
                                    <label htmlFor="city" className={'label'} style={{width: 'unset'}}>{t('City')}</label>
                                    <input type="text"
                                        className='form-control'
                                        id="city"
                                        name={'city'}
                                        aria-describedby=""
                                        placeholder={t('Please enter your city')}
                                        value={this.state.city}
                                        onChange={this.changeInputField}
                                    />
                                </div>

                                <button type="button"
                                    className="btn registration-flow-btn"
                                    id={'button-go-to-rooms-setup'}
                                    onClick={this.gotoRoomsSetupPage}
                                >{t('Continue')}
                                </button>
                            </div>)}
                        </form>
                    </div>
                    <div className="footer-links">
                        <a
                            target={'_blank'}
                            rel="noopener noreferrer"
                            href={`/assets/images/page-start-configuration/Sense_ConnectionGuide_B2C_${currentLanguage?.toUpperCase() ?? 'DE'}.pdf`}>{t('Download the user guide')}</a>
                    </div>
                </div>
            </div>
        </div>
    }
}

PageRegistrationAddDeviceAndRoom.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired,
    location: PropTypes.any
};
/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(PageRegistrationAddDeviceAndRoom));