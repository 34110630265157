'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import db from '../../../lib/structure';
import SupportRequestItem from './support-request-item';

class SupportRequestList extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            supportRequests: []
        };

        this.refreshList = this.refreshList.bind(this);
    }

    async componentDidMount(){
        await this.refreshList();
    }

    async refreshList(){
        let supportRequests = await new Parse.Query(db.classes.SupportRequest)
            .equalTo(db.SupportRequest.ROOM, this.props.room)
            .include(db.SupportRequest.USER)
            .include(db.SupportRequest.ROOM)
            .descending(db.SupportRequest.CREATED_AT)
            .notEqualTo(db.SupportRequest.DELETED, true)
            .find();

        this.setState({supportRequests});
    }

    render(){
        const {t} = this.props;

        return <div className={'support-request-item-list'}>
            {
                this.state.supportRequests.length === 0 && <h5>{t('No support requests so far.')}</h5>
            }
            {
                this.state.supportRequests.map(supportRequest => {
                    return <SupportRequestItem
                        {...this.props}
                        key={supportRequest.id}
                        supportRequest={supportRequest}
                        t={t}
                        refreshList={this.refreshList}
                    />;
                })
            }
        </div>;
    }
}

SupportRequestList.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    history: PropTypes.any.isRequired,
    room: PropTypes.object.isRequired
};

export default SupportRequestList;