import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as db from '../../../lib/structure';
import Loader from '../../loader';
import moment from 'moment';

export default class EnvironmentIndex extends Component {
    constructor(props){
        super(props);
        this.state = {
            co2: this.props.room.get(db.Room.AVG_CO2_LAST_HOUR),
            loading: true
        };

        this.getCO2Value = this.getCO2Value.bind(this);
    }

    async componentDidMount(){
        await this.getCO2Value();
        this.setState({loading: false});

    }

    async getCO2Value() {
        const {devices} = this.props;
        let isDemoAccount = localStorage.getItem('demoAccount') === 'true';
        let co2 = null;

        for (let device of devices) {
            let lastMeasurementDate = device.get(db.Device.LAST_MEASUREMENT_DATE);
            if (!isDemoAccount && moment(lastMeasurementDate).isBefore(moment().subtract(4, 'hours'))) continue;

            co2 = device.get(db.Device.CO2);
        }

        this.setState({co2});
    }

    render(){
        const {t} = this.props;
        const {co2, loading} = this.state;

        let co2Value = 0;
        let noCo2DataFound = false;

        if (co2 >= 1500) co2Value = '100%';
        if (co2 < 1500 && co2 >= 1000) co2Value = '70%';
        if (co2 < 1000)  co2Value = '20%';
        if (co2 == null) {
            co2Value = '50%';
            noCo2DataFound = true;
        }

        return <div className="environment-index">

            <div className="card card-outer side-card">
                <div className="environment-index-list">
                    <div className="content__heading environment-index__heading">{t('Environment index')}</div>

                    {
                        loading && <Loader size={'medium'}/> }
                    { !loading &&
                    <div className="environment-index__main environment">

                        <div className="environment__scale">
                            <span className="environment__scale-max">{t('Not optimal')}</span>
                            <span className="environment__scale-min">{t('Optimal')}</span>
                            <div className="environment__scale-main">
                                <div className="environment__scale-main-graph">
                                    <div className="environment__scale-main-graph-current" style={{height: co2Value}}>
                                        {!noCo2DataFound && <div className="environment__scale-main-graph-current-scale"/> }
                                        {!noCo2DataFound && <div className="environment__scale-main-graph-current-delimeter"/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            noCo2DataFound && <div className="environment__info">
                                {t('No CO2 data found')}
                            </div>
                        }
                    </div>
                    }
                </div>
            </div>
        </div>
    }
}

EnvironmentIndex.propTypes = {
    t: PropTypes.func,
    i18n: PropTypes.any,
    roomId: PropTypes.string,
    devices: PropTypes.array,
    room: PropTypes.object
};