'use strict';
import './assets/js/chartist-plugin-tooltip';
import './assets/js/script';

// import modules here
import React from 'react';
import ReactDOM from 'react-dom';
import {Route, Router, Switch} from 'react-router-dom';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import {portalApp} from './lib/reducer';
import promiseMiddleware from 'redux-promise-middleware';
import {createLogger} from 'redux-logger';
import es6Promise from 'es6-promise';
import {initializeParse} from './lib/parse-utils';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {createBrowserHistory} from 'history';
import * as paths from './lib/paths';
import * as db from './lib/structure';

import PullToRefresh from 'pulltorefreshjs';
import 'popper.js';
import 'bootstrap';
import './lib/polyfills';
import 'moment/locale/it';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.fr';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.de';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.it';

import './scss/main.scss';

// import components here
import NotFound from './components/not-found';
import LoginPage from './components/page-login';
import ForgotPasswordPage from './components/page-forgot-password';
import SystemDiagnosticsPage from './components/page-system-diagnostics';
import BuildingPage from './components/page-building';
import RoomPage from './components/page-room';
import ErrorPage from './components/page-error';
import DocumentsPage from './components/page-documents';
import PageProfile from './components/page-profile';
import PageBuildingSettings from './components/page-building-settings';
import UserManagementPage from './components/page-userManagement';
import ResetPasswordPage from './components/page-reset-password';
import InvalidLink from './components/page-invalid-link';
import PageFeedback from './components/page-feedback';
import AdminPanelPage from './components/page-admin-panel';
import FAQ from './components/page-faq';
import HolidayPage from './components/page-holiday';
import FuelHistory from './components/page-fuel-history';
import BuildingsPage from './components/page-buildings';
import RoomReport from './components/page-room-report';
import PageRegistrationEmail from './components/page-registration-email';
import PageRegistrationAddDeviceAndRoom from './components/page-registration-add-device-and-room';
import PageStart from './components/page-start';
import PageIntegrations from './components/page-integrations';
import PageStartConfiguration from './components/page-start-configuration';
import RoomManagement from './components/page-room-management';
import BuildingReport from './components/page-building-report';
import PageQrCodePortalDiscoverCleveron from './components/page-qr-code-portal-discover-cleveron/discover-cleveron';
import PageQrCodePortalDiscoverCleveronEmail
    from './components/page-qr-code-portal-discover-cleveron/discover-cleveron-email';
import PageSupportRequestOpenNew from './components/page-support-request-open-new/page-support-request-open-new';
import PageSupportRequestDiagnostic from './components/page-support-request-diagnostic/page-support-request-diagnostic';
import PageSupportRequestDetails from './components/page-support-request-details/page-support-request-details';
import PageBuildingOnboardForms from './components/page-building-onboard-forms/page-building-onboard-forms';
import PageCookieDeclaration from './components/page-cookie-declaration';
import PageBuildingSupportRequests from './components/page-building-support-requests';
import PageHelpdesk from './components/page-helpdesk';
import PageSupportRequestList from "./components/page-support-request-list";
import FloorPlanViewer from "./components/floor-plan-viewer";
import PageBuildingRoomTickets from "./components/page-building-room-tickets";

initializeParse();
es6Promise.polyfill();

const loggerMiddleware = createLogger();

const store = createStore(
    portalApp,
    applyMiddleware(
        promiseMiddleware(),
        loggerMiddleware
    )
);

const customHistory = createBrowserHistory();

document.body.addEventListener('swl', () => {
    customHistory.goForward();
}, false);
document.body.addEventListener('swr', () =>{
    customHistory.goBack();
}, false);

PullToRefresh.init({
    mainElement: 'body',
    onRefresh() {
        window.location.reload();
    }
});

if (NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://2c160081c46541e0ad31368d1b59544b@o1003745.ingest.sentry.io/5971669',
        integrations: [new Integrations.BrowserTracing()],
        environment: NODE_ENV,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 0.2,
    });
}

if(NODE_ENV === 'production'){
    let currentUser = localStorage.getItem('Parse/NjSUT8HxvCz706ldcwUn/currentUser') ?
        JSON.parse(localStorage.getItem('Parse/NjSUT8HxvCz706ldcwUn/currentUser')): null;
    let email = currentUser?.email;
    let typeOfCustomer = currentUser?.typeOfCustomer;
    let buildingCity = localStorage.getItem(`home:${db.Home.CITY}`);
    let buildingCountry =  localStorage.getItem(`home:${db.Home.COUNTRY}`);
    window.pagesense = window.pagesense || [];
    window.pagesense.push(['identifyUser', email]);
    window.pagesense.push(['typeOfCustomer', typeOfCustomer]);
    window.pagesense.push(['buildingCity', buildingCity]);
    window.pagesense.push(['buildingCountry', buildingCountry]);
    //Future: marketing scope, training status, important customer
}

if (document.getElementById('app')) {
    ReactDOM.render(
        (
            <Provider store={store}>
                {
                    NODE_ENV !== 'production' && <div id={'dev-info'}>
                        {/* eslint-disable-next-line react/jsx-no-literals */}
                        Server {Parse.serverURL}&nbsp;
                        {/* eslint-disable-next-line react/jsx-no-literals */}
                        {/* eslint-disable-next-line react/jsx-no-literals */}
                        NODE_ENV {NODE_ENV}&nbsp;
                        Width: {$('body').width()}
                        {/*SITE_ENV {SITE_ENV}<br/>HOST {HOST}*/}
                        {/* eslint-enable react/jsx-no-literals*/}
                    </div>
                }

                <Router history={customHistory}>
                    <Switch>
                        <Route exact path={paths.home} component={LoginPage}/>
                        <Route exact path={paths.analytics} component={BuildingReport}/>
                        <Route exact path={paths.login} component={LoginPage}/>
                        <Route exact path={paths.start} component={PageStart}/>
                        <Route exact path={paths.forgotPassword} component={ForgotPasswordPage}/>
                        <Route exact path={paths.profile} component={PageProfile}/>
                        <Route exact path={paths.buildingSettings} component={PageBuildingSettings}/>
                        <Route exact path={paths.dashboard} component={BuildingPage}/>
                        <Route exact path={paths.documents} component={DocumentsPage}/>
                        <Route exact path={paths.building} component={BuildingPage}/>
                        <Route exact path={paths.buildingLink} component={BuildingPage}/>
                        <Route exact path={paths.room} component={RoomPage}/>
                        <Route exact path={paths.error} component={ErrorPage}/>
                        <Route exact path={paths.userManagement} component={UserManagementPage}/>
                        <Route exact path={paths.resetPassword} component={ResetPasswordPage}/>
                        <Route exact path={paths.invalidLink} component={InvalidLink}/>
                        <Route exact path={paths.roomFeedback} component={PageFeedback}/>
                        <Route exact path={paths.roomFeedbackDiscoverCleveron}
                            component={PageQrCodePortalDiscoverCleveron}/>
                        <Route exact path={paths.roomFeedbackDiscoverCleveronFaq}
                            component={PageQrCodePortalDiscoverCleveronEmail}/>
                        <Route exact path={paths.holidays} component={HolidayPage}/>
                        <Route exact path={paths.helpdesk} component={PageHelpdesk}/>
                        <Route exact path={paths.adminPanel} component={AdminPanelPage}/>
                        <Route exact path={paths.fuelHistory} component={FuelHistory}/>
                        <Route exact path={paths.roomReport} component={RoomReport}/>
                        <Route exact path={paths.buildings} component={BuildingsPage}/>
                        <Route exact path={paths.integrations} component={PageIntegrations}/>
                        <Route exact path={paths.registrationEmail} component={PageRegistrationEmail}/>
                        <Route exact path={paths.registrationAddDeviceToRoom}
                            component={PageRegistrationAddDeviceAndRoom}/>
                        <Route exact path={paths.startConfiguration}
                            component={PageStartConfiguration}/>
                        <Route exact path={paths.roomManagement}
                            component={RoomManagement}/>
                        <Route exact path={paths.systemDiagnostics} component={SystemDiagnosticsPage}/>
                        <Route exact path={paths.supportRequestOpenNew} component={PageSupportRequestOpenNew}/>
                        <Route exact path={paths.supportRequestDiagnostic} component={PageSupportRequestDiagnostic}/>
                        <Route exact path={paths.supportRequestDetails} component={PageSupportRequestDetails}/>
                        <Route exact path={paths.onBoardBuilding} component={PageBuildingOnboardForms}/>
                        <Route exact path={paths.cookieDeclaration} component={PageCookieDeclaration}/>
                        <Route exact path={paths.buildingSupportRequests} component={PageBuildingSupportRequests}/>
                        <Route exact path={paths.floorPlan} component={FloorPlanViewer}/>
                        <Route exact path={paths.buildingOperationReport} component={PageBuildingRoomTickets}/>

                        <Route component={NotFound}/>
                    </Switch>
                </Router>
            </Provider>
        ),
        document.getElementById('app')
    );
}