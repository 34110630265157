'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {merge} from 'lodash';
import translate from '../../translate';
import {connect} from 'react-redux';
import * as db from '../../../lib/structure';
import swal from 'sweetalert';
import moment from 'moment-timezone';
import RoomIndicator from './room-indicator';
import energyIcon from '../../../assets/images/ic_room_energy.svg';
import humidityIcon from '../../../assets/images/ic_humidity.svg';
import temperatureIcon from '../../../assets/images/ic_temperature.svg';
import co2Icon from '../../../assets/images/ic_co2.svg';
import {getCategoryOfSaving, isValidCo2Value, toPointerFromId} from '../../../lib/util';

class RoomDetails extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            currentTempClient: 0,
            correctionTemp: 0,
            measuredTempDb: 0,
            lastMeasuredTemp: 0,
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.saveButtonPressed = this.saveButtonPressed.bind(this);
    }

    async componentDidMount() {
    }

    async saveButtonPressed() {
        const {t}  = this.props;
        let currentUser = Parse.User.current();
        let currentHomeId = localStorage.getItem('buildingSelection');

        try {
            let lastTemperature = this.props.cleverSense.get(db.Device.TEMPERATURE);
            let lastCorrectionTemperature = this.props.cleverSense.get(db.Device.ROOM_TEMP_CORRECTION) || 0;

            let {currentTempClient} = this.state;

            if (!currentTempClient || currentTempClient === '') {
                throw new Error(t('Please enter a valid number'));
            }

            currentTempClient = parseFloat(currentTempClient);

            let difference = currentTempClient - (lastTemperature - lastCorrectionTemperature);
            let differenceAbs = Math.abs(difference);

            if (differenceAbs > 4) {
                const errorMessage = 'The difference between our measurements and yours is too large. If your value is accurate, please contact support@cleveron.ch';
                let roomAction = new Parse.Object(db.classes.RoomAction);
                roomAction.set(db.RoomAction.ACTION_NAME, db.RoomAction.ACTION_NAME$TEMPERATURE_CALIBRATION);
                roomAction.set(db.RoomAction.CURRENT_TEMPERATURE, lastTemperature);
                roomAction.set(db.RoomAction.ROOM_TEMP_CORRECTION, lastCorrectionTemperature);
                roomAction.set(db.RoomAction.CALIBRATION_TEMPERATURE, currentTempClient);
                roomAction.set(db.RoomAction.ERROR, errorMessage);
                roomAction.set(db.RoomAction.USER, currentUser);
                roomAction.set(db.RoomAction.ROOM, this.props.room);
                roomAction.set(db.RoomAction.HOME, toPointerFromId(currentHomeId, db.classes.Home));
                roomAction.save();
                throw new Error(t(errorMessage));
            }

            let title = t('Success!');
            let text = t('The sensors has been calibrated. Changes will go in effect in approx. 20 minutes');

            this.props.room.set(db.Room.CORRECTION_TEMP, difference);
            this.props.room.set(db.Room.LAST_CALIBRATION_TEMP, currentTempClient);
            await this.props.room.save();

            swal({title, text, icon: 'success', button: false, timer: 3000});
        } catch (err) {
            let title = t('Error');
            swal(title, err.message, 'error');
            console.error(err);
        }
    }

    onInputChange(event){
        let currentTempClient = event.target.value;
        this.setState({currentTempClient});
    }

    render(){
        const {t} = this.props;

        let mainPhoto = this.props.room.get(db.Room.MAIN_PHOTO);
        let mainPhotoUrl = this.props.room.get(db.Room.MAIN_PHOTO_URL);
        let photoUrl = null;

        if(mainPhoto)
            photoUrl = mainPhoto._url;
        else {
            if(mainPhotoUrl){
                photoUrl = mainPhotoUrl;
            }
        }
        let showEstimatedRoomTemperature = this.props.room?.get(db.Room.SHOW_ESTIMATED_ROOM_TEMP_APP_CHART) ??
            this.props.home?.get(db.Home.SHOW_ESTIMATED_ROOM_TEMP_APP_CHART) ?? false;
        let avgTempLastHour = this.props.room.get(db.Room.AVG_TEMP_LAST_HOUR);
        let avgHumLastHour = this.props.room.get(db.Room.AVG_HUM_LAST_HOUR);
        let avgEstimatedTempLastHour =this.props.room.get(db.Room.AVG_ROOM_EST_TEMP_LAST_HOUR);
        let numberSensp = this.props.room.get(db.Room.NUMBER_SENSP) || 0;
        let numberSense = this.props.room.get(db.Room.NUMBER_SENSE)|| 0;
        let numberSenco = this.props.room.get(db.Room.NUMBER_SENCO)|| 0;
        let numberThermo = this.props.room.get(db.Room.NUMBER_RADIATORS)|| 0;
        let numberOfSensors = numberSense + numberSensp + numberSenco;
        let numberDevices = numberSensp + numberSense + numberSenco + numberThermo;
        let lastUpdateAvgTemp = this.props.room.get(db.Room.LAST_UPDATE_AVG_TEMP);
        let co2 = this.props.room.get(db.Room.CO2);
        let energySavingPercentage = this.props.room.get(db.Room.ENERGY_SAVING_PERCENTAGE);
        let differenceFromNowInMinutes = 100000;

        if(lastUpdateAvgTemp){
            lastUpdateAvgTemp = moment(lastUpdateAvgTemp);
            differenceFromNowInMinutes = Math.abs(lastUpdateAvgTemp.tz('Europe/Zurich').diff(moment(), 'minutes'));
        }

        if(differenceFromNowInMinutes >= 43200){
            avgEstimatedTempLastHour = null;
            avgHumLastHour = null;
            avgTempLastHour = null;
            co2 = null;
        }

        let displayedTemperature = showEstimatedRoomTemperature === true ? avgEstimatedTempLastHour : avgTempLastHour;
        if(isValidCo2Value(co2) && co2 < 400) co2 = 400;

        return (
            <React.Fragment>
                <div className="card card-outer room-view-outer">
                    {
                        this.props.showImage && <div className="room-view-inner">
                            <div className="card-img" style={{
                                backgroundImage: `url(${photoUrl})`,
                                backgroundSize: 'cover'
                            }}>
                                {
                                    !photoUrl && <img src={'/assets/images/upload-photo.png'}/>
                                }
                            </div>
                        </div>
                    }

                    <div className="card-info align-center room-indicator-list" style={{gap: '18px', justifyContent: 'start'}}>
                        {
                            numberDevices > 0 && <RoomIndicator
                                icon={temperatureIcon}
                                title={t('Room Temperature')}
                                value={displayedTemperature == null ? 'N/A' : `${parseFloat(displayedTemperature).toFixed(1)} °C`}
                                showCalibrateSensor={numberOfSensors > 0}
                            />
                        }

                        {
                            numberDevices > 0 && <RoomIndicator
                                icon={humidityIcon}
                                title={t('Humidity')}
                                value={avgHumLastHour == null ? 'N/A' : `${parseFloat(avgHumLastHour).toFixed(0)} %`}
                            />
                        }

                        {
                            energySavingPercentage != null && energySavingPercentage !== 0 &&
                            <RoomIndicator
                                icon={energyIcon}
                                title={t('Energy Saved')}
                                value={parseFloat(getCategoryOfSaving(energySavingPercentage)).toFixed(0) + ' %'}
                            />
                        }

                        {
                            co2 != null &&
                            <RoomIndicator
                                icon={co2Icon}
                                title={t('Co2 Level')}
                                value={co2 == null ? 'N/A' : `${co2} ppm`}
                            />
                        }
                        {
                            differenceFromNowInMinutes <= 180 && <small className={'mt-10'} style={{color: 'grey'}}>{t('Last update')}: {lastUpdateAvgTemp.tz('Europe/Zurich').format('DD/MM/YYYY HH:mm')}</small>
                        }
                    </div>
                </div>
                <div className="modal fade" id="calibrateSensor" tabIndex="-1" role="dialog" aria-labelledby="editRoomLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content edit-info">
                            <div className="modal-header">
                                <h5 className="modal-title">{t('Calibrate sensor:')}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <path fill="#222"
                                            d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="modal-body">
                                {
                                    displayedTemperature && differenceFromNowInMinutes <= 120 && <div>
                                        <p>{t('Current temperature:')} {displayedTemperature && parseFloat(displayedTemperature).toFixed(1)} °C</p>
                                        <p>{t('Please enter the temperature currently measured in this room:')}</p>
                                        <div className="form-group">
                                            <input className="" type="number" onChange={this.onInputChange} min="10" max="50" placeholder={`${displayedTemperature && parseFloat(displayedTemperature).toFixed(1)}°C`}/>
                                        </div>

                                        <div className="actions">
                                            <button onClick={this.saveButtonPressed} className="submit-form-btn" data-dismiss="modal">{t('Apply')}</button>
                                            <button className="cancel-form-btn" data-dismiss="modal">{t('Cancel')}</button>
                                        </div>
                                    </div>
                                }
                                {
                                    (!displayedTemperature || differenceFromNowInMinutes > 120)  && <p>{t('No measurements were registered in the last 1 hour. A calibration cannot be done in this circumstance. Please contact the support@cleveron.ch if this situation persists.')}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

RoomDetails.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    room: PropTypes.object,
    home: PropTypes.object,
    loadingEnergy: PropTypes.bool,
    energy: PropTypes.object,
    cleverSense: PropTypes.object,
    showImage: PropTypes.bool
};

RoomDetails.defaultProps = {
    showImage: true
}

/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(RoomDetails));