'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import i18next from './../i18n';
import {get, merge} from 'lodash';
import Parse from 'parse';

import translate from '../translate';
import * as paths from '../../lib/paths';

class ResetPasswordPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            username: '',
            new_password: '',
            confirm_new_password: '',
            buttonEnabled: false,
            path: '',
            id: '',
            token: ''
        };

        this.changeLanguage = this.changeLanguage.bind(this);
        this.checkPasswordMatch = this.checkPasswordMatch.bind(this);

    }

    componentDidMount(){

        var urlParams = {};
        (function (){
            var pair, // Really a match. Index 0 is the full match; 1 & 2 are the key & val.
                tokenize = /([^&=]+)=?([^&]*)/g,
                // decodeURIComponents escapes everything but will leave +s that should be ' '
                reSpace = function (s){
                    return decodeURIComponent(s.replace(/\+/g, ' '));
                },
                // Substring to cut off the leading '?'
                querystring = window.location.search.substring(1);

            //eslint-disable-next-line
            while (pair = tokenize.exec(querystring))
                urlParams[reSpace(pair[1])] = reSpace(pair[2]);
        }());

        let id = urlParams.id;

        let base = Parse.serverURL;

        document.getElementById('token').value = urlParams.token;

        let myUsername = document.getElementById('username').value = urlParams.username;

        if (myUsername === undefined || id === undefined) {
            return this.props.history.push(paths.login);
        }

        this.setState({
            path: `${base}/apps/${id}/request_password_reset`,
            username: document.getElementById('username').value = urlParams.username
        });

    }

    changeLanguage(language){
        i18next.changeLanguage(language);
        document.cookie = 'i18next=' + language;
    }

    checkPasswordMatch(e){

        this.setState({[e.target.name]: e.target.value});

        if (e.target.name === 'confirm_new_password') {

            if (this.state.new_password !== e.target.value) {
                this.setState({buttonEnabled: false});
            } else {
                this.setState({buttonEnabled: true});
            }
        }

        if (e.target.name === 'new_password') {

            if (this.state.confirm_new_password !== e.target.value) {
                this.setState({buttonEnabled: false});
            } else {
                this.setState({buttonEnabled: true});
            }
        }

    }

    render(){
        const {t} = this.props;

        return <div className={'login-page'}>
            {
                get(this.props, 'shared.generalError.visible') && <div className={'alert alert-danger'}>
                    {get(this.props, 'shared.generalError.message')}
                </div>
            }
            <div className={'back-image'}></div>
            <div className="auth-logo">{t('Cleveron')}</div>
            <div className="card auth-card card-login">
                <div className="card-body">
                    <div className="m-b-36">
                        <div className="dropdown pull-right auth-dropdown">
                            <button className="dropdown-toggle " type="button" id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                {this.props.i18n.resolvedLanguage}
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a className="dropdown-item" href="#"
                                    onClick={() => this.changeLanguage('en')}>{t('English')}</a>
                                <a className="dropdown-item" href="#"
                                    onClick={() => this.changeLanguage('it')}>{t('Italian')}</a>
                                <a className="dropdown-item" href="#"
                                    onClick={() => this.changeLanguage('fr')}>{t('French')}</a>
                                <a className="dropdown-item" href="#"
                                    onClick={() => this.changeLanguage('de')}>{t('German')}</a>
                            </div>
                        </div>
                    </div>
                    <div className="header">
                        <div className="title">{t('Reset Password')}</div>
                        <div className="subtitle">{t('New Password for')} {this.state.username}<span
                            id='username_label'></span>
                        </div>
                    </div>
                    <form className="p-b-32" id='form' action={this.state.path} method='POST'>
                        <div className="form-group">
                            <input name="username" id="username" type="hidden"/>
                            <input name="token" id="token" type="hidden"/>
                            <input type="password"
                                className="form-control"
                                id="password"
                                name="new_password"
                                aria-describedby=""
                                placeholder={t('New Password')}
                                onChange={this.checkPasswordMatch}
                            />
                        </div>
                        <div className="form-group m-b-32">
                            <input type="password"
                                className="form-control"
                                id="password_confirm"
                                name="confirm_new_password"
                                placeholder={t('Confirm new password')}
                                onChange={this.checkPasswordMatch}
                            />
                        </div>
                        <span id="password_match_info"></span>
                        <p style={{color: 'orange'}}>
                            {t('The password should follow the following rules')}:
                            <ul>
                                <li>{t('Password length at least 6 characters')}</li>
                                <li>{t('Password contains at least 3 of the 4 following categories: Upper case letters, lower case letters, numbers, special characters.')}</li>
                                <li>{t('Password must not contain the account name or more than 2 consecutive characters of the user\'s plain name')}</li>
                            </ul>
                        </p>
                        <button type="submit" id="change_password"
                            className="btn" disabled={!this.state.buttonEnabled}>{t('Apply')}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    }
}

ResetPasswordPage.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    history: PropTypes.any.isRequired,
};
/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(ResetPasswordPage));