'use strict';

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import translate from '../translate';
import Parse from 'parse';
import {floorNameFromNumber, manageError, redirectIfNotLogged, toPointerFromId, userIsInRole} from '../../lib/util';
import * as db from '../../lib/structure';
import paths from '../../lib/paths';
import logoImg from '../../assets/images/logo.png';
import logoImgSmall from '../../assets/images/logo-small.png';
import roomsIcon from '../../assets/images/rooms.svg';

class Sidebar extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            navigationOpen: true,
            openedMobile: false
        };

        this.getRooms = this.getRooms.bind(this);
        this.sidebarClick = this.sidebarClick.bind(this);
    }

    go(path){
        $('html').css('position', 'absolute');
        $('.sidebar').removeClass('opened-mobile');
        this.props.history.push(path)
    }

    sidebarClick(){
        if ($(window).width() > 768) {
            $('.sidebar').toggleClass('active');
            $('.content').toggleClass('active');
            $('.navbar').toggleClass('active');
        } else {
            $('.sidebar').addClass('opened-mobile');
            $('.chat').hide();
            $('html').css('position', 'fixed');
        }
    }

    async componentDidMount(){
        await redirectIfNotLogged(this.props.history);

        $('body').on('click', '.sidebar-dropmenu', function (){
            $(this).siblings('li.active').toggleClass('opened-dropmenu');
        });

        let floorNames = await this.getRooms();
        let currentHome = localStorage.getItem('buildingSelection');

        let home;
        try {
             home = await new Parse.Query(db.classes.Home).get(currentHome);
        } catch (e) {
            home = null;
        }

        this.setState({floorNames, home});
    }

    async getRooms(){
        let currentHome = localStorage.getItem('buildingSelection');
        let query = new Parse.Query('Room');
        query.notEqualTo(db.Room.HIDDEN, true);
        query.notEqualTo(db.Room.DELETED, true);
        query.equalTo('home', toPointerFromId(currentHome, 'Home'));
        query.limit(1000);

        try {
            let rooms = await query.find();
            let floors = {};

            rooms.forEach(room => {
                let floor = room.get(db.Room.FLOOR);

                if (!floors[floor]) {
                    floors[floor] = [];
                }

                floors[floor].push(room);
            });

            let floorNames = Object.keys(floors).sort((a, b) => a - b).map(floorNumber => {
                return {
                    name: floorNameFromNumber(floorNumber),
                    number: floorNumber
                }
            });

            return floorNames;
        } catch (err) {
            return await manageError(err, this.props);
        }
    }

    render(){
        const {t} = this.props;

        const typeOfCustomer = localStorage.getItem(`home:${db.Home.TYPE_OF_CUSTOMER}`);
        const hasReadPermission = localStorage.getItem('user:hasReadPermission') === 'true';
        const isBA = localStorage.getItem('user:isAdmin') === 'true';
        const isAdminRole = userIsInRole(db.roles.ADMIN);
        const isSupportRole = userIsInRole(db.roles.SUPPORT);
        const isZIDBuilding = localStorage.getItem('buildingSelection') === 'X6EitoEDpX';
        const isDataCollectionOpen = this.state.home?.get(db.Home.DATA_COLLECTION_STATUS) === db.Home.DATA_COLLECTION_STATUS$OPEN;

        return <Fragment>
            <nav className="sidebar">
                <div className="sidebar-header">
                    <button className="sidebarCollapse open-active" onClick={this.sidebarClick}/>
                    <div className="logo header__logo">
                        <a className="logo__wrap" href="/">
                            <img className="logo__img img-responsive" src={logoImg} alt="logo"
                                width="135" title="" id="logo_big"/>
                            <img className="logo__img img-responsive" src={logoImgSmall} alt="logo"
                                title="" id="logo_small"/>
                        </a>
                    </div>
                    <div className="close-nav">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <path fill="#9b9b9b"
                                d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                        </svg>
                    </div>
                </div>

                {
                    isDataCollectionOpen === false && this.state.home != null && <ul className="list-unstyled">
                        {
                            hasReadPermission && <Fragment>
                                <li
                                    className={`sidebar-menu-item ${this.props.location.pathname.startsWith('/building/') ? 'active' : ''}`}>
                                    <a href="#" onClick={() => this.go(paths.building.replace(':floorNumber', '0'))}>
                                        <i className="ico dashboard-ico"/>
                                        {t('Building')}
                                    </a>
                                </li>

                                <li className={`sidebar-menu-item ${this.props.location.pathname === paths.buildingSupportRequests ? 'active' : ''}`}>
                                    <a href="#" onClick={() => this.go(paths.buildingSupportRequests)}>
                                        <i className="ico system-diagnostics-ico"/>
                                        {t('Support requests')}
                                    </a>
                                </li>
                                <li
                                    className={`sidebar-menu-item ${this.props.location.pathname === paths.analytics ? 'active' : ''}`}>
                                    <a href="#" onClick={() => this.go(paths.analytics)}>
                                        <i className="ico analytics-icon"/>
                                        {t('Analytics')}
                                    </a>
                                </li>
                            </Fragment>
                        }

                        {
                            typeOfCustomer === db._User.TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER && <Fragment>
                                <li className={this.props.location.pathname === paths.roomManagement ? 'active' : ''}>
                                    <a href="#" onClick={() => this.go(paths.roomManagement)}>
                                        <img src={roomsIcon} style={{
                                            width: 24,
                                            height: 24,
                                            marginRight: 11
                                        }}/>
                                        {t('Room Management')}
                                    </a>
                                </li>

                                {
                                    hasReadPermission && <li className={this.props.location.pathname === paths.adminPanel ? 'active' : ''}>
                                        <a href="#" onClick={() => this.go(paths.adminPanel)}>
                                            <i className="ico admin-panel-ico"/>
                                            {t('Admin Panel')}
                                        </a>
                                    </li>
                                }

                                {
                                    (isBA || isSupportRole || isAdminRole) &&
                                    <li className={this.props.location.pathname === paths.userManagement ? 'active' : ''}>
                                        <a className="wrapForFrench" href="#" onClick={() => this.go(paths.userManagement)}>
                                            <i className="ico userManagement-ico"></i>
                                            <div style={{}}>
                                                {t('User Management')}
                                            </div>
                                        </a>
                                    </li>
                                }

                                <li className={`sidebar-menu-item ${this.props.location.pathname === paths.integrations ? 'active' : ''}`}>
                                    <a href="#" onClick={() => this.go(paths.integrations)}>
                                        <i className="fa fa-exchange" aria-hidden="true"></i>
                                        {t('API')}
                                    </a>
                                </li>
                            </Fragment>
                        }

                        {
                            typeOfCustomer === db._User.TYPE_OF_CUSTOMER$FULL_SYSTEM_CUSTOMER &&
                            <Fragment>
                                {
                                    hasReadPermission && <li className={this.props.location.pathname === paths.adminPanel ? 'active' : ''}>
                                        <a href="#" onClick={() => this.go(paths.adminPanel)}>
                                            <i className="ico admin-panel-ico"/>
                                            {t('Admin Panel')}
                                        </a>
                                    </li>
                                }

                                {
                                    (isBA || isSupportRole || isAdminRole)  &&
                                    <li className={this.props.location.pathname === paths.userManagement ? 'active' : ''}>
                                        <a className="wrapForFrench" href="#" onClick={() => this.go(paths.userManagement)}>
                                            <i className="ico userManagement-ico"></i>
                                            <div style={{}}>
                                                {t('User Management')}
                                            </div>
                                        </a>
                                    </li>
                                }

                                {
                                    hasReadPermission &&
                                    <li className={this.props.location.pathname === paths.fuelHistory ? 'active' : ''}>
                                        <a href="#" onClick={() => this.go(paths.fuelHistory)}>
                                            <i className="ico fuel-history-ico"></i>
                                            {t('Consumption history')}
                                        </a>
                                    </li>
                                }

                                {
                                    hasReadPermission &&
                                    <li className={this.props.location.pathname === paths.holidays ? 'active' : ''}>
                                        <a href="#" onClick={() => this.go(paths.holidays)}>
                                            <i className="ico holiday-ico"></i>
                                            {t('Holidays')}
                                        </a>
                                    </li>
                                }

                                <li className={`sidebar-menu-item ${this.props.location.pathname === paths.integrations ? 'active' : ''}`}>
                                    <a href="#" onClick={() => this.go(paths.integrations)}>
                                        <i className="fa fa-exchange" aria-hidden="true"></i>
                                        {t('API')}
                                    </a>
                                </li>
                            </Fragment>
                        }
                    </ul>
                }
                {
                    isDataCollectionOpen === true && this.state.home != null && <ul className={'list-unstyled'}>
                        <li className={this.props.location.pathname?.startsWith(paths.onBoardBuilding.replace(':buildingId', ''))  ? 'active' : ''}>
                            <a className="wrapForFrench" href="#">
                                <i className="ico userManagement-ico"></i>
                                <div style={{}}>
                                    {t('Collect information')}
                                </div>
                            </a>
                        </li>
                    </ul>
                }
            </nav>
        </Fragment>;
    }
}

Sidebar.propTypes = {
    t: PropTypes.any,
    location: PropTypes.object,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired,
    typeOfCustomer: PropTypes.string
};

export default translate(Sidebar);