import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import swal from 'sweetalert';
import Parse from 'parse';
import db from '../../../lib/structure';
import {addCloseButtonToDatepicker, toPointerFromId} from '../../../lib/util';
import i18next from '../../i18n';

class CardCreateCalendarEvent extends Component {

    constructor(props){
        super(props);

        let nextFiveMins = moment().format('mm') % 15;

        this.state = {
            dateFrom: moment().add(15 - nextFiveMins, 'minutes'),
            dateTo: moment().add(15 - nextFiveMins + 15, 'minutes'),
            heating: 'heat',
            home: ''
        };

        this.initDatePicker = this.initDatePicker.bind(this);
        this.displayTime = this.displayTime.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.saveButtonPressed = this.saveButtonPressed.bind(this);
        this.verifyInput = this.verifyInput.bind(this);
        this.createDBEvent = this.createDBEvent.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }

    displayTime(name) {
        const timeFormat = 'HH:mm';
        let timesArray = [];
        let time = moment('00:00', timeFormat);
        timesArray.push(time.format(timeFormat));

        // fill array with 00:00 to 23:45
        for (let i = 0; i < 95; i++) {
            timesArray.push(time.add(15, 'minutes').format(timeFormat));
        }

        return timesArray.map(time => {
            return <label key={time} className="dropdown-item__label dropdown-item select-reason">
                {time}
                <input type="radio" name={name}
                    aria-describedby="12" value={time} onChange={this.handleInputChange}
                />
            </label>
        });

    }

    async componentDidMount() {
        this.initDatePicker();
        i18next.on('languageChanged', this.handleLanguageChange);

        this.setState({home: this.props.room.get(db.Room.HOME)});
    }

    componentWillUnmount(){
        i18next.off('languageChanged', this.handleLanguageChange);
    }

    initDatePicker(){
        $('#dateFromEvent').datepicker({
            todayHighlight: true,
            format: 'dd M yyyy',
            autoclose: true,
            maxViewMode: 0,
            forceParse: false,
            language: this.props.i18n.resolvedLanguage,
            weekStart: 1
        })
            .on('changeDate', (e) => {
                let date = moment(e.date);
                this.setState({dateFrom: this.state.dateFrom.year(date.year()).month(date.month()).date(date.date())});
            })
            .on('show', (e) => {
                addCloseButtonToDatepicker(e.currentTarget.id, this.props.t);
            });

        $('#dateToEvent').datepicker({
            todayHighlight: true,
            format: 'dd M yyyy',
            autoclose: true,
            maxViewMode: 0,
            forceParse: false,
            language: this.props.i18n.resolvedLanguage,
            weekStart: 1
        })
            .on('changeDate', (e) => {
                let date = moment(e.date);
                this.setState({dateTo: this.state.dateTo.year(date.year()).month(date.month()).date(date.date())});
            })
            .on('show', (e) => {
                addCloseButtonToDatepicker(e.currentTarget.id, this.props.t);
            });
    }

    handleLanguageChange(){
        $('#dateFromEvent').datepicker('destroy');
        $('#dateToEvent').datepicker('destroy');

        this.initDatePicker();
    }

    handleInputChange(e) {
        let hourMinute = e.target.value.split(':');
        if (e.target.name === 'startTime') {
            this.setState({dateFrom: this.state.dateFrom.hour(hourMinute[0]).minute(hourMinute[1])});
        }

        if (e.target.name === 'endTime') {
            this.setState({dateTo: this.state.dateTo.hour(hourMinute[0]).minute(hourMinute[1])});
        }

        if(e.target.name === 'heat') {
            if (e.target.value !== 'checked') this.setState({heating: 'heat'});
        }

        if (e.target.name === 'no-heat') {
            if (e.target.value !== 'checked') this.setState({heating: 'no-heat'});
        }

    }

    verifyInput(){
        let {dateFrom, dateTo} = this.state;
        const {t} = this.props;

        if (moment(dateTo).isBefore(moment(dateFrom))) {
            let messageText = t('Invalid Date');
            swal(messageText, '', 'error');
            return false;
        }
        if (moment(dateTo).isBefore(moment())) {
            let messageText = t('End date already happened');
            swal(messageText, '', '');
            return false;
        }
        return true;

    }

    async saveButtonPressed() {
        let validInput = this.verifyInput();
        if (validInput) {
            await this.createDBEvent();
            await this.props.sortEvents();
        }
    }

    async createDBEvent(){
        const {t} = this.props;

        try {

            let CalendarEvent = Parse.Object.extend(db.classes.CalendarEvent);
            let calendarEvent = new CalendarEvent();
            let startDate = this.state.dateFrom.toDate();
            let endDate = this.state.dateTo.toDate();
            let swalText = t('Created Event');

            calendarEvent.set(db.CalendarEvent.START_DATE, startDate);
            calendarEvent.set(db.CalendarEvent.END_DATE, endDate);
            calendarEvent.set(db.CalendarEvent.ACTIVE, true);
            calendarEvent.set(db.CalendarEvent.EVENT_TYPE, this.state.heating);
            calendarEvent.set(db.CalendarEvent.HOME, toPointerFromId(this.state.home.id, 'Home'));
            calendarEvent.set(db.CalendarEvent.ROOM, toPointerFromId(this.props.room.id, 'Room'));

            await calendarEvent.save();
            swal(swalText, '', 'success');

        } catch (err) {
            console.error(err);
            swal('Error', err.message, 'error');
        }

    }

    render() {

        const {t}  = this.props;
        const dateFormat = 'DD MMM YYYY';
        const timeFormat = 'HH:mm';
        const language = this.props.i18n.resolvedLanguage;
        const hasWritePermission = localStorage.getItem('user:hasWritePermission') === 'true';

        return (
            <div className="room-create-calendar-event">
                <div className="card card-outer side-card left-col full-width">
                    <div className="card-header vacation-header d-flex justify-content-between align-items-center">
                        <div className="card-title mb-0">
                            <h2 className="mb-0">{t('Create calendar event')}</h2>
                        </div>
                    </div>
                    <div className="card-wrap mb-0">
                        <div id="calendar-event-form">
                            <div className="row calendar-event-radio">
                                <label className="radio__label">
                                    {t('Heat')}
                                    <input
                                        type="radio"
                                        name="heat"
                                        aria-describedby="Heat"
                                        value="heat"
                                        onChange={this.handleInputChange}
                                        checked={this.state.heating === 'heat'}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                                <label className="radio__label">
                                    {t('Don\'t heat')}
                                    <input
                                        type="radio"
                                        name="no-heat"
                                        aria-describedby="Don't heat"
                                        value="no-heat"
                                        onChange={this.handleInputChange}
                                        checked={this.state.heating === 'no-heat'}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>

                            <div className="row">
                                <div className="col-5 pr-0">
                                    <label htmlFor="dateFromEvent" className="datepicker__label">{t('Start date')}</label>
                                    <input type="text" name="dateFromEvent" className="p-2 datepicker-input w-100" readOnly
                                        id="dateFromEvent"
                                        value={this.state.dateFrom.locale(language).format(dateFormat)}/>
                                </div>
                                <div className="col-1 pickers__divider d-flex justify-content-center align-items-end">
                                    <span className="range-divider">-</span>
                                </div>
                                <div className="col-4 col-lg-3 pr-0 pl-0 calendar-event-select">
                                    <label htmlFor="dropdownMenuButtonStart1">{t('Start time')}</label>
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-secondary dropdown-toggle select-reason-button"
                                            type="button"
                                            id="dropdownMenuButtonStart1"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            {this.state.dateFrom.format(timeFormat)}
                                        </button>
                                        <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButtonStart1">
                                            {this.displayTime('startTime')}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-5 pr-0">
                                    <label htmlFor="dateToEvent" className="datepicker__label">{t('End date')}</label>
                                    <input type="text" name="dateToEvent" className="p-2 datepicker-input w-100" readOnly
                                        id="dateToEvent"
                                        value={this.state.dateTo.locale(language).format(dateFormat)}/>
                                </div>
                                <div className="col-1 pickers__divider d-flex justify-content-center align-items-end">
                                    <span className="range-divider">-</span>
                                </div>
                                <div className="col-4 col-lg-3 pr-0 pl-0 calendar-event-select">
                                    <label htmlFor="dateFromEvent">{t('End time')}</label>
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-secondary dropdown-toggle select-reason-button"
                                            type="button"
                                            id="dropdownMenuButtonEnd1"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            {this.state.dateTo.format(timeFormat)}
                                        </button>
                                        <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButtonEnd1">
                                            {this.displayTime('endTime')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div className="buttons-wr">

                                <button id="btn-save--event" className="btn btn-green"
                                    disabled={!hasWritePermission}
                                    onClick={this.saveButtonPressed}>{t('Save')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

CardCreateCalendarEvent.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    roomId: PropTypes.string,
    sortEvents: PropTypes.func,
    room: PropTypes.object,
};

export default CardCreateCalendarEvent;