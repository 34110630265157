'use strict';
/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import {get, merge, orderBy} from 'lodash';
import Parse from 'parse';

import moment from 'moment';

import {connect} from 'react-redux';
import translate from '../translate';
import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import Footer from '../footer';
import * as db from '../../lib/structure';
import {redirectIfNotLogged, toPointerFromId} from '../../lib/util';

class DocumentsPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            documents: []
        };

        this.loadDocumentDownloads = this.loadDocumentDownloads.bind(this);
    }

    componentDidMount(){
        redirectIfNotLogged(this.props.history);
        this.loadDocumentDownloads();
    }

    async loadDocumentDownloads(){
        try {
            let currentHome = localStorage.getItem('buildingSelection');
            let query = new Parse.Query(db.classes.DocumentDownload);
            query.equalTo(db.DocumentDownload.HOME, toPointerFromId(currentHome, db.classes.Home));
            query.equalTo(db.DocumentDownload.TYPE, 'energy_report');
            query.equalTo(db.DocumentDownload.STATUS, 'public');

            let documents = await query.find();

            documents = documents.map(doc => {
                let key = doc.get(db.DocumentDownload.KEY);

                if(!key) return doc;

                let year = parseInt(key.split('-')[1]);
                let month = parseInt(key.split('-')[0]);

                doc.set('month', month);
                doc.set('year', year);
                doc.set('date', moment().year(year).month(month -1 ).endOf('month'));

                return doc;
            });

            documents = orderBy(documents, ['attributes.year', 'attributes.month'], ['desc', 'desc']);

            this.setState({documents})
        } catch (e) {
            console.error(e);
        }
    }

    render(){
        const {t} = this.props;

        let monthsLabel = {
            1: t('January'),
            2: t('February'),
            3: t('March'),
            4: t('April'),
            5: t('May'),
            6: t('June'),
            7: t('July'),
            8: t('August'),
            9: t('September'),
            10: t('October'),
            11: t('November'),
            12: t('December')
        };

        return <div className={'page-download'}>
            {
                get(this.props, 'shared.generalError.visible') && <div className={'alert alert-danger'}>
                    {get(this.props, 'shared.generalError.message')}
                </div>
            }

            <div className="wrapper d-flex align-items-stretch">
                <Sidebar {...this.props}/>
                <div className="content">
                    <Navbar {...this.props}/>
                    <div className="inner">
                        <Breadcrumbs {...this.props} elements={[
                            {
                                link: '/dashboard',
                                name: t('Home')
                            },
                            {
                                link: null,
                                name: t('Reports')
                            }
                        ]}/>
                        <h1>{t('Reports')}</h1>
                        <div
                            className="grid-cols flex-xs-column-reverse flex-sm-column-reverse flex-md-column-reverse
                            flex-lg-row">
                            <div className="card card-outer full-w no-padding">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="table-head name"><strong>{t('Document name')}</strong></th>
                                            <th className="table-head date"><strong>{t('Date created')}</strong></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.documents.map(document => {
                                                let month = document.get(db.DocumentDownload.KEY).split('-')[0];
                                                let year = document.get(db.DocumentDownload.KEY).split('-')[1];

                                                return <tr className="table-row" key={document.id}>
                                                    <td>
                                                        <a href={document.get(db.DocumentDownload.URL)} download target={'_blank'}
                                                            rel="noopener noreferrer"
                                                            className="download-link">
                                                            <i className="ico-document"></i>
                                                            <span>{`${t('Energy report')} ${monthsLabel[month]} ${year}.pdf`}</span>
                                                        </a>
                                                        <small
                                                            className="m-download-date">{moment(document.get(db.DocumentDownload.END_DATE)).format('DD MMM YYYY')}</small>
                                                    </td>
                                                    <td className="hide-sm">
                                                        <span>{moment(document.get(db.DocumentDownload.DATE)).format('DD MMM YYYY')}</span>
                                                    </td>
                                                    <td>
                                                        <a href={document.get(db.DocumentDownload.URL)}
                                                            rel="noopener noreferrer"
                                                            target="_blank" className="table-download-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24">
                                                                <g fill="none" fillRule="evenodd">
                                                                    <path fill="#ccc" fillRule="nonzero"
                                                                        d="M7.58139535,11.1906977 C7.24651163,10.8837209 6.71627907,10.8837209 6.40930233,11.2186047 C6.10232558,11.5534884 6.10232558,12.0837209 6.4372093,12.3906977 L11.4604651,17.0790698 C11.627907,17.2186047 11.8232558,17.3023256 12.0186047,17.3023256 C12.2139535,17.3023256 12.4372093,17.2186047 12.5767442,17.0790698 L17.6,12.3906977 C17.9348837,12.0837209 17.9627907,11.5534884 17.627907,11.2186047 C17.3209302,10.8837209 16.7906977,10.855814 16.455814,11.1906977 L12.8837209,14.5674419 L12.8837209,0.976744186 C12.8837209,0.502325581 12.5209302,0.139534884 12.0465116,0.139534884 C11.572093,0.139534884 11.2093023,0.502325581 11.2093023,0.976744186 L11.2093023,14.5674419 L7.58139535,11.1906977 Z"/>
                                                                    <path fill="#ccc" fillRule="nonzero"
                                                                        d="M21.255814,5.44186047 L16.2325581,5.44186047 C15.7581395,5.44186047 15.3953488,5.80465116 15.3953488,6.27906977 C15.3953488,6.75348837 15.7581395,7.11627907 16.2325581,7.11627907 L20.4186047,7.11627907 L20.4186047,22.1860465 L3.6744186,22.1860465 L3.6744186,7.11627907 L7.86046512,7.11627907 C8.33488372,7.11627907 8.69767442,6.75348837 8.69767442,6.27906977 C8.69767442,5.80465116 8.33488372,5.44186047 7.86046512,5.44186047 L2.8372093,5.44186047 C2.3627907,5.44186047 2,5.80465116 2,6.27906977 L2,23.0232558 C2,23.4976744 2.3627907,23.8604651 2.8372093,23.8604651 L21.255814,23.8604651 C21.7302326,23.8604651 22.0930233,23.4976744 22.0930233,23.0232558 L22.0930233,6.27906977 C22.0930233,5.80465116 21.7302326,5.44186047 21.255814,5.44186047 Z"/>
                                                                    <rect width="24" height="24"/>
                                                                </g>
                                                            </svg>
                                                        </a>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {
                            this.state.documents.length === 0 && <div className={'no-document-text'}>
                                {t('No document available')}</div>
                        }

                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    }
}

DocumentsPage.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired
};

/**
 * Maps state from redux to props of the component. use this only for container-component.
 *
 * @param state
 * @returns {{languages: Array|*|string[]}}
 */
const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(DocumentsPage));