import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import swal from 'sweetalert';
import db from '../../../lib/structure.js';
import i18next from '../../i18n';
import {addCloseButtonToDatepicker} from '../../../lib/util';

class EditEventModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            event: '',
            eventName: '',
            dateFrom: moment(),
            dateTo: moment(),
        };
        this.initDatePicker = this.initDatePicker.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.updateButtonPressed = this.updateButtonPressed.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }

    componentDidMount() {
        i18next.on('languageChanged', this.handleLanguageChange);
    }

    componentWillUnmount(){
        i18next.off('languageChanged', this.handleLanguageChange);
    }

    handleLanguageChange(){
        $('#date-picker-from-edit').datepicker('destroy');
        $('#date-picker-to-edit').datepicker('destroy');

        this.initDatePicker();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.event !== this.props.event) {
            this.setState({event:this.props.event});
        }

        this.getEventData();
        this.initDatePicker();
    }

    getEventData() {
        const {event} = this.props;

        if (!event || !event.id) return;

        let eventName = event.get(db.CalendarEvent.EVENT_NAME);
        let dateFrom = moment(event.get(db.CalendarEvent.START_DATE));
        let dateTo = moment(event.get(db.CalendarEvent.END_DATE));

        if (this.state.event.id !== event.id) {
            this.setState({eventName, dateFrom, dateTo});

        }

    }

    async updateButtonPressed(){
        let validInput = this.verifyInput();
        if (validInput) {
            await this.editDBEvent();
            await this.props.fetchEvents();
        }

    }

    verifyInput(){
        let {dateFrom, dateTo} = this.state;
        const {t} = this.props;

        if (moment(dateTo).isBefore(moment(dateFrom))) {
            let messageText = t('Invalid Date');
            swal(messageText, '', 'error');
            return false;
        }
        if (moment(dateTo).isBefore(moment())) {
            let messageText = t('End date already happened');
            swal(messageText, '', '');
            return false;
        }
        return true;

    }

    handleNameChange(e){
        this.setState({eventName: e.target.value});
    }

    initDatePicker(){
        $('#date-picker-from-edit').datepicker({
            todayHighlight: true,
            format: 'dd M yyyy',
            autoclose: true,
            maxViewMode: 0,
            forceParse: false,
            id: 'test1',
            orientation: 'top',
            language: this.props.i18n.resolvedLanguage,
            weekStart: 1
        })
            .on('show', (e) => {
                $('.datepicker-dropdown').show(() => {
                    $('.datepicker-dropdown').attr('id', 'centerDatepicker')
                })
                addCloseButtonToDatepicker(e.currentTarget.id, this.props.t);
            })
            .on('changeDate', (e) => {
                let date = moment(e.date);
                this.setState({dateFrom: this.state.dateFrom.year(date.year()).month(date.month()).date(date.date())});
            });

        $('#date-picker-to-edit').datepicker({
            todayHighlight: true,
            format: 'dd M yyyy',
            autoclose: true,
            maxViewMode: 0,
            forceParse: false,
            id: 'test2',
            language: this.props.i18n.resolvedLanguage,
            weekStart: 1
        })
            .on('show', () => {
                $('.datepicker-dropdown').show(() => {
                    $('.datepicker-dropdown').attr('id', 'centerDatepicker')
                })
            })
            .on('changeDate', (e) => {
                let date = moment(e.date);
                this.setState({dateTo: this.state.dateTo.year(date.year()).month(date.month()).date(date.date())});
            });
    }

    async editDBEvent(){
        const {t} = this.props;
        const {event, eventName} = this.state;

        try {

            event.set(db.CalendarEvent.EVENT_NAME, eventName);

            let startDate = this.state.dateFrom.toDate();
            let endDate = this.state.dateTo.toDate();
            let swalText = t('Created Event');

            event.set(db.CalendarEvent.START_DATE, startDate);
            event.set(db.CalendarEvent.END_DATE, endDate);

            await event.save();
            swal(swalText, '', 'success');

        } catch (err) {
            console.error(err);
            swal('Error', err.message, 'error');
        }

    }

    render(){
        const {t} = this.props;
        const {eventName, dateFrom, dateTo} = this.state;
        const language = this.props.i18n.resolvedLanguage;

        const dateFormat = 'DD MMMM YYYY';

        return (

            <div className="modal fade" id="editEvent" tabIndex="-1" role="dialog"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content edit-info">
                        <div className="modal-header">
                            <h5 className="modal-title">{t('Edit Calendar Event')}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                    <path fill="#222"
                                        d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                </svg>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="text">
                                <div className="form-group">
                                    <label>{t('Event Name')}</label>
                                    <input type="text" placeholder={eventName} value={this.state.eventName} onChange={this.handleNameChange}/>
                                </div>

                                <div className="form-group">
                                    <label>{t('Start')}</label>
                                    <div className="datepicker-outer-full-width">
                                        <input type="text" className="datepicker-input" readOnly id="date-picker-from-edit"
                                            value={dateFrom.locale(language).format(dateFormat)}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label>{t('End')}</label>
                                    <div className="datepicker-outer-full-width">
                                        <input type="text" className="datepicker-input" readOnly id="date-picker-to-edit"
                                            value={dateTo.locale(language).format(dateFormat)}
                                        />
                                    </div>
                                </div>

                                <div className="actions">
                                    <button className="submit-form-btn" onClick={this.updateButtonPressed}
                                        data-dismiss="modal">{t('Update')}</button>
                                    <button className="cancel-form-btn" data-dismiss="modal">{t('Cancel')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EditEventModal.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    event: PropTypes.object,
    fetchEvents: PropTypes.func,
};

export default EditEventModal;
