'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import translate from '../translate';
import Footer from '../footer';

class PageSuportRequestList extends React.Component {
    constructor(props){
        super(props);

        this.state = {};
    }

    async componentDidMount(){

    }

    render(){
        const {t} = this.props;

        return <div className={'system-diagnostics'}>
            <div className="wrapper d-flex align-items-stretch">
                <Sidebar {...this.props}/>
                <div className="content">
                    <Navbar {...this.props}/>
                    <div className="inner">
                        <Breadcrumbs {...this.props} elements={[
                            {
                                link: null,
                                name: t('All support requests')
                            }
                        ]}/>
                        <div className='system-diagnostics-header'>
                            <div className='system-diagnostics-header-title full-w'>
                                <button className='room-reporting-export-button' style={{float: 'left'}}
                                    onClick={() => this.props.history.push({
                                        pathname: '/buildings'
                                    })}
                                >
                                    {t('Return to buildings')}
                                </button>
                                <h1 style={{textAlign: 'center'}}>
                                    {t('Building data collection for building')}
                                </h1>
                            </div>
                        </div>
                        <div className='card card-outer full-w' style={{padding: '20px'}}>

                        </div>
                    </div>
                    <Footer {...this.props}/>
                </div>
            </div>
        </div>
    }
}

PageSuportRequestList.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.any.isRequired,
    location: PropTypes.object
};

export default translate(PageSuportRequestList);
