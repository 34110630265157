'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default class RelativeOccupationFigure extends React.Component {
    constructor(props){
        super(props);

        const relativeOccupationFigure = props.relativeOccupationFigure;

        this.state = {
            relativeOccupationFigure
        };
    }

    componentDidMount() {
        this.initChart(this.props);
    }

    initChart(props){
        const {t} = props;

        const data = props.relativeOccupationFigure.map((relativeOccupation) => {
            return {name: relativeOccupation.x, y: relativeOccupation.y, z: 10};
        });

        Highcharts.chart('relativeOccupationFigure', {
            chart: {
                type: 'column',
            },

            title: {
                text: ''
            },

            subtitle: {
                text: ''
            },
            yAxis: {
                title: {
                    text: t('Relative occupation')
                }
            },

            xAxis: {
                type: 'category',
                labels: {
                    rotation: -45,
                    align: 'right'

                }
            },

            colors:[
                '#7ace4c'

                /*
                '#72e7ff',
                '#7ace4c',
                '#fdd74d',
                '#ff9a58',
                '#ff5858',
                '#ffc6b8',
                '#fdede5',
                '#e7fbff',
                '#e3e3e3',
                '#f0f0f0',
                '#e5f7ec',*/
            ],

            caption: {
                text: t('The relative occupation is calculated over the full 24 hours of a day.')
            },

            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    dataLabels: [{
                        enabled: true,
                        color: '#000',
                        style: {fontWeight: 'bolder'},
                        formatter: function() {return ''},
                        inside: true,
                        rotation: 0
                    }, {
                        enabled: true,
                        color: '#000',
                        style: {fontWeight: 'bolder'},
                        formatter: function() {return Math.round(this.point.y) + '%'},
                        inside: false,
                        rotation: 0
                    }],
                    groupPadding: 0
                }
            },

            series: [{
                name: 'Labor Costs',
                data,
                tooltip: {
                    pointFormat: `${t('Relative occupation')}: <b>{point.y}%</b>`
                },
                colorByPoint: true
            }]
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.month !== this.props.month) this.initChart(nextProps);
        if (!_.isEqual(
            nextProps.relativeOccupationFigure.map(item => item.y),
            this.props.relativeOccupationFigure.map(item => item.y))
        )
            this.initChart(nextProps);
    }

    render() {
        const {t} = this.props;

        return <div className="card card-outer climate-outer-bottom" style={{marginBottom: 20}}>
            <div className="card-header">
                <div className="card-title climate-control d-flex ">
                    <h2>{t('Relative occupation')}</h2>
                </div>
            </div>
            <div className="chart-lines__outer">
                <div className={'windows-opening-distribution-figure-outer'}>
                    <figure className="highcharts-figure">
                        <div id="relativeOccupationFigure"></div>
                    </figure>
                </div>
            </div>
        </div>;
    }
}

RelativeOccupationFigure.propTypes = {
    t: PropTypes.any,
    month: PropTypes.number,
    year: PropTypes.number,
    relativeOccupationFigure: PropTypes.array
};