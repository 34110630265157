import React, {Component, Fragment} from 'react';
import Sidebar from '../sidebar';
import Navbar from '../navbar';
import Breadcrumbs from '../breadcrumbs';
import Footer from '../footer';
import translate from '../translate';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import db from '../../lib/structure.js';
import Parse from 'parse';
import Loader from '../loader';
import {floorNameFromNumber, redirectIfNotLogged, toPointerFromId} from '../../lib/util';
import swal from 'sweetalert';
import {merge} from 'lodash';
import deleteIcon from '../../assets/images/ic_delete_grey.svg';

class RoomManagement extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading: true,
            rooms: [],
            roomName: '',
            floor: '',
            room: ''
        };

    }

    async componentDidMount() {
        await redirectIfNotLogged(this.props.history);
        await this.getRooms();
    }

    async getRooms() {
        let homeId = localStorage.getItem('buildingSelection');
        let query = new Parse.Query(db.classes.Room);
        query.equalTo(db.Room.HOME, toPointerFromId(homeId, db.classes.Home));
        query.notEqualTo(db.Room.DELETED, true);
        query.notEqualTo(db.Room.HIDDEN, true);
        query.limit(1000);
        let rooms = await query.find();
        this.setState({rooms, loading: false});
    }

    async deleteConfirmed() {
        const {room} = this.state;
        const {t} = this.props;

        let swalTitle = t('Deleted');
        let swalText = t('Room has been deleted');

        try {
            let query = new Parse.Query(db.classes.Room);
            let roomDB = await query.get(room.id);
            roomDB.set(db.Room.DELETED, true);
            await roomDB.save();
            await this.getRooms();
            swal(swalTitle, swalText, 'success');

        } catch (err) {
            console.error(err);
            swal('Error', err, 'error');
        }
    }

    async deleteIconClicked(room) {
        const {t} = this.props;
        let roomName = room.get(db.Room.ROOM_NAME);
        let floor = t(floorNameFromNumber(room.get(db.Room.FLOOR)));
        this.setState({room, roomName, floor});

    }

    render() {
        const {t} = this.props;
        const {roomName, floor, rooms, loading} = this.state;

        return (
            <Fragment>
                <div className="page-room-management">
                    <div className="wrapper d-flex align-items-stretch">
                        <Sidebar {...this.props}/>
                        <div className="content">
                            <Navbar {...this.props}/>
                            <div className="inner page-room-management-container">
                                <Breadcrumbs {...this.props} elements={[
                                    {
                                        link: '/dashboard',
                                        name: t('Home')
                                    },
                                    {
                                        link: null,
                                        name: t('Room Management')
                                    }
                                ]}/>
                                <div className="d-flex">
                                    <h1>{t('Room Management')}</h1>
                                </div>
                                <div className="grid-cols flex-xs-column-reverse flex-sm-column-reverse flex-md-column-reverse
                            flex-lg-row">

                                    <div className="card card-outer full-w">
                                        { loading &&  <Loader size={'medium'}/>}
                                        { (rooms.length === 0 && !loading) &&  t('No room data available')}
                                        { rooms.length > 0 &&
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        {t('Room')}
                                                    </th>
                                                    <th className="hide-lg">
                                                        {t('Floor')}
                                                    </th>
                                                    <th className="hide-lg">
                                                        {t('Action')}
                                                    </th>
                                                    <th className="hide-header"/>
                                                    <th className="hide-header"/>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    rooms && rooms.map(room => {
                                                        return <tr key={room.id}>
                                                            <td>
                                                                <a href="#" className="ml-auto align-items-center link" onClick={() => this.props.history.push(`/room/${room.id}`)}>
                                                                    {room.get(db.Room.ROOM_NAME)}
                                                                </a>
                                                                <button className="edit-modal-btn"
                                                                    data-toggle="modal"
                                                                    data-target="#deleteRoom"
                                                                    id="deleteButton-prm-small"
                                                                    onClick={() => this.deleteIconClicked(room)}
                                                                >
                                                                    <img alt="delete" src={deleteIcon}/>
                                                                </button>
                                                                <small className='m-download-date'>
                                                                    {/* eslint-disable-next-line max-len */}
                                                                    {t(floorNameFromNumber(room.get(db.Room.FLOOR)))}
                                                                </small>
                                                            </td>
                                                            <td className='hide-sm'>{t(floorNameFromNumber(room.get(db.Room.FLOOR)))}</td>
                                                            <td className='delete-btn-small' id='deleteButton-prm'>
                                                                <button className="edit-modal-btn"
                                                                    data-toggle="modal"
                                                                    data-target="#deleteRoom"
                                                                    id=""
                                                                    onClick={() => this.deleteIconClicked(room)}
                                                                >
                                                                    <img alt="delete" src={deleteIcon}/>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        }
                                    </div>
                                </div>
                            </div>
                            <Footer/>
                        </div>
                    </div>
                </div>
                
                {/*Delete Modal*/}
                <div className="modal fade" id="deleteRoom" tabIndex="-1" role="dialog"
                    aria-labelledby="deleteEventLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content edit-info">
                            <div className="modal-header">
                                <h5 className="modal-title"
                                    id="deleteEventLabel">{t('Are you sure you want to delete this room?')}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                        viewBox="0 0 18 18">
                                        <path fill="#222"
                                            d="M10.0612662,8.99987492 L14.7801172,4.28102395 C15.0732943,3.98809698 15.0732943,3.51280982 14.7801172,3.21988285 C14.48694,2.92670572 14.0121532,2.92670572 13.718976,3.21988285 L9.00012508,7.93873382 L4.28102395,3.21988285 C3.98784682,2.92670572 3.51305997,2.92670572 3.21988285,3.21988285 C2.92670572,3.51280982 2.92670572,3.98809698 3.21988285,4.28102395 L7.93898397,8.99987492 L3.21988285,13.7187259 C2.92670572,14.0116529 2.92670572,14.48694 3.21988285,14.779867 C3.36647141,14.9262054 3.55858748,14.9994997 3.7504534,14.9994997 C3.94231932,14.9994997 4.13443539,14.9262054 4.28102395,14.7796169 L9.00012508,10.0607659 L13.718976,14.7796169 C13.8655646,14.9262054 14.0576807,14.9994997 14.2495466,14.9994997 C14.4414125,14.9994997 14.6335286,14.9262054 14.7801172,14.7796169 C15.0732943,14.4866899 15.0732943,14.0114027 14.7801172,13.7184757 L10.0612662,8.99987492 Z"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="text">
                                    <table className="delete-event-card mb-2">
                                        <tbody>
                                            <tr>
                                                <td>{t('Room Name')}:</td>
                                                <td>{roomName}</td>
                                            </tr>
                                            <tr>
                                                <td>{t('Floor')}:</td>
                                                <td>{floor}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="actions">
                                    <button type="submit" className="submit-form-btn" data-dismiss="modal"
                                        onClick={(e) => this.deleteConfirmed(e)}>{t('Yes')}</button>
                                    <button className="cancel-form-btn" data-dismiss="modal">{t('Cancel')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>

        );
    }
}

RoomManagement.propTypes = {
    t: PropTypes.any,
    i18n: PropTypes.object,
    dispatch: PropTypes.func,
    shared: PropTypes.object,
    history: PropTypes.any.isRequired
};

const mapStateToProps = state => {

    return merge(
        {},
        {},
        {
            shared: state.shared
        });
};

export default connect(mapStateToProps)(translate(RoomManagement));