'use strict';

import React from 'react';
import PropTypes from 'prop-types';

class Loader extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
        let size = this.props.size || 'medium';

        return <span className={'loader-container'}>
            {this.props.message}
            <span className={`loader loader--${size}`} style={this.props.style}>&nbsp;</span>
        </span>;
    }
}

Loader.propTypes = {
    size: PropTypes.oneOf([
        'medium',
        'large'
    ]),
    message: PropTypes.string,
    style: PropTypes.string
};

Loader.defaultProps = {
    size: 'medium'
};

export default Loader;