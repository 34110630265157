'use strict';

import React, {Fragment} from 'react';
import PropType from 'prop-types';

import translate from '../translate';

class NotFound extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
        const {t} = this.props;

        return <Fragment>
            <article className="grid-container grid-x">
                <section className="cell">
                    <h1>{t('Not found')}</h1>
                </section>
            </article>
        </Fragment>;
    }
}

NotFound.propTypes = {
    t: PropType.func
};

export default translate(NotFound);
