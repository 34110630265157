import db from './structure';
import moment from 'moment';
import swal from 'sweetalert';
import {toPointerFromId, userIsInRole} from './util';
import React from 'react';

export  function renderRoomTickets (roomTickets, t, update) {
    let isSupportRole = userIsInRole(db.roles.SUPPORT);
    let isAdminRole = userIsInRole(db.roles.ADMIN);

    return <table className="table">
        <thead>
            <tr>
                <th>
                    {t('Reference number')}
                </th>
                <th>
                    {t('Date')}
                </th>
                <th className="show-desktop">
                    {t('Room')}
                </th>
                <th className="show-desktop">
                    {t('Category')}
                </th>
                <th className="show-desktop">
                    {t('Notes')}
                </th>
                {
                    (isSupportRole || isAdminRole) && <th className="show-desktop">
                        {t('Actions')}
                    </th>
                }
            </tr>
        </thead>
        <tbody>
            {
                roomTickets.map(roomTicket => {
                    let referenceNumber = roomTicket.get(db.RoomTicket.CRM_OPERATION_REFERENCE_NUMBER);
                    let room = roomTicket.get(db.RoomTicket.ROOM);
                    let issueCategory = roomTicket.get(db.RoomTicket.ISSUE_CATEGORY);
                    let category = issueCategory.get(db.IssueCategory.CATEGORY_KEY);
                    let subCategory = issueCategory.get(db.IssueCategory.SUB_CATEGORY_KEY);
                    let createdAt = roomTicket.get(db.RoomTicket.CREATED_AT);
                    let note = roomTicket.get(db.RoomTicket.NOTE);

                    createdAt = createdAt != null ? moment(createdAt) : null;

                    return  <tr key={roomTicket.id}>
                        <td>{referenceNumber}</td>
                        <td>{createdAt && createdAt.format('DD/MM/YYYY')}</td>
                        <td>
                            <a href={`/room/${room.id}`}>
                                {room?.get(db.Room.ROOM_NAME)}
                            </a>
                        </td>
                        <td>{t(`RoomTicket.category.${category}.${subCategory}`)}</td>
                        <td>{note}</td>
                        {
                            (isSupportRole || isAdminRole) && <td>
                                <a href={'javascript: ;'} onClick={async () => {
                                    try {
                                        let text = await swal({
                                            heightAuto: false,
                                            title: 'Enter text',
                                            content: {
                                                element: 'input',
                                                attributes: {
                                                    placeholder: 'Enter text',
                                                    type: 'text',
                                                    value: note
                                                },
                                            }
                                        });

                                        if(!text) return;

                                        roomTicket.set(db.RoomTicket.NOTE, text);

                                        await roomTicket.save();
                                        update();

                                        swal('Success', '', 'success');
                                    } catch (e) {
                                        console.error(e);
                                        swal('Error', e.message, 'error');
                                    }
                                }}>Edit text</a><br/>
                                <a href={'javascript: ;'} onClick={async () => {
                                    try {
                                        let categories = await new Parse.Query(db.classes.IssueCategory)
                                            .descending(db.IssueCategory.CATEGORY_KEY)
                                            .find();

                                        let buttons = {
                                            cancel: 'Cancel'
                                        };

                                        categories.forEach(category => buttons[category.id]=`${category.get(db.IssueCategory.CATEGORY_KEY)}.${category.get(db.IssueCategory.SUB_CATEGORY_KEY)}`);
                                        let selectedCategory = await swal('Select a category', {
                                            buttons
                                        });

                                        if(!selectedCategory) return;

                                        roomTicket.set(db.RoomTicket.ISSUE_CATEGORY, toPointerFromId(selectedCategory, db.classes.IssueCategory));

                                        await roomTicket.save();

                                        update();

                                        swal('Success', '', 'success');
                                    }catch (e) {
                                        console.error(e);
                                        swal('Error', e.message, 'error');
                                    }

                                }}>Edit category</a>
                            </td>
                        }

                    </tr>
                })
            }
            {
                roomTickets.length === 0 && <p style={{marginTop: 20}}>{t('There are no records to show.')}.</p>
            }
        </tbody>
    </table>
}
