import React from 'react';
import PropType from 'prop-types';
import settingsIcon from '../../../assets/images/ic_settings.svg';

export default class RoomIndicator extends React.Component {

    constructor(props){
        super(props);
    }

    render() {
        const {title, icon, value, showCalibrateSensor} = this.props;
        if (value == null) {
            return '';
        }

        return (
            <div className='room-indicator-container'>
                <div>
                    <img src={icon} className='room-indicator-icon' />
                </div>
                <div>
                    <div className='room-indicator-title'>{title}</div>
                    <div className='room-indicator-value-container'>
                        <h2 className='room-indicator-value'>{value}</h2>
                        {showCalibrateSensor && <div style={{marginLeft: '15px'}}>
                            <img src={settingsIcon} style={{cursor: 'pointer'}} data-toggle="modal" data-target="#calibrateSensor" />
                        </div>}
                    </div>
                </div>
            </div>
        );
    }
}

RoomIndicator.propTypes = {
    icon: PropType.string,
    value: PropType.string,
    title: PropType.string,
    showCalibrateSensor: PropType.bool
};