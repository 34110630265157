import PropTypes from 'prop-types';

import React, {Component} from 'react';
import translate from '../translate';
import db from '../../lib/structure';
import swal from 'sweetalert';
import Parse from 'parse';
import {copyACLToObject, manageError, toPointerFromId} from '../../lib/util';
import {VerifaliaRestClient} from 'verifalia';

const isValidEmail = require('is-valid-email');

const compress = new Compress();

const verifalia = new VerifaliaRestClient({
    username: 'fca8acfd21c14cbe84e96ce20a175e63',
    password: 'x3U)vHVgESqU'
});

class CreateRoom extends Component {

    constructor(props) {
        super(props);

        this.addRoomAccordeon = React.createRef();

        this.handleInputChange = this.handleInputChange.bind(this);
        this.createRoom = this.createRoom.bind(this);
        this.saveButtonPressed = this.saveButtonPressed.bind(this);
        this.validateField = this.validateField.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.shouldDisableSaveButton = this.shouldDisableSaveButton.bind(this);

        this.state = this.getInitialState(props);
    }

    getInitialState(props) {
        return  {
            comment: '',
            email: localStorage.getItem('room-feedback-email') || '',
            validEmail: !!localStorage.getItem('room-feedback-email'),
            emailTouched: !!localStorage.getItem('room-feedback-email'),
            emailLoading: false,
            validationEmailTexts: [],
            validatedEmail: localStorage.getItem('room-feedback-email') || '',
            homeId: props.homeId,
            roomId: props.roomId,
            commentIsBlank: true,
            commentTouched: false,
            file: null,
            fileName: null,

            sensorPhoto: null,
            sensorPhotoFileName: null,

            roomTypes: [
                {
                    key: 'classroom',
                    value: 'Classroom'
                },
                {
                    key: 'corridor',
                    value: 'Corridor'
                },
                {
                    key: 'office',
                    value: 'Office'
                },
                {
                    key: 'meeting-room',
                    value: 'Meeting room'
                },
                {
                    key: 'break-room',
                    value: 'Break room'
                },
                {
                    key: 'entry',
                    value: 'Entry'
                },
                {
                    key: 'wc',
                    value: 'WC'
                }
            ],

            roomDimensions: [
                {
                    key: 'small',
                    value: 'Small'
                },
                {
                    key: 'medium',
                    value: 'Medium'
                },
                {
                    key: 'big',
                    value: 'Big'
                },
            ],

            radiatorDimensions: [
                {
                    key: 'very-small',
                    value: 'Very small'
                },
                {
                    key: 'small',
                    value: 'Small'
                },
                {
                    key: 'normal',
                    value: 'Normal'
                },
                {
                    key: 'big',
                    value: 'Big'
                },
            ],

            roomSensor: false,
            roomSunInsolation: false,
            roomType: null,
            roomDimension: null,
            roomRadiatorDimension: null,
            roomName: '',
            roomFloor: null,
            roomRadiatorsCount: null,
            roomSensorPosition: '',
            roomCode: '',

            roomRadiatorDimensionTouched: false,
            roomDimensionTouched: false,
            roomTypeTouched: false
        };
    }

    componentDidMount(){
        const t = this.props.t;

        compress.attach('#addRoomFileUpload', {
            size: 4,
            quality: .75
        }).then((data) => {
            let file = data[0];

            if (file.initialHeightInPx > file.initialWidthInPx) {
                return swal(
                    t('Error'),
                    t('The width of the image should be bigger that it\'s height. If you are using the phone please take the photo with the phone in horizontal position.'),
                    'error'
                );
            }

            this.setState({file: data[0]});
        })
            .catch(manageError);

        compress.attach('#sensorPhoto', {
            size: 4,
            quality: .75
        }).then((data) => {
            let file = data[0];

            if (file.initialHeightInPx > file.initialWidthInPx) {
                return swal(
                    t('Error'),
                    t('The width of the image should be bigger that it\'s height. If you are using the phone please take the photo with the phone in horizontal position.'),
                    'error'
                );
            }

            this.setState({sensorPhoto: data[0]});
        })
            .catch(manageError);
    }

    async validateEmail(e){
        if(this.state.validEmail && this.state.validatedEmail === this.state.email) return;
        let email = e ? e.target.value : this.state.email;

        if(email === '' || email == null)
            return this.setState({validEmail: false, emailTouched: true});

        this.setState({emailLoading: true});

        if(!isValidEmail(email))
            return this.setState({validEmail: false, emailTouched: true, emailLoading: false, validationEmailTexts: [
                'The email is invalid. Please check that is contains the symbol "@" and that the domain refers to a valid mailbox.',
            ]});

        let result = await verifalia
            .emailValidations
            .submit(email, true);

        let classification = result.entries[0].classification;

        if(classification === 'Deliverable' || classification === 'Risky') {
            this.setState({validEmail: true, emailTouched: true, emailLoading: false, validatedEmail: email});
            localStorage.setItem('room-feedback-email', email);
        } else {
            this.setState({validEmail: false, emailTouched: true, emailLoading: false, validationEmailTexts: [
                'The email is invalid. Please check that is contains the symbol "@" and that it refers to a valid mailbox.',
                'Contact our <a href="mailto:support@cleveron.ch">support</a> if your e-mail was not recognised as valid.'
            ]});
        }
    }

    resetForm(){
        this.setState(this.getInitialState(this.props));
    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
        if (e.target.attributes && e.target.attributes.getNamedItem('required') !== null) {
            this.validateField(e);
        }
    }

    validateField(e) {
        if (!e.target.value) {
            this.setState({[e.target.name + 'IsBlank']: true, [e.target.name + 'Touched']: true});
        } else {
            this.setState({[e.target.name + 'IsBlank']: false, [e.target.name + 'Touched']: true});
        }
    }

    async saveButtonPressed() {
        if (!this.shouldDisableSaveButton()) {
            const {t} = this.props;
            try {
                let wantsToAddRoom = await swal({
                    title: t('Are you sure?'),
                    text: t('Are you sure you want to add the room?'),
                    buttons: true
                });

                if (wantsToAddRoom) {
                    let file = null;
                    if (this.state.file) {
                        var parseFile = new Parse.File('photo.png', {base64: this.state.file.data});
                        file = await parseFile.save();
                    }

                    let sensorPhoto = null;
                    if (this.state.sensorPhoto) {
                        var sensorFile = new Parse.File('sensor-photo.png', {base64: this.state.sensorPhoto.data});
                        sensorPhoto = await sensorFile.save();
                    }

                    let room = new Parse.Object(db.classes.Room);
                    room.set(db.Room.ROOM_NAME, this.state.roomName);
                    room.set(db.Room.FLOOR, parseInt(this.state.roomFloor));
                    room.set(db.Room.NUMBER_RADIATORS, parseInt(this.state.roomRadiatorsCount));
                    room.set(db.Room.SENSOR, !!this.state.roomSensor);
                    room.set(db.Room.SENSOR_POSITION, this.state.roomSensorPosition);
                    room.set(db.Room.ROOM_SIZE, this.state.roomDimension.key);
                    room.set(db.Room.ROOM_TYPE, this.state.roomType.key);
                    room.set(db.Room.RADIATORS_SIZE, this.state.roomRadiatorDimension.key);
                    room.set(db.Room.SUN_RADIATED, !!this.state.roomSunInsolation);
                    room.set(db.Room.ROOM_CODE, this.state.roomCode);
                    room.set(db.Room.QR_CODE_PORTAL_EMAIL, this.state.validatedEmail);
                    room.set(db.Room.HOME, toPointerFromId(this.props.homeId, 'Home'));

                    if (file) {
                        room.set(db.Room.MAIN_PHOTO, file);
                    } else {
                        room.set(db.Room.MAIN_PHOTO_URL, 'https://ik.imagekit.io/sh/shutterstock_575394781_xugf8fHgRAXwT.jpg');
                    }

                    if (sensorPhoto) {
                        room.set(db.Room.SENSOR_PHOTO, sensorPhoto);
                    }
                    let home = await (new Parse.Query(db.classes.Home)).get(this.props.homeId);
                    copyACLToObject(home, room);
                    await room.save();

                    await swal({title: t('Success'), text: ' ', icon: 'success', button: [''], timer: 1000});
                    this.resetForm();
                    this.props.onAdd();
                }
            } catch (err) {
                console.error(err);
                swal('Error', err.message, 'error');
            }
        }
    }

    shouldDisableSaveButton() {
        return !this.state.roomType ||
        !this.state.roomRadiatorDimension ||
        !this.state.roomDimension ||
        !this.state.roomName ||
        this.state.roomFloor === null ||
        this.state.roomRadiatorsCount === null ||
            !this.state.validEmail;
    }

    async createRoom(){
        const {t} = this.props;
        try {

            let file = null;
            if (this.state.file) {
                var parseFile = new Parse.File('facility-manager-feedback-image.png', {base64: this.state.file.data});
                file = await parseFile.save();
            }

            let feedback = new Parse.Object(db.classes.RoomFMFeedback);
            feedback.set(db.RoomFMFeedback.COMMENT, this.state.comment);
            feedback.set(db.RoomFMFeedback.EMAIL, this.state.validatedEmail);
            feedback.set(db.RoomFMFeedback.HOME, toPointerFromId(this.props.homeId, 'Home'));
            feedback.set(db.RoomFMFeedback.ROOM, toPointerFromId(this.props.roomId, 'Room'));

            if (file) {
                feedback.set(db.RoomFMFeedback.FILE, file);
            }
            await feedback.save();
            swal(t('Room was added'), '', 'success');

            this.resetForm();
            this.props.onAdd();
        } catch (err) {
            console.error(err);
            swal('Error', err.message, 'error');
        }
    }

    render() {
        const {t}  = this.props;

        let emailField = () => {
            return <div className={`input-field ${!this.state.validEmail  && this.state.emailTouched ? 'error' : ''}`}>
                <label htmlFor="add-room-email" className="datepicker__label">{t('E-mail')}<span>*</span>
                </label>
                <div className="input-field__input">
                    <input className="input" type="email" name="qrCodePortalEmail" placeholder={t('Please enter your e-mail')} id="add-room-email" required
                        value={this.state.email}
                        onChange={(e) => {
                            this.setState({email: e.target.value});
                        }}
                        onKeyPress={async (e) => {
                            if(e.key === 'Enter') {
                                e.preventDefault();
                                await this.validateEmail(e);
                            }
                        }}
                        onBlur={this.validateEmail}
                    />
                    {
                        !this.state.validEmail && !this.state.emailLoading && this.state.emailTouched && <svg className="icon icon-error-status input-notification__icon">
                            <use href="/assets/images/sprite.svg#error-status"></use>
                        </svg>
                    }
                    {
                        this.state.emailLoading && <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                            viewBox="0 0 100 100" enableBackground="new 0 0 100 100" className={'icon-loader'}>
                            <path fill="grey" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                <animateTransform
                                    attributeName="transform"
                                    attributeType="XML"
                                    type="rotate"
                                    dur="1s"
                                    from="0 50 50"
                                    to="360 50 50"
                                    repeatCount="indefinite" />
                            </path>
                        </svg>
                    }
                </div>
                {
                    this.state.email !== '' && !this.state.validEmail  && this.state.emailTouched && <small className="input-notification">
                        {this.state.validationEmailTexts.map((message, i) => <span key={i}>{t(message)}&nbsp;</span>)}
                    </small>
                }
            </div>
        }

        return <div className="informer">
            <div className="accordeon" id='room-add-accordeon' ref={this.addRoomAccordeon}>
                <div className={`accordeon__heading ${this.state.addRoomAccordeonOpen ? 'is-active' : ''}`}
                    onClick={() => this.setState({
                        addRoomAccordeonOpen: !this.state.addRoomAccordeonOpen
                    })}
                >
                    <div className="informer__header" >
                        <div className="informer__heading">{t('Create your room')}</div>
                        <div className="informer__desc">
                            {t('Add room details')}
                        </div>
                    </div>
                </div>
                <div className="accordeon__pane">
                    <div className="accordeon__pane-main" style={{overflow: 'hidden'}}>

                        <div id="room-create-form" className="room-holiday-event">

                            {emailField()}

                            <div className={`input-field ${this.state.roomNameIsBlank && this.state.roomNameTouched ? 'error' : ''}`}>
                                <label htmlFor="room-name" className="datepicker__label">{t('Room name')}<span>*</span>
                                </label>

                                <div className="input-field__input">
                                    <input className="input" type="text" name="roomName" placeholder={t('Room name')} id="room-name" required
                                        value={this.state.roomName}
                                        onChange={this.handleInputChange}
                                        onBlur={this.validateField}
                                    />
                                </div>
                            </div>

                            <div className={`input-field ${this.state.roomFloorIsBlank && this.state.roomFloorTouched ? 'error' : ''}`}>
                                <label htmlFor="room-floor" className="datepicker__label">{t('Floor')}<span>*</span>
                                </label>

                                <div className="input-field__input">
                                    <input className="input" type="number" name="roomFloor" placeholder={t('Floor')} id="room-floor" required
                                        value={this.state.roomFloor || ''}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={this.handleInputChange}
                                        onBlur={this.validateField}
                                    />
                                </div>
                            </div>

                            <div className={`input-field ${this.state.roomRadiatorsCountIsBlank && this.state.roomRadiatorsCountTouched ? 'error' : ''}`}>
                                <label htmlFor="room-number-of-radiators" className="datepicker__label">{t('Number of radiators')}<span>*</span>
                                </label>

                                <div className="input-field__input">
                                    <input className="input" type="number" name="roomRadiatorsCount" placeholder={t('Number of radiators')} id="room-number-of-radiators" required
                                        value={this.state.roomRadiatorsCount || ''}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={this.handleInputChange}
                                        onBlur={this.validateField}
                                    />
                                </div>
                            </div>

                            <div className='input-field' style={{display: 'flex'}}>
                                <label htmlFor="room-sensor-position" className="datepicker__label">{t('Sensor')}
                                </label>
                                <div className="input-field__input">
                                    <input type="checkbox" id="room-sensor" className="custom-toggle" checked={this.state.roomSensor} onChange={() => {this.setState({roomSensor: !this.state.roomSensor})}} />
                                    <label htmlFor="room-sensor" className="ml-2 custom-toggle-btn"></label>
                                </div>
                            </div>

                            {<div style={{'display': this.state.roomSensor ? 'block' : 'none'}}>
                                <div className='input-field'>
                                    <label htmlFor="room-sensor-position" className="datepicker__label">{t('Sensor position')}
                                    </label>
                                    <div className="input-field__input">
                                        <textarea className="textarea" name="roomSensorPosition" id="room-sensor-position" required
                                            value={this.state.roomSensorPosition}
                                            onChange={this.handleInputChange}
                                        ></textarea>
                                    </div>
                                </div>

                                <div className="input-field">
                                    <label className='datepicker__label'>{t('Sensor photo')}</label>
                                    <div className="upload">
                                        <input
                                            type="file"
                                            id="sensorPhoto"
                                            accept="image/*"
                                        />
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 108">
                                            <g fill="none" fillRule="evenodd">
                                                <path fill="#CCC" fillRule="nonzero"
                                                    d="M97.5,28.1 L85.7,28.1 L85.7,18.4 C85.7,16.7 84.4,15.4 82.7,15.4 L10.5,15.4 C8.8,15.4 7.5,16.7 7.5,18.4 L7.5,75.1 C7.5,76.8 8.8,78.1 10.5,78.1 L21.2,78.1 L21.2,87.7 C21.2,89.4 22.5,90.7 24.2,90.7 L97.5,90.7 C99.2,90.7 100.5,89.4 100.5,87.7 L100.5,31.1 C100.5,29.4 99.2,28.1 97.5,28.1 Z M13.5,21.4 L79.7,21.4 L79.7,72.1 L13.5,72.1 L13.5,21.4 Z M94.5,84.6 L27.2,84.6 L27.2,78 L82.8,78 C84.5,78 85.8,76.7 85.8,75 C85.8,74.7 85.8,74.5 85.7,74.2 L85.7,34.1 L94.5,34.1 C94.5,34.1 94.5,84.6 94.5,84.6 Z"/>
                                                <path fill="#CCC" fillRule="nonzero"
                                                    d="M55.5,42.9 C60.4,42.9 64.4,38.9 64.4,34 C64.4,29.1 60.4,25.1 55.5,25.1 C50.6,25.1 46.6,29.1 46.6,34 C46.6,38.9 50.6,42.9 55.5,42.9 Z M55.5,31.1 C57.1,31.1 58.4,32.4 58.4,34 C58.4,35.6 57.1,36.9 55.5,36.9 C53.9,36.9 52.6,35.6 52.6,34 C52.6,32.4 53.9,31.1 55.5,31.1 Z"/>
                                                <path fill="#CCC" fillRule="nonzero"
                                                    d="M63.6,47.8 C63,47.1 62.2,46.7 61.3,46.7 C60.4,46.7 59.5,47.1 59,47.8 L54,54 L42.3,37 C41.7,36.2 40.8,35.7 39.8,35.7 C38.8,35.7 37.9,36.2 37.3,37 L19.8,62.6 C19.2,63.5 19.1,64.7 19.6,65.7 C20.1,66.7 21.1,67.3 22.3,67.3 L49.6,67.3 L57.4,67.3 L73,67.3 C74.2,67.3 75.2,66.6 75.7,65.6 C76.2,64.6 76.1,63.3 75.3,62.4 L63.6,47.8 Z M49.6,61.3 L28,61.3 L39.8,44 L51.7,61.3 L49.6,61.3 Z M59,61.3 L57.6,59.2 L61.4,54.5 L66.8,61.3 L59,61.3 Z"/>
                                                <rect width="108" height="108"/>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                {
                                    this.state.sensorPhoto && <div>
                                        {t('Selected file')}: {this.state.sensorPhoto.alt}
                                    </div>
                                }
                            </div>}
                            <div className="input-field">
                                <label className='datepicker__label'>{t('Room photo')}</label>
                                <div className="upload">
                                    <input
                                        type="file"
                                        id="addRoomFileUpload"
                                        accept="image/*"
                                    />
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 108">
                                        <g fill="none" fillRule="evenodd">
                                            <path fill="#CCC" fillRule="nonzero"
                                                d="M97.5,28.1 L85.7,28.1 L85.7,18.4 C85.7,16.7 84.4,15.4 82.7,15.4 L10.5,15.4 C8.8,15.4 7.5,16.7 7.5,18.4 L7.5,75.1 C7.5,76.8 8.8,78.1 10.5,78.1 L21.2,78.1 L21.2,87.7 C21.2,89.4 22.5,90.7 24.2,90.7 L97.5,90.7 C99.2,90.7 100.5,89.4 100.5,87.7 L100.5,31.1 C100.5,29.4 99.2,28.1 97.5,28.1 Z M13.5,21.4 L79.7,21.4 L79.7,72.1 L13.5,72.1 L13.5,21.4 Z M94.5,84.6 L27.2,84.6 L27.2,78 L82.8,78 C84.5,78 85.8,76.7 85.8,75 C85.8,74.7 85.8,74.5 85.7,74.2 L85.7,34.1 L94.5,34.1 C94.5,34.1 94.5,84.6 94.5,84.6 Z"/>
                                            <path fill="#CCC" fillRule="nonzero"
                                                d="M55.5,42.9 C60.4,42.9 64.4,38.9 64.4,34 C64.4,29.1 60.4,25.1 55.5,25.1 C50.6,25.1 46.6,29.1 46.6,34 C46.6,38.9 50.6,42.9 55.5,42.9 Z M55.5,31.1 C57.1,31.1 58.4,32.4 58.4,34 C58.4,35.6 57.1,36.9 55.5,36.9 C53.9,36.9 52.6,35.6 52.6,34 C52.6,32.4 53.9,31.1 55.5,31.1 Z"/>
                                            <path fill="#CCC" fillRule="nonzero"
                                                d="M63.6,47.8 C63,47.1 62.2,46.7 61.3,46.7 C60.4,46.7 59.5,47.1 59,47.8 L54,54 L42.3,37 C41.7,36.2 40.8,35.7 39.8,35.7 C38.8,35.7 37.9,36.2 37.3,37 L19.8,62.6 C19.2,63.5 19.1,64.7 19.6,65.7 C20.1,66.7 21.1,67.3 22.3,67.3 L49.6,67.3 L57.4,67.3 L73,67.3 C74.2,67.3 75.2,66.6 75.7,65.6 C76.2,64.6 76.1,63.3 75.3,62.4 L63.6,47.8 Z M49.6,61.3 L28,61.3 L39.8,44 L51.7,61.3 L49.6,61.3 Z M59,61.3 L57.6,59.2 L61.4,54.5 L66.8,61.3 L59,61.3 Z"/>
                                            <rect width="108" height="108"/>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            {
                                this.state.file && <div>
                                    {t('Selected file')}: {this.state.file.alt}
                                </div>
                            }

                            <div className='input-field'>
                                <label htmlFor="room-radiator-dimension" className="datepicker__label">{t('Dimension of radiator')}<span>*</span>
                                </label>
                                <div className={`select-custom ${this.state.roomRadiatorDimensionActive ? 'active' : ''} ${!this.state.roomRadiatorDimension && this.state.roomRadiatorDimensionTouched ? 'select-error' : ''}`}
                                    onClick={() => this.setState({
                                        roomRadiatorDimensionActive: !this.state.roomRadiatorDimensionActive,
                                        roomRadiatorDimensionTouched: this.state.roomRadiatorDimensionActive
                                    })}
                                    id='room-radiator-dimension'
                                >
                                    <div className="select-custom-status">
                                        {this.state.roomRadiatorDimension ? this.state.roomRadiatorDimension.value : t('Dimension of radiator')}</div>
                                    <ul className="select-custom-list">
                                        {
                                            this.state.radiatorDimensions.map(type => {
                                                return <li key={type.key}
                                                    className="select-custom-item"
                                                    onClick={() => this.handleInputChange({
                                                        target: {
                                                            name: 'roomRadiatorDimension',
                                                            value: type
                                                        }
                                                    })}
                                                >{t(type.value)}</li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>

                            <div className='input-field'>
                                <label htmlFor="room-type" className="datepicker__label">{t('Room type')}<span>*</span>
                                </label>
                                <div className={`select-custom ${this.state.roomTypeActive ? 'active' : ''} ${!this.state.roomType && this.state.roomTypeTouched ? 'select-error' : ''}`}
                                    onClick={() => this.setState({
                                        roomTypeActive: !this.state.roomTypeActive,
                                        roomTypeTouched: this.state.roomTypeActive
                                    })}
                                    id='room-type'
                                >
                                    <div className="select-custom-status">
                                        {this.state.roomType ? this.state.roomType.value : t('Room type')}</div>
                                    <ul className="select-custom-list">
                                        {
                                            this.state.roomTypes.map(type => {
                                                return <li key={type.key}
                                                    className="select-custom-item"
                                                    onClick={() => this.handleInputChange({
                                                        target: {
                                                            name: 'roomType',
                                                            value: type
                                                        }
                                                    })}
                                                >{t(type.value)}</li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>

                            <div className='input-field'>
                                <label htmlFor="room-dimension" className="datepicker__label">{t('Room dimension')}<span>*</span>
                                </label>
                                <div className={`select-custom ${this.state.roomDimensionActive ? 'active' : ''} ${!this.state.roomDimension && this.state.roomDimensionTouched ? 'select-error' : ''}`}
                                    onClick={() => this.setState({
                                        roomDimensionActive: !this.state.roomDimensionActive,
                                        roomDimensionTouched: this.state.roomDimensionActive
                                    })}
                                    id='room-dimension'
                                >
                                    <div className="select-custom-status">
                                        {this.state.roomDimension ? this.state.roomDimension.value : t('Room dimension')}</div>
                                    <ul className="select-custom-list">
                                        {
                                            this.state.roomDimensions.map(type => {
                                                return <li key={type.key}
                                                    className="select-custom-item"
                                                    onClick={() => this.handleInputChange({
                                                        target: {
                                                            name: 'roomDimension',
                                                            value: type
                                                        }
                                                    })}
                                                >{t(type.value)}</li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>

                            <div className={`input-field ${this.state.commentIsBlank && this.state.commentTouched ? 'error' : ''}`} style={{display: 'flex'}}>
                                <label htmlFor="room-sensor-insolation" className="datepicker__label">{t('Sun insolation')}
                                </label>
                                <div className="input-field__input">
                                    <input type="checkbox" id="room-sun-insolation" className="custom-toggle" checked={this.state.roomSunInsolation} onChange={() => {this.setState({roomSunInsolation: !this.state.roomSunInsolation})}} />
                                    <label htmlFor="room-sun-insolation" className="ml-2 custom-toggle-btn"></label>
                                </div>
                            </div>

                            <div className='input-field'>
                                <label htmlFor="room-code" className="datepicker__label">{t('Room code')}
                                </label>

                                <div className="input-field__input">
                                    <input className="input" type="text" name="roomCode" placeholder={t('Room code')} id="room-code"
                                        value={this.state.roomCode || ''}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className="buttons-wr">

                                <button id="btn-add-room" className={`btn btn--primary ${this.shouldDisableSaveButton() ? 'disabled' : ''}`}
                                    onClick={this.saveButtonPressed}>{t('Add room')}</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>;
    }
}

CreateRoom.propTypes = {
    t: PropTypes.any,
    roomId: PropTypes.string,
    homeId: PropTypes.string,
    i18n: PropTypes.object,
    onAdd: PropTypes.func,
};

export default translate(CreateRoom);