import React from 'react';
import PropType from 'prop-types';
import missingIcon from '../../../assets/images/ic_icon_missing.svg';

export default class RoomReportingIndicator extends React.Component {

    constructor(props){
        super(props);
    }

    render() {
        const {title, icon, value} = this.props;
        if (value == null) {
            return '';
        }

        return (
            <div className='reporting-indicator-container'>
                <div>
                    {icon && <img src={icon} className='reporting-indicator-icon' />}
                    {!icon && <img src={missingIcon} className='reporting-indicator-icon' />}
                </div>
                <div>
                    <div className='reporting-indicator-value-container'>
                        <h2 className='reporting-indicator-value'>{value}</h2>
                    </div>
                    <div className='reporting-indicator-title'>{title}</div>

                </div>
            </div>
        );
    }
}

RoomReportingIndicator.propTypes = {
    icon: PropType.string,
    value: PropType.string,
    title: PropType.string
};